import React from 'react';
import styled from 'styled-components';

import { Text, Loading, Flex } from '../../../components/elements';
import Item from './Item';
import { getKeywordTagline } from '../../../utils/helpers';
import MainRecentSearch from './MainRecentSearch';

const Wrapper = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyTheta};
`;
const Empty = styled.div`
  padding: 1rem;
`;

const Content = styled.div`
  margin-top: 10rem;
  margin-bottom: 1rem;
`;

const LoadingWrapper = styled(Flex)`
  height: 80vh;
`;

const DesktopView = ({ activeTab, places, items, events, query, loading }) => {
  const totalItems = places.length + items.length + events.length;
  return (
    <Content>
      {loading ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <>
          {query.length === 0 && <MainRecentSearch />}
          {totalItems === 0 && query.length !== 0 && (
            <Empty>
              <Text size="small" color="darkGrey" weight="semibold">
                No results matching &quot;{query}&quot; has been found! Please try again with
                different search criteria
              </Text>
            </Empty>
          )}
          {totalItems !== 0 && (
            <Wrapper>
              <Text size="small" weight="semibold">
                Search results for &quot;{query}&quot;
              </Text>
            </Wrapper>
          )}
          {activeTab === 'places' &&
            places.map(place => (
              <Item
                title={place.name}
                subtitle={getKeywordTagline(place.business_type)}
                subtitle1={`${place.address_line_1}, ${place.city}`}
                image={place.default_image_url}
                path={`/place/${place.place_id}`}
              />
            ))}
          {activeTab === 'items' &&
            items.map(item => (
              <Item
                title={item.name}
                subtitle={item.place_name}
                subtitle1={`${item.city}, ${item.state}`}
                image={item.default_image_url}
                path={`/item/${item.item_id}`}
              />
            ))}
          {activeTab === 'events' &&
            events.map(event => (
              <Item
                title={event.name}
                subtitle={`${event.address_line_1}, ${event.city}`}
                image={event.default_image_url}
                path={`/event/${event.event_id}`}
              />
            ))}
        </>
      )}
    </Content>
  );
};

export default DesktopView;
