import React from 'react';
import styled from 'styled-components';
import { FlexRows, IconActionButton } from '../../../../components/elements';
import SocialActionButton from '../../../../components/SocialActionButton';

const Container = styled.div`
  background: ${({ theme: { colors } }) => colors.white};
  padding-top: 0.5rem;
`;

const SocialIconActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'micro', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 2 },
  showCount: false,
})``;

const IconActionButtonStyle = styled(IconActionButton).attrs({
  textStyle: { size: 'micro', weight: 'semibold', color: 'darkGrey' },
  iconStyle: { size: 2 },
})``;

const ActionButtons = ({ item }) => (
  <Container>
    <FlexRows justify="space-between">
      <SocialIconActionButtonStyle
        social={item.social}
        objectId={item.item_id}
        objectType="ITEM"
        type="TRIED"
        name="TRIED"
        defaultIcon="triedGrey"
        activeIcon="triedBlue"
      />
      <SocialIconActionButtonStyle
        social={item.social}
        objectId={item.item_id}
        objectType="ITEM"
        type="LOVE_IT"
        name="LOVE"
        defaultIcon="loveTheItemGrey"
        activeIcon="loveTheItemBlue"
      />
      <SocialIconActionButtonStyle
        social={item.social}
        objectId={item.item_id}
        objectType="ITEM"
        type="BOOKMARK"
        name="BOOKMARK"
        defaultIcon="bookmarkGrey"
        activeIcon="bookmarkSolid"
      />
      <IconActionButtonStyle name="REVIEW" icon="reviewAPlaceBlue" />
    </FlexRows>
  </Container>
);

export default ActionButtons;
