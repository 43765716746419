import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import gql from 'graphql-tag';

import { FlexRows, IconSocialActionButton } from '../../../../components/elements';
import FlaggedActionButton from '../../../../components/FlaggedActionButton';
import ShareActionButton from '../../../../components/ShareActionButton';
import client from '../../../../utils/apolloClient';

const SocialActionButtonStyle = styled(IconSocialActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const FlaggedActionButtonStyle = styled(FlaggedActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const ShareActionButtonStyle = styled(ShareActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const socialMutation = gql`
  mutation socialAction($input: SocialNetworkInput) {
    create_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const removeSocialMutation = gql`
  mutation removeSocialAction($input: NavigateSocialInput) {
    delete_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const Social = ({
  isActive,
  parentId,
  objectId,
  objectType,
  likeActive,
  likeCountInt,
  likeSocialIdS,
}) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn, userId } = useStoreState(state => state.auth);
  const [likeCount, setLikeCount] = useState(likeCountInt);
  const [likeSocialId, setLikeSocialId] = useState(likeSocialIdS);
  const [isActiveLike, setIsActiveLike] = useState(likeActive);

  useEffect(() => {
    setLikeCount(likeCountInt);
    setLikeSocialId(likeSocialIdS);
    setIsActiveLike(likeActive);
  }, [likeActive, likeCountInt, likeSocialIdS]);

  const handleCountIncrement = type => {
    if (type === 'LIKE') {
      setLikeCount(likeCount + 1);
      setIsActiveLike(true);
    }
  };

  const handleCountDecrement = type => {
    if (type === 'LIKE') {
      setLikeCount(likeCount - 1);
      setIsActiveLike(false);
    }
  };

  const handleOnClickActive = type => {
    handleCountIncrement(type);
    client.clientPrivate
      .mutate({
        mutation: socialMutation,
        variables: {
          input: {
            user_id: userId,
            object_id: objectId,
            object_type: objectType,
            type,
            value: 1,
          },
        },
      })
      .then(({ data }) => {
        if (type === 'LIKE') {
          setLikeSocialId(data.create_social.social_id);
        }
      });
  };

  const handleOnClickRemove = (type, socialId) => {
    handleCountDecrement(type);
    client.clientPrivate
      .mutate({
        mutation: removeSocialMutation,
        variables: {
          input: {
            user_id: userId,
            social_id: socialId,
          },
        },
      })
      .then(() => {});
  };

  const handleSocialAction = (type, action, socialId) => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
      });
    } else if (action) {
      handleOnClickActive(type);
    } else {
      handleOnClickRemove(type, socialId);
    }
  };

  if (!isActive) {
    return null;
  }

  return (
    <FlexRows justify="flex-start">
      <SocialActionButtonStyle
        active={isActiveLike}
        name="Like"
        defaultIcon="likeGrey"
        activeIcon="likeBlue"
        socialCount={likeCount}
        handleOnClick={() => handleSocialAction('LIKE', !isActiveLike, likeSocialId)}
      />
      &nbsp;&nbsp;&nbsp;
      <ShareActionButtonStyle name="Share" icon="shareBlue" />
      {/* <FlaggedActionButtonStyle
        className="is-right"
        objectId={objectId}
        parentId={parentId}
        objectType="IMAGE"
        name="Flag"
        icon="flagGrey"
        dropdownHeading="Flag this Photo"
        suggestions={[
          { key: 'REPORT_PHOTO', name: 'Report this photo' },
          { key: 'INAPPROPRIATE_PHOTO', name: 'Inappropriate photo' },
          { key: 'LOW_QUALITY_PHOTO', name: 'Low Quality' },
          { key: 'DUPLICATE_PHOTO', name: 'Duplicate photo' },
          { key: 'OTHER_FEED_REPORT', name: 'Other' },
        ]}
      /> */}
    </FlexRows>
  );
};

Social.defaultProps = {
  objectId: '',
  objectType: '',
  likeActive: false,
  likeCountInt: 0,
};

Social.propTypes = {
  objectId: PropTypes.string,
  objectType: PropTypes.string,
  likeActive: PropTypes.bool,

  likeCountInt: PropTypes.number,
};

export default Social;
