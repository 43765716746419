import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';

import { Text, Input, Button, InputErrorMessage, FlexRows } from '../../components/elements';

const ChangePasswordForm = ({
  isLoading,
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  values,
  touched,
  onCancel,
  resetForm,
  isError,
}) => {
  return (
    <form
      className="modal-card-body"
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <div className="columns">
        <div className="column is-6">
          <Text size="large" weight="semibold" paddingBottom={0.5}>
            Change Password
          </Text>
          <br />
          <div className="field">
            <label className="label">Current password</label>
            <div className="control">
              <Input
                className="input"
                name="currentPass"
                type="password"
                value={values.currentPass}
                placeholder="Enter your existing password"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FlexRows justify="flex-end">
                <Text size="micro" color="white">
                  Y.
                </Text>
              </FlexRows>
              {isError && (
                <p className="help is-danger is-size-4">Your current password is incorrect!</p>
              )}
              <InputErrorMessage errors={errors.currentPass} touched={touched.currentPass} />
            </div>
          </div>
          <div className="field">
            <label className="label">Enter your new password</label>
            <div className="control">
              <Input
                className="input"
                name="newPassword"
                type="password"
                value={values.newPassword}
                placeholder="Enter the new password you would like"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FlexRows justify="flex-end">
                <Text size="micro" color="specialGrey">
                  Your password must be at least 8 characters.
                </Text>
              </FlexRows>
              <InputErrorMessage errors={errors.newPassword} touched={touched.newPassword} />
            </div>
          </div>
          <div className="field">
            <label className="label">Enter your new password</label>
            <div className="control">
              <Input
                className="input"
                name="confirmPass"
                type="password"
                value={values.confirmPass}
                placeholder="Renter your password"
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <InputErrorMessage errors={errors.confirmPass} touched={touched.confirmPass} />
            </div>
          </div>
          <br />
          <FlexRows justify="flex-end">
            <a
              onClick={() => {
                onCancel();
                resetForm();
              }}
            >
              <Text size="tiny" weight="semibold" color="primaryColor">
                Cancel
              </Text>
            </a>
            &nbsp; &nbsp;
            <Button
              className={isLoading && 'is-loading'}
              type="submit"
              name="Save New Password"
              width={3}
              backgroundColor="primaryColor"
              textProps={{
                color: isLoading ? 'primaryColor' : 'white',
                weight: 'semibold',
                size: 'tiny',
              }}
            />
          </FlexRows>
        </div>
      </div>
    </form>
  );
};

const MyEnhancedForm = withFormik({
  mapPropsToValues: () => ({
    currentPass: '',
    newPassword: '',
    confirmPass: '',
  }),

  validate: values => {
    const errors = {};
    if (values.newPassword !== values.confirmPass) {
      errors.confirmPass = 'Confirm Password must be matches with new password!';
    }

    if (values.newPassword.length < 8) {
      errors.newPassword = 'Your password must be at least 8 characters!';
    }

    if (values.currentPass === values.newPassword) {
      errors.newPassword = 'Make sure your new password is not match with old password';
    }

    return errors;
  },

  validationSchema: yup.object().shape({
    currentPass: yup.string().required('Current Password is required!'),
    newPassword: yup.string().required('New password is required!'),
    confirmPass: yup.string().required('Confirm password is required!'),
  }),

  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, { resetForm });
  },

  displayName: 'ChangePasswordForm',
})(ChangePasswordForm);

export default MyEnhancedForm;
