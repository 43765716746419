import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MdChevronRight } from 'react-icons/md';
import { FiLogOut } from 'react-icons/fi';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { capitalize } from 'lodash';
import localForage from 'localforage';

import { Flex, Image, Icon, FlexRows, Text } from '../elements';
import GetImages from '../GetImages';

const tabListPrivate = [
  {
    name: 'My Bookmarks',
    iconName: 'bookmarkBlue',
    to: '/profile/my-bookmarks',
  },
  {
    name: 'Find Friends',
    iconName: 'findFriendsBlue',
    to: '/profile/find-friends',
  },
  {
    name: 'My Orders',
    iconName: 'myOrdersBlue',
    to: '/profile/my-orders',
  },
  // {
  //   name: 'Settings',
  //   iconName: 'settingsBlue',
  //   to: '/settings',
  // },
];

const tabListPublic = [
  // {
  //   name: 'Support and Feedback',
  //   iconName: 'supportAndFeedbackBlue',
  //   to: '/support-and-feedback',
  // },
  {
    name: 'Add a Place',
    iconName: 'addAPlaceBlue',
    to: '/add-place',
  },
  {
    name: 'Claim your Place',
    iconName: 'claimYourPlacesBlue',
    to: '/search-place-for-claim',
  },
];

const Container = styled.div`
  background: ${({ theme: { colors } }) => colors.lightGreyBeta};
`;
const PageHeader = styled(Flex)`
  background: ${({ theme: { colors } }) => colors.primaryColor};
  padding: 0.5rem;
`;
const Logo = styled(Image)`
  width: 6rem;
`;
const User = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding: 1rem;
  background: ${({ theme: { colors } }) => colors.white};
`;

const TabList = styled.div`
  background: ${({ theme: { colors } }) => colors.white};
  margin-top: 0.05rem;
`;

const Tab = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding: 1rem 0rem;
  margin: 0rem 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const IconWrapper = styled.div`
  padding: 0rem 1rem;
`;

const SideBarComponent = ({ onSetSidebarOpen }) => {
  const { setIsActiveDesktopLoginModal, logout } = useStoreActions(state => state.auth);
  const { isLoggedIn, user, userImage } = useStoreState(state => state.auth);

  return (
    <>
      {/* <PageHeader justify="space-between">
        <Logo source={logo} objectfit="contain" />
        <a
          onClick={() => {
            onSetSidebarOpen(false);
          }}
        >
          <IconWrapper>
            <Text color="white">
              <FaTimes size={25} />
            </Text>
          </IconWrapper>
        </a>
      </PageHeader> */}
      <Container>
        {isLoggedIn ? (
          <Link to="/profile">
            <User>
              <div>
                <FlexRows>
                  {user && (
                    <GetImages
                      name={user.display_name}
                      maskProps={{ width: 50, height: 50 }}
                      maskTextProps={{
                        color: 'white',
                        weight: 'bold',
                        size: 'small',
                        letterSpacing: 'loose',
                      }}
                      borderRadius={25}
                      randomColorCode={user.color_code}
                      userImage={userImage}
                    />
                  )}
                  &nbsp; &nbsp;
                  <div>
                    <Text color="primaryColor" size="medium" weight="semibold">
                      {user && `${capitalize(user.first_name)}`}{' '}
                      {user && `${capitalize(user.last_name)}`}
                    </Text>
                    <Text size="tiny">View Profile</Text>
                  </div>
                </FlexRows>
              </div>

              <Text color="specialGrey">
                <MdChevronRight size={25} />
              </Text>
            </User>
          </Link>
        ) : (
          <a onClick={() => setIsActiveDesktopLoginModal({ value: true })}>
            <User>
              <div>
                <FlexRows>
                  <Icon name="myProfileBlue" size={3.5} />
                  &nbsp; &nbsp;
                  <div>
                    <Text color="primaryColor" size="medium" weight="semibold">
                      Sign In / Sign Up
                    </Text>
                  </div>
                </FlexRows>
              </div>
              <Text color="specialGrey">
                <MdChevronRight size={20} />
              </Text>
            </User>
          </a>
        )}

        <TabList>
          {isLoggedIn &&
            tabListPrivate.map(item => (
              <Link to={item.to} key={item.name}>
                <Tab>
                  <div>
                    <FlexRows>
                      <Icon name={item.iconName} size={1.5} />
                      &nbsp; &nbsp;
                      <Text size="small">{item.name}</Text>
                    </FlexRows>
                  </div>
                  <Text color="specialGrey" align="center">
                    <MdChevronRight size={20} />
                  </Text>
                </Tab>
              </Link>
            ))}
          {tabListPublic.map(item => (
            <Link to={item.to} key={item.name}>
              <Tab>
                <div>
                  <FlexRows>
                    <Icon name={item.iconName} size={1.5} />
                    &nbsp; &nbsp;
                    <Text size="small">{item.name}</Text>
                  </FlexRows>
                </div>
                <Text color="specialGrey" align="center">
                  <MdChevronRight size={20} />
                </Text>
              </Tab>
            </Link>
          ))}
          {isLoggedIn && (
            <a
              onClick={async () => {
                await localForage.clear();
                await logout();
                await window.localStorage.clear();
              }}
            >
              <Tab>
                <div>
                  <FlexRows>
                    <Text color="primaryColor">
                      <FiLogOut size={20} />
                    </Text>
                    &nbsp; &nbsp;
                    <Text size="small">Sign Out</Text>
                  </FlexRows>
                </div>
                <Text color="specialGrey" align="center">
                  <MdChevronRight size={20} />
                </Text>
              </Tab>
            </a>
          )}
        </TabList>
      </Container>
    </>
  );
};

export default SideBarComponent;
