import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import ReactLoading from 'react-loading';
import { forEach, omit } from 'lodash';
import { toast } from 'react-toastify';
import { Text, FlexRows } from '../../../../components/elements';
import EmptyCart from './EmptyCart';
import YourOrderItem from './YourOrderItem';
import { formatCurrency } from '../../../../utils/helpers';
import { CartLoader } from '../../Loader';
import {
  guestUserFlowCartConversation,
  parseOrderDatabaseInputItemsWhileSubmissionAgain,
} from '../../helpers';
import { OrderFragments } from '../../../../utils/fragments';

const Container = styled.div`
  /* padding-bottom: 15rem; */
  && {
    .d-flex {
      display: flex;
      justify-content: space-between;
    }
    figure {
      margin-bottom: 1rem;
    }
    .is-2by1 {
      padding-top: 40%;
      margin-top: 4rem;
    }
    span {
      color: ${({ theme: { colors: themeColors } }) => themeColors.primaryColor};
    }
  }
`;

const ConfirmOrder = styled(FlexRows)`
  background-color: ${({ disabled, theme: { colors: themeColors } }) =>
    disabled ? themeColors.coolGrey : themeColors.primaryColor};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  width: 100%;
  cursor: pointer;
`;

// const PromoWrapper = styled(FlexRows)`
//   margin: 1.5rem 0rem;
//   cursor: pointer;
// `;

// const ApplyPromo = styled(Text)`
//   color: ${({ theme: { colors: color } }) => color.primaryColor};
//   margin-top: -10px;
// `;

const Bottom = styled.div`
  border-top: 2px solid ${({ theme: { colors: color } }) => color.borderColor};
  padding-top: 2rem;
`;

const updateOrderMutation = gql`
  mutation updateOrder($input: MenuOrderInput) {
    update_menu_order(input: $input) {
      ...OrderFragments
      error {
        description
      }
    }
  }
  ${OrderFragments}
`;

const YourOrder = ({
  orderDataFromDataBase,
  addToCartLoading,
  placeName,
  place,
  lineItems,
  onItemEdit,
  history,
  cartLoading,
  billing,
  originalOrderFromDataBase,
  updateQuantity,
  emptyCart,
}) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn } = useStoreState(state => state.auth);
  const { anonymousUserIdForCart } = useStoreState(state => state.global);
  const { setAnonymousUserIdForCart } = useStoreActions(state => state.global);
  const [guestUserLoading, setGuestUserLoading] = useState(false);

  if (cartLoading) {
    return <CartLoader />;
  }
  const getQuantity = () => {
    let qyt = 0;
    forEach(lineItems, item => {
      qyt += item.quantity;
    });

    return qyt;
  };

  return (
    <Container>
      <div className="d-flex justify-space-between">
        <Text weight="semibold" size="bmedium" paddingBottom="0.2">
          Your Order
        </Text>
        {lineItems.length !== 0 && (
          <a onClick={emptyCart}>
            <Text weight="medium" size="tiny" paddingBottom="0.2" color="primaryColor">
              Empty Cart
            </Text>
          </a>
        )}
      </div>

      {lineItems.length === 0 ? (
        <EmptyCart />
      ) : (
        <>
          <Text
            className="is-capitalized"
            weight="medium"
            size="smaller"
            paddingBottom="0.2"
            color="darkGrey"
          >
            From <span>{placeName}</span>
          </Text>
          <Text weight="regular" size="smaller">
            {getQuantity()} Items
          </Text>
          <Mutation
            mutation={updateOrderMutation}
            onCompleted={({ update_menu_order }) => {
              if (update_menu_order.error) {
                update_menu_order.error.map(item => toast.error(item.description));
              } else {
                const state = {};
                Object.assign(
                  state,
                  place.place_id === orderDataFromDataBase.place_id && {
                    order: { ...update_menu_order, place },
                  },
                );
                history.push('/checkout', {
                  ...state,
                });
              }
            }}
          >
            {(update_menu_order, { loading: confirmLoading }) => (
              <ConfirmOrder
                justify="space-between"
                onClick={async () => {
                  if (!isLoggedIn) {
                    setIsActiveModal({
                      value: true,
                      callBackFunction: ({ userId }) =>
                        guestUserFlowCartConversation({
                          guestMenuOrderId: anonymousUserIdForCart,
                          userId,
                          history,
                          setAnonymousUserIdForCart,
                          setGuestUserLoading,
                        }),
                    });
                  } else if (lineItems.length !== 0) {
                    const finalOne = await parseOrderDatabaseInputItemsWhileSubmissionAgain(
                      orderDataFromDataBase.line_item,
                    );
                    const updateInput = {
                      user_id: orderDataFromDataBase.userId,
                      menu_order_id: orderDataFromDataBase.menu_order_id,
                      place_id: orderDataFromDataBase.place_id,
                      // service_type: orderDataFromDataBase.service_type,
                      service_type_setting_id: orderDataFromDataBase.service_type_setting_id,
                      menu_id: orderDataFromDataBase.menu_id,
                      address: omit(orderDataFromDataBase.address, '__typename'),
                      line_item: finalOne,
                    };

                    update_menu_order({
                      variables: {
                        input: updateInput,
                      },
                    });
                  }
                }}
                disabled={lineItems.length === 0}
              >
                <Text weight="semibold" size="smedium" color="white">
                  {guestUserLoading || confirmLoading ? (
                    <ReactLoading type="spin" height={25} width={26} />
                  ) : (
                    'Confirm Order'
                  )}
                </Text>
                <Text weight="semibold" size="smedium" color="white">
                  {formatCurrency(billing.order_total)}
                </Text>
              </ConfirmOrder>
            )}
          </Mutation>

          {lineItems.map(item => (
            <YourOrderItem
              addToCartLoading={addToCartLoading}
              cartLoading={cartLoading}
              originalOrderFromDataBase={originalOrderFromDataBase}
              updateQuantity={updateQuantity}
              item={item}
              onItemEdit={() => onItemEdit(item)}
            />
          ))}
          {/* <CheckBox
            label="Please send cutlery, straws, napkins etc"
            labelTextProps={{ size: 'smedium', color: colors.coolGrey, weight: 'medium' }}
            onChange={() => {}}
          />
          <TextArea
            placeholder="Add a note and 
            mention anything specific such as no nuts or cross contamination, less spicy"
            style={{
              marginTop: '1rem',
              minHeight: '4rem',
              borderColor: '#3e3e3a12',
              resize: 'none',
            }}
          />
          <Text color="coolGrey" size="ultraMicro">
            Characters left: 0/114
          </Text>
          <PromoWrapper
            justify="flex-end"
            align="center"
            onClick={() => {
              setIsActiveModal(true);
            }}
          >
            <ApplyPromo size="smedium">Apply Promocode</ApplyPromo>
            <Text color="coolGrey">
              <MdKeyboardArrowRight size={30} />
            </Text>
          </PromoWrapper> */}
          <Bottom>
            {billing.sub_total && billing.sub_total !== 0 && (
              <FlexRows justify="space-between">
                <Text weight="medium" size="smedium" paddingBottom="0.2">
                  Items subtotal
                </Text>
                <Text
                  weight="medium"
                  size="smedium"
                  paddingBottom="0.2"
                  style={{ width: '4.6rem' }}
                >
                  {formatCurrency(billing.sub_total)}
                </Text>
              </FlexRows>
            )}
            {billing.delivery_fee && billing.delivery_fee !== 0 && (
              <FlexRows justify="space-between">
                <Text weight="medium" size="smedium" paddingBottom="0.2">
                  Delivery fee
                </Text>
                <Text
                  weight="medium"
                  size="smedium"
                  paddingBottom="0.2"
                  style={{ width: '4.6rem' }}
                >
                  {formatCurrency(billing.delivery_fee)}
                </Text>
              </FlexRows>
            )}
            {billing.order_total && billing.order_total !== 0 && (
              <FlexRows justify="space-between">
                <Text weight="semibold" size="medium" paddingBottom="0.2">
                  Total
                </Text>
                <Text
                  weight="semibold"
                  size="smedium"
                  paddingBottom="0.2"
                  style={{ width: '4.6rem' }}
                >
                  {formatCurrency(billing.order_total)}
                </Text>
              </FlexRows>
            )}
          </Bottom>
        </>
      )}
    </Container>
  );
};

export default withRouter(YourOrder);
