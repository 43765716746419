import gql from 'graphql-tag';

import client from '../../../utils/apolloClient';

const searchCartQuery = gql`
  query searchCart($input: SearchInput) {
    search_menu_orders(input: $input) {
      menu_order_listing {
        menu_order_id
        place_id
        service_type
        service_type_setting_id
        menu_id
        favourite_order
        order_reference
        place {
          name
          city
          state
          address_line_1
          post_code
          default_image_url
        }
        service_type_setting {
          image_url
        }
        status
        address {
          address_id
          type
          is_default
          contact_name
          contact_phone
          address_line_1
          address_line_2
          city
          state
          country
          post_code
          latitude
          longitude
          status
        }
        billing {
          sub_total

          delivery_fee

          order_total
          order_amount
          tax {
            type
            value
          }
        }
        overall_quantity
        audit {
          created_at
        }
        line_item {
          name
          item_link_id
          menu_item_id
          prompt_id
          variant_id
          variant_name
          name
          quantity
          price_per_item
          net_price_per_item
          tax_per_item
          overall_price
          price
          tax
          modifier {
            name
            modifier_group_id
            menu_item_id
            prompt_id
            variant_id
            variant_name
            name
            quantity
            price_per_item
            net_price_per_item
            tax_per_item
            price
            tax
          }
          menu_item_rating
          special_request
          user_id
          caption
          private
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getCart = ({ input }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchCartQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          data.search_menu_orders.menu_order_listing &&
          data.search_menu_orders.menu_order_listing.length !== 0
        ) {
          resolve(data.search_menu_orders.menu_order_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
