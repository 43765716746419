import React, { useState } from 'react';
import styled from 'styled-components';
import { uniqBy } from 'lodash';

import { Text, FlexRows } from '../../../../components/elements';

const Wrapper = styled.div`
  border: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  background-color: ${({ theme: { colors: themeColor }, currentTab, id }) =>
    currentTab === id ? themeColor.primaryColor : 'transparent'};
  width: 100%;
  margin: 0rem 0.5rem;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
  cursor: pointer;
`;

const Container = styled(FlexRows)`
  padding: 2rem 0rem;
  padding-bottom: 0;
`;

const DiningOptionItem = ({ item, setCurrentTab, currentTab }) => {
  return (
    <Wrapper onClick={() => setCurrentTab(item.name)} currentTab={currentTab} id={item.name}>
      <Text color={currentTab === item.name ? 'white' : 'primaryColor'} weight="semibold">
        {item.name}
      </Text>
    </Wrapper>
  );
};

const DiningOptionTabs = ({ serviceType, setCurrentTab, currentTab }) => {
  const options = uniqBy(serviceType, 'name');
  return (
    <Container justify="space-between">
      {options.map(item => (
        <DiningOptionItem
          key={item.name}
          item={item}
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
        />
      ))}
    </Container>
  );
};

export default DiningOptionTabs;
