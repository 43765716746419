import React, { useRef, useState, useEffect } from 'react';
import { withFormik, FieldArray } from 'formik';
import styled from 'styled-components';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import { forEach, capitalize } from 'lodash';
import ReactLoading from 'react-loading';

import colors from '../../../../../theme/styles/colors';

import bgImg from '../../../../../assets/images/food-1245955_640.jpg';
import { Text, FlexRows, TextArea } from '../../../../../components/elements';
import Variant from './Variant';
import QuantityCounter from './QuantityCounter';
import Modifiers from './Modifiers';
import ModifierVariantSelection from './ModifierVariantSelection';
import { parseFormValuesWhileSubmission, validateAddOrder } from '../../../helpers';
import { ModalLoader } from '../../../Loader';

const Header = styled(FlexRows)`
  padding: 0rem 0rem;
  width: 100%;
`;

const Content = styled.div`
  padding: 1.5rem;
`;

const BgImage = styled.div`
  && {
    background-image: ${({ image }) => (image ? `url(${image})` : `url(${bgImg})`)};
    padding: 12rem;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`;

const Footer = styled.footer`
  && {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 1);
    border-top: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
    justify-content: space-between;
  }
`;

const AddToOrder = styled.div`
  background-color: ${({ theme: { colors: themeColor }, disabled }) =>
    disabled ? themeColor.lightGreyTheta : themeColor.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1rem;
  width: 100%;
  margin-left: 5rem;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  :hover {
    ${({ disabled }) => disabled && 'cursor: not-allowed;'}
  }
  p {
    text-align: center;
  }
`;
const PriceTextWrapper = styled.div`
  position: absolute;
  right: 10px;
`;

const HeaderWrapper = styled.div`
  height: ${({ headerHeight }) => (headerHeight ? `${headerHeight}rem` : '0rem')};
  transition: height 300ms ease 0s, opacity 300ms ease 0s;
`;

const InputStyle = styled.div`
  && {
    width: 100%;
    border-radius: 7px;
    select {
      width: 100%;
    }
  }
`;

const Contact = ({ values, setFieldValue }) => {
  return (
    <div>
      <Text size="smedium" weight="medium" paddingBottom={0.5}>
        Special Requests
      </Text>
      <TextArea
        value={values.special_request}
        placeholder="Note to the kitchen such as allergies, dressing on the side etc"
        style={{ resize: 'none' }}
        onChange={e =>
          e.target.value.length <= 144 && setFieldValue('special_request', e.target.value)
        }
        row="3"
      />

      <Text
        color={values.special_request.length === 144 ? 'danger' : 'coolGrey'}
        size="ultraMicro"
        paddingBottom={1}
      >
        Characters left: {values.special_request.length} / 144
      </Text>
      <Text size="small" paddingBottom={0.3}>
        If Item is &quot;Sold Out&quot;
      </Text>

      <InputStyle className="select">
        <select
          value={values.sold_out}
          onChange={e => {
            setFieldValue('sold_out', e.target.value);
          }}
        >
          <option value="CONTACT_ME">Contact Me</option>
          <option value="CANCEL_ORDER">Cancel Order</option>
        </select>
      </InputStyle>
    </div>
  );
};

const MyForm = ({
  loading,
  menuItem,
  values,
  setFieldValue,
  handleSubmit,
  addToCartLoading,
  onClose,
  isOpen,
  isUpdate,
}) => {
  const [scrollPos, setScrollPos] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);
  const elementRef = useRef();
  const onScroll = () => {
    const { scrollTop } = elementRef.current;
    setScrollPos(scrollTop);
  };

  const calculateItemPrice = input => {
    let price = 0;
    if (input.itemVariant) {
      price += input.itemVariant.variant_price;
    } else {
      price += input.price;
    }
    forEach(input.groups, item => {
      forEach(item.modifier, item2 => {
        if (item2.isSelected) {
          if (item2.selectedVariant && item2.selectedVariant.length !== 0) {
            const variant = item2.selectedVariant[0];
            const variants = variant.variants.filter(item3 => item3.isSelected === true);
            const variantPrice = variants.length !== 0 ? variants[0].variant_price : 0;
            price += variantPrice;
          } else {
            price += item2.price;
          }
        }
      });
    });
    return price;
  };

  useEffect(() => {
    const price = calculateItemPrice(values);
    const validation = validateAddOrder(menuItem, values);
    setIsValid(validation);
    setTotalPrice(price);

    if (menuItem.availability_status === 'UNAVAILABLE' || menuItem.un_available_dates !== null) {
      setIsAvailable(false);
    } else {
      setIsAvailable(true);
    }
  }, [menuItem, values]);

  return (
    <>
      <div>
        {values.isActiveModifierLevelVariantForStep2 ? (
          <a onClick={() => setFieldValue('isActiveModifierLevelVariantForStep2', false)}>
            <div className="cross">
              <MdKeyboardArrowLeft
                size={30}
                color={colors.dark}
                style={{ marginRight: '1rem', cursor: 'pointer' }}
              />
            </div>
          </a>
        ) : (
          <a onClick={onClose}>
            <div className="cross">
              <IoMdClose
                size={20}
                color={colors.dark}
                style={{ cursor: 'pointer' }}
                onClick={onClose}
              />
            </div>
          </a>
        )}
      </div>
      {!menuItem.image && (
        <HeaderWrapper className="modal-card-head" id="scroll" headerHeight={4}>
          <Header>
            <Text size="large" weight="semibold" paddingBottom={0.5} className="item-title">
              {menuItem.name}
            </Text>
          </Header>
        </HeaderWrapper>
      )}
      {(values.isActiveModifierLevelVariantForStep2 || menuItem.image) && (
        <HeaderWrapper id="scroll" headerHeight={scrollPos > 400 ? 4 : 0}>
          <div className="modal-card-head">
            <Header>
              <Text size="large" weight="semibold" paddingBottom={0.5} className="item-title">
                {values.isActiveModifierLevelVariantForStep2
                  ? values.isActiveModifierLevelVariantForStep2HeaderValue
                  : menuItem.name}
              </Text>
            </Header>
          </div>
        </HeaderWrapper>
      )}
      <div className="modal-card-body" ref={elementRef} onScroll={onScroll}>
        {values.isActiveModifierLevelVariantForStep2 ? (
          <ModifierVariantSelection
            prompt={values.isActiveModifierLevelVariantForStep2Value.selectedVariant[0].prompt}
            variants={values.isActiveModifierLevelVariantForStep2Value.selectedVariant[0].variants}
            handleVariantSelection={value => {
              const finalVariant = {
                prompt_id:
                  values.isActiveModifierLevelVariantForStep2Value.selectedVariant[0].prompt_id,
                prompt: values.isActiveModifierLevelVariantForStep2Value.selectedVariant[0].prompt,
                variants: value,
              };
              setFieldValue(
                // eslint-disable-next-line max-len
                `groups.${values.isActiveModifierLevelVariantForStep2Value.parentIndex}.modifier.${values.isActiveModifierLevelVariantForStep2Value.index}.selectedVariant`,
                [finalVariant],
              );
              setFieldValue(`isActiveModifierLevelVariantForStep2`, false);
              setFieldValue(`isActiveModifierLevelVariantForStep2Value`, null);
            }}
          />
        ) : (
          <>
            {menuItem.image && <BgImage image={menuItem.image} />}
            <Content>
              {menuItem.image && (
                <Text size="large" weight="semibold" paddingBottom={0.5} className="item-title">
                  {menuItem.name}
                </Text>
              )}
              {(!isAvailable || !isOpen) && !loading && (
                <Text
                  color="darkGrey"
                  size="smaller"
                  lineHeight="normal"
                  weight="regular"
                  className="item-info"
                  paddingBottom={1}
                >
                  {capitalize(menuItem.name)} not available right now
                </Text>
              )}
              <Text
                color="darkGrey"
                size="smaller"
                lineHeight="normal"
                weight="regular"
                className="item-info"
                paddingBottom={1}
              >
                {menuItem.description}
              </Text>

              {loading ? (
                <ModalLoader />
              ) : (
                <>
                  {isAvailable &&
                    isOpen &&
                    menuItem.variant.map(item => (
                      <>
                        <Text
                          className="is-capitalized"
                          size="medium"
                          paddingBottom={0.4}
                          weight="semibold"
                        >
                          {item.prompt}
                        </Text>
                        <Text size="smaller" color="secondaryColor" paddingBottom="1">
                          Please choose any one option ( Required )
                        </Text>
                        {item.variants.map(item2 => (
                          <Variant
                            key={item2.variant_id}
                            isAvailable={isAvailable}
                            variant={item2}
                            isActive={
                              values.itemVariant &&
                              values.itemVariant.variant_id === item2.variant_id
                            }
                            handleVariantSelection={() =>
                              setFieldValue('itemVariant', {
                                prompt_id: item.prompt_id,
                                variant_id: item2.variant_id,
                                variant_price: item2.variant_price,
                              })
                            }
                          />
                        ))}
                      </>
                    ))}
                  {isAvailable && isOpen && menuItem.variant.length !== 0 && <hr />}
                  {isAvailable && isOpen && (
                    <FieldArray
                      name="groups"
                      render={() => (
                        <>
                          {values.groups.map((group, index) => (
                            <Modifiers
                              isAvailable={isAvailable}
                              parentIndex={index}
                              group={group}
                              setFieldValue={setFieldValue}
                            />
                          ))}
                        </>
                      )}
                    />
                  )}
                  {isAvailable && isOpen && (
                    <Contact values={values} setFieldValue={setFieldValue} />
                  )}
                </>
              )}
            </Content>
          </>
        )}
      </div>
      {!loading && !values.isActiveModifierLevelVariantForStep2 && (
        <Footer className="modal-card-foot">
          <QuantityCounter
            quantity={values.quantity}
            onChange={count => setFieldValue('quantity', count)}
            disabled={!isValid || !isAvailable || !isOpen}
          />
          <AddToOrder
            onClick={() => {
              if (isValid && isAvailable && isOpen) {
                handleSubmit();
              }
            }}
            disabled={!isValid || !isAvailable || !isOpen}
          >
            {isAvailable && isOpen ? (
              <>
                {isUpdate ? (
                  <>
                    <Text
                      size="bmedium"
                      weight="semibold"
                      color={isValid ? colors.white : colors.waterMarkGrey}
                    >
                      {addToCartLoading ? (
                        <ReactLoading type="spin" height={25} width={30} />
                      ) : (
                        'Update Order'
                      )}
                    </Text>
                    <PriceTextWrapper>
                      <Text
                        size="lmedium"
                        weight="semibold"
                        color={isValid ? colors.white : colors.waterMarkGrey}
                      >
                        ${parseFloat(totalPrice * values.quantity).toFixed(2)}
                      </Text>
                    </PriceTextWrapper>
                  </>
                ) : (
                  <>
                    <Text
                      size="bmedium"
                      weight="semibold"
                      color={isValid ? colors.white : colors.waterMarkGrey}
                    >
                      {addToCartLoading ? (
                        <ReactLoading type="spin" height={25} width={30} />
                      ) : (
                        'Add to Order'
                      )}
                    </Text>
                    <PriceTextWrapper>
                      <Text
                        size="lmedium"
                        weight="semibold"
                        color={isValid ? colors.white : colors.waterMarkGrey}
                      >
                        ${parseFloat(totalPrice * values.quantity).toFixed(2)}
                      </Text>
                    </PriceTextWrapper>
                  </>
                )}
              </>
            ) : (
              <>
                {menuItem.un_available_dates ? (
                  <Text size="bmedium" weight="semibold" color={colors.waterMarkGrey}>
                    {menuItem.un_available_dates}
                  </Text>
                ) : (
                  <Text size="bmedium" weight="semibold" color={colors.waterMarkGrey}>
                    {isOpen ? 'Sold out' : 'Unavailable'}
                  </Text>
                )}

                <PriceTextWrapper>
                  <Text size="lmedium" weight="semibold" color={colors.waterMarkGrey}>
                    ${parseFloat(totalPrice * values.quantity).toFixed(2)}
                  </Text>
                </PriceTextWrapper>
              </>
            )}
          </AddToOrder>
        </Footer>
      )}
    </>
  );
};

const ItemAndModifiersSelectionForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({
    menuItem,
    modifiersGroups,
    selectedVariantFromOrder,
    quantity,
    soldOut,
  }) => ({
    isActiveModifierLevelVariantForStep2: false,
    isActiveModifierLevelVariantForStep2Value: null,
    isActiveModifierLevelVariantForStep2HeaderValue: null,
    isActiveModifiersItemVariants: false,
    special_request: '',
    price: menuItem.price,
    itemVariant: selectedVariantFromOrder,
    quantity,
    groups: modifiersGroups,
    sold_out: soldOut || 'CONTACT_ME',
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    props.onSubmit(parseFormValuesWhileSubmission(values), resetForm);
    setSubmitting(false);
  },
  displayName: 'ItemAndModifiersSelectionForm',
})(MyForm);

export default ItemAndModifiersSelectionForm;
