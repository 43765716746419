import React from 'react';
import styled from 'styled-components';

import LocationForm from './LocationForm';

import { Text } from '../../components/elements';

const Container = styled.div`
  padding: 0rem, 2rem;
`;

const Wrapper = styled.div`
  padding-left: 0.4rem;
`;

const Heading = styled(Text)`
  span {
    color: ${({ theme: { colors: color } }) => color.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
  }
`;

const SubHeading = styled(Text)`
  span {
    color: ${({ theme: { colors: themeColor } }) => themeColor.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
  }
`;

const DesktopForm = ({ onSubmit, loading, setIsActiveModal, isLoggedIn }) => {
  // const { values, handleSubmit, loading, isClaimed } = props;
  return (
    <>
      <Container>
        <Wrapper>
          <Heading size="big" weight="semibold" paddingBottom={0.5} paddingTop={0.5}>
            Add a new Place
          </Heading>
          <SubHeading size="small" weight="regular" color="darkGrey">
            If you know of a place that&apos;s not listed on KraveIn, please add the place below. We
            would love to list the place that is not already on KraveIn. If you are the owner or an
            authorised representative of a place that is not listed on Kravein, please add your
            place and once verified, your place will appear on Kravein. You can then claim your
            place to join our community and manage your place listing details, upload photos and
            update services offered to showcase your venue.
          </SubHeading>
        </Wrapper>
        <br />
        <br />
        <LocationForm
          onSubmit={onSubmit}
          loading={loading}
          setIsActiveModal={setIsActiveModal}
          isLoggedIn={isLoggedIn}
        />
      </Container>
    </>
  );
};

export default DesktopForm;
