import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { isNull } from 'lodash';

import { useStoreState } from 'easy-peasy';

import { Text } from '../../../../../components/elements';
import Place from './Place';
import Loading from '../Loading';
import Empty from '../Empty';
import client from '../../../../../utils/apolloClient';

const Container = styled.div`
  padding: 1rem;
`;

const Heading = styled.div`
  padding: 1rem;
`;

const PlaceContainer = styled.article`
  padding: 1rem;
  justify-content: center;
  border: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const followQuery = gql`
  query follows($input: SearchInput) {
    search_follows(input: $input) {
      follow_listing {
        follow_id
        user_id
        object_id
        type
        place {
          place_id
          name
          tagline
          description
          address_line_1
          city
          state
          country
          cuisine_style {
            name
            description
          }
          business_type {
            name
            description
          }
        }
      }
    }
  }
`;

const MyPlaces = () => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <>
      <Heading>
        <Text className="is-capitalized" size="large" weight="semibold">
          My Places
        </Text>
      </Heading>

      <Query
        client={client.clientPrivate}
        query={followQuery}
        variables={{
          input: {
            filter: {
              follow_filter: { user_id: userId, object_type: 'PLACE', type: 'FOLLOW' },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <Empty isError />;
          }

          const { search_follows } = data;

          if (isNull(search_follows)) {
            return <Empty />;
          }

          if (search_follows.follow_listing.length === 0) {
            return <Empty />;
          }

          return (
            <Container>
              <div className="columns is-multiline">
                {search_follows.follow_listing.map(item => (
                  <div className="column is-6" key={item.follow_id}>
                    <PlaceContainer className="media">
                      <Place
                        place={item.place}
                        placeId={item.object_id}
                        followId={item.follow_id}
                      />
                    </PlaceContainer>
                  </div>
                ))}
              </div>
            </Container>
          );
        }}
      </Query>
    </>
  );
};

export default MyPlaces;
