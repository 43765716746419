import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { MdLocationOn } from 'react-icons/md';

import { Text, Icon } from '../../elements';
import AutoSuggest from './AutoSuggest';

import client from '../../../utils/apolloClient';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  cursor: pointer;
  && {
    p {
      padding-left: 1rem;
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 0.8rem 1rem;
  ${({ activeIndex }) => activeIndex && 'background: #f0f0f0;'};
  :hover {
    background: #f0f0f0;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-left: 1rem;
`;

const Suggestions = ({ item, activeIndex, onClick }) => {
  return (
    <Row activeIndex={activeIndex} onClick={onClick}>
      <Icon name="headerPlaceAroundMe" size={1.6} />

      <TextWrapper>
        <Text className="is-capitalized" color="darkGrey" size="small" weight="semibold">
          {capitalize(item.name)}
        </Text>
        <Text color="darkGrey" size="micro">
          {item.state_name}, {item.post_code}
        </Text>
      </TextWrapper>
    </Row>
  );
};

const searchLocationQuery = gql`
  query searchLocation($search_criteria: String!) {
    search_location(input: { search_criteria: $search_criteria }) {
      location_listing {
        code
        country_id
        name
        post_code
        state_name
        country_name
        latitude
        longitude
        location
      }
    }
  }
`;

const AddPlace = ({ active, onClick }) => {
  const updateUsedLocation = useStoreActions(state => state.global.updateUsedLocation);
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const [query, setQuery] = useState('ss');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setQuery(`${usedLocation.city} ${usedLocation.state} ${usedLocation.postcode}`);
  }, [usedLocation.city, usedLocation.postcode, usedLocation.state]);

  const onSuggestionsFetchRequested = value => {
    client.clientPublic
      .query({
        query: searchLocationQuery,
        variables: { search_criteria: value },
      })
      .then(({ data }) => {
        setSuggestions(data.search_location.location_listing);
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const fetchData = event => {
    setQuery(event.target.value);
    onSuggestionsFetchRequested(event.target.value);
  };

  return (
    <a onClick={onClick}>
      <Container>
        {active ? (
          <AutoSuggest
            inputIsCapitalized
            placeholder="Search suburb name"
            icon={<MdLocationOn color="#aeaeae" size={25} />}
            value={capitalize(query)}
            suggestions={suggestions}
            onBlur={event => {
              if (event.target.value.length === 0) {
                setQuery(`${usedLocation.city} ${usedLocation.state} ${usedLocation.postcode}`);
              }
            }}
            onChange={fetchData}
            onSelectItem={item => {
              setQuery(`${capitalize(item.name)} ${item.state_name} ${item.post_code}`);
              updateUsedLocation({
                addressLine1: '',
                addressLine2: '',
                city: item.name,
                state: item.state_name,
                postcode: item.post_code,
                country: item.country_name,
                latitude: item.latitude,
                longitude: item.longitude,
              });
              onClick('search');
            }}
            renderSuggestion={(item, activeIndex, onSelectItem) => (
              <Suggestions item={item} activeIndex={activeIndex} onClick={onSelectItem} />
            )}
          />
        ) : (
          <>
            <MdLocationOn color="#ffffff" size={25} />
            <Text className="is-capitalized" color="white" size="small" weight="semibold">
              {`${capitalize(usedLocation.city)} ${usedLocation.state} ${usedLocation.postcode}`}
            </Text>
          </>
        )}
      </Container>
    </a>
  );
};

export default AddPlace;
