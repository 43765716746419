import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { FlexRows, IconSocialActionButton } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const SocialActionButtonStyle = styled(IconSocialActionButton).attrs({
  textStyle: { size: 'small', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const updateOrderMutation = gql`
  mutation updateOrder($input: MenuOrderInput) {
    update_menu_order_trivial(input: $input) {
      menu_order_id
      error {
        description
      }
    }
  }
`;

const FavoriteAction = ({ order }) => {
  const [isActive, setIsActive] = useState(order.favourite_order);
  const [loading, setLoading] = useState(false);

  const handleAction = () => {
    setLoading(true);
    client.clientPublic
      .mutate({
        mutation: updateOrderMutation,
        variables: { input: { menu_order_id: order.menu_order_id, favourite_order: !isActive } },
      })
      .then(() => {
        setIsActive(!isActive);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  if (order.status !== 'COMPLETE') {
    return null;
  }
  return (
    <FlexRows justify="space-between">
      {/* <SocialActionButtonStyle
        loading={loading}
        active={isActive}
        name="Loved"
        defaultIcon="loveTheItemGrey"
        activeIcon="loveTheItemBlue"
        handleOnClick={() => {}}
      /> */}
      <SocialActionButtonStyle
        loading={loading}
        active={isActive}
        name="Favorite order |"
        defaultIcon="bookmarkSolidGrey"
        activeIcon="bookmarkSolid"
        handleOnClick={handleAction}
      />
    </FlexRows>
  );
};

export default FavoriteAction;
