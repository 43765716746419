import React, { useState } from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { truncate, replace, capitalize } from 'lodash';
import { MdDone, MdClear, MdKeyboardArrowRight } from 'react-icons/md';

import { Modal, Text, FlexRows } from '../../../../components/elements';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const ModalHeaderLeft = styled.div`
  position: absolute;
  left: 0.5rem;
`;
const ModalHeaderRight = styled.div`
  position: absolute;
  right: 0.5rem;
`;

const ModalFooter = styled.a`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem;
  position: absolute;
  bottom: 0;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const Header = styled(FlexRows).attrs({ justify: 'space-between' })`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.specialGrey};
  padding: 1rem;
`;

const Flex = styled(FlexRows)`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.specialGrey};
`;

const Title = styled(Text).attrs({ lineSpacing: 'loose', size: 'small' })``;
const Tags = styled(Text).attrs({
  className: 'is-capitalized',
  size: 'tiny',
  color: 'primaryColor',
})``;
const RightIconWrapper = styled(Text).attrs({ color: 'specialGrey' })``;

const Item = ({ title, isIconActive, onSelect }) => (
  <a onClick={onSelect}>
    <Flex justify="space-between">
      <div>
        <Text>{title}</Text>
      </div>
      <div className="is-flex">
        {isIconActive && (
          <Text color="primaryColor">
            <MdDone size={25} />
          </Text>
        )}
      </div>
    </Flex>
  </a>
);

const MobileFilterModal = ({
  isActive,
  setActiveFilterModal,

  eventTypeInputs,
  addEventTypes,
  removeEventTypes,

  eventCategoryInputs,
  addEventCategories,
  removeEventCategories,

  addFeatures,
  removeFeatures,
  featuresInput,

  ageRestrictionInputs,
  addAgeRestriction,
  removeAgeRestriction,

  dressCodeInputs,
  addDressCode,
  removeDressCode,

  setToMainFilter,
  clearFilters,
}) => {
  const [activeFilterTag, setActiveFilterTag] = useState('');
  const filterTags = useStoreState(state => state.event.filterTags);
  const {
    ticketType,
    listingType,
    eventType,
    eventCategory,
    feature,
    ageRestriction,
    dressCode,
  } = filterTags;

  const listType = listingType.filter(item => item.name === 'public_listing');
  const paidTicket = ticketType.filter(item => item.name === 'PAID_TICKET');
  const freeTicket = ticketType.filter(item => item.name === 'FREE_TICKET');

  return (
    <Modal isActive={isActive}>
      <ModalHeader>
        <ModalHeaderLeft>
          <a
            onClick={() =>
              activeFilterTag === '' ? setActiveFilterModal(false) : setActiveFilterTag('')
            }
          >
            <Text color="white">
              <MdClear size={25} />
            </Text>
          </a>
        </ModalHeaderLeft>
        <ModalHeaderRight>
          <a onClick={() => clearFilters()}>
            <Text className="is-uppercase" color="white" weight="semibold" size="small">
              Clear All
            </Text>
          </a>
        </ModalHeaderRight>

        <FlexRows>
          <Text className="is-uppercase" align="center" color="white" weight="bold" size="lmedium">
            Filter
          </Text>
        </FlexRows>
      </ModalHeader>

      <Content>
        {activeFilterTag === '' && (
          <>
            {/* <a
              onClick={() => {
                setActiveFilterTag('listing-types');
              }}
            >
              <Header>
                <Title>Listing Types</Title>
                <Tags>
                  {truncate(
                    eventTypeInputs.map(item => ` ${capitalize(replace(item.name, '_', ','))}`),
                    {
                      length: 20,
                    },
                  )}
                </Tags>
                &nbsp; &nbsp;
                <RightIconWrapper>
                  <MdKeyboardArrowRight size={25} />
                </RightIconWrapper>
              </Header>
            </a> */}
            <a
              onClick={() => {
                setActiveFilterTag('types');
              }}
            >
              <Header>
                <Title>Events Types</Title>
                <Tags>
                  {truncate(
                    eventTypeInputs.map(item => ` ${capitalize(replace(item.name, '_', ','))}`),
                    {
                      length: 20,
                    },
                  )}
                </Tags>
                &nbsp; &nbsp;
                <RightIconWrapper>
                  <MdKeyboardArrowRight size={25} />
                </RightIconWrapper>
              </Header>
            </a>
            <a
              onClick={() => {
                setActiveFilterTag('categories');
              }}
            >
              <Header>
                <Title>Event Categories</Title>
                <Tags>
                  {truncate(
                    eventCategoryInputs.map(item => ` ${capitalize(replace(item.name, '_', ','))}`),
                    {
                      length: 20,
                    },
                  )}
                </Tags>
                &nbsp; &nbsp;
                <RightIconWrapper>
                  <MdKeyboardArrowRight size={30} />
                </RightIconWrapper>
              </Header>
            </a>

            <a
              onClick={() => {
                setActiveFilterTag('features');
              }}
            >
              <Header>
                <Title>Features</Title>
                <Tags>
                  {truncate(
                    featuresInput.map(item => ` ${capitalize(replace(item.name, '_', ','))}`),
                    {
                      length: 20,
                    },
                  )}
                </Tags>
                &nbsp; &nbsp;
                <RightIconWrapper>
                  <MdKeyboardArrowRight size={30} />
                </RightIconWrapper>
              </Header>
            </a>
            <a
              onClick={() => {
                setActiveFilterTag('age-restriction');
              }}
            >
              <Header>
                <Title>Age Restriction</Title>
                <Tags>
                  {truncate(
                    ageRestrictionInputs.map(
                      item => ` ${capitalize(replace(item.name, '_', ','))}`,
                    ),
                    {
                      length: 20,
                    },
                  )}
                </Tags>
                &nbsp; &nbsp;
                <RightIconWrapper>
                  <MdKeyboardArrowRight size={30} />
                </RightIconWrapper>
              </Header>
            </a>
            <a
              onClick={() => {
                setActiveFilterTag('dress-code');
              }}
            >
              <Header>
                <Title>Dress Code</Title>
                <Tags className="is-capitalized" color="primaryColor">
                  {truncate(
                    dressCodeInputs.map(item => ` ${capitalize(replace(item.name, '_', ','))}`),
                    {
                      length: 20,
                    },
                  )}
                </Tags>
                &nbsp; &nbsp;
                <RightIconWrapper>
                  <MdKeyboardArrowRight size={30} />
                </RightIconWrapper>
              </Header>
            </a>
          </>
        )}
        {activeFilterTag === 'listing-types' && (
          <>
            <Item title="Listing only" isIconActive={false} onSelect={() => {}} />
            <Item title="Paid Event" isIconActive={false} onSelect={() => {}} />
            <Item title="Free Event" isIconActive={false} onSelect={() => {}} />
          </>
        )}
        {activeFilterTag === 'types' && (
          <>
            {eventType.map(type => {
              const typeActive = eventTypeInputs.filter(item => item.name === type.name);
              const activeElement = typeActive.length !== 0;
              return (
                <Item
                  key={type.name}
                  title={`${type.description} `}
                  isIconActive={activeElement}
                  onSelect={() =>
                    activeElement ? removeEventTypes(type.name) : addEventTypes(type)
                  }
                />
              );
            })}
          </>
        )}

        {activeFilterTag === 'categories' && (
          <>
            {eventCategory.map(category => {
              const categoryActive = eventCategoryInputs.filter(
                item => item.name === category.name,
              );
              const activeElement = categoryActive.length !== 0;
              return (
                <Item
                  key={category.name}
                  title={`${category.description} `}
                  isIconActive={activeElement}
                  onSelect={() =>
                    activeElement
                      ? removeEventCategories(category.name)
                      : addEventCategories(category)
                  }
                />
              );
            })}
          </>
        )}

        {activeFilterTag === 'features' && (
          <>
            {feature.map(type => {
              const typeActive = featuresInput.filter(item => item.name === type.name);
              const activeElement = typeActive.length !== 0;
              return (
                <Item
                  key={type.name}
                  title={`${type.description} `}
                  isIconActive={activeElement}
                  onSelect={() => (activeElement ? removeFeatures(type.name) : addFeatures(type))}
                />
              );
            })}
          </>
        )}
        {activeFilterTag === 'age-restriction' && (
          <>
            {ageRestriction.map(type => {
              const typeActive = ageRestrictionInputs.filter(item => item.name === type.name);
              const activeElement = typeActive.length !== 0;
              return (
                <Item
                  key={type.name}
                  title={`${type.description} `}
                  isIconActive={activeElement}
                  onSelect={() =>
                    activeElement ? removeAgeRestriction(type.name) : addAgeRestriction(type)
                  }
                />
              );
            })}
          </>
        )}
        {activeFilterTag === 'dress-code' && (
          <>
            {dressCode.map(type => {
              const typeActive = dressCodeInputs.filter(item => item.name === type.name);
              const activeElement = typeActive.length !== 0;
              return (
                <Item
                  key={type.name}
                  title={`${type.description} `}
                  isIconActive={activeElement}
                  onSelect={() => (activeElement ? removeDressCode(type.name) : addDressCode(type))}
                />
              );
            })}
          </>
        )}
      </Content>
      <ModalFooter
        onClick={() => {
          if (activeFilterTag === '') {
            setToMainFilter();
          } else {
            setActiveFilterTag('');
          }
        }}
      >
        <Text className="is-uppercase" color="white" align="center" weight="bold" size="small">
          {activeFilterTag === '' ? 'Filter' : 'Add'}
        </Text>
      </ModalFooter>
    </Modal>
  );
};
export default MobileFilterModal;
