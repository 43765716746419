import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { MdLocationOn } from 'react-icons/md';
import { capitalize } from 'lodash';
import { Text, FlexColumns, FlexRows } from '../../components/elements';

import hero from '../../assets/images/home-hero.png';

const Container = styled.div`
  background-image: url(${hero});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
const Overlay = styled.div`
  display: flex;
  height: ${window.innerHeight / 3}px;
  justify-content: center;
  align-items: center;
  background-color: black;
  opacity: 0.4;
`;
const LocationInputWrapper = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: ${window.innerHeight / 8}px;
  z-index: 50;
`;

const LocationInput = styled(FlexRows)`
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
`;

const HeroText = styled.p`
  font-family: 'Kalam', cursive;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.165px;
  color: #f6f6f6;
  padding: 1rem 2.5rem;
`;

const MobileHero = () => {
  const usedLocation = useStoreState(state => state.global.usedLocation);
  return (
    <Container>
      <LocationInputWrapper>
        <FlexColumns>
          <HeroText>What are you kraving in {capitalize(usedLocation.city)}</HeroText>
          <Link to="/search-location">
            <LocationInput justify="flex-start">
              <div style={{ paddingTop: '4px' }}>
                <Text color="darkGrey" weight="semibold">
                  <MdLocationOn size={20} />
                </Text>
              </div>
              <Text className="is-capitalized" color="darkGrey" weight="semibold" size="small">
                {capitalize(usedLocation.city)}, {usedLocation.state}
              </Text>
            </LocationInput>
          </Link>
        </FlexColumns>
      </LocationInputWrapper>
      <Overlay />
    </Container>
  );
};

export default MobileHero;
