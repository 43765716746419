import React, { useState } from 'react';
import styled from 'styled-components';
import { AiOutlineRight } from 'react-icons/ai';
import { GoSearch } from 'react-icons/go';

import { Text } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';

const Container = styled.div`
  && {
    padding: 1rem;
    .is-info {
      background-color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const SearchInput = ({ onChange }) => {
  const [query, setQuery] = useState('');

  return (
    <Container>
      <Text paddingBottom="0.8" className="" color="dark" size="lmedium" weight="regular">
        Search for places or cuisines
      </Text>
      <div className="field has-addons">
        <div className="control has-icons-left" style={{ width: '100%' }}>
          <input
            className="input"
            type="text"
            placeholder="Search for Places or Cuisines"
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
          <span className="icon is-small is-left">
            <GoSearch size={18} color={colors.coolGrey} />
          </span>
        </div>
        <div className="control">
          <a className="button is-info" onClick={() => onChange(query)}>
            <AiOutlineRight size={18} color={colors.white} />
          </a>
        </div>
      </div>
    </Container>
  );
};

export default SearchInput;
