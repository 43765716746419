import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  ${({ order }) => order && `order:${order}`};
  ${({ grow }) => grow && `flex-grow:${grow}`};
  ${({ shrink }) => shrink && `flex-shrink:${shrink}`};
  ${({ basis }) => basis && `flex-basis:${basis}`};
  ${({ flex }) => flex && `flex:${flex}`};
  ${({ alignSelf }) => alignSelf && `align-self:${alignSelf}`};
  ${({ align }) => align && ` text-align:${align}`};}
  ${({ alignItem }) => alignItem && ` align-items:${alignItem}`};}
`;

const FlexItem = ({ children, ...props }) => <Container {...props}>{children}</Container>;

export default FlexItem;
