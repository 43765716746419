import React from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';
import { Text } from '../../../../components/elements';

const Container = styled.div`
  &&& {
    z-index: 200;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      z-index: 201;
      height: 100%;
      padding: 0px;
      padding-bottom: 2rem;
    }
    .modal-card {
      top: 6rem;
      position: absolute;
      width: 40%;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
  }
`;

const Content = styled.div`
  padding: 0rem 1rem;
`;
const Claim = styled(Text)`
  && {
    span {
      font-weight: 700;
      color: ${({ theme: { colors: themeColors } }) => themeColors.coolGrey};
    }
  }
`;

const PromoCodeModal = ({ isActive, deal, onClose, placeLocation }) => {
  const time_zone = tzLookup(
    parseFloat(placeLocation.latitude),
    parseFloat(placeLocation.longitude),
  );
  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{deal.name}</p>
          <a onClick={onClose} aria-label="close">
            <IoMdClose
              size={25}
              color="#000000"
              style={{ marginLeft: '1rem', cursor: 'pointer' }}
            />
          </a>
        </header>
        <section className="modal-card-body">
          <Content>
            <Text paddingBottom={0.2} size="medium" weight="regular">
              Details
            </Text>
            <Text size="small" weight="regular" color="darkGrey" paddingBottom={0.3}>
              {deal.name} off your order
            </Text>
            {deal.promotion !== 'AUTOMATIC' && (
              <Claim className="is-uppercase" size="smaller" paddingBottom={0.3}>
                To claim use this voucher: <span>{deal.promo_code}</span>
              </Claim>
            )}
            {deal.description && deal.description.length !== 0 ? (
              <Text size="small" weight="regular" color="waterMarkGrey">
                {deal.description}
              </Text>
            ) : (
              <>
                <Text size="small" weight="regular" color="darkGrey">
                  Valid for this restaurant only
                </Text>
                <Text size="small" weight="regular" color="darkGrey">
                  Offer valid till{' '}
                  {moment(deal.end)
                    .tz(time_zone)
                    .format('MMM DD, YYYY hh:mm:A')}
                </Text>
              </>
            )}
          </Content>
        </section>
      </div>
    </Container>
  );
};

export default PromoCodeModal;
