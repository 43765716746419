import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { trim, isNull } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import uuid from 'uuid';

import {
  Text,
  Field,
  Button,
  InputErrorMessage,
  LocationSuggestion,
  MobileInput,
} from '../../../../../components/elements';

const Wrapper = styled.form`
  padding: 0rem 1rem;
`;
const Span = styled.span`
  color: red;
`;

const Label = styled(Text).attrs({
  className: 'is-capitalized',
  color: 'darkGrey',
  size: 'tiny',
  weight: 'semibold',
  paddingBottom: 0.2,
})``;

const Header = styled.div`
  padding: 0.5rem 0rem;
`;

const AddressForm = props => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    loading,
    setFieldValue,
    touched,
    errors,
    address,

    onCancel,
  } = props;
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn } = useStoreState(state => state.auth);

  const handleOnSubmit = () => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
        callBackFunction: () => handleSubmit(),
      });
    } else {
      handleSubmit();
    }
  };
  return (
    <Wrapper
      onSubmit={event => {
        event.preventDefault();
        handleOnSubmit();
      }}
    >
      <Header>
        <Text size="lmedium" color="darkGrey" weight="semibold">
          {address ? 'Edit Address' : 'Add New Address'}
        </Text>
      </Header>
      <br />
      <div className="columns is-multiline">
        <div className="column is-7">
          <Label size="medium">
            Address Type<Span>*</Span>
          </Label>
          <Field
            name="type"
            id="AddAddress_Type"
            value={values.type}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Home, Office, Beach house etc"
          />
          <InputErrorMessage touched={touched.type} errors={errors.type} />
        </div>
        <div className="column is-7">
          <Label size="medium">
            Full Name<Span>*</Span>
          </Label>
          <Field
            name="contact_name"
            id="AddAddress_Name"
            value={values.contact_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputErrorMessage touched={touched.contact_name} errors={errors.contact_name} />
        </div>
        <div className="column is-5">
          <Label>Phone</Label>
          <MobileInput
            name="contact_phone"
            value={values.contact_phone}
            onChange={value => setFieldValue('contact_phone', value)}
          />
          <InputErrorMessage touched={touched.contact_phone} errors={errors.contact_phone} />
        </div>
        <div className="column is-7">
          <div className="field">
            <Label>
              Search Address<Span>*</Span>
            </Label>
          </div>
          <LocationSuggestion
            initialValue=""
            onSelect={place => {
              setFieldValue('address_line_1', trim(place.addressLine1));
              setFieldValue('city', place.city);
              setFieldValue('post_code', place.postcode);
              setFieldValue('state', place.state);
              setFieldValue('country', place.country || 'AUSTRALIA');
              setFieldValue('timezone', place.timezone || '+10');
              setFieldValue('latitude', place.latitude);
              setFieldValue('longitude', place.longitude);
            }}
          />
        </div>
        <div className="column is-7">
          <div className="field">
            <Label>
              Street Address<Span>*</Span>
            </Label>
          </div>
          <Field
            name="address_line_1"
            value={values.address_line_1}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="2/155 Fil"
          />
          <InputErrorMessage touched={touched.address_line_1} errors={errors.address_line_1} />
        </div>
        <div className="column is-5">
          <div className="field">
            <Label>Additional Address Information</Label>
          </div>
          <Field
            name="address_line_2"
            id="AddAddress_AdditionalInfo"
            value={values.address_line_2}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Landmark, Shopping Center etc..."
          />
        </div>
        <div className="column is-7">
          <div className="field">
            <Label>
              City / Suburb<Span>*</Span>
            </Label>
          </div>
          <Field name="city" value={values.city} onChange={handleChange} onBlur={handleBlur} />
          <InputErrorMessage touched={touched.city} errors={errors.city} />
        </div>
        <div className="column is-5">
          <div className="field">
            <Label>
              PostCode<Span>*</Span>
            </Label>
          </div>
          <Field
            name="post_code"
            value={values.post_code}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputErrorMessage touched={touched.post_code} errors={errors.post_code} />
        </div>
        <div className="column is-7">
          <div className="field">
            <Label>
              State<Span>*</Span>
            </Label>
          </div>
          <Field name="state" value={values.state} onChange={handleChange} onBlur={handleBlur} />
          <InputErrorMessage touched={touched.state} errors={errors.state} />
        </div>
        <div className="column is-5">
          <div className="field">
            <Label>
              Country<Span>*</Span>
            </Label>
          </div>
          <Field
            name="country"
            value={values.country}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputErrorMessage touched={touched.country} errors={errors.country} />
        </div>
      </div>
      <br />
      <div className="level">
        <div className="level-left">
          <div className="level-item" />
        </div>
        <div className="level-right">
          <div className="level-item">
            <a onClick={() => onCancel()}>
              <Text color="primaryColor" weight="semibold">
                Cancel
              </Text>
            </a>
          </div>
          <div className="level-item">
            <Button
              type="submit"
              className={loading && 'is-loading'}
              name="Save"
              backgroundColor="primaryColor"
              textProps={{ color: 'white', weight: 'semibold' }}
            />
          </div>
        </div>
      </div>
      <br />
    </Wrapper>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ address, isUpdateFormType }) => ({
    address_id: address && !isNull(address.address_id) ? address.address_id : uuid(),
    is_default: address && !isNull(address.is_default) ? address.is_default : !isUpdateFormType,
    type: address && !isNull(address.type) ? address.type : '',
    contact_name: address && !isNull(address.contact_name) ? address.contact_name : '',
    contact_phone: address && !isNull(address.contact_phone) ? address.contact_phone : '',
    address_line_1: address && !isNull(address.address_line_1) ? address.address_line_1 : '',
    address_line_2: address && !isNull(address.address_line_2) ? address.address_line_2 : '',
    city: address && !isNull(address.city) ? address.city : '',
    post_code: address && !isNull(address.post_code) ? address.post_code : '',
    state: address && !isNull(address.state) ? address.state : '',
    country: address && !isNull(address.country) ? address.country : '',
    latitude: address && !isNull(address.latitude) ? address.latitude : '',
    longitude: address && !isNull(address.longitude) ? address.longitude : '',
    timezone: address && !isNull(address.timezone) ? address.timezone : '',
    status: address && !isNull(address.status) ? address.status : 'ACTIVE',
    additionalInfo: '',
  }),

  validationSchema: yup.object().shape({
    type: yup.string().required('Address type is required'),
    contact_name: yup.string().required('Full name is required'),
    address_line_1: yup.string().required('Street Address is required'),
    city: yup.string().required('City is required'),
    post_code: yup.string().required('Postcode is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  },

  displayName: 'LocationForm',
})(AddressForm);

export default Form;
