import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdChevronLeft, MdClear } from 'react-icons/md';
import { FaTimes } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';

import { Text } from '../../elements';

const Container = styled.div`
  &&& {
    background: ${({ theme: { colors }, headerLikeModal }) =>
      headerLikeModal ? colors.lightGreyBeta : colors.primaryColor};
    height: 3rem;
    color: #ffffff;
    margin: 0rem;
    padding: 0rem 0.5rem;
    position: fixed;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  padding-top: 3rem;
  flex: 1;
`;

const MobileSecondaryHeader = ({ secondaryHeaderHeading, headerLikeModal, children, history }) => {
  return (
    <>
      <Container headerLikeModal={headerLikeModal}>
        <a onClick={() => history.goBack()}>
          {headerLikeModal ? (
            <Text color="dark">
              <MdClear size={25} />
            </Text>
          ) : (
            <Text color="white">
              <MdChevronLeft size={30} />
            </Text>
          )}
        </a>
        <Text color={headerLikeModal ? 'dark' : 'white'} weight="bold">
          {secondaryHeaderHeading}
        </Text>
        <span />
      </Container>
      <Wrapper>{children}</Wrapper>
    </>
  );
};

MobileSecondaryHeader.defaultProps = {
  headerLikeModal: false,
};

MobileSecondaryHeader.propTypes = {
  headerLikeModal: PropTypes.bool,
};

export default withRouter(MobileSecondaryHeader);
