import headerPlaceAroundMe from './icons/headerPlaceAroundMe.svg';
import headerFindItem from './icons/headerFindItem.svg';
import headerDiscoverEvent from './icons/headerDiscoverEvent.svg';
import headerSpotShareItem from './icons/headerSpotShareItem.svg';
import headerWriteReview from './icons/headerWriteReview.svg';
import partyCateringSolid from './icons/party-catering.svg';
import partyOrFunctionSolid from './icons/10-Party-or-Function-Packages-1.svg';
import roomServiceSolid from './icons/13-Room-Service-3.svg';
import schoolCateringSolid from './icons/14-School-Catering-1.svg';
import nightClubBookingSolid from './icons/15-Night-Club-booking-1.svg';
import restaurantSolid from './icons/16-Restaurant-1.svg';
import barSolid from './icons/17-Bars-1.svg';
import happyHourSolid from './icons/18-Happy-Hour-1.svg';
import corporateCateringSolid from './icons/19-Corporate-Catering-1.svg';
import corporateCateringSolidGrey from './icons/19-Corporate-Catering-1-grey.svg';
import aboutUs from './icons/21-About-Us-1.svg';
import contactUsSolid from './icons/22-Contact-Us-Support-and-Feedback1.svg';
import listABusinessSolid from './icons/23-List-a-Business-1.svg';
import claimABusinessSolid from './icons/24-Claim-a-Business-1.svg';
import corporateCuisineSolid from './icons/25-Corporate-Cuisine-2.svg';
import corporateFunctionSolid from './icons/26-Corporate-function-package-1.svg';
import filterSolid from './icons/27-Filter-3.svg';
import mapViewSolid from './icons/28-Map-View-3.svg';
import listViewSolid from './icons/29-List-View-3-blue.svg';
import kraverSolid from './icons/65-Kraver.svg';
import inspiredKraverSolid from './icons/66-Inspired-Kraver.svg';
import socialiteKraverSolid from './icons/67-Socialite-Kraver.svg';
import explorerKraver1Solid from './icons/68-Explorer-Kraver.svg';
import explorerKraver2Solid from './icons/68-Explorer-Kraver-2.svg';
import persistentKraverSolid from './icons/69-Persistent-Kraver.svg';
import perfectKraverSolid from './icons/70-Perfect-Kraver.svg';
import expertKraverSolid from './icons/71-Expert-Kraver.svg';
import connoisseurKraverSolid from './icons/72-Connoisseur-Kraver.svg';
import superstarKraverSolid from './icons/73-Superstar-Kraver.svg';
import amazingKraverSolid from './icons/74-Amazing-Kraver.svg';
import incredibleKraverSolid from './icons/75-Incredible-Kraver.svg';
import marvelousKraverSolid from './icons/76-Marvellous-Kraver.svg';
import djStationSolid from './icons/81-DJ-Station-1.svg';
import supportAndFeedbackSolid from './icons/82-Support-and-Feedback-1.svg';
import groupCateringSolid from './icons/90-Group-Catering-1.svg';
import placesMapMarkerSolid from './icons/88-Places-Map-Marker–IC3.svg';
import venueAreaLocMarkerSolid from './icons/91-Venue-Area-location-marker.svg';
import groupOrderSolid from './icons/128-Group-Order-1.svg';
import splitPaymentSolid from './icons/129-Split-Payment-1.svg';
import bakerySolid from './icons/135-Bakery.svg';
import pickupSolid from './icons/125-Pickup-3.svg';
import breweriesAndWinsSolid from './icons/148-Breweries-and-wins.svg';
import itemRatingBlueSolid from './icons/150-Item-Rating-blue.svg';
import eventMapMarkerSolid from './icons/174-Event-Map-Marker-IC3.svg';
import accommodationRoomsSolid from './icons/178-Accommodation-rooms-IC1.svg';
import eventFunctionPackagesSolid from './icons/179-Event-Function-Packages-3.svg';
import inHouseCateringSolid from './icons/180-In-House-Catering-IC1.svg';
import inHouseCateringSolid2 from './icons/180-In-House-Catering1-IC1.svg';
import venueAreaSolid from './icons/183-Venue-Area-IC2.svg';
import venueCeilingHeightSolid from './icons/184-Venue-Ceiling-Height-IC1-copy.svg';
import venuePriceSolid from './icons/185-Venue-Price-IC1.svg';
import enquireSolid from './icons/186-Enquire-IC1.svg';
import meetingMaterialSolid from './icons/187-Meeting-Material.svg';
import termsAndConditionSolid from './icons/188-Terms-and-Conditions.svg';
import bestSuitedForSolid from './icons/189-Best-Suited-For-IC2.svg';
import parkingSolid from './icons/193-Parking-IC3.svg';
import venueMapMarkerSolid from './icons/195-Venue-Map-Marker-IC3.svg';
import gallerySolid from './icons/251-Gallery.svg';
import beenThereBlueSolid from './icons/been-there-blue.svg';
import bookmarkSolid from './icons/bookmark.svg';
import bookmarkSolidGrey from './icons/bookmark-grey.svg';
import dealsAndPromotionSolid from './icons/deals-and-promotion-1.svg';
import photoAddSolid from './icons/camera-add.svg';
import discoverEventsSolid from './icons/discover-events-solid.svg';
import facebookSolid from './icons/facebook.svg';
import findAndShareItemSolid from './icons/find-and-share-Item-solid.svg';
import findVenuesSolid from './icons/find-venues-solid.svg';
import placesAroundMeSolid from './icons/places-around-me-solid.svg';
import restaurantAndBars from './icons/restaurant-and-bars.svg';
import reviewItemSolid from './icons/review-item-solid.svg';
import reviewPlaceSolid from './icons/review-place.svg';
import tableBookingSolid from './icons/table-booking-solid.svg';
import takeawayOrDeliverySolid from './icons/takeaway-or-delivery-solid.svg';
import itemRatingGoldSolid from './icons/item-rating-gold.svg';
import itemRatingSolidGrey from './icons/item-rating-grey.svg';
import partyCateringBlue from './icons/7-Party-Catering-2.svg';
import reviewAPlace from './icons/8-Review-a-Place-2.svg';
import partyOrFunctionPackagesBlue from './icons/10-Party or-Function-Packages-2.svg';
import dealsAndPromotionsBlue from './icons/12-Deals-and-promotions-2.svg';
import roomServiceBlue from './icons/13-Room-Service-2.svg';
import nightClubBookingBlue from './icons/15-Night-Club-booking-2.svg';
import Restaurant from './icons/16-Restaurant-2.svg';
import Bars from './icons/17-Bars-2.svg';
import happyHour from './icons/18-Happy-Hour-2.svg';
import corporateCatering from './icons/19-Corporate-Catering-2.svg';
import contactUs from './icons/22-Contact-Us-Support-and-Feedback2.svg';
import corporateCuisine from './icons/25-Corporate-Cuisine-1.svg';
import corporateFunction from './icons/26-Corporate-function-package-2.svg';
import homeBlue from './icons/30-home-3-blue.svg';
import shoppingCartBlue from './icons/31-Shopping-Cart-3-blue.svg';
import reviewAPlaceBlue from './icons/35-Review-a-Place-3-blue-1.svg';
import activityFeedBlue from './icons/37-Activity-Feed-3.svg';
import notificationsBlue from './icons/38-Notifications-3.svg';
import searchesBlue from './icons/41-My-Searches-2.svg';
import searchesWhite from './icons/41-My-Searches-2-white.svg';
import trackMyOrderBlue from './icons/44-Track-my-order-2.svg';
import reorderBlue from './icons/45-Reorder-2.svg';
import myProfileBlue from './icons/47-My-Profile-IC2.svg';
import facilitiesBlue from './icons/190-Facilities-IC2.svg';
import featuresAndServices from './icons/191-Features-and-Services-IC2.svg';
import cateringPreferencesBlue from './icons/192-Catering-Preferences-IC2.svg';
import disabilityAccessBlue from './icons/194-Disability-Access-IC3.svg';
import decreaseQuantityBlue from './icons/196-Decrease-Quantity-IC2.svg';
import increaseQuantityBlue from './icons/197-Increase-Quantity-IC2.svg';
import externalCateringBlue from './icons/202-External-Catering-IC3.svg';
import banquetOnRoundsBlue from './icons/203-Banquet-on-Rounds-IC2.svg';
import boardroomBlue from './icons/204-Boardroom-IC2.svg';
import classroomBlue from './icons/206-Classroom-IC2.svg';
import cocktailBlue from './icons/207-Cocktail-IC2.svg';
import hollowSquareBlue from './icons/208-HollowSquare-IC2.svg';
import theatreBlue from './icons/209-Theatre-IC2.svg';
import uShapeTableBlue from './icons/210-U-Shape-IC2.svg';
import exhibitionBlue from './icons/211-Exhibition-IC2.svg';
import seatedBlue from './icons/212-Seated-IC2.svg';
import standingBlue from './icons/213-Standing-IC2.svg';
import banquetOvalBlue from './icons/214-Banquet-on-Ovals-IC2.svg';
import breakOutRoomsBlue from './icons/215-Break-Out-Rooms-IC2.svg';
import danceFloorBlue from './icons/216-Dance-Floor-IC2.svg';
import addAPlaceBlue from './icons/add-a-places.svg';
import addAPlaceWhite from './icons/add-a-places-white.svg';
import addAPlaceGrey from './icons/add-place-grey.svg';
import beenThereBlue from './icons/be-there.svg';
import beenThereGrey from './icons/be-there-grey.svg';
import claimYourPlacesBlue from './icons/claim-your-places.svg';
import claimYourPlacesGrey from './icons/claim-your-places-grey.svg';
import commentBlue from './icons/comment.svg';
import discoverEventsBlue from './icons/discover-events-lite.svg';
import findAndShareItemBlue from './icons/find-and-share-Item-lite.svg';
import findFriendsBlue from './icons/find-friends.svg';
import findFriendsGrey from './icons/find-friends-grey.svg';
import findVenuesBlue from './icons/find-venues-lite.svg';
import likeBlue from './icons/like.svg';
import likeGrey from './icons/likeGrey.svg';
import likeStarBlue from './icons/like-star.svg';
import likeStarGrey from './icons/like-star-grey.svg';
import bookmarkBlue from './icons/my-bookmark.svg';
import bookmarkGrey from './icons/my-bookmark-grey.svg';
import myOrdersBlue from './icons/my-orders.svg';
import myOrdersGrey from './icons/my-orders-grey.svg';
import placesAroundMeBlue from './icons/places-around-me-lite.svg';
import settingsBlue from './icons/settings.svg';
import reviewItemBlue from './icons/review-item.svg';
import reviewItemGrey from './icons/review-item-grey.svg';
import supportAndFeedbackBlue from './icons/support-and-feedback.svg';
import tableBookingBlue from './icons/table-booking-lite.svg';
import takeawayOrDeliveryBlue from './icons/takeaway-or-delivery-lite.svg';
import partyCatering from './icons/7 Party Catering 3.svg';
import partyOrFunctionPackages from './icons/10 Party or Function Packages 3.svg';
import dealsAndPromotions from './icons/12 Deals and promotions 3.svg';
import roomService from './icons/13 Room Service 1.svg';
import schoolCatering from './icons/14 School Catering 3.svg';
import nightClubBooking from './icons/15 Night Club booking 3.svg';
import schoolCateringBlue from './icons/14 School Catering 2.svg';
import shareBlue from './icons/share.svg';
import locationBlue from './icons/location.svg';
import locationWhite from './icons/location-white.svg';
import FollowGrey from './icons/93-Follow-IC3-2.svg';
import phoneBlue from './icons/107-Phone-or-call.svg';
import flagBlue from './icons/flag.svg';
import flagGrey from './icons/flag-grey.svg';
import featuresBlue from './icons/104-Features-(for-Venue-or-Restaurant).svg';
import rewardPointsBlue from './icons/55-Reward-Points-IC3.svg';
import loveTheItemBlue from './icons/love-the-item.svg';
import myBadgesBlue from './icons/my-badges.svg';
import profileEditBlue from './icons/profile-edit.svg';
import triedBlue from './icons/tried.svg';
import triedGrey from './icons/triedGrey.svg';
import dateBlue from './icons/169-Date-picker-Icon-IC3-blue.svg';
import follow from './icons/follow.svg';
import following from './icons/following.svg';
import loveTheItemGrey from './icons/love-the-item-grey.svg';
import flagThisReview from './icons/flag-this-review.svg';
import flagThisReviewGrey from './icons/flag-this-review-grey.svg';
import featureIcon from './icons/check-list-and-pencil.svg';
import deals from './icons/deals.svg';
import cartIcon from './icons/cart.svg';
import cartIconWhite from './icons/cart-white.svg';
import searchBlack from './icons/search-black.svg';
import addPlaceBlack from './icons/add-place-black.svg';
import locationBlack from './icons/location-black.svg';
import cartSolid from './icons/cart-solid.svg';
import error from './icons/error.svg';
import settingsGrey from './icons/settings-grey.svg';

const svgIcons = {
  addAPlaceGrey,
  error,
  searchBlack,
  addPlaceBlack,
  locationBlack,
  cartIconWhite,
  cartSolid,
  featureIcon,
  flagThisReview,
  flagThisReviewGrey,
  headerPlaceAroundMe,
  headerFindItem,
  headerDiscoverEvent,
  headerSpotShareItem,
  headerWriteReview,
  follow,
  following,
  partyCateringSolid,
  partyOrFunctionSolid,
  roomServiceSolid,
  schoolCateringSolid,
  nightClubBookingSolid,
  restaurantSolid,
  barSolid,
  happyHourSolid,
  corporateCateringSolid,
  corporateCateringSolidGrey,
  aboutUs,
  contactUsSolid,
  listABusinessSolid,
  claimABusinessSolid,
  corporateCuisineSolid,
  corporateFunctionSolid,
  filterSolid,
  mapViewSolid,
  listViewSolid,
  kraverSolid,
  inspiredKraverSolid,
  socialiteKraverSolid,
  explorerKraver1Solid,
  explorerKraver2Solid,
  persistentKraverSolid,
  perfectKraverSolid,
  expertKraverSolid,
  connoisseurKraverSolid,
  superstarKraverSolid,
  amazingKraverSolid,
  incredibleKraverSolid,
  marvelousKraverSolid,
  djStationSolid,
  supportAndFeedbackSolid,
  groupCateringSolid,
  placesMapMarkerSolid,
  venueAreaLocMarkerSolid,
  venueAreaSolid,
  groupOrderSolid,
  splitPaymentSolid,
  bakerySolid,
  pickupSolid,
  breweriesAndWinsSolid,
  itemRatingBlueSolid,
  eventMapMarkerSolid,
  accommodationRoomsSolid,
  eventFunctionPackagesSolid,
  inHouseCateringSolid,
  inHouseCateringSolid2,
  venueCeilingHeightSolid,
  venuePriceSolid,
  enquireSolid,
  meetingMaterialSolid,
  termsAndConditionSolid,
  bestSuitedForSolid,
  parkingSolid,
  venueMapMarkerSolid,
  gallerySolid,
  beenThereBlueSolid,
  bookmarkSolid,
  bookmarkSolidGrey,
  dealsAndPromotionSolid,
  photoAddSolid,
  discoverEventsSolid,
  facebookSolid,
  findAndShareItemSolid,
  findVenuesSolid,
  placesAroundMeSolid,
  restaurantAndBars,
  reviewItemSolid,
  reviewItemGrey,
  reviewPlaceSolid,
  tableBookingSolid,
  takeawayOrDeliverySolid,
  itemRatingGoldSolid,
  itemRatingSolidGrey,
  partyCateringBlue,
  reviewAPlace,
  partyOrFunctionPackagesBlue,
  dealsAndPromotionsBlue,
  roomServiceBlue,
  schoolCatering,
  schoolCateringBlue,
  nightClubBookingBlue,
  Restaurant,
  Bars,
  happyHour,
  corporateCatering,
  contactUs,
  corporateCuisine,
  corporateFunction,
  homeBlue,
  shoppingCartBlue,
  reviewAPlaceBlue,
  activityFeedBlue,
  notificationsBlue,
  searchesBlue,
  searchesWhite,
  trackMyOrderBlue,
  reorderBlue,
  myProfileBlue,
  facilitiesBlue,
  featuresAndServices,
  cateringPreferencesBlue,
  disabilityAccessBlue,
  decreaseQuantityBlue,
  increaseQuantityBlue,
  externalCateringBlue,
  banquetOnRoundsBlue,
  boardroomBlue,
  classroomBlue,
  cocktailBlue,
  hollowSquareBlue,
  theatreBlue,
  uShapeTableBlue,
  exhibitionBlue,
  seatedBlue,
  standingBlue,
  banquetOvalBlue,
  breakOutRoomsBlue,
  danceFloorBlue,
  addAPlaceBlue,
  addAPlaceWhite,
  beenThereBlue,
  beenThereGrey,
  claimYourPlacesBlue,
  claimYourPlacesGrey,
  commentBlue,
  discoverEventsBlue,
  findAndShareItemBlue,
  findFriendsBlue,
  findVenuesBlue,
  likeBlue,
  likeGrey,
  likeStarBlue,
  likeStarGrey,
  bookmarkBlue,
  bookmarkGrey,
  myOrdersBlue,
  myOrdersGrey,
  placesAroundMeBlue,
  settingsBlue,
  reviewItemBlue,
  supportAndFeedbackBlue,
  tableBookingBlue,
  takeawayOrDeliveryBlue,
  partyCatering,
  partyOrFunctionPackages,
  dealsAndPromotions,
  roomService,
  nightClubBooking,
  shareBlue,
  locationBlue,
  locationWhite,
  phoneBlue,
  FollowGrey,
  flagBlue,
  flagGrey,
  featuresBlue,
  rewardPointsBlue,
  loveTheItemBlue,
  myBadgesBlue,
  profileEditBlue,
  triedBlue,
  triedGrey,
  dateBlue,
  loveTheItemGrey,
  deals,
  cartIcon,
  findFriendsGrey,
  settingsGrey,
};

export default { ...svgIcons };
