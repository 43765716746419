import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { ScrollView, Text } from '../../../../components/elements';

const Container = styled.div`
  padding-top: 1rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  /* position: sticky;
  top: 4.3rem;
  z-index: 100; */
`;
const Item = styled.div`
  padding: 0.3rem 1.5rem;
  cursor: pointer;
  p {
    color: ${({ theme: { primaryColor }, isActive }) => (isActive ? primaryColor : '#000000')};
  }
  border-bottom: 3px solid
    ${({ theme: { primaryColor }, isActive }) => (isActive ? primaryColor : 'transparent')};
`;

const Menu = (values, selected) =>
  values.map(item => (
    <Item
      key={item.name}
      isActive={selected === item.name}
      className="has-text-weight-bold has-text-centered"
    >
      <Text size="medium" weight="semibold">
        {item.description}
      </Text>
    </Item>
  ));

const MenuCategoryTabs = ({ handleTabDateFilter }) => {
  const allEventDateFilter = useStoreState(state => state.global.allEventDateFilter);
  const eventCurrentDateFilter = useStoreState(state => state.global.eventCurrentDateFilter);
  const [filterTag, setFilterTag] = useState(eventCurrentDateFilter.name);
  const menu = Menu(allEventDateFilter, filterTag);

  const handleFilter = key => {
    setFilterTag(key);
    handleTabDateFilter(key);
  };

  useEffect(() => {
    setFilterTag(eventCurrentDateFilter.name);
  }, [eventCurrentDateFilter.name]);

  return (
    <Container>
      <ScrollView menu={menu} onSelect={key => handleFilter(key)} />
    </Container>
  );
};

export default MenuCategoryTabs;
