import React from 'react';
import styled from 'styled-components';

import PageHeader from './components/desktop/PageHeader';
import Elements from './components/desktop/Elements';
import Details from './components/desktop/Details';
import { ScrollToTopButton, Pager } from '../../components/elements';

const Container = styled(Pager)``;

const DesktopView = ({ user }) => (
  <Container>
    <PageHeader user={user} />
    <Elements user={user} />
    <br />
    <Details user={user} />
    <br />
    <ScrollToTopButton />
  </Container>
);

export default DesktopView;
