import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import Icon from './Icon';
import Text from './Text';

const ButtonAnimation = posed.a({ pressable: true, init: { scale: 1 }, press: { scale: 0.9 } });

const Button = styled(ButtonAnimation)`
  &&& {
    min-height: 40px;
    min-width: 40px;
    padding: ${({ width, height }) => `${height}rem ${width}rem`};
    display: -webkit-inline-flex;
    display: inline-flex;
    margin: 0;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border: 2px solid #dce1e1;
    color: ${({ theme: { colors } }) => colors.primaryColor};
    text-decoration: none;
    font-size: ${({ size, theme: { fonts } }) => fonts.size[size || 'medium']};
    font-weight: 600;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: ${({ theme: { colors }, backgroundColor }) =>
      backgroundColor ? colors[backgroundColor] : colors.white};
    cursor: pointer;
    p {
      padding-left: 12px;
    }
    :hover {
      border-color: ${({ theme: { colors } }) => colors.primaryColor};
      color: ${({ theme: { colors } }) => colors.primaryColor};
      text-decoration: none;
      fill: #262959;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16);
      transform: translateY(-1px);
    }
    :active {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
      transform: translateY(0px);
    }
  }
`;

const SecondaryButton = ({ icon, name, width, height, textProps, iconSize, ...props }) => (
  <Button className="button" width={width} height={height} {...props}>
    {icon && <Icon name={icon} size={iconSize || 1} />}
    <Text {...textProps}>{name}</Text>
  </Button>
);

SecondaryButton.defaultProps = {
  width: 1,
  height: 1,
};

SecondaryButton.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SecondaryButton;
