import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { MdChevronLeft } from 'react-icons/md';
import { first } from 'lodash';
import client from '../../../utils/apolloClient';

import { Text, Modal, FlexRows } from '../../../components/elements';
import Loading from '../../../components/global/Loading';
import MobilePlace from './MobilePlace';

const Container = styled(Modal)``;

const ModalHeader = styled(FlexRows)`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const socialQuery = gql`
  query social($input: SearchInput) {
    search_my_social(input: $input) {
      social_listing_aggregation {
        total_pages
        total_size
        social_listing {
          social_id
          object_id
          object_type
          type
        }
      }
      place_listing {
        place_id
        name
        tagline
        description
        address_line_1
        cuisine_style {
          name
          description
        }
        business_type {
          name
          description
        }
        city
        state
        country
        post_code
      }
      image_listing {
        image_id
        object_id
        url
      }
    }
  }
`;

const DesktopView = ({ isActive, onClose }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Container isActive={isActive}>
      <ModalHeader justify="space-between">
        <a onClick={onClose}>
          <Text color="white">
            <MdChevronLeft size={25} />
          </Text>
        </a>
        <Text className="is-uppercase" align="center" color="white" weight="bold" size="medium">
          MY BOOKMARK - PLACES
        </Text>
        <Text />
      </ModalHeader>
      <div className="modal-card">
        <Query
          client={client.clientPrivate}
          query={socialQuery}
          variables={{
            input: {
              filter: {
                social_filter: { user_id: userId, object_type: 'PLACE', type: 'BOOKMARK' },
              },
            },
          }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return 'error';
            }
            const {
              search_my_social: {
                place_listing,
                image_listing,
                social_listing_aggregation: { social_listing },
              },
            } = data;

            if (social_listing.length === 0) {
              return (
                <Container>
                  <Text size="medium" color="darkGrey">
                    No saved places yet!
                  </Text>
                  <Text size="tiny" color="coolGrey">
                    Add Places to your Bookmarks for easy reference.
                  </Text>
                </Container>
              );
            }

            return (
              <div className="modal-card-body">
                <div className="columns is-multiline is-gapless">
                  {social_listing.map(item => (
                    <div className="column is-6" key={item.social_id}>
                      <MobilePlace
                        place={first(
                          place_listing.filter(place => place.place_id === item.object_id),
                        )}
                        image={first(
                          image_listing.filter(image => image.object_id === item.object_id),
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    </Container>
  );
};

export default DesktopView;
