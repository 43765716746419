import { useEffect } from 'react';
import publicIp from 'public-ip';
import iplocation from 'iplocation';
import Geocode from 'react-geocode';
import { first, forEach, isNull } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import config from './config';

Geocode.setApiKey('AIzaSyAVuL31AF_5ea38yqssYXT33x_eN38I-UM');
const getIpAddress = async () => {
  try {
    const ip = await publicIp.v4();
    return ip;
  } catch (error) {
    console.log(error);
  }
};

const normalizePlaceData = async result => {
  const components = {};
  await forEach(result.address_components, addressComponent => {
    forEach(addressComponent.types, type => {
      components[type] = addressComponent.long_name;
    });
  });

  const finalData = {
    addressLine1: `${components.street_number ? components.street_number : ''} ${
      components.route ? components.route : ''
    }`,
    addressLine2: result.formatted_address,
    city: components.locality ? components.locality : '',
    state: components.administrative_area_level_1 ? components.administrative_area_level_1 : '',
    postcode: components.postal_code ? components.postal_code : '',
    country: components.country ? components.country : '',
    latitude: result.geometry.location.lat.toString(10),
    longitude: result.geometry.location.lng.toString(10),
  };

  return finalData;
};

const useHandleLocation = coords => {
  const updateCurrentLocation = useStoreActions(state => state.global.updateCurrentLocation);

  const isHandleCurrentLocation = useStoreState(state => state.global.isHandleCurrentLocation);

  useEffect(() => {
    async function resolveLocation() {
      const location = null;
      if (coords && !isNull(coords)) {
        const { latitude, longitude } = coords;
        Geocode.fromLatLng(latitude, longitude).then(
          async response => {
            const address = response.results;
            const final = await normalizePlaceData(first(address));
            updateCurrentLocation(
              final.country !== 'Australia' ? config.defaultCurrentLocation : final,
            );
          },
          error => {
            console.error('error', error);
          },
        );
        return isNull(location) && location && location.country !== 'Australia'
          ? config.defaultCurrentLocation
          : location;
      }
      const ipAddress = await getIpAddress();
      iplocation(ipAddress, [], (error, res) => {
        if (error) {
          console.log(error);
        }
        if (!isNull(res)) {
          Geocode.fromLatLng(res.latitude, res.longitude).then(
            async response => {
              const address = response.results;
              const final = await normalizePlaceData(first(address));
              updateCurrentLocation(
                final.country !== 'Australia' ? config.defaultCurrentLocation : final,
              );
            },
            error1 => {
              console.error('error', error1);
            },
          );
        }
      });
      return null;
    }
    if (isHandleCurrentLocation) {
      resolveLocation();
    }
  }, [coords, isHandleCurrentLocation, updateCurrentLocation]);
  return null;
};

export default useHandleLocation;
