import React from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, FlexRows } from '../../../components/elements';
import colors from '../../../theme/styles/colors';

const Container = styled.div`
  padding: 1rem;
  margin-top: 0.5rem;
  background-color: ${colors.white};
`;

const PaymentDetails = ({ handleCompleteBooking, loading, back }) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const isLoggedIn = useStoreState(state => state.auth.isLoggedIn);
  const handleOnClick = () => {
    if (!isLoggedIn) {
      setIsActiveModal({ value: true, path: '' });
    } else {
      handleCompleteBooking();
    }
  };
  return (
    <Container>
      {/* <Text size="large" weight="semibold" paddingBottom={1}>
        Payment Details
      </Text>

      <Text size="large" weight="semibold" paddingBottom={1}>
        Billing Information
      </Text> */}
      <FlexRows justify="space-between">
        <div>
          <Button
            name="Back"
            backgroundColor="primaryColor"
            textProps={{
              color: 'white',
              weight: 'semibold',
              size: 'small',
            }}
            onClick={back}
          />
        </div>
        {/* <div>
          <Button
            className={loading && 'is-loading'}
            name="Complete Booking"
            backgroundColor="primaryColor"
            textProps={{
              color: loading ? 'primaryColor' : 'white',
              weight: 'semibold',
              size: 'small',
            }}
            onClick={handleOnClick}
          />
        </div> */}
      </FlexRows>
    </Container>
  );
};

export default PaymentDetails;
