import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import MobileForm from './MobileForm';

const Container = styled.div`
  background: ${({ theme: { colors } }) => colors.lightGreyBeta};
  height: 100vh;
`;

const MobileView = ({
  place,
  placeReview,
  isCreated,
  uploadedImages,
  reviewLoading,
  onSubmit,
  history,
}) => {
  return (
    <Container>
      <MobileForm
        place={place}
        loading={reviewLoading}
        history={history}
        onSubmit={onSubmit}
        placeReview={placeReview}
        isCreated={isCreated}
        uploadedImages={uploadedImages}
      />
    </Container>
  );
};

export default withRouter(MobileView);
