import React from 'react';
import styled from 'styled-components';
import { MdDone } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';

import { FlexRows, Text } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';

const Header = styled(FlexRows)`
  padding: 0rem 0rem;
  width: 100%;
  .pickup-time {
    margin-right: 11rem;
  }
`;

const Margin = styled.div`
  margin-top: 1rem;
  margin-bottom: ${({ checkBox }) => (checkBox === 'scheduleMode' ? '0rem' : '10rem')};
`;

const Select = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  select {
    width: inherit;
  }
`;

const PickupTime = styled.div`
  padding-bottom: 2rem;
  border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const array = [
  { key: 'asap', value: 'As Soon As Possible (ASAP)' },
  { key: 'scheduleMode', value: 'Schedule your preferred time' },
];

const PickUpTime = ({
  days,
  timeRange,
  checkBox,
  selectedDate,
  selectedTime,
  handleCheck,
  handleSelectDate,
  handleSelectTime,
  onBack,
}) => {
  return (
    <>
      <header className="modal-card-head">
        <Header justify="space-between">
          <IoMdClose size={25} onClick={onBack} />
          <Text size="large" weight="semibold" paddingBottom={0.5} className="pickup-time">
            Pickup Time
          </Text>
        </Header>
      </header>
      <div className="modal-card-body">
        <>
          <br />
          <Margin className="margin" checkBox={checkBox}>
            {array.map(item => (
              <PickupTime
                onClick={() => {
                  handleCheck(item.key);
                  if (item.key === 'scheduleMode') {
                    if (days.length !== 0) {
                      handleSelectTime(days[0]);
                    }
                    if (timeRange.length !== 0) {
                      handleSelectDate(timeRange[0]);
                    }
                  }
                }}
              >
                <Text size="medium" weight="semibold" paddingBottom={0.5}>
                  {item.value}
                </Text>
                {checkBox === item.key && <MdDone size={25} color={colors.primaryColor} />}
              </PickupTime>
            ))}
          </Margin>
          {checkBox === 'scheduleMode' && (
            <>
              <Text color="primaryColor" size="small" paddingBottom={0.3}>
                Select date
              </Text>
              <Select className="select">
                <select value={selectedDate} onChange={e => handleSelectDate(e.target.value)}>
                  {days.map(item => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </Select>
              <Text color="primaryColor" size="small" paddingBottom={0.3}>
                Select time
              </Text>
              <Select className="select">
                <select value={selectedTime} onChange={e => handleSelectTime(e.target.value)}>
                  {timeRange.map(item => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </Select>
            </>
          )}
        </>
      </div>
    </>
  );
};

export default PickUpTime;
