import { createGlobalStyle } from 'styled-components';
import fonts from './styles/fonts';
import colors from './styles/colors';

export const elements = { mobileBreakpoint: 800, ipadBreakpoint: 1024 };

const theme = { primaryColor: colors.primaryColor, colors, fonts };

export default theme;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color:#ffffff;
  }
  


  
   
   &&&{ 
  
     
     .react-autosuggest__input--focused {
      outline: none;
    }
    
    .react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
      display: none;
    }
    
    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 12px;
      border-bottom: 1px solid #f6f6f6 !important;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color:#f6f6f6;
    }

    
    
    }
`;
