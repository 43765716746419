import React from 'react';
import styled from 'styled-components';

import { UploadImage } from '../../components/elements';

const Container = styled.div``;

const Images = ({ setFieldValue, values }) => {
  return (
    // const handleRemove = url => {
    //   setValues({
    //     ...values,
    //     image: parseImage(values.image.filter(item => item.url !== url)),
    //   });
    // };

    <Container>
      <div className="columns is-multiline">
        <UploadImage
          totalNumberOfUploadedImage={0}
          colWidth={3}
          s3UploadPath="place/"
          metaData={{
            place_id: values.place_id,
            user_id: '2290802a-c911-4718-a829-5edc420e0d05',
          }}
          setFieldValue={setFieldValue}
        />
      </div>
    </Container>
  );
};
export default Images;
