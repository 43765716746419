import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { GoLocation } from 'react-icons/go';
import { times, first, isNull } from 'lodash';
import { useStoreState } from 'easy-peasy';

import { isOpen, parseSocialPlace, getKeywordTagline } from '../../../../utils/helpers';

import { Text, FlexRows, Icon } from '../../../../components/elements';
import ImageView from './ImageView';

import PlaceFollowButton from '../../../../components/PlaceFollowButton';
import colors from '../../../../theme/styles/colors';
import PlaceSocial from './PlaceSocial';

const Container = styled.div`
  /* padding: 1rem; */
  &&& {
    background-color: ${({ theme: { colors: themeColors } }) => themeColors.white};
    margin-bottom: 1rem;
    /* :hover {
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
      transition: box-shadow 0.5s ease-out;
    } */
    .icon {
      justify-content: flex-start;
    }
  }
`;

const Span = styled.span`
  color: ${({ theme: { colors: color } }) => color.primaryColor};
`;

const CardFooter = styled.div`
  position: relative;
`;

const SubTitleWrapper = styled.div`
  padding-top: 0.3rem;
`;

const TaglineWrapper = styled.div`
  padding-top: 0rem;
`;

const AddressWrapper = styled.div`
  padding-top: 0.8rem;
`;
const Wrapper = styled(FlexRows)`
  &&& {
    padding: 0.5rem 0rem;
    border: 0;
    padding-bottom: 0rem;
  }
`;
const KeywordsWrapper = styled.div`
  padding-top: 0rem;
`;

const ReviewWrapper = styled.div`
  padding-top: 0.8rem;
`;

const IconWrapper = styled.div`
  position: relative;
  top: 6.3rem;
  left: 2rem;
`;

const BusinessCard = ({ place, socials, follow, deal }) => {
  const { userId } = useStoreState(state => state.auth);
  const [isOpenPlace, setIsOpenPlace] = useState(false);

  useEffect(() => {
    const fetData = async () => {
      const data = await isOpen(place);
      setIsOpenPlace(data);
    };
    fetData();
  }, [place, place.place_id]);

  const socialArray = socials.length !== 0 ? first(socials).total_social_count : [];
  const social = parseSocialPlace(socialArray, userId);
  const followData = follow.length !== 0 ? first(follow) : null;
  const dealCounts = deal.length !== 0 ? first(deal).count : 0;

  return (
    <Container>
      <article className="media">
        <figure className="media-left">
          <ImageView place={place} />
          <CardFooter>
            <PlaceSocial
              objectId={place.place_id}
              objectType="PLACE"
              likeActive={social.like.active}
              bookMarkActive={social.bookmark.active}
              likeCountInt={social.like.count}
              bookMarkCountInt={social.bookmark.count}
              likeSocialIdS={social.like.id}
              bookMarkSocialIdS={social.bookmark.id}
            />
          </CardFooter>
        </figure>
        <div className="media-content">
          <div>
            <Link to={`/place/${place.place_id}`}>
              <Text className="is-capitalized" size="mlarge" weight="semibold">
                {place.name}
              </Text>
            </Link>
            <SubTitleWrapper>
              <Text color="darkGrey" size="small">
                {parseFloat(place.distance).toFixed(2)} Kms{' '}
                {place.price_range && place.price_range !== 0 && '|'}{' '}
                {place.price_range && times(place.price_range).map(() => '$')}{' '}
                {dealCounts !== 0 && `| ${dealCounts} deals Available`}
                {isOpenPlace && (
                  <a>
                    | <Span>Open Now</Span>
                  </a>
                )}
              </Text>
            </SubTitleWrapper>

            {place.tagline && (
              <TaglineWrapper>
                <Text color="darkGrey" size="small">
                  {place.tagline}
                </Text>
              </TaglineWrapper>
            )}
          </div>
          <AddressWrapper className="is-flex">
            <span className="icon" style={{ marginRight: '0.3rem', marginLeft: '-0.15rem' }}>
              <GoLocation size={18} color={colors.specialGrey} />
            </span>
            <Text size="small" color="dark" weight="regular">
              {place.address_line_1}, {place.city}, {place.state}
            </Text>
          </AddressWrapper>
          <KeywordsWrapper>
            <Wrapper justify="flex-start">
              <div className="is-flex">
                <span className="icon" style={{ marginRight: '0.3rem' }}>
                  <Icon size={0.8} name="featureIcon" />
                </span>
                <Text color="darkGrey" size="small">
                  {getKeywordTagline(
                    place.business_type,
                    place.cuisine_style,
                    place.feature,
                    place.dietary_information,
                  )}
                </Text>
              </div>
            </Wrapper>
          </KeywordsWrapper>
          <ReviewWrapper className="is-flex">
            <span className="icon" style={{ marginRight: '0.3rem' }}>
              <Icon size={1} name="reviewAPlaceBlue" />
            </span>
            <Link to={`/place-review/${place.place_id}`}>
              <Text size="small" color="primaryColor" weight="regular">
                Review this place
              </Text>
            </Link>
          </ReviewWrapper>
        </div>
        <div className="media-right">
          <PlaceFollowButton
            isActive={!isNull(followData)}
            activeFollowId={isNull(followData) ? '' : followData.follow_id}
            objectId={place.place_id}
            objectType="PLACE"
            type="FOLLOW"
          />
          <IconWrapper>
            <Text color="coolGrey">
              <MdKeyboardArrowRight size={30} style={{ marginLeft: '2rem' }} />
            </Text>
          </IconWrapper>
        </div>
      </article>
    </Container>
  );
};

export default withRouter(BusinessCard);
