import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { Text, FlexColumns, FlexRows } from '../../components/elements';
import config from '../../utils/config';
import { getEncodedImage } from '../../utils/s3';
import theme from '../../theme';

const Container = styled.div`
  padding: 1rem 0.5rem;
  border-top: 3px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;
const Heading = styled.div``;
const ScrollView = styled.div`
  overflow: auto;
  width: 100%;
  padding: 1rem 0rem;
`;

const Items = styled(FlexRows).attrs({ justify: 'space-between', align: 'flex-start' })``;

const Item = styled(FlexColumns).attrs({ justify: 'flex-start', align: 'flex-start' })`
  background: ${({ theme: { colors } }) => colors.white};
  width: 40%;
  margin-right: 1rem;
`;

const Figure = styled.figure`
  && {
    width: 9rem;
    img {
      border-radius: 4px;
      height: 9rem;
    }
  }
`;

const eventsQuery = gql`
  query {
    search_events {
      event_listing {
        place_id
        event_id
        status
        display_order
        listing_type
        featured
        default_url
        start
        end
        place_name
        venue_name
        name
        description
        tagline
        slug
        minimum_price
        maximum_price
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        default_image_url
      }
    }
  }
`;

const ImageView = ({ image, id }) => {
  if (image === null || image === '') {
    return (
      <Link to={`/place/${id}`}>
        <Figure>
          <img src={config.dummyImage} alt={image} />
        </Figure>
      </Link>
    );
  }
  return (
    <Figure>
      <img src={getEncodedImage(image, 307, 307)} alt={image} />
    </Figure>
  );
};

const Events = () => {
  return (
    <Container>
      <Heading>
        <Text color="secondaryColor" size="smedium" weight="semibold">
          Popular Events
        </Text>
      </Heading>
      <ScrollView>
        <Query query={eventsQuery} fetchPolicy="network-only">
          {({ data, loading, error }) => {
            if (error) {
              return 'error';
            }

            if (loading) {
              return (
                <FlexColumns style={{ padding: '2rem' }}>
                  <ReactLoading type="spin" color={theme.primaryColor} width={50} height={50} />
                </FlexColumns>
              );
            }
            if (!data.search_events) {
              return (
                <FlexColumns style={{ padding: '2rem' }}>
                  <ReactLoading type="spin" color={theme.primaryColor} width={50} height={50} />
                </FlexColumns>
              );
            }
            const events = data.search_events.event_listing;
            console.log('data', data);
            return (
              <Items>
                {events.map(event => (
                  <Item key={event.event_id}>
                    <ImageView id={event.event_id} image={event.default_image_url} />
                    <div>
                      <Text
                        className="is-capitalized"
                        size="tiny"
                        weight="semibold"
                        lineHeight="normal"
                      >
                        {capitalize(event.name)}
                      </Text>
                      <Text
                        className="is-capitalized"
                        size="ultraMicro"
                        color="darkGrey"
                        lineHeight="normal"
                      >
                        {capitalize(event.place_name)}
                      </Text>
                    </div>
                  </Item>
                ))}
              </Items>
            );
          }}
        </Query>
      </ScrollView>
    </Container>
  );
};

export default Events;
