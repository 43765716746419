import React from 'react';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/Layout';
import Analytics from '../../components/global/Analytics';

const AllBadges = ({ history, match }) => (
  <Layout history={history} match={match} bottomNavigationEnable={false}>
    <Analytics pageName="AllBadges" category="allbadge" title="Badge-List">
      <div className="is-hidden-desktop">
        <MobileView />
      </div>
      <div className="is-hidden-touch">
        <DesktopView />
      </div>
    </Analytics>
  </Layout>
);

export default AllBadges;
