import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { MdLocationOn } from 'react-icons/md';
import { FaListUl } from 'react-icons/fa';

import { isUndefined, capitalize } from 'lodash';
import MenuCategoryTabs from './components/desktop/MenuCategoryTabs';
// import Breadcrumb from './components/desktop/Breadcrumb';
import Filters from './components/desktop/Filters';
import BusinessCard from './components/desktop/BusinessCard';
// import Sort from './components/desktop/Sort';
import {
  Text,
  FlexRows,
  Button,
  Pagination,
  MapView,
  ScrollToTopButton,
  Pager,
} from '../../components/elements';
import Tags from './components/desktop/Tags';
import emptyImage from '../../assets/images/empty.svg';

const Container = styled(Pager)`
  padding-top: 2rem;
`;

const TopWrapperView = styled.div`
  && {
    padding: 0.5rem 1rem;
    margin: 0rem 0rem;
    /* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06); */
  }
`;
const EventWrapper = styled.div`
  && {
    padding: 1rem;
    border-radius: 4px;
    /* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06); */
  }
`;

const FilterWrapper = styled.div``;
const PaginationWrapper = styled.div`
  padding: 1rem;
`;

const getMapLocation = dta => {
  const data = dta.map(item => ({
    id: item.event_id,
    titleHead: moment(item.start).format('llll'),
    title: `${item.name}`,
    subtitle: item.place_name,
    subtitle1: `${item.address_line_1}, ${item.city}`,
    imageObject: {
      object_id: item.event_id,
      object_type: ['EVENT'],
      parent_id: item.place_id,
      parent_type: ['PLACE'],
    },
    latitude: parseFloat(item.latitude),
    longitude: parseFloat(item.longitude),
    type: 'EVENT',
  }));
  return data;
};

const DesktopView = ({
  data,
  socials,
  filterStateInputs,
  filterFuncInputs,
  currentLocation,
  handlePageClick,
}) => {
  const [events, setEvents] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [mapActive, setMapActive] = useState(false);

  useEffect(() => {
    setEvents(
      !isUndefined(data.search_events) && !isUndefined(data.search_events.event_listing)
        ? data.search_events.event_listing
        : [],
    );
    setTotalPage(!isUndefined(data.search_events) && data.search_events.total_pages);
  }, [data.search_events]);

  return (
    <Container className="hero">
      <div>
        <Text className="is-capitalized" size="blarger" weight="semibold" paddingBottom={1}>
          Events Around {capitalize(currentLocation.city)}
        </Text>
      </div>
      <MenuCategoryTabs handleTabDateFilter={filterFuncInputs.handleTabDateFilter} />
      {events.length === 0 && (
        <FlexRows>
          <div style={{ marginTop: '1rem' }}>
            <figure style={{ width: '40rem' }}>
              <img src={emptyImage} alt="empty" />
            </figure>
          </div>
        </FlexRows>
      )}
      <br />
      {events.length !== 0 && (
        <>
          {/* <TopWrapper>
            <div className="columns is-gapless is-vcentered">
              <div className="column">
                <Sort />
              </div>
              <div className="column is-three-quarters">
                
              </div>
            </div>
          </TopWrapper> */}
          <FilterWrapper className="columns">
            <div className="column">
              <Filters filterFuncInputs={filterFuncInputs} />
            </div>
            <EventWrapper className="column is-three-quarters">
              <TopWrapperView>
                <FlexRows justify="space-between" align="center">
                  <Tags filterStateInputs={filterStateInputs} filterFuncInputs={filterFuncInputs} />
                  <div>
                    <Button
                      height={0}
                      name={mapActive ? 'Show List View' : 'Show Map View'}
                      textProps={{
                        size: 'tiny',
                        color: 'darkGrey',
                      }}
                      onClick={() => setMapActive(!mapActive)}
                    >
                      {' '}
                      {!mapActive ? <MdLocationOn size={20} /> : <FaListUl size={15} />}&nbsp;&nbsp;
                    </Button>
                  </div>
                </FlexRows>
              </TopWrapperView>
              {mapActive ? (
                <MapView data={getMapLocation(events)} />
              ) : (
                <>
                  {events.map(event => (
                    <BusinessCard
                      key={event.event_id}
                      event={event}
                      socials={socials.filter(item => item.object_id === event.event_id)}
                    />
                  ))}
                </>
              )}

              <PaginationWrapper>
                <Pagination
                  currentPage={currentPage}
                  pageCount={totalPage}
                  handlePageClick={value => {
                    handlePageClick(value);
                    setCurrentPage(value.selected + 1);
                  }}
                />
              </PaginationWrapper>
            </EventWrapper>
          </FilterWrapper>
        </>
      )}
      <ScrollToTopButton />
    </Container>
  );
};

export default DesktopView;
