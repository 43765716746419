import { useState, useEffect } from 'react';
import { first, isNull } from 'lodash';
import gql from 'graphql-tag';
import client from './apolloClient';

const captionQuery = gql`
  query captions($input: CaptionInput) {
    fetch_caption(input: $input) {
      object_id
      caption_1
      caption_2
      caption_3
      parent_id
      color_code
    }
  }
`;

function useFetchUser({ userId }) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  return {
    loading,
    error,
    user,
  };
}

export default useFetchUser;
