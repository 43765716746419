import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import { useStoreActions, useStoreState } from 'easy-peasy';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { getApiImages } from '../../../../utils/reusableQuery';
import { Image, Button, Icon, Flex, FlexRows } from '../../../../components/elements';
import blankImage from '../../../../assets/images/blank.png';
import { getEncodedImage } from '../../../../utils/s3';
import PhotoPreviewModal from '../../../ItemPhotos/PhotoPreviewModal';
import config from '../../../../utils/config';

const Figure = styled.figure`
  &&& {
    .figure-img {
      opacity: 0.5;
    }
    img {
      padding: 0.5em;
      border-radius: 12px;
    }
  }
`;

const SliderContainer = styled.div`
  justify-content: flex-start;
  &&& {
    .slick-track {
      width: 100%;
    }
  }
`;

const AddPhotoWrapper = styled.div`
  position: relative;
  right: 2rem;
  float: right;
  bottom: 4rem;
`;

const ButtonWrapper = styled(FlexRows)`
  padding: 0rem 0.5rem;
`;

const FigureEmpty = styled.figure`
  &&& {
    padding-top: 20%;
    opacity: 0.5;
  }
`;

const ImageView = ({ placeId, item, history }) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const isLoggedIn = useStoreState(state => state.auth.isLoggedIn);
  const [images, setImage] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [preViewImage, setPreViewImage] = useState({});
  const [whichImage, setWhichImage] = useState(0);
  const [currentSliderImage, setCurrentSliderImage] = useState(0);

  const handleOnClickAddPhotoButton = path => {
    if (!isLoggedIn) {
      setIsActiveModal({ value: true, path });
    } else {
      history.push(path);
    }
  };

  useEffect(() => {
    const get = async () => {
      const data = await getApiImages({
        object_id: item.item_id,
        object_type: ['ITEM'],
        parent_id: placeId,
        parent_type: ['PLACE'],
        links_to_id: [item.item_id],
        links_to_type: ['ITEM'],
      });
      setImage(data);
    };
    get();
  }, [item.item_id, placeId]);

  if (images === null) {
    return (
      <FigureEmpty className="image is-3by1">
        <Image source={blankImage} objectFit="contain" />
      </FigureEmpty>
    );
  }

  if (images.length === 0) {
    return (
      <>
        <FigureEmpty className="image is-5by4">
          <Image source={config.dummyImage} objectFit="contain" />
        </FigureEmpty>
        <AddPhotoWrapper>
          <Button
            name="Add Photo"
            icon="photoAddSolid"
            textProps={{ size: 'micro' }}
            borderRadius={20}
            onClick={() => handleOnClickAddPhotoButton(`/add-item-photos/${item.item_id}`)}
          >
            <span className="icon is-small">
              <Icon name="photoAddSolid" />
            </span>
          </Button>
        </AddPhotoWrapper>
      </>
    );
  }

  if (images.length === 1) {
    return (
      <>
        <div className="columns is-vcentered is-mobile">
          {images.map((image, index) => (
            <div className="column is-4">
              <a
                onDoubleClick={() => {
                  setModalActive(true);
                  setPreViewImage(image);
                  setWhichImage(index);
                }}
              >
                <Figure className="image is-5by4">
                  <Image source={getEncodedImage(image.url, 470, 376)} objectFit="contain" />
                </Figure>
              </a>
            </div>
          ))}

          <div className="column is-4 is-mobile">
            <Button
              name="Add Photo"
              icon="photoAddSolid"
              textProps={{ size: 'micro' }}
              borderRadius={20}
              onClick={() => history.push(`/add-item-photos/${item.item_id}`)}
            >
              <span className="icon is-small">
                <Icon name="photoAddSolid" />
              </span>
            </Button>
          </div>
        </div>
        {modalActive && (
          <PhotoPreviewModal
            whichImage={whichImage}
            item={item}
            isActive={modalActive}
            images={images}
            preViewImage={preViewImage}
            isClose={() => setModalActive(false)}
            onSliderChange={value => {
              setPreViewImage(images[value]);
            }}
          />
        )}
      </>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    // centerMode: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    adaptiveHeight: true,
  };

  return (
    <>
      <SliderContainer>
        <Slider {...settings}>
          {images.map((image, index) => (
            <a
              onDoubleClick={() => {
                setModalActive(true);
                setPreViewImage(image);
                setWhichImage(index);
              }}
            >
              <Figure className="image is-5by4">
                <Image source={getEncodedImage(image.url, 470, 376)} objectFit="contain" />
              </Figure>
            </a>
          ))}
        </Slider>
      </SliderContainer>
      <ButtonWrapper justify="space-between">
        {images.length > 3 && (
          <Button
            height={0}
            name="See all Photos"
            icon="photoAddSolid"
            borderRadius={20}
            textProps={{ size: 'micro' }}
            onClick={() => history.push(`/item-photos/${placeId}/${item.item_id}`)}
          />
        )}
        <Button
          height={0}
          name="Add Photo"
          icon="photoAddSolid"
          borderRadius={20}
          textProps={{ size: 'micro' }}
          onClick={() => handleOnClickAddPhotoButton(`/add-item-photos/${item.item_id}`)}
        >
          <span className="icon is-small">
            <Icon name="photoAddSolid" />
          </span>
        </Button>
      </ButtonWrapper>

      {modalActive && (
        <PhotoPreviewModal
          whichImage={whichImage}
          currentSliderImage={currentSliderImage}
          item={item}
          isActive={modalActive}
          images={images}
          preViewImage={preViewImage}
          isClose={() => setModalActive(false)}
          onSliderChange={(value, next) => {
            setCurrentSliderImage(next);
          }}
        />
      )}
    </>
  );
};

export default withRouter(ImageView);
