import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from './Button';
import { IconButton } from '.';
import { trackEvent } from '../../utils/analytics';

const ButtonStyle = styled(Button)`
  && {
    border: 0;
  }
`;

const ToggleableButton = ({
  type,
  name,
  defaultIcon,
  activeIcon,
  initialValue,
  triggerOnClickFunction,
  ...props
}) => {
  const [enable, setEnable] = React.useState(initialValue);

  const onClick = () => {
    setEnable(!enable);
    triggerOnClickFunction();
  };

  // eslint-disable-next-line no-return-assign
  return (
    <>
      {type === 'primary' ? (
        <IconButton
          type={type}
          iconName={enable ? activeIcon : defaultIcon}
          buttonName={name}
          onClick={onClick}
          {...props}
        />
      ) : (
        <ButtonStyle
          name={name}
          leftIcon={enable ? activeIcon : defaultIcon}
          onClick={onClick}
          {...props}
        />
      )}
    </>
  );
};

ToggleableButton.defaultProps = {
  initialValue: false,
  name: '',
  activeIcon: '',
  defaultIcon: '',
  triggerOnClickFunction: () => '',
};

ToggleableButton.propTypes = {
  initialValue: PropTypes.bool,
  name: PropTypes.string,
  activeIcon: PropTypes.string,
  defaultIcon: PropTypes.string,
  triggerOnClickFunction: PropTypes.func,
};

export default ToggleableButton;
