import React from 'react';
import styled from 'styled-components';
import { MdChevronLeft } from 'react-icons/md';
import { Modal, Text, FlexRows } from '../../../../components/elements';
import AddPlaceForm from './AddPlaceForm';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const ModalHeaderLeftWrapper = styled.div`
  position: absolute;
  left: 0.5rem;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const SubHeader = styled(FlexRows)`
  padding: 0.5rem;
`;

const AddPlaceModal = ({ isActive, onClose, placeNameValue, onSubmit }) => {
  return (
    <Modal isActive={isActive}>
      <ModalHeader align="center">
        <ModalHeaderLeftWrapper>
          <a onClick={onClose}>
            <Text color="white">
              <MdChevronLeft size={30} />
            </Text>
          </a>
        </ModalHeaderLeftWrapper>
        <FlexRows>
          <Text className="is-uppercase" weight="semibold" size="lmedium" color="white">
            Place Name
          </Text>
        </FlexRows>
      </ModalHeader>
      <Content>
        <SubHeader>
          <Text className="is-uppercase" weight="semibold" size="lmedium" color="primaryColor">
            Add a new place
          </Text>
        </SubHeader>
        <AddPlaceForm placeNameValue={placeNameValue} onSubmit={onSubmit} />
      </Content>
    </Modal>
  );
};

export default AddPlaceModal;
