import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from '../../elements';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Logo = () => {
  return (
    <Link to="/">
      <Container>
        <Text color="white" size="big" weight="bold" letterSpacing="loose">
          Kravein
        </Text>
        <p className="is-size-4 has-text-white">Create your experience</p>
      </Container>
    </Link>
  );
};

export default Logo;
