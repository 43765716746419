import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { first } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { getApiImages } from '../../../../utils/reusableQuery';
import { Text, Image, FlexRows, Icon, IconProvider } from '../../../../components/elements';
import dummy from '../../../../assets/images/food.jpg';
import blankImage from '../../../../assets/images/blank.png';
import SocialActionButton from '../../../../components/SocialActionButton';
import { getEncodedImage } from '../../../../utils/s3';

const Container = styled.div`
  &&& {
    background-color: ${({ theme: { colors } }) => colors.white};
    cursor: pointer;
    height: 100%;
    margin:0rem 1rem;
    border-radius: 4px;
    :hover {
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
      transition: box-shadow .5s ease-out;
      /* background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha}; */
    }
  }
`;

const Figure = styled.figure`
  && {
    img {
      border-radius: 4px;
    }
  }
`;

const Wrapper = styled.div`
  &&& {
    padding: 1rem;
    border: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
const IconWrapper = styled.div`
  padding: 0.2rem 0rem;
`;

const Button = styled.a`
  &&& {
    min-height: 40px;
    min-width: 30px;
    padding: 8px 40px;
    display: -webkit-inline-flex;
    display: inline-flex;
    margin: 0;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border: 2px solid #dce1e1;
    color: ${({ theme: { colors } }) => colors.primaryColor};
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    cursor: pointer;
    p {
      padding-left: 12px;
    }
    :hover {
      border-color: ${({ theme: { colors } }) => colors.primaryColor};
      color: ${({ theme: { colors } }) => colors.primaryColor};
      text-decoration: none;
      fill: #262959;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16);
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
    }
  }
`;

const IconsWrapper = styled(FlexRows)`
  padding: 1rem 0rem;
`;

const SocialActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'micro', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1.2 },
})``;

const ImageView = ({ image }) => {
  if (image === null) {
    return <Image source={dummy} />;
  }

  return <Image source={getEncodedImage(image, 394, 263)} />;
};

const ItemCard = ({ item, history }) => (
  <Container>
    <Wrapper>
      <Link to={`/item/${item.item_id}`}>
        <Text className="is-capitalized" size="large" weight="semibold" paddingBottom={0.2}>
          {item.name}
        </Text>
      </Link>
      {/* <Text size="tiny" color="dark" weight="semibold" paddingBottom={0.5}>
        {item.place_name}, {item.city}, {item.post_code}
      </Text> */}
      <IconWrapper>
        <div className="is-flex">
          <span className="icon">
            <IconProvider iconName="itemRatingGoldSolid" iconSize={1} />
          </span>
          <Text color="darkGrey" size="tiny" paddingBottom={1}>
            90% loved it
          </Text>
        </div>
      </IconWrapper>

      <Link to={`/item/${item.item_id}`}>
        <Figure className="image is-3by2">
          <ImageView image={item.default_image_url} />
        </Figure>
      </Link>
      <IconsWrapper justify="space-between">
        <SocialActionButtonStyle
          social={item.social}
          objectId={item.item_id}
          objectType="ITEM"
          type="TRIED"
          name="Tried"
          defaultIcon="triedGrey"
          activeIcon="triedBlue"
        />
        <SocialActionButtonStyle
          social={item.social}
          objectId={item.item_id}
          objectType="ITEM"
          type="LOVE_IT"
          name="Loved"
          defaultIcon="loveTheItemGrey"
          activeIcon="loveTheItemBlue"
        />
        <SocialActionButtonStyle
          social={item.social}
          objectId={item.item_id}
          objectType="ITEM"
          type="BOOKMARK"
          name="Bookmark"
          defaultIcon="bookmarkSolidGrey"
          activeIcon="bookmarkSolid"
        />
      </IconsWrapper>
      <FlexRows justify="space-around">
        <Button className="button" onClick={() => history.push(`/item-review/${item.item_id}`)}>
          <Icon name="reviewAPlace" size={1} /> <p>Review</p>
        </Button>
        <Button className="button">
          <Icon name="shareBlue" size={1} />
          <p>Share</p>
        </Button>
      </FlexRows>
    </Wrapper>
  </Container>
);

export default withRouter(ItemCard);
