import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Flex,
  FlexRows,
  Text,
  FlexColumns,
  Icon,
} from '../../components/elements';

const Container = styled.div`
  padding-top: 2rem;
`;

const Wrapper = styled(FlexRows)`
  padding: 2rem 0rem;
`;

const SignupButton = styled(Link)`
  padding-left: 1rem;
`;

const SocialWrapper = styled(FlexRows)`
  padding: 2rem 6rem;
`;

const OrWrapper = styled(FlexColumns)`
  margin: 0rem 1rem;
  margin-top: 2rem;
  border-top: 2px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const Or = styled(Text)`
  &&& {
    position: relative;
    padding: 0.5rem;
    border: 1px solid;
    z-index: 1;
    background-color: #fff;
    border-radius: 20px;
    bottom: 20px;
  }
`;

const MobileTop = () => (
  <Container>
    <Wrapper>
      <Text
        color="coolGrey"
        size="small"
        weight="semibold"
        letterSpacing="loose"
      >
        Already in Kravein ?
      </Text>
      <SignupButton to="/login">
        <Text
          className="is-uppercase"
          size="small"
          weight="semibold"
          color="primaryColor"
          letterSpacing="loose"
        >
          Sign in
        </Text>
      </SignupButton>
    </Wrapper>
    <Flex>
      <Text
        className="is-uppercase"
        color="coolGrey"
        size="small"
        weight="semibold"
        letterSpacing="loose"
      >
        Sign in using social
      </Text>
    </Flex>
    <SocialWrapper justify="space-around">
      <a>
        <Icon name="facebook" size={2} />
      </a>
      <a>
        <Icon name="twitter" size={2} />
      </a>
    </SocialWrapper>
    <OrWrapper>
      <Or
        className="is-uppercase"
        color="coolGrey"
        size="small"
        weight="semibold"
        letterSpacing="loose"
      >
        OR
      </Or>
      {/* <Hr /> */}
    </OrWrapper>
  </Container>
);

export default MobileTop;
