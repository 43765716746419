import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Text } from '../../../../components/elements';
import ItemCard from './ItemCard';
import ItemReviewActionButton from './ItemReviewActionButton';

const itemsQuery = gql`
  query itemsQuery($input: SearchInput) {
    search_items(input: $input) {
      item_listing {
        item_id
        place_id
        place_name
        name
        slug
        star_count
        loved_percentage
        tag
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        timezone
        default_image_url
        cuisine_style {
          name
          description
          display_order
        }
        item_type {
          name
          description
          display_order
        }
      }
    }
  }
`;

const Container = styled.div`
  padding-top: 1rem;
`;

const Header = styled.div`
  padding: 1rem 0rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const TrendingItems = ({ place, handleElement }) => {
  return (
    <Container>
      <Header>
        <Text size="large" weight="semibold">
          Trending Items
        </Text>
      </Header>
      <ItemReviewActionButton placeId={place.place_id} placeName={place.name} />
      <Query
        query={itemsQuery}
        variables={{
          input: {
            filter: {
              item_filter: {
                place_id: place.place_id,
                status: 'ACTIVE',
                approval_status: 'APPROVED',
              },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading }) => {
          if (loading) {
            return '';
          }
          if (!data) {
            return '';
          }
          if (!data.search_items) {
            return '';
          }

          if (!data.search_items.item_listing) {
            return '';
          }

          return <View items={data.search_items.item_listing} handleElement={handleElement} />;
        }}
      </Query>
    </Container>
  );
};

const View = ({ items, handleElement }) => {
  useEffect(() => {
    if (items.length === 0) {
      handleElement('itemElements');
    }
  }, [handleElement, items.length]);

  if (items.length === 0) {
    return null;
  }

  return (
    <div className="columns is-multiline is-8 is is-gapless">
      {items.map(item => (
        <div className="column is-4" key={item.item_id}>
          <ItemCard item={item} />
        </div>
      ))}
    </div>
  );
};

export default TrendingItems;
