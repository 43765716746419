/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { FieldArray } from 'formik';
import styled from 'styled-components';
import uuid from 'uuid';
import moment from 'moment';

import { FlexRows, Text } from '../../components/elements';
import TimePicker from '../../components/elements/TimePicker';

const Container = styled.div`
  &&& {
    .column {
      padding: 0.2rem;
    }
    .select {
      -webkit-appearance: none;
      align-items: center;
      border: 1px solid transparent;
      border-radius: 4px;
      box-shadow: none;
      display: inline-flex;
      font-size: 0.8rem !important;
      height: 2.24em;
      justify-content: flex-start;
      line-height: 1.5;
      padding: calc(0.375em - 1px) calc(0.625em - 1px);
      position: relative;
      vertical-align: top;
    }
  }
`;

const TextWrapper = styled.div`
  padding-left: 0.5rem;
`;
const Wrapper = styled.div`
  padding: 0.5rem;
  padding-bottom: 1rem;
`;
const options = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
const PlaceHours = ({ values }) => {
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [startSec, setStartSec] = useState(0);
  const [endSec, setEndSec] = useState(0);
  const [day, setDay] = useState('');
  const labelTextProps = {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  };
  const buttonTextProps = {
    className: 'is-uppercase',
    size: 'micro',
    color: 'primaryColor',
    weight: 'bold',
  };
  const disable = start === '' || end === '' || day === '';

  return (
    <Container>
      <TextWrapper>
        <Text {...labelTextProps}>Add Hours</Text>
      </TextWrapper>
      <br />

      <FieldArray
        name="business_hour"
        render={helpers => (
          <div>
            {values.business_hour.length !== 0 && (
              <Wrapper>
                {values.business_hour.map((item, index) => (
                  <FlexRows justify="space-between">
                    <Text className="is-capitalized" weight="semibold" size="small">
                      {item.day}
                    </Text>
                    <Text color="darkGrey" size="small">
                      {item.start} - {item.end}
                    </Text>
                    <a onClick={() => helpers.remove(index)}>
                      <Text {...buttonTextProps}>Remove</Text>
                    </a>
                  </FlexRows>
                ))}
              </Wrapper>
            )}
            <div className="columns is-mobile is-1">
              <div className="column is-3">
                <div className="select">
                  <select
                    style={{ fontSize: '11.5px' }}
                    value={day}
                    onChange={e => setDay(e.target.value)}
                  >
                    <option>Select</option>
                    {options.map(item => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="column is-3">
                <TimePicker
                  placeholder="Start time"
                  value={
                    start === '' || start === 'Invalid date' || start === null
                      ? ''
                      : moment(`April 17, 2020 ${start}`)
                  }
                  onChange={time => {
                    if (time === null) {
                      setStart('');
                      setStartSec(0);
                    } else {
                      setStart(moment(time).format('LT'));
                      setStartSec(
                        parseFloat(moment(time).hours()).toFixed(0) * 3600 +
                          parseFloat(moment(time).minutes()).toFixed(0) * 60,
                      );
                    }
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="column is-3">
                <TimePicker
                  placeholder="End time"
                  value={
                    end === '' || end === 'Invalid date' || end === null
                      ? ''
                      : moment(`April 17, 2020 ${end}`)
                  }
                  onChange={time => {
                    if (time === null) {
                      setEnd('');
                      setEndSec(0);
                    } else {
                      setEnd(moment(time).format('LT'));
                      setEndSec(
                        parseFloat(moment(time).hours()).toFixed(0) * 3600 +
                          parseFloat(moment(time).minutes()).toFixed(0) * 60,
                      );
                    }
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="column is-3">
                <a
                  onClick={async () => {
                    if (!disable) {
                      await helpers.push({
                        id: uuid(),
                        day,
                        start,
                        end,
                        start_in_sec: startSec,
                        end_in_sec: endSec,
                      });
                      setDay('');
                      setStart('');
                      setEnd('');
                    }
                  }}
                >
                  <Text {...buttonTextProps}>Add hours</Text>
                </a>
              </div>
            </div>
          </div>
        )}
      />
      <br />
    </Container>
  );
};

export default PlaceHours;
