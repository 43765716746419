import React from 'react';
import styled from 'styled-components';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { first } from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';

import { Text, Button, FlexRows } from '../../../../components/elements';
import GetImages from '../../../../components/GetImages';
import apolloClient from '../../../../utils/apolloClient';

const Wrapper = styled.article`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`;

const Container = styled.article`
  padding-bottom: 0.5rem;
  justify-content: center;
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const itemQuery = gql`
  query fetch_item($input: NavigateItemInput) {
    fetch_item(input: $input) {
      item_id
      place_id
      place_name
      name
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      timezone
    }
  }
`;

const searchReviewQuery = gql`
  query searchItemReview($itemReviewFilter: ItemReviewFilter) {
    search_item_review(input: { filter: { item_review_filter: $itemReviewFilter } }) {
      item_review_listing {
        place_id
        item_id
        item_review_id
        rating
        content
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const deleteItemReview = gql`
  mutation deleteItemReview($input: NavigateItemReviewInput) {
    delete_item_review(input: $input) {
      item_review_id
      error {
        description
      }
    }
  }
`;

const ReviewedItemHeader = ({ review, history }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Query
      query={itemQuery}
      variables={{ input: { item_id: review.item_id } }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return '';
        }
        if (error) {
          return '';
        }
        const { fetch_item } = data;
        const item = first(fetch_item);
        return (
          <Wrapper>
            <Container className="media">
              <figure className="media-left">
                <GetImages
                  name={item.name}
                  maskProps={{ width: 80, height: 80 }}
                  variables={{
                    object_type: ['ITEM'],
                    object_id: item.item_id,
                    links_to_id: [item.item_id],
                    links_to_type: ['ITEM'],
                  }}
                />
              </figure>
              <div className="media-content">
                <div className="content">
                  <div>
                    <Text
                      className="is-capitalized"
                      size="small"
                      weight="semibold"
                      lineHeight="normal"
                    >
                      {item.name}
                    </Text>
                    <Text size="micro" color="darkGrey" lineHeight="normal">
                      {item.place_name}
                      <br />
                      {item.address_line_1} <br /> {item.city}, {item.state} {item.post_code},{' '}
                      {item.country}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="media-right">
                <Text size="micro" color="darkGrey" lineHeight="normal">
                  {moment(review.audit.created_at).fromNow()}
                </Text>
              </div>
            </Container>
            <FlexRows justify="flex-start">
              <Mutation
                client={apolloClient.clientPrivate}
                mutation={deleteItemReview}
                variables={{
                  input: {
                    user_id: review.audit.created_by,
                    place_id: review.place_id,
                    item_id: review.item_id,
                    item_review_id: review.item_review_id,
                  },
                }}
                update={(client, { data: { delete_item_review } }) => {
                  const { clientPublic } = apolloClient;
                  const {
                    search_item_review: { item_review_listing },
                  } = clientPublic.readQuery({
                    query: searchReviewQuery,
                    variables: {
                      itemReviewFilter: { user_id: userId, draft: true, status: 'SUBMITTED' },
                    },
                  });
                  const finalReview = item_review_listing.filter(
                    edge => edge.item_review_id !== delete_item_review.item_review_id,
                  );
                  clientPublic.writeQuery({
                    query: searchReviewQuery,
                    variables: {
                      itemReviewFilter: { user_id: userId, draft: true, status: 'SUBMITTED' },
                    },
                    data: {
                      search_item_review: {
                        item_review_listing: finalReview,
                      },
                    },
                  });
                }}
                onCompleted={() => {
                  swal('Poof! Your Item review has been deleted!', {
                    icon: 'success',
                  });
                }}
              >
                {(delete_item_review, { loading: deleteLoading }) => (
                  <Button
                    className={`${deleteLoading && 'is-loading'}`}
                    height={0}
                    width={2}
                    name="Delete"
                    textProps={{
                      size: 'tiny',
                      color: deleteLoading ? 'white' : 'darkGrey',
                    }}
                    onClick={() => {
                      swal('Are you sure you want to do this?', {
                        buttons: ['Cancel', true],
                      }).then(willDelete => {
                        if (willDelete) {
                          delete_item_review();
                        }
                      });
                    }}
                  />
                )}
              </Mutation>
              &nbsp;&nbsp;&nbsp;
              <Button
                height={0}
                name="Finish Review"
                textProps={{
                  size: 'tiny',
                  color: 'darkGrey',
                }}
                onClick={() =>
                  history.push(
                    // eslint-disable-next-line max-len
                    `/item-review/${review.item_id}?reviewId=${review.item_review_id}&placeId=${review.place_id}`,
                  )
                }
              />
            </FlexRows>
          </Wrapper>
        );
      }}
    </Query>
  );
};

ReviewedItemHeader.defaultProps = {};

ReviewedItemHeader.propTypes = {};

export default withRouter(ReviewedItemHeader);
