import React from 'react';
import styled from 'styled-components';
import { first } from 'lodash';
import { useStoreState } from 'easy-peasy';

import { Link, withRouter } from 'react-router-dom';

import { Text, Image, FlexRows, Icon, Button } from '../../../../components/elements';
import dummy from '../../../../assets/images/food.jpg';
import { parseSocialItem } from '../../../../utils/helpers';
import { getEncodedImage } from '../../../../utils/s3';
import Socials from './Socials';

const Container = styled.div`
  &&& {
    background-color: ${({ theme: { colors } }) => colors.white};
    cursor: pointer;
    /* margin:0rem 1rem; */
    border-radius: 4px;
    height: 100%;
    /* :hover {
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
      transition: box-shadow .5s ease-out;
      background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha};
    } */
  }
`;

const Figure = styled.figure`
  && {
    img {
      border-radius: 4px;
    }
  }
`;

const Wrapper = styled.div`
  &&& {
    padding: 1rem;
    border: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const EmptyWrapper = styled.div`
  &&& {
    position: relative;
    .img-opacity {
      opacity: 0.6;
    }
  }
`;

const AddPhotosButtonWrapper = styled.div`
  right: 1rem;
  position: absolute;
  bottom: 1rem;
  z-index: 2;
`;

const ButtonStyle = styled.a`
  &&& {
    min-height: 40px;
    min-width: 40px;
    padding: 8px 50px;
    display: -webkit-inline-flex;
    display: inline-flex;
    margin: 0;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border: 2px solid #dce1e1;
    color: ${({ theme: { colors } }) => colors.primaryColor};
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    cursor: pointer;
    p {
      padding-left: 12px;
    }
    :hover {
      border-color: ${({ theme: { colors } }) => colors.primaryColor};
      color: ${({ theme: { colors } }) => colors.primaryColor};
      text-decoration: none;
      fill: #262959;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16);
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
    }
  }
`;

const IconsWrapper = styled.div`
  padding: 1rem 1.2rem;
`;

const EmptyImage = ({ itemId, handleOnClickAddPhotoButton }) => (
  <EmptyWrapper>
    <AddPhotosButtonWrapper>
      <Button
        name="Add Photo"
        icon="photoAddSolid"
        textProps={{ size: 'small' }}
        onClick={() => handleOnClickAddPhotoButton(`/add-item-photos/${itemId}`)}
      >
        <span className="icon is-small">
          <Icon name="photoAddSolid" />
        </span>
      </Button>
    </AddPhotosButtonWrapper>
    <Link to={`/item/${itemId}`}>
      <Figure className="image is-3by2 img-opacity">
        <Image source={dummy} />
      </Figure>
    </Link>
  </EmptyWrapper>
);

const ImageView = ({ itemId, handleOnClickAddPhotoButton, itemImage }) => {
  if (itemImage === null) {
    return <EmptyImage itemId={itemId} handleOnClickAddPhotoButton={handleOnClickAddPhotoButton} />;
  }

  return (
    <Link to={`/item/${itemId}`}>
      <Figure className="image image is-3by2">
        <Image source={getEncodedImage(itemImage, 452, 301)} />
      </Figure>
    </Link>
  );
};

const ItemCard = ({ item, history, socials }) => {
  const { userId } = useStoreState(state => state.auth);
  const handleOnClickAddPhotoButton = path => {
    history.push(path);
  };

  const socialArray = socials.length !== 0 ? first(socials).total_social_count : [];
  const social = parseSocialItem(socialArray, userId);

  return (
    <Container>
      <Wrapper>
        <div>
          <Link to={`/item/${item.item_id}`}>
            <Text className="is-capitalized" size="large" weight="semibold" paddingBottom={0.2}>
              {item.name}
            </Text>
          </Link>
          <Text size="small" color="dark" weight="semibold" paddingBottom={0.2}>
            {item.place_name}, {item.city}, {item.post_code}
          </Text>
          <div className="is-flex">
            <span className="icon">
              <Icon size={1} name="itemRatingGoldSolid" />
            </span>
            <Text color="darkGrey" size="tiny" weight="regular" paddingBottom={0.2}>
              90% loved it
            </Text>
          </div>
        </div>
        <ImageView
          itemId={item.item_id}
          itemImage={item.default_image_url}
          handleOnClickAddPhotoButton={handleOnClickAddPhotoButton}
        />
        <div>
          <IconsWrapper>
            <Socials
              objectId={item.item_id}
              objectType="ITEM"
              triedActive={social.tried.active}
              lovedActive={social.loved.active}
              bookMarkActive={social.bookmark.active}
              triedCountInt={social.tried.count}
              lovedCountInt={social.loved.count}
              bookMarkCountInt={social.bookmark.count}
              triedSocialIdS={social.tried.id}
              lovedSocialIdS={social.loved.id}
              bookMarkSocialIdS={social.bookmark.id}
            />
          </IconsWrapper>
          <FlexRows justify="space-around">
            <ButtonStyle
              className="button"
              onClick={() => history.push(`/item-review/${item.item_id}`)}
            >
              <Icon name="reviewAPlace" size={1} /> <p>Review</p>
            </ButtonStyle>
            <ButtonStyle className="button">
              <Icon name="shareBlue" size={1} />
              <p>Share</p>
            </ButtonStyle>
          </FlexRows>
        </div>
      </Wrapper>
    </Container>
  );
};

export default withRouter(ItemCard);
