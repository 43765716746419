import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdEmail } from 'react-icons/md';
import config from '../../../utils/config';

import Text from '../Text';

const Wrapper = styled.div`
  padding: 0.5rem 0rem;
  padding-left: 0.5rem;
  cursor: pointer;
  align-items: center;
`;

const Email = ({ text }) => {
  return (
    <Wrapper className="is-flex">
      <MdEmail size={20} /> &nbsp;&nbsp;
      <a href={`mailto:someone@example.com?Subject=Kravien - Share your Experience&Body=${text}`}>
        <Text color="coolGrey" size="small">
          Share on Email
        </Text>
      </a>
    </Wrapper>
  );
};

Email.defaultProps = {
  text: `https://${config.apiPrefix}admin.portal.kravein.com.au/`,
};

Email.propTypes = {
  text: PropTypes.string,
};

export default Email;
