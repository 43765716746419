import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { capitalize, join, replace, dropRight, forEach } from 'lodash';
import { MdPhone } from 'react-icons/md';
import { AiFillInfoCircle } from 'react-icons/ai';
import moment from 'moment';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';

import { Text, Pager } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';

import bgImg from '../../../../assets/images/food-1245955_640.jpg';
import DiningOptionsModal from './DiningOptionsModal';
import OpenHoursModal from './OpenHoursModal';
import { formatCurrency } from '../../../../utils/helpers';
import client from '../../../../utils/apolloClient';

const Container = styled.div`
  && {
    margin-top: 1rem;
    background-image: ${({ image }) => (image ? `url(${image})` : `url(${bgImg})`)};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 12rem;
    position: relative;
    .columns:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

const Heading = styled(Text)`
  span {
    color: ${({ theme: { colors: color } }) => color.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.tiny};
    font-weight: ${({ theme: { fonts } }) => fonts.weight.medium};
    text-transform: none;
    margin-left: 1rem !important;
  }
`;
const AddressWrapper = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  .is-flex {
    margin-left: 1rem;
  }
`;

const SubHeading = styled(Text)``;

const IconActionButtonWrapper = styled.div`
  margin-top: 1rem;
`;

const DiningOption = styled(Text)`
  display: block;
  ${'' /* margin-top: 1.5rem; */}
  ${'' /* margin-bottom: 0.5rem; */}
  justify-content: center;
  padding: 1rem;
  margin-top: 2.5rem;
  border: 1px solid lightgrey;
  .dining-option {
    display: flex;
    padding-bottom: 0.3rem;
  }

  span {
    margin-right: 0.5rem;
  }
  .more-info {
    color: ${({ theme: { colors: themeColor } }) => themeColor.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
    font-weight: ${({ theme: { fonts } }) => fonts.weight.medium};
    margin-left: 1rem !important;
  }
`;

const Timing = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Warning = styled(Text)`
  margin-left: 1rem;
`;

const AvailableDiningOptions = styled(Text)``;

const InfoContainer = styled.div`
  position: absolute;
  background-color: #fff;
  width: 32rem;
  right: 4%;
  padding: 1.5rem;
  bottom: 2rem;
`;

const OpenNow = styled.span`
  color: ${({ theme: { colors: themeColor } }) => themeColor.primaryColor};
  padding-left: 0.5rem;
`;

const Closed = styled.span`
  color: ${({ theme: { colors: themeColor } }) => themeColor.danger};
  padding-left: 0.5rem;
`;

const fetchDeliveryZoneQuery = gql`
  query fetchDeliveryZoneQ($input: NavigateZoneSettingsInput) {
    fetch_zone_settings(input: $input) {
      zone_found
      matched_zone {
        name
        delivery_fee
        delivery_estimate
      }
    }
  }
`;

const TopView = ({
  place,
  serviceTypeSetting,
  func,
  state,
  currentMenu,
  openHoursData,
  onUpdateDelivery,
  isCustomService,
}) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [openHoursModal, setOpenHoursModal] = useState(false);
  const phoneNumberArray = place.contact.filter(item => item.type === 'phone_primary');
  const phoneNumber = phoneNumberArray.length !== 0 ? phoneNumberArray[0].value : null;
  const currentLocation = useStoreState(state1 => state1.global.usedLocation);

  const [deliveryEstimate, setDeliveryEstimate] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);

  const serviceTypes = values => {
    const service_type = [];
    forEach(values, item => {
      if (item.name === 'Pickup' || item.name === 'Dinein' || item.name === 'Delivery') {
        service_type.push(item);
      }
    });
    return service_type;
  };

  const fetchZone = useCallback(
    ({ latitude, longitude }) => {
      client.clientPublic
        .query({
          query: fetchDeliveryZoneQuery,
          variables: {
            input: {
              service_type_setting_id: serviceTypeSetting.service_type_setting_id,
              address: {
                latitude,
                longitude,
              },
            },
          },
        })
        .then(({ data }) => {
          if (data.fetch_zone_settings && data.fetch_zone_settings.zone_found) {
            setDeliveryEstimate(
              parseInt(data.fetch_zone_settings.matched_zone.delivery_estimate, 10),
            );
            setDeliveryFee(data.fetch_zone_settings.matched_zone.delivery_fee);
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    [serviceTypeSetting.service_type_setting_id],
  );

  useEffect(() => {
    const check =
      state.deliveryAddress && state.deliveryAddress.latitude && state.deliveryAddress.longitude;
    if (check) {
      fetchZone({
        latitude: state.deliveryAddress.latitude,
        longitude: state.deliveryAddress.longitude,
      });
    } else {
      fetchZone({ latitude: currentLocation.latitude, longitude: currentLocation.longitude });
    }
  }, [currentLocation.latitude, currentLocation.longitude, fetchZone, state.deliveryAddress]);

  const asap =
    parseInt(serviceTypeSetting.preparation_estimate, 10) +
    parseInt(serviceTypeSetting.added_estimate, 10) +
    deliveryEstimate;
  const asapFirstValue =
    serviceTypeSetting.service_type === 'Delivery'
      ? parseInt(serviceTypeSetting.preparation_estimate, 10) + deliveryEstimate
      : parseInt(serviceTypeSetting.preparation_estimate, 10);
  const cuisineStyleArray = dropRight(
    place.cuisine_style,
    place.cuisine_style ? place.cuisine_style.length - 3 : 0,
  );

  const businessTypeArray = dropRight(
    place.business_type,
    place.business_type ? place.business_type.length - 2 : 0,
  );

  return (
    <>
      <Container className="main-div" image={serviceTypeSetting.image_url}>
        <DiningOptionsModal
          asap={asap}
          asapFirstValue={asapFirstValue}
          onUpdateDelivery={onUpdateDelivery}
          currentMenu={currentMenu}
          place={place}
          serviceTypes={serviceTypes(place.service_type)}
          serviceTypeSetting={serviceTypeSetting}
          isActiveModal={isActiveModal}
          setIsActiveModal={setIsActiveModal}
          func={func}
          state={state}
        />
        <OpenHoursModal
          currentMenu={currentMenu}
          isActiveModal={openHoursModal}
          setIsActiveModal={setOpenHoursModal}
        />

        <InfoContainer className="info-container">
          <Heading className="is-capitalized" size="big" weight="semibold" paddingBottom={0.2}>
            {capitalize(place.name)}
            <Link to={`/place/${place.place_id}`} className="more-info">
              <span>More info</span>
            </Link>
          </Heading>
          <SubHeading size="small" weight="medium" color="darkGrey">
            {join(
              businessTypeArray.map(item => item.description),
              ', ',
            )}{' '}
            |{' '}
            {join(
              cuisineStyleArray.map(item => item.description),
              ', ',
            )}
          </SubHeading>
          <AddressWrapper>
            <Text size="small" color="darkGrey">
              {place.city} {place.state} {place.post_code}
            </Text>
            {phoneNumber && (
              <div className="is-flex">
                <span className="icon">
                  <MdPhone size={15} color="#BABABA" /> &nbsp;
                </span>
                <Text size="smaller" color="darkGrey">
                  {phoneNumber}
                </Text>
              </div>
            )}
          </AddressWrapper>

          <AvailableDiningOptions weight="medium" size="smedium">
            Available Dining Options:{' '}
            {join(
              serviceTypes(place.service_type).map(item => item.name),
              ', ',
            )}
          </AvailableDiningOptions>
          {/* <IconActionButtonWrapper>
            <PlaceSocial />
          </IconActionButtonWrapper> */}
        </InfoContainer>
      </Container>

      {currentMenu && (
        <Pager>
          <DiningOption size="medium" weight="semibold">
            <div className="dining-option">
              <span>Dining Option: </span>
              {isCustomService ? (
                <Text
                  size="medium"
                  color={colors.grey}
                  weight="medium"
                  className="pickup"
                  align="start"
                >
                  {serviceTypeSetting.service_type}
                </Text>
              ) : (
                <Text
                  size="medium"
                  color={colors.grey}
                  weight="medium"
                  className="pickup"
                  align="start"
                >
                  {serviceTypeSetting.service_type} |{' '}
                  {state.deliveryMode === 'asap'
                    ? `ASAP ( ${asapFirstValue} - ${asap}m )`
                    : `${state.date}, ${state.time}`}
                  <a className="more-info is-uppercase" onClick={() => setIsActiveModal(true)}>
                    <span>Change</span>
                  </a>
                </Text>
              )}
            </div>
            {!isCustomService && (
              <SubHeading
                size="smedium"
                weight="medium"
                color="darkGrey"
                align="start"
                paddingBottom="0.2"
              >
                {serviceTypeSetting.minimum_order_amount &&
                serviceTypeSetting.minimum_order_amount !== 0
                  ? `${formatCurrency(serviceTypeSetting.minimum_order_amount)} Minimum Amount`
                  : 'No minimum order amount'}{' '}
                |{' '}
                {serviceTypeSetting.service_type === 'Delivery' &&
                  (deliveryFee !== 0
                    ? `$${parseFloat(deliveryFee).toFixed(2)} Delivery Fee | `
                    : 'No Delivery Fee | ')}
                <span className="is-capitalized">
                  {join(
                    serviceTypeSetting.payment_method.map(item =>
                      capitalize(replace(item, '-', ' ')),
                    ),
                    ', ',
                  )}
                </span>
              </SubHeading>
            )}
            <Timing size="smedium" color="coolGrey" className="d-flex align-items-center">
              {openHoursData.todayDay}{' '}
              {openHoursData.isOpen ? <OpenNow> Open Now | </OpenNow> : <Closed> Closed </Closed>}
              {join(
                openHoursData.hours.map(item => `${item.start} - ${item.end}`),
                ', ',
              )}
              <AiFillInfoCircle
                size={20}
                color="#CCCCCC"
                style={{ marginLeft: '1rem', cursor: 'pointer' }}
                onClick={() => setOpenHoursModal(true)}
              />
              {openHoursData.isOpen &&
                openHoursData.isStopped &&
                openHoursData.messageOfClosingAndOpening === '' && (
                  <Warning size="small" color="secondaryColor" weight="regular" marginLeft="1rem">
                    Currently not accepting orders.
                  </Warning>
                )}
              <Warning size="small" color="secondaryColor" weight="regular" marginLeft="1rem">
                {openHoursData.messageOfClosingAndOpening}
              </Warning>
            </Timing>
          </DiningOption>
        </Pager>
      )}
    </>
  );
};

export default TopView;
