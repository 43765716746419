import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import gql from 'graphql-tag';
import { uniq, first } from 'lodash';
import { useStoreState } from 'easy-peasy';

import { Text } from '../../components/elements';
import ImageSlider from '../../components/ImageSlider';

import Social from './Social';
import UserArticle from '../../components/UserArticle';
import FollowButtonAction from '../../components/FollowButtonAction';
import { getPlaceTagline, parseSocialPlace } from '../../utils/helpers';
import client from '../../utils/apolloClient';

const Container = styled.div`
  &&& {
    z-index: 1800;
    .modal-content,
    .modal-card {
      max-height: 100vh;
      overflow-x: hidden;
      overflow-y: hidden;
      width: 100%;
    }
    .modal-background {
      background-color: rgba(0, 0, 0, 1);
    }
    .slick-next {
      right: 4px;
    }
    .slick-prev {
      left: 4px;
      z-index: 1800;
    }
  }
`;
// const ButtonStyle = styled.div`
//   &&& {
//     position: absolute;
//     right: 4px;
//     top: 5px;
//   }
// `;

const PlaceWrapper = styled.div`
  padding: 0.5rem;
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.5rem;
  /* background: white; */
`;

const searchQuery = gql`
  query searchItemReview($input: SearchInput, $imageInput: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        profile_image_url
        color_code
      }
    }
    search_social(input: $imageInput) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const BottomView = ({ images, place, preViewImage }) => {
  const { userId } = useStoreState(state => state.auth);
  const allUserIds = uniq(images.map(image => image.audit.created_by));
  const imageIds = uniq(images.map(image => image.image_id));
  const [socials, setSocial] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    client.clientPublic
      .query({
        query: searchQuery,
        variables: {
          imageInput: {
            filter: { social_filter: { object_id: imageIds, object_type: 'IMAGE' } },
          },
          input: {
            filter: {
              user_filter: {
                user_id: allUserIds,
              },
            },
          },
        },
      })
      .then(({ data }) => {
        if (data) {
          setSocial(data.search_social.social_aggregation.total_social_per_object_id);
          setLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return null;
  }

  return (
    <>
      <PlaceWrapper>
        <Text size="small" weight="semibold" color="white">
          {place.name}
        </Text>
        <Text size="smaller" color="white">
          {getPlaceTagline(place.business_type, place.cuisine_style)}
        </Text>
        <Text color="white" size="smaller">
          {moment(place.audit.created_at).fromNow()}
        </Text>
      </PlaceWrapper>
      <hr />
      {images.map(item => {
        const socialsPerObject = socials.filter(edge => edge.object_id === item.image_id);
        const socialArray =
          socialsPerObject.length !== 0 ? first(socialsPerObject).total_social_count : [];
        const social = parseSocialPlace(socialArray, userId);
        const isActive = item.image_id === preViewImage.image_id;

        return (
          <Social
            isActive={isActive}
            objectId={item.image_id}
            parentId={place.place_id}
            objectType="IMAGE"
            likeActive={social.like.active}
            likeCountInt={social.like.count}
            likeSocialIdS={social.like.id}
          />
        );
      })}
    </>
  );
};

const PhotoPreviewModal = ({
  isActive,
  isClose,
  images,
  place,
  whichImage,
  onSliderChange,
  preViewImage,
}) => {
  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <section className="modal-content" style={{ background: 'black' }}>
        <div className="columns is-centered" style={{ background: 'black' }}>
          <div className="column">
            <div>
              <ImageSlider
                images={images}
                whichImage={whichImage}
                onSliderChange={onSliderChange}
              />
            </div>
          </div>
          <div />
        </div>
      </section>
      <button className="modal-close is-large" aria-label="close" onClick={isClose} />
      <Bottom>
        <BottomView images={images} place={place} preViewImage={preViewImage} />
      </Bottom>
    </Container>
  );
};

export default PhotoPreviewModal;
