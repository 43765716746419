import React, { useState } from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { withRouter } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

import DesktopForm from './DesktopForm';
import { Text, Pager } from '../../components/elements';
import Loading from '../../components/global/mobile/MobileLoading';

const Container = styled(Pager)`
  padding-top: 2rem;
`;
const Heading = styled.div`
  padding: 1rem 0rem;
`;

const DesktopView = ({ loading, data, claimLoading, createPlaceClaim, history }) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn } = useStoreState(state => state.auth);
  const [step, setStep] = useState(1);
  if (loading) {
    return <Loading />;
  }
  const place = data.fetch_place;
  return (
    <Container>
      <Heading>
        <Text className="is-capitalized" size="big" weight="semibold" paddingBottom={0.5}>
          Claim `{capitalize(place.name)}`
        </Text>
        <Text color="darkGrey" size="tiny" weight="regular" paddingBottom={0.5}>
          {place.address_line_1} {place.city}, {place.state} {place.post_code}
        </Text>
        {step === 1 && (
          <Text color="darkGrey" size="tiny" weight="regular">
            Claim your free listing&apos;s and customize to you taste! If you are an authorized
            representative or the owner of this place, sign up to manage your place, keep all the
            information upto date and grow your business. You will be also be able to showcase your
            specialties, upload photos, engage with customers, receive notifications and track page
            visits. Features include unlimited free posts, displaying multiple menus, black board
            specials, publishing deals or offers for free, creating and managing events and much,
            much more...
          </Text>
        )}
        {step === 2 && (
          <Text color="darkGrey" size="tiny" weight="regular">
            Inorder to ensure and protect your place, choose an option below to confirm your
            identity. This will ensure your listing is always safe and secure.
          </Text>
        )}
      </Heading>
      <DesktopForm
        step={step}
        setStep={setStep}
        setIsActiveModal={setIsActiveModal}
        isLoggedIn={isLoggedIn}
        history={history}
        loading={claimLoading}
        place={place}
        onSubmit={values => {
          createPlaceClaim(values);
        }}
      />
    </Container>
  );
};

export default withRouter(DesktopView);
