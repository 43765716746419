import React from 'react';
import styled from 'styled-components';
import { Pager } from '../../components/elements';
import DesktopForm from './DesktopForm';

const Container = styled(Pager)`
  padding-top: 2rem;
`;

const DesktopView = () => (
  <Container>
    <br />
    <DesktopForm />
  </Container>
);

export default DesktopView;
