import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';

import { Text, Button, ShowMoreText } from '../../../../components/elements';
import EventContactModal from './EventContactModal';

const Container = styled.div`
  margin: 0;
`;

const Header = styled.div`
  padding: 0.5rem 0rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  margin-bottom: 1rem;
`;

const contactEventOrganizerMutation = gql`
  mutation contact_event_organizer($input: ContactEventOrganizerInput) {
    contact_event_organizer(input: $input) {
      id
    }
  }
`;

const Details = ({ event }) => {
  const [isActiveContactModal, setIsActiveContactModal] = useState(false);
  const [resetFormCallback, setResetFormCallback] = useState();

  return (
    <Container>
      <Header>
        <Text size="large" weight="semibold" paddingTop={0.5}>
          Details
        </Text>
      </Header>
      <div className="columns is-multiline">
        {event.event_type.length !== 0 && (
          <div className="column is-4">
            <Text className="is-uppercase" size="small" color="darkGrey" weight="semibold">
              Event Type
            </Text>
            <Text size="small" color="darkGrey">
              {event.event_type.map(
                (item, index) =>
                  `${item.description}${index !== event.event_type.length - 1 ? ', ' : ''}`,
              )}
              {' | '}
              {event.event_category &&
                event.event_category.length !== 0 &&
                event.event_category.map(
                  (item, index) =>
                    `${item.description}${index !== event.event_category.length - 1 ? ', ' : ''}`,
                )}
            </Text>
          </div>
        )}
        {event.dress_code.length !== 0 && (
          <div className="column is-4">
            <Text className="is-uppercase" size="small" color="darkGrey" weight="semibold">
              Dress code
            </Text>
            <Text size="small" color="darkGrey">
              {event.dress_code.map(
                (item, index) =>
                  `${item.description}${index !== event.dress_code.length - 1 ? ', ' : ''}`,
              )}
            </Text>
          </div>
        )}
        {event.restriction.length !== 0 && (
          <div className="column is-4">
            <Text className="is-uppercase" size="small" color="darkGrey" weight="semibold">
              Age restriction
            </Text>
            <Text size="small" color="darkGrey">
              {event.restriction.length === 4
                ? 'All Ages'
                : event.restriction.map(
                    (item, index) =>
                      `${item.description}${index !== event.restriction.length - 1 ? ', ' : ''}`,
                  )}
            </Text>
          </div>
        )}

        <div className="column is-full">
          {event.feature.length !== 0 && (
            <>
              <Text className="is-uppercase" size="small" color="darkGrey" weight="semibold">
                Features
              </Text>
              <Text size="small" color="darkGrey" paddingBottom={1}>
                {event.feature.map(
                  (item, index) =>
                    `${item.description}${index !== event.feature.length - 1 ? ', ' : ''}`,
                )}
              </Text>
            </>
          )}
          <ShowMoreText content={event.description} limit={500} size="small" color="darkGrey" />
        </div>
      </div>
      {event.contact_event_organizer && (
        <>
          <Button
            name="CONTACT EVENT ORGANIZER "
            backgroundColor="primaryColor"
            textProps={{ color: 'white', weight: 'semibold', size: 'small' }}
            onClick={() => setIsActiveContactModal(true)}
          />
          <Mutation
            mutation={contactEventOrganizerMutation}
            onCompleted={() => {
              setIsActiveContactModal(false);
              resetFormCallback.resetForm();
              swal('Thank you for contacting us!');
            }}
          >
            {(contact_event_organizer, { loading, error }) => (
              <EventContactModal
                isActive={isActiveContactModal}
                loading={loading}
                error={error}
                onClose={() => setIsActiveContactModal(false)}
                event={event}
                onSubmit={(values, { resetForm }) => {
                  setResetFormCallback({ resetForm });
                  contact_event_organizer({ variables: { input: { ...values } } });
                }}
              />
            )}
          </Mutation>
        </>
      )}
    </Container>
  );
};

export default Details;
