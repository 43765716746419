import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import chroma from 'chroma-js';

const fontSize = {
  small: '12px',
  medium: '15px',
  large: '18px',
  extraLarge: '20px',
};

const padding = {
  small: '0.3rem 0.8rem',
  medium: '0.3rem 0.9rem',
  large: '0.6rem 1rem',
  extraLarge: '0.8rem 1.3rem',
};

const loaderSize = {
  small: '18px',
  medium: '20px',
  large: '22px',
  extraLarge: '24px',
};

const baseCss = css`
  border: 0;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  color: #ffffff;
  font-size: ${({ size }) => fontSize[size]};
  padding: ${({ size }) => padding[size]};
  font-weight: 600;
  cursor: pointer;
  outline-style: none;
  width: 100%;
  position: relative;
  ${({ isLoading }) =>
      isLoading &&
      `opacity: 0.8;
    cursor: not-allowed !important;`}
    :disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
`;

const ButtonStyle = styled.button`
  background-color: #00aeef;
  ${baseCss};
  :hover {
    background-color: ${chroma('#00aeef').darken(0.5)};
  }
`;
const ButtonIsOutLine = styled.button`
  ${baseCss};
  border: 1px solid #00aeef;
  color: #00aeef;
  background-color: white;
  :hover {
    background-color: ${chroma('#00aeef').alpha(0.1)};
  }
`;
const ButtonIsGhost = styled.button`
  ${baseCss};
  color: #00aeef;
  background-color: white;
  :hover {
    background-color: ${chroma('#00aeef').alpha(0.1)};
  }
`;
const ButtonIsLink = styled.button`
  ${baseCss};
  color: #00aeef;
  background-color: white;
  :hover {
    text-decoration: underline;
  }
`;

const Loading = ({ color, size }) => (
  <ReactLoading type="spin" color={color} height={loaderSize[size]} width={loaderSize[size]} />
);

const Button = ({
  size,
  isOutLine,
  isGhost,
  isLink,
  type,
  isLoading,
  isDisabled,
  children,
  ...props
}) => {
  if (isOutLine) {
    return (
      <ButtonIsOutLine
        type={type}
        isLoading={isLoading}
        size={size}
        disabled={isDisabled}
        {...props}
      >
        {isLoading ? <Loading color="#00aeef" size={size} /> : children}
      </ButtonIsOutLine>
    );
  }
  if (isGhost) {
    return (
      <ButtonIsGhost type={type} isLoading={isLoading} size={size} disabled={isDisabled} {...props}>
        {isLoading ? <Loading color="#00aeef" size={size} /> : children}
      </ButtonIsGhost>
    );
  }
  if (isLink) {
    return (
      <ButtonIsLink type={type} isLoading={isLoading} size={size} disabled={isDisabled} {...props}>
        {isLoading ? <Loading color="#00aeef" size={size} /> : children}
      </ButtonIsLink>
    );
  }
  return (
    <ButtonStyle type={type} isLoading={isLoading} size={size} disabled={isDisabled} {...props}>
      {isLoading ? <Loading color="#ffffff" size={size} /> : children}
    </ButtonStyle>
  );
};

Button.defaultProps = {
  type: 'button',
  size: 'medium',
  isLoading: false,
  isDisabled: false,
};

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge']),
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default Button;
