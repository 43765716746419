import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';

import { Icon } from '../../../../../components/elements';
import apolloClient from '../../../../../utils/apolloClient';

const ButtonAnimation = posed.a({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const IconButton = styled(ButtonAnimation)`
  &&& {
    border: 0;
    ${({ paddingLess }) => paddingLess && `padding:0.2rem 0.5rem`};
    background-color: transparent;
  }
`;

const followQuery = gql`
  query follows($input: SearchInput) {
    search_follows(input: $input) {
      follow_listing {
        follow_id
        user_id
        type
      }
    }
  }
`;

const followMutation = gql`
  mutation follow($input: ActionFollowInput) {
    action_follow(input: $input) {
      follow_id
      object_id
      object_type
      user_id
      type
    }
  }
`;

const UnFollowActionButton = ({ objectId, followId, type }) => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={apolloClient.clientPrivate}
      mutation={followMutation}
      update={(client, { data: { action_follow } }) => {
        const variables = {
          input: {
            filter: {
              follow_filter: { user_id: userId, object_type: 'USER', type: 'FOLLOW' },
            },
          },
        };

        const { search_follows } = client.readQuery({
          query: followQuery,
          variables,
        });
        console.log('vfdhvfhev', search_follows);

        const deleteExisted = search_follows.follow_listing.filter(
          item => item.follow_id !== action_follow.follow_id,
        );

        client.writeQuery({
          query: followQuery,
          variables,
          data: {
            search_follows: {
              follow_listing: deleteExisted,
              __typename: 'FollowListingAggregation',
            },
          },
        });
      }}
    >
      {action_follow => (
        <IconButton
          className="button"
          onClick={() => {
            action_follow({
              variables: {
                input: {
                  user_id: userId,
                  object_id: objectId,
                  object_type: 'USER',
                  type,
                  follow_id: followId,
                },
              },
            });
          }}
        >
          <span className="icon">
            <Icon name="following" />
          </span>
        </IconButton>
      )}
    </Mutation>
  );
};

export default UnFollowActionButton;
