import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import PhotoPreviewModal from '../../../PlacePhotos/PhotoPreviewModal';
import { getApiImages } from '../../../../utils/reusableQuery';
import { Image, Button, Icon, Flex } from '../../../../components/elements';
import blankImage from '../../../../assets/images/blank.png';
import { getEncodedImage } from '../../../../utils/s3';
import config from '../../../../utils/config';

const Figure = styled.figure`
  &&& {
    .figure-img {
      opacity: 0.5;
    }
    img {
      padding: 0.5em;
    }
  }
`;

const AddPhotoWrapper = styled.div`
  position: relative;
  right: 2rem;
  float: right;
  bottom: 4rem;
`;

const Wrapper = styled.div`
  position: relative;
  right: 1rem;
  float: right;
`;
const Container = styled.div`
  margin-bottom: 1rem;
`;

const FigureEmpty = styled.figure`
  &&& {
    padding-top: 20%;
    opacity: 0.5;
    img {
      object-fit: cover;
    }
  }
`;

const ImageView = ({ history, place }) => {
  const [images, setImage] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [preViewImage, setPreViewImage] = useState({});
  const [whichImage, setWhichImage] = useState(0);

  useEffect(() => {
    const get = async () => {
      const data = await getApiImages({
        object_id: place.place_id,
        object_type: ['PLACE'],
        parent_id: place.place_id,
        parent_type: ['PLACE'],
        links_to_id: [place.place_id],
        links_to_type: ['PLACE'],
      });
      setImage(data);
    };
    get();
  }, [place.place_id]);

  if (images === null) {
    return (
      <Container>
        <FigureEmpty className="image is-2by1">
          <Image source={blankImage} objectFit="contain" />
        </FigureEmpty>
      </Container>
    );
  }

  if (images.length === 0) {
    return (
      <Container>
        <FigureEmpty className="image is-2by1">
          <Image source={config.dummyImage} objectFit="contain" />
        </FigureEmpty>
        <AddPhotoWrapper>
          <Button
            name="Add Photo"
            icon="photoAddSolid"
            textProps={{ size: 'small' }}
            onClick={() => history.push(`/add-place-photos/${place.place_id}`)}
          >
            <span className="icon is-small">
              <Icon name="photoAddSolid" />
            </span>
          </Button>
        </AddPhotoWrapper>
      </Container>
    );
  }

  if (images.length <= 3) {
    return (
      <div className="columns is-vcentered">
        {images.map((image, index) => (
          <div className="column is-3" key={image.url}>
            <a
              key={image.url}
              onDoubleClick={() => {
                setModalActive(true);
                setPreViewImage(image);
                setWhichImage(index);
              }}
            >
              <Figure className="image is-5by4">
                <Image source={getEncodedImage(image.url, 344, 275)} objectFit="contain" />
              </Figure>
            </a>
          </div>
        ))}

        <div className="column is-3">
          <Button
            name="Add Photo"
            icon="photoAddSolid"
            textProps={{ size: 'small' }}
            onClick={() => history.push(`/add-place-photos/${place.place_id}`)}
          >
            <span className="icon is-small">
              <Icon name="photoAddSolid" />
            </span>
          </Button>
        </div>
        {modalActive && (
          <PhotoPreviewModal
            whichImage={whichImage}
            place={place}
            isActive={modalActive}
            images={images}
            preViewImage={preViewImage}
            isClose={() => setModalActive(false)}
            onSliderChange={value => {
              setPreViewImage(images[value]);
            }}
          />
        )}
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <>
      <Slider {...settings}>
        {images.map((image, index) => (
          <a
            key={image.url}
            onDoubleClick={() => {
              setModalActive(true);
              setPreViewImage(image);
              setWhichImage(index);
            }}
          >
            <Figure className="image is-5by4">
              <Image source={getEncodedImage(image.url, 344, 275)} objectFit="contain" />
            </Figure>
          </a>
        ))}
      </Slider>
      <Wrapper>
        <Flex>
          <Button
            onClick={() => history.push(`/place-photos/${place.place_id}`)}
            name="See all Photo"
            icon="photoAddSolid"
            textProps={{ size: 'small' }}
          />
          &nbsp; &nbsp;
          <Button
            name="Add Photo"
            icon="photoAddSolid"
            textProps={{ size: 'small' }}
            onClick={() => history.push(`/add-place-photos/${place.place_id}`)}
          >
            <span className="icon is-small">
              <Icon name="photoAddSolid" />
            </span>
          </Button>
        </Flex>
        {modalActive && (
          <PhotoPreviewModal
            whichImage={whichImage}
            place={place}
            isActive={modalActive}
            images={images}
            preViewImage={preViewImage}
            isClose={() => setModalActive(false)}
            onSliderChange={value => {
              setPreViewImage(images[value]);
            }}
          />
        )}
      </Wrapper>
    </>
  );
};

export default withRouter(ImageView);
