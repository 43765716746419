import React from 'react';
import styled from 'styled-components';
import { FlexRows, Icon } from '../../components/elements';

const IconWrapper = styled.span`
  padding: 0.2rem;
`;
const IconStyle = styled(Icon).attrs({ size: 3 })``;

const MobileView = ({ initialRating, handleChange }) => {
  const [rating, setRating] = React.useState(initialRating || 0);

  return (
    <>
      <FlexRows align="flex-start">
        <a
          onClick={() => {
            setRating(1);
            handleChange(1);
          }}
        >
          {rating < 1 ? (
            <IconWrapper>
              <IconStyle name="itemRatingSolidGrey" />
            </IconWrapper>
          ) : (
            <IconWrapper>
              <IconStyle name="itemRatingGoldSolid" />
            </IconWrapper>
          )}
        </a>
        <a
          onClick={() => {
            setRating(2);
            handleChange(2);
          }}
        >
          {rating < 2 ? (
            <IconWrapper>
              <IconStyle name="itemRatingSolidGrey" />
            </IconWrapper>
          ) : (
            <IconWrapper>
              <IconStyle name="itemRatingGoldSolid" />
            </IconWrapper>
          )}
        </a>
        <a
          onClick={() => {
            setRating(3);
            handleChange(3);
          }}
        >
          {rating < 3 ? (
            <IconWrapper>
              <IconStyle name="itemRatingSolidGrey" />
            </IconWrapper>
          ) : (
            <IconWrapper>
              <IconStyle name="itemRatingGoldSolid" />
            </IconWrapper>
          )}
        </a>
        <a
          onClick={() => {
            setRating(4);
            handleChange(4);
          }}
        >
          {rating < 4 ? (
            <IconWrapper>
              <IconStyle name="itemRatingSolidGrey" />
            </IconWrapper>
          ) : (
            <IconWrapper>
              <IconStyle name="itemRatingGoldSolid" />
            </IconWrapper>
          )}
        </a>
        <a
          onClick={() => {
            setRating(5);
            handleChange(5);
          }}
        >
          {rating < 5 ? (
            <IconWrapper>
              <IconStyle name="itemRatingSolidGrey" />
            </IconWrapper>
          ) : (
            <IconWrapper>
              <IconStyle name="itemRatingGoldSolid" />
            </IconWrapper>
          )}
        </a>
      </FlexRows>
    </>
  );
};
export default MobileView;
