import React from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { ReactSelect, Text } from '../../../../components/elements';

const options = [
  { label: 'Best Match', value: 'RATING' },
  // { label: 'Rating', value: 'rating' },
  { label: 'Distance', value: 'LOCATION' },
  { label: 'Price Low to High', value: 'PRICE_LOW_TO_HIGH' },
  { label: 'Price High to Low', value: 'PRICE_HIGH_TO_LOW' },
];

const Container = styled.div`
  &&& {
    padding-right: 1.5rem;
    margin: 0;
  }
`;

const Sort = () => {
  const updateSort = useStoreActions(state => state.place.updateSort);
  const sort = useStoreState(state => state.place.sort);
  return (
    <Container className="columns is-gapless is-vcentered">
      <div className="column is-4">
        <Text weight="regular" size="lmedium">
          Sort By
        </Text>
      </div>
      <div className="column">
        <ReactSelect value={sort} options={options} onChange={value => updateSort(value)} />
      </div>
    </Container>
  );
};

export default Sort;
