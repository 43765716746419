import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isNull, kebabCase } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Text, FlexRows, Icon } from '../../../components/elements';

import { getTicketUrlPdf } from '../../../utils/s3';
import Ingredient from './Ingredient';

const Container = styled.div`
  padding-bottom: 2rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.borderColor};
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  .time-detail {
    margin: 0.8rem 0;
  }
  .btn-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const Top = styled(FlexRows)`
  width: 100%;
  .button {
    border-color: ${({ theme: { colors } }) => colors.primaryColor};
    color: ${({ theme: { colors } }) => colors.primaryColor};
  }
`;

const Left = styled(FlexRows)`
  .address {
  }
  .info-right {
    margin-left: 1rem;
  }
  .favourite {
    margin-left: 0.1rem;
  }
  .view-details {
    margin-left: 5px;
  }

  img {
    border-radius: 12px;
  }
`;

const Flex = styled(FlexRows)`
  margin-bottom: -0.2rem;
`;

const Ingredients = styled.div`
  ${'' /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2rem 0rem;
  justify-content: space-between; */}
`;

const MobileFood = ({ active, status }) => {
  return (
    <Container>
      <Top justify="space-between">
        <div>
          <Left justify="flex-start">
            <figure className="image is-64x64">
              <img src="https://bulma.io/images/placeholders/128x128.png" alt="food-order" />
            </figure>
            <div className="info-right">
              <Flex justify="flex-start">
                <Text weight="semibold" size="medium">
                  David's Spicy Pot
                </Text>
              </Flex>
              <Text weight="regular" size="small" color="darkGrey" className="address">
                St. Kilda VIC
              </Text>
              <div className="">
                <Flex justify="flex-start">
                  <Icon name="bookmarkSolidGrey" size={0.8} />
                  <Text weight="regular" size="tiny" color="darkGrey" className="favourite">
                    Favourite order |
                  </Text>
                  <Text
                    weight="regular"
                    size="tiny"
                    color="secondaryColor"
                    className="view-details"
                  >
                    View Details
                  </Text>
                </Flex>
              </div>
            </div>
          </Left>
        </div>
      </Top>
      <Text weight="regular" size="tiny" color="darkGrey" className="time-detail">
        16 April 10:14 pm | 7 Items | $45.25
      </Text>
      <div className="row">
        <Ingredients>
          <Ingredient status={status} isMobile />
          <Ingredient status={status} isMobile />
          <Ingredient status={status} isMobile />
          <Ingredient status={status} isMobile />
          <Ingredient status={status} isMobile />
          <Ingredient status={status} isMobile />
        </Ingredients>
      </div>

      <Text size="tiny" color="primaryColor" paddingBottom="0.5" align="right">
        SHOW MORE{' '}
      </Text>
      <div className="btn-wrapper">
        <FlexRows>
          <Text size="smaller" color="secondaryColor" paddingBottom="0.5">
            Delivery{' '}
          </Text>

          <Text size="smaller" color="darkGrey" paddingBottom="0.5">
            | {status}
          </Text>
        </FlexRows>
        {status !== 'Delivered' ||
          (status !== 'Cancelled' && (
            <button className="button">
              {active === 'active' ? 'Track this order' : 'Repeat Order'}
            </button>
          ))}
      </div>
    </Container>
  );
};

export default MobileFood;
