import React, { useState } from 'react';
import styled from 'styled-components';
import { times, dropRight } from 'lodash';
import { IoMdSunny } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { Text, FlexRows, ShowMoreText } from '../../../../components/elements';
import PlaceHours from './PlaceHours';
import ImageView from './ImageView';
import colors from '../../../../theme/styles/colors';
import font from '../../../../theme/styles/fonts';

const Container = styled.div`
  padding-top: 1rem;
`;

const IconWrapper = styled.div`
  margin-left: 0.5px;
`;

const Content = styled.div`
  padding: 0.5rem 0rem;
  border-bottom: 2px solid ${colors.lightGreyBeta};
  margin-bottom: 2rem;
`;

const DescriptionWrapper = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 1rem;
`;

const CuisineStyle = styled.span`
  background: ${colors.lightGreyBeta};
  padding: 0.2rem 0.8rem;
  margin-bottom: 1rem;
  font-size: ${font.size.tiny};
`;

const AmitiesWrapper = styled.div`
  margin-bottom: 1rem;
  margin-left: 0.5rem;
`;

const Information = ({ place }) => {
  const [showMoreFeatureActive, setShowMoreFeatureActive] = useState(false);
  const featureArrayCheckNull = place.feature ? place.feature : [];
  const featureArray = dropRight(
    featureArrayCheckNull,
    featureArrayCheckNull ? featureArrayCheckNull.length - 20 : 0,
  );

  const [showMoreFeature, setShowMoreFeature] = useState(featureArray);

  const cuisineStyleArray = dropRight(
    place.cuisine_style,
    place.cuisine_style ? place.cuisine_style.length - 10 : 0,
  );

  const businessTypeArray = dropRight(
    place.business_type,
    place.business_type ? place.business_type.length - 10 : 0,
  );

  return (
    <Container>
      <ImageView place={place} />
      <div style={{ marginLeft: '0.5rem' }}>
        <div className="is-flex">
          {businessTypeArray.length !== 0 && (
            <CuisineStyle>
              {businessTypeArray.map(
                (item, index) =>
                  `${item.description}${index !== businessTypeArray.length - 1 ? ', ' : ''}`,
              )}
            </CuisineStyle>
          )}
          {(cuisineStyleArray.length !== 0 || (place.price_range !== 0 && place.price_range)) && (
            <span
              style={{
                background: colors.lightGreyBeta,
                paddingTop: '3px',
                marginBottom: '1rem',
              }}
            >
              {place.price_range === null ? '' : '|'}
            </span>
          )}
          {place.price_range !== 0 && place.price_range && (
            <CuisineStyle style={{ paddingTop: '5px' }}>
              {times(place.price_range || 0).map(() => (
                <span style={{ color: colors.primaryColor }}>$</span>
              ))}
            </CuisineStyle>
          )}
        </div>
        {/* {cuisineStyleArray.length !== 0 && (
          <CuisineStyle>
            {cuisineStyleArray.map(
              (item, index) =>
                `${item.description}${index !== cuisineStyleArray.length - 1 ? ', ' : ''}`,
            )}
          </CuisineStyle>
        )} */}
      </div>
      {(place.tagline || place.description) && (
        <DescriptionWrapper>
          {place.tagline && (
            <Text size="lmedium" weight="semibold" paddingBottom={0.5} letterSpacing="tight">
              {place.tagline}
            </Text>
          )}
          {place.description && (
            <ShowMoreText content={place.description} size="tiny" color="darkGrey" limit={500} />
          )}
        </DescriptionWrapper>
      )}
      {place.cuisine_style && place.cuisine_style.length !== 0 && (
        <AmitiesWrapper>
          <Text className="is-uppercase" size="smaller" color="darkGrey">
            Cuisines
          </Text>
          <Text size="tiny" color="darkGrey">
            {place.cuisine_style.map(
              (item, index) =>
                `${item.description}${index !== place.cuisine_style.length - 1 ? ', ' : ''}`,
            )}
          </Text>
        </AmitiesWrapper>
      )}
      {place.dietary_information && place.dietary_information.length !== 0 && (
        <AmitiesWrapper>
          <Text className="is-uppercase" size="smaller" color="darkGrey">
            Dietary Information
          </Text>
          <Text size="tiny" color="darkGrey">
            {place.dietary_information.map(
              (item, index) =>
                `${item.description}${index !== place.dietary_information.length - 1 ? ', ' : ''}`,
            )}
          </Text>
        </AmitiesWrapper>
      )}
      {place.good_for && place.good_for.length !== 0 && (
        <AmitiesWrapper>
          <Text className="is-uppercase" size="smaller" color="darkGrey">
            Known For
          </Text>
          <Text size="tiny" color="darkGrey">
            {place.good_for.map(
              (item, index) =>
                `${item.description}${index !== place.good_for.length - 1 ? ', ' : ''}`,
            )}
          </Text>
        </AmitiesWrapper>
      )}
      {place.ambience && place.ambience.length !== 0 && (
        <AmitiesWrapper>
          <Text className="is-uppercase" size="smaller" color="darkGrey">
            ambience
          </Text>
          <Text size="tiny" color="darkGrey">
            {place.ambience.map(
              (item, index) =>
                `${item.description}${index !== place.ambience.length - 1 ? ', ' : ''}`,
            )}
          </Text>
        </AmitiesWrapper>
      )}
      <div className="columns">
        <div className="column">
          {featureArray.length !== 0 && (
            <Text
              className="is-uppercase"
              size="smaller"
              color="darkGrey"
              paddingBottom={0.5}
              style={{ marginLeft: '0.5rem' }}
            >
              Features
            </Text>
          )}
          <div className="columns is-multiline">
            {showMoreFeature.map(item => (
              <div className="column is-3" key={item.name}>
                <div className="is-flex">
                  <IconWrapper>
                    <Text size="tiny" color="tertiaryColor">
                      <IoMdSunny />
                    </Text>
                  </IconWrapper>
                  &nbsp;
                  <Text color="darkGrey" size="tiny">
                    {item.description}
                  </Text>
                </div>
              </div>
            ))}
          </div>
          {featureArray.length !== featureArrayCheckNull.length && (
            <a
              onClick={() => {
                setShowMoreFeatureActive(!showMoreFeatureActive);
                setShowMoreFeature(showMoreFeatureActive ? featureArray : featureArrayCheckNull);
              }}
            >
              <Text weight="semibold" size="tiny" color="primaryColor">
                {showMoreFeatureActive ? 'Show Less' : 'Show More'}
              </Text>
            </a>
          )}
        </div>
        <div className="column is-4">
          <PlaceHours place={place} />
        </div>
      </div>
      <Content>
        <FlexRows justify="space-between">
          <div className="is-flex">
            <Text size="smaller" color="darkGrey">
              Is this your business ?
            </Text>
            &nbsp;
            <Link to={`/claim-your-place/${place.place_id}`}>
              <Text size="smaller" color="primaryColor">
                Claim this place
              </Text>
            </Link>
          </div>
          <div className="is-flex">
            <Text size="smaller" color="darkGrey">
              Is something wrong ?
            </Text>
            &nbsp;
            <Link to="#">
              <Text size="smaller" color="primaryColor">
                Help us improve our data
              </Text>
            </Link>
          </div>
        </FlexRows>
      </Content>
    </Container>
  );
};

export default Information;
