import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FlexRows, Icon, Text, FlexItem } from '../../../components/elements';

const Container = styled.div``;

const SecondaryHeader = styled(FlexRows)`
  padding: 1rem 0.5rem;
`;

const Elements = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const Wrapper = styled.div`
  padding: 0rem 1rem;
`;

const Tab = styled(FlexRows)`
  padding: 1rem 0rem;
  margin: 0rem 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const MobileView = ({ children }) => (
  <>
    <Container>
      <Link to="/all-badges">
        <SecondaryHeader justify="space-between">
          <Text className="is-uppercase" color="primaryColor" size="small" weight="bold">
            View All Badges
          </Text>
          <Icon iconName="fas fa-chevron-right" color="specialGrey" />
        </SecondaryHeader>
      </Link>

      <Elements>
        <Text className="is-uppercase" weight="semibold" size="small">
          Congratulations on your Achievements!
        </Text>
        <Text size="tiny" color="darkGrey" paddingBottom={1}>
          You have earned the following badges:
        </Text>
        <FlexRows justify="flex-start" align="flex-start">
          <div>
            <Icon name="kraver" size={2} />
          </div>
          <Wrapper>
            <Text size="small" weight="bold">
              Kraver
            </Text>
            <Text color="specialGrey" size="tiny">
              Your first share and review
            </Text>
          </Wrapper>
        </FlexRows>
      </Elements>
      <Link to="/item-review">
        <a>
          <Tab>
            <FlexItem grow={1}>
              <Icon name="share" size={1.5} />
            </FlexItem>
            <FlexItem grow={8}>
              <Text className="is-uppercase" color="primaryColor" size="medium">
                Share An Item
              </Text>
            </FlexItem>
            <FlexItem grow={1}>
              <Icon iconName="fas fa-angle-right" color="specialGrey" />
            </FlexItem>
          </Tab>
        </a>
      </Link>
      <a>
        <Tab>
          <FlexItem grow={1}>
            <Icon name="reviewPlace" size={2} />
          </FlexItem>
          <FlexItem grow={8}>
            <Text className="is-uppercase" color="primaryColor" size="medium">
              Review A Place
            </Text>
          </FlexItem>
          <FlexItem grow={1}>
            <Icon iconName="fas fa-angle-right" color="specialGrey" />
          </FlexItem>
        </Tab>
      </a>
    </Container>
    {children}
  </>
);

export default MobileView;
