import React from 'react';
import ReactSelectInput from './ReactSelectInput';

const MySelect = ({ value, options, onChange, ...props }) => (
  <ReactSelectInput
    defaultValue={value}
    options={options}
    onChange={onChange}
    isDisabled={props.disabled}
  />
);

export default MySelect;
