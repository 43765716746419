import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';

import { Text, FlexRows } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 30rem;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    }
    .modal-card-head {
      padding: 1rem 1rem;
      padding-bottom: 0.6rem;
      background-color: rgba(255, 255, 255, 1);
      border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
    }
    .modal-card-body {
      padding: 2rem;
      padding-top: 1rem;
    }
    a {
      color: ${colors.primaryColor};
    }
  }
`;

const Header = styled(FlexRows)`
  padding: 0rem 0rem;
  width: 100%;
  .open-hours-title {
    margin-left: 10rem;
  }
`;

const HoursItem = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid #e0dada;
  margin-bottom: 1rem;
`;

const OpenHoursModal = ({ currentMenu, isActiveModal, setIsActiveModal }) => {
  return (
    <Container className={`modal ${isActiveModal && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <Header justify="space-between">
            <Text size="large" weight="semibold" paddingBottom={0.5} className="open-hours-title">
              Open Hours
            </Text>
            <IoMdClose
              size={20}
              color={colors.dark}
              style={{ marginLeft: '1rem', cursor: 'pointer' }}
              onClick={() => setIsActiveModal(false)}
            />
          </Header>
        </header>
        <div className="modal-card-body">
          {currentMenu &&
            currentMenu.menu_hour.map(item => {
              if (item.option.length === 0 || item.option[0].start === null) {
                return null;
              }
              return (
                <HoursItem>
                  <Text color="primaryColor" size="small" weight="medium">
                    {item.day}
                  </Text>
                  {item.option.map(item2 => (
                    <Text color="darkGrey" size="small" weight="regular">
                      {item2.start} - {item2.end}
                    </Text>
                  ))}
                </HoursItem>
              );
            })}
        </div>
      </div>
    </Container>
  );
};

export default withRouter(OpenHoursModal);
