import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import ReviewItem from './ReviewItem';
import Loading from '../Loading';
import Empty from '../Empty';
import { Text } from '../../../../../components/elements';

const Header = styled.div`
  padding: 0.5rem 0rem;
`;

const searchItemReviewQuery = gql`
  query searchItemReview($input: SearchInput) {
    search_item_review(input: $input) {
      item_review_listing {
        place_id
        item_id
        item_review_id
        rating
        content
        status
        audit {
          created_at
          created_by
        }
        place {
          name
          address_line_1
          address_line_2
          city
          state
          country
          post_code
          latitude
          longitude
          timezone
        }
        item {
          item_id
          place_id
          place_name
          name
          address_line_1
          address_line_2
          city
          state
          country
          post_code
          latitude
          longitude
          timezone
        }
      }
    }
    search_social(input: { filter: { social_filter: { object_type: "ITEM_REVIEW" } } }) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 0rem 1rem;
`;

const Reviews = () => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Query
      query={searchItemReviewQuery}
      variables={{
        input: {
          filter: {
            item_review_filter: {
              user_id: userId,
              draft: false,
              status: ['APPROVED', 'SUBMITTED'],
            },
          },
        },
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <Empty isError />;
        }

        if (isNull(data.search_item_review)) {
          return <Empty />;
        }

        if (data.search_item_review.item_review_listing.length === 0) {
          return <Empty />;
        }
        const reviews = data.search_item_review.item_review_listing;
        const socials = data.search_social.social_aggregation.total_social_per_object_id;
        return (
          <Container>
            <Header>
              <Text size="large" weight="semibold">
                My Item Reviews
              </Text>
            </Header>
            {reviews.map(review => (
              <ReviewItem review={review} socials={socials} key={review.item_review_id} />
            ))}
          </Container>
        );
      }}
    </Query>
  );
};

export default Reviews;
