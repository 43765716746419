import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { first } from 'lodash';
import moment from 'moment';

import { FlexRows, Text } from '../../components/elements';
import MobileOrderEvent from './Events/MobileOrderEvent';
import Loading from '../../components/global/Loading';
import client from '../../utils/apolloClient';
import MobileFood from './Food/MobileFood';

const Container = styled.div``;

const SecondaryHeader = styled(FlexRows)`
  position: fixed;
  width: 100%;
  z-index: 50;
  background-color: #ffffff;
`;

const Wrapper = styled.div`
  padding-top: 4rem;
`;

const Item = styled.a`
  padding: 1rem 0rem;
  margin: 0rem 1rem;
  border-bottom: 2px solid
    ${({ theme: { colors }, active }) => (active ? colors.primaryColor : 'transparent')};
  width: 100%;
`;

const TextStyle = styled(Text).attrs({
  className: 'is-uppercase',
  align: 'center',
  weight: 'bold',
  size: 'small',
})``;

const bookingQuery = gql`
  query search_event_bookings($input: SearchInput) {
    search_event_bookings(input: $input) {
      event_booking_listing {
        event_id
        booking_id
        booking_number
        url
        booking {
          booked_at
        }
      }
    }
  }
`;

const searchEventsQuery = gql`
  query search_events($input: SearchInput) {
    search_events(input: $input) {
      event_listing {
        event_id
        name
        place_name
        start
        end
        minimum_price
        maximum_price
        description
        address_line_1
        city
        state
        country
        post_code
        latitude
        longitude
      }
    }
  }
`;

const MobileView = () => {
  const { userId } = useStoreState(state => state.auth);
  const [active, setActive] = useState('active');
  const [bookingData, setBookingData] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment().toISOString());

  useEffect(() => {
    setCurrentDate(moment().toISOString());
  }, []);

  return (
    <>
      <Container>
        <SecondaryHeader justify="space-around">
          <Item active={active === 'active'} onClick={() => setActive('active')}>
            <TextStyle color={active === 'active' ? 'primaryColor' : 'dark'}>
              Active Orders
            </TextStyle>
          </Item>
          <Item active={active === 'past'} onClick={() => setActive('past')}>
            <TextStyle color={active === 'past' ? 'primaryColor' : 'dark'}>Past Orders</TextStyle>
          </Item>
        </SecondaryHeader>
        <Wrapper>
          <Query
            client={client.clientPrivate}
            query={bookingQuery}
            variables={{
              input: {
                filter: {
                  event_booking_filter: { user_id: userId },
                },
              },
            }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }
              const { search_event_bookings } = data;
              if (
                search_event_bookings.event_booking_listing &&
                search_event_bookings.event_booking_listing.length === 0
              ) {
                return 'No data found';
              }

              const eventsIds = search_event_bookings.event_booking_listing.map(
                item => item.event_id,
              );
              const event_filter = {};
              Object.assign(
                event_filter,
                { event_id: eventsIds },
                active === 'active' && { end_date_range: { start_date: currentDate } },
                active === 'past' && {
                  end_date_range: { end_date: moment(currentDate).subtract(1, 'day') },
                },
              );

              return (
                <Query
                  query={searchEventsQuery}
                  variables={{
                    input: {
                      filter: {
                        event_filter,
                      },
                    },
                  }}
                >
                  {({ data: data2, loading: loading2, error: error2 }) => {
                    if (loading2) {
                      return <Loading />;
                    }
                    if (error2) {
                      return 'error';
                    }

                    if (data2.search_events.event_listing.length === 0) {
                      return <Container>No data found</Container>;
                    }

                    return (
                      <View
                        bookings={search_event_bookings.event_booking_listing}
                        events={data2.search_events.event_listing}
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                        active={active}
                      />
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        </Wrapper>
      </Container>
    </>
  );
};

const View = ({ bookings, events, bookingData, setBookingData }) => {
  useEffect(() => {
    const finalBookings = bookings.map(item => {
      const event =
        events.length !== 0 ? events.filter(item2 => item2.event_id === item.event_id) : null;
      return {
        ...item,
        event: event.length !== 0 ? first(event) : null,
      };
    });
    setBookingData(finalBookings);
  }, [bookings, events, events.length, setBookingData]);
  return (
    <>
      {/* <MobileFood status="Delivered" />
      <MobileFood status="Confirmed" active="active" />
      <MobileFood status="Delivered" /> */}
      {bookingData.map(item => (
        <MobileOrderEvent
          key={item.booking_id}
          eventId={item.event_id}
          bookingId={item.booking_id}
          orderNumber={item.booking_number}
          orderDate={item.booking.booked_at}
          bookingUrl={item.url}
          event={item.event}
        />
      ))}
    </>
  );
};

export default MobileView;
