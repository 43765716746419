import React from 'react';
import styled from 'styled-components';

import { UploadImage } from '../../../../components/elements';

const Container = styled.div`
  padding: 0rem 1rem;
`;

const Images = ({ setFieldValue }) => {
  return (
    <Container>
      <div className="columns is-multiline">
        <UploadImage
          totalNumberOfUploadedImage={0}
          s3UploadPath="place/"
          metaData={{
            place_id: '',
            user_id: '2290802a-c911-4718-a829-5edc420e0d05',
          }}
          setFieldValue={setFieldValue}
        />
      </div>
    </Container>
  );
};
export default Images;
