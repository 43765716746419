import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { first } from 'lodash';

import { Text } from '../../../../../components/elements';
import GetImages from '../../../../../components/GetImages';

const Container = styled.article`
  padding-bottom: 1rem;
  justify-content: center;
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const ReviewedItemHeader = ({ itemId, item, place, children }) => {
  return (
    <Container className="media">
      <figure className="media-left">
        <GetImages
          name={item.name}
          maskProps={{ width: 64, height: 64 }}
          variables={{
            object_type: ['ITEM'],
            object_id: itemId,
            links_to_id: [itemId],
            links_to_type: ['ITEM'],
          }}
        />
      </figure>
      <div className="media-content">
        <div className="content">
          <div>
            <Text className="is-capitalized" size="medium" weight="semibold">
              {item.name}
            </Text>
            <Text size="micro" color="darkColor">
              {place.name}
            </Text>
            <Text size="micro" color="darkColor">
              {place.address_line_1}, {place.city}, {place.state} {place.post_code}
            </Text>
          </div>
        </div>
      </div>
      <div className="media-right">{children}</div>
    </Container>
  );
};

ReviewedItemHeader.defaultProps = {};

ReviewedItemHeader.propTypes = {};

export default ReviewedItemHeader;
