import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { kebabCase } from 'lodash';
import { FaApple, FaGoogle, FaWindows } from 'react-icons/fa';
// import { FaGoogle } from 'react-icons/fa';

import { Icon, Text } from './elements';

import addToCalendar from '../utils/addToCalendar';
import { getZoneTime } from '../utils/eventHelpers';

const ButtonAnimation = posed.a({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const Button = styled(ButtonAnimation)`
  &&& {
    border: 0;
    ${({ paddingLess }) => paddingLess && `padding:0.2rem 0.5rem`};
    background-color: transparent;
  }
`;

const AddToCalender = ({ event }) => {
  const [eventStartDate, setEventStartDate] = useState(event.start);
  const [eventEndDate, setEventEndDate] = useState(event.end);

  useEffect(() => {
    const fetData = async () => {
      const startDate = await getZoneTime(event.start, event.latitude, event.longitude);
      const endDate = await getZoneTime(event.end, event.latitude, event.longitude);
      setEventStartDate(startDate);
      setEventEndDate(endDate);
    };
    fetData();
  }, [event]);

  const eventData = {
    startTime: eventStartDate,
    endTime: eventEndDate,
    title: event.name,
    description: event.description,
    location: `${event.address_line_1}, ${event.city} ${event.state}, ${event.country}`,
  };

  const handleCalender = value => {
    window.open(addToCalendar.buildUrl(eventData, value));
  };

  return (
    <>
      <div className="dropdown is-up is-hoverable">
        <div className="dropdown-trigger">
          <Button className="button">
            <span className="icon">
              <Icon name="dateBlue" size={1.2} />
            </span>
            <span>
              <Text size="small" weight="semibold" color="darkGrey">
                Add to Calendar
              </Text>
            </span>
          </Button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <a
              className="dropdown-item"
              href={addToCalendar.buildUrl(eventData, 'apple')}
              download={`${kebabCase(event.name)}-calender.ics`}
            >
              <div className="is-flex">
                <span className="icon">
                  <FaApple size={22} />
                </span>
                <Text size="small" weight="semibold" color="darkGrey">
                  Apple Calendar
                </Text>
              </div>
            </a>
            <a className="dropdown-item" onClick={() => handleCalender('google')}>
              <div className="is-flex">
                <span className="icon">
                  <FaGoogle size={20} />
                </span>
                <Text size="small" weight="semibold" color="darkGrey">
                  Google (online)
                </Text>
              </div>
            </a>
            <a
              className="dropdown-item"
              href={addToCalendar.buildUrl(eventData, 'apple')}
              download={`${kebabCase(event.name)}-calender.ics`}
            >
              <div className="is-flex">
                <span className="icon">
                  <FaWindows size={20} />
                </span>
                <Text size="small" weight="semibold" color="darkGrey">
                  Outlook
                </Text>
              </div>
            </a>
            <a className="dropdown-item" onClick={() => handleCalender('outlookcom')}>
              <div className="is-flex">
                <span className="icon">
                  <FaWindows size={20} />
                </span>
                <Text size="small" weight="semibold" color="darkGrey">
                  Outlook.com (online)
                </Text>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

AddToCalender.defaultProps = {};

AddToCalender.propTypes = {};

export default AddToCalender;
