import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';

import {
  Flex,
  Text,
  Field,
  SelectField,
  TextArea,
  FlexRows,
} from '../../components/elements';
// import PlaceDataCorrections from '../PlaceDataCorrections';

const Container = styled.div`
  padding: 1rem;
`;
const FieldWrapper = styled.div`
  padding: 1rem 0.5rem;
`;

const FieldStyle = styled(Field).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;
const SelectFieldStyle = styled(SelectField).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;
const FooterButton = styled.a`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.8rem 1rem;
  position: fixed;
  bottom: 0;
`;

const MobileForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  loading,
}) => (
  <React.Fragment>
    <Container>
      <FieldWrapper>
        <FieldStyle
          id="addressType"
          name="addressType"
          label="Address Type"
          placeholder="Home , Office , Beach House"
          value={values.addressType}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.addressType && touched.addressType && (
          <div className="has-text-danger">{errors.addressType}</div>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          id="name"
          name="name"
          label="Name"
          placeholder="John"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.name && touched.name && (
          <div className="has-text-danger">{errors.name}</div>
        )}
      </FieldWrapper>

      <FieldWrapper>
        <FieldStyle
          id="addressLine1"
          name="addressLine1"
          label=" Address Line 1"
          placeholder="Enter"
          value={values.addressLine1}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.addressLine1 && touched.addressLine1 && (
          <div className="has-text-danger">{errors.addressLine1}</div>
        )}
      </FieldWrapper>
      <FlexRows justify="space-between" align="flex-start">
        <FieldWrapper>
          <FieldStyle
            id="suburb"
            name="suburb"
            label="Suburb"
            placeholder="Enter"
            value={values.addressLine1}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.suburb && touched.suburb && (
            <div className="has-text-danger">{errors.suburb}</div>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <FieldStyle
            id="state"
            name="state"
            label="State"
            placeholder="select"
            value={values.state}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.state && touched.state && (
            <div className="has-text-danger">{errors.state}</div>
          )}
        </FieldWrapper>
      </FlexRows>
      <FlexRows justify="space-between" align="flex-start">
        <FieldWrapper>
          <FieldStyle
            id="postcode"
            name="postcode"
            label="Post Code"
            placeholder="Enter"
            value={values.postcode}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.postcode && touched.postcode && (
            <div className="has-text-danger">{errors.postcode}</div>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <FieldStyle
            id="country"
            name="country"
            label="Country"
            placeholder="select"
            value={values.addressLine1}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.addressLine1 && touched.addressLine1 && (
            <div className="has-text-danger">{errors.addressLine1}</div>
          )}
        </FieldWrapper>
      </FlexRows>
    </Container>

    <FooterButton>
      <Flex>
        <Text
          className="is-uppercase"
          color="white"
          align="center"
          weight="bold"
          size="medium"
          letterSpacing="loose"
        >
          Add
        </Text>
      </Flex>
    </FooterButton>
  </React.Fragment>
);

const MyEnhancedForm = withFormik({
  mapPropsToValues: () => ({
    name: '',
    mobile: '',
    email: '',
    subject: 'Other',
    message: '',
  }),

  // Custom sync validation
  validate: values => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.mobile) {
      errors.mobile = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    }
    if (!values.subject) {
      errors.subject = 'Required';
    }
    if (!values.message) {
      errors.message = 'Required';
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  },

  displayName: 'ContactUsForm',
})(MobileForm);

export default MyEnhancedForm;
