import React, { useState } from 'react';
import styled from 'styled-components';
import { times, isUndefined, first } from 'lodash';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';

import {
  FlexRows,
  IconActionButton,
  Icon,
  ShowMoreText,
  Text,
  FlexColumns,
  Avatar,
} from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';
import ReviewImageView from './ReviewImageView';
import ReviewCommentList from './ReviewCommentList';
import SocialActionButton from '../../../../components/SocialActionButton';
import FlaggedActionButton from '../../../../components/FlaggedActionButton';
import FollowButtonAction from '../../../../components/FollowButtonAction';
import { parseSocialPlace } from '../../../../utils/helpers';

const Container = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${colors.lightGreyBeta};
`;
const IconWrapper = styled.span`
  padding: 0.1rem;
`;

const ButtonWrapper = styled.div`
  padding-top: 0.5rem;
`;
const Heading = styled.div`
  padding-left: 0.5rem;
`;

const IconStyle = styled(Icon).attrs({ size: 0.8 })``;
const SocialActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'smaller', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const IconActionButtonStyle = styled(IconActionButton).attrs({
  textStyle: { size: 'smaller', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;
const FlaggedActionButtonStyle = styled(FlaggedActionButton).attrs({
  textStyle: { size: 'smaller', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const ReviewItem = ({ review, socials }) => {
  const { userId } = useStoreState(state => state.auth);
  const socialData = socials.filter(item => item.object_id === review.item_review_id);
  const socialArray = socialData.length !== 0 ? first(socialData).total_social_count : [];
  const social = parseSocialPlace(socialArray, userId);
  const [totalComments, setTotalComments] = useState(0);
  const [totalLike, setTotalLike] = useState(social.like.count);
  const user = review.user_details;
  return (
    <Container>
      <FlexRows justify="space-between" align="flex-start">
        <div>
          <FlexRows justify="flex-start" align="flex-start">
            <Avatar
              name={user && user.display_name}
              maskProps={{ width: 40 }}
              maskTextProps={{
                color: 'white',
                weight: 'bold',
                size: 'small',
                letterSpacing: 'loose',
              }}
              borderRadius={25}
              color={user && user.color_code}
              image={user && user.profile_image_url}
            />

            <Heading>
              <Text className="is-capitalized" size="medium" weight="semibold">
                {user && user.display_name}
              </Text>
              <Text size="micro" color="darkGrey">
                {moment(review.audit.created_at).fromNow()}
              </Text>
            </Heading>
          </FlexRows>
        </div>
        <div>
          <FlexColumns>
            <ButtonWrapper>
              <FollowButtonAction
                objectId={review.audit.created_by}
                objectType="USER"
                textProps={{ size: 'micro', weight: 'semibold' }}
                buttonProps={{ width: 0.4 }}
              />
            </ButtonWrapper>
            <div className="is-flex">
              {times(review.rating).map(() => (
                <IconWrapper>
                  <IconStyle name="itemRatingGoldSolid" />
                </IconWrapper>
              ))}
              {times(5 - review.rating).map(() => (
                <IconWrapper>
                  <IconStyle name="itemRatingSolidGrey" />
                </IconWrapper>
              ))}
            </div>
          </FlexColumns>
        </div>
      </FlexRows>
      <ShowMoreText content={review.content} limit={500} size="smaller" />
      <br />
      <ReviewImageView placeId={review.place_id} itemReviewId={review.item_review_id} />
      <FlexRows justify="space-between">
        <SocialActionButtonStyle
          social={review.social}
          objectId={review.item_review_id}
          objectType="ITEM_REVIEW"
          type="LIKE"
          name="LIKE"
          defaultIcon="likeGrey"
          activeIcon="likeBlue"
        />
        <IconActionButtonStyle name="COMMENT" icon="commentBlue" />
        <FlaggedActionButtonStyle
          createdBy={review.audit.created_by}
          objectId={review.item_review_id}
          parentId={review.place_id}
          objectType="ITEM_REVIEW"
          name="FLAG"
          icon="flagGrey"
        />
      </FlexRows>
      <ReviewCommentList
        placeId={review.place_id}
        itemId={review.item_id}
        itemReviewId={review.item_review_id}
        totalComments={totalComments}
        setTotalComments={setTotalComments}
      />
    </Container>
  );
};

export default ReviewItem;
