/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import { concat, uniq, uniqBy, forEach } from 'lodash';
import moment from 'moment';

import {
  // getPlaceTypes,
  getCuisines,
  getItemTypes,
  // getFeatures,
  // getServiceCategory,
  // getServicesTypes,
  // getDressCodes,
  // getDiningStyle,
  // getPublicTransports,
  // getAmbience,
} from '../utils/fetchStatic';

const dateFilter = {
  name: 'today',
  description: 'Today',
  range: {
    start_date_range: {
      start_date: moment()
        .subtract(6, 'months')
        .format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },
    end_date_range: {
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment()
        .add(6, 'months')
        .format('YYYY-MM-DD'),
    },
  },
};

export default {
  event: {
    dateFilter,
    allDateFilter: [],
    filterTags: {
      listingType: [],
      ticketType: [],
      eventType: [],
      eventCategory: [],
      feature: [],
      ageRestriction: [],
      dressCode: [],
    },

    filterInputs: {
      listingType: [],
      eventType: [],
      eventCategory: [],
      feature: [],
      ageRestriction: [],
      dressCode: [],
    },
    resetEventFilter: action(state => {
      state.filterInputs = {
        listingType: [],
        eventType: [],
        eventCategory: [],
        feature: [],
        ageRestriction: [],
        dressCode: [],
      };
      // state.dateFilter = dateFilter;
    }),
    updateDateFilter: action((state, payload) => {
      state.dateFilter = payload;
    }),
    updateAllDateFilter: action((state, payload) => {
      state.allDateFilter = payload;
    }),

    updateFilterTags: action((state, payload) => {
      const emptyArray = [];
      let ticketType = emptyArray;
      let listingType = emptyArray;
      let eventType = emptyArray;
      let eventCategory = emptyArray;
      let feature = emptyArray;
      let ageRestriction = emptyArray;
      let dressCode = emptyArray;

      forEach(payload, item => {
        if (item.aggregation_name === 'ticket_type') {
          ticketType = item.aggregation_listing;
        }
        if (item.aggregation_name === 'listing_type') {
          listingType = item.aggregation_listing;
        }
        if (item.aggregation_name === 'event_type') {
          eventType = item.aggregation_listing;
        }
        if (item.aggregation_name === 'event_category') {
          eventCategory = item.aggregation_listing;
        }
        if (item.aggregation_name === 'feature') {
          feature = item.aggregation_listing;
        }
        if (item.aggregation_name === 'restriction') {
          ageRestriction = item.aggregation_listing;
        }
        if (item.aggregation_name === 'dress_code') {
          dressCode = item.aggregation_listing;
        }
      });

      const updateData = {
        ticketType,
        listingType,
        eventType,
        eventCategory,
        feature,
        ageRestriction,
        dressCode,
      };
      state.filterTags = updateData;
    }),

    fetchFilterTags: thunk(async actions => {
      const cuisines = await getCuisines();
      const itemTypes = await getItemTypes();

      const data = {
        cuisines: uniqBy(cuisines, 'name'),
        itemTypes: uniqBy(itemTypes, 'name'),
      };
      actions.updateFilterTags(data);
    }),

    updateFilterInputs: action((state, payload) => {
      const filterEventInputs = {
        ticketType: payload.ticketType,
        listingType: payload.listingType,
        eventType: payload.eventType,
        eventCategory: payload.eventCategory,
        feature: payload.feature,
        ageRestriction: payload.ageRestriction,
        dressCode: payload.dressCode,
      };
      state.filterInputs = filterEventInputs;
    }),

    recentViews: [],
    updateFilters: action((state, payload) => {
      concat(state.currentLocation, payload);
    }),
    fetchFilters: thunk(async (actions, payload) => {
      actions.updateFilters(payload);
    }),
    addRecentView: action((state, payload) => {
      const newRecentView = concat(state.recentViews, payload);
      state.recentViews = uniq(newRecentView);
    }),
  },
};
