import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { isNull, omit } from 'lodash';
import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';
import { Text, FlexRows, FlexItem, Flex, FlexColumns, Button } from '../../../components/elements';
import AddAddressModal from './AddAddressModal';
import client from '../../../utils/apolloClient';
import Loading from '../../../components/global/mobile/MobileLoading';

const MainElement = styled(FlexItem)`
  padding: 0rem 1rem;
`;
// const Container = styled.div`
//   padding-top: 3.4rem;
// `;
const Container = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const Wrapper = styled(FlexColumns)`
  padding-bottom: 0.6rem;
  margin: 0rem 1rem;
  height: 80vh;
`;

const FooterButton = styled.a`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.8rem 1rem;
  position: fixed;
  bottom: 0;
`;

const userQuery = gql`
  query user($userId: String) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      first_name
      last_name
      display_name
      preferred_username
      color_code
      address {
        address_id
        type
        is_default
        contact_name
        contact_phone
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        status
      }
    }
  }
`;

const deleteAddressMutation = gql`
  mutation delete_user_address($input: NavigateUserAddressInput) {
    delete_user_address(input: $input) {
      address_id
      type
      is_default
      contact_name
      contact_phone
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      timezone
      status
      error {
        description
      }
    }
  }
`;

const createAddressMutation = gql`
  mutation create_user_address($input: UserAddressInput) {
    create_user_address(input: $input) {
      address_id
      type
      is_default
      contact_name
      contact_phone
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      timezone
      status
      error {
        description
      }
    }
  }
`;
const updateAddressMutation = gql`
  mutation update_user_address($input: UserAddressInput) {
    update_user_address(input: $input) {
      address_id
      type
      is_default
      contact_name
      contact_phone
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      timezone
      status
      error {
        description
      }
    }
  }
`;
const MobileView = ({ match, history }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const { userId } = useStoreState(state => state.auth);
  const [addEditAction, setAddEditAction] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editAddressData, setEditAddressData] = useState();
  return (
    <Layout
      history={history}
      match={match}
      bottomNavigationEnable={false}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading="ADDRESS"
    >
      <Query
        client={client.clientPrivate}
        query={userQuery}
        variables={{ userId }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }
          const { fetch_user } = data;

          if (fetch_user.address === null) {
            return (
              <Wrapper>
                <Text>Please Add New Address</Text>
              </Wrapper>
            );
          }

          if (fetch_user.address.length === 0) {
            return (
              <Wrapper>
                <Text>Please Add New Address</Text>
              </Wrapper>
            );
          }

          return (
            <Container>
              {fetch_user.address.map(item => (
                <FlexRows justify="space-between" align="flex-start">
                  <FlexItem grow={1} align="center">
                    <input type="radio" />
                  </FlexItem>
                  <MainElement grow={6}>
                    <Text size="small" weight="semibold">
                      {item.type}
                    </Text>
                    <Text size="tiny" color="darkGrey">
                      {item.address_line_1}
                    </Text>
                    <Text size="tiny" color="darkGrey">
                      {item.city}, {item.state}, {item.post_code}
                    </Text>
                    <br />
                    <FlexRows justify="flex-start" align="flex-start">
                      <Mutation
                        client={client.clientPrivate}
                        mutation={deleteAddressMutation}
                        onCompleted={({ delete_user_address }) => {
                          if (!isNull(delete_user_address) && !isNull(delete_user_address.error)) {
                            delete_user_address.error.map(item2 => toast.error(item2.description));
                          } else if (isNull(delete_user_address)) {
                            toast.error('Something went wrong in backned!');
                          } else {
                            toast.success('Address had been deleted successfully!');
                          }
                        }}
                      >
                        {(delete_user_address, { loading: deleteLoading }) => (
                          <Button
                            className={`${deleteLoading && 'is-loading'}`}
                            name="Delete"
                            width={1.5}
                            textProps={{ size: 'tiny', color: 'danger' }}
                            borderHoverColor="danger"
                            onClick={() => {
                              swal('Are you sure delete this Address ?', {
                                dangerMode: true,
                                buttons: true,
                              }).then(willDelete => {
                                if (willDelete) {
                                  delete_user_address({
                                    variables: {
                                      input: { user_id: userId, address_id: item.address_id },
                                    },
                                  });
                                }
                              });
                            }}
                          />
                        )}
                      </Mutation>
                      &nbsp;&nbsp;
                      <Button
                        height={0}
                        width={2}
                        name="Edit"
                        textProps={{
                          size: 'tiny',
                          color: 'darkGrey',
                        }}
                        onClick={() => {
                          setEditAddress(true);
                          setEditAddressData(item);
                          setIsActiveModal(true);
                        }}
                      />
                    </FlexRows>
                  </MainElement>
                </FlexRows>
              ))}
            </Container>
          );
        }}
      </Query>

      <a
        onClick={() => {
          setIsActiveModal(true);
          setAddEditAction(true);
        }}
      >
        <FooterButton>
          <Flex>
            <Text
              className="is-uppercase"
              color="white"
              align="center"
              weight="bold"
              size="medium"
              letterSpacing="loose"
            >
              Add New Address
            </Text>
          </Flex>
        </FooterButton>
      </a>
      {addEditAction && (
        <Mutation
          client={client.clientPrivate}
          mutation={createAddressMutation}
          onCompleted={({ create_user_address }) => {
            if (!isNull(create_user_address.error)) {
              create_user_address.error.map(item => toast.error(item.description));
            } else {
              swal('Great!', 'Address had been created successfully!', 'success').then(() => {
                setAddEditAction(false);
              });
            }
          }}
        >
          {(create_user_address, { loading }) => (
            <AddAddressModal
              isUpdateFormType={false}
              loading={loading}
              isActive={isActiveModal}
              onClose={() => {
                setIsActiveModal(false);
                setAddEditAction(false);
                setEditAddress(false);
              }}
              onSubmit={values => {
                const input = omit(values, 'additionalInfo');
                create_user_address({
                  variables: { input: { user_id: userId, ...input } },
                });
              }}
            />
          )}
        </Mutation>
      )}
      {editAddress && editAddressData && (
        <Mutation
          client={client.clientPrivate}
          mutation={updateAddressMutation}
          onCompleted={({ update_user_address }) => {
            if (!isNull(update_user_address.error)) {
              update_user_address.error.map(item => toast.error(item.description));
            } else {
              swal('Great!', 'Address had been updated successfully!', 'success').then(() => {
                setEditAddress(false);
                setEditAddressData();
              });
            }
          }}
        >
          {(update_user_address, { loading }) => (
            <AddAddressModal
              isUpdateFormType
              address={editAddressData}
              loading={loading}
              isActive={isActiveModal}
              onClose={() => setIsActiveModal(false)}
              onCancel={() => {
                setAddEditAction(false);
                setEditAddress(false);
              }}
              onSubmit={values => {
                const input = omit(values, 'additionalInfo');
                update_user_address({
                  variables: { input: { user_id: userId, ...input } },
                });
              }}
            />
          )}
        </Mutation>
      )}
    </Layout>
  );
};

export default MobileView;
