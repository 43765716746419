/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  &&& {
    margin: 0;
    span {
      margin: 3px;
    }
  }
`;

const Tags = ({ filterStateInputs, filterFuncInputs }) => (
  <Container>
    {filterStateInputs.listingTypeInputs.map(item => (
      <span className="tag">
        Listing only
        <button
          className="delete is-small"
          onClick={() => filterFuncInputs.removeListingTypeInputs(item.name)}
        />
      </span>
    ))}
    {filterStateInputs.ticketTypeInputs.map(item => (
      <span className="tag">
        {item.name === 'PAID_TICKET' ? 'Paid Event' : 'Free Event'}
        <button
          className="delete is-small has-color-danger"
          onClick={() => filterFuncInputs.removeTicketTypeInputs(item.name)}
        />
      </span>
    ))}

    {filterStateInputs.eventTypeInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small has-color-danger"
          onClick={() => filterFuncInputs.removeEventTypeInputs(item.name)}
        />
      </span>
    ))}
    {filterStateInputs.eventCategoryInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small"
          onClick={() => filterFuncInputs.removeEventCategory(item.name)}
        />
      </span>
    ))}
    {filterStateInputs.featureInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small has-color-danger"
          onClick={() => filterFuncInputs.removeFeatures(item.name)}
        />
      </span>
    ))}
    {filterStateInputs.ageRestrictionInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small"
          onClick={() => filterFuncInputs.removeAgeRestriction(item.name)}
        />
      </span>
    ))}
    {filterStateInputs.dressCodeInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small has-color-danger"
          onClick={() => filterFuncInputs.removeDressCode(item.name)}
        />
      </span>
    ))}
  </Container>
);

export default Tags;
