import React, { useState } from 'react';
import styled from 'styled-components';
import { times, reverse, replace } from 'lodash';
// import { useStoreState } from 'easy-peasy';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

import { Text, CheckBox, FlexRows } from '../../../../components/elements';

const Item = styled.div``;
const ItemContent = styled.div`
  padding: 0.25rem 1rem;
`;

const Heading = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding-top: 0.25rem;
  padding-right: 1rem;
`;
const CheckBoxWrapper = styled.div`
  padding: 0rem;
`;

const parseLabel = value => {
  if (value === '1') {
    return '$';
  }
  if (value === '2') {
    return '$$';
  }
  if (value === '3') {
    return '$$$';
  }
  if (value === '4') {
    return '$$$$';
  }
  return '$$$$$';
};

const PriceRangeFilter = ({ priceRangeInputs, filterFuncInputs, priceRange }) => {
  const [toggle, setToggle] = useState(true);

  return (
    <Item>
      <Heading>
        <div className="is-flex">
          <span className="icon">
            <a onClick={() => setToggle(!toggle)}>
              {toggle ? (
                <Text color="dark" size="small" weight="medium">
                  <FaAngleUp />
                </Text>
              ) : (
                <Text color="dark" size="small" weight="medium">
                  <FaAngleDown />
                </Text>
              )}
            </a>
          </span>
          <Text className="is-capitalized" color="dark" size="smedium" weight="regular">
            Price Range
          </Text>
        </div>
        <a onClick={() => filterFuncInputs.onHandleClearItem('priceRange')}>
          <Text className="is-uppercase" color="primaryColor" size="micro" weight="semibold">
            Clear
          </Text>
        </a>
      </Heading>
      {toggle && (
        <ItemContent>
          {reverse(priceRange).map(item => {
            const labelValue = `${parseLabel(item.name)} (${item.count})`;
            return (
              <CheckBoxWrapper>
                <CheckBox
                  label={labelValue}
                  initialValue={
                    priceRangeInputs.filter(item2 => item2 === parseInt(item.name, 10)).length === 1
                  }
                  onChange={value =>
                    value
                      ? filterFuncInputs.addPriceRangeInputs(parseInt(item.name, 10))
                      : filterFuncInputs.removePriceRangeInputs(parseInt(item.name, 10))
                  }
                  onHandleClearItem={() => filterFuncInputs.onHandleClearItem('ambience')}
                />
              </CheckBoxWrapper>
            );
          })}
        </ItemContent>
      )}
    </Item>
  );
};

export default PriceRangeFilter;
