import React from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { withRouter } from 'react-router-dom';
import { Text, Pager } from '../../components/elements';
import DeskForm from './DeskForm';

import { getPlaceTagline } from '../../utils/helpers';

const Container = styled(Pager)`
  padding-top: 2rem;
`;
const Header = styled.div`
  padding-bottom: 1rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;
const DesktopView = ({
  place,
  placeReview,
  isCreated,
  uploadedImages,
  reviewLoading,
  onSubmit,
  history,
}) => {
  const setIsActiveDesktopLoginModal = useStoreActions(
    state => state.auth.setIsActiveDesktopLoginModal,
  );
  const { isLoggedIn } = useStoreState(state => state.auth);
  return (
    <Container>
      <Header>
        <Text className="is-capitalized" size="big" weight="semibold" paddingBottom={0.5}>
          {capitalize(place.name)}
        </Text>
        <Text size="small" color="darkGrey" paddingBottom={0.5}>
          {getPlaceTagline(place.business_type, place.cuisine_style)}
        </Text>
        <Text size="tiny" color="darkGrey">
          {place.address_line_1}
        </Text>
        <Text size="tiny" color="darkGrey">
          {place.city}, {place.state} {place.post_code}
        </Text>
      </Header>

      <DeskForm
        place={place}
        loading={reviewLoading}
        history={history}
        onSubmit={onSubmit}
        placeReview={placeReview}
        isCreated={isCreated}
        uploadedImages={uploadedImages}
        setIsActiveDesktopLoginModal={setIsActiveDesktopLoginModal}
        isLoggedIn={isLoggedIn}
      />
    </Container>
  );
};

export default withRouter(DesktopView);
