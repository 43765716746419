import React, { useState } from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';
import colors from '../../../theme/styles/colors';

import { resetPasswordRequest, confirmPassword } from '../../../utils/auth';
import ForgotPassForm from './ForgotPassForm';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 35rem;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    }
    .modal-card-body {
      padding: 2rem;
    }
    a {
      color: ${colors.primaryColor};
    }
  }
`;

const ForgotPasswordModal = () => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const isActiveModal = useStoreState(state => state.auth.isActiveForgotPassModal);
  const setIsActiveForgotPassModal = useStoreActions(
    state => state.auth.setIsActiveForgotPassModal,
  );

  const handleSubmit = async email => {
    setIsLoading(true);
    try {
      await resetPasswordRequest(email);
      setIsLoading(false);
      setStep(2);
      setError();
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  const handleConfirm = async ({ email, otp, newPassword }) => {
    setIsLoading(true);
    try {
      await confirmPassword(email, otp, newPassword);
      setIsLoading(false);
      setError();
      setStep(1);
      setIsActiveForgotPassModal({ value: false });
      toast.success('Password updated successfully!');
    } catch (err) {
      console.log(err);
      setError(err.message);
      setIsLoading(false);
    }
  };

  return (
    <Container className={`modal ${isActiveModal && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <ForgotPassForm
          step={step}
          isLoading={isLoading}
          apiError={error}
          setIsActiveForgotPassModal={setIsActiveForgotPassModal}
          setStep={setStep}
          onSubmit={values => {
            if (step === 1) {
              handleSubmit(values.email);
            }
            if (step === 2) {
              handleConfirm({ ...values });
            }
          }}
        />
      </div>
    </Container>
  );
};

export default ForgotPasswordModal;
