import React from 'react';
import styled from 'styled-components';

import { FaAngleRight } from 'react-icons/fa';
import { FlexRows, FlexColumns, Text } from '../../../../components/elements';
import Reviews from './Reviews';
import Activities from './Activities';
import Friends from './Friends';
import Places from './Places';
import Payment from './Payments';
import Address from './Address';
import Photos from './Photos';

const IconWrapper = styled.div`
  padding-left: 2rem;
`;

const Wrapper = styled.div`
  && {
    background-color: ${({ theme: { colors } }) => colors.white};
    .columns {
      margin-bottom: 0rem;
    }
  }
`;

const ItemWrapper = styled(FlexColumns).attrs({ align: 'flex-start' })`
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-right: 1rem;
`;

const RightWrapper = styled.div`
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-right: 1rem;
`;

const Item = styled(FlexRows).attrs({ justify: 'space-between' })`
  cursor: pointer;
  padding: 1rem;
  width: 100%;
  /* border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta}; */
  :hover {
    background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha};
  }

  background-color: ${({ theme: { colors }, active }) => active && colors.lightGreyAlpha};
`;

const items = [
  { name: 'My Reviews' },
  { name: 'My Activity' },
  { name: 'My Friends' },
  { name: 'My Photos' },
  { name: 'My Places' },
  { name: 'My Address Details' },
  { name: 'My Payment Details' },
];

const Details = ({ user }) => {
  const [active, setActive] = React.useState('My Reviews');
  return (
    <Wrapper className="container">
      <div className="columns is-gapless">
        <div className="column is-3">
          <ItemWrapper align="flex-start">
            {items.map(item => (
              <Item
                key={item.name}
                active={active === item.name}
                onClick={() => setActive(item.name)}
              >
                <Text
                  size="medium"
                  weight={active === item.name ? 'semibold' : 'regular'}
                  color={active === item.name ? 'darkGrey' : 'specialGrey'}
                >
                  {item.name}
                </Text>
                <IconWrapper>
                  <Text size="large" color="specialGrey">
                    <FaAngleRight />
                  </Text>
                </IconWrapper>
              </Item>
            ))}
          </ItemWrapper>
        </div>
        <RightWrapper className="column">
          {active === 'My Reviews' && <Reviews />}
          {active === 'My Activity' && <Activities />}
          {active === 'My Friends' && <Friends />}
          {active === 'My Photos' && <Photos />}
          {active === 'My Places' && <Places />}
          {active === 'My Address Details' && <Address addresses={user.address} />}
          {active === 'My Payment Details' && <Payment />}
        </RightWrapper>
      </div>
    </Wrapper>
  );
};

export default Details;
