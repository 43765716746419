import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Image } from '../../../../components/elements';
import eventDefault from '../../../../assets/images/event-default.png';
import { getEncodedImage } from '../../../../utils/s3';

const Figure = styled.figure`
  && {
    img {
      border-radius: 4px;
    }
  }
`;

const EmptyWrapper = styled.div`
  &&& {
    position: relative;
    .img-opacity {
      opacity: 0.6;
    }
  }
`;

const EmptyImage = ({ eventId }) => (
  <EmptyWrapper>
    <Link to={`/event/${eventId}`}>
      <Figure className="image is-2by1 img-opacity">
        <Image source={eventDefault} />
      </Figure>
    </Link>
  </EmptyWrapper>
);

const ImageView = ({ event }) => {
  if (event.default_image_url === null || event.default_image_url === '') {
    return <EmptyImage eventId={event.event_id} />;
  }

  return (
    <Link to={`/event/${event.event_id}`}>
      <Figure className="image is-2by1">
        <Image source={getEncodedImage(event.default_image_url, 400, 200)} />
      </Figure>
    </Link>
  );
};

export default ImageView;
