import React from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';

import DesktopForm from './DesktopForm';
import DeskHeader from './DeskHeader';
import { Pager } from '../../components/elements';

const Container = styled(Pager)`
  padding-top: 2rem;
`;

const DesktopView = ({
  item,
  loading,
  history,
  onSubmit,
  itemReview,
  uploadedImages,
  isCreated,
}) => {
  const setIsActiveDesktopLoginModal = useStoreActions(
    state => state.auth.setIsActiveDesktopLoginModal,
  );
  const { isLoggedIn } = useStoreState(state => state.auth);
  return (
    <Container>
      <DeskHeader item={item} />
      {itemReview && (
        <DesktopForm
          history={history}
          itemReview={itemReview}
          loading={loading}
          onSubmit={onSubmit}
          uploadedImages={uploadedImages}
          isCreated={isCreated}
          setIsActiveDesktopLoginModal={setIsActiveDesktopLoginModal}
          isLoggedIn={isLoggedIn}
        />
      )}
      {!itemReview && (
        <DesktopForm
          history={history}
          itemReview={itemReview}
          loading={loading}
          onSubmit={onSubmit}
          uploadedImages={uploadedImages}
          isCreated={isCreated}
          setIsActiveDesktopLoginModal={setIsActiveDesktopLoginModal}
          isLoggedIn={isLoggedIn}
        />
      )}
    </Container>
  );
};

export default DesktopView;
