import React, { useState } from 'react';
import styled from 'styled-components';
import { isNull, forEach, first } from 'lodash';
import moment from 'moment';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import colors from '../../../theme/styles/colors';
import ApplyVoucherForm from './ApplyVoucherForm';

import { Text, Flex, FlexColumns, FlexRows, CheckoutButton } from '../../../components/elements';

const Header = styled(Flex).attrs({ justify: 'flex-start' })`
  padding: 0.5rem;
  background-color: ${colors.lightGreyBeta};
`;

const Content = styled.div`
  padding: 0rem 1rem;
`;

const Item = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${colors.lightGreyBeta};
`;

const ItemElement = styled(FlexRows)`
  padding: 1rem 0rem;
`;

// const Footer = styled.div`
//   position: fixed;
//   bottom: 0;
//   width: 100%;
// `;
// const FooterButton = styled.div`
//   background-color: ${colors.primaryColor};
//   z-index: 50;
//   width: 100%;
//   opacity: ${({ active }) => (active ? 1 : 0.7)};
//   padding: 0.8rem 1rem;
// `;

const ButtonWrapper = styled.div`
  padding: 0.8rem 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 50;
  background-color: white;
`;

const ButtonText = styled(Text).attrs({ weight: 'semibold', size: 'medium' })``;

const PriceBar = styled.div`
  background-color: ${colors.lightGreyBeta};
  z-index: 50;
  width: 100%;
  padding: 0.8rem 1rem;
`;

const IconWrapper = styled.span`
  padding: 0rem 1rem;
`;

const StatusWrapper = styled.span`
  background: #c7c6c6;
  padding: 6px 17px;
  width: auto;
  font-size: 10px;
`;

const PromoCode = styled(FlexRows).attrs({ justify: 'flex-end' })`
  &&& {
    padding: 1rem 0rem;
  }
`;

const OrderSummary = styled.div`
  background-color: ${({ theme: { colors: color } }) => color.lightGreyTheta};
  padding: 1rem;
`;

const StatusQuantityHandler = ({
  ticket,
  ticketBookingFee,
  ticketSelections,
  handleTicketSelection,
  remainingTickets,
  displayRemainingTicket,
}) => {
  const filterTicketSelection = ticketSelections.filter(item => item.id === ticket.ticket_id);
  const quantity = filterTicketSelection.length !== 0 ? first(filterTicketSelection).quantity : 0;
  const [noOfSelectedTicket, setNoOfSelectedTicket] = useState(quantity);

  const onChange = value => {
    const ticketInput = {
      id: ticket.ticket_id,
      name: ticket.name,
      type: ticket.type,
      price: ticket.price,
      totalTicketPrice: parseFloat(ticket.price) * parseFloat(value),
      quantity: parseInt(value, 10),
      ticketBookingFee,
      attributes: ticket.attribute,
    };

    handleTicketSelection(ticketInput);
    setNoOfSelectedTicket(parseInt(value, 10));
  };

  if (ticket.status === 'HOLD') {
    return <StatusWrapper>HOLD</StatusWrapper>;
  }
  if (ticket.status === 'HIDDEN') {
    return <StatusWrapper>HIDDEN</StatusWrapper>;
  }

  if (remainingTickets <= 0) {
    return <StatusWrapper>SOLD OUT</StatusWrapper>;
  }
  if (moment(ticket.sale_start).isAfter(new Date())) {
    return <StatusWrapper>SALE NOT STARTED</StatusWrapper>;
  }

  if (moment(ticket.sale_end).isBefore(new Date())) {
    return <StatusWrapper>SALES ENDED</StatusWrapper>;
  }
  // console.log('ticket', display_remaining_ticket);
  return (
    <FlexColumns>
      <div className="is-flex">
        <a
          onClick={() => {
            if (noOfSelectedTicket !== 0 && noOfSelectedTicket > ticket.minimum) {
              onChange(noOfSelectedTicket - 1);
            } else if (noOfSelectedTicket !== 0) {
              onChange(noOfSelectedTicket - ticket.minimum);
            }
          }}
        >
          <IconWrapper>
            <FiMinusCircle
              size={20}
              color={noOfSelectedTicket === 0 ? colors.specialGrey : colors.primaryColor}
            />
          </IconWrapper>
        </a>
        <Text size="tiny" color="darkGrey" weight="semibold">
          {noOfSelectedTicket}
        </Text>
        <a
          onClick={() => {
            if (
              noOfSelectedTicket !== remainingTickets &&
              noOfSelectedTicket !== ticket.maximum &&
              noOfSelectedTicket >= ticket.minimum
            ) {
              onChange(noOfSelectedTicket + 1);
            } else if (
              noOfSelectedTicket !== remainingTickets &&
              noOfSelectedTicket !== ticket.maximum
            ) {
              onChange(noOfSelectedTicket + ticket.minimum);
            }
          }}
        >
          <IconWrapper>
            <FiPlusCircle
              size={20}
              color={
                noOfSelectedTicket === remainingTickets || noOfSelectedTicket === ticket.maximum
                  ? colors.specialGrey
                  : colors.primaryColor
              }
            />
          </IconWrapper>
        </a>
      </div>
      {displayRemainingTicket && (
        <Text size="micro" color="darkGrey">
          {remainingTickets} Remaining
        </Text>
      )}
    </FlexColumns>
  );
};

const Tickets = ({
  userId,
  allVouchers,
  event,
  occurrence,
  tickets,
  subTotalPrice,
  setSubTotalPrice,
  totalQuantity,
  bookingFee,
  discount,
  bookingFeeOfEvent,
  setTotalQuantity,
  ticketSelections,
  setTicketSelections,
  setBookingFee,
  isCouponApplied,
  onHandleConfirmBooking,
  eventBookingFee,
  setBookingFeeOfEvent,
  setCheckSelectionHaveAttendeeDetailsOrNot,
  checkSelectionHaveAttendeeDetailsOrNot,
  setStoreOfTicketPlusAttendee,
  applyCouponLoading,
  handleApplyCouponLoading,
  // isCouponModalActive,
  setIsCouponModalActive,
  setIsCouponApplied,
  setCouponCode,
  setDiscount,
  loading,
}) => {
  const handleTicketSelection = async ticket => {
    let newTickets = [];
    const removeExisted = await ticketSelections.filter(item => item.id !== ticket.id);
    newTickets = ticket.quantity !== 0 ? removeExisted.concat(ticket) : removeExisted;
    await setTicketSelections(newTickets);
    let newPrice = 0;
    await newTickets.map(item => {
      newPrice += item.totalTicketPrice;
      return {};
    });
    let newQuantity = 0;
    await newTickets.map(item => {
      newQuantity += parseFloat(item.quantity);
      return {};
    });
    await setSubTotalPrice(newPrice);
    await setTotalQuantity(newQuantity);
    let newBookingFee = 0;
    await newTickets.map(item => {
      newBookingFee += item.ticketBookingFee * item.quantity;
      return {};
    });

    setBookingFee(newBookingFee);
    const checkingFreeOnlyOrPaidWithFee = [];
    await forEach(newTickets, item => {
      if (item.type === 'paid') {
        checkingFreeOnlyOrPaidWithFee.push(item);
      }
    });
    if (checkingFreeOnlyOrPaidWithFee.length === 0) {
      setBookingFeeOfEvent(0);
    } else {
      setBookingFeeOfEvent(eventBookingFee);
    }
    setCheckSelectionHaveAttendeeDetailsOrNot(false);
    forEach(newTickets, item => {
      if (item.attributes.length !== 0) {
        setCheckSelectionHaveAttendeeDetailsOrNot(true);
      }
    });
    setStoreOfTicketPlusAttendee();
  };
  return (
    <div>
      <Header>
        <Text className="is-uppercase" weight="semibold" size="small" lineSpacing="loose">
          Choose Booking Type and Quantity
        </Text>
      </Header>
      <Content>
        {tickets.map(ticket => {
          const checkBookingFee = ticket.fee.filter(item => item.name === 'online_passon_fee');
          const ticketBookingFee = checkBookingFee.length !== 0 ? first(checkBookingFee).value : 0;
          return (
            <Item key={ticket.ticket_id}>
              <Text className="is-capitalized" color="primaryColor" weight="semibold" size="small">
                {ticket.name}
              </Text>
              <ItemElement justify="space-between">
                <Text size="small">${parseFloat(ticket.price).toFixed(2)}</Text>
                <StatusQuantityHandler
                  ticket={ticket}
                  displayRemainingTicket={occurrence.display_remaining_ticket}
                  ticketSelections={ticketSelections}
                  handleTicketSelection={handleTicketSelection}
                  ticketBookingFee={ticketBookingFee}
                  remainingTickets={ticket.quantity - ticket.sold}
                  isCouponApplied={isCouponApplied}
                />
              </ItemElement>
              <Text size="tiny" color="darkGrey" weight="regular">
                Purchase between {ticket.minimum} and {ticket.maximum} ticket(s)
              </Text>
              <Text size="tiny" color="darkGrey">
                Sale start from {moment(ticket.sale_start).format('LLLL')} to{' '}
                {moment(ticket.sale_start).format('L') !==
                moment(isNull(ticket.sale_end) ? event.end : ticket.sale_end).format('L')
                  ? moment(isNull(ticket.sale_end) ? event.end : ticket.sale_end).format('LLLL')
                  : moment(isNull(ticket.sale_end) ? event.end : ticket.sale_end).format('LT')}
              </Text>
            </Item>
          );
        })}
        {totalQuantity !== 0 && (
          <PromoCode>
            <ApplyVoucherForm
              userId={userId}
              allVouchers={allVouchers}
              setIsCouponModalActive={setIsCouponModalActive}
              applyCouponLoading={applyCouponLoading}
              handleApplyCouponLoading={value => handleApplyCouponLoading(value)}
              eventId={event.event_id}
              event={event}
              eventOccurrenceId={occurrence.event_occurrence_id}
              placeId={event.place_id}
              tickets={tickets}
              subTotalPrice={subTotalPrice}
              isCouponApplied={isCouponApplied}
              removedCoupon={() => {
                setDiscount(0);
                setCouponCode('');
                setIsCouponApplied(false);
              }}
              handleDisCount={(discountPrice, voucherCode) => {
                setDiscount(parseFloat(discountPrice).toFixed(2));
                setCouponCode(isNull(voucherCode) ? '' : voucherCode);
                setIsCouponApplied(true);
              }}
            />
          </PromoCode>
        )}
      </Content>
      {totalQuantity !== 0 && (
        <>
          <OrderSummary>
            <Text size="small" weight="semibold" paddingBottom={0.4}>
              Order Summary
            </Text>
            {ticketSelections.map(item => (
              <FlexRows justify="space-between">
                <Text size="smaller">
                  {item.quantity} x {item.name}
                </Text>
                <Text size="smaller" color="darkGrey">
                  ${parseFloat(item.price).toFixed(2)}
                </Text>
              </FlexRows>
            ))}

            <hr />
            <FlexRows justify="space-between">
              <Text size="smaller" color="darkGrey">
                Sub Total
              </Text>
              <Text size="smaller" color="darkGrey">
                ${parseFloat(subTotalPrice).toFixed(2)}
              </Text>
            </FlexRows>
            <FlexRows justify="space-between">
              <Text size="micro" color="darkGrey">
                Ticket Fee
              </Text>
              <Text size="micro" color="darkGrey">
                ${parseFloat(bookingFee).toFixed(2)}
              </Text>
            </FlexRows>
            <FlexRows justify="space-between">
              <Text size="micro" color="darkGrey">
                Booking Fee
              </Text>
              <Text size="micro" color="darkGrey">
                ${parseFloat(bookingFeeOfEvent).toFixed(2)}
              </Text>
            </FlexRows>
            <hr />
            <FlexRows justify="space-between">
              <Text size="small" weight="semibold">
                Total
              </Text>
              <Text size="small" weight="semibold">
                ${parseFloat(subTotalPrice + bookingFee - discount + bookingFeeOfEvent).toFixed(2)}
              </Text>
            </FlexRows>
            <br />
            <Text size="smaller">
              By Clicking `Confirm Tickets`, you agree to our{' '}
              <a style={{ color: colors.primaryColor }}>Terms of Service</a> and event&apos;s
              organizer&apos;s Terms and conditions
            </Text>
          </OrderSummary>

          <PriceBar>
            <Flex justify="space-between">
              <Text className="is-uppercase" align="center" weight="semibold" size="small">
                Total Quantity: {totalQuantity}
              </Text>
              <Text className="is-uppercase" align="center" weight="semibold" size="small">
                Total: $
                {parseFloat(subTotalPrice + bookingFee - discount + bookingFeeOfEvent).toFixed(2)}
              </Text>
            </Flex>
          </PriceBar>
        </>
      )}
      {totalQuantity !== 0 && (
        <ButtonWrapper>
          <CheckoutButton
            className={`${loading && 'is-loading'}`}
            isFullWidth
            backgroundColor="primaryColor"
            onClick={onHandleConfirmBooking}
            disabled={totalQuantity === 0}
          >
            <ButtonText color={`${loading ? 'primaryColor' : 'white'}`}>
              Qty:{totalQuantity}
            </ButtonText>
            <ButtonText color={`${loading ? 'primaryColor' : 'white'}`}>
              {checkSelectionHaveAttendeeDetailsOrNot ? 'Confirm Booking' : 'Checkout'}
            </ButtonText>
            <ButtonText color={`${loading ? 'primaryColor' : 'white'}`}>
              ${parseFloat(subTotalPrice + bookingFee - discount + bookingFeeOfEvent).toFixed(2)}
            </ButtonText>
          </CheckoutButton>
        </ButtonWrapper>
      )}
    </div>
  );
};

export default Tickets;
