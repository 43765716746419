import React, { useState, useEffect } from 'react';
import { forEach } from 'lodash';
import styled from 'styled-components';

import {
  getPlaceTypes,
  getCuisines,
  getServicesTypes,
  getFeatures,
} from '../../../utils/fetchStatic';

import { Text, TagInputs } from '../../../components/elements';

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const Span = styled.span`
  color: red;
`;

const parseInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

const Label = styled(Text).attrs({})``;

const Tags = ({ values, handleChange, handleBlur, handleSubmit, loading, setFieldValue }) => {
  const [data, setData] = useState([{ businessTypes: [], cuisines: [] }]);

  useEffect(() => {
    const getData = async () => {
      const businessTypes = await getPlaceTypes();
      const serviceTypes = await getServicesTypes();
      const featureTypes = await getFeatures();
      const cuisines = await getCuisines();

      setData({
        businessTypes,
        serviceTypes,
        featureTypes,
        cuisines,
      });
    };
    getData();
  }, []);
  return (
    <>
      <div className="columns is-multiline">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <TagInputs
              label={<Label paddingBottom={0.5}>Business Type</Label>}
              value={parseData(values.business_types)}
              suggestions={parseData(data.businessTypes)}
              onChange={value => setFieldValue('business_types', parseInput(value))}
            />
          </div>
        </div>
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <TagInputs
              label={<Label paddingBottom={0.5}>Services</Label>}
              value={parseData(values.service_types)}
              suggestions={parseData(data.serviceTypes)}
              onChange={value => setFieldValue('service_types', parseInput(value))}
            />
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <TagInputs
              label={<Label paddingBottom={0.5}>Features</Label>}
              value={parseData(values.feature_types)}
              suggestions={parseData(data.featureTypes)}
              onChange={value => setFieldValue('feature_types', parseInput(value))}
            />
          </div>
        </div>
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <TagInputs
              label={
                <Label paddingBottom={0.5}>
                  Cuisines<Span>*</Span>
                </Label>
              }
              value={parseData(values.cuisine_style)}
              suggestions={parseData(data.cuisines)}
              onChange={value => setFieldValue('cuisine_style', parseInput(value))}
            />
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-two-thirds">
          <Text weight="regular" size="medium" paddingBottom={0.5}>
            What&apos;s good about this place
          </Text>
          <textarea className="textarea" rows="3" />
        </div>
      </div>
      <hr />
    </>
  );
};
export default Tags;
