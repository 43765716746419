import React from 'react';
import styled from 'styled-components';

import { FlexRows, Icon, Text } from '../../../components/elements';

const Container = styled.div`
  padding: 1rem 0rem;
  margin: 0rem 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const Wrapper = styled.div`
  padding: 0rem 1rem;
`;

const TextWrapper = styled(Text)`
  padding-left: 6px;
`;

const MobileItems = () => (
  <React.Fragment>
    <Container>
      <FlexRows justify="flex-start" align="flex-start">
        <div>
          <Icon name="findAndShareItemSolid" size={2} />
        </div>
        <Wrapper>
          <Text size="tiny" color="coolGrey">
            7 days ago
          </Text>

          <FlexRows justify="space-between">
            <TextWrapper color="#00AEEF">Ram</TextWrapper>
            <TextWrapper>reviewed</TextWrapper>
            <TextWrapper color="#00AEEF">Roti</TextWrapper>
            <TextWrapper> at </TextWrapper>
            <TextWrapper color="#00AEEF">Tapas</TextWrapper>
          </FlexRows>
        </Wrapper>
      </FlexRows>
    </Container>
  </React.Fragment>
);

export default MobileItems;
