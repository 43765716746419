import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { Text, FlexColumns, FlexRows } from '../../components/elements';
import config from '../../utils/config';
import { getEncodedImage } from '../../utils/s3';
import theme from '../../theme';

const Container = styled.div`
  padding: 1rem 0.5rem;
  border-top: 4px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;
const Heading = styled.div``;
const ScrollView = styled.div`
  overflow: auto;
  width: 100%;
  padding: 0.5rem 0rem;
`;

const Items = styled(FlexRows).attrs({ justify: 'space-between', align: 'flex-start' })``;

const Item = styled(FlexColumns).attrs({ justify: 'flex-start', align: 'flex-start' })`
  background: ${({ theme: { colors } }) => colors.white};
  width: 40%;
  margin-right: 1rem;
`;

const Figure = styled.figure`
  && {
    width: 9rem;
    img {
      border-radius: 4px;
      height: 9rem;
    }
  }
`;

const placesQuery = gql`
  query {
    search_places(input: { sort: "LOCATION" }) {
      place_listing {
        place_id
        name
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        default_image_url
      }
    }
  }
`;

const ImageView = ({ image, id }) => {
  if (image === null || image === '') {
    return (
      <Link to={`/place/${id}`}>
        <Figure>
          <img src={config.dummyImage} alt={image} />
        </Figure>
      </Link>
    );
  }
  return (
    <Figure>
      <img src={getEncodedImage(image, 307, 307)} alt={image} />
    </Figure>
  );
};

const Events = () => {
  return (
    <Container>
      <Heading>
        <Text color="secondaryColor" size="smedium" weight="semibold">
          Great spots around you
        </Text>
      </Heading>
      <ScrollView>
        <Query query={placesQuery} fetchPolicy="network-only">
          {({ data, loading, error }) => {
            if (error) {
              return 'error';
            }

            if (loading) {
              return (
                <FlexColumns style={{ padding: '2rem' }}>
                  <ReactLoading type="spin" color={theme.primaryColor} width={50} height={50} />
                </FlexColumns>
              );
            }
            if (!data.search_places) {
              return (
                <FlexColumns style={{ padding: '2rem' }}>
                  <ReactLoading type="spin" color={theme.primaryColor} width={50} height={50} />
                </FlexColumns>
              );
            }
            const places = data.search_places.place_listing;

            return (
              <Items>
                {places.map(place => (
                  <Item key={place.place_id}>
                    <ImageView id={place.place_id} image={place.default_image_url} />
                    <div>
                      <Text
                        className="is-capitalized"
                        size="tiny"
                        weight="semibold"
                        lineHeight="normal"
                      >
                        {capitalize(place.name)}
                      </Text>
                      <Text
                        className="is-capitalized"
                        size="ultraMicro"
                        color="darkGrey"
                        lineHeight="normal"
                      >
                        {place.city}, {place.state}
                      </Text>
                    </div>
                  </Item>
                ))}
              </Items>
            );
          }}
        </Query>
      </ScrollView>
    </Container>
  );
};

export default Events;
