import { debounce, forEach, first, sortBy, filter, isNaN } from 'lodash';
import dayjs from 'dayjs';
import { getDistance } from 'geolib';
import gql from 'graphql-tag';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';

import { elements } from '../theme';
import client from './apolloClient';

export const isDesktop = () => {
  const maxIpadWidth = elements.mobileBreakpoint;
  return window.innerWidth > 375 && window.innerWidth > maxIpadWidth;
};

export const formatCurrency = value => {
  const price = !isNaN(parseFloat(value)) ? `$${parseFloat(value).toFixed(2)}` : '';
  return price;
};

export const formatDate = data => dayjs(data).format('ddd, M MMM YYYY, h:mm A');
export const startEndDateParser = (start, end) => {
  if (dayjs(start).isSame(dayjs(end))) {
    return formatDate(start);
  }
  if (dayjs(start).diff(dayjs(end)) <= 86400000) {
    return `${dayjs(start).format('ddd M MMM YYYY, h:mm A')} to ${dayjs(end).format('h:mm A')}`;
  }
  return `${formatDate(start)} to ${formatDate(end)}`;
};

export const checkWindowSize = (init = true, cb) => {
  const maxMobileWidth = elements.mobileBreakpoint;
  let isMobile = window.innerWidth < maxMobileWidth;
  if (init) {
    return isMobile;
  }

  return window.addEventListener(
    'resize',
    debounce(
      () => {
        isMobile = window.innerWidth < maxMobileWidth;
        cb(isMobile);
      },
      200,
      false,
    ),
    false,
  );
};

export const getDistanceMatrix = (currentLocation, placeLocation) => {
  const distance = getDistance(currentLocation, placeLocation);
  return Math.round(distance / 1000);
};

export const firstTwoLetterPicker = name => {
  let initials = (name && name.match(/\b\w/g)) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  return initials;
};

export const normalizePlaceData = async result => {
  const components = {};
  await forEach(result.address_components, addressComponent => {
    forEach(addressComponent.types, type => {
      components[type] = addressComponent.long_name;
    });
  });

  const finalData = {
    addressLine1: `${components.street_number ? `${components.street_number} ` : ''}${
      components.route ? components.route : ''
    }`,
    addressLine2: result.formatted_address,
    city: components.locality ? components.locality : '',
    state: components.administrative_area_level_1 ? components.administrative_area_level_1 : '',
    postcode: components.postal_code ? components.postal_code : '',
    country: components.country ? components.country : '',
    latitude: result.geometry.location.lat().toString(10),
    longitude: result.geometry.location.lng().toString(10),
  };

  return finalData;
};

export const getPlaceTagline = (businessType, cuisineType) => {
  const businessTypeData =
    businessType && businessType.length !== 0 ? first(businessType).description : '';
  const cuisineTypeDataFirst =
    cuisineType && cuisineType.length !== 0 ? first(cuisineType).description : '';
  const cuisineTypeDataSecond =
    cuisineType && cuisineType.length >= 2 ? cuisineType[1].description : '';
  return `${businessTypeData}${cuisineTypeDataFirst !== '' ? ',' : ''} ${cuisineTypeDataFirst}${
    cuisineTypeDataSecond !== '' ? ',' : ''
  } ${cuisineTypeDataSecond}`;
};

export const getKeywordTagline = (businessType, cuisineType, features, dietary) => {
  const businessTypeData =
    businessType && businessType.length !== 0 ? `${first(businessType).description}` : '';
  const businessTypeDataSecond =
    businessType && businessType.length >= 2 ? `, ${businessType[1].description}` : '';
  const cuisineTypeDataFirst =
    cuisineType && cuisineType.length !== 0 ? `, ${first(cuisineType).description}` : '';
  const cuisineTypeDataSecond =
    cuisineType && cuisineType.length >= 2 ? `, ${cuisineType[1].description}` : '';
  const featureDataFirst =
    features && features.length !== 0 ? `, ${first(features).description}` : '';
  const featureDataSecond = features && features.length >= 2 ? `, ${features[1].description}` : '';
  const dietaryDataFirst = dietary && dietary.length !== 0 ? `, ${first(dietary).description}` : '';
  const dietaryDataSecond = dietary && dietary.length >= 2 ? `, ${dietary[1].description}` : '';
  return `${businessTypeData}${businessTypeDataSecond}${cuisineTypeDataFirst}${cuisineTypeDataSecond}${featureDataFirst}${featureDataSecond}${dietaryDataFirst}${dietaryDataSecond}`;
};

const eventTicketsQuery = gql`
  query eventTicketsQuery($eventOccurrenceId: String) {
    fetch_event_occurrence_ticket(input: { event_occurrence_id: $eventOccurrenceId }) {
      event_occurrence_id
      ticket_id
      sale_start
      sale_end
      status
      sales_method
    }
  }
`;

const parseOfflineTicket = listOfTickets => {
  const finalTicket = [];
  forEach(listOfTickets, item => {
    forEach(item.sales_method, item2 => {
      if (item2 !== 'offline') {
        finalTicket.push(item);
      }
    });
  });
  return finalTicket;
};

export const getTicketStartAndEndDate = eventOccurrenceId =>
  new Promise((resolve, reject) => {
    client
      .query({
        query: eventTicketsQuery,
        variables: {
          eventOccurrenceId,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const { fetch_event_occurrence_ticket } = data;
        if (fetch_event_occurrence_ticket) {
          const parseOfflineTickets = parseOfflineTicket(fetch_event_occurrence_ticket);
          const ticketFilter = filter(parseOfflineTickets, item => item.status !== 'HIDDEN');
          const startDateSort = first(sortBy(ticketFilter, 'sale_start'));
          console.log('eventOccurrenceId', ticketFilter);
          resolve(startDateSort);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const isOpen = async place => {
  let is_open = false;
  try {
    const time_zone = tzLookup(parseFloat(place.latitude), parseFloat(place.longitude));
    const date_now = moment().tz(time_zone);
    let day = date_now.day();
    let options = get_time_range_for_a_day(place, day);
    let now_in_seconds = date_now.hour() * 3600 + date_now.minute() * 60 + date_now.second();
    is_open = await check_open_for_day_range(now_in_seconds, options);
    // If not open, check in previous day for role over
    if (!is_open) {
      day = day > 1 ? day - 1 : 7;
      options = get_time_range_for_a_day(place, day);
      now_in_seconds += 24 * 60 * 60;
      is_open = await check_open_for_day_range(now_in_seconds, options);
    }
  } catch (error) {
    console.log(error);
  }
  return is_open;
};

const get_time_range_for_a_day = (place, day) => {
  const time_range_for_a_day =
    place.business_hour && place.business_hour.length !== 0
      ? place.business_hour[day - 1]
      : { option: [] };
  return time_range_for_a_day && time_range_for_a_day.option ? time_range_for_a_day.option : [];
};

const check_open_for_day_range = async (now_in_seconds, options) => {
  for (const item of options) {
    const start_in_sec = item.start_in_sec ? item.start_in_sec : 0;
    const { end_in_sec } = item;
    if (start_in_sec <= now_in_seconds && now_in_seconds <= end_in_sec) {
      return true;
    }
  }
  return false;
};

export const parseSocialPlace = (socials, userId) => {
  const bookmarkSocialArray =
    socials && socials.length !== 0 ? socials.filter(item => item.type === 'BOOKMARK') : [];
  const bookmarkSocial = bookmarkSocialArray.length !== 0 ? first(bookmarkSocialArray) : undefined;
  const isActiveBookMarkArray = bookmarkSocial
    ? bookmarkSocial.user.filter(item => item.user_id === userId)
    : [];

  const likeSocialArray =
    socials && socials.length !== 0 ? socials.filter(item => item.type === 'LIKE') : [];
  const likeSocial = likeSocialArray.length !== 0 ? first(likeSocialArray) : undefined;
  const isActiveLikeArray = likeSocial
    ? likeSocial.user.filter(item => item.user_id === userId)
    : [];
  const likeUsers = likeSocial ? likeSocial.user.map(item => item.user_id) : [];

  const beenThereSocialArray =
    socials && socials.length !== 0 ? socials.filter(item => item.type === 'BEEN_THERE') : [];
  const beenThereSocial =
    beenThereSocialArray.length !== 0 ? first(beenThereSocialArray) : undefined;
  const isActiveBeenThereArray = beenThereSocial
    ? beenThereSocial.user.filter(item => item.user_id === userId)
    : [];

  const social = {
    like: {
      id: isActiveLikeArray.length !== 0 ? first(isActiveLikeArray).social_id : '',
      count: likeSocial ? likeSocial.count : 0,
      active: isActiveLikeArray.length !== 0,
      likeUsers,
    },
    bookmark: {
      id: isActiveBookMarkArray.length !== 0 ? first(isActiveBookMarkArray).social_id : '',
      count: bookmarkSocial ? bookmarkSocial.count : 0,
      active: isActiveBookMarkArray.length !== 0,
    },
    beenThere: {
      id: isActiveBeenThereArray.length !== 0 ? first(isActiveBeenThereArray).social_id : '',
      count: beenThereSocial ? beenThereSocial.count : 0,
      active: isActiveBeenThereArray.length !== 0,
    },
  };
  return social;
};

export const parseSocialItem = (socials, userId) => {
  const bookmarkSocialArray =
    socials && socials.length !== 0 ? socials.filter(item => item.type === 'BOOKMARK') : [];
  const bookmarkSocial = bookmarkSocialArray.length !== 0 ? first(bookmarkSocialArray) : undefined;
  const isActiveBookMarkArray = bookmarkSocial
    ? bookmarkSocial.user.filter(item => item.user_id === userId)
    : [];

  const triedSocialArray =
    socials && socials.length !== 0 ? socials.filter(item => item.type === 'TRIED') : [];
  const triedSocial = triedSocialArray.length !== 0 ? first(triedSocialArray) : undefined;
  const isActiveTriedArray = triedSocial
    ? triedSocial.user.filter(item => item.user_id === userId)
    : [];

  const lovedSocialArray =
    socials && socials.length !== 0 ? socials.filter(item => item.type === 'LOVE_IT') : [];
  const lovedSocial = lovedSocialArray.length !== 0 ? first(lovedSocialArray) : undefined;
  const isActiveLovedArray = lovedSocial
    ? lovedSocial.user.filter(item => item.user_id === userId)
    : [];

  const social = {
    tried: {
      id: isActiveTriedArray.length !== 0 ? first(isActiveTriedArray).social_id : '',
      count: triedSocial ? triedSocial.count : 0,
      active: isActiveTriedArray.length !== 0,
    },
    bookmark: {
      id: isActiveBookMarkArray.length !== 0 ? first(isActiveBookMarkArray).social_id : '',
      count: bookmarkSocial ? bookmarkSocial.count : 0,
      active: isActiveBookMarkArray.length !== 0,
    },
    loved: {
      id: isActiveLovedArray.length !== 0 ? first(isActiveLovedArray).social_id : '',
      count: lovedSocial ? lovedSocial.count : 0,
      active: isActiveLovedArray.length !== 0,
    },
  };
  return social;
};
