import React from 'react';
import styled from 'styled-components';
import { MdDone } from 'react-icons/md';

import { FlexRows, Text } from '../../../../components/elements';
import cardImg from '../../../../assets/images/mastercard-logo.jpg';
import colors from '../../../../theme/styles/colors';

const Container = styled(FlexRows)`
  border-bottom: 2px solid ${({ theme: { colors: themeColors } }) => themeColors.borderColor};
  padding: 1rem 0rem;
  width: 100%;
  cursor: pointer;
`;

const ImageStyled = styled.img`
  height: 50px;
  width: 50px;
  margin-right: 2rem;
  object-fit: contain;
`;

const PaymentItem = ({ isActive, title, onClick = () => {} }) => {
  return (
    <Container justify="space-between" onClick={onClick}>
      <FlexRows justify="flex-start">
        <ImageStyled src={cardImg} alt="master-card" />
        <Text color="coolGrey" size="medium">
          {title}
        </Text>
      </FlexRows>
      {isActive && (
        <Text>
          <MdDone size={25} color={colors.primaryColor} />
        </Text>
      )}
    </Container>
  );
};

export default PaymentItem;
