import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { withRouter } from 'react-router-dom';
import { capitalize } from 'lodash';
import { FaFilter, FaListUl, FaMapMarkerAlt } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { useStoreState } from 'easy-peasy';

import { Text, FlexRows, SearchInput } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';
import font from '../../../../theme/styles/fonts';

const Container = styled.div`
  position: fixed;
  background-color: ${({ theme: { colors: color } }) => color.lightGreyBeta};
  padding-top: 0.5rem;
  z-index: 100;
  width: 100%;
`;

const Columns = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
`;

const ScrollView = styled(FlexRows).attrs({ justify: 'space-around' })`
  padding-top: 3rem;
  width: 100%;
`;

const ScrollViewItem = styled.a`
  display: inline-block;
  text-align: center;
  padding: 0.5rem 0.5rem;

  border-bottom: 4px solid
    ${({ active, theme: { colors: color } }) => (active ? color.primaryColor : 'transparent')};
`;
const ButtonAnimation = posed.button({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const Button = styled(ButtonAnimation)`
  margin: 0rem 0.3rem !important;
  &&& {
    border-color: transparent;
    background-color: ${({ theme: { colors: color } }) => color.lightGreyAlpha};
    border-radius: 25px;
    :focus {
      border: 0;
    }
    :active {
      border: 0;
    }
  }
`;

const ButtonText = styled.span`
  color: ${colors.dark};
  font-size: ${font.size.tiny};
  font-weight: ${font.weight.semibold};
`;

const ButtonWrapper = styled.div`
  padding-top: 7rem;
`;

const MobileFilterContainer = ({
  categoryFilter,
  setCategoryFilter,
  setActiveFilterModal,
  setActiveMap,
  activeMap,
  setActiveCuisinesModal,
  history,
}) => {
  const usedLocation = useStoreState(state => state.global.usedLocation);
  return (
    <>
      <Container>
        <Columns>
          <SearchInput
            value={`${capitalize(usedLocation.city)}, ${capitalize(usedLocation.state)}`}
            onFocus={() => history.push('/search-location')}
            leftIcon={<MdLocationOn size={20} color={colors.coolGrey} />}
            hideRightIcon
          />
          {/* <Button className="button is-fullwidth" onClick={() => setActiveSortByModal(true)}>
        <span className="icon">
          <FaSort size={18} color={colors.coolGrey} />
        </span>
        <ButtonText>SORT</ButtonText>
      </Button> */}
        </Columns>
        <ScrollView>
          <ScrollViewItem
            className="item"
            active={categoryFilter === 'best-dishes'}
            onClick={() => {
              setCategoryFilter('best-dishes');
            }}
          >
            <Text className="is-uppercase" weight="semibold" size="micro">
              Best Dishes
            </Text>
          </ScrollViewItem>
          <ScrollViewItem
            className="item"
            active={categoryFilter === 'nearest-dishes'}
            onClick={() => {
              setCategoryFilter('nearest-dishes');
            }}
          >
            <Text className="is-uppercase" weight="semibold" size="micro">
              Nearest Dishes
            </Text>
          </ScrollViewItem>
          <ScrollViewItem
            className="item"
            active={categoryFilter === 'latest-dishes'}
            onClick={() => {
              setCategoryFilter('latest-dishes');
            }}
          >
            <Text className="is-uppercase" weight="semibold" size="micro">
              Latest Dishes
            </Text>
          </ScrollViewItem>
        </ScrollView>
      </Container>
      <ButtonWrapper>
        <FlexRows justify="space-around">
          <Button className="button is-fullwidth" onClick={() => setActiveFilterModal(true)}>
            <span className="icon">
              <FaFilter size={16} color={colors.coolGrey} />
            </span>
            <ButtonText>Filters</ButtonText>
          </Button>
          <Button className="button is-fullwidth" onClick={() => setActiveCuisinesModal(true)}>
            <span className="icon">
              <FaListUl size={16} color={colors.coolGrey} />
            </span>
            <ButtonText>Cuisines</ButtonText>
          </Button>
          <Button className="button is-fullwidth" onClick={() => setActiveMap(!activeMap)}>
            <span className="icon">
              {activeMap ? (
                <FaListUl size={16} color={colors.coolGrey} />
              ) : (
                <FaMapMarkerAlt size={16} color={colors.coolGrey} />
              )}
            </span>
            <ButtonText>{activeMap ? 'List' : 'Map'}</ButtonText>
          </Button>
        </FlexRows>
      </ButtonWrapper>
    </>
  );
};

export default withRouter(MobileFilterContainer);
