import React from 'react';

import Layout from '../components/global/Layout';
import LocationSuggestForm from '../components/global/LocationSuggestForm';

const Location = ({ history, match }) => (
  <Layout
    history={history}
    match={match}
    isEnableMobileSecondaryHeader
    secondaryHeaderHeading="CHOOSE YOUR LOCATION"
  >
    <div className="is-hidden-desktop">
      <LocationSuggestForm back={() => history.goBack()} />
    </div>
  </Layout>
);

export default Location;
