import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Input from './Input';
import Text from './Text';
import colors from '../../theme/styles/colors';
// import font from '../../theme/styles/fonts';

const Wrapper = styled.div`
  border-bottom: 1px solid
    ${({ borderBottomColor }) => (borderBottomColor ? colors[borderBottomColor] : 'transparent')};
`;

const Span = styled.span`
  &&& {
    font-size: 11px;
    color: #737373;
  }
`;

const Span2 = styled.span`
  &&& {
    font-size: 11px;
    color: red;
    position: relative;
    bottom: 4px;
  }
`;

const Field = ({ borderBottomColor, label, labelTextProps, optional, required, ...props }) => (
  <Wrapper className="field" borderBottomColor={borderBottomColor}>
    {label && (
      <label className="label">
        <Text {...labelTextProps}>
          {label} {required && <Span2>*</Span2>} {optional && <Span>(OPTIONAL)</Span>}
        </Text>
      </label>
    )}
    <div className="control">
      <Input {...props} />
    </div>
  </Wrapper>
);

export default Field;
