import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Flex from './Flex';

const Container = styled(Flex)`
  flex-direction: row;
`;

const FlexRows = ({ children, ...props }) => <Container {...props}>{children}</Container>;

FlexRows.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FlexRows;
