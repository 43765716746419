import React, { useEffect } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { useStoreActions } from 'easy-peasy';
// import qs from 'querystringify';
import moment from 'moment';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';
import ErrorMessage from '../../components/global/ErrorMessage';

const fetchPlaceQuery = gql`
  query fetch_place($placeId: String) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      tagline
      description
      address_line_1
      city
      state
      price_range
      keywords
      latitude
      longitude
      post_code
      cuisine_style {
        name
        description
      }
      dietary_information {
        name
        description
      }
      ambience {
        name
        description
      }
      good_for {
        name
        description
      }
      feature {
        name
        description
      }
      default_image_url
      contact {
        type
        value
        is_primary
      }
      dining_style {
        name
        description
      }
      business_hour {
        day
        option {
          type
          start
          end
          start_in_sec
          end_in_sec
        }
      }
      business_type {
        name
        description
      }
      audit {
        created_at
      }
    }
    search_social(
      input: { filter: { social_filter: { object_id: [$placeId], object_type: "PLACE" } } }
    ) {
      social_aggregation {
        total_social_per_object_id {
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const View = ({ data, loading }) => {
  const updateRecentView = useStoreActions(state => state.place.addRecentView);

  useEffect(() => {
    if (data.fetch_place) {
      updateRecentView({ ...data.fetch_place, createdAt: moment().toISOString() });
    }
  });

  return (
    <Analytics pageName="Places" category="place" title="Place- Listing">
      <div className="is-hidden-desktop">
        <MobileView loading={loading} data={data} />
      </div>
      <div className="is-hidden-touch">
        <DesktopView data={data} loading={loading} />
      </div>
    </Analytics>
  );
};

const Place = ({ history, match }) => {
  const { placeId } = match.params;
  return (
    <Layout history={history} match={match} service="places">
      <Query query={fetchPlaceQuery} variables={{ placeId }} fetchPolicy="network-only">
        {({ data, loading, error }) => {
          if (error) {
            return <ErrorMessage />;
          }
          return <View data={data} loading={loading} />;
        }}
      </Query>
    </Layout>
  );
};

export default Place;
