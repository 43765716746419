import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
// import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { getEncodedImage } from '../utils/s3';

const SliderContainer = styled.div`
  justify-content: flex-start;
  &&& {
    img {
      object-fit: contain;
    }
  }
`;

const ImageSlider = ({ images, whichImage, onSliderChange }) => {
  const settings = {
    // dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    initialSlide: whichImage,
    afterChange: onSliderChange,
  };

  return (
    <SliderContainer>
      <Slider {...settings}>
        {images.map(image => (
          <figure className="image is-3by2">
            <img src={getEncodedImage(image.url)} alt={image.url} radius={0} />
          </figure>
        ))}
      </Slider>
    </SliderContainer>
  );
};
export default ImageSlider;
