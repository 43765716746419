import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import SubHeader from './SubHeader';

const Container = styled.div``;
const Wrapper = styled.div`
  margin: 0rem auto;
  min-height: 48vh;
  background: ${({ theme: { colors } }) => colors.white};
  position: relative;
  padding: 0 24px;
  box-sizing: border-box;
  max-width: 1330px;
`;

const DesktopViewLayout = ({ children, location }) => (
  <Container>
    <Header />
    {location.pathname !== '/search' && <SubHeader />}
    <Wrapper>{children}</Wrapper>
    <Footer />
  </Container>
);

export default withRouter(DesktopViewLayout);
