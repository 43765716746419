import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { dropRight, times, first, capitalize } from 'lodash';
import { IoIosRedo } from 'react-icons/io';
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdPhone } from 'react-icons/md';

import {
  Text,
  FlexRows,
  FlexColumns,
  GetDirections,
  ShowMoreText,
} from '../../../../components/elements';
import ImageView from './ImageView';
import ActionButtons from './ActionButtons';
import OpeningHours from './OpeningHours';
import PlaceReviewActionButton from './PlaceReviewActionButton';
import colors from '../../../../theme/styles/colors';
import { isOpen } from '../../../../utils/helpers';

const TopContainer = styled.div`
  padding: 3rem 0.5rem;
  padding-bottom: 1rem;
`;
const Container = styled.div`
  padding: 0rem 0.5rem;
  padding-bottom: 1rem;
`;

const ImageWrapper = styled.div`
  padding: 1rem 0rem;
  overflow: hidden;
`;

const ProgressBar = styled(CircularProgressbar)`
  max-width: 45px;
`;

const MobileContactWrapper = styled.div`
  padding: 0.5rem 0rem;
  margin-top: 1rem;
  border-top: 1px solid ${colors.lightGreyBeta};
  border-bottom: 1px solid ${colors.lightGreyBeta};
`;

const DescriptionWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const AmitiesWrapper = styled.div`
  margin-bottom: 1rem;
`;

const styles = {
  root: {
    width: '100%',
  },
  path: {
    stroke: colors.tertiaryColor,
    strokeLinecap: 'round',
    transition: 'stroke-dashoffset 0.5s ease 0s',
  },
  trail: {
    stroke: 'transparent',
  },
  text: {
    fill: colors.secondaryColor,
    fontSize: 20,
    dominantBaseline: 'middle',
    textAnchor: 'middle',
  },
};

const Information = ({ place, onlyPageHeader }) => {
  const [isOpenPlace, setIsOpenPlace] = useState(false);
  const cuisineStyleArray = dropRight(
    place.cuisine_style,
    place.cuisine_style ? place.cuisine_style.length - 8 : 0,
  );

  const businessTypeArray = dropRight(
    place.business_type,
    place.business_type ? place.business_type.length - 3 : 0,
  );

  const findMobileContact =
    place.contact !== null ? place.contact.filter(item => item.type === 'phone_primary') : [];
  const primaryMobileContact = findMobileContact.length !== 0 && first(findMobileContact).value;

  useEffect(() => {
    const fetchData = async () => {
      const data = await isOpen(place);
      setIsOpenPlace(data);
    };
    fetchData();
  });

  return (
    <>
      <TopContainer>
        <FlexRows justify="space-between" align="flex-start">
          <div>
            <Text className="is-capitalized" size="large" weight="regular">
              {place.name}
            </Text>
            <Text size="micro" color="darkGrey">
              {businessTypeArray.length !== 0 &&
                businessTypeArray.map(
                  (item, index) =>
                    `${item.description}${index !== businessTypeArray.length - 1 ? ', ' : ''}`,
                )}
              |{' '}
              {cuisineStyleArray.length !== 0 &&
                cuisineStyleArray.map(
                  (item, index) =>
                    `${item.description}${index !== cuisineStyleArray.length - 1 ? ', ' : ''}`,
                )}
            </Text>
          </div>
          <Text color="coolGrey">
            <IoIosRedo size={25} />
          </Text>
        </FlexRows>
        <ActionButtons place={place} />
      </TopContainer>

      <ImageWrapper>
        <ImageView place={place} />
      </ImageWrapper>

      {!onlyPageHeader && (
        <Container>
          <PlaceReviewActionButton placeId={place.place_id} placeName={place.name} />
          <FlexRows justify="space-between" align="flex-end">
            <div>
              <Text size="micro" color="darkGrey">
                {place.address_line_1} <br /> {place.city}, {place.state}
              </Text>

              <Text size="micro" color="darkGrey">
                {place.price_range !== 0 && place.price_range && (
                  <>{times(place.price_range || 0).map(() => '$')}</>
                )}{' '}
                -{' '}
                <span style={{ color: colors.primaryColor }}>
                  <span> {isOpenPlace ? 'Open Now' : 'Closed'}</span>
                </span>
              </Text>
              <GetDirections latitude={place.latitude} longitude={place.longitude} mobile />
            </div>
            <div>
              <FlexColumns>
                <ProgressBar
                  value={Math.round(80)}
                  text={`${Math.round(80)}%`}
                  strokeWidth={12}
                  styles={styles}
                />
                <Text size="micro" color="secondaryColor" weight="bold">
                  LIKE THIS PLACE
                </Text>
              </FlexColumns>
            </div>
          </FlexRows>
          {primaryMobileContact && (
            <MobileContactWrapper className="is-flex">
              <span className="icon">
                <MdPhone size={16} color={colors.tertiaryColor} /> &nbsp;
              </span>
              <Text size="smaller"> {primaryMobileContact}</Text>
            </MobileContactWrapper>
          )}
          <OpeningHours businessHours={place.business_hour} isOpen={isOpenPlace} />
          {(place.tagline || place.description) && (
            <DescriptionWrapper>
              {place.tagline && (
                <Text
                  className="is-capitalized"
                  size="medium"
                  weight="semibold"
                  paddingBottom={0.5}
                  letterSpacing="tight"
                >
                  {capitalize(place.tagline)}
                </Text>
              )}
              {place.description && (
                <ShowMoreText
                  content={place.description}
                  size="tiny"
                  color="darkGrey"
                  limit={500}
                />
              )}
            </DescriptionWrapper>
          )}

          {place.cuisine_style && place.cuisine_style.length !== 0 && (
            <AmitiesWrapper>
              <Text
                className="is-capitalized"
                size="medium"
                weight="semibold"
                paddingBottom={0.5}
                letterSpacing="tight"
              >
                Cuisines
              </Text>
              <Text size="tiny" color="darkGrey">
                {place.cuisine_style.map(
                  (item, index) =>
                    `${item.description}${index !== place.cuisine_style.length - 1 ? ', ' : ''}`,
                )}
              </Text>
            </AmitiesWrapper>
          )}
          {place.dietary_information && place.dietary_information.length !== 0 && (
            <AmitiesWrapper>
              <Text
                className="is-capitalized"
                size="medium"
                weight="semibold"
                paddingBottom={0.5}
                letterSpacing="tight"
              >
                Dietary Information
              </Text>
              <Text size="tiny" color="darkGrey">
                {place.dietary_information.map(
                  (item, index) =>
                    `${item.description}${
                      index !== place.dietary_information.length - 1 ? ', ' : ''
                    }`,
                )}
              </Text>
            </AmitiesWrapper>
          )}
          {place.good_for && place.good_for.length !== 0 && (
            <AmitiesWrapper>
              <Text
                className="is-capitalized"
                size="medium"
                weight="semibold"
                paddingBottom={0.5}
                letterSpacing="tight"
              >
                Known For
              </Text>
              <Text size="tiny" color="darkGrey">
                {place.good_for.map(
                  (item, index) =>
                    `${item.description}${index !== place.good_for.length - 1 ? ', ' : ''}`,
                )}
              </Text>
            </AmitiesWrapper>
          )}
          {place.ambience && place.ambience.length !== 0 && (
            <AmitiesWrapper>
              <Text
                className="is-capitalized"
                size="medium"
                weight="semibold"
                paddingBottom={0.5}
                letterSpacing="tight"
              >
                ambience
              </Text>
              <Text size="tiny" color="darkGrey">
                {place.ambience.map(
                  (item, index) =>
                    `${item.description}${index !== place.ambience.length - 1 ? ', ' : ''}`,
                )}
              </Text>
            </AmitiesWrapper>
          )}
        </Container>
      )}
    </>
  );
};

export default Information;
