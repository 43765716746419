import React from 'react';
import styled from 'styled-components';
import { UploadImage } from '../../components/elements';
import UploadedImages from './UploadedImages';

const Container = styled.div`
  padding: 0rem 1rem;
`;

const Images = ({ values, setFieldValue }) => {
  return (
    <Container>
      <div className="columns is-multiline">
        <UploadedImages setFieldValue={setFieldValue} />
        <UploadImage
          totalNumberOfUploadedImage={values.uploadedImages.length}
          s3UploadPath="place/"
          metaData={{
            place_id: '',
            user_id: '2290802a-c911-4718-a829-5edc420e0d05',
          }}
          setFieldValue={setFieldValue}
        />
      </div>
    </Container>
  );
};
export default Images;
