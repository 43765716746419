import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import swal from 'sweetalert';
import { isDesktop } from '../../utils/helpers';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';

const customerFeedbackMutation = gql`
  mutation customer_feedback($input: SupportInput) {
    customer_feedback(input: $input) {
      id
      message
      status
    }
  }
`;

const Profile = ({ history, match }) => {
  const isMobile = !isDesktop();
  return (
    <Layout history={history} match={match}>
      <Mutation
        mutation={customerFeedbackMutation}
        onError={() => swal('Something went wrong!', 'try after some time', 'error')}
        onCompleted={() =>
          swal('Thank you for providing the feedback ! 🙂 🙂 🙂 ', 'success').then(() => {
            history.push('/support-and-feedback');
          })
        }
      >
        {(customer_feedback, { loading }) => (
          <>
            {isMobile ? (
              <MobileView
                loading={loading}
                onSubmit={input => {
                  customer_feedback({ variables: { input } });
                }}
              />
            ) : (
              <DesktopView />
            )}
          </>
        )}
      </Mutation>
    </Layout>
  );
};

export default Profile;
