import React, { useState } from 'react';
import styled from 'styled-components';
import { times, first } from 'lodash';
import moment from 'moment';
import { MdStar } from 'react-icons/md';
import { useStoreState } from 'easy-peasy';

import { ShowMoreText } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';
import ReviewImageView from './ReviewImageView';
import PlaceReviewCommentList from './PlaceReviewCommentList';
import UserArticle from '../../../../components/UserArticle';
import FollowButtonAction from '../../../../components/FollowButtonAction';
import ReviewSocial from './ReviewSocial';
import { parseSocialPlace } from '../../../../utils/helpers';

const Container = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${colors.lightGreyBeta};
  &&& {
    .columns:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;
const IconWrapper = styled.span`
  padding: 0.2rem;
`;

const IconActionButtonWrapper = styled.div`
  padding-top: 0rem;
`;
const ButtonWrapper = styled.div`
  padding-top: 0.5rem;
`;

const ReviewItem = ({ review, socials }) => {
  const { userId } = useStoreState(state => state.auth);
  const socialArray = socials.length !== 0 ? first(socials).total_social_count : [];
  const social = parseSocialPlace(socialArray, userId);
  const [totalComments, setTotalComments] = useState(0);
  const [totalLike, setTotalLike] = useState(social.like.count);

  return (
    <Container>
      <UserArticle
        user={review.user_details}
        subHeading={`${moment(review.audit.created_at).fromNow()}, Visited
        ${moment(review.month_visited).format("MMMM 'YY")}`}
        maskProps={{ width: 50 }}
        maskTextProps={{ color: 'white', weight: 'bold', size: 'medium', letterSpacing: 'loose' }}
        headingLeftElement={
          <ButtonWrapper>
            <FollowButtonAction
              objectId={review.audit.created_by}
              objectType="USER"
              textProps={{ size: 'micro', weight: 'semibold' }}
              buttonProps={{ width: 0.4 }}
            />
          </ButtonWrapper>
        }
        headingRightElement={
          <div className="is-flex">
            {times(review.overall_rating).map(index => (
              <IconWrapper key={index}>
                <MdStar color={colors.tertiaryColor} size={35} />
              </IconWrapper>
            ))}
            {times(5 - review.overall_rating).map(index => (
              <IconWrapper key={index}>
                <MdStar color={colors.specialGrey} size={35} />
              </IconWrapper>
            ))}
          </div>
        }
      >
        <ShowMoreText content={review.content} lines={5} size="tiny" />
        <br />
        <ReviewImageView placeId={review.place_id} placeReviewId={review.place_review_id} />
        <div className="columns">
          <div className="column">
            <IconActionButtonWrapper>
              <ReviewSocial
                totalLike={totalLike}
                totalComments={totalComments}
                likeUsers={social.like.likeUsers}
                review={review}
                objectId={review.place_review_id}
                objectType="PLACE_REVIEW"
                likeActive={social.like.active}
                likeCountInt={social.like.count}
                likeSocialIdS={social.like.id}
                setTotalLike={setTotalLike}
              />
            </IconActionButtonWrapper>
          </div>
        </div>
      </UserArticle>
      <PlaceReviewCommentList
        placeId={review.place_id}
        placeReviewId={review.place_review_id}
        totalComments={totalComments}
        setTotalComments={setTotalComments}
      />
    </Container>
  );
};

export default ReviewItem;
