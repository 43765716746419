import React from 'react';
import styled from 'styled-components';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { first } from 'lodash';
import { Text, FlexColumns, FlexRows } from '../../../components/elements';
import AttendeeFormModal from './AttendeeFormModal';

const Content = styled.div`
  padding: 1rem;
  /* margin-bottom: 2rem; */
`;

const HeadingWrapper = styled.div`
  padding: 1rem 0rem;
  span {
    font-weight: 550;
  }
`;

const ClickableField = styled.a`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  margin-bottom: 1rem;
`;

const Attendees = ({ item2, isRequired, index, ticketIndex, setFieldValue, ticketName }) => {
  const [isModalActive, setIsModalActive] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  return (
    <>
      <Text color="primaryColor" weight="regular" size="small">
        Attendee {index + 1}
      </Text>
      <ClickableField onClick={() => setIsModalActive(true)}>
        <FlexRows justify="space-between">
          {!isRequired && !completed && (
            <Text color="darkGrey" size="tiny" weight="light">
              Optional
            </Text>
          )}
          {completed && (
            <Text color="darkGrey" size="tiny" weight="light">
              Information completed
            </Text>
          )}
          {isRequired && !completed && (
            <Text color="danger" size="tiny" weight="light">
              Required. Tap to complete
            </Text>
          )}
          <Text color="waterMarkGrey">
            <MdKeyboardArrowRight size={20} />
          </Text>
        </FlexRows>
      </ClickableField>
      <AttendeeFormModal
        ticketName={ticketName}
        isActive={isModalActive}
        setActiveAttendeeModal={setIsModalActive}
        attributes={item2.attributes}
        onSubmit={values => {
          setFieldValue(
            `ticketSelections.${ticketIndex}.attendees.${index}.attributes`,
            values.attributes,
          );
          setCompleted(true);
        }}
      />
    </>
  );
};

const AttendeesForms = ({ item, setFieldValue, ticketIndex }) => {
  const { attributes } = first(item.attendees);
  if (attributes.length === 0) {
    return null;
  }
  return (
    <Content>
      <HeadingWrapper>
        <Text className="is-capitalized" size="small">
          Ticket Type : <span>{item.name}</span>
        </Text>
      </HeadingWrapper>
      <FlexColumns align="space-between">
        {item.attendees.map((item2, index) => {
          const isRequiredAttendee = item2.attributes.filter(edge => edge.required === true);
          const isRequired = isRequiredAttendee.length !== 0;
          if (item2.attributes.length === 0) {
            return null;
          }
          return (
            <Attendees
              ticketName={item.name}
              item2={item2}
              isRequired={isRequired}
              index={index}
              ticketIndex={ticketIndex}
              setFieldValue={setFieldValue}
            />
          );
        })}
      </FlexColumns>
    </Content>
  );
};

export default AttendeesForms;
