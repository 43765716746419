import React from 'react';
import styled from 'styled-components';
import { PageHeader, Text, FlexRows, Icon } from '../../components/elements';

const Container = styled.div`
  padding-top: 3.4rem;
`;

const Element = styled.div`
  padding: 0.5rem;
`;

const Wrapper = styled.div`
  padding: 0rem 1rem;
`;

const badges = [
  { icon: 'kraver', name: 'Kraver', info: ' Your first share or review' },
  {
    icon: 'socialiteKraver',
    name: 'Socialite Kraver',
    info: 'Earned after 5 shares',
  },
  {
    icon: 'inspiredKraver',
    name: 'Inspired Kraver',
    info: '500 Kravein points or earned after 3 shares or review',
  },
  {
    icon: 'explorerKraver2',
    name: 'Explorer Kraver',
    info: 'Earned after 5 reviews',
  },
  {
    icon: 'persistentKraver',
    name: 'Persistent Kraver',
    info: '1000 Kravein points or earned after 10 shares or review',
  },
  {
    icon: 'perfectKraver',
    name: 'Perfect Kraver',
    info: '2500 Kravein points or earned after 20 shares or review',
  },
  {
    icon: 'expertKraver',
    name: 'Expert Kraver',
    info: '4000 Kravein points or earned after 30 shares or review',
  },
  {
    icon: 'connoisseur',
    name: 'connoisseur',
    info: '6000 Kravein points or earned after 40 shares or review',
  },
  {
    icon: 'superstarKraver',
    name: 'Superstar Kraver',
    info: '7500 Kravein points or earned after 50 shares or review',
  },
];

const MobileView = ({ children }) => (
  <React.Fragment>
    <PageHeader text="All Badges" />
    <Container>
      <Element>
        <Text size="small" weight="bold">
          Earning points and Badges is very easy and simple. You can earn point
          badges in many ways such as:
        </Text>
        <Text color="darkGrey" size="tiny">
          Share your favorite dishes and items
        </Text>
        <Text color="darkGrey" size="tiny">
          Reviewing Places
        </Text>
        <Text color="darkGrey" size="tiny">
          When other try or loved that you shared
        </Text>
      </Element>
      {badges.map(badge => {
        return (
          <Element>
            <FlexRows justify="flex-start" align="flex-start">
              <div>
                <Icon name={badge.icon} size={2} />
              </div>
              <Wrapper>
                <Text size="small" weight="bold">
                  {badge.name}
                </Text>
                <Text color="specialGrey" size="tiny">
                  {badge.info}
                </Text>
              </Wrapper>
            </FlexRows>
          </Element>
        );
      })}
    </Container>
    {children}
  </React.Fragment>
);

export default MobileView;
