import React from 'react';
import styled from 'styled-components';

import MobilePlaceSuggestionModal from './MobilePlaceSuggestionModal';

const Container = styled.div``;

const MobileView = ({
  placeLoading,
  places,
  placeQueryTag,
  recentPlaceViews,
  recentLocationViews,
  onSuggestionsFetchRequested,
  setPlaceQueryTag,
  onSelectLocation,
  usedLocation,
}) => (
  <Container>
    <MobilePlaceSuggestionModal
      placeLoading={placeLoading}
      places={places}
      placeQueryTag={placeQueryTag}
      setPlaceQueryTag={setPlaceQueryTag}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      recentPlaceViews={recentPlaceViews}
      recentLocationViews={recentLocationViews}
      onSelectLocation={onSelectLocation}
      usedLocation={usedLocation}
    />
  </Container>
);

export default MobileView;
