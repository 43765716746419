import React from 'react';
import { first } from 'lodash';
import { useStoreState } from 'easy-peasy';

import Info from './components/mobile/Info';
import Reviews from './components/mobile/Reviews';
import ItemReviewActionButton from './components/mobile/ItemReviewActionButton';
import { parseSocialItem } from '../../utils/helpers';

const MobileView = ({ data, socials }) => {
  const { userId } = useStoreState(state => state.auth);
  const item = first(data.fetch_item);
  const socialArray = socials.length !== 0 ? first(socials).total_social_count : [];
  const social = parseSocialItem(socialArray, userId);
  return (
    <>
      <Info item={item} social={social} />
      <ItemReviewActionButton item={item} />
      <Reviews item={item} />
    </>
  );
};

export default MobileView;
