import React from 'react';
import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import { capitalize } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { MdLocationOn } from 'react-icons/md';
import { Text, FlexRows, FlexColumns, GetDirections } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';
import Socials from './Socials';

const Container = styled.div`
  && {
    .columns:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

const styles = {
  root: {
    width: '100%',
  },
  path: {
    stroke: colors.tertiaryColor,
    strokeLinecap: 'round',
    transation: 'stroke-dashoffset 0.5s ease 0s',
  },
  trail: {
    stroke: 'transparent',
  },
  text: {
    fill: colors.secondaryColor,
    fontSize: 20,
    dominantBaseline: 'middle',
    textAnchor: 'middle',
  },
};

const Wrapper = styled.div`
  padding-left: 0.6rem;
`;

const Heading = styled(Text)`
  span {
    color: ${({ theme: { colors: color } }) => color.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
  }
`;

const SubHeading = styled(Text)`
  span {
    color: ${({ theme: { colors: themeColor } }) => themeColor.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
  }
`;

const ProgressBar = styled(CircularProgressbar)`
  max-width: 55px;
`;

const IconActionButtonWrapper = styled.div`
  padding: 1rem 0rem;
`;

const AddressWrapper = styled(FlexRows)`
  padding-bottom: 0.5rem;
`;

const TopView = ({ item, history, social }) => {
  const handleOnClickAddPhotoButton = path => {
    history.push(path);
  };

  return (
    <Container>
      <div className="columns">
        <div className="column">
          <Wrapper>
            <Heading
              className="is-capitalized"
              size="big"
              weight="semibold"
              paddingBottom={0.3}
              style={{ marginLeft: '0.4rem' }}
            >
              {capitalize(item.name)}
            </Heading>
            <Link to={`/place/${item.place_id}`}>
              <SubHeading
                size="lmedium"
                weight="semibold"
                paddingBottom={0.3}
                style={{ marginLeft: '0.4rem' }}
              >
                {capitalize(item.place_name)}, {item.city}
              </SubHeading>
            </Link>
            <div className="is-flex">
              <div>
                <span className="icon">
                  <MdLocationOn size={19} color={colors.specialGrey} />
                </span>
              </div>

              <AddressWrapper align="flex-end">
                <Text size="small">
                  {item.address_line_1} <br /> {item.city}, {item.state}
                  <GetDirections latitude={item.latitude} longitude={item.longitude} />
                </Text>
              </AddressWrapper>
            </div>
          </Wrapper>
          <div className="columns">
            <div className="column is-7">
              <IconActionButtonWrapper style={{ marginLeft: '0.6rem' }}>
                <Socials
                  objectId={item.item_id}
                  objectType="ITEM"
                  triedActive={social.tried.active}
                  lovedActive={social.loved.active}
                  bookMarkActive={social.bookmark.active}
                  triedCountInt={social.tried.count}
                  lovedCountInt={social.loved.count}
                  bookMarkCountInt={social.bookmark.count}
                  triedSocialIdS={social.tried.id}
                  lovedSocialIdS={social.loved.id}
                  bookMarkSocialIdS={social.bookmark.id}
                />
              </IconActionButtonWrapper>
            </div>
          </div>
        </div>
        <div className="column is-4">
          <FlexColumns>
            <ProgressBar
              value={Math.round(80)}
              text={`${Math.round(80)}%`}
              strokeWidth={12}
              styles={styles}
            />
            <Text size="micro" color="secondaryColor" weight="bold">
              LIKE THIS ITEMS
            </Text>
            <br />
            {/* <FlexRows justify="space-around">
              <Button
                name="See all Photos"
                width={1.5}
                textProps={{ size: 'small', weight: 'medium' }}
                onClick={() =>
                  handleOnClickAddPhotoButton(`/item-photos/${item.place_id}/${item.item_id}`)
                }
              />
            </FlexRows> */}
          </FlexColumns>
        </div>
      </div>
    </Container>
  );
};
export default withRouter(TopView);
