import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';

import { Button } from '../../../../../components/lib';
import PromoCodeModal from './PromoCodeModal';
import { OrderFragments } from '../../../../../utils/fragments';
import { parseOrderDatabaseInputItemsWhileSubmissionAgain } from '../../../../Menu/helpers';
import { FlexItem, FlexRows, Text } from '../../../../../components/elements';

const Container = styled.div`
  margin-top: 1rem;
  border-top: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;

const RemoveWrapper = styled(FlexRows)`
  padding: 1rem 0rem;
`;

const RemoveButtonWrapper = styled.div`
  float: right;
`;

const updateOrderMutation = gql`
  mutation updateOrder($input: MenuOrderInput) {
    update_menu_order(input: $input) {
      ...OrderFragments
      error {
        description
      }
    }
  }
  ${OrderFragments}
`;

const PromoCode = ({ order, dataBaseLineItem, setToMainOrderCart }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const { isLoggedIn, userId } = useStoreState(state => state.auth);
  const [appliedDeals, setAppliedDeals] = useState([]);
  const [isError, setIsError] = useState('');

  const finalOne = parseOrderDatabaseInputItemsWhileSubmissionAgain(dataBaseLineItem);
  return (
    <Container>
      <Mutation
        mutation={updateOrderMutation}
        onCompleted={({ update_menu_order }) => {
          if (update_menu_order.error) {
            setAppliedDeals([]);
            update_menu_order.error.map(item => setIsError(item.description));
          } else {
            setToMainOrderCart({
              ...update_menu_order,
              place: order.place,
              place_menu_deal: appliedDeals,
            });
            setIsActiveModal(false);
            setAppliedDeals([]);
          }
        }}
      >
        {(update_menu_order, { loading }) => (
          <>
            <PromoCodeModal
              isErrorWhenFinalApply={isError}
              appliedDealId={appliedDeals.length !== 0 ? appliedDeals[0].deal_id : null}
              placeId={order.place_id}
              menuId={order.menu_id}
              menuOrderId={order.menu_order_id}
              userId={userId}
              updateLoading={loading}
              isActive={isActiveModal}
              onClose={() => setIsActiveModal(false)}
              onSubmitPromoCode={async deal => {
                setAppliedDeals([deal]);
                update_menu_order({
                  variables: {
                    input: {
                      menu_order_id: order.menu_order_id,
                      place_menu_deal: [{ deal_id: deal.deal_id }],
                      user_id: isLoggedIn ? userId : null,
                      place_id: order.place_id,
                      // service_type: order.service_type,
                      menu_id: order.menu_id,
                      address: {
                        address_id: order.address.address_id,
                        type: order.address.address_id,
                        contact_name: order.address.contact_name,
                        contact_phone: order.address.contact_phone,
                        address_line_1: order.address.address_line_1,
                        address_line_2: order.address.address_line_2,
                        city: order.address.city,
                        state: order.address.state,
                        country: order.address.country,
                        post_code: order.address.post_code,
                        latitude: order.address.latitude,
                        longitude: order.address.longitude,
                        status: order.address.status,
                      },
                      line_item: finalOne,
                    },
                  },
                });
              }}
            />
            {order.place_menu_deal && order.place_menu_deal.length !== 0 ? (
              <div>
                {order.place_menu_deal.map(edge => (
                  <RemoveWrapper justify="space-between" align="flex-start">
                    <FlexItem grow={2}>
                      <Text size="smedium" color="darkGrey" weight="semibold">
                        {edge.promo_code && `${edge.promo_code} - `} Applied successfully
                      </Text>
                      <Text size="small" color="waterMarkGrey">
                        {edge.name}
                      </Text>
                    </FlexItem>
                    <FlexItem grow={1}>
                      <RemoveButtonWrapper>
                        <Button
                          isLink
                          isLoading={loading}
                          size="large"
                          onClick={async () => {
                            update_menu_order({
                              variables: {
                                input: {
                                  menu_order_id: order.menu_order_id,
                                  place_menu_deal: [],
                                  user_id: isLoggedIn ? userId : null,
                                  place_id: order.place_id,
                                  // service_type: order.service_type,
                                  menu_id: order.menu_id,
                                  address: {
                                    address_id: order.address.address_id,
                                    type: order.address.address_id,
                                    contact_name: order.address.contact_name,
                                    contact_phone: order.address.contact_phone,
                                    address_line_1: order.address.address_line_1,
                                    address_line_2: order.address.address_line_2,
                                    city: order.address.city,
                                    state: order.address.state,
                                    country: order.address.country,
                                    post_code: order.address.post_code,
                                    latitude: order.address.latitude,
                                    longitude: order.address.longitude,
                                    status: order.address.status,
                                  },
                                  line_item: finalOne,
                                },
                              },
                            });
                          }}
                        >
                          Remove
                        </Button>
                      </RemoveButtonWrapper>
                    </FlexItem>
                  </RemoveWrapper>
                ))}
              </div>
            ) : (
              <Button isGhost size="large" onClick={() => setIsActiveModal(true)}>
                Apply Promo Code
              </Button>
            )}
          </>
        )}
      </Mutation>
    </Container>
  );
};

export default PromoCode;
