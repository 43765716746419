import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';

import { Flex, Text, Field, FlexRows } from '../../components/elements';
// import PlaceDataCorrections from '../PlaceDataCorrections';

const Container = styled.div`
  padding: 1rem;
`;
const FieldWrapper = styled.div`
  padding: 1rem 0.5rem;
`;

const FieldStyle = styled(Field).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;
// const SelectFieldStyle = styled(SelectField).attrs({
//   lite: true,
//   borderBottomColor: 'specialGrey',
//   labelTextProps: {
//     className: 'is-uppercase',
//     size: 'small',
//     color: 'primaryColor',
//     weight: 'bold',
//     letterSpacing: 'loose',
//   },
// })``;
const FooterButton = styled.a`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.8rem 1rem;
  position: fixed;
  bottom: 0;
`;

const MobileForm = ({ values, touched, errors, handleChange, handleBlur, handleSubmit, loading }) => (
  <>
    <Container>
      <FieldWrapper>
        <FieldStyle
          id="cardNo"
          name="cardNo"
          label="Card No."
          placeholder="Card Number"
          value={values.addressType}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.cardNo && touched.cardNo && <div className="has-text-danger">{errors.cardNo}</div>}
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          id="name"
          name="name"
          label="Name"
          placeholder="John"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.name && touched.name && <div className="has-text-danger">{errors.name}</div>}
      </FieldWrapper>

      <FlexRows justify="space-between" align="flex-start">
        <FieldWrapper>
          <FieldStyle
            id="expiryDate"
            name="expiryDate"
            label="Expiry Date"
            placeholder="01/22"
            value={values.expiryDate}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.expiryDate && touched.expiryDate && <div className="has-text-danger">{errors.expiryDate}</div>}
        </FieldWrapper>
        <FieldWrapper>
          <FieldStyle
            id="cvv"
            name="cvv"
            label="CVV"
            placeholder="cvv"
            value={values.cvv}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.cvv && touched.cvv && <div className="has-text-danger">{errors.cvv}</div>}
        </FieldWrapper>
      </FlexRows>
      <FieldWrapper>
        <FieldStyle
          id="postcode"
          name="postcode"
          label="Billing Postcode"
          placeholder="123"
          value={values.postcode}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.postcode && touched.postcode && <div className="has-text-danger">{errors.postcode}</div>}
      </FieldWrapper>
    </Container>

    <FooterButton>
      <Flex>
        <Text className="is-uppercase" color="white" align="center" weight="bold" size="medium" letterSpacing="loose">
          Add
        </Text>
      </Flex>
    </FooterButton>
  </>
);

const MyEnhancedForm = withFormik({
  mapPropsToValues: () => ({
    name: '',
    mobile: '',
    email: '',
    subject: 'Other',
    message: '',
  }),

  // Custom sync validation
  validate: values => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.mobile) {
      errors.mobile = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    }
    if (!values.subject) {
      errors.subject = 'Required';
    }
    if (!values.message) {
      errors.message = 'Required';
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  },

  displayName: 'ContactUsForm',
})(MobileForm);

export default MyEnhancedForm;
