import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { FlexColumns, Text } from '../../elements';
import colors from '../../../theme/styles/colors';
import SignUpForm from './SignUpForm';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 45rem;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    }
    .modal-card-body {
      padding: 2rem;
    }
    a {
      color: ${colors.primaryColor};
    }
  }
`;
const ButtonStyle = styled.div`
  &&& {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

const ErrorMessage = styled.div`
  padding: 0.5rem;
  background: red;
  color: white;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const DeskTopSignInModal = () => {
  const { loading, isError } = useStoreState(state => state.auth);
  const clearError = useStoreActions(state => state.auth.clearError);
  const signUp = useStoreActions(state => state.auth.signUp);
  const isActiveModal = useStoreState(state => state.auth.isActiveDesktopSignInModal);
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopSignInModal);
  const setIsActiveDesktopLoginModal = useStoreActions(
    state => state.auth.setIsActiveDesktopLoginModal,
  );

  useEffect(() => {
    clearError();
  }, [clearError]);

  const handleSignupModal = () => {
    setIsActiveDesktopLoginModal({ value: true });
    setIsActiveModal({ value: false });
  };

  return (
    <Container className={`modal ${isActiveModal && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <section className="modal-card-body">
          <ButtonStyle
            className="delete"
            label="close"
            onClick={() => setIsActiveModal({ value: false })}
          />
          <FlexColumns>
            <div>
              <Text color="primaryColor" size="large" weight="semibold" paddingBottom={0.5}>
                Sign Up for Kravein
              </Text>
            </div>
            <div>
              <Text color="darkGrey" size="tiny">
                Crave in, dig in....Create your experience
              </Text>
            </div>
          </FlexColumns>
          <br />
          {isError.message !== '' && (
            <ErrorMessage>
              <p>{isError.message}</p>
            </ErrorMessage>
          )}
          <SignUpForm
            isLoading={loading}
            onSubmit={values => {
              const input = {
                firstName: values.firstName,
                lastName: values.lastName,
                displayName: values.displayName,
                email: values.email.toLowerCase(),
                password: values.password,
                confirmPassword: values.confirmPassword,
              };
              signUp({ ...input });
            }}
          />

          <br />
          <Text color="darkGrey" size="micro">
            By Signing up. I agree to Kravein&apos;s <a>Terms of Service</a> and{' '}
            <a>Privacy Policy</a>. I understand that the content post is public. I also consent to
            receiving information events, deals,dishes, items or places on Kravein.
          </Text>
          <br />
          <Text color="darkGrey" size="micro">
            Already on Kravein&apos;s? <a onClick={handleSignupModal}>Sign in</a>
          </Text>
        </section>
      </div>
    </Container>
  );
};

export default withRouter(DeskTopSignInModal);
