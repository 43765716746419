import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { Text, FlexRows } from '../../../components/elements';
import { formatCurrency } from '../../../utils/helpers';

const Container = styled(FlexRows)`
  &&& {
    margin-bottom: 2rem;
    align-items: flex-start;
  }
`;

const Item = styled.div`
  width: 70%;
`;

const YourOrderItem = ({ item }) => {
  return (
    <Container justify="space-between">
      <Text weight="medium" size="smedium" paddingBottom="0.2" color="darkGrey">
        {item.quantity}
      </Text>
      <Item>
        <Text weight="regular" size="smedium" paddingBottom="0.2">
          {item.name}
        </Text>
        {item && item.selectedVariant && (
          <Text weight="medium" size="tiny" color="darkGrey" lineHeight="normal">
            {item.selectedVariant.variant_name} (
            {formatCurrency(item.selectedVariant.variant_price)})
          </Text>
        )}
        {item.groups &&
          item.groups.map(group =>
            group.modifiers.map(modifier => (
              <Text weight="medium" size="tiny" color="darkGrey" lineHeight="normal">
                {modifier.menu_item.name} (
                {modifier.selectedVariant
                  ? `${modifier.selectedVariant.variant_name} (${formatCurrency(
                      modifier.selectedVariant.variant_price,
                    )})`
                  : formatCurrency(modifier.override_price)}
                )
              </Text>
            )),
          )}
        {item.special_request && item.special_request.length !== 0 && (
          <Text weight="medium" size="tiny" color="darkGrey" lineHeight="normal">
            Request: {item.special_request}
          </Text>
        )}
      </Item>
      <Text weight="medium" size="smedium" paddingBottom="0.2" color="darkGrey">
        {formatCurrency(item.price)}
      </Text>
    </Container>
  );
};

export default withRouter(YourOrderItem);
