import React from 'react';
import styled from 'styled-components';
import { trim, forEach } from 'lodash';
import { withFormik } from 'formik';
import * as yup from 'yup';
import uuid from 'uuid';
import {
  Input,
  TagInputs,
  LocationSuggestion,
  InputErrorMessage,
} from '../../../../components/elements';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-card {
      width: 35rem;
      margin-top: 4rem;
    }
    .modal-card-head,
    .modal-card-foot {
      justify-content: space-between;
    }
  }
`;
const Span = styled.span`
  color: red;
`;

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const parseInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

const Form = props => {
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    isActive,
    onClose,
    businessTypes,
  } = props;

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add Place</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={() => {
              setFieldValue('name', '');
              onClose();
            }}
          />
        </header>
        <section className="modal-card-body">
          <div className="columns is-multiline">
            <div className="column is-full">
              <div className="field">
                <label className="label">
                  Place name <Span>*</Span>
                </label>
                <div className="control">
                  <Input
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputErrorMessage touched={touched.name} errors={errors.name} />
                  <br />
                </div>
              </div>
            </div>
            <div className="column is-full">
              <TagInputs
                label={
                  <label className="label">
                    Business Type <Span>*</Span>
                  </label>
                }
                value={parseData(values.business_type)}
                suggestions={values.business_type.length >= 2 ? [] : parseData(businessTypes)}
                onChange={value => setFieldValue('business_type', parseInput(value))}
              />
              <InputErrorMessage touched={touched.business_type} errors={errors.business_type} />
            </div>
            <div className="column is-full">
              <LocationSuggestion
                label="Search Address"
                initialValue=""
                onSelect={place => {
                  setFieldValue('address_line_1', trim(place.addressLine1));
                  setFieldValue('city', place.city);
                  setFieldValue('post_code', place.postcode);
                  setFieldValue('state', place.state);
                  setFieldValue('country', place.country || 'AUSTRALIA');
                  setFieldValue('timezone', place.timezone || '+10');
                  setFieldValue('latitude', place.latitude);
                  setFieldValue('longitude', place.longitude);
                }}
              />
            </div>
            <div className="column is-full">
              <div className="field">
                <label className="label">
                  Street address <Span>*</Span>
                </label>
                <div className="control">
                  <Input
                    name="address_line_1"
                    value={values.address_line_1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputErrorMessage
                    touched={touched.address_line_1}
                    errors={errors.address_line_1}
                  />
                  <br />
                </div>
              </div>
            </div>
            <div className="column is-full">
              <div className="field">
                <label className="label">
                  City / Suburb <Span>*</Span>
                </label>
                <div className="control">
                  <Input
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputErrorMessage touched={touched.city} errors={errors.city} />
                  <br />
                </div>
              </div>
            </div>
            <div className="column is-full">
              <div className="field">
                <label className="label">
                  State <Span>*</Span>
                </label>
                <div className="control">
                  <Input
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputErrorMessage touched={touched.state} errors={errors.state} />
                  <br />
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="field">
                <label className="label">
                  Country <Span>*</Span>
                </label>
                <div className="control">
                  <Input
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputErrorMessage touched={touched.country} errors={errors.country} />
                  <br />
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="field">
                <label className="label">
                  Post code <Span>*</Span>
                </label>
                <div className="control">
                  <Input
                    name="post_code"
                    value={values.post_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputErrorMessage touched={touched.post_code} errors={errors.post_code} />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          {/* <button type="button"className="button">Reset</button> */}
          <button type="button" className="button is-primary" onClick={() => handleSubmit()}>
            Submit
          </button>
        </footer>
      </div>
    </Container>
  );
};

const DeskAddPlaceModal = withFormik({
  mapPropsToValues: ({ placeNameValue }) => ({
    place_id: uuid(),
    name: placeNameValue,
    business_type: '',
    address_line_1: '',
    city: '',
    state: '',
    country: '',
    post_code: '',
    latitude: '',
    longitude: '',
    timezone: '',
    event_business: false,
  }),

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'At least 3 Characters Required!')
      .max(75, 'Not greater than 75 Characters!')
      .required('Place Name is required!'),
    business_type: yup.array().required('Business Type is required'),
    address_line_1: yup.string().required('Street Address is required'),
    city: yup.string().required('Suburb / City is required'),
    post_code: yup.string().required('Post Code is required'),
    timezone: yup.string().required('Timezone is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    // latitude: yup.string().required('Latitude is required'),
    // longitude: yup.string().required('Longitude is required'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'DeskAddPlaceModalForm',
})(Form);

export default DeskAddPlaceModal;
