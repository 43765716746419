import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { omit } from 'lodash';
import { FlexRows, IconSocialActionButton } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const SocialActionButtonStyle = styled(IconSocialActionButton).attrs({
  textStyle: { size: 'small', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const updateOrderMutation = gql`
  mutation updateOrder($input: MenuOrderInput) {
    update_menu_order_trivial(input: $input) {
      menu_order_id
      error {
        description
      }
    }
  }
`;

const RatingAction = ({ order, item }) => {
  const [isActive, setIsActive] = useState(
    !!(item.menu_item_rating && item.menu_item_rating !== 5),
  );
  const [loading, setLoading] = useState(false);

  const handleAction = () => {
    setLoading(true);
    client.clientPublic
      .mutate({
        mutation: updateOrderMutation,
        variables: {
          input: {
            menu_order_id: order.menu_order_id,
            line_item: [
              {
                menu_item_id: item.menu_item_id,
                menu_item_rating: !isActive,
              },
            ],
          },
        },
      })
      .then(() => {
        setIsActive(!isActive);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <FlexRows justify="space-between">
      <SocialActionButtonStyle
        loading={loading}
        active={isActive}
        name=""
        defaultIcon="loveTheItemGrey"
        activeIcon="loveTheItemBlue"
        handleOnClick={handleAction}
      />
    </FlexRows>
  );
};

export default RatingAction;
