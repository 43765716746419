import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 92%;
  margin: 1rem;
`;

const SearchInput = ({ type }) => {
  return (
    <Container className="control has-icons-left">
      <input className="input is-medium" type="text" placeholder={`Search By typing ${type}`} />
      <span className="icon is-medium is-left">
        <i className="fas fa-search" />
      </span>
    </Container>
  );
};

export default SearchInput;
