import React from 'react';
import styled from 'styled-components';
import { Text, FlexRows, Radio } from '../../../../../components/elements';
import colors from '../../../../../theme/styles/colors';

const Wrapper = styled.div``;

const CheckBoxWrapper = styled(FlexRows)`
  && {
    padding-bottom: 0.7rem;
    a {
      font-size: 15px;
    }
    i {
      font-size: 1.2rem;
      margin-top: 3px;
    }
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

const Variant = ({ variant, isActive = false, handleVariantSelection = () => {} }) => {
  const disabled =
    variant.availability_status === 'UNAVAILABLE' || variant.un_available_dates !== null;
  return (
    <Wrapper>
      <CheckBoxWrapper justify="space-between">
        <div className="is-flex radio-btn">
          <Radio
            label={variant.variant_name}
            value={isActive}
            labelTextProps={{
              size: 'small',
              color: disabled ? colors.waterMarkGrey : colors.darkGrey,
              weight: 'regular',
            }}
            onChange={() => handleVariantSelection()}
            disabled={disabled}
          />
        </div>
        {disabled ? (
          <Text size="smedium" weight="semibold" color="waterMarkGrey">
            {variant.un_available_dates ? variant.un_available_dates : 'Sold out'}
          </Text>
        ) : (
          <Text size="smedium" weight="regular" color="darkGrey">
            $ {parseFloat(variant.variant_price).toFixed(2)}
          </Text>
        )}
      </CheckBoxWrapper>
    </Wrapper>
  );
};

export default Variant;
