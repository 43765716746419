/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { MdClose, MdLocationOn, MdSearch } from 'react-icons/md';
import { FaTimesCircle } from 'react-icons/fa';
import { useStoreActions, useStoreState } from 'easy-peasy';

import MainSearchResult from './mobile/MainSearchResult';
import SearchSuggestionList from './mobile/SearchSuggestionList';
import LocationSuggestionItem from './mobile/LocationSuggestionItem';
import { FlexRows, Text, Flex, Icon } from '../../components/elements';
import color from '../../theme/styles/colors';

const Container = styled.div``;
const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
`;

const Content = styled.div`
  margin-top: 7rem;
  margin-bottom: 1rem;
`;

const Field = styled.div`
  width: 100%;
  .icon {
    pointer-events: initial !important;
  }
  .input {
    box-shadow: 0 0 0 0.08em #f0f0f0 !important;
    border: 0;
    text-transform: capitalize;
  }
  .input:focus {
    border-color: #eeeeee !important;
    box-shadow: 0 0 0 0.08em #f0f0f0 !important;
  }
  .input:hover {
    border-color: #eeeeee !important;
    box-shadow: 0 0 0 0.08em #f0f0f0 !important;
  }
`;

const ModalHeader = styled(FlexRows).attrs({
  justify: 'space-between',
  align: 'flex-start',
})`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 0rem;
  &&& {
    .input {
      text-transform: capitalize;
    }
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  }
`;

const ModalHeaderRightWrapper = styled.div`
  width: 100%;
  padding: 0.5rem;
`;
const ModalHeaderLeftWrapper = styled.div`
  padding: 0.5rem;
`;

const TextStyle = styled(Text).attrs({ size: 'tiny', color: 'dark', weight: 'semibold' })`
  padding-left: 0.5rem;
  &&& {
    :hover {
      ${({ theme: { primaryColor }, disabled }) => !disabled && `color:${primaryColor}`};
    }
  }
`;

const Tabs = styled(FlexRows).attrs({ justify: 'space-between' })`
  background: ${({ theme: { colors } }) => colors.white};
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyTheta};
  position: sticky;
  top: 4rem;
  background: #ffffff;
  z-index: 200;
`;
const Item = styled.a`
  padding: 0.5rem 1rem;
  border-bottom: 2px solid
    ${({ theme: { colors }, active }) => (active ? colors.primaryColor : 'transparent')};
  ${({ disabled }) => disabled && 'opacity: 0.5'};
  position: relative;
  top: 2px;
`;

const FlexStyle = styled(Flex).attrs({ justify: 'flex-start' })``;
const Wrapper = styled.div`
  padding-top: 1rem;
  padding: 0.5rem 1rem;
`;

const MobileView = ({
  history,
  places,
  items,
  events,
  query,
  handleOnChange,
  searchLocationQuery,
  loading,
  // locationLoading,
  locations,
  fetchLocation,
  handleSelectLocation,
}) => {
  const addRecentViewLocation = useStoreActions(state => state.global.addRecentViewLocation);
  const recentViewLocations = useStoreState(state => state.global.recentViewLocations);
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const currentLocation = useStoreState(state => state.global.currentLocation);
  const updateUsedLocation = useStoreActions(state => state.global.updateUsedLocation);

  const searchInputRef = useRef(null);
  const locationInputRef = useRef(null);
  const [step, setStep] = useState('search-items');
  const [activeTab, setActive] = useState('places');

  const onHandleFocusSearchInput = value => {
    searchInputRef.current.focus();
    setStep('search-items');
    handleSelectLocation(value);
    addRecentViewLocation(value);
  };

  useEffect(() => {
    if (query.length !== 0) {
      setStep('result');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (places.length !== 0) {
      setActive('places');
    } else if (items.length !== 0) {
      setActive('items');
    } else if (events.length !== 0) {
      setActive('events');
    }
  }, [events.length, items.length, places.length]);

  return (
    <Container>
      <HeaderWrapper>
        <ModalHeader>
          <ModalHeaderLeftWrapper>
            <a onClick={() => history.goBack()}>
              <Text color="white" size="large">
                <MdClose />
              </Text>
            </a>
          </ModalHeaderLeftWrapper>
          <ModalHeaderRightWrapper>
            <Field className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  className="input is-fullwidth"
                  type="search"
                  name="search"
                  title="Search"
                  aria-label="Search"
                  value={query}
                  ref={searchInputRef}
                  onChange={e => handleOnChange(e.target.value)}
                  onFocus={() => setStep('search-items')}
                  spellCheck={false}
                  autoComplete={false}
                  onKeyDown={event => {
                    if (event.keyCode === 13 && query.length !== 0) {
                      searchInputRef.current.blur();
                      setStep('result');
                      history.push(`global-search?query=${query}`);
                      if (searchLocationQuery.length === 0) {
                        handleSelectLocation({
                          name: usedLocation.city,
                          state_name: usedLocation.state,
                          location: `${usedLocation.latitude},${usedLocation.longitude}`,
                        });
                      }
                    }
                  }}
                />
                <span className="icon is-small is-left">
                  <MdSearch size={20} color={color.coolGrey} />
                </span>
                {query.length !== 0 && (
                  <span
                    className="icon is-small is-right"
                    onClick={() => {
                      handleOnChange('');
                      searchInputRef.current.focus();
                    }}
                  >
                    <FaTimesCircle size={16} color={color.specialGrey} />
                  </span>
                )}
              </p>
            </Field>
            <Field className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  className="input is-fullwidth"
                  type="search"
                  name="search"
                  title="Search"
                  aria-label="Search"
                  ref={locationInputRef}
                  value={searchLocationQuery}
                  onChange={e => fetchLocation(e.target.value)}
                  onFocus={() => setStep('search-location')}
                  spellCheck={false}
                  autoComplete={false}
                  onKeyDown={event => {
                    if (event.keyCode === 13 && searchLocationQuery.length !== 0) {
                      locationInputRef.current.blur();
                      setStep('result');
                      history.push(`/global-search?query=${query}`);
                    }
                  }}
                />
                <span className="icon is-small is-left">
                  <MdLocationOn size={20} color={color.specialGrey} />
                </span>
                {searchLocationQuery.length !== 0 && (
                  <span
                    className="icon is-small is-right"
                    onClick={() => {
                      fetchLocation('');
                      locationInputRef.current.focus();
                    }}
                  >
                    <FaTimesCircle size={16} color={color.specialGrey} />
                  </span>
                )}
              </p>
            </Field>
          </ModalHeaderRightWrapper>
        </ModalHeader>
        {step === 'result' && (
          <Tabs>
            <Item
              active={activeTab === 'places'}
              onClick={() => places.length !== 0 && setActive('places')}
              disabled={places.length === 0}
            >
              <FlexStyle>
                <Icon name="headerPlaceAroundMe" size={1.2} />
                <TextStyle
                  color={activeTab === 'places' ? 'primaryColor' : 'dark'}
                  disabled={places.length === 0}
                >
                  Places
                </TextStyle>
              </FlexStyle>
            </Item>

            <Item
              active={activeTab === 'items'}
              onClick={() => items.length !== 0 && setActive('items')}
              disabled={items.length === 0}
            >
              <FlexStyle>
                <Icon name="headerFindItem" size={1.2} />
                <TextStyle
                  color={activeTab === 'items' ? 'primaryColor' : 'dark'}
                  disabled={items.length === 0}
                >
                  Items
                </TextStyle>
              </FlexStyle>
            </Item>
            <Item
              active={activeTab === 'events'}
              onClick={() => events.length !== 0 && setActive('events')}
              disabled={events.length === 0}
            >
              <FlexStyle>
                <Icon name="headerDiscoverEvent" size={1.2} />
                <TextStyle
                  color={activeTab === 'events' ? 'primaryColor' : 'dark'}
                  disabled={events.length === 0}
                >
                  Events
                </TextStyle>
              </FlexStyle>
            </Item>
          </Tabs>
        )}
      </HeaderWrapper>

      {step === 'result' && (
        <MainSearchResult
          activeTab={activeTab}
          places={places}
          items={items}
          events={events}
          query={query}
          loading={loading}
        />
      )}
      {step === 'search-items' && (
        <Content>
          <SearchSuggestionList query={query} places={places} items={items} events={events} />
        </Content>
      )}
      {step === 'search-location' && (
        <Content>
          {locations.map(edge => (
            <LocationSuggestionItem
              title={edge.name}
              subtitle={`${edge.state_name}, ${edge.post_code}`}
              onClick={() => {
                onHandleFocusSearchInput(edge);
                updateUsedLocation({
                  addressLine1: '',
                  addressLine2: '',
                  city: edge.name,
                  state: edge.state_name,
                  postcode: edge.post_code,
                  country: edge.country_name,
                  latitude: edge.latitude,
                  longitude: edge.longitude,
                });
              }}
            />
          ))}
          {(searchLocationQuery.length === 0 || locations.length === 0) && (
            <>
              <LocationSuggestionItem
                isEnableCurrent
                title="Current location"
                onClick={() =>
                  onHandleFocusSearchInput({
                    name: currentLocation.city,
                    state_name: currentLocation.state,
                    location: `${currentLocation.latitude},${currentLocation.longitude}`,
                  })
                }
              />
              <Wrapper>
                <Text size="small" weight="semibold">
                  Recent Search
                </Text>
              </Wrapper>
              {recentViewLocations.map(edge => (
                <LocationSuggestionItem
                  title={edge.name}
                  subtitle={`${edge.state_name}, ${edge.post_code}`}
                  onClick={() => {
                    onHandleFocusSearchInput(edge);
                    updateUsedLocation({
                      addressLine1: '',
                      addressLine2: '',
                      city: edge.name,
                      state: edge.state_name,
                      postcode: edge.post_code,
                      country: edge.country_name,
                      latitude: edge.latitude,
                      longitude: edge.longitude,
                    });
                  }}
                />
              ))}
            </>
          )}
        </Content>
      )}
    </Container>
  );
};

export default MobileView;
