import React from 'react';
import styled from 'styled-components';
import { Icon, Text, Button } from '../elements';
import { trackEvent } from '../../utils/analytics';

const Container = styled.section`
  height: 90vh;
`;
const ButtonWrapper = styled.div`
  padding: 1rem 3rem;
`;
const ButtonStyle = styled(Button).attrs({
  textStyle: { color: 'white', weight: 'bold' },
})`
  &&& {
    background-color: ${({ theme: colors }) => colors.primaryColor};
  }
`;

const ErrorMessage = () => (
  <Container className="hero">
    <div className="hero-body">
      <div className="container has-text-centered">
        <Text size="large" weight="bold">
          Something is Wrong
        </Text>
        <Text size="small" weight="bold" color="specialGrey">
          message here
        </Text>
        <br />
        <ButtonWrapper>
          <ButtonStyle onClick={() => {}} name="Try Again" />
        </ButtonWrapper>
        <br />
        <h1>
          <Icon name="error" size={30} />
        </h1>
      </div>
    </div>
  </Container>
);

export default ErrorMessage;
