import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import ReactLoading from 'react-loading';
import swal from 'sweetalert';

import { Icon } from '../../../../../components/elements';
import client from '../../../../../utils/apolloClient';
import theme from '../../../../../theme';

const ButtonAnimation = posed.a({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const IconButton = styled(ButtonAnimation)`
  &&& {
    border: 0;
    ${({ paddingLess }) => paddingLess && `padding:0.2rem 0.5rem`};
    background-color: transparent;
  }
`;

const followQuery = gql`
  query follows($input: SearchInput) {
    search_follows(input: $input) {
      follow_listing {
        follow_id
        user_id
        object_id
        type
        place {
          place_id
          name
          tagline
          description
          address_line_1
          city
          state
          country
          cuisine_style {
            name
            description
          }
          business_type {
            name
            description
          }
        }
      }
    }
  }
`;

const followMutation = gql`
  mutation follow($input: ActionFollowInput) {
    action_follow(input: $input) {
      follow_id
      object_id
      object_type
      user_id
      type
    }
  }
`;

const UnFollowActionButton = ({ placeId, followId }) => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={followMutation}
      update={() => {
        const variables = {
          input: {
            filter: {
              follow_filter: { user_id: userId, object_type: 'PLACE', type: 'FOLLOW' },
            },
          },
        };
        const { search_follows } = client.clientPrivate.readQuery({
          query: followQuery,
          variables,
        });

        const deleteExisted = search_follows.follow_listing.filter(
          item => item.follow_id !== followId,
        );

        client.clientPrivate.writeQuery({
          query: followQuery,
          variables,
          data: {
            search_follows: {
              follow_listing: deleteExisted,
              __typename: 'FollowListingAggregation',
            },
          },
        });
      }}
    >
      {(action_follow, { loading }) => (
        <IconButton
          className="button"
          onClick={() => {
            swal({
              text: 'Are you sure do you want to unfollow the place?',
              // text: 'Would you like to remove this bookmark',
              icon: 'warning',
              buttons: ['Keep', 'UnFollow'],
              dangerMode: true,
            }).then(willDelete => {
              if (willDelete) {
                action_follow({
                  variables: {
                    input: {
                      user_id: userId,
                      object_id: placeId,
                      object_type: 'PLACE',
                      type: 'UNFOLLOW',
                      follow_id: followId,
                    },
                  },
                });
              }
            });
          }}
        >
          <span className="icon">
            {loading ? (
              <ReactLoading
                type="spin"
                color={theme.colors.primaryColor}
                height="20px"
                width="20px"
              />
            ) : (
              <Icon name="following" />
            )}
          </span>
        </IconButton>
      )}
    </Mutation>
  );
};

export default UnFollowActionButton;
