import React from 'react';
import styled from 'styled-components';

import { CheckBox, Text } from '../../../../components/elements';

const CheckBoxWrapper = styled.div`
  padding: 1rem 1rem;
  display: flex;
  justify-content: space-between;
`;

const DealsCheckBox = () => {
  return (
    <CheckBoxWrapper>
      <Text weight="medium" size="medium">
        Show places with deals only
      </Text>
      <CheckBox initialValue={false} label="" onChange={() => {}} />
    </CheckBoxWrapper>
  );
};

export default DealsCheckBox;
