import React from 'react';
import PropTypes from 'prop-types';

import { Text, FlexRows, FlexColumns, Avatar } from './elements';

const UserArticle = ({
  user,
  subHeading,
  headingLeftElement,
  headingRightElement,
  children,
  maskProps,
  maskTextProps,
}) => {
  return (
    <article className="media">
      <figure className="media-left">
        <FlexColumns>
          <Avatar
            name={user && user.display_name}
            maskProps={maskProps}
            maskTextProps={maskTextProps}
            borderRadius={25}
            color={user && user.color_code}
            image={user && user.profile_image_url}
          />
          {headingLeftElement}
        </FlexColumns>
      </figure>
      <div className="media-content">
        <FlexRows justify="space-between">
          <div>
            <Text className="is-capitalized" size="medium" weight="semibold" paddingBottom={0.2}>
              {user && user.display_name}
            </Text>
            <Text size="micro" color="darkGrey" paddingBottom={0.5}>
              {subHeading}
            </Text>
          </div>
          {headingRightElement}
        </FlexRows>
        {children}
      </div>
    </article>
  );
};

UserArticle.defaultProps = {
  maskProps: { width: 55 },
  maskTextProps: { color: 'white', weight: 'bold', size: 'large', letterSpacing: 'loose' },
};

UserArticle.propTypes = {
  maskProps: PropTypes.object,
  maskTextProps: PropTypes.object,
};

export default UserArticle;
