import React from 'react';
import { isDesktop } from '../../utils/helpers';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/Layout';

const SupportAndFeedback = ({ history, match }) => {
  const isMobile = !isDesktop();
  return (
    <Layout
      history={history}
      match={match}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading="SUPPORT & FEEDBACK"
    >
      {isMobile ? <MobileView /> : <DesktopView />}
    </Layout>
  );
};

export default SupportAndFeedback;
