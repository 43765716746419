import React from 'react';
import styled from 'styled-components';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';

const Container = styled.div`
  margin-top: 1rem;
`;

const ParentComponent = () => {
  return (
    <>
      <div className="columns is-multiline">
        <div className="column is-full">
          <RectShape color="#f0f0f0" style={{ width: '60%', height: '2.5rem' }} />
        </div>
        <div className="column is-full">
          <RectShape color="#f0f0f0" style={{ width: '100%', height: '8rem' }} />
        </div>
      </div>
      <hr />
      <Container>
        <div className="columns is-multiline">
          <div className="column is-full">
            <RectShape color="#f0f0f0" style={{ width: '60%', height: '2.5rem' }} />
          </div>
          <div className="column is-full">
            <RectShape color="#f0f0f0" style={{ width: '100%', height: '8rem' }} />
          </div>
        </div>
      </Container>
    </>
  );
};

const ParentLoader = () => {
  return (
    <ReactPlaceholder ready showLoadingAnimation>
      <ParentComponent />
    </ReactPlaceholder>
  );
};

export default ParentLoader;
