import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull } from 'lodash';

import User from './User';
import Loading from './Loading';
import Empty from './Empty';
import client from '../../../../utils/apolloClient';

const Container = styled.article``;

const followQuery = gql`
  query follows($input: SearchInput) {
    search_follows(input: $input) {
      follow_listing {
        follow_id
        object_id
        user_id
        type
      }
    }
  }
`;

const Follower = ({ userId }) => {
  return (
    <Query
      client={client.clientPrivate}
      query={followQuery}
      variables={{
        input: {
          filter: {
            follow_filter: { object_id: userId, object_type: 'USER', type: 'FOLLOW' },
          },
        },
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <Empty isError />;
        }
        const { search_follows } = data;

        if (isNull(search_follows)) {
          return <Empty />;
        }
        if (search_follows.follow_listing.length === 0) {
          return <Empty />;
        }
        return (
          <Container>
            <div className="columns is-multiline">
              {search_follows.follow_listing.map(item => (
                <div className="column is-4" key={item.follow_id}>
                  <User
                    userId={item.user_id}
                    followId={item.follow_id}
                    forWhichComponent="Follower"
                  />
                </div>
              ))}
            </div>
          </Container>
        );
      }}
    </Query>
  );
};

export default Follower;
