import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { isNull } from 'lodash';
import { Text } from '../../../../components/elements';
import EventCard from '../../../Events/components/desktop/BusinessCard';
import { getZoneTime } from '../../../../utils/eventHelpers';

const eventsQuery = gql`
  query events($input: SearchInput) {
    search_events(input: $input) {
      event_listing {
        event_id
        name
        tagline
        place_name
        slug
        city
        post_code
        listing_type
        start
        end
        maximum_price
        minimum_price
        address_line_1
        city
        state
        latitude
        longitude
        post_code
        venue_name
        city
        state
        featured
        voucher_count
        event_type {
          description
        }
        event_category {
          description
        }
        default_image_url
      }
    }
  }
`;

const Container = styled.div`
  padding: 0rem 0rem;
`;

const Header = styled.div`
  padding: 1rem 0rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const Events = ({ place, handleElement }) => {
  const [eventDateFilter, setEventDateFilter] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const date = await getZoneTime(moment(), place.latitude, place.longitude);
      setEventDateFilter(moment(date).toISOString());
    };
    fetchData();
  }, [place.latitude, place.longitude]);

  const event_filter = {};

  Object.assign(
    event_filter,
    { place_name: place.name },
    { status: 'ACTIVE', draft: false },
    eventDateFilter !== '' && { end_date_range: { start_date: eventDateFilter } },
  );

  return (
    <Container>
      <Query
        query={eventsQuery}
        variables={{ input: { filter: { event_filter } } }}
        fetchPolicy="network-only"
      >
        {({ data, loading }) => {
          if (loading) {
            return '';
          }

          if (isNull(data.search_events)) {
            return '';
          }
          if (data.search_events.event_listing.length === 0) {
            handleElement('eventElements');
            return '';
          }
          console.log('eventElements', data.search_events.event_listing);
          return <View events={data.search_events.event_listing} handleElement={handleElement} />;
        }}
      </Query>
    </Container>
  );
};

const View = ({ events, handleElement }) => {
  useEffect(() => {
    if (events.length === 0) {
      handleElement('eventElements');
    }
  }, [handleElement, events.length]);

  if (events.length === 0) {
    return null;
  }

  return (
    <>
      <Header>
        <Text size="large" weight="semibold">
          Events
        </Text>
      </Header>
      <br />
      <div className="columns is-multiline">
        {events.map(event => (
          <div className="column is-10">
            <EventCard event={event} />
          </div>
        ))}
      </div>
    </>
  );
};

export default Events;
