import React, { useEffect, useCallback, useState } from 'react';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import moment from 'moment';

import {
  Text,
  FlexRows,
  FlexItem,
  FlexColumns,
  GetDirections,
} from '../../../../components/elements';
import ImageView from './ImageView';
import AddToCalender from '../../../../components/AddToCalender';
import ShareActionButton from '../../../../components/ShareActionButton';
import client from '../../../../utils/apolloClient';
import { getZoneTime } from '../../../../utils/eventHelpers';

const Container = styled.div`
  padding: 1rem;
`;

const AddressWrapper = styled(FlexRows)`
  padding-bottom: 0.5rem;
`;

const ShareWrapper = styled.div`
  padding-left: 0.2rem;
`;

const ShareActionButtonButtonStyle = styled(ShareActionButton).attrs({
  textStyle: { size: 'small', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1.2 },
  showCount: false,
})``;

const placeQuery = gql`
  query eventTicketsQuery($placeId: String) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      event_business
    }
  }
`;

const Header = ({ event }) => {
  const [placeEventOrganizeOnly, setPlaceEventOrganizeOnly] = useState(false);
  const [eventStartDate, setEventStartDate] = useState(event.start);
  const [eventEndDate, setEventEndDate] = useState(event.end);
  const eventTicketStartEndDate = useCallback(async () => {
    client.clientPublic
      .query({
        query: placeQuery,
        variables: { placeId: event.place_id },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          setPlaceEventOrganizeOnly(data.fetch_place.event_business);
        }
      });
  }, [event.place_id]);

  useEffect(() => {
    const fetData = async () => {
      const startDate = await getZoneTime(event.start, event.latitude, event.longitude);
      const endDate = await getZoneTime(event.end, event.latitude, event.longitude);
      setEventStartDate(startDate);
      setEventEndDate(endDate);
    };
    fetData();
    eventTicketStartEndDate();
  }, [event, eventTicketStartEndDate]);

  return (
    <Container>
      <FlexRows justify="space-between">
        <FlexItem grow={1}>
          <div className="columns">
            <div className="column is-3">
              <ImageView image={event.default_image_url} />
            </div>
            <div className="column is-5">
              <Text className="is-capitalized" size="large" weight="semibold" paddingBottom={0.5}>
                {capitalize(event.name)}
              </Text>
              {!placeEventOrganizeOnly && (
                <Link to={`/place/${event.place_id}`}>
                  <Text size="tiny" weight="semibold" color="dark">
                    {event.place_name}
                  </Text>
                </Link>
              )}
              {placeEventOrganizeOnly && (
                <Text size="tiny" weight="semibold" color="dark">
                  {event.place_name}
                </Text>
              )}
              <AddressWrapper justify="flex-start" align="flex-end">
                <Text size="tiny">
                  {event.address_line_1} <br /> {event.city}, {event.state}
                </Text>
                <GetDirections latitude={event.latitude} longitude={event.longitude} />
              </AddressWrapper>
            </div>
          </div>
        </FlexItem>
        <div>
          <FlexColumns justify="flex-start" align="flex-start">
            <Text size="tiny" weight="semibold" color="dark" paddingBottom={0.2}>{`${moment(
              eventStartDate,
            ).format('llll')} to ${
              moment(eventStartDate).format('L') === moment(eventEndDate).format('L')
                ? moment(eventEndDate).format('LT')
                : moment(eventEndDate).format('llll')
            }`}</Text>
            <AddToCalender event={event} />
            <ShareWrapper>
              <ShareActionButtonButtonStyle
                dropdownClassName="is-down"
                objectId={event.event_id}
                objectType="EVENT"
              />
            </ShareWrapper>
          </FlexColumns>
        </div>
      </FlexRows>
    </Container>
  );
};

export default Header;
