import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { Icon, FlexRows } from '../../../../components/elements';
import GetImages from '../../../../components/GetImages';

const Container = styled(FlexRows).attrs({ justify: 'flex-start' })`
  margin: 0rem 1rem;
  margin-top: 1rem;
`;

const Field = styled.div`
  width: 82%;
  margin-left: 1rem;
`;

const ItemReviewActionButton = ({ history, item }) => {
  const { isLoggedIn, user, userImage } = useStoreState(state => state.auth);

  return (
    <Container>
      {isLoggedIn ? (
        <GetImages
          name={user.display_name}
          maskProps={{ width: 40, height: 40 }}
          maskTextProps={{
            color: 'white',
            weight: 'bold',
            size: 'medium',
            letterSpacing: 'loose',
          }}
          borderRadius={25}
          randomColorCode={user.color_code}
          userImage={userImage}
        />
      ) : (
        <Icon name="myProfileBlue" size={2.5} />
      )}

      <Field className="control has-icons-left has-icons-right">
        <input
          className="input is-rounded is-fullwidth"
          placeholder={`Rate and Share ${item.name} at ${item.place_name}`}
          onFocus={() => history.push(`/item-review/${item.item_id}`)}
        />
        <span className="icon is-small is-left">
          <Icon name="itemRatingGoldSolid" size={1.2} />
        </span>
      </Field>
    </Container>
  );
};
export default withRouter(ItemReviewActionButton);
