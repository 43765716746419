/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { first } from 'lodash';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { startEndDateParser } from '../../../utils/helpers';

import { Text, FlexRows, IconActionButton, ShowMoreText } from '../../../components/elements';

import ImageView from './desktop/ImageView';
import SocialActionButton from '../../../components/SocialActionButton';
import BookingButton from './BookingButton';

const Details = styled.div`
  padding: 1rem 0rem;
`;
const Description = styled(ShowMoreText)`
  padding-top: 0.8rem;
`;
const Wrapper = styled.div`
  padding: 0.5rem 1rem;
`;
const SpecialSpan = styled.span`
  background-color: ${({ theme: { colors: color } }) => color.lightGreyBeta};
  padding: 4px;
`;

const TagWrapper = styled.div`
  padding: 0.5rem 0rem;
`;

const SocialActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'small', weight: 'semibold', color: 'darkGrey' },
  iconStyle: { size: 1.2 },
})``;
const IconActionButtonStyle = styled(IconActionButton).attrs({
  textStyle: { size: 'small', weight: 'semibold', color: 'darkGrey' },
  iconStyle: { size: 1.2 },
})``;

const Event = ({ event, totalNumberOfTicket }) => (
  <Wrapper>
    <div>
      <Text className="is-capitalized" size="medium" weight="semibold">
        {event.name || ''}
      </Text>
      <Text size="small" weight="medium" color="darkGrey">
        {event.place_name || ''}
      </Text>
      <Text size="micro" weight="medium" color="darkGrey">
        {event.address_line_1 || ''}
      </Text>
      <Text size="micro" weight="medium" color="darkGrey">
        {event.city || ''}, {event.state || ''}
      </Text>
      <FlexRows justify="space-between">
        <Text
          className="is-uppercase"
          size="micro"
          weight="semibold"
          color="primaryColor"
          paddingBottom={0.2}
        >
          Find Other Events and Details of this Place
        </Text>
        <Text color="coolGrey">
          <MdKeyboardArrowRight size={25} />
        </Text>
      </FlexRows>
    </div>
    <ImageView image={event.default_image_url} />
    <br />
    <FlexRows justify="space-between">
      <SocialActionButtonStyle
        social={event.social}
        objectId={event.event_id}
        objectType="EVENT"
        type="LIKE"
        name="LIKE"
        defaultIcon="likeGrey"
        activeIcon="likeBlue"
      />
      <SocialActionButtonStyle
        social={event.social}
        objectId={event.event_id}
        objectType="EVENT"
        type="BOOKMARK"
        name="BOOKMARK"
        defaultIcon="bookmarkGrey"
        activeIcon="bookmarkBlue"
      />
      <IconActionButtonStyle name="SHARE" icon="shareBlue" />
    </FlexRows>
    <br />
    <Text size="micro" color="darkGrey" paddingBottom={0.5}>
      <SpecialSpan>
        {event.event_type && event.event_type.length !== 0 && first(event.event_type).description}
        {event.event_category && event.event_category.length !== 0 && ' | '}
        {event.event_category &&
          event.event_category.length !== 0 &&
          event.event_category.map(
            (item, index) =>
              `${item.description}${index !== event.event_category.length - 1 ? ', ' : ''}`,
          )}
      </SpecialSpan>
    </Text>
    <BookingButton event={event} disabled={totalNumberOfTicket === 0} />
    <Details>
      <Text weight="regular" color="dark" size="small">
        {startEndDateParser(event.start, event.end)}
      </Text>

      {event.ticket_sale_start && totalNumberOfTicket !== 0 && (
        <div>
          <Text size="tiny" color="darkGrey" paddingBottom={2}>
            Tickets available from {moment(event.eventSaleStartDate).format('llll')}
            {moment(event.eventSaleEndDate).isAfter(event.eventSaleStartDate) &&
              ` to ${moment(event.eventSaleEndDate).format('llll')}`}
          </Text>
        </div>
      )}
      <Description
        size="tiny"
        weight="medium"
        color="darkGrey"
        content={event.description}
        limit={200}
      />
    </Details>
    {event.dress_code.length !== 0 && (
      <TagWrapper>
        <Text size="small" weight="semibold">
          Dress code
        </Text>
        <Text size="small" color="darkGrey">
          {event.dress_code.map(
            (item, index) =>
              `${item.description}${index !== event.dress_code.length - 1 ? ', ' : ''}`,
          )}
        </Text>
      </TagWrapper>
    )}
    {event.restriction.length !== 0 && (
      <TagWrapper>
        <Text size="small" weight="semibold">
          Age restriction
        </Text>
        <Text size="small" color="darkGrey">
          {event.restriction.length === 4
            ? 'All Ages'
            : event.restriction.map(
                (item, index) =>
                  `${item.description}${index !== event.restriction.length - 1 ? ', ' : ''}`,
              )}
        </Text>
      </TagWrapper>
    )}
    {event.feature.length !== 0 && (
      <TagWrapper>
        <Text size="small" weight="semibold">
          Features
        </Text>
        <Text size="small" color="darkGrey" paddingBottom={1}>
          {event.feature.map(
            (item, index) => `${item.description}${index !== event.feature.length - 1 ? ', ' : ''}`,
          )}
        </Text>
      </TagWrapper>
    )}
  </Wrapper>
);

export default Event;
