import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';

import { Text, FlexRows, Button } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';

import PaymentItem from './PaymentItem';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 30rem;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    }
    .modal-card-head {
      padding: 1rem 2rem;
      padding-bottom: 0.6rem;
      background-color: rgba(255, 255, 255, 1);
      border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
    }
    .modal-card-body {
      padding: 2rem;
      padding-top: 0rem;
    }
    a {
      color: ${colors.primaryColor};
    }
  }
`;

const Header = styled(FlexRows)`
  padding: 0rem 0rem;
  width: 100%;
  .pickup-time {
    margin-right: 11rem;
  }
`;

const Wrapper = styled.div`
  padding-bottom: 6rem;
  padding-top: 1rem;
`;

const AddPaymentMethodBtn = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
`;

const Footer = styled.footer`
  && {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 1);
    border-top: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  }
`;

const AddPaymentMethodModal = ({ isActiveModal, setIsActiveModal, values, setFieldValue }) => {
  const [addCardForm, setAddCardForm] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(values.payment_method);

  return (
    <Container className={`modal ${isActiveModal && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <Header justify="space-between">
            <Text size="large" weight="semibold" paddingBottom={0.5}>
              {addCardForm ? 'Add Credit or debit card' : 'Payment Method'}
            </Text>
            <IoMdClose
              size={20}
              color={colors.dark}
              style={{ marginLeft: '1rem', cursor: 'pointer' }}
              onClick={() => {
                setIsActiveModal(false);
                setAddCardForm(false);
              }}
            />
          </Header>
        </header>

        {addCardForm ? (
          <div className="modal-card-body">
            <Wrapper addCardForm={addCardForm}>
              <div className="field">
                <label className="label">Card Number</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    id="CardNumber"
                    placeholder="**** **** **** 4567"
                  />
                </div>
              </div>
              <FlexRows justify="space-between">
                <div className="field">
                  <label className="label">Expiry Date</label>
                  <div className="control">
                    <input className="input" type="text" id="ExpiryDate" placeholder="02/21" />
                  </div>
                </div>
                <div className="field">
                  <label className="label">CVV</label>
                  <div className="control">
                    <input className="input" type="text" id="CVV" placeholder="123" />
                  </div>
                </div>
              </FlexRows>
            </Wrapper>
          </div>
        ) : (
          <div className="modal-card-body">
            <Wrapper>
              <PaymentItem
                isActive={paymentMethod === 'cash'}
                title="Cash"
                onClick={() => setPaymentMethod('cash')}
              />
              <PaymentItem title="**** **** **** 6578" />
              <AddPaymentMethodBtn>
                <Text
                  size="medium"
                  weight="regular"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setAddCardForm(true)}
                >
                  + Add a credit or debit card
                </Text>
              </AddPaymentMethodBtn>
            </Wrapper>
          </div>
        )}

        <Footer className="modal-card-foot">
          <Button
            // className={isLoading && 'is-loading'}
            type="submit"
            name={addCardForm ? 'Add Card' : 'Update'}
            width={3}
            backgroundColor="primaryColor"
            textProps={{
              color: colors.white,
              weight: 'semibold',
              size: 'small',
            }}
            style={{ width: '100%' }}
            onClick={() => {
              setIsActiveModal(false);
              setFieldValue('payment_method', paymentMethod);
            }}
          />
        </Footer>
      </div>
    </Container>
  );
};

export default withRouter(AddPaymentMethodModal);
