import React from 'react';
import styled from 'styled-components';
// import { MdKeyboardArrowRight } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { BsFillXCircleFill } from 'react-icons/bs';

import moment from 'moment';
import { formatCurrency } from '../../../../utils/helpers';
import { Text, FlexRows, CheckBox } from '../../../../components/elements';

import YourOrderItem from './YourOrderItem';

const Container = styled.div`
  && {
    position: sticky;
    top: 10rem;
    .order-details {
      padding: 2rem 0rem;
    }
    .your-order {
      padding-top: 1rem;
    }
    .order-detail-info {
      padding-bottom: 1rem;
    }
    .rest-name {
      margin-top: 1.5rem;
    }
    .d-flex {
      display: flex;
      justify-content: space-between;
    }
    figure {
      margin-bottom: 1rem;
    }
    .is-2by1 {
      padding-top: 40%;
      margin-top: 4rem;
    }
    span {
      color: ${({ theme: { colors: themeColors } }) => themeColors.primaryColor};
    }
  }
`;

const Bottom = styled.div`
  padding-top: 2rem;
`;

const Loading = () => <ReactLoading type="spin" color="#00aeef" height="30px" width="30px" />;

const YourOrder = ({ order, actionLoading, onCancel }) => {
  const promoDiscount =
    order.billing.sub_total + order.billing.delivery_fee - order.billing.order_total;
  return (
    <Container>
      <div className="d-flex justify-space-between">
        <Text weight="semibold" size="large" paddingBottom="0.2" className="your-order">
          Your Order
        </Text>
        {order.status === 'RECEIVED' && (
          <>
            {actionLoading ? (
              <div className="your-order">
                <Loading />
              </div>
            ) : (
              <a onClick={onCancel}>
                <Text size="medium" color="primaryColor" paddingBottom="0.2" className="your-order">
                  Cancel Order
                </Text>
              </a>
            )}
          </>
        )}
      </div>
      <>
        <>
          <div className="order-detail-info">
            <Text className="is-capitalized rest-name" size="smedium" weight="semibold">
              {order.place.name}
            </Text>
            <Text size="small" color="darkGrey" weight="regular" paddingBottom={0.5}>
              {order.place.address_line_1}, {order.place.city}, {order.place.state},{' '}
              {order.place.post_code}
            </Text>
          </div>
          <FlexRows justify="space-between">
            <div className="order-detail-info">
              <Text size="smedium" weight="regular">
                Order Number
              </Text>
              <Text size="small" color="darkGrey" weight="regular" paddingBottom={0.5}>
                {order.order_reference}
              </Text>
            </div>
            <div className="order-detail-info">
              <Text size="smedium" weight="regular">
                Service
              </Text>
              <Text size="small" color="darkGrey" weight="regular" paddingBottom={0.5}>
                {order.service_type}
              </Text>
            </div>
          </FlexRows>
          <div className="order-detail-info">
            <Text size="smedium" weight="regular">
              Order Date
            </Text>
            <Text size="small" color="darkGrey" weight="regular" paddingBottom={0.5}>
              {moment(order.audit.created_at).format('LLLL')}
            </Text>
          </div>
          <div className="order-detail-info">
            <Text size="smedium" weight="regular">
              Status
            </Text>
            <FlexRows
              justify="flex-start"
              style={{ marginTop: '0.2rem' }}
              className="order-detail-info"
            >
              {order.status === 'CANCELLED' ? (
                <>
                  <BsFillXCircleFill
                    size={20}
                    color="red"
                    style={{ marginRight: '1rem', cursor: 'pointer' }}
                  />
                  <Text size="small" weight="regular" color="darkGrey">
                    Order Cancelled
                  </Text>
                </>
              ) : (
                <Text size="small" weight="regular" color="primaryColor">
                  {order.status}
                </Text>
              )}
            </FlexRows>
          </div>
          <div className="order-detail-info">
            <Text size="smedium" weight="regular">
              Payment
            </Text>
            <Text size="small" color="darkGrey" weight="regular" paddingBottom={0.5}>
              Cash
            </Text>
          </div>
        </>

        <Text weight="semibold" size="large" paddingBottom="0.2" className="order-details">
          Order Details
        </Text>
        {order.line_item.map(item => (
          <YourOrderItem item={item} />
        ))}
        {order.include_cuttlery && (
          <CheckBox
            label="Please send cutlery, straws, napkins etc"
            initialValue={order.include_cuttlery}
            labelTextProps={{ size: 'smedium', color: 'coolGrey', weight: 'medium' }}
            disabled
          />
        )}
        {order.note && order.length !== 0 && (
          <Text weight="medium" size="tiny" color="darkGrey" lineHeight="normal">
            Notes: {order.note.map(item => item.value)}
          </Text>
        )}
        <Bottom>
          {order.billing.sub_total && order.billing.sub_total !== 0 && (
            <FlexRows justify="space-between">
              <Text weight="medium" size="smedium" paddingBottom="0.2">
                Items subtotal
              </Text>
              <Text weight="medium" size="smedium" paddingBottom="0.2">
                {formatCurrency(order.billing.sub_total)}
              </Text>
            </FlexRows>
          )}
          {order.billing.delivery_fee && order.billing.delivery_fee !== 0 && (
            <FlexRows justify="space-between">
              <Text weight="medium" size="smedium" paddingBottom="0.2">
                Delivery fee
              </Text>
              <Text weight="medium" size="smedium" paddingBottom="0.2">
                {formatCurrency(order.billing.delivery_fee)}
              </Text>
            </FlexRows>
          )}
          {promoDiscount !== 0 && (
            <FlexRows justify="space-between">
              <Text color="primaryColor" weight="semibold" size="smedium" paddingBottom="0.2">
                Promo
              </Text>
              <Text color="primaryColor" weight="semibold" size="smedium" paddingBottom="0.2">
                - {formatCurrency(promoDiscount)}
              </Text>
            </FlexRows>
          )}
          {order.billing.order_total && order.billing.order_total !== 0 && (
            <FlexRows justify="space-between">
              <Text weight="semibold" size="medium" paddingBottom="0.2">
                Total
              </Text>
              <Text weight="semibold" size="smedium" paddingBottom="0.2">
                {formatCurrency(order.billing.order_total)}
              </Text>
            </FlexRows>
          )}
        </Bottom>
      </>
    </Container>
  );
};

export default withRouter(YourOrder);
