import React from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { useFormik } from 'formik';
import { useStoreState } from 'easy-peasy';
import * as yup from 'yup';

import {
  FlexRows,
  Text,
  Field,
  TextArea,
  Button,
  InputErrorMessage,
} from '../../../../components/elements';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 45rem;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    }
    .modal-card-body {
      padding: 1rem 1.5rem;
    }
  }
`;
const ButtonStyle = styled.div`
  &&& {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

const FieldStyle = styled(Field).attrs({ labelTextProps: { color: 'dark', label: 'small' } })``;
const Label = styled(Text).attrs({
  color: 'darkGrey',
  size: 'small',
  weight: 'semibold',
})``;

const Span = styled.span`
  color: red;
`;

const Content = styled.div`
  padding: 1rem 0rem;
`;

const ButtonWrapper = styled(FlexRows)``;

const EventContactModal = ({ isActive, onClose, event, loading, onSubmit }) => {
  const { userId } = useStoreState(state => state.auth);
  const formik = useFormik({
    initialValues: {
      user_id: userId,
      event_id: event.event_id,
      name: '',
      email: '',
      message: '',
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is required!'),
      email: yup
        .string()
        .email('Enter valid email address!')
        .required('Email is required!'),
      message: yup.string().required('Message is required!'),
    }),
    onSubmit,
  });
  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <form
          className="modal-card-body"
          onSubmit={e => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <FlexRows justify="space-between">
            <div>
              <Text className="is-capitalized" size="large" weight="semibold">
                {event.name}
              </Text>
              <Text className="is-capitalized" size="tiny" weight="semibold" color="darkGrey">
                {event.place_name}, {capitalize(event.venue_name)}
              </Text>
            </div>
            <ButtonStyle className="delete" label="close" onClick={onClose} />
          </FlexRows>
          <Content>
            <div className="field">
              <Label>
                Your name <Span>*</Span>
              </Label>
            </div>
            <FieldStyle
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Your name"
            />
            <InputErrorMessage touched={formik.touched.name} errors={formik.errors.name} />
            <div className="field">
              <Label>
                Your address <Span>*</Span>
              </Label>
            </div>
            <FieldStyle
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Your email address"
            />
            <InputErrorMessage touched={formik.touched.email} errors={formik.errors.email} />
            <div className="field">
              <Label>
                Your message <Span>*</Span>
              </Label>
            </div>
            <TextArea
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Your address"
            />
            <InputErrorMessage touched={formik.touched.message} errors={formik.errors.message} />
          </Content>

          <ButtonWrapper justify="space-between">
            <Button
              type="button"
              name="Cancel"
              backgroundColor="primaryColor"
              textProps={{ size: 'small', color: 'white', weight: 'semibold' }}
              onClick={onClose}
            />
            <Button
              type="submit"
              className={loading && 'is-loading'}
              name="Send Message"
              backgroundColor="primaryColor"
              textProps={{
                size: 'small',
                color: loading ? 'primaryColor' : 'white',
                weight: 'semibold',
              }}
            />
          </ButtonWrapper>
        </form>
      </div>
    </Container>
  );
};

export default EventContactModal;
