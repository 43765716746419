import React from 'react';
import ReactSelectInput from './ReactSelectInput';
import InputErrorMessage from './InputErrorMessage';

const TagInputs = ({ label, value, onChange, suggestions, errors, touched, disabled }) => (
  <div className="field">
    {label && label}
    <div className="control">
      <ReactSelectInput
        defaultValue={value}
        closeMenuOnSelect={false}
        isMulti
        name="colors"
        options={suggestions}
        // isLoading={suggestions ? suggestions.length === 0 : true}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
        isDisabled={disabled}
      />
    </div>
    <InputErrorMessage errors={errors} touched={touched} />
  </div>
);

export default TagInputs;
