import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { Text, FlexRows } from '../../../../components/elements';

const Container = styled.div`
  padding: 2rem 0rem;
  border-bottom: 1px solid;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.borderColor};
  cursor: pointer;
`;

const MenuItem = ({ placeId, title, history, description }) => {
  return (
    <Container onClick={() => history.push(`/order-food/${title}/${placeId}`)}>
      <FlexRows justify="space-between">
        <Text size="medium" color="primaryColor" weight="regular">
          {title}
        </Text>
        <Text color="coolGrey">
          <MdKeyboardArrowRight size={30} />
        </Text>
      </FlexRows>
      <Text size="small" color="darkGrey" weight="regular">
        {description}
      </Text>
    </Container>
  );
};

export default withRouter(MenuItem);
