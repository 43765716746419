import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isNull, first } from 'lodash';
import posed from 'react-pose';
import gql from 'graphql-tag';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withRouter } from 'react-router-dom';
import { Icon, Text } from './elements';

import client from '../utils/apolloClient';
import { FaceBookShare, Copy, Email } from './elements/SocialShare';

const ButtonAnimation = posed.a({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const Button = styled(ButtonAnimation)`
  &&& {
    border: 0;
    ${({ paddingLess }) => paddingLess && `padding:0.2rem 0.5rem`};
    background-color: transparent;
  }
`;

const socialQuery = gql`
  query social($input: SearchInput) {
    search_social(input: $input) {
      social_listing {
        social_id
      }
      social_aggregation {
        total_social_count {
          type
          count
        }
        total_social_per_object_id {
          object_id
          total_social_count {
            user {
              user_id
              time
              social_id
            }
          }
        }
      }
    }
  }
`;
const socialMutation = gql`
  mutation socialAction($input: SocialNetworkInput) {
    create_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const ShareActionButton = ({
  objectId,
  objectType,
  type,
  icon,
  textStyle,
  iconStyle,
  paddingLess,
  showCount,
  dropdownClassName,
  link,
}) => {
  const [socialCount, setSocialCount] = useState(0);
  const setIsActiveDesktopLoginModal = useStoreActions(
    state => state.auth.setIsActiveDesktopLoginModal,
  );
  const { isLoggedIn, userId } = useStoreState(state => state.auth);

  const social_filter = {};
  Object.assign(social_filter, { object_type: objectType, object_id: objectId, type });

  useEffect(() => {
    if (isLoggedIn) {
      client.clientPublic
        .query({
          query: socialQuery,
          fetchPolicy: 'network-only',
          variables: {
            input: {
              filter: {
                social_filter,
              },
            },
          },
        })
        .then(async ({ data: { search_social } }) => {
          if (search_social && !isNull(search_social)) {
            if (search_social.social_aggregation && !isNull(search_social.social_aggregation)) {
              const totalSocialCount = first(search_social.social_aggregation.total_social_count);
              if (totalSocialCount) {
                setSocialCount(totalSocialCount.count);
              }
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createShareMutation = () => {
    client.clientPrivate
      .mutate({
        mutation: socialMutation,
        variables: {
          input: {
            user_id: userId,
            object_id: objectId,
            object_type: objectType,
            type,
            value: 1,
          },
        },
      })
      .then(() => {
        // toast.success('Flag successfully submitted!');
      })
      .catch(() => {});
  };

  const handleShare = () => {
    if (!isLoggedIn) {
      setIsActiveDesktopLoginModal({
        value: true,
        path: '',
        callBackFunction: () => {},
      });
    } else {
      createShareMutation();
    }
  };

  return (
    <>
      <div className={`dropdown is-hoverable ${dropdownClassName}`}>
        <div className="dropdown-trigger">
          <Button className="button" paddingLess={paddingLess}>
            {icon && (
              <span className="icon">
                <Icon name={icon} {...iconStyle} />
              </span>
            )}
            <span>
              <Text className="is-capitalized" {...textStyle}>
                Share {showCount && socialCount !== 0 && `(${socialCount})`}
              </Text>
            </span>
          </Button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <FaceBookShare shareAction={handleShare} url={link} />
            <Copy text={link} />
            <Email text={link} />
          </div>
        </div>
      </div>
    </>
  );
};

ShareActionButton.defaultProps = {
  type: 'SHARE',
  icon: 'shareBlue',
  dropdownHeading: 'Social Share',
  dropdownHeadingFlagIcon: 'shareBlue',
  showCount: true,
};

ShareActionButton.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string,
  dropdownHeading: PropTypes.string,
  dropdownHeadingFlagIcon: PropTypes.string,
  showCount: PropTypes.bool,
};

export default withRouter(ShareActionButton);
