import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { capitalize, dropRight } from 'lodash';
import { GoSearch } from 'react-icons/go';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { MdClear } from 'react-icons/md';
import queryString from 'query-string';

import { AutoCompleteInput, Text, Icon } from '../../../../components/elements';
import client from '../../../../utils/apolloClient';
import colors from '../../../../theme/styles/colors';
import { getZoneTime } from '../../../../utils/eventHelpers';
import config from '../../../../utils/config';

const SearchWrapper = styled.div`
  padding: 0.25rem 1rem;
`;

const Container = styled.form`
  &&& {
    padding-left: 0;
    .icon {
      pointer-events: initial;
    }
    .react-autosuggest__container {
      position: relative;
    }
    .react-autosuggest__suggestions-container {
      display: none;
    }
    .react-autosuggest__suggestions-container--open {
      max-height: 30rem;
      overflow-y: hidden;
      display: block;
      position: absolute;
      width: 100%;
      background: #fff;
      border: 1px solid #f6f6f6;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
      z-index: 2000;
    }
    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 7px 10px;
      border-bottom: 1px solid #f6f6f6 !important;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: #f6f6f6;
    }
    .control.has-icons-left .icon,
    .control.has-icons-right .icon {
      top: -2px;
    }
    .input {
      font-weight: 500;
      font-size: 14px;
      color: ${colors.dark};
      text-transform: capitalize;
      ::placeholder {
        color: ${colors.coolGrey};
        font-weight: 500;
        opacity: 1;
        text-transform: none;
      }
      :focus {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
`;

const IconWrapper = styled.div`
  padding-top: 0.15rem;
  padding-right: 0.5rem;
`;

const searchQuery = gql`
  query search(
    $search_criteria: String!
    $location: String
    $distanceRadius: String
    $eventFilter: EventFilter
  ) {
    search_places(
      input: {
        location: $location
        filter: {
          place_filter: {
            place_name: $search_criteria
            listing_status: "APPROVED"
            event_business: false
            status: "ACTIVE"
            distance: $distanceRadius
          }
        }
      }
    ) {
      place_listing {
        place_id
        name
        slug
        address_line_1
        city
        state
        post_code
        country
      }
    }
    search_items(
      input: {
        location: $location
        filter: {
          item_filter: {
            name: $search_criteria
            status: "ACTIVE"
            approval_status: "APPROVED"
            distance: $distanceRadius
          }
          place_filter: { status: "ACTIVE" }
        }
      }
    ) {
      item_listing {
        item_id
        name
        place_name
        address_line_1
        city
        state
        post_code
        country
      }
    }
    search_events(
      input: {
        location: $location
        filter: { event_filter: $eventFilter, place_filter: { status: "ACTIVE" } }
      }
    ) {
      event_listing {
        event_id
        name
        address_line_1
        city
        state
        post_code
        country
      }
    }
  }
`;

const renderLocationSuggestion = location => (
  <div className="is-flex">
    {location.showAllResult ? (
      <Text size="tiny" color="tertiaryColor" weight="semibold">
        {location.title}
      </Text>
    ) : (
      <>
        <IconWrapper>
          {location.place_id && <Icon name="headerPlaceAroundMe" size={1.6} />}
          {location.item_id && <Icon name="headerFindItem" size={1.6} />}
          {location.event_id && <Icon name="headerDiscoverEvent" size={1.6} />}
        </IconWrapper>
        <div>
          <Text className="is-capitalized" color="primaryColor" size="tiny" weight="bold">
            {capitalize(location.name)}
          </Text>
          {location.item_id && (
            <Text className="is-capitalized" color="darkGrey" size="micro">
              {capitalize(location.place_name)}
            </Text>
          )}
          <Text color="darkGrey" size="micro">
            {location.address_line_1}, {location.city}, {location.state}
          </Text>
        </div>
      </>
    )}
  </div>
);
const getSuggestionValue = suggestion => suggestion.suburbName;

const renderSuggestionsContainer = ({ containerProps, children }) => {
  return <div {...containerProps}>{children}</div>;
};

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: '',
      suggestions: [],
      eventDateFilter: '',
    };
  }

  fetchData = async () => {
    const { usedLocation } = this.props;
    const date = await getZoneTime(moment(), usedLocation.latitude, usedLocation.longitude);
    this.setState({ eventDateFilter: moment(date).toISOString() });
  };

  componentDidMount = async () => {
    const { query } = queryString.parse(this.props.location.search);
    if (query) {
      this.setState({ location: query });
    }
    // this.fetchData();
  };

  setSuburbSuggestions = suggestions => {
    this.setState({ suggestions });
  };

  onChange = (event, { newValue, method }) => {
    // if (method === 'type') {
    //   this.setState({
    //     location: newValue,
    //   });
    // }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { usedLocation } = this.props;
    const { eventDateFilter } = this.state;
    const event_filter = {};

    Object.assign(
      event_filter,
      {
        event_name: value,
        status: 'ACTIVE',
        draft: false,
        distance: config.eventSearchDistanceRadius,
      },
      eventDateFilter !== '' && {
        end_date_range: { start_date: eventDateFilter },
      },
    );

    client.clientPublic
      .query({
        query: searchQuery,
        variables: {
          search_criteria: value,
          location: `${usedLocation.latitude},${usedLocation.longitude}`,
          eventFilter: event_filter,
          distanceRadius: config.searchDistanceRadius,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        let finalSuggestion = [];
        if (data.search_places.length !== 0) {
          finalSuggestion = finalSuggestion.concat(data.search_places.place_listing);
        }
        if (data.search_items.length !== 0) {
          finalSuggestion = finalSuggestion.concat(data.search_items.item_listing);
        }
        if (data.search_events.length !== 0) {
          finalSuggestion = finalSuggestion.concat(data.search_events.event_listing);
        }
        let finalCustomSuggestion = [
          {
            showAllResult: true,
            title: `Show all result for "${value}"`,
            path: `/global-search?query=${value}`,
          },
        ];

        finalCustomSuggestion = finalCustomSuggestion.concat(
          dropRight(finalSuggestion, finalSuggestion ? finalSuggestion.length - 5 : 0),
        );

        this.setSuburbSuggestions(
          finalSuggestion.length === 0 ? finalSuggestion : finalCustomSuggestion,
        );
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({
      location: capitalize(suggestion.name),
    });
    const { history } = this.props;
    if (suggestion.place_id) {
      history.push(`/place/${suggestion.place_id}`);
    }
    if (suggestion.item_id) {
      history.push(`/item/${suggestion.item_id}`);
    }
    if (suggestion.event_id) {
      history.push(`/event/${suggestion.event_id}`);
    }
    if (suggestion.showAllResult) {
      history.push(suggestion.path);
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      location: '',
    });
  };

  render() {
    const { location, suggestions } = this.state;
    const { history } = this.props;

    return (
      <SearchWrapper>
        <Text className="is-capitalized" color="dark" size="lmedium" weight="regular">
          Search for places or cuisines
        </Text>
        <Container
          className="navbar-item"
          onSubmit={event => {
            event.preventDefault();
            if (location.length >= 1) {
              if (suggestions.length === 2) {
                const suggestion = suggestions[1];
                if (suggestion.place_id) {
                  history.push(`/place/${suggestion.place_id}`);
                }
                if (suggestion.item_id) {
                  history.push(`/item/${suggestion.item_id}`);
                }
                if (suggestion.event_id) {
                  history.push(`/event/${suggestion.event_id}`);
                }
              } else {
                history.push(`/global-search?query=${location}`);
              }
            }
          }}
        >
          <AutoCompleteInput
            ref={this.inputRef}
            rightIcon={location.length > 0 && <MdClear size={22} color={colors.specialGrey} />}
            onClickRightIcon={this.onSuggestionsClearRequested}
            inputClass="is-medium"
            width={`${window.innerWidth / 3.58}px`}
            maxWidth={250}
            value={location}
            placeholder="Search for Places or Cuisines "
            leftIcon={<GoSearch size={18} color={colors.coolGrey} />}
            enableInputWithAllElements
            onChange={this.onChange}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            // onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderLocationSuggestion}
            onSuggestionSelected={this.onSuggestionSelected}
            customRenderSuggestionsContainer={renderSuggestionsContainer}
          />
        </Container>
      </SearchWrapper>
    );
  }
}

export default withRouter(SearchBar);
