import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { capitalize, first } from 'lodash';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { IoMdStar } from 'react-icons/io';
import { MdPhone } from 'react-icons/md';
import { useStoreState } from 'easy-peasy';

import { GoLocation } from 'react-icons/go';
import { isOpen, getPlaceTagline, parseSocialPlace } from '../../../../utils/helpers';
import {
  Text,
  FlexRows,
  FlexColumns,
  Button,
  Icon,
  GetDirections,
} from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';
import PlaceSocial from './PlaceSocial';

const Container = styled.div`
  && {
    .columns:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

const Wrapper = styled.div`
  padding-left: 0.4rem;
`;

const Heading = styled(Text)`
  span {
    color: ${({ theme: { colors: color } }) => color.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.tiny};
    font-weight: ${({ theme: { fonts } }) => fonts.weight.medium};
    text-transform: none;
  }
`;
const AddressWrapper = styled(FlexRows)`
  padding-bottom: 0.5rem;
`;

const SubHeading = styled(Text)`
  span {
    color: ${({ theme: { colors: themeColor } }) => themeColor.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
    font-weight: ${({ theme: { fonts } }) => fonts.weight.medium};
  }
`;

const ProgressBar = styled(CircularProgressbar)`
  max-width: 55px;
`;

const IconActionButtonWrapper = styled.div`
  padding: 1rem 0rem;
  margin-left: 0.5rem;
`;

const styles = {
  root: {
    width: '100%',
  },
  path: {
    stroke: colors.tertiaryColor,
    strokeLinecap: 'round',
    transition: 'stroke-dashoffset 0.5s ease 0s',
  },
  trail: {
    stroke: 'transparent',
  },
  text: {
    fill: colors.secondaryColor,
    fontSize: 20,
    dominantBaseline: 'middle',
    textAnchor: 'middle',
  },
};

const TopView = ({ place, socials, history }) => {
  const { userId } = useStoreState(state => state.auth);

  const [isOpenPlace, setIsOpenPlace] = useState(false);
  const findMobileContact =
    place.contact !== null ? place.contact.filter(item => item.type === 'phone_primary') : [];
  const primaryMobileContact = findMobileContact.length !== 0 && first(findMobileContact).value;

  useEffect(() => {
    const fetchData = async () => {
      const data = await isOpen(place);
      setIsOpenPlace(data);
    };
    fetchData();
  });

  const social = parseSocialPlace(socials, userId);

  return (
    <Container>
      <div className="columns">
        <div className="column">
          <Wrapper>
            <Heading
              className="is-capitalized"
              size="big"
              weight="semibold"
              paddingBottom={0.2}
              style={{ marginLeft: '0.3rem' }}
            >
              {capitalize(place.name)}{' '}
              <a onClick={() => history.push(`/claim-your-place/${place.place_id}`)}>
                <span>Claim this place....</span>
              </a>
            </Heading>
            <SubHeading
              size="smedium"
              weight="medium"
              color="darkGrey"
              paddingBottom={0.5}
              style={{ marginLeft: '0.3rem' }}
            >
              {getPlaceTagline(place.business_type, place.cuisine_style)} |{' '}
              <span> {isOpenPlace ? 'Open Now' : 'Closed'}</span>
            </SubHeading>
            <div className="is-flex">
              <span className="icon">
                <GoLocation size={16} colors="specialGrey" />
              </span>

              <AddressWrapper align="flex-end">
                <Text size="smaller">
                  {place.address_line_1} <br /> {place.city}, {place.state}
                </Text>
                <GetDirections latitude={place.latitude} longitude={place.longitude} />
              </AddressWrapper>
            </div>

            {primaryMobileContact && (
              <div className="is-flex">
                <span className="icon">
                  <MdPhone size={16} colors="specialGrey" /> &nbsp;
                </span>

                <Text size="smaller"> {primaryMobileContact}</Text>
              </div>
            )}
          </Wrapper>
          <div className="columns">
            <div className="column is-8">
              <IconActionButtonWrapper>
                <PlaceSocial
                  objectId={place.place_id}
                  objectType="PLACE"
                  likeActive={social.like.active}
                  bookMarkActive={social.bookmark.active}
                  beenThereActive={social.beenThere.active}
                  likeCountInt={social.like.count}
                  bookMarkCountInt={social.bookmark.count}
                  beenThereCountInt={social.beenThere.count}
                  likeSocialIdS={social.like.id}
                  bookMarkSocialIdS={social.bookmark.id}
                  beenThereSocialIdS={social.beenThere.id}
                />
              </IconActionButtonWrapper>
            </div>
          </div>
        </div>
        <div className="column is-4">
          <FlexColumns>
            <ProgressBar
              value={Math.round(80)}
              text={`${Math.round(80)}%`}
              strokeWidth={12}
              styles={styles}
            />
            <Text size="micro" color="secondaryColor" weight="bold">
              LIKE THIS PLACE
            </Text>
            <br />
            <FlexRows justify="space-around">
              <Button
                name="Spot and Share an Item"
                borderColor="primaryColor"
                textProps={{ size: 'tiny' }}
                onClick={() => history.push(`/spot-and-share-an-item?place_id=${place.place_id}`)}
              >
                <span className="icon">
                  <Icon name="itemRatingBlueSolid" />
                </span>
              </Button>
              &nbsp;&nbsp;
              <Button
                name="Write a Review"
                width={3}
                borderColor="primaryColor"
                textProps={{ size: 'small' }}
                onClick={() => history.push(`/place-review/${place.place_id}`)}
              >
                <span className="icon">
                  <IoMdStar size={30} color={colors.tertiaryColor} />
                </span>
              </Button>
            </FlexRows>
          </FlexColumns>
        </div>
      </div>
    </Container>
  );
};

export default withRouter(TopView);
