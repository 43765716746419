import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { Text, Icon } from '../../elements';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 100%;
  && {
    p {
      padding-left: 0.5rem;
    }
  }
`;

const Cart = () => {
  const { cartQuantity } = useStoreState(state => state.global);
  if (cartQuantity === 0) {
    return null;
  }
  return (
    <Link to="/checkout">
      <Container>
        <Icon name="cartSolid" size={1.5} />
        <Text color="secondaryColor" size="small" weight="bold">
          {cartQuantity}
        </Text>
      </Container>
    </Link>
  );
};

export default Cart;
