import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import uuid from 'uuid';
import swal from 'sweetalert';
import { forEach } from 'lodash';
import { useStoreState } from 'easy-peasy';
import randomColor from 'randomcolor';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';
import client from '../../utils/apolloClient';

const createImageMutation = gql`
  mutation createImage($input: ImageInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
  }
`;

const createPlace = gql`
  mutation createItem($input: PlaceInput) {
    create_place(input: $input) {
      place_id
      name
      error {
        description
      }
    }
  }
`;
const createItem = gql`
  mutation createItem($input: ItemInput) {
    create_item(input: $input) {
      item_id
      place_id
      error {
        description
      }
    }
  }
`;
const createItemReview = gql`
  mutation itemReview($input: ItemReviewInput) {
    create_item_review(input: $input) {
      item_review_id
      place_id
      item_id
      error {
        description
      }
    }
  }
`;

const createItemDictionary = gql`
  mutation createItemDictionary($input: ItemDictionaryInput) {
    create_item_dictionary(input: $input) {
      item_dictionary_id
      name
      error {
        description
      }
    }
  }
`;

// action1 -> selected place and selected item -> result: trigger create-review
// action2 -> selected place, create item, create review -> result: create-item -> create-review
// action3 -> create place, create item and create review ->
// result: create-place->create-item->create-review

const titleMessage = 'Thanks for sharing your review.';
const textMessage = 'Your review will be posted shortly.';

const draftTitleMessage = 'Your review has been saved as a draft!.';
const draftTextMessage = 'Your draft can be reviewed, edited and posted from your profile. ';

const parseImage = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        image_id: item.id,
        url: item.url,
        link: item.link,
        tags: item.tags,
        tooltip: item.tooltip,
        width: item.width,
        height: item.height,
      });
    }
  });
  return imageValues;
};

const ItemSpotAndShare = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const [images, setImages] = useState([]);
  const [item, setItem] = useState();
  const [itemReview, setItemReview] = useState();
  const [itemDictionary, setItemDictionary] = useState();
  const [actions, setActions] = useState();
  const [draft, setDraft] = useState(false);

  return (
    <Layout
      history={history}
      match={match}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading="REVIEW ITEM"
      headerLikeModal
    >
      <Analytics pageName="MyBookmarks" category="mybookmark" title="MyBookmark-List">
        <Mutation
          client={client.clientPrivate}
          mutation={createImageMutation}
          onCompleted={() => {
            swal(
              draft ? draftTitleMessage : titleMessage,
              draft ? draftTextMessage : textMessage,
              'success',
            ).then(() => {
              history.goBack();
            });
          }}
        >
          {(create_image, { loading: imageLoading }) => (
            <Mutation
              client={client.clientPrivate}
              mutation={createItemReview}
              onCompleted={() => {
                create_image({
                  variables: {
                    input: {
                      user_id: userId,
                      object_id: itemReview.item_review_id,
                      object_type: 'ITEM_REVIEW',
                      parent_id: itemReview.place_id,
                      parent_type: 'PLACE',
                      upload_type: 'USER',
                      links_to: [
                        {
                          id: item.item_id,
                          type: 'ITEM',
                        },
                      ],
                      images: parseImage(images),
                    },
                  },
                });
              }}
            >
              {(create_item_review1, { loading: itemReviewLoading1 }) => (
                <Mutation
                  client={client.clientPrivate}
                  mutation={createItem}
                  onCompleted={() => {
                    create_item_review1({ variables: { input: itemReview } });
                  }}
                >
                  {(create_item, { loading: itemLoading }) => (
                    <Mutation
                      client={client.clientPrivate}
                      mutation={createItemDictionary}
                      onCompleted={() => {
                        if (actions.itemAction === 'existed') {
                          create_item_review1({
                            variables: { input: itemReview },
                          });
                        }
                        if (actions.itemAction === 'new') {
                          create_item({ variables: { input: item } });
                        }
                      }}
                    >
                      {(create_item_dictionary, { loading: createItemDictionaryLoading }) => (
                        <Mutation
                          client={client.clientPrivate}
                          mutation={createPlace}
                          onCompleted={() => {
                            if (actions.dictionaryAction === 'existed') {
                              if (actions.itemAction === 'existed') {
                                create_item_review1({
                                  variables: { input: itemReview },
                                });
                              }
                              if (actions.itemAction === 'new') {
                                create_item({ variables: { input: item } });
                              }
                            }
                            if (actions.dictionaryAction === 'new') {
                              create_item_dictionary({
                                variables: { input: itemDictionary },
                              });
                            }
                          }}
                        >
                          {(create_place, { loading: placeLoading }) => {
                            return (
                              <Mutation
                                client={client.clientPrivate}
                                mutation={createItemReview}
                                onCompleted={() => {
                                  create_image({
                                    variables: {
                                      input: {
                                        user_id: userId,
                                        object_id: itemReview.item_review_id,
                                        object_type: 'ITEM_REVIEW',
                                        parent_id: itemReview.place_id,
                                        parent_type: 'PLACE',
                                        upload_type: 'USER',
                                        links_to: [
                                          {
                                            id: item.item_id,
                                            type: 'ITEM',
                                          },
                                        ],
                                        images: parseImage(images),
                                      },
                                    },
                                  });
                                }}
                              >
                                {(create_item_review, { loading: itemReviewLoading2 }) => {
                                  return (
                                    <>
                                      <div className="is-hidden-desktop">
                                        <MobileView
                                          loading={
                                            itemLoading ||
                                            itemReviewLoading1 ||
                                            itemReviewLoading2 ||
                                            placeLoading ||
                                            createItemDictionaryLoading ||
                                            imageLoading
                                          }
                                          onSubmit={values => {
                                            setImages(values.image);
                                            setDraft(values.isDraft);
                                            setActions({
                                              placeAction: values.placeAction,
                                              itemAction: values.itemAction,
                                              dictionaryAction: values.dictionaryAction,
                                            });
                                            const reviewInput = {
                                              user_id: userId,
                                              item_review_id: uuid(),
                                              item_id: values.item_id,
                                              place_id: values.placeData.place_id,
                                              item_name: values.item_name,
                                              rating: values.overall_rating,
                                              cuisine_style: values.cuisine_style,
                                              item_type: values.item_type,
                                              content: values.content,
                                              draft: values.isDraft,
                                            };

                                            const itemInput = {
                                              user_id: userId,
                                              item_id: values.item_id,
                                              item_dictionary_id: values.item_dictionary_id,
                                              place_id: values.placeData.place_id,
                                              name: values.item_name,
                                              cuisine_style: values.cuisine_style,
                                              item_type: values.item_type,
                                              color_code: randomColor({
                                                luminosity: 'dark',
                                              }),
                                            };
                                            const itemDictionaryInput = {
                                              user_id: userId,
                                              ...values.dictionary,
                                            };
                                            const placeInput = {
                                              user_id: userId,
                                              listing_status: 'UNLISTED',
                                              claim_status: 'UNCLAIMED',
                                              status: 'ACTIVE',
                                              item_review_place: true,
                                              ...values.placeData,
                                            };
                                            setItemReview(reviewInput);
                                            setItem(itemInput);
                                            setItemDictionary(itemDictionaryInput);
                                            if (values.placeAction === 'new') {
                                              create_place({ variables: { input: placeInput } });
                                            }
                                            if (values.placeAction === 'existed') {
                                              if (values.dictionaryAction === 'existed') {
                                                if (values.itemAction === 'existed') {
                                                  create_item_review({
                                                    variables: { input: reviewInput },
                                                  });
                                                }
                                                if (values.itemAction === 'new') {
                                                  create_item({ variables: { input: itemInput } });
                                                }
                                              }
                                              if (values.dictionaryAction === 'new') {
                                                create_item_dictionary({
                                                  variables: { input: itemDictionaryInput },
                                                });
                                              }
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="is-hidden-touch">
                                        <DesktopView
                                          loading={
                                            itemLoading ||
                                            itemReviewLoading1 ||
                                            itemReviewLoading2 ||
                                            placeLoading ||
                                            createItemDictionaryLoading ||
                                            imageLoading
                                          }
                                          onSubmit={values => {
                                            setImages(values.image);
                                            setDraft(values.isDraft);
                                            setActions({
                                              placeAction: values.placeAction,
                                              itemAction: values.itemAction,
                                              dictionaryAction: values.dictionaryAction,
                                            });
                                            const reviewInput = {
                                              user_id: userId,
                                              item_review_id: uuid(),
                                              item_id: values.item_id,
                                              place_id: values.placeData.place_id,
                                              item_name: values.item_name,
                                              rating: values.overall_rating,
                                              cuisine_style: values.cuisine_style,
                                              item_type: values.item_type,
                                              content: values.content,
                                              draft: values.isDraft,
                                            };

                                            const itemInput = {
                                              user_id: userId,
                                              item_id: values.item_id,
                                              item_dictionary_id: values.item_dictionary_id,
                                              place_id: values.placeData.place_id,
                                              name: values.item_name,
                                              cuisine_style: values.cuisine_style,
                                              item_type: values.item_type,
                                            };
                                            const itemDictionaryInput = {
                                              user_id: userId,
                                              ...values.dictionary,
                                            };
                                            const placeInput = {
                                              user_id: userId,
                                              listing_status: 'UNLISTED',
                                              claim_status: 'UNCLAIMED',
                                              status: 'ACTIVE',
                                              item_review_place: true,
                                              ...values.placeData,
                                            };
                                            setItemReview(reviewInput);
                                            setItem(itemInput);
                                            setItemDictionary(itemDictionaryInput);
                                            if (values.placeAction === 'new') {
                                              create_place({ variables: { input: placeInput } });
                                            }
                                            if (values.placeAction === 'existed') {
                                              if (values.dictionaryAction === 'existed') {
                                                if (values.itemAction === 'existed') {
                                                  create_item_review({
                                                    variables: { input: reviewInput },
                                                  });
                                                }
                                                if (values.itemAction === 'new') {
                                                  create_item({ variables: { input: itemInput } });
                                                }
                                              }
                                              if (values.dictionaryAction === 'new') {
                                                create_item_dictionary({
                                                  variables: { input: itemDictionaryInput },
                                                });
                                              }
                                            }
                                          }}
                                        />
                                      </div>
                                    </>
                                  );
                                }}
                              </Mutation>
                            );
                          }}
                        </Mutation>
                      )}
                    </Mutation>
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>
      </Analytics>
    </Layout>
  );
};

export default ItemSpotAndShare;
