import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { capitalize } from 'lodash';

import { ScrollView, Text, FlexRows } from '../../../../components/elements';

const Container = styled(FlexRows).attrs({ justify: 'flex-between' })`
  padding-top: 1rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  &&& {
    .menu-wrapper--inner {
      width: 10 !important;
    }
  }
`;
const Item = styled.div`
  padding: 0.3rem 1.5rem;
  cursor: pointer;
  p {
    color: ${({ theme: { primaryColor }, isActive }) => (isActive ? primaryColor : '#000000')};
  }
  border-bottom: 3px solid
    ${({ theme: { primaryColor }, isActive }) => (isActive ? primaryColor : 'transparent')};
`;

const Menu = (productTypes, selected) =>
  productTypes.map(item => (
    <Item
      key={item.name}
      isActive={selected === item.name}
      className="has-text-weight-bold has-text-centered"
    >
      <Text size="medium" weight="semibold">
        {item.description}
      </Text>
    </Item>
  ));

const options = [
  { name: 'Pickup', description: 'Pickup' },
  { name: 'Delivery', description: 'Delivery' },
  { name: 'Dinein', description: 'Dinein' },
];

const MenuCategoryTabs = ({ history, location }) => {
  const routeData = queryString.parse(location.search);
  const serviceType = capitalize(routeData.service_type) || 'Pickup';
  const menu = Menu(options, serviceType);

  const handleFilter = key => {
    history.push(`/order-food?service_type=${key}`);
  };
  return (
    <Container className="level">
      <div className="level-left">
        <ScrollView
          menu={menu}
          onSelect={key => handleFilter(key)}
          innerWrapperStyle={{
            transform: 'translate3d(0px, 0px, 0px)',
            transition: 'transform 0.4s ease 0s',
            width: '900px',
            textAlign: 'left',
            userSelect: 'none',
            whiteSpace: 'nowrap',
          }}
        />
      </div>
    </Container>
  );
};

export default withRouter(MenuCategoryTabs);
