import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import randomColor from 'randomcolor';
import { Text, Image } from '.';
import { firstTwoLetterPicker } from '../../utils/helpers';
import { getEncodedImage } from '../../utils/s3';

const MaskWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ randomColorCode }) => randomColorCode};
  width: ${({ maskProps }) => `${maskProps.width}px`};
  height: ${({ maskProps }) => `${maskProps.width}px`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  ${({ borderColor }) => borderColor && `border: 2px solid ${borderColor}`};
  padding: 1rem;
  margin-right: 0.2rem;
`;

const ImageWrapper = styled.div`
  &&& {
    img {
      border-radius: ${({ borderRadius }) => `${borderRadius}px`};
      width: ${({ maskProps }) => `${maskProps.width}px`};
      height: ${({ maskProps }) => `${maskProps.width}px`};
    }
  }
`;

const Avatar = ({
  name,
  color,
  image,
  maskTextProps,
  maskProps,
  borderRadius,
  isEncodedImage,
  borderColor,
}) => {
  if (image && image.length !== 0) {
    return (
      <ImageWrapper borderRadius={borderRadius} maskProps={maskProps}>
        <Image
          source={isEncodedImage ? getEncodedImage(image, maskProps.width, maskProps.width) : image}
        />
      </ImageWrapper>
    );
  }

  return (
    <MaskWrapper
      maskProps={maskProps}
      borderRadius={borderRadius}
      borderColor={borderColor}
      randomColorCode={color}
    >
      <Text {...maskTextProps}>{firstTwoLetterPicker(name)}</Text>
    </MaskWrapper>
  );
};

Avatar.defaultProps = {
  name: 'Dev Nasim',
  isEncodedImage: true,
  maskProps: { width: 55 },
  maskTextProps: { color: 'white', weight: 'bold', size: 'large', letterSpacing: 'loose' },
  borderRadius: 6,
  color: randomColor({
    luminosity: 'dark',
  }),
};

Avatar.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  maskProps: PropTypes.object,
  maskTextProps: PropTypes.object,
  borderRadius: PropTypes.number,
  isEncodedImage: PropTypes.bool,
};

export default Avatar;
