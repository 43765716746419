import React from 'react';
import styled from 'styled-components';

import { UploadImage } from '../../components/elements';
import UploadedImages from './UploadedImages';

const Container = styled.div`
  width: 100%;
`;

const Images = ({ setFieldValue, values, colWidth, iconProps, textProps }) => (
  <Container>
    <div className="columns is-multiline is-mobile">
      <UploadedImages colWidth={colWidth} setFieldValue={setFieldValue} />
      <UploadImage
        totalNumberOfUploadedImage={values.uploadedImages.length}
        colWidth={colWidth}
        s3UploadPath="place/"
        metaData={{
          place_id: values.place_id,
          user_id: '2290802a-c911-4718-a829-5edc420e0d05',
        }}
        setFieldValue={setFieldValue}
        iconProps={iconProps}
        textProps={textProps}
      />
    </div>
  </Container>
);

export default Images;
