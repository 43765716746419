import gql from 'graphql-tag';
import { forEach, groupBy, map, capitalize } from 'lodash';

import uuid from 'uuid';

import client from '../../utils/apolloClient';

export const parseOrderDatabaseInputItems = items => {
  const data = [];
  forEach(items, item => {
    let selectedVariant = null;
    if (item.variant_id) {
      selectedVariant = {
        variant_id: item.variant_id,
        variant_name: item.variant_name,
        variant_price: item.price_per_item,
      };
    }

    const groupsTemp = [];
    const groups = groupBy(item.modifier, 'modifier_group_id');
    // console.log('parseOrderDatabaseInputItems', groups);
    map(groups, item2 => {
      let selectedVariant2 = null;
      if (item2.variant_id) {
        selectedVariant2 = {
          variant_id: item2.variant_id,
          variant_name: item2.variant_name,
          variant_price: item2.price_per_item,
        };
      }

      const modifiersTemp = [];
      forEach(item2, item3 => {
        modifiersTemp.push({
          menu_item: { menu_item_id: item3.menu_item_id, name: item3.name },
          override_price: item3.price,
          override_variant: [],
          selectedVariant: selectedVariant2,
          ...item3,
        });
      });

      const group = { modifier_group_id: item2[0].modifier_group_id, modifiers: modifiersTemp };
      groupsTemp.push(group);
    });

    const newItem = {
      id: uuid(),
      item_link_id: item.item_link_id,
      menu_item_id: item.menu_item_id,
      quantity: item.quantity,
      name: item.name,
      price_per_item: item.price_per_item,
      tax: item.tax,
      net_price_per_item: item.net_price_per_item,
      price: item.price,
      selectedVariant,
      special_request: item.special_request,
      groups: groupsTemp,
    };
    data.push(newItem);
  });
  return data;
};

const searchCartQuery = gql`
  query searchCart($input: SearchInput) {
    search_menu_orders(input: $input) {
      menu_order_listing {
        menu_order_id
        place_id
        service_type
        service_type_setting_id
        menu_id
        place {
          place_id
          name
          address_line_1
          city
          state
          post_code
        }
        address {
          address_id
          type
          is_default
          contact_name
          contact_phone
          address_line_1
          address_line_2
          city
          state
          country
          post_code
          latitude
          longitude
          status
        }
        place_menu_deal {
          deal_id
          name
          promo_code
          percentage
          amount
          delivery_fee
        }
        billing {
          sub_total

          delivery_fee

          order_total
          order_amount
          tax {
            type
            value
          }
        }
        line_item {
          name
          item_link_id
          menu_item_id
          prompt_id
          variant_id
          variant_name
          name
          quantity
          price_per_item
          net_price_per_item
          tax_per_item
          price
          tax
          sold_out
          modifier {
            name
            modifier_group_id
            menu_item_id
            prompt_id
            variant_id
            variant_name
            name
            quantity
            price_per_item
            net_price_per_item
            tax_per_item
            price
            tax
          }
          menu_item_rating
          special_request
          user_id
          caption
          private
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getCart = ({ size = 100, filter }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchCartQuery,
        variables: {
          input: {
            size,
            filter,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          data.search_menu_orders.menu_order_listing &&
          data.search_menu_orders.menu_order_listing.length !== 0
        ) {
          resolve(data.search_menu_orders.menu_order_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchFoodOrdersQuery = gql`
  query searchFoodOrders($placeId: String, $serviceType: [String]) {
    search_food_orders(
      input: { filter: { place_filter: { place_id: $placeId, service_type: $serviceType } } }
    ) {
      place_and_service_type_listing {
        place {
          place_id
          name
          service_type {
            name
          }
          business_type {
            description
          }
          cuisine_style {
            description
          }
          contact {
            type
            value
            display
          }
          address_line_1
          city
          state
          post_code
        }
        service_type_setting {
          service_type_setting_id
          service_type
          added_estimate
          payment_method
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getFoodOrder = ({ placeId, serviceType }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchFoodOrdersQuery,
        variables: {
          placeId,
          serviceType: capitalize(serviceType),
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_food_orders &&
          data.search_food_orders.place_and_service_type_listing &&
          data.search_food_orders.place_and_service_type_listing.length !== 0
        ) {
          resolve(data.search_food_orders.place_and_service_type_listing[0]);
        } else {
          resolve(null);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
