import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../theme/styles/colors';
import Details from './Details';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 650px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
      position: relative;
      .cross {
        position: fixed;
        z-index: 100;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 22px;
        margin: 0.8rem;
      }
    }
    .modal-card-head {
      padding: 1rem 1rem;
      padding-bottom: 0.6rem;
      background-color: rgba(255, 255, 255, 1);
      border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
    }
    .modal-card-body {
      padding: 0rem;
    }
    a {
      color: ${colors.primaryColor};
    }
    .select:not(.is-multiple):not(.is-loading)::after {
      border-color: ${({ theme: { colors: themeColor } }) => themeColor.coolGrey};
    }
    .hide {
      display: none;
    }
  }
`;

const ModifiersModal = ({ isOpen, menuItem, isActiveModal, onClose }) => {
  return (
    <Container className={`modal ${isActiveModal && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <Details isOpen={isOpen} menuItem={menuItem} onClose={onClose} />
      </div>
    </Container>
  );
};

export default ModifiersModal;
