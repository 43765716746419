import React from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { MdSearch } from 'react-icons/md';
import { Text, Flex, Button, Input, Avatar, Pager } from '../../components/elements';
import LocationSearchInput from './LocationSearch';
import { getPlaceTagline } from '../../utils/helpers';

const Container = styled(Pager)`
  padding-top: 2rem;
`;
const Wrapper = styled.div`
  padding: 2rem 1rem;
`;

const ItemWrapper = styled.div`
  padding: 1rem 0rem;
`;

const Item = styled(ItemWrapper)`
  &&& {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  }
`;

const TextWrapper = styled.div`
  padding-left: 1rem;
`;

const Heading = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;

const DesktopView = ({
  loading,
  places,
  placeQueryTag,
  setPlaceQueryTag,
  onSuggestionsFetchRequested,
  history,
  recentViews,
  setLocation,
  isError,
}) => (
  <Container>
    <Text size="large" weight="semibold" paddingBottom={0.5}>
      Write a Review
    </Text>
    <Text size="tiny" color="darkGrey">
      Did you have a good experience, enjoyed delicious meal or an alluring drink that you would
      like to share? Review your favorite place, restaurant, pub, nightclub, ice cream parlour,
      neighborhood cafe and enticing share to treat your experience and off course earn badges and
      points.
    </Text>
    <Wrapper>
      <div className="columns is-centered">
        <div className="column is-10">
          <form
            onSubmit={event => {
              event.preventDefault();
              onSuggestionsFetchRequested();
            }}
          >
            <ItemWrapper>
              <div className="columns">
                <div className="column is-5">
                  <div className="control has-icons-left">
                    <Input
                      placeholder="Search by place name"
                      id="WriteReview_SearchPlace_SuburbName"
                      value={placeQueryTag}
                      onChange={event => setPlaceQueryTag(event.target.value)}
                    />
                    <span className="icon is-small is-left">
                      <MdSearch size={20} />
                    </span>
                  </div>
                  {isError && (
                    <Text size="tiny" color="danger">
                      {isError}
                    </Text>
                  )}
                </div>
                <div className="column is-4">
                  <LocationSearchInput
                    onHandleLocationChange={value => setLocation(value.location)}
                  />
                </div>
                <div className="column">
                  <Button
                    className={`${loading && 'is-loading'}`}
                    name="Search"
                    width={4}
                    type="submit"
                    height={0.9}
                    backgroundColor="primaryColor"
                    textProps={{
                      color: loading ? 'primaryColor' : 'white',
                      size: 'small',
                      weight: 'semibold',
                    }}
                  />
                </div>
              </div>
            </ItemWrapper>
          </form>

          {places.map(item => (
            <Item key={item.place_id}>
              <div className="columns">
                <div className="column is-5">
                  <div>
                    <Flex justify="flex-start" align="flex-start">
                      <Avatar
                        name={item.name}
                        maskProps={{ width: 50, height: 50 }}
                        image={item.default_image_url}
                      />
                      <TextWrapper>
                        <Link to={`/place-review/${item.place_id}`}>
                          <Text className="is-capitalized" size="medium">
                            {capitalize(item.name)}
                          </Text>
                        </Link>

                        <Text size="small" color="darkGrey">
                          {item.tagline}
                        </Text>
                        {getPlaceTagline(item.business_type, item.cuisine_style)}
                      </TextWrapper>
                    </Flex>
                  </div>
                </div>
                <div className="column is-4">
                  <Text size="small" color="darkGrey">
                    {item.address_line_1}, <br />
                    {item.city}, {item.state} {item.post_code}
                  </Text>
                </div>
                <div className="column">
                  <Button
                    name="Write a Review"
                    type="button"
                    width={2.3}
                    height={0.9}
                    backgroundColor="primaryColor"
                    textProps={{ color: 'white', size: 'small', weight: 'semibold' }}
                    onClick={() => history.push(`/place-review/${item.place_id}`)}
                  />
                </div>
              </div>
            </Item>
          ))}
          {places.length === 0 && (
            <>
              <br />

              {recentViews.length !== 0 && (
                <Heading>
                  <Text size="large" weight="semibold">
                    Recently Viewed
                  </Text>
                </Heading>
              )}

              {recentViews.map(item => (
                <Item key={item.place_id}>
                  <div className="columns">
                    <div className="column is-5">
                      <div>
                        <Flex justify="flex-start" align="flex-start">
                          <Avatar
                            name={item.name}
                            maskProps={{ width: 50, height: 50 }}
                            image={item.default_image_url}
                          />
                          <TextWrapper>
                            <Link to={`/place-review/${item.place_id}`}>
                              <Text className="is-capitalized" size="medium">
                                {capitalize(item.name)}
                              </Text>
                            </Link>
                            <Text size="tiny" color="darkGrey">
                              {item.tagline}
                            </Text>
                            {getPlaceTagline(item.business_type, item.cuisine_style)}
                          </TextWrapper>
                        </Flex>
                      </div>
                    </div>
                    <div className="column is-4">
                      <Text size="tiny" color="darkGrey">
                        {item.address_line_1}, <br />
                        {item.city}, {item.state}
                      </Text>
                    </div>
                    <div className="column">
                      <Button
                        name="Write a Review"
                        width={2.3}
                        type="button"
                        height={0.9}
                        className="is-small"
                        backgroundColor="primaryColor"
                        textProps={{ color: 'white', size: 'small', weight: 'semibold' }}
                        onClick={() => history.push(`/place-review/${item.place_id}`)}
                      />
                    </div>
                  </div>
                </Item>
              ))}
            </>
          )}
        </div>
      </div>
    </Wrapper>
  </Container>
);

export default withRouter(DesktopView);
