import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SelectInput from './Select';
import Text from './Text';
import colors from '../../theme/styles/colors';

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ borderBottomColor }) => (borderBottomColor ? colors[borderBottomColor] : 'transparent')};
`;

const SelectField = ({ leftIcon, borderBottomColor, rightIcon, label, labelTextProps, ...props }) => (
  <Wrapper className="field" borderBottomColor={borderBottomColor}>
    {label && (
      <label className="label">
        <Text {...labelTextProps}>{label}</Text>
      </label>
    )}
    <div className={`control ${leftIcon && 'has-icons-left'} ${rightIcon && 'has-icons-right'} `}>
      <SelectInput {...props} />
      {leftIcon && (
        <span className="icon is-left">
          <i className={leftIcon} />
        </span>
      )}
      {rightIcon && (
        <span className="icon is-right">
          <i className={rightIcon} />
        </span>
      )}
    </div>
  </Wrapper>
);

SelectField.defaultProps = {
  leftIcon: '',
  rightIcon: '',
  label: '',
  labelTextProps: {},
  borderBottomColor: '',
};

SelectField.propTypes = {
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  label: PropTypes.string,
  labelTextProps: PropTypes.object,
  borderBottomColor: PropTypes.string,
};

export default SelectField;
