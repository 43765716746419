import React from 'react';
import styled from 'styled-components';

import { Text, SecondaryButton, FlexRows, Icon } from '../../../../../components/elements';

const Container = styled.div`
  padding: 1rem;
`;

const Heading = styled(Text)`
  padding: 1rem 0rem;
`;

const Item = styled(FlexRows)`
  padding: 0.5rem 0rem;
`;

const Details = styled.div`
  padding-left: 1rem;
`;

const MyPayment = () => {
  const [activeItem, setActiveItem] = React.useState(false);
  return (
    <Container>
      <Heading size="large" weight="bold" letterSpacing="loose">
        My Payments
      </Heading>
      <Item justify="space-between">
        <FlexRows align="flex-start">
          <a onClick={() => setActiveItem(!activeItem)}>
            <Icon
              iconName={activeItem ? 'far fa-dot-circle' : 'far fa-circle'}
              size="large"
              color="primaryColor"
            />
          </a>
          <Details>
            <Text size="medium" weight="bold">
              Visa
            </Text>
            <Text size="small">Details</Text>
          </Details>
        </FlexRows>
        <div>
          <a>
            <Icon iconName="far fa-trash-alt" size="large" color="primaryColor" paddingBottom={1} />
          </a>
          <a>
            <Icon iconName="fas fa-pencil-alt" size="large" color="primaryColor" />
          </a>
        </div>
      </Item>
      <Item justify="space-between">
        <FlexRows align="flex-start">
          <a onClick={() => setActiveItem(!activeItem)}>
            <Icon
              iconName={activeItem ? 'far fa-dot-circle' : 'far fa-circle'}
              size="large"
              color="primaryColor"
            />
          </a>
          <Details>
            <Text size="medium" weight="bold">
              Visa
            </Text>
            <Text size="small">Details</Text>
          </Details>
        </FlexRows>
        <div>
          <a>
            <Icon iconName="far fa-trash-alt" size="large" color="primaryColor" paddingBottom={1} />
          </a>
          <a>
            <Icon iconName="fas fa-pencil-alt" size="large" color="primaryColor" />
          </a>
        </div>
      </Item>
      <br />
      <SecondaryButton name="Add Payment" width={5} height={1.2} />
    </Container>
  );
};

export default MyPayment;
