import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { uniqBy } from 'lodash';
import moment from 'moment';

import { FlexRows, Text, FlexColumns } from '../../../../components/elements';

const Container = styled.div`
  margin-top: 1rem;
`;
const Tabs = styled(FlexRows).attrs({ justify: 'space-between' })`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;
const Item = styled.a`
  padding: 0.5rem;
  border-bottom: 2px solid
    ${({ theme: { colors }, active }) => (active ? colors.primaryColor : 'transparent')};
`;

const Type = styled(FlexColumns).attrs({ align: 'flex-start' })`
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const OpeningHours = ({ businessHours, isOpen }) => {
  const hours = uniqBy(businessHours, 'day');
  const [currentHour, setCurrentHour] = useState();

  useEffect(() => {
    if (businessHours && businessHours !== 0) {
      const day = moment().day();
      const time_range_for_a_day =
        businessHours && businessHours.length !== 0 ? businessHours[day - 1] : { option: [] };
      setCurrentHour(time_range_for_a_day);
    }
  }, [businessHours]);

  return (
    <Container>
      <FlexRows justify="flex-start">
        <Text size="medium" weight="semibold">
          Opening Hours:{' '}
        </Text>
        &nbsp;
        <Text color="primaryColor" size="tiny">
          {isOpen ? 'Open Now' : 'Closed'}
        </Text>
      </FlexRows>
      <Tabs>
        {hours.map(businessHour => (
          <Item
            active={currentHour && businessHour.day === currentHour.day}
            onClick={() => {
              setCurrentHour(businessHour);
            }}
            key={businessHour.day}
          >
            <Text className="is-uppercase" size="tiny" weight="semibold">
              {businessHour.day.substring(0, 2)}
            </Text>
          </Item>
        ))}
      </Tabs>
      {currentHour &&
        currentHour.option.length !== 0 &&
        currentHour.option.map(opt => {
          if (opt.type === 'Closed') {
            return null;
          }
          return (
            <Type key={opt.type}>
              <Text size="tiny">{opt.type}</Text>
              {opt.type !== 'Open 24 hours' && (
                <div className="hours-right">
                  <Text size="micro">
                    {opt.start} to {opt.end}
                  </Text>
                </div>
              )}
            </Type>
          );
        })}
    </Container>
  );
};

export default OpeningHours;
