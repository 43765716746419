import React from 'react';

import EventInfo from './components/EventInfo';
import Deals from './components/Deals';

const MobileView = ({ event, totalNumberOfTicket }) => {
  return (
    <>
      <EventInfo event={event} totalNumberOfTicket={totalNumberOfTicket} />
      <Deals event={event} totalNumberOfTicket={totalNumberOfTicket} />

      {/* <ChooseDateAndTime event={event} occurrence={occurrence} /> */}
    </>
  );
};

export default MobileView;
