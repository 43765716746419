import React from 'react';
import styled from 'styled-components';
import GetImages from '../../../../../components/GetImages';

import { Text } from '../../../../../components/elements';
// import AvatarWithText from '../../../../../components/loader/AvatarWithText';

const Container = styled.article`
  padding-bottom: 1rem;
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const ReviewedPlaceHeader = ({ placeId, place, children }) => {
  return (
    <Container className="media">
      <figure className="media-left">
        <GetImages
          name={place.name}
          maskProps={{ width: 64, height: 64 }}
          variables={{
            object_type: ['PLACE'],
            object_id: placeId,
            links_to_id: placeId,
            links_to_type: ['PLACE'],
          }}
        />
      </figure>
      <div className="media-content">
        <div className="content">
          <div>
            <Text className="is-capitalized" size="medium" weight="semibold">
              {place.name}
            </Text>
            <Text size="tiny" color="darkColor">
              {place.address_line_1}, {place.city}, {place.state}, {place.post_code}
            </Text>
          </div>
        </div>
      </div>
      <div className="media-right">{children}</div>
    </Container>
  );
};

ReviewedPlaceHeader.defaultProps = {};

ReviewedPlaceHeader.propTypes = {};

export default ReviewedPlaceHeader;
