import React from 'react';
import styled from 'styled-components';
import ShowMore from 'react-show-more';

import Text from './Text';

const Container = styled.div`
  div {
    white-space: pre-wrap;
  }
`;
const Span = styled.a`
  color: ${({ theme: { colors } }) => colors.primaryColor};
  :hover {
    color: ${({ theme: { colors } }) => colors.primaryColor};
  }
`;

const ShowMoreText = ({ content, lines, ...props }) => {
  return (
    <Container>
      <Text {...props}>
        <ShowMore
          lines={lines || 4}
          more={<Span> Show More </Span>}
          less={<Span> Show Less </Span>}
        >
          {content}
        </ShowMore>
      </Text>
    </Container>
  );
};

export default ShowMoreText;
