import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MdChevronRight } from 'react-icons/md';
import { FlexRows, Text, Icon } from '../../../../components/elements';

const items = [
  { name: 'my reviews', icon: 'reviewAPlace', path: '/profile/my-reviews' },
  { name: 'my activity', icon: 'activityFeedBlue', path: '/profile/my-activity' },
  { name: 'my friends', icon: 'following', path: '/profile/my-friends' },
  { name: 'my badges', icon: 'myBadgesBlue', path: '/profile/my-badges' },
  {
    name: 'payment details',
    icon: 'facilitiesBlue',
    path: '/profile/payment-details',
  },
  {
    name: 'address details',
    icon: 'findVenuesBlue',
    path: '/profile/address-details',
  },
];
const Container = styled.div`
  margin-top: 1rem;
`;

const Item = styled(FlexRows).attrs({ justify: 'space-between' })`
  background: ${({ theme: { colors } }) => colors.white};
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;
const IconWrapper = styled.div`
  width: 10%;
`;

const TextWrapper = styled(FlexRows).attrs({ justify: 'flex-start' })`
  width: 60%;
`;

const DetailsItemTab = () => (
  <Container>
    {items.map(item => (
      <Link key={item.name} to={item.path}>
        <Item>
          <TextWrapper>
            <Icon name={item.icon} size={1.5} />
            &nbsp;&nbsp;
            <Text className="is-capitalized" align="left">
              {item.name}
            </Text>
          </TextWrapper>

          <IconWrapper>
            <Text align="center" color="specialGrey">
              <MdChevronRight size={25} />
            </Text>
          </IconWrapper>
        </Item>
      </Link>
    ))}
  </Container>
);

export default DetailsItemTab;
