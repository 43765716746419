import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { first, sortBy, omit } from 'lodash';
import queryString from 'query-string';
import uuidv4 from 'uuid/v4';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { toast } from 'react-toastify';
import swal from 'sweetalert';
import {
  getMenus,
  parseOrderDatabaseInputItems,
  parseOrderDatabaseInputItemsWhileSubmissionAgain,
  getCart,
  checkCurrentOrderOrNewOrder,
  deleteCart,
  getUserAddress,
} from './helpers';
import { OrderFragments } from '../../utils/fragments';
// import client from '../../utils/apolloClient';
// import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';
import ErrorMessage from '../../components/global/ErrorMessage';
import { ParentLoader } from './Loader';
import { Icon, Text } from '../../components/elements';

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const searchFoodOrdersQuery = gql`
  query searchFoodOrders($placeId: [String], $serviceType: [String]) {
    search_food_orders(
      input: { filter: { place_filter: { place_id: $placeId, service_type: $serviceType } } }
    ) {
      place_and_service_type_listing {
        place {
          place_id
          name
          service_type {
            name
          }
          business_type {
            description
          }
          cuisine_style {
            description
          }
          contact {
            type
            value
            display
          }
          address_line_1
          city
          state
          post_code
          latitude
          longitude
        }
        service_type_setting {
          service_type_setting_id
          schedule_order_when_closed
          schedule_order
          preparation_estimate
          display_attendee_options
          minimum_no_of_attendees
          maximum_no_of_attendees
          schedule_order_duration
          service_type
          minimum_order_amount
          added_estimate
          cut_off_time
          payment_method
          image_url
          is_custom_service
        }
      }
    }
  }
`;

const createOrderMutation = gql`
  mutation createOrder($input: MenuOrderInput) {
    create_menu_order(input: $input) {
      ...OrderFragments
      error {
        description
      }
    }
  }
  ${OrderFragments}
`;

const updateOrderMutation = gql`
  mutation updateOrder($input: MenuOrderInput) {
    update_menu_order(input: $input) {
      ...OrderFragments
      error {
        description
      }
    }
  }
  ${OrderFragments}
`;

const View = ({ place, service_type_setting, location }) => {
  const routeData = queryString.parse(location.search);
  const currentLocation = useStoreState(state => state.global.usedLocation);
  const { isLoggedIn, userId } = useStoreState(state => state.auth);
  const { anonymousUserIdForCart } = useStoreState(state => state.global);
  const { setAnonymousUserIdForCart } = useStoreActions(state => state.global);
  const routeAddress = routeData.deliveryAddress ? JSON.parse(routeData.deliveryAddress) : null;
  const [deliveryAddress, setDeliveryAddress] = useState(routeAddress);
  const [userAddress, setUserAddress] = useState([]);

  // console.log('routeData.deliveryAddress', JSON.parse(routeData.deliveryAddress));

  const [menus, setMenus] = useState([]);
  const [menuLoading, setMenuLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [order, setOrder] = useState({ line_item: [] });
  const [closeModalCallBackFunction, setCloseModalCallBackFunction] = useState();

  const fetchCart = useCallback(
    async cartId => {
      setOrder({ line_item: [] });
      setCartLoading(true);
      const menu_order_filter = { status: 'DRAFT' };
      Object.assign(
        menu_order_filter,
        isLoggedIn && { user_id: userId },
        cartId && { menu_order_id: cartId },
      );
      try {
        const list = await getCart({
          filter: { menu_order_filter },
        });
        if (list.length !== 0) {
          setOrder({
            ...list[0],
            line_item: list[0].line_item.map((item, index) => ({
              ...item,
              id: uuidv4(),
              displayOrder: index,
            })),
          });
        }

        setCartLoading(false);
      } catch (err) {
        console.error(err);
        setCartLoading(false);
      }
    },
    [isLoggedIn, userId],
  );

  const fetchMenus = useCallback(async () => {
    setMenuLoading(true);
    try {
      const list = await getMenus({
        placeId: place.place_id,
        serviceType: service_type_setting.service_type,
      });
      setMenus(list);
      setMenuLoading(false);
    } catch (err) {
      setMenuLoading(false);
    }
  }, [place.place_id, service_type_setting.service_type]);

  const fetchUserAddress = useCallback(async () => {
    try {
      const list = await getUserAddress({
        userId,
      });
      if (list.length !== 0) {
        setUserAddress(list);
      }
    } catch (err) {
      console.error(err);
    }
  }, [userId]);

  useEffect(() => {
    fetchMenus();
    if (isLoggedIn) {
      fetchUserAddress();
      fetchCart();
    } else if (anonymousUserIdForCart) {
      fetchCart(anonymousUserIdForCart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeliveryAddress = address => {
    setDeliveryAddress(address);
  };

  const updateUserAddress = address => {
    setUserAddress(userAddress.concat(address));
  };

  const state = {
    routeData,
    deliveryMode: routeData.mode || 'asap',
    date: routeData.date ? routeData.date : '',
    time: routeData.time ? routeData.time : '',
    deliveryAddress: routeAddress,
    userAddress,
    userId,
    serviceType: service_type_setting && service_type_setting.service_type,
  };

  const func = {
    handleDeliveryAddress,
    updateUserAddress,
  };

  const lineItem = parseOrderDatabaseInputItems(order.line_item);

  return (
    <Mutation
      mutation={updateOrderMutation}
      onCompleted={({ update_menu_order }) => {
        if (closeModalCallBackFunction) {
          closeModalCallBackFunction.func();
        }
        if (update_menu_order.error) {
          update_menu_order.error.map(item => toast.error(item.description));
        } else {
          setOrder({
            ...update_menu_order,
            line_item: update_menu_order.line_item.map((item, index) => ({
              ...item,
              id: uuidv4(),
              displayOrder: index,
            })),
            place,
          });
        }
      }}
    >
      {(update_menu_order, { loading }) => (
        <Mutation
          mutation={createOrderMutation}
          onCompleted={({ create_menu_order }) => {
            closeModalCallBackFunction.func();
            if (create_menu_order.error) {
              create_menu_order.error.map(item => toast.error(item.description));
            } else {
              setOrder({
                ...create_menu_order,
                line_item: create_menu_order.line_item.map((item, index) => ({
                  ...item,
                  id: uuidv4(),
                  displayOrder: index,
                })),
                place,
              });
            }
          }}
        >
          {(create_menu_order, { loading: loading2 }) => (
            <Analytics pageName="Places" category="place" title="Place- Listing">
              <div className="is-hidden-desktop">
                {/* <MobileView loading={loading} data={data} /> */}
              </div>
              <div className="is-hidden-touch">
                <DesktopView
                  orderDataFromDataBase={{ ...order, userId }}
                  routeData={routeData}
                  loading={loading || loading2}
                  place={place}
                  serviceTypeSetting={service_type_setting}
                  order={{ ...order, line_item: lineItem }}
                  menuId={routeData.menuId}
                  menus={menus}
                  menuLoading={menuLoading}
                  addToCartLoading={loading || loading2}
                  cartLoading={cartLoading}
                  func={func}
                  state={state}
                  emptyCart={() => {
                    swal({
                      title: 'Empty Cart?',
                      text: `Remove all items in the cart?`,
                      buttons: ['Cancel', 'Yes'],
                    }).then(async willDelete => {
                      if (willDelete) {
                        try {
                          await deleteCart({
                            input: {
                              user_id: isLoggedIn ? userId : null,
                              menu_order_id: order.menu_order_id,
                            },
                          });
                          setOrder({ line_item: [] });
                          // if (!isLoggedIn) {
                          //   setAnonymousUserIdForCart(null);
                          // }
                        } catch (err) {
                          console.log(err);
                        }
                      }
                    });
                  }}
                  onUpdateUserWithOrder={() => {
                    update_menu_order({
                      variables: {
                        input: {
                          user_id: isLoggedIn ? userId : null,
                          menu_order_id: order.menu_order_id,
                        },
                      },
                    });
                  }}
                  onUpdateDelivery={async (address, serviceType) => {
                    const finalOne = await parseOrderDatabaseInputItemsWhileSubmissionAgain(
                      order.line_item,
                    );
                    const updateInput = {
                      user_id: isLoggedIn ? userId : null,
                      menu_order_id: order.menu_order_id ? order.menu_order_id : uuidv4(),
                      place_id: order.place_id ? order.place_id : place.place_id,
                      // service_type: serviceType,
                      // service_type_setting_id: service_type_setting.service_type_setting_id,
                      // menu_id: menuId,
                      address,
                      line_item: finalOne,
                    };
                    if (updateInput.line_item.length !== 0) {
                      update_menu_order({
                        variables: {
                          input: updateInput,
                        },
                      });
                    }
                  }}
                  updateQuantity={async (itemId, quantity, displayOrder) => {
                    const currentItem = order.line_item.filter(item => item.id === itemId);
                    const restItem = order.line_item.filter(item => item.id !== itemId);
                    const allItems =
                      quantity === 0
                        ? restItem
                        : restItem.concat({ ...currentItem[0], quantity, displayOrder });
                    const sortItems = sortBy(allItems, 'displayOrder');
                    const finalOne = await parseOrderDatabaseInputItemsWhileSubmissionAgain(
                      sortItems,
                    );
                    const updateInput = {
                      user_id: isLoggedIn ? userId : null,
                      menu_order_id: order.menu_order_id ? order.menu_order_id : uuidv4(),
                      place_id: order.place_id ? order.place_id : place.place_id,
                      // service_type: service_type_setting.service_type,
                      // service_type_setting_id: service_type_setting.service_type_setting_id,
                      // menu_id: menuId,
                      address: {
                        address_id: order.address.address_id,
                        type: order.address.address_id,
                        contact_name: order.address.contact_name,
                        contact_phone: order.address.contact_phone,
                        address_line_1: order.address.address_line_1,
                        address_line_2: order.address.address_line_2,
                        city: order.address.city,
                        state: order.address.state,
                        country: order.address.country,
                        post_code: order.address.post_code,
                        latitude: order.address.latitude,
                        longitude: order.address.longitude,
                        status: order.address.status,
                      },
                      line_item: finalOne,
                    };

                    if (updateInput.line_item.length === 0) {
                      try {
                        const data = await deleteCart({
                          input: {
                            user_id: isLoggedIn ? userId : null,
                            menu_order_id: updateInput.menu_order_id,
                          },
                        });
                        if (data) {
                          setOrder({ line_item: [] });
                        }
                      } catch (err) {
                        console.log(err);
                      }
                    } else {
                      update_menu_order({
                        variables: {
                          input: updateInput,
                        },
                      });
                    }
                  }}
                  onSubmit={(inputs, menuId, callback) => {
                    setCloseModalCallBackFunction(callback);

                    const orderLineItem = order.line_item.filter(edge => edge.id !== inputs.id);
                    const finalOne = parseOrderDatabaseInputItemsWhileSubmissionAgain(
                      orderLineItem,
                    );
                    // const finalListWithCurrentInput= itemForEdit?finalOne.filter(edge=>edge.ite)

                    const input = {
                      user_id: isLoggedIn ? userId : null,
                      menu_order_id: uuidv4(),
                      place_id: place.place_id,
                      // service_type: service_type_setting.service_type,
                      service_type_setting_id: service_type_setting.service_type_setting_id,
                      menu_id: menuId,
                      address:
                        deliveryAddress !== null
                          ? deliveryAddress
                          : {
                              city: currentLocation.city,
                              state: currentLocation.state,
                              post_code: currentLocation.post_code,
                              country: currentLocation.country,
                              latitude: currentLocation.latitude,
                              longitude: currentLocation.longitude,
                            },
                      line_item: omit(inputs, 'id'),
                    };

                    const updateInput = {
                      user_id: isLoggedIn ? userId : null,
                      menu_order_id: order.menu_order_id ? order.menu_order_id : uuidv4(),
                      place_id: order.place_id ? order.place_id : place.place_id,
                      // service_type: service_type_setting.service_type,
                      // service_type_setting_id: service_type_setting.service_type_setting_id,
                      // menu_id: menuId,
                      address:
                        deliveryAddress !== null
                          ? deliveryAddress
                          : {
                              city: currentLocation.city,
                              state: currentLocation.state,
                              post_code: currentLocation.post_code,
                              country: currentLocation.country,
                              latitude: currentLocation.latitude,
                              longitude: currentLocation.longitude,
                            },
                      line_item: finalOne.concat(omit(inputs, 'id')),
                    };
                    if (order.line_item.length !== 0) {
                      const isNewCart = checkCurrentOrderOrNewOrder(
                        {
                          place_id: place.place_id,
                          service_type_setting_id: service_type_setting.service_type_setting_id,
                          menu_id: order.menu_id,
                        },
                        {
                          place_id: order.place_id,
                          service_type_setting_id: order.service_type_setting_id,
                          menu_id: order.menu_id,
                        },
                      );
                      let message = '';
                      if (place.place_id !== order.place_id) {
                        // eslint-disable-next-line max-len
                        message = `Your order contains food from other restaurant. Create a new order to add food from ${place.name}.`;
                      } else if (
                        service_type_setting.service_type_setting_id !==
                        order.service_type_setting_id
                      ) {
                        // eslint-disable-next-line max-len
                        message = `You order contains items from another Dining Option.  Are you sure you want to to clear this cart and start a new order?`;
                      } else if (place.menuId !== order.menu_id) {
                        // eslint-disable-next-line max-len
                        message = `You order contains items from another Menu. Are you sure you want to to clear this cart and start a new order?`;
                      }
                      if (isNewCart) {
                        swal({
                          title: 'Create a new order?',
                          // eslint-disable-next-line max-len
                          text: message,
                          buttons: ['Cancel', 'New Order'],
                        }).then(async willDelete => {
                          if (willDelete) {
                            try {
                              const data = await deleteCart({
                                input: {
                                  user_id: isLoggedIn ? userId : null,
                                  menu_order_id: order.menu_order_id,
                                },
                              });
                              if (data) {
                                setOrder({ line_item: [] });
                                create_menu_order({
                                  variables: {
                                    input,
                                  },
                                });
                                if (!isLoggedIn) {
                                  setAnonymousUserIdForCart(input.menu_order_id);
                                }
                              }
                            } catch (err) {
                              console.log(err);
                            }
                          }
                        });
                      } else {
                        update_menu_order({
                          variables: {
                            input: updateInput,
                          },
                        });
                      }
                    } else {
                      create_menu_order({
                        variables: {
                          input,
                        },
                      });
                      if (!isLoggedIn) {
                        setAnonymousUserIdForCart(input.menu_order_id);
                      }
                    }
                  }}
                />
              </div>
            </Analytics>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

const Menu = ({ match, location }) => {
  const { placeId, serviceType } = match.params;
  return (
    <Layout>
      <Query
        query={searchFoodOrdersQuery}
        variables={{ serviceType, placeId }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (error) {
            return <ErrorMessage />;
          }

          if (loading) {
            return <ParentLoader />;
          }

          const { place, service_type_setting } = first(
            data.search_food_orders.place_and_service_type_listing,
          );

          if (!service_type_setting) {
            return (
              <Error>
                <Icon name="error" size={40} />
                <Text color="primaryColor" size="large" weight="semibold">
                  Something went wrong
                </Text>
              </Error>
            );
          }

          return (
            <View place={place} service_type_setting={service_type_setting} location={location} />
          );
        }}
      </Query>
    </Layout>
  );
};

export default Menu;
