import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import randomColor from 'randomcolor';
import { first } from 'lodash';
import { Text, Image } from './elements';
import { firstTwoLetterPicker } from '../utils/helpers';
import { getApiImages } from '../utils/reusableQuery';
import { getEncodedImage } from '../utils/s3';

const MaskWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ randomColorCode }) => randomColorCode};
  width: ${({ maskProps }) => `${maskProps.width}px`};
  height: ${({ maskProps }) => `${maskProps.width}px`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  padding: 1rem;
  margin-right: 0.2rem;
`;

const ImageWrapper = styled.div`
  &&& {
    img {
      border-radius: ${({ borderRadius }) => `${borderRadius}px`};
      width: ${({ maskProps }) => `${maskProps.width}px`};
      height: ${({ maskProps }) => `${maskProps.width}px`};
    }
  }
`;

const GetImages = ({
  variables,
  name,
  maskProps,
  maskTextProps,
  borderRadius,
  randomColorCode,
  userImage,
}) => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    const get = async () => {
      const data = await getApiImages({
        ...variables,
      });
      setImages(data);
    };
    if (variables && !userImage) {
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setImages, variables]);

  if (images === null && !userImage) {
    return (
      <MaskWrapper
        maskProps={maskProps}
        borderRadius={borderRadius}
        randomColorCode={randomColorCode}
      >
        <Text {...maskTextProps}>{firstTwoLetterPicker(name)}</Text>
      </MaskWrapper>
    );
  }

  if (images.length === 0 && !userImage) {
    return (
      <MaskWrapper
        maskProps={maskProps}
        borderRadius={borderRadius}
        randomColorCode={randomColorCode}
      >
        <Text {...maskTextProps}>{firstTwoLetterPicker(name)}</Text>
      </MaskWrapper>
    );
  }

  if (userImage) {
    return (
      <ImageWrapper borderRadius={borderRadius} maskProps={maskProps}>
        <Image source={userImage} />
      </ImageWrapper>
    );
  }

  const image = first(images);

  return (
    <ImageWrapper borderRadius={borderRadius} maskProps={maskProps}>
      <Image source={getEncodedImage(image.url, maskProps.width, maskProps.width)} />
    </ImageWrapper>
  );
};

GetImages.defaultProps = {
  name: 'Nasim Akhtar',
  maskProps: { width: 55 },
  maskTextProps: { color: 'white', weight: 'bold', size: 'large', letterSpacing: 'loose' },
  borderRadius: 6,
  randomColorCode: randomColor({
    luminosity: 'dark',
  }),
};

GetImages.propTypes = {
  name: PropTypes.string,
  randomColorCode: PropTypes.string,
  maskProps: PropTypes.object,
  maskTextProps: PropTypes.object,
  borderRadius: PropTypes.number,
};

export default GetImages;
