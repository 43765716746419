import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { MdClear } from 'react-icons/md';

import { FlexColumns, Text, FlexRows, Input, CheckBox, Button, Modal } from '../../elements';
// import colors from '../../../theme/styles/colors';

const ModalHeader = styled(FlexRows)`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;
const ErrorMessage = styled.div`
  padding: 0.5rem;
  background: red;
  color: white;
  border-radius: 4px;
  margin-bottom: 1rem;
`;
const MobileSignInModal = ({ history }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const isError = useStoreState(state => state.auth.isError);
  const isLoading = useStoreState(state => state.auth.loading);
  const migrationPath = useStoreState(state => state.auth.migrationPath);
  const clearError = useStoreActions(state => state.auth.clearError);
  const login = useStoreActions(state => state.auth.logIn);
  const isActiveModal = useStoreState(state => state.auth.isActiveDesktopLoginModal);
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const callBackFunction = useStoreState(state => state.auth.callBackFunction);
  const setIsActiveDesktopSignInModal = useStoreActions(
    state => state.auth.setIsActiveDesktopSignInModal,
  );

  useEffect(() => {
    clearError();
  }, [clearError]);

  const handleSubmit = () => {
    login({ username: username.toLowerCase(), password, history, migrationPath, callBackFunction });
  };

  const handleSignupModal = () => {
    setIsActiveDesktopSignInModal({ value: true });
    setIsActiveModal({ value: false });
  };

  return (
    <Modal isActive={isActiveModal}>
      <ModalHeader justify="space-between">
        <a onClick={() => setIsActiveModal({ value: false })}>
          <Text color="white">
            <MdClear size={25} />
          </Text>
        </a>
        <Text className="is-uppercase" align="center" color="white" weight="bold" size="lmedium">
          Sign In
        </Text>
        <Text />
      </ModalHeader>

      <div className="modal-card">
        <form
          className="modal-card-body"
          onSubmit={event => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <FlexColumns>
            <div>
              <Text color="primaryColor" size="large" weight="semibold" paddingBottom={0.5}>
                Sign In to Kravein
              </Text>
            </div>
            <div>
              <Text color="darkGrey" size="tiny">
                New to Kravien? <a onClick={handleSignupModal}>Sign up</a>
              </Text>
            </div>
          </FlexColumns>
          <br />
          {isError.message !== '' && (
            <ErrorMessage>
              <p>{isError.message}</p>
            </ErrorMessage>
          )}
          <div className="field">
            <label className="label">Email Address</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                placeholder="Email"
                onChange={event => setUsername(event.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Password</label>
            <div className="control">
              <Input
                className="input"
                type="Password"
                placeholder="password"
                onChange={event => setPassword(event.target.value)}
              />
            </div>
          </div>
          <FlexRows justify="space-between" align="flex-start">
            <div>
              <CheckBox label="Remember" onChange={() => {}} />
            </div>
            <div>
              <Button
                className={isLoading && 'is-loading'}
                type="submit"
                name="Sign In"
                width={3}
                backgroundColor="primaryColor"
                textProps={{
                  color: isLoading ? 'primaryColor' : 'white',
                  weight: 'semibold',
                  size: 'small',
                }}
                onClick={handleSubmit}
              />

              <a>
                <Text color="primaryColor" size="tiny">
                  Forgot your password?
                </Text>
              </a>
            </div>
          </FlexRows>
          <br />
          <Text color="darkGrey" size="tiny">
            By Sign in your agree to our <a>Terms of Service</a> and <a>Privacy Policy</a>
          </Text>
          <Text color="darkGrey" size="tiny">
            Don&apos;t have a account? <a onClick={handleSignupModal}>Sign up</a>
          </Text>
        </form>
      </div>
    </Modal>
  );
};

export default withRouter(MobileSignInModal);
