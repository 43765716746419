import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text, FlexRows, Icon } from '../../../components/elements';

const Container = styled.div``;

const items = [
  {
    name: 'Add Debit / Credit Card',
    path: 'profile/payment-details/add-card',
    icon: 'debitCard',
  },
  {
    name: 'Paypal',
    path: 'profile/payment-details/paypal',
    icon: 'paypal',
  },
  {
    name: 'Visa checkout',
    path: 'profile/payment-details/visa',
    icon: 'visaCard',
  },
];

// const Container = styled.div`
//   padding-top: 2.9rem;
// `;

const Item = styled(FlexRows).attrs({ justify: 'space-between' })`
  background: ${({ theme: { colors } }) => colors.white};
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  border-radius: 0.5rem;
  margin: 0.5rem 0rem;
`;

const IconWrapper = styled.div`
  width: 10%;
`;
const TextWrapper = styled.div`
  width: 60%;
`;

const MobileView = ({ children }) => (
  <>
    <Container>
      {items.map(item => (
        <Link key={item.name} to={item.path}>
          <Item>
            <IconWrapper>
              <Icon name={item.icon} size={2} />
            </IconWrapper>
            <TextWrapper>
              <Text className="is-capitalized" align="left">
                {item.name}
              </Text>
            </TextWrapper>

            <IconWrapper>
              <Icon iconName="fas fa-angle-right" align="center" color="specialGrey" />
            </IconWrapper>
          </Item>
        </Link>
      ))}
    </Container>
    {children}
  </>
);

export default MobileView;
