import React from 'react';
import styled from 'styled-components';
import DesktopForm from './DesktopForm';
import { Text, Pager } from '../../components/elements';
import Loading from '../../components/global/mobile/MobileLoading';

const Container = styled(Pager)`
  padding-top: 2rem;
`;
const Heading = styled.div`
  padding: 1rem 0rem;
`;

const DesktopView = ({ loading }) => {
  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Heading>
        <Text className="is-capitalized" size="big" weight="semibold" paddingBottom={0.5}>
          Contact Us
        </Text>

        <Text color="darkGrey" size="tiny" weight="regular">
          We'd love to hear from you! We are here to answer any questions you may have about your
          kravein experiences. We are passionate about Kravein and always are happy to help find you
          the solution for your needs. Even if there is something you have always wanted to
          experience and can't find it on Kravein, let us know and we will do our best to work on
          the solution for you.
        </Text>
      </Heading>
      <DesktopForm />
    </Container>
  );
};

export default DesktopView;
