import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ProfileSettings from './ProfileSettings';
import { FlexRows, Text, Icon } from '../../components/elements';

const items = [
  {
    name: 'Notifications',
    path: '/settings/notifications',
    icon: 'notifications',
  },
  {
    name: 'Social Sharing',
    path: '/settings/social-sharing',
    icon: 'socialSharing',
  },
  {
    name: 'Location',
    path: '/settings/location',
    icon: 'location',
  },
];

const Container = styled.div``;

const Item = styled(FlexRows).attrs({ justify: 'space-between' })`
  background: ${({ theme: { colors } }) => colors.white};
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  border-radius: 0.5rem;
  margin: 0.5rem 0rem;
`;

const IconWrapper = styled.div`
  width: 10%;
`;
const TextWrapper = styled.div`
  width: 60%;
`;
const MobileView = ({ children }) => (
  <>
    <Container>
      {items.map(item => (
        <Link key={item.name} to={item.path}>
          <Item>
            <IconWrapper>
              <Icon name={item.icon} size={2} />
            </IconWrapper>
            <TextWrapper>
              <Text className="is-capitalized" align="left">
                {item.name}
              </Text>
            </TextWrapper>

            <IconWrapper>
              <Icon iconName="fas fa-angle-right" align="center" color="specialGrey" />
            </IconWrapper>
          </Item>
        </Link>
      ))}
      {<ProfileSettings />}
      {
        <Link key="change-password" to="/settings/change-password">
          <Item>
            <IconWrapper>
              <Icon name="changePassword" size={2} />
            </IconWrapper>
            <TextWrapper>
              <Text className="is-capitalized" align="left">
                Change Password
              </Text>
            </TextWrapper>

            <IconWrapper>
              <Icon iconName="fas fa-angle-right" align="center" color="specialGrey" />
            </IconWrapper>
          </Item>
        </Link>
      }
    </Container>
    {children}
  </>
);

export default MobileView;
