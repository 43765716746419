import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '../../../components/elements';

const BookingButton = ({ history, event, disabled }) => {
  return (
    <Button
      name="BOOK NOW"
      isFullWidth
      backgroundColor="primaryColor"
      textProps={{ color: 'white', weight: 'semibold', size: 'small' }}
      disabled={disabled}
      onClick={() => history.push(`/event-booking/${event.event_id}`)}
    />
  );
};

export default withRouter(BookingButton);
