import React from 'react';
import styled from 'styled-components';
import { FaAngleRight } from 'react-icons/fa';
import localForage from 'localforage';
import { useStoreState, useStoreActions } from 'easy-peasy';
import swal from 'sweetalert';

import { Text, FlexRows, FlexColumns, Pager } from '../../components/elements';
import ChangePasswordForm from './ChangePasswordForm';
import { changePassword } from '../../utils/auth';

const Container = styled(Pager)`
  padding-top: 2rem;
`;
const PageHeader = styled.div`
  position: sticky;
  top: 4rem;
  z-index: 100;
  background: #ffffff;
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const IconWrapper = styled.div`
  padding-left: 2rem;
`;

const Wrapper = styled.div`
  && {
    background-color: ${({ theme: { colors } }) => colors.white};
    .is-2 {
      width: 19.66667%;
    }
  }
`;

const ItemWrapper = styled(FlexColumns).attrs({ align: 'flex-start' })`
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-right: 1rem;
  height: 74vh;
`;

const RightWrapper = styled.div`
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin: 0.5rem;
`;

const Item = styled(FlexRows).attrs({ justify: 'space-between' })`
  cursor: pointer;
  padding: 1rem;
  width: 100%;
  :hover {
    background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha};
  }

  background-color: ${({ theme: { colors }, active }) => active && colors.lightGreyAlpha};
`;

const items = [
  { name: 'Notification Preferences' },
  { name: 'Social Sharing' },
  { name: 'Profile Setting' },
  { name: 'Change Password' },
];

const DesktopView = () => {
  const [active, setActive] = React.useState('Events');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const user = useStoreState(state => state.auth.user);
  const { logout } = useStoreActions(state => state.auth);

  return (
    <Container>
      <PageHeader>
        <Text size="large" weight="semibold" paddingBottom={0.5}>
          Settings
        </Text>
        <Text color="darkGrey" size="tiny">
          Maintain you account settings
        </Text>
      </PageHeader>
      <Wrapper>
        <div className="columns is-gapless">
          <div className="column is-2">
            <ItemWrapper justify="flex-start" align="flex-start">
              {items.map(item => (
                <Item
                  key={item.name}
                  active={active === item.name}
                  onClick={() => setActive(item.name)}
                >
                  <Text
                    size="tiny"
                    weight="semibold"
                    color={active === item.name ? 'darkGrey' : 'specialGrey'}
                  >
                    {item.name}
                  </Text>
                  <IconWrapper>
                    <Text size="large" color="specialGrey">
                      <FaAngleRight />
                    </Text>
                  </IconWrapper>
                </Item>
              ))}
            </ItemWrapper>
          </div>
          <RightWrapper className="column">
            {active === 'Change Password' && (
              <ChangePasswordForm
                isError={error}
                isLoading={loading}
                onCancel={() => setActive('Notification Preferences')}
                onSubmit={async (value, { resetForm }) => {
                  setLoading(true);
                  setError(false);

                  console.log(value);
                  try {
                    await changePassword(user.email, value.currentPass, value.newPassword);
                    setLoading(false);
                    swal({
                      title: 'Your password changed successfully!',
                      text: 'Are you want to logout?',
                      icon: 'success',
                      buttons: ['Cancel', 'Signout'],
                      dangerMode: true,
                    }).then(async willDelete => {
                      if (willDelete) {
                        console.log();
                        resetForm();
                        await localForage.clear();
                        await logout();
                        await window.localStorage.clear();
                      } else {
                        resetForm();
                      }
                    });
                  } catch (err) {
                    console.log(err);
                    setError(true);
                    setLoading(false);
                  }
                }}
              />
            )}
          </RightWrapper>
        </div>
      </Wrapper>
    </Container>
  );
};

export default DesktopView;
