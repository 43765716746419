import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import ReactLoading from 'react-loading';
import { isNull } from 'lodash';

import { Text, FlexColumns } from '../../../../components/elements';
import ItemCard from '../../../Items/components/mobile/ItemCard';
import theme from '../../../../theme';
// import ItemReviewActionButton from './ItemReviewActionButton';

const itemsQuery = gql`
  query itemsQuery($input: SearchInput) {
    search_items(input: $input) {
      item_listing {
        item_id
        place_id
        place_name
        name
        slug
        star_count
        loved_percentage
        tag
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        timezone
        cuisine_style {
          name
          description
          display_order
        }
        item_type {
          name
          description
          display_order
        }
        default_image_url
      }
    }
  }
`;

const Container = styled.div`
  padding: 1rem 0rem;
`;

const MessageContainer = styled(FlexColumns)`
  height: 30vh;
`;

const TrendingItems = ({ place }) => {
  return (
    <Container>
      <Query
        query={itemsQuery}
        variables={{
          input: {
            filter: {
              item_filter: {
                place_id: place.place_id,
                status: 'ACTIVE',
                approval_status: 'APPROVED',
              },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return (
              <MessageContainer>
                <ReactLoading type="spin" color={theme.primaryColor} width={50} height={50} />
              </MessageContainer>
            );
          }
          if (error) {
            return (
              <MessageContainer>
                <Text weight="semibold" color="darkGrey">
                  {error.message}
                </Text>
              </MessageContainer>
            );
          }
          if (isNull(data.search_items)) {
            return (
              <MessageContainer>
                <Text weight="semibold" color="darkGrey">
                  No Item Found
                </Text>
              </MessageContainer>
            );
          }

          return <View items={data.search_items.item_listing} place={place} />;
        }}
      </Query>
    </Container>
  );
};

const View = ({ items }) => {
  if (items.length === 0) {
    return (
      <MessageContainer>
        <Text weight="semibold" color="darkGrey">
          No Item Found
        </Text>
      </MessageContainer>
    );
  }

  return (
    <>
      {/* <ItemReviewActionButton placeId={place.place_id} placeName={place.name} /> */}
      <div className="columns is-multiline is-8 is is-gapless">
        {items.map(item => (
          <ItemCard item={item} />
        ))}
      </div>
    </>
  );
};

export default TrendingItems;
