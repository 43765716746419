/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import gql from 'graphql-tag';
import rug from 'random-username-generator';
import uuidv4 from 'uuid/v4';
import randomColor from 'randomcolor';
import { first, isNull } from 'lodash';
import { cognitoSignIn, cognitoSignUp, refreshTokenFunction } from '../utils/auth';
import client from '../utils/apolloClient';
import { getApiImages } from '../utils/reusableQuery';

const updateUserMutation = gql`
  mutation updateUser($input: UserInput) {
    update_user(input: $input) {
      user_id
      first_name
      last_name
      display_name
      email
      color_code
      preferred_username
      error {
        description
      }
    }
  }
`;

const fetchUserQuery = gql`
  query fetch_user($input: NavigateUserInput) {
    fetch_user(input: $input) {
      user_id
      first_name
      last_name
      display_name
      email
      color_code
      preferred_username
      profile_image {
        image_id
      }
    }
  }
`;

rug.setSeperator('_');

export default {
  auth: {
    remember: {
      username: '',
      password: '',
      isRemember: false,
    },
    isActiveDesktopLoginModal: false,
    isActiveDesktopSignInModal: false,
    isActiveForgotPassModal: false,
    migrationPath: '/',
    user: null,
    loading: false,
    isError: { message: '', error: false },
    isLoggedIn: false,
    authTokens: {},
    logOutTime: null,
    token: null,
    userId: null,
    userImage: null,
    callBackFunction: () => {},

    setUserImage: action((state, payload) => {
      state.userImage = payload;
    }),

    setRemember: action((state, payload) => {
      state.remember = payload;
    }),

    logout: action(state => {
      state.isActiveDesktopLoginModal = false;
      state.isActiveDesktopSignInModal = false;
      state.migrationPath = '/';
      state.user = null;
      state.loading = false;
      state.isError = { message: '', error: false };
      state.isLoggedIn = false;
      state.authTokens = {};
      state.logOutTime = null;
      state.token = null;
      state.userId = null;
      state.userImage = null;
      window.localStorage.setItem('token', undefined);
      // window.location.reload();
    }),

    setAuthTokens: action((state, payload) => {
      state.authTokens = payload;
      state.isLoggedIn = true;
      state.logOutTime = payload.payload.exp - 100;
      state.token = payload.idToken;
      state.userId = payload.payload['custom:user_id'];
    }),

    setIsActiveDesktopLoginModal: action((state, payload) => {
      state.isActiveDesktopLoginModal = payload.value;
      state.migrationPath = payload.path ? payload.path : '';
      state.callBackFunction = payload.callBackFunction ? payload.callBackFunction : () => {};
      state.loading = false;
      state.isError = { message: '', error: false };
    }),

    setIsActiveDesktopSignInModal: action((state, payload) => {
      state.isActiveDesktopSignInModal = payload.value;
      state.loading = false;
      state.isError = { message: '', error: false };
      // state.migrationPath = payload.path;
      // state.callBackFunction = payload.callBackFunction ? payload.callBackFunction : () => {};
    }),

    setIsActiveForgotPassModal: action((state, payload) => {
      state.isActiveForgotPassModal = payload.value;
      state.loading = false;
      state.isError = { message: '', error: false };
      // state.migrationPath = payload.path;
      // state.callBackFunction = payload.callBackFunction ? payload.callBackFunction : () => {};
    }),

    setError: action((state, payload) => {
      state.isError = payload;
    }),

    clearError: action(state => {
      state.isError = { message: '', error: false };
    }),

    setLoading: action((state, payload) => {
      state.loading = payload;
    }),

    updateUser: action((state, payload) => {
      state.user = payload;
    }),

    refreshToken: thunk(async (actions, payload) => {
      const data = await refreshTokenFunction(payload.token, payload.username);
      actions.setAuthTokens(data);
    }),

    logIn: thunk(async (actions, payload) => {
      const { username, password, history, migrationPath, callBackFunction } = payload;
      let profileImageId;
      try {
        actions.setError({ message: '', error: false });
        actions.setLoading(true);
        const data = await cognitoSignIn(username, password);
        await window.localStorage.setItem('token', data.idToken);
        await window.localStorage.setItem('username', username);
        await window.localStorage.setItem('accessToken', data.accessToken);
        await window.localStorage.setItem('refreshToken', data.refreshToken);
        await client.clientPrivate
          .query({
            query: fetchUserQuery,
            variables: { input: { user_id: data.payload['custom:user_id'] } },
            fetchPolicy: 'network-only',
          })
          .then(({ data: result }) => {
            if (result.fetch_user) {
              actions.updateUser(result.fetch_user);
              if (isNull(result.fetch_user.profile_image)) {
                profileImageId = null;
              } else if (result.fetch_user.profile_image.length === 0) {
                profileImageId = null;
              } else {
                profileImageId = first(result.fetch_user.profile_image).image_id;
              }
            }
          });
        await actions.setUserImage(null);
        await actions.setAuthTokens(data);
        await actions.setIsActiveDesktopLoginModal({ value: false, path: '/' });
        await actions.setLoading(false);
        if (migrationPath !== '') {
          history.push(migrationPath);
        } else {
          callBackFunction({ history, userId: data.payload['custom:user_id'] });
        }

        if (!isNull(profileImageId)) {
          const image = await getApiImages({ image_id: profileImageId });
          await actions.setUserImage(first(image).url);
        }
      } catch (err) {
        const error = { message: 'Please Enter valid email address and password', error: true };
        await actions.setError(error);
        console.log('err', err);
        await actions.setLoading(false);
      }
    }),

    signUp: thunk(async (actions, payload) => {
      const { firstName, lastName, displayName, email, password } = payload;
      const username = uuidv4();
      try {
        actions.setLoading(true);
        await cognitoSignUp(firstName, lastName, displayName, username, email, password);
        actions.afterSignUp({ firstName, lastName, displayName, username, email, password });
      } catch (err) {
        const error = { message: err.message, error: true };
        actions.setError(error);
        actions.setLoading(false);
      }
    }),

    afterSignUp: thunk(async (actions, payload) => {
      const { username, password, firstName, lastName, displayName, email } = payload;
      try {
        actions.setError({ message: '', error: false });
        actions.setLoading(true);
        const data = await cognitoSignIn(username, password);
        await window.localStorage.setItem('token', data.idToken);
        await window.localStorage.setItem('username', username);
        await window.localStorage.setItem('accessToken', data.accessToken);
        await window.localStorage.setItem('refreshToken', data.refreshToken);
        await client.clientPrivate
          .mutate({
            mutation: updateUserMutation,
            variables: {
              input: {
                user_id: username,
                object_id: username,
                first_name: firstName,
                last_name: lastName,
                display_name: displayName,
                preferred_username: email, // data.username,
                status: 'ACTIVE',
                color_code: randomColor({
                  luminosity: 'dark',
                }),
              },
            },
          })
          .then(async result => {
            await actions.updateUser(result.data.update_user);
            await actions.setAuthTokens(data);
            actions.setUserImage(null);
            await actions.setLoading(false);
            await actions.setIsActiveDesktopSignInModal({ value: false });
          });

        await actions.setIsActiveDesktopLoginModal({ value: false, path: '/' });
        await actions.setLoading(false);
      } catch (err) {
        const error = { message: err.message, error: true };
        await actions.setError(error);
        await actions.setLoading(false);
      }
    }),
  },
};
