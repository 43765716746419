import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/global/MenuLayout';
import { Icon, Text, Button } from '../../components/elements';
import { trackEvent } from '../../utils/analytics';

const Container = styled.section`
  height: 90vh;
`;
const ButtonWrapper = styled.div`
  padding: 1rem 3rem;
`;
const ButtonStyle = styled(Button).attrs({ textStyle: { color: 'white', weight: 'bold' } })`
  &&& {
    background-color: ${({ theme: colors }) => colors.primaryColor};
  }
`;

const NotFound = ({ history }) => (
  <Layout>
    <Container className="hero">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="columns">
            <div className="column">
              <Text size="large" weight="bold">
                Looks Like you're wondering to <i>nowhere...</i>
              </Text>
              <Text size="small" weight="bold" color="specialGrey">
                Let us guide you to awesome places with extraordinary items
              </Text>
              <br />
              <ButtonWrapper>
                <ButtonStyle onClick={() => history.push('/')} name="Lets Go" />
              </ButtonWrapper>
              <br />
            </div>
            <div className="column">
              <h1 className="title">
                <Icon name="notFound" size={30} />
              </h1>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </Layout>
);

export default NotFound;
