import React, { useState } from 'react';
import styled from 'styled-components';
import { MdChevronRight } from 'react-icons/md';
import { GoLocation } from 'react-icons/go';
import { toast } from 'react-toastify';
import {
  Text,
  Icon,
  FlexColumns,
  FlexRows,
  FlexItem,
  TextArea,
  InputErrorMessage,
} from '../../../../components/elements';
import ImageUpload from './ImageUpload';
import RatingInput from '../desktop/RatingInput';
import SelectPlaceModal from './SelectPlaceModal';
import SelectItemDictionary from './SelectItemDictionary';

const Container = styled.div`
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem 0rem;
  border-radius: 0.5rem;
`;
const CameraWrapper = styled(FlexColumns)`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;
const RatingWrapper = styled(FlexColumns)`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;
const PlaceWrapper = styled(FlexRows)`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;
const ItemWrapper = styled(FlexRows)`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const ReviewPlaceIcon = styled(Icon)`
  padding-top: 4px;
`;

const TextAreaWrapper = styled(FlexRows)`
  padding: 1rem;
`;

const Card = ({ setFieldValue, values, touched, errors, handleChange }) => {
  const [isActivePlaceModal, setIsActivePlaceModal] = useState(false);
  const [isActiveItemModal, setIsActiveItemModal] = useState(false);
  console.log('values', values);
  return (
    <Container>
      <CameraWrapper>
        <ImageUpload setFieldValue={setFieldValue} />
      </CameraWrapper>
      <a onClick={() => setIsActivePlaceModal(true)}>
        <PlaceWrapper justify="space-between">
          <Text color="primaryColor">
            <GoLocation size={30} />
          </Text>
          <div>
            <Text
              className="is-uppercase"
              size="small"
              weight="semibold"
              color="primaryColor"
              align="center"
            >
              Place name
            </Text>
            <Text size="tiny" align="center" color="darkGrey" weight="semibold">
              {values.placeData.name ? values.placeData.name : 'Select a Place Name'}
            </Text>
          </div>
          <Text color="specialGrey">
            <MdChevronRight size={30} />
          </Text>
        </PlaceWrapper>
      </a>

      <a
        onClick={() => {
          if (values.placeData.place_id) {
            setIsActiveItemModal(true);
          } else {
            toast.error('Please select place first');
          }
        }}
      >
        <ItemWrapper justify="space-between">
          <Text>
            <Icon name="restaurantAndBars" size={2} />
          </Text>
          <div>
            <Text
              className="is-uppercase"
              align="center"
              size="small"
              weight="semibold"
              color="primaryColor"
            >
              Item or Food
            </Text>
            <Text size="tiny" align="center" color="darkGrey" weight="semibold">
              {values.item_name !== '' ? values.item_name : 'Select a Item Name'}
            </Text>
            <InputErrorMessage touched={touched.item_name} errors={errors.item_name} />
          </div>
          <Text color="specialGrey">
            <MdChevronRight size={30} />
          </Text>
        </ItemWrapper>
      </a>

      <RatingWrapper>
        <Text
          className="is-uppercase"
          size="small"
          weight="semibold"
          align="center"
          paddingBottom={0.5}
        >
          How was it ?
        </Text>
        <RatingInput
          iconSize={2}
          handleChange={value => setFieldValue('overall_rating', value)}
          padding={10}
        />
        <InputErrorMessage touched={touched.overall_rating} errors={errors.overall_rating} />
      </RatingWrapper>
      <FlexRows />
      <TextAreaWrapper justify="space-between" align="flex-start">
        <FlexItem grow={1}>
          <ReviewPlaceIcon name="reviewPlaceSolid" size={2} />
        </FlexItem>
        <FlexItem grow={6}>
          <TextArea
            name="content"
            borderLess
            value={values.content}
            placeholder="Say Somethings about this item,
           food and beverage (min 10 and max 280 characters)"
            onChange={handleChange}
          />
          <InputErrorMessage touched={touched.content} errors={errors.content} />
        </FlexItem>
      </TextAreaWrapper>
      <SelectPlaceModal
        isActive={isActivePlaceModal}
        onClose={() => setIsActivePlaceModal(false)}
        onSelectPlace={(place, action) => {
          setIsActivePlaceModal(false);
          setFieldValue('placeData', place);
          setFieldValue('placeAction', action);
        }}
      />
      <SelectItemDictionary
        placeData={values.placeData}
        values={values}
        isActive={isActiveItemModal}
        onClose={() => setIsActiveItemModal(false)}
        setFieldValue={setFieldValue}
      />
    </Container>
  );
};

export default Card;
