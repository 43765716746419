import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { Button, Icon, Text } from '../../../../components/elements';
import GetImages from '../../../../components/GetImages';

const Container = styled.div`
  padding: 1rem 0rem;
  padding-right: 20%;
`;

const Wrapper = styled.div`
  margin-top: 1.6rem;
`;
const ButtonWrapper = styled.div`
  margin-top: 2.28rem;
`;

const SearchInput = styled.div`
  &&& {
    p {
      margin-bottom: 0.7em !important;
    }
    .input {
      padding-left: 0.9rem;
    }
  }
`;

const PlaceReviewActionButton = ({ placeId, history, placeName }) => {
  const { isLoggedIn, user, userImage } = useStoreState(state => state.auth);
  return (
    <Container>
      <article className="media">
        <figure className="media-left">
          <Wrapper>
            {isLoggedIn ? (
              <GetImages
                name={user.display_name}
                maskProps={{ width: 50, height: 50 }}
                maskTextProps={{
                  color: 'white',
                  weight: 'bold',
                  size: 'medium',
                  letterSpacing: 'loose',
                }}
                borderRadius={25}
                randomColorCode={user.color_code}
                userImage={userImage}
              />
            ) : (
              <Icon name="myProfileBlue" size={3} />
            )}
          </Wrapper>
        </figure>
        <div className="media-content">
          <div className="content">
            <SearchInput>
              <Text size="bmedium" weight="semibold">
                Write a Review
              </Text>

              <input
                className="input"
                placeholder={`Share your experience at ${placeName}..`}
                onFocus={() => history.push(`/place-review/${placeId}`)}
              />
            </SearchInput>
          </div>
        </div>
        <div className="media-right">
          <ButtonWrapper>
            <Button
              name="Add a review"
              height={0}
              borderColor="primaryColor"
              backgroundColor="primaryColor"
              textProps={{ size: 'tiny', color: 'white', weight: 'semibold' }}
              onClick={() => history.push(`/place-review/${placeId}`)}
            />
          </ButtonWrapper>
        </div>
      </article>
    </Container>
  );
};
export default withRouter(PlaceReviewActionButton);
