import React from 'react';
import styled from 'styled-components';
import { FaAngleRight } from 'react-icons/fa';

import { Text, FlexRows, FlexColumns, Pager } from '../../components/elements';
import Events from './Events';
import Places from './Places';
import Items from './Items';

const PageHeader = styled(Pager)`
  background: #ffffff;
  padding-top: 2rem;
  padding-bottom: 1rem;
`;

const IconWrapper = styled.div`
  padding-left: 2rem;
`;

const Wrapper = styled.div`
  && {
    background-color: ${({ theme: { colors } }) => colors.white};
  }
`;

const ItemWrapper = styled(FlexColumns).attrs({ align: 'flex-start' })`
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-right: 1rem;
  height: 74vh;
`;

const RightWrapper = styled.div`
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin: 0.5rem;
`;

const Item = styled(FlexRows).attrs({ justify: 'space-between' })`
  cursor: pointer;
  padding: 1rem;
  width: 100%;
  :hover {
    background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha};
  }

  background-color: ${({ theme: { colors }, active }) => active && colors.lightGreyAlpha};
`;

const items = [
  { name: 'My Saved Places' },
  { name: 'My Saved Items' },
  { name: 'My Saved Events' },
];

const DesktopView = () => {
  const [active, setActive] = React.useState('My Saved Places');
  return (
    <>
      <PageHeader>
        <Text size="large" weight="semibold" paddingBottom={0.5}>
          My Bookmarks
        </Text>
        <Text color="darkGrey" size="tiny">
          Access and maintain your saved Bookmarks
        </Text>
      </PageHeader>
      <Wrapper>
        <div className="columns is-gapless">
          <div className="column is-2">
            <ItemWrapper justify="flex-start" align="flex-start">
              {items.map(item => (
                <Item
                  key={item.name}
                  active={active === item.name}
                  onClick={() => setActive(item.name)}
                >
                  <Text
                    size="small"
                    weight="semibold"
                    color={active === item.name ? 'darkGrey' : 'specialGrey'}
                  >
                    {item.name}
                  </Text>
                  <IconWrapper>
                    <Text size="large" color="specialGrey">
                      <FaAngleRight />
                    </Text>
                  </IconWrapper>
                </Item>
              ))}
            </ItemWrapper>
          </div>
          <RightWrapper className="column">
            {active === 'My Saved Places' && <Places />}
            {active === 'My Saved Items' && <Items />}
            {active === 'My Saved Events' && <Events />}
          </RightWrapper>
        </div>
      </Wrapper>
    </>
  );
};

export default DesktopView;
