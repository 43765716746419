import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { forEach, groupBy, map } from 'lodash';

import { Text, FlexRows } from '../../../components/elements';
import YourOrderItem from './YourOrderItem';
import { formatCurrency } from '../../../utils/helpers';

const Container = styled.div`
  padding-top: 1rem;
  && {
    .order-details {
      padding: 2rem 0rem;
    }
    /* .your-order {
      padding-top: 1rem;
    } */
    .order-detail-info {
      padding-bottom: 1rem;
    }
    .rest-name {
      margin-top: 1.5rem;
    }
    .d-flex {
      display: flex;
      justify-content: space-between;
    }
    figure {
      margin-bottom: 1rem;
    }
    .is-2by1 {
      padding-top: 40%;
      margin-top: 4rem;
    }
    span {
      color: ${({ theme: { colors: themeColors } }) => themeColors.primaryColor};
    }
  }
`;

const Bottom = styled.div``;

const parseOrderDatabaseInputItems = items => {
  const data = [];
  forEach(items, item => {
    let selectedVariant = null;
    if (item.variant_id) {
      selectedVariant = {
        variant_id: item.variant_id,
        variant_name: item.variant_name,
        variant_price: item.price_per_item,
        prompt_id: item.prompt_id,
      };
    }

    const groupsTemp = [];
    const groups = groupBy(item.modifier, 'modifier_group_id');

    map(groups, item2 => {
      const modifiersTemp = [];
      forEach(item2, item3 => {
        let selectedVariant2 = null;
        if (item3.variant_id) {
          selectedVariant2 = {
            prompt_id: item3.prompt_id,
            variant_id: item3.variant_id,
            variant_name: item3.variant_name,
            variant_price: item3.price_per_item,
          };
        }
        modifiersTemp.push({
          menu_item: { menu_item_id: item3.menu_item_id, name: item3.name },
          override_price: item3.price,
          override_variant: [],
          selectedVariant: selectedVariant2,
          ...item3,
        });
      });

      const group = { modifier_group_id: item2[0].modifier_group_id, modifiers: modifiersTemp };
      groupsTemp.push(group);
    });

    const newItem = {
      id: item.id,
      displayOrder: item.displayOrder,
      item_link_id: item.item_link_id,
      menu_item_id: item.menu_item_id,
      quantity: item.quantity,
      name: item.name,
      price_per_item: item.price_per_item,
      tax: item.tax,
      net_price_per_item: item.net_price_per_item,
      price: item.price,
      overall_price: item.overall_price,
      special_request: item.special_request,
      selectedVariant,
      groups: groupsTemp,
    };
    data.push(newItem);
  });
  return data;
};

const YourOrder = ({ order }) => {
  const orderDetails = { ...order, line_item: parseOrderDatabaseInputItems(order.line_item) };
  const promoDiscount =
    order.billing.sub_total + order.billing.delivery_fee - order.billing.order_total;
  return (
    <Container>
      <Text weight="semibold" size="smedium">
        Order Details
      </Text>
      <br />
      {orderDetails.line_item.map(item => (
        <YourOrderItem item={item} />
      ))}
      <hr />
      <Bottom>
        {order.billing.sub_total && order.billing.sub_total !== 0 && (
          <FlexRows justify="space-between">
            <Text weight="medium" size="smedium" paddingBottom="0.2">
              Items subtotal
            </Text>
            <Text weight="medium" size="smedium" paddingBottom="0.2">
              {formatCurrency(order.billing.sub_total)}
            </Text>
          </FlexRows>
        )}
        {order.billing.delivery_fee && order.billing.delivery_fee !== 0 && (
          <FlexRows justify="space-between">
            <Text weight="medium" size="smedium" paddingBottom="0.2">
              Delivery fee
            </Text>
            <Text weight="medium" size="smedium" paddingBottom="0.2">
              {formatCurrency(order.billing.delivery_fee)}
            </Text>
          </FlexRows>
        )}
        {promoDiscount !== 0 && (
          <FlexRows justify="space-between">
            <Text color="primaryColor" weight="semibold" size="smedium" paddingBottom="0.2">
              Promo
            </Text>
            <Text color="primaryColor" weight="semibold" size="smedium" paddingBottom="0.2">
              - {formatCurrency(promoDiscount)}
            </Text>
          </FlexRows>
        )}
        {order.billing.order_total && order.billing.order_total !== 0 && (
          <FlexRows justify="space-between">
            <Text weight="semibold" size="medium" paddingBottom="0.2">
              Total
            </Text>
            <Text weight="semibold" size="smedium" paddingBottom="0.2">
              {formatCurrency(order.billing.order_total)}
            </Text>
          </FlexRows>
        )}
      </Bottom>
    </Container>
  );
};

export default withRouter(YourOrder);
