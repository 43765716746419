import React from 'react';
import styled from 'styled-components';
import { MdMenu } from 'react-icons/md';
import Sidebar from 'react-sidebar';
import SideBarComponent from './SideBarComponent';
import colors from '../../theme/styles/colors';

const Wrapper = styled.div`
  padding: 13px;
  margin-top: 6px;
`;

const style = {
  root: {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
  },
  sidebar: {
    zIndex: 999,
    position: 'fixed',
    top: 0,
    bottom: 0,
    transition: 'transform .3s ease-out',
    WebkitTransition: '-webkit-transform .3s ease-out',
    willChange: 'transform',
    overflowY: 'auto',
    background: 'white',
    width: '70%',
  },
  content: {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    transition: 'left .3s ease-out, right .3s ease-out',
  },
  overlay: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity .3s ease-out, visibility .3s ease-out',
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  dragHandle: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    bottom: 0,
  },
};

class MySidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
  }

  onSetSidebarOpen = open => {
    this.setState({ sidebarOpen: open });
  };

  onHandleClickOnTabs = to => {
    const { history } = this.props;
    this.setState({ sidebarOpen: false });
    history.push(to);
  };

  render() {
    return (
      <>
        <Sidebar
          pullRight
          sidebar={
            <SideBarComponent
              onSetSidebarOpen={this.onSetSidebarOpen}
              onHandleClickOnTabs={this.onHandleClickOnTabs}
            />
          }
          open={this.state.sidebarOpen}
          onSetOpen={this.onSetSidebarOpen}
          styles={{ ...style }}
          contentClassName="content"
        >
          <a onClick={() => this.onSetSidebarOpen(true)}>
            <Wrapper>
              <MdMenu size={25} color={colors.white} />
            </Wrapper>
          </a>
        </Sidebar>
      </>
    );
  }
}

export default MySidebar;
