import React, { useState } from 'react';
import styled from 'styled-components';
import { times } from 'lodash';
import moment from 'moment';

import { MdStar } from 'react-icons/md';
import {
  FlexRows,
  IconActionButton,
  ShowMoreText,
  FlexColumns,
  Text,
} from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';
import ReviewImageView from './ReviewImageView';

import PlaceReviewCommentList from './PlaceReviewCommentList';
import SocialActionButton from '../../../../components/SocialActionButton';
import FlaggedActionButton from '../../../../components/FlaggedActionButton';
import PlaceHeader from '../PlaceHeader';

const Container = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${colors.lightGreyBeta};
`;
const IconWrapper = styled.span`
  padding: 0rem;
`;

const SocialActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const IconActionButtonStyle = styled(IconActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const FlaggedActionButtonStyle = styled(FlaggedActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const ReviewItem = ({ review }) => {
  const [reFetchLikeUsers, setReFetchLikeUsers] = useState(false);
  return (
    <Container>
      <PlaceHeader placeId={review.place_id}>
        <FlexColumns>
          <div className="is-flex">
            {times(review.overall_rating).map(index => (
              <IconWrapper key={index}>
                <MdStar color={colors.tertiaryColor} size={15} />
              </IconWrapper>
            ))}
            {times(5 - review.overall_rating).map(index => (
              <IconWrapper key={index}>
                <MdStar color={colors.specialGrey} size={15} />
              </IconWrapper>
            ))}
          </div>
          <Text size="micro" color="darkGrey" lineHeight="normal">
            {moment(review.audit.created_at).fromNow()}
          </Text>
        </FlexColumns>
      </PlaceHeader>
      <ShowMoreText content={review.content} limit={200} size="tiny" />
      <br />
      <ReviewImageView placeId={review.place_id} placeReviewId={review.place_review_id} />
      <FlexRows justify="space-between">
        <SocialActionButtonStyle
          social={review.social}
          objectId={review.place_review_id}
          objectType="PLACE_REVIEW"
          type="LIKE"
          name="LIKE"
          defaultIcon="likeGrey"
          activeIcon="likeBlue"
          trackOnChange={() => setReFetchLikeUsers(!reFetchLikeUsers)}
        />
        <IconActionButtonStyle
          name="COMMENT"
          icon="commentBlue"
          // onClick={() => setIsActiveComment(true)}
        />
        <FlaggedActionButtonStyle
          createdBy={review.audit.created_by}
          objectId={review.place_review_id}
          parentId={review.place_id}
          objectType="PLACE_REVIEW"
          name="FLAG"
          icon="flagGrey"
        />
      </FlexRows>
      <PlaceReviewCommentList placeId={review.place_id} placeReviewId={review.place_review_id} />
    </Container>
  );
};

export default ReviewItem;
