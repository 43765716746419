import React from 'react';
import styled from 'styled-components';

import { Field, TextArea, Text, Flex } from '../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
`;

const FieldWrapper = styled.div`
  padding: 1rem 0rem;
`;

const FieldStyle = styled(Field).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;

const FooterButton = styled.a`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.8rem 1rem;
  position: fixed;
  bottom: 0;
`;

const MobileForm = () => (
  <>
    <Wrapper>
      <FieldWrapper>
        <FieldStyle label="Place Name" />
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle label="Address" />
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle label="Phone Number" />
      </FieldWrapper>
      <FieldWrapper>
        <label className="level">
          <Text className="is-uppercase" color="primaryColor" weight="bold" letterSpacing="loose">
            Any Other Details
          </Text>
        </label>
        <TextArea id="message" name="message" placeholder="Enter your message" />
      </FieldWrapper>
    </Wrapper>
    <FooterButton>
      <Flex>
        <Text
          className="is-uppercase"
          color="white"
          align="center"
          weight="bold"
          size="medium"
          letterSpacing="loose"
        >
          Submit
        </Text>
      </Flex>
    </FooterButton>
  </>
);

export default MobileForm;
