import React from 'react';
import styled from 'styled-components';
import { Text, FlexRows } from '../../../components/elements';
import MobileItems from './MobileItems';
import MobilePlaces from './MobilePlaces';
import MobileEvents from './MobileEvents';

const Container = styled.div``;

const Wrapper = styled(FlexRows)`
  position: sticky;
  top: 3.4rem;
`;

const Item = styled.div`
  padding: 1rem;
  width: 100%;
  border-bottom: 2px solid
    ${({ theme: { colors }, active }) => (active ? colors.primaryColor : 'transparent')};
  text-align: center;
`;

const MobileView = ({ children }) => {
  const [activeTab, setActiveTab] = React.useState('items');
  return (
    <>
      <Container>
        <Wrapper justify="space-around">
          <Item active={activeTab === 'items'} onClick={() => setActiveTab('items')}>
            <Text
              className="is-uppercase"
              weight="bold"
              size="small"
              color={activeTab === 'items' ? 'primaryColor' : 'dark'}
            >
              Items
            </Text>
          </Item>
          <Item active={activeTab === 'places'} onClick={() => setActiveTab('places')}>
            <Text
              className="is-uppercase"
              weight="bold"
              size="small"
              color={activeTab === 'places' ? 'primaryColor' : 'dark'}
            >
              Places
            </Text>
          </Item>
          <Item active={activeTab === 'events'} onClick={() => setActiveTab('events')}>
            <Text
              className="is-uppercase"
              weight="bold"
              size="small"
              color={activeTab === 'events' ? 'primaryColor' : 'dark'}
            >
              Events
            </Text>
          </Item>
        </Wrapper>
        {activeTab === 'items' && <MobileItems />}
        {activeTab === 'places' && <MobilePlaces />}
        {activeTab === 'events' && <MobileEvents />}
      </Container>
      {children}
    </>
  );
};

export default MobileView;
