import React from 'react';
import styled from 'styled-components';

import { Text, FlexRows, Pager } from '../../components/elements';
import Events from './Events';
import Food from './Food';

const PageHeader = styled.div`
  padding-bottom: 1rem;
`;

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const ItemWrapper = styled(FlexRows).attrs({ align: 'flex-start' })`
  border-radius: 4px;
  margin-right: 1rem;
  width: 30%;
`;

const Item = styled(FlexRows)`
  cursor: pointer;
  border-radius: 30px;
  padding: 0.6rem;
  margin-right: 1rem;
  width: 100%;
  :hover {
    background-color: ${({ theme: { colors }, active }) =>
      active ? colors.primaryColor : colors.lightGreyBeta};
  }
  background-color: ${({ theme: { colors }, active }) =>
    active ? colors.primaryColor : colors.lightGreyAlpha};
`;

const items = [{ name: 'Food Orders' }, { name: 'Events' }];

const DesktopView = () => {
  const [active, setActive] = React.useState('Food Orders');
  return (
    <Pager>
      <Wrapper>
        <PageHeader>
          <Text size="large" weight="semibold" paddingBottom={0.5}>
            My Orders
          </Text>
          <Text color="darkGrey" size="tiny">
            Access and maintain your Orders
          </Text>
        </PageHeader>
        <ItemWrapper justify="flex-start" align="flex-start">
          {items.map(item => (
            <Item
              key={item.name}
              active={active === item.name}
              onClick={() => setActive(item.name)}
            >
              <Text
                size="medium"
                weight="semibold"
                color={active === item.name ? 'white' : 'darkGrey'}
              >
                {item.name}
              </Text>
            </Item>
          ))}
        </ItemWrapper>
      </Wrapper>
      <Wrapper>
        {active === 'Events' && <Events title="Events" />}
        {active === 'Food Orders' && <Food title="Orders" />}
      </Wrapper>
    </Pager>
  );
};

export default DesktopView;
