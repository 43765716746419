import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Link, Element } from 'react-scroll';

import TopView from './components/desktop/TopView';
import EventDateAndTime from './components/desktop/EventDateAndTime';
import Details from './components/desktop/Details';
import Deals from './components/desktop/Deals';
import { FlexRows, Text, ScrollToTopButton, Pager } from '../../components/elements';

const Container = styled(Pager)`
  padding-top: 2rem;
`;
const Tabs = styled(FlexRows).attrs({ justify: 'flex-start' })`
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyTheta};
  position: sticky;
  top: 4rem;
  background: #ffffff;
  z-index: 200;
`;
const Item = styled.a`
  padding: 0.5rem 1rem;
  border-bottom: 2px solid
    ${({ theme: { colors }, active }) => (active ? colors.primaryColor : 'transparent')};
`;

const tabs = [
  { key: 'bookingElement', value: 'Booking' },
  { key: 'detailsElement', value: 'Details' },
  { key: 'dealsElement', value: 'Deals' },
];

const Tab = ({ item, setActive, activeTab, elements }) => {
  // useScrollPosition(
  //   ({ currPos }) => {
  //     if (currPos.y <= 150) {
  //       setActive(item.key);
  //     }
  //   },
  //   [],
  //   elements[item.key],
  // );

  return (
    <Item active={activeTab === item.key} key={item.key}>
      <Link
        to={item.key}
        spy
        smooth
        duration={500}
        offset={-120}
        isDynamic
        ignoreCancelEvents
        // onClick={() => {
        //   setActive(item.key);
        // }}
      >
        <Text
          className="is-capitalized"
          size="small"
          weight="medium"
          color={activeTab === item.key ? 'primaryColor' : 'dark'}
        >
          {item.value}
        </Text>
      </Link>
    </Item>
  );
};

const DesktopView = ({
  event,
  totalNumberOfTicket,
  placeEventOrganizeOnly,
  placeInactive,
  socials,
}) => {
  const [activeTab, setActive] = useState('bookingElement');

  const elements = {
    bookingElement: useRef(),
    detailsElement: useRef(),
    dealsElement: useRef(),
  };
  return (
    <Container>
      <TopView
        event={event}
        socials={socials}
        totalNumberOfTicket={totalNumberOfTicket}
        placeEventOrganizeOnly={placeEventOrganizeOnly}
        placeInactive={placeInactive}
      />
      {/* <Tabs>
        {tabs.map(item => (
          <Tab item={item} setActive={setActive} elements={elements} activeTab={activeTab} />
        ))}
      </Tabs>
      <br /> */}
      <Element name="bookingElement">
        <div ref={elements.bookingElement}>
          <EventDateAndTime event={event} totalNumberOfTicket={totalNumberOfTicket} />
        </div>
      </Element>
      <Element name="detailsElement">
        <div ref={elements.detailsElement}>
          <Details event={event} />
        </div>
      </Element>
      <Element name="dealsElement">
        <div ref={elements.dealsElement}>
          <Deals event={event} />
        </div>
      </Element>
      <ScrollToTopButton />
    </Container>
  );
};

export default DesktopView;
