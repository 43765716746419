import React, { useState } from 'react';
import { Icon, Button } from '../../components/elements';
import ImageCropModal from '../../components/ImageCropModal';

const ProfilePhotoUpload = ({ setFieldValue }) => {
  const [isActiveImageUploadModal, setIsActiveImageUploadModal] = useState(false);
  return (
    <div>
      <Button
        type="button"
        name="Add / Edit Photos"
        textProps={{ size: 'small', weight: 'semibold' }}
        onClick={() => setIsActiveImageUploadModal(true)}
      >
        <span className="icon is-small">
          <Icon name="photoAddSolid" />
        </span>
      </Button>
      <ImageCropModal
        isActive={isActiveImageUploadModal}
        onClose={() => setIsActiveImageUploadModal(false)}
        onChange={image => {
          setFieldValue('profile_image', image.url);
          setIsActiveImageUploadModal(false);
        }}
      />
    </div>
  );
};
export default ProfilePhotoUpload;
