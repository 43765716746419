import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Text, Icon } from '../../components/elements';

const Container = styled.div`
  margin-bottom: 1rem;
`;

const Header = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
`;
const Flex = styled(Link).attrs({ className: 'column is-half' })`
  display: flex;
  flex-direction: column;
`;

const Elements = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

const IconStyle = styled(Icon).attrs({ size: 3 })`
  padding-bottom: 1rem;
`;

const TextStyle = styled(Text).attrs({
  weight: 'semibold',
  align: 'center',
  size: 'small',
  lineSpacing: 'loose',
})``;

const Landing = () => {
  return (
    <Container>
      <Header className="columns is-gapless is-multiline is-mobile">
        <Flex to="/items">
          <Elements>
            <IconStyle name="reviewItemSolid" />
            <TextStyle>Find a dish</TextStyle>
          </Elements>
        </Flex>
        <Flex to="/places">
          <Elements>
            <IconStyle name="placesAroundMeSolid" />
            <TextStyle>Places around me</TextStyle>
          </Elements>
        </Flex>
        <Flex to="/events">
          <Elements>
            <IconStyle name="discoverEventsSolid" />
            <TextStyle>Discover events</TextStyle>
          </Elements>
        </Flex>
        <Flex to="/spot-and-share-an-item">
          <Elements>
            <IconStyle name="itemRatingGoldSolid" />
            <TextStyle>Spot, Snap & Share</TextStyle>
          </Elements>
        </Flex>
      </Header>
    </Container>
  );
};

export default Landing;
