import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: #00aeef;
`;

const DesktopView = ({ children }) => (
  <Container>
    jhbfkjdasbfjb
    {children}
  </Container>
);

export default DesktopView;
