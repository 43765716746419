import React from 'react';
import styled from 'styled-components';

const Flex = styled.div`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35vh;
  }
`;
const Empty = ({ isError }) => (
  <div className="columns is-centered">
    <Flex className="column is-12">{isError ? 'Something went wrong!' : 'No data found'}</Flex>
  </div>
);

export default Empty;
