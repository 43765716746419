import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { forEach } from 'lodash';

import { Text, FlexRows, CheckBox, Loading } from '../../../../components/elements';
import { TextArea } from '../../../../components/lib';
import YourOrderItem from './YourOrderItem';
import colors from '../../../../theme/styles/colors';
import { formatCurrency } from '../../../../utils/helpers';
import PromoCode from './ApplyPromoCode';

const Container = styled.div`
  && {
    /* position: sticky;
    top: 8.5rem; */
    .order-details {
      padding: 2rem 0rem;
    }
    /* .your-order {
      padding-top: 1rem;
    } */
    .order-detail-info {
      padding-bottom: 1rem;
    }
    .rest-name {
      margin-top: 1.5rem;
    }
    .d-flex {
      display: flex;
      justify-content: space-between;
    }
    figure {
      margin-bottom: 1rem;
    }
    .is-2by1 {
      padding-top: 40%;
      margin-top: 4rem;
    }
    span {
      color: ${({ theme: { colors: themeColors } }) => themeColors.primaryColor};
    }
  }
`;

const PlaceOrder = styled(FlexRows)`
  background-color: ${({ disabled, theme: { colors: themeColors } }) =>
    disabled ? themeColors.coolGrey : themeColors.primaryColor};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  width: 100%;
  cursor: pointer;
`;

const Bottom = styled.div`
  padding-top: 2rem;
  margin-right: 1rem;
`;

const YourOrder = ({
  order,
  dataBaseLineItem,
  loading,
  onSubmit,
  actionLoading,
  values,
  setFieldValue,
  history,
  setToMainOrderCart,
  cartUpdateLoading,
}) => {
  if (loading || cartUpdateLoading) {
    return <Loading />;
  }
  const promoDiscount =
    order.billing.sub_total + order.billing.delivery_fee - order.billing.order_total;
  const getQuantity = () => {
    let qyt = 0;
    forEach(order.line_item, item => {
      qyt += item.quantity;
    });

    return qyt;
  };
  return (
    <Container>
      <div className="d-flex justify-space-between">
        <Text weight="semibold" size="bmedium" paddingBottom="0.2" className="your-order">
          Your Order
        </Text>
        <Text
          weight="medium"
          size="tiny"
          paddingBottom="0.2"
          color="primaryColor"
          onClick={() => history.push(`/order-food/${order.service_type}/${order.place_id}`)}
          style={{ cursor: 'pointer' }}
        >
          Modify Order
        </Text>
      </div>

      <Text weight="medium" size="smaller" paddingBottom="0.2" color="darkGrey">
        From <span>{order.place.name}</span>
      </Text>
      <Text weight="regular" size="smaller">
        {getQuantity()} Items
      </Text>

      <PlaceOrder disabled={false} justify="space-between" onClick={onSubmit}>
        <Text weight="semibold" size="smedium" color="white">
          {actionLoading ? <ReactLoading type="spin" height={25} width={27} /> : 'Place Order'}
        </Text>
        <Text weight="semibold" size="smedium" color="white">
          {formatCurrency(order.billing.order_total)}
        </Text>
      </PlaceOrder>

      {order.line_item.map(item => (
        <YourOrderItem item={item} />
      ))}
      <CheckBox
        label="Please send cutlery, straws, napkins etc"
        value={values.include_cuttlery}
        labelTextProps={{ size: 'smedium', color: colors.coolGrey, weight: 'medium' }}
        onChange={value => setFieldValue('include_cuttlery', value)}
      />
      <TextArea
        placeholder="Add a note and mention anything specific such as no nuts or cross contamination, less spicy"
        style={{
          marginTop: '1rem',
          minHeight: '4rem',
          borderColor: '#3e3e3a12',
          resize: 'none',
        }}
        value={values.notes}
        onChange={e => e.target.value.length < 145 && setFieldValue('notes', e.target.value)}
        row="3"
      />
      <Text color={values.notes.length === 144 ? 'danger' : 'coolGrey'} size="micro">
        Characters left: {values.notes.length} / 144
      </Text>
      <PromoCode
        order={order}
        dataBaseLineItem={dataBaseLineItem}
        placeId={order.place_id}
        menuId={order.menu_id}
        menuOrderId={order.menu_order_id}
        setToMainOrderCart={setToMainOrderCart}
      />
      <Bottom>
        {order.billing.sub_total && order.billing.sub_total !== 0 && (
          <FlexRows justify="space-between">
            <Text weight="medium" size="smedium" paddingBottom="0.2">
              Items subtotal
            </Text>
            <Text weight="medium" size="smedium" paddingBottom="0.2">
              {formatCurrency(order.billing.sub_total)}
            </Text>
          </FlexRows>
        )}
        {order.billing.delivery_fee && order.billing.delivery_fee !== 0 && (
          <FlexRows justify="space-between">
            <Text weight="medium" size="smedium" paddingBottom="0.2">
              Delivery fee
            </Text>
            <Text weight="medium" size="smedium" paddingBottom="0.2">
              {formatCurrency(order.billing.delivery_fee)}
            </Text>
          </FlexRows>
        )}
        {promoDiscount !== 0 && (
          <FlexRows justify="space-between">
            <Text color="primaryColor" weight="semibold" size="smedium" paddingBottom="0.2">
              Promo
            </Text>
            <Text color="primaryColor" weight="semibold" size="smedium" paddingBottom="0.2">
              - {formatCurrency(promoDiscount)}
            </Text>
          </FlexRows>
        )}
        {order.billing.order_total && order.billing.order_total !== 0 && (
          <FlexRows justify="space-between">
            <Text weight="semibold" size="medium" paddingBottom="0.2">
              Total
            </Text>
            <Text weight="semibold" size="smedium" paddingBottom="0.2">
              {formatCurrency(order.billing.order_total)}
            </Text>
          </FlexRows>
        )}
      </Bottom>
    </Container>
  );
};

export default withRouter(YourOrder);
