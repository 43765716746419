import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
// import { useStoreActions, useStoreState } from 'easy-peasy';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
// import Analytics from '../../components/global/Analytics';
import ErrorMessage from '../../components/global/ErrorMessage';
import Loading from '../../components/global/Loading';

const itemQuery = gql`
  query fetch_item($itemId: String) {
    fetch_item(input: { item_id: $itemId }) {
      item_id
      place_id
      place_name
      name
      slug
      loved_percentage
      tag
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      timezone
      cuisine_style {
        name
        description
        display_order
      }
      item_type {
        name
        description
        display_order
      }
      audit {
        created_at
      }
    }
    search_social(
      input: { filter: { social_filter: { object_id: [$itemId], object_type: "ITEM" } } }
    ) {
      social_aggregation {
        total_social_per_object_id {
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const View = ({ data }) => {
  const [socials, setSocials] = useState([]);
  useEffect(() => {
    setSocials(data.search_social.social_aggregation.total_social_per_object_id);
  }, [data.search_social.social_aggregation.total_social_per_object_id]);

  return (
    <>
      <div className="is-hidden-desktop">
        <MobileView data={data} socials={socials} />
      </div>
      <div className="is-hidden-touch">
        <DesktopView data={data} socials={socials} />
      </div>
    </>
  );
};
const Item = ({ history, match }) => {
  const { itemId } = match.params;
  return (
    <Layout history={history} match={match}>
      <Query query={itemQuery} variables={{ itemId }} fetchPolicy="network-only">
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <ErrorMessage />;
          }

          return <View data={data} loading={loading} />;
        }}
      </Query>
    </Layout>
  );
};

export default Item;
