import React from 'react';
import styled from 'styled-components';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import moment from 'moment';

import { Text, Flex, ShowMoreText } from '../../../components/elements';
import { getZoneTime } from '../../../utils/eventHelpers';
import BookingButton from './BookingButton';

const Container = styled.div``;

const PageHeader = styled(Flex).attrs({ justify: 'flex-start' })`
  padding: 0.8rem 0.5rem;
  background-color: ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const Content = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const Padded = styled(Text)`
  padding-top: 0.4rem;
`;

const Wrapper = styled.div`
  padding: 0.5rem 1rem;
`;

const dealsQuery = gql`
  query deals($input: SearchInput) {
    search_place_event_voucher(input: $input) {
      total_size
      place_event_voucher_listing {
        deal_id
        name
        tagline
        description
        voucher_code
      }
    }
  }
`;

const Deals = ({ event, totalNumberOfTicket }) => {
  return (
    <Query
      query={dealsQuery}
      variables={{
        input: {
          filter: {
            place_event_voucher_filter: {
              place_id: event.place_id,
              event_id: event.event_id,
              status: 'ACTIVE',
              end_date_range: {
                start_date: getZoneTime(moment(), event.latitude, event.longitude),
              },
            },
          },
        },
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return '';
        }
        if (error) {
          return 'error';
        }
        const { search_place_event_voucher } = data;

        if (search_place_event_voucher.place_event_voucher_listing.length === 0) {
          return '';
        }

        return (
          <>
            <Container>
              <PageHeader>
                <Text className="is-uppercase" weight="semibold" size="small" lineSpacing="loose">
                  Deals & Offers
                </Text>
              </PageHeader>
              {search_place_event_voucher.place_event_voucher_listing.map(deal => (
                <Content>
                  <Text size="small" weight="semibold">
                    {deal.tagline}
                  </Text>
                  {deal.code && (
                    <Padded size="tiny" weight="medium" color="darkGrey">
                      To Claim use voucher Code- <span>{deal.code}</span>
                    </Padded>
                  )}
                  <ShowMoreText
                    size="micro"
                    weight="regular"
                    color="darkGrey"
                    content={deal.description}
                  />
                </Content>
              ))}
            </Container>
            <Wrapper>
              <BookingButton event={event} disabled={totalNumberOfTicket === 0} />
            </Wrapper>
          </>
        );
      }}
    </Query>
  );
};

export default Deals;
