import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import MobileForm from './MobileForm';

const MobileView = ({
  item,
  loading,
  history,
  onSubmit,
  itemReview,
  uploadedImages,
  isCreated,
}) => {
  const setIsActiveDesktopLoginModal = useStoreActions(
    state => state.auth.setIsActiveDesktopLoginModal,
  );
  const { isLoggedIn } = useStoreState(state => state.auth);
  return (
    <>
      {itemReview && (
        <MobileForm
          history={history}
          item={item}
          itemReview={itemReview}
          loading={loading}
          onSubmit={onSubmit}
          uploadedImages={uploadedImages}
          isCreated={isCreated}
          setIsActiveDesktopLoginModal={setIsActiveDesktopLoginModal}
          isLoggedIn={isLoggedIn}
        />
      )}
      {!itemReview && (
        <MobileForm
          history={history}
          item={item}
          itemReview={itemReview}
          loading={loading}
          onSubmit={onSubmit}
          uploadedImages={uploadedImages}
          isCreated={isCreated}
          setIsActiveDesktopLoginModal={setIsActiveDesktopLoginModal}
          isLoggedIn={isLoggedIn}
        />
      )}
    </>
  );
};

export default MobileView;
