import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import uuid from 'uuid';
import { isUndefined } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import { MapView } from '../../components/elements';
import MobileEventCard from './components/mobile/MobileEventCard';
import MobileFilterContainer from './components/mobile/MobileFilterContainer';
import MobileFilterModal from './components/mobile/MobileFilterModal';
import SortByModal from './components/mobile/SortByModal';
import EventTypeModal from './components/mobile/EventTypeModal';

import EventsCardLoader from '../../components/loader/EventCardLoader';
import Blank from '../../components/global/Blank';
import { isDesktop } from '../../utils/helpers';

const PageWrapper = styled.div`
  padding: 0rem 0rem;
`;

const getMapLocation = dta => {
  const data = dta.map(item => ({
    id: item.event_id,
    title: `${item.name}`,
    subtitle: `${item.address_line_1}, ${item.city}, ${item.state}`,
    imageObject: {
      object_id: item.event_id,
      object_type: ['EVENT'],
      parent_id: item.place_id,
      parent_type: ['PLACE'],
    },
    latitude: parseFloat(item.latitude),
    longitude: parseFloat(item.longitude),
  }));
  return data;
};

const MobileView = ({
  loading,
  data,
  filterStateInputs,
  handleMobileFilter,
  filterFuncInputs,
  fetchMoreInMobile,
}) => {
  const [events, setEvents] = useState([]);
  const [activeMap, setActiveMap] = useState(false);
  const [activeFilterModal, setActiveFilterModal] = useState(false);
  const [activeSortByModal, setActiveSortByModal] = useState(false);
  const [activeEventTypeModal, setActiveEventTypeModal] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setEvents(
      !isUndefined(data.search_events) && !isUndefined(data.search_events.event_listing)
        ? events.concat(data.search_events.event_listing)
        : [],
    );

    const totalItemLength = data.search_events.event_listing.length + events.length;

    if (data.search_events.total_size === totalItemLength) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.search_events]);

  const handleInfiniteScroll = () => {
    if (!loading && !isDesktop() && hasMore) {
      fetchMoreInMobile();
    }
  };

  // const sortByDate = useStoreState(state => state.event.sortByDate);
  // const handleSortByDate = useStoreActions(state => state.event.updateSortByDate);
  // const clearFilterInputs = useStoreActions(state => state.event.clearFilterInputs);

  const [eventCategoryInputs, setEventCategoryInputs] = useState(
    filterStateInputs.eventCategoryInputs,
  );
  const [eventTypeInputs, setEventTypeInputs] = useState(filterStateInputs.eventTypeInputs);
  const [featuresInput, setFeaturesInput] = useState(filterStateInputs.featureInputs);
  const [ageRestrictionInputs, setAgeRestrictionInputs] = useState(
    filterStateInputs.ageRestrictionInputs,
  );
  const [dressCodeInputs, setDressCodeInputs] = useState(filterStateInputs.dressCodeInputs);

  const setToMainFilter = () => {
    const finalFilter = {
      eventCategoryInputs,
      eventTypeInputs,
      featuresInput,
      ageRestrictionInputs,
      dressCodeInputs,
    };
    handleMobileFilter(finalFilter);
    setActiveFilterModal(false);
    setActiveSortByModal(false);
    setEvents([]);
    setHasMore(false);
  };

  const clearHelper = () => {
    setEventCategoryInputs([]);
    setEventTypeInputs([]);
    setFeaturesInput([]);
    setAgeRestrictionInputs([]);
    setDressCodeInputs([]);
  };

  const clearFilters = async () => {
    await clearHelper();
    setToMainFilter(true);
  };

  // Categories Filter
  const addEventCategories = category => {
    const newCategories = eventCategoryInputs.concat([category]);
    setEventCategoryInputs(newCategories);
  };

  const removeEventCategories = name => {
    const newCategories = eventCategoryInputs.filter(item => item.name !== name);
    setEventCategoryInputs(newCategories);
  };

  const addFeatures = feature => {
    const newFeatures = featuresInput.concat([feature]);
    setFeaturesInput(newFeatures);
  };

  const removeFeatures = name => {
    const newFeatures = featuresInput.filter(item => item.name !== name);
    setFeaturesInput(newFeatures);
  };

  // Types Filter
  const addEventTypes = eventType => {
    const newEventTypes = eventTypeInputs.concat([eventType]);
    setEventTypeInputs(newEventTypes);
  };

  const removeEventTypes = name => {
    const newEventTypes = eventTypeInputs.filter(item => item.name !== name);
    setEventTypeInputs(newEventTypes);
  };

  const addAgeRestriction = ageRestriction => {
    const newAgeRestriction = ageRestrictionInputs.concat([ageRestriction]);
    setAgeRestrictionInputs(newAgeRestriction);
  };

  const removeAgeRestriction = name => {
    const newAgeRestriction = ageRestrictionInputs.filter(item => item.name !== name);
    setAgeRestrictionInputs(newAgeRestriction);
  };

  const addDressCode = dressCode => {
    const newDressCode = dressCodeInputs.concat([dressCode]);
    setDressCodeInputs(newDressCode);
  };

  const removeDressCode = name => {
    const newDressCode = dressCodeInputs.filter(item => item.name !== name);
    setDressCodeInputs(newDressCode);
  };

  // const dateFilter = useStoreState(state => state.event.dateFilter);
  // const updateDateFilter = useStoreActions(state => state.event.updateDateFilter);

  return (
    <>
      <MobileFilterContainer
        filterStateInputs={filterStateInputs}
        setActiveFilterModal={setActiveFilterModal}
        setActiveMap={setActiveMap}
        activeMap={activeMap}
        setActiveSortByModal={setActiveSortByModal}
        setActiveEventTypeModal={setActiveEventTypeModal}
        handleTabDateFilter={key => {
          setEvents([]);
          setHasMore(false);
          filterFuncInputs.handleTabDateFilter(key);
        }}
      />

      <PageWrapper>
        {/* {dateFilter.name ===
           'upcoming-event' && <DatePicker handleEventDate={updateDateFilter} />} */}

        <>
          {activeMap ? (
            <MapView height="100vh" data={getMapLocation(events)} />
          ) : (
            <>
              {events.length === 0 && !loading && <Blank message="No Event found" />}
              {events.length === 0 && loading && <EventsCardLoader />}
              <InfiniteScroll
                dataLength={events.length}
                next={handleInfiniteScroll}
                hasMore={hasMore}
                loader={<EventsCardLoader />}
              >
                {events.map(event => (
                  <MobileEventCard key={uuid()} event={event} />
                ))}
              </InfiniteScroll>
            </>
          )}
        </>
      </PageWrapper>
      <EventTypeModal
        isActive={activeEventTypeModal}
        onCloseModal={() => setActiveEventTypeModal(false)}
        typeInputs={eventTypeInputs}
        addType={addEventTypes}
        removeType={removeEventTypes}
        done={() => {
          setToMainFilter();
          setActiveEventTypeModal(false);
        }}
      />
      <SortByModal isActive={activeSortByModal} setActiveSortByModal={setActiveSortByModal} />

      <MobileFilterModal
        isActive={activeFilterModal}
        // features
        featuresInput={featuresInput}
        addFeatures={addFeatures}
        removeFeatures={removeFeatures}
        // eventCategories
        eventCategoryInputs={eventCategoryInputs}
        addEventCategories={addEventCategories}
        removeEventCategories={removeEventCategories}
        // eventTypes
        eventTypeInputs={eventTypeInputs}
        addEventTypes={addEventTypes}
        removeEventTypes={removeEventTypes}
        // age rest
        ageRestrictionInputs={ageRestrictionInputs}
        addAgeRestriction={addAgeRestriction}
        removeAgeRestriction={removeAgeRestriction}
        // dress code
        dressCodeInputs={dressCodeInputs}
        addDressCode={addDressCode}
        removeDressCode={removeDressCode}
        // other
        setActiveFilterModal={setActiveFilterModal}
        setToMainFilter={setToMainFilter}
        clearFilters={clearFilters}
      />
    </>
  );
};
export default MobileView;
