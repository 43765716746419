/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { forEach } from 'lodash';
import styled from 'styled-components';

import {
  getPlaceTypes,
  getCuisines,
  getFeatures,
  getServiceCategory,
  getGoodFor,
} from '../../utils/fetchStatic';

import { Text, TagInputs, InputErrorMessage } from '../../components/elements';
import colors from '../../theme/styles/colors';
import font from '../../theme/styles/fonts';

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const Span = styled.span`
  color: red;
`;

const AdditionalText = styled.span`
  &&& {
    color: ${colors.coolGrey};
    font-size: ${font.size.tiny};
  }
`;

const parseInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

const Label = styled(Text).attrs({
  className: 'is-capitalized',
  color: 'darkGrey',
  size: 'small',
  weight: 'semibold',
})``;

const Tags = ({ values, setFieldValue, touched, errors }) => {
  const [data, setData] = useState([{ businessTypes: [], cuisines: [] }]);

  useEffect(() => {
    const getData = async () => {
      const businessTypes = await getPlaceTypes();
      const serviceTypes = await getServiceCategory();
      const featureTypes = await getFeatures();
      const cuisines = await getCuisines();
      const goodFor = await getGoodFor();

      setData({
        businessTypes,
        serviceTypes,
        featureTypes,
        cuisines,
        goodFor,
      });
    };
    getData();
  }, []);
  return (
    <>
      <div className="columns is-multiline">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <TagInputs
              label={
                <Label paddingBottom={0.5}>
                  Business Type <AdditionalText>( Select upto 2 Business Type )</AdditionalText>{' '}
                  <Span>*</Span>
                </Label>
              }
              value={parseData(values.business_types)}
              suggestions={values.business_types.length === 2 ? [] : parseData(data.businessTypes)}
              onChange={value => setFieldValue('business_types', parseInput(value))}
            />
            <InputErrorMessage touched={touched.business_types} errors={errors.business_types} />
          </div>
        </div>
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <TagInputs
              label={
                <Label paddingBottom={0.5}>
                  Services{' '}
                  <AdditionalText>( Select the applicable service at this place )</AdditionalText>{' '}
                  <Span>*</Span>
                </Label>
              }
              value={parseData(values.service_category)}
              suggestions={parseData(data.serviceTypes)}
              onChange={value => setFieldValue('service_category', parseInput(value))}
            />
            <InputErrorMessage
              touched={touched.service_category}
              errors={errors.service_category}
            />
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <TagInputs
              label={
                <Label paddingBottom={0.5}>
                  Features{' '}
                  <AdditionalText>
                    ( Select all the feature available at this place )
                  </AdditionalText>
                </Label>
              }
              value={parseData(values.feature_types)}
              suggestions={parseData(data.featureTypes)}
              onChange={value => setFieldValue('feature_types', parseInput(value))}
            />
          </div>
        </div>
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <TagInputs
              label={
                <Label paddingBottom={0.5}>
                  Cuisines{' '}
                  <AdditionalText>( Select the applicable cuisines at this place )</AdditionalText>{' '}
                  <Span>*</Span>
                </Label>
              }
              value={parseData(values.cuisine_style)}
              suggestions={parseData(data.cuisines)}
              onChange={value => setFieldValue('cuisine_style', parseInput(value))}
            />
            <InputErrorMessage touched={touched.cuisine_style} errors={errors.cuisine_style} />
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-two-thirds">
          <div className="field">
            <TagInputs
              label={
                <Label paddingBottom={0.5}>
                  What&apos;s good about this place{' '}
                  <AdditionalText>
                    ( Describe this place, characteristic, specialties etc.)
                  </AdditionalText>
                </Label>
              }
              value={parseData(values.good_for)}
              suggestions={parseData(data.goodFor)}
              onChange={value => setFieldValue('good_for', parseInput(value))}
            />
            <InputErrorMessage touched={touched.good_for} errors={errors.good_for} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Tags;
