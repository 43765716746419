import React from 'react';

import MobileLoading from '../../components/global/mobile/MobileLoading';
import PageHeader from './components/mobile/PageHeader';

import Information from './components/mobile/Information';
import Insights from './components/mobile/Insights';
import Deals from './components/mobile/Deals';
import Events from './components/mobile/Events';

const MobileView = ({ loading, data }) => {
  const [tab, setTab] = React.useState('info');
  if (loading) {
    return <MobileLoading />;
  }
  const place = data.fetch_place;

  return (
    <div>
      <PageHeader tab={tab} setTab={setTab} />
      {(tab === 'info' || tab === 'insights') && (
        <Information place={place} onlyPageHeader={tab === 'insights'} />
      )}
      {tab === 'insights' && <Insights place={place} />}
      {tab === 'deals' && <Deals place={place} />}
      {tab === 'events' && <Events place={place} />}
    </div>
  );
};

export default MobileView;
