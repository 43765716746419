import React from 'react';
import styled from 'styled-components';
import { isNull, isUndefined } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Text, Avatar } from '../../../components/elements';

const PlaceContainer = styled.article`
  padding: 1rem 0rem;
  justify-content: center;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const Event = ({ event, image }) => {
  if (isUndefined(event) || isNull(event)) {
    return null;
  }
  return (
    <div className="column is-6">
      <Link to={`/event/${event.event_id}`}>
        <PlaceContainer className="media">
          <figure className="media-left">
            <p className="image is-96x96">
              <Avatar
                name={event.name}
                image={image && image.url}
                maskProps={{ width: 96, height: 96 }}
              />
            </p>
          </figure>
          <div className="media-content">
            <div>
              <Text size="tiny" weight="semibold">
                {moment(event.start).format('LLLL')}
              </Text>
              <Text size="small" weight="semibold">
                {event.name}
              </Text>
              <Text color="darkGrey" size="tiny">
                {event.place_name}
              </Text>
              <Text color="darkGrey" size="tiny">
                {event.city}, {event.state}, {event.country} {event.post_code}
              </Text>
            </div>
          </div>
          <div className="media-right" />
        </PlaceContainer>
      </Link>
    </div>
  );
};

export default Event;
