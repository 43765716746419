import React from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { useStoreState } from 'easy-peasy';
import gql from 'graphql-tag';
import { MdChevronLeft } from 'react-icons/md';
import { Modal, SearchInput, Text, FlexRows, FlexColumns } from '../../../../components/elements';
import GetImages from '../../../../components/GetImages';
import { getPlaceTagline } from '../../../../utils/helpers';

import client from '../../../../utils/apolloClient';
import Loading from '../../../../components/global/mobile/MobileLoading';
import AddPlaceModal from './AddPlaceModal';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const ModalHeaderLeftWrapper = styled.div`
  position: absolute;
  left: 0.5rem;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const TextWrapper = styled.div`
  padding-left: 1rem;
`;

const ItemWrapper = styled(FlexRows)`
  padding: 0.5rem;
`;

const AddPlaceWrapper = styled(FlexColumns)`
  &&& {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
    padding: 1rem 0rem;
  }
`;

const Item = styled(ItemWrapper)`
  &&& {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  }
`;

const RecentContainer = styled.div`
  margin-top: 1rem;
`;

const RecentViewHeader = styled.div`
  background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha};
  padding: 0.5rem 0.8rem;
`;

const InputWrapper = styled.div`
  width: 100%;
  padding: 1rem 0.5rem;
`;

const searchPlacesQuery = gql`
  query searchPlaceQuery($placeName: String, $location: String) {
    search_places(
      input: {
        location: $location
        search_criteria: $placeName
        filter: {
          place_filter: { status: "ACTIVE", listing_status: "APPROVED", event_business: false }
        }
      }
    ) {
      place_listing {
        place_id
        name
        tagline
        cuisine_style {
          name
          description
        }
        business_type {
          name
          description
        }
        address_line_1
        address_line_2
        city
        state
        country
        post_code
      }
    }
  }
`;

const PlaceSuggestionItem = ({ place, onSelect }) => (
  <a onClick={onSelect}>
    <Item justify="flex-start" align="flex-start">
      <GetImages
        name={place.name}
        maskProps={{ width: 50, height: 50 }}
        variables={{
          object_type: ['PLACE'],
          object_id: place.place_id,
          links_to_id: [place.place_id],
          links_to_type: ['PLACE'],
        }}
      />
      <TextWrapper>
        <Text className="is-capitalized" size="small">
          {capitalize(place.name)}
        </Text>
        <Text size="micro" color="darkGrey">
          {getPlaceTagline(place.business_type, place.cuisine_style)}
        </Text>
      </TextWrapper>
    </Item>
  </a>
);

const MobileSearchModal = ({ isActive, onClose, onSelectPlace }) => {
  const recentPlaceViews = useStoreState(state => state.place.recentViews);
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const [places, setPlaces] = React.useState([]);

  const [placeQueryTag, setPlaceQueryTag] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [isActiveAddPlaceModal, setIsActiveAddPlaceModal] = React.useState(false);

  const onSuggestionsFetchPlaceRequested = value => {
    setPlaceQueryTag(value);
    setLoading(true);
    client.clientPublic
      .query({
        query: searchPlacesQuery,
        variables: {
          placeName: placeQueryTag,
          location: `${usedLocation.latitude},${usedLocation.longitude}`,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        setPlaces(data.search_places.place_listing);
        setLoading(false);
      })
      .catch(error => {
        console.log('error', error);
        setLoading(false);
      });
  };

  return (
    <Modal isActive={isActive}>
      <ModalHeader>
        <ModalHeaderLeftWrapper>
          <a onClick={onClose}>
            <Text color="white">
              <MdChevronLeft size={30} />
            </Text>
          </a>
        </ModalHeaderLeftWrapper>
        <FlexRows>
          <Text className="is-uppercase" weight="semibold" size="lmedium" color="white">
            Place Name
          </Text>
        </FlexRows>
      </ModalHeader>
      <InputWrapper>
        <SearchInput
          placeholder="Search by typing place name"
          value={placeQueryTag}
          onChange={event => onSuggestionsFetchPlaceRequested(event.target.value)}
          onClear={() => {
            setPlaces([]);
            setPlaceQueryTag('');
          }}
        />
      </InputWrapper>
      <Content>
        <a onClick={() => setIsActiveAddPlaceModal(true)}>
          <AddPlaceWrapper>
            <Text className="is-capitalized" size="small" weight="semibold">
              Be the first to share the place
            </Text>
            <Text className="is-uppercase" size="smaller" color="primaryColor" weight="semibold">
              Would You Like to add ?
            </Text>
          </AddPlaceWrapper>
        </a>
        {loading ? (
          <Loading />
        ) : (
          <>
            {places.map(place => (
              <PlaceSuggestionItem place={place} onSelect={() => onSelectPlace(place, 'existed')} />
            ))}

            {recentPlaceViews.length !== 0 && (
              <RecentContainer>
                <RecentViewHeader>
                  <Text className="is-uppercase" color="primaryColor" weight="bold">
                    Recently Viewed
                  </Text>
                </RecentViewHeader>
                {recentPlaceViews.map(place => (
                  <PlaceSuggestionItem
                    place={place}
                    onSelect={() => onSelectPlace(place, 'existed')}
                  />
                ))}
              </RecentContainer>
            )}
          </>
        )}
      </Content>
      <AddPlaceModal
        isActive={isActiveAddPlaceModal}
        onClose={() => setIsActiveAddPlaceModal(false)}
        onSubmit={place => {
          setIsActiveAddPlaceModal(false);
          onSelectPlace(place, 'new');
        }}
      />
    </Modal>
  );
};

export default MobileSearchModal;
