import React from 'react';
import styled from 'styled-components';
import { FlexRows, Text } from '../../../components/elements';
import RatingAction from './RatingAction';

const Container = styled(FlexRows)``;

const Ingredient = ({ order, item, isMobile }) => {
  return (
    <Container justify="space-between" isMobile={isMobile}>
      <Text size="small" weight="regular">
        {item.quantity} X {item.name}
      </Text>
      {order.status === 'COMPLETE' && <RatingAction order={order} item={item} />}
    </Container>
  );
};

export default Ingredient;
