import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';

import client from '../../utils/apolloClient';

const searchPlacesQuery = gql`
  query searchPlaceQuery($input: SearchInput) {
    search_places(input: $input) {
      place_listing {
        place_id
        name
        tagline
        cuisine_style {
          name
          description
        }
        business_type {
          name
          description
        }
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        default_image_url
      }
    }
  }
`;

const PlaceSearchForReview = ({ history, match }) => {
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const [placeQueryTag, setPlaceQueryTag] = useState('');
  const [location, setLocation] = useState(`${usedLocation.latitude},${usedLocation.longitude}`);
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const recentPlaceViews = useStoreState(state => state.place.recentViews);
  const recentLocationViews = useStoreState(state => state.global.recentViewLocations);
  // const searchVariable = location.search;
  // console.log('location', searchVariable);

  const input = {};
  Object.assign(input, location !== null && { size: 30, location }, {
    search_criteria: placeQueryTag,
    filter: {
      place_filter: {
        status: 'ACTIVE',
        listing_status: 'APPROVED',
        event_business: false,
      },
    },
  });

  const onSuggestionsFetchRequested = () => {
    setIsError(null);
    setLoading(true);
    if (placeQueryTag.length === 0) {
      setPlaces([]);
      setLoading(false);
    } else if (placeQueryTag.length < 2) {
      setLoading(false);
      setIsError('Please enter atleast 2 characters');
    } else
      client.clientPublic
        .query({
          query: searchPlacesQuery,
          variables: {
            input,
          },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          setPlaces(data.search_places.place_listing);
          setLoading(false);
        })
        .catch(error => {
          console.log('error', error);
          setLoading(false);
        });
  };

  const onMobileSuggestionsFetchRequested = value => {
    setLoading(true);
    client.clientPublic
      .query({
        query: searchPlacesQuery,
        variables: { placeName: value, location },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        setPlaces(data.search_places.place_listing);
        setLoading(false);
      })
      .catch(error => {
        console.log('error', error);
        setLoading(false);
      });
  };
  return (
    <Layout history={history} match={match}>
      <div className="is-hidden-desktop">
        <MobileView
          placeLoading={loading}
          places={places}
          placeQueryTag={placeQueryTag}
          setPlaceQueryTag={setPlaceQueryTag}
          onSuggestionsFetchRequested={onMobileSuggestionsFetchRequested}
          recentPlaceViews={recentPlaceViews}
          recentLocationViews={recentLocationViews}
          onSelectLocation={setLocation}
          usedLocation={usedLocation}
        />
      </div>
      <div className="is-hidden-touch">
        <DesktopView
          loading={loading}
          places={places}
          placeQueryTag={placeQueryTag}
          setPlaceQueryTag={setPlaceQueryTag}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          recentViews={recentPlaceViews}
          setLocation={setLocation}
          isError={isError}
        />
      </div>
    </Layout>
  );
};

export default PlaceSearchForReview;
