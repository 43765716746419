import gql from 'graphql-tag';
import { forEach, groupBy, map, sortBy, trim } from 'lodash';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';
import client from '../../utils/apolloClient';
import { OrderFragments } from '../../utils/fragments';

export const parseOrderDataForEdit = ({ modifiersGroups, modifiersOrderGroups }) => {
  const data = [];
  forEach(modifiersGroups, item => {
    const temp = [];
    const orderGroupArray = modifiersOrderGroups.filter(
      edge => edge.modifier_group_id === item.modifier_group_id,
    );
    forEach(item.modifier, item2 => {
      const orderGroupModifierArray =
        orderGroupArray.length !== 0
          ? orderGroupArray[0].modifiers.filter(
              edge => edge.menu_item.menu_item_id === item2.menu_item_id,
            )
          : [];
      const final = { ...item2, isSelected: orderGroupModifierArray.length !== 0 };
      if (orderGroupModifierArray.length !== 0 && orderGroupModifierArray[0].selectedVariant) {
        const variants =
          item2.variant && item2.variant.length !== 0 ? item2.variant[0].variants : null;
        const temp2 = variants.map(edge => ({
          ...edge,
          isSelected: edge.variant_id === orderGroupModifierArray[0].selectedVariant.variant_id,
        }));
        Object.assign(final, {
          selectedVariant: [{ ...item2.variant[0], variants: temp2 }],
          variant: [{ ...item2.variant[0], variants: temp2 }],
        });
      }
      temp.push(final);
    });
    data.push({ ...item, modifier: temp });
  });
  return data;
};

export const parseFormValuesWhileSubmission = values => {
  const data = {
    quantity: values.quantity,
    special_request: trim(values.special_request),
    sold_out: values.sold_out,
  };
  const { itemVariant } = values;
  Object.assign(
    data,
    itemVariant && {
      prompt_id: itemVariant.prompt_id,
      variant_id: itemVariant.variant_id,
    },
  );
  const modifierData = [];
  forEach(values.groups, group => {
    forEach(group.modifier, modifier => {
      if (modifier.isSelected && modifier.isSelected === true) {
        const custom = {
          modifier_group_id: group.modifier_group_id,
          menu_item_id: modifier.menu_item_id,
          quantity: 1,
        };
        if (modifier.selectedVariant && modifier.selectedVariant.length !== 0) {
          const variant = modifier.selectedVariant[0];
          const variants = variant.variants.filter(item => item.isSelected === true);
          Object.assign(custom, {
            prompt_id: variant.prompt_id,
            variant_id: variants[0].variant_id,
          });
        }
        modifierData.push(custom);
      }
    });
  });
  Object.assign(data, {
    modifier: modifierData,
  });

  return data;
};

export const calculateItemPrice = (variant, itemPrice) => {
  let price = `$${parseFloat(itemPrice).toFixed(2)}`;
  forEach(variant, item => {
    if (item.variants && item.variants.length !== 0) {
      const sort = sortBy(item.variants, 'variant_price');
      price = `$${parseFloat(sort[0].variant_price).toFixed(2)} +`;
    }
  });
  return price;
};

export const parseLinkOverrideModifiersIntoGroups = modifiers => {
  const data = [];
  if (modifiers && modifiers.length !== 0) {
    const groups = groupBy(modifiers, 'modifier_group_id');
    map(groups, item => {
      data.push({ modifier_group_id: item[0].modifier_group_id, modifiers: item });
    });
  }
  return data;
};

const checkUnavailableDates = (date, location) => {
  if (date && date.length !== 0 && date[0].end_date_time) {
    const time_zone = tzLookup(parseFloat(location.latitude), parseFloat(location.longitude));
    const dateNow = moment().tz(time_zone);
    const endDate = moment(date[0].end_date_time).tz(time_zone);
    if (moment(endDate).isAfter(dateNow)) {
      return `Available on ${moment(endDate).format('D MMM')}`;
    }
    return null;
  }
  return null;
};

export const finalModifierGroupModifiers = ({ modifiers, linkModifiers, location }) => {
  const data = [];
  forEach(modifiers, item => {
    const linkModifierItem = linkModifiers.filter(
      edge => edge.menu_item_id === item.menu_item.menu_item_id,
    );

    const newModifier = {
      menu_item_id: item.menu_item.menu_item_id,
      name: item.menu_item.name,
    };
    if (linkModifierItem.length !== 0 && linkModifierItem[0].override_price) {
      newModifier.price = linkModifierItem[0].override_price;
    } else if (item.override_price) {
      newModifier.price = item.override_price;
    } else {
      newModifier.price = item.menu_item.price;
    }
    if (
      linkModifierItem.length !== 0 &&
      linkModifierItem[0].availability_status &&
      item.menu_item.availability_status === 'AVAILABLE'
    ) {
      newModifier.availability_status = linkModifierItem[0].availability_status;
    } else {
      newModifier.availability_status = item.menu_item.availability_status;
    }
    if (
      linkModifierItem.length !== 0 &&
      linkModifierItem[0].un_available_dates &&
      linkModifierItem[0].un_available_dates !== 0
    ) {
      newModifier.un_available_dates = checkUnavailableDates(
        linkModifierItem[0].un_available_dates,
        location,
      );
    } else {
      newModifier.un_available_dates = checkUnavailableDates(
        item.menu_item.un_available_dates,
        location,
      );
    }
    const temp = [];
    forEach(item.menu_item.variant, item2 => {
      const temp2 = [];
      const linkModifierItemVariant =
        linkModifierItem.length !== 0 &&
        linkModifierItem[0].override_variant &&
        linkModifierItem[0].override_variant.length !== 0
          ? linkModifierItem[0].override_variant.filter(
              edge3 => edge3.prompt_id === item2.prompt_id,
            )
          : [];
      const modifierItemVariant =
        item.override_variant && item.override_variant !== 0
          ? item.override_variant.filter(edge3 => edge3.prompt_id === item2.prompt_id)
          : [];
      forEach(item2.variants, item3 => {
        const linkModifierItemVariants =
          linkModifierItemVariant.length !== 0 &&
          linkModifierItemVariant[0].variants &&
          linkModifierItemVariant[0].variants.length !== 0
            ? linkModifierItemVariant[0].variants.filter(
                edge3 => edge3.variant_id === item3.variant_id,
              )
            : [];
        const modifierItemVariants =
          modifierItemVariant.length !== 0 && modifierItemVariant[0].variants !== 0
            ? modifierItemVariant[0].variants.filter(edge3 => edge3.variant_id === item3.variant_id)
            : [];
        const linkModifierItemPrice =
          linkModifierItemVariants.length !== 0 ? linkModifierItemVariants[0].variant_price : null;
        const modifierItemPrice =
          modifierItemVariants.length !== 0 ? modifierItemVariants[0].variant_price : null;
        const linkAvailabilityStatus =
          linkModifierItemVariants.length !== 0
            ? linkModifierItemVariants[0].availability_status
            : null;
        const linkUnAvailableDates =
          linkModifierItemVariants.length !== 0
            ? linkModifierItemVariants[0].un_available_dates
            : null;

        const newVariant = {
          variant_id: item3.variant_id,
          variant_name: item3.variant_name,
          variant_price: linkModifierItemPrice || modifierItemPrice || item3.variant_price,
          availability_status:
            linkAvailabilityStatus && item3.availability_status === 'AVAILABLE'
              ? linkAvailabilityStatus
              : item3.availability_status,
          un_available_dates:
            linkUnAvailableDates && linkUnAvailableDates.length !== 0
              ? checkUnavailableDates(linkUnAvailableDates, location)
              : checkUnavailableDates(item3.un_available_dates, location),
        };
        temp2.push(newVariant);
      });
      temp.push({
        prompt_id: item2.prompt_id,
        prompt: item2.prompt,
        variants: temp2,
      });
      newModifier.variant = temp;
    });
    data.push(newModifier);
  });
  return data;
};

export const finalLinkListingItemDataParser = ({ link, location }) => {
  const data = {
    item_link_id: link.item_link_id,
    menu_item_id: link.menu_item.menu_item_id,
    item_id: link.menu_item.item_id,
    name: link.menu_item.name,
    image: link.menu_item.image_url,
    description: link.menu_item.description,
    modifier_groups_id: link.menu_item.modifier_group_id,
    linkModifierGroup: link.modifier,
    allow_item_review: link.menu_item.allow_item_review,
  };
  if (link.price) {
    data.price = link.price;
  } else {
    data.price = link.menu_item.price;
  }
  if (link.availability_status && link.menu_item.availability_status === 'AVAILABLE') {
    data.availability_status = link.availability_status;
  } else {
    data.availability_status = link.menu_item.availability_status;
  }

  if (link.un_available_dates && link.un_available_dates.length !== 0) {
    data.un_available_dates = checkUnavailableDates(link.un_available_dates, location);
  } else {
    data.un_available_dates = link.menu_item.un_available_dates;
  }

  const temp = [];
  forEach(link.menu_item.variant, item => {
    const linkVariant = link.variant
      ? link.variant.filter(edge => edge.prompt_id === item.prompt_id)
      : [];
    const temp2 = [];
    forEach(item.variants, item2 => {
      const linkVariants =
        linkVariant.length !== 0
          ? linkVariant[0].variants.filter(edge2 => edge2.variant_id === item2.variant_id)
          : [];
      const linkVariantPrice = linkVariants.length !== 0 ? linkVariants[0].variant_price : null;
      const linkAvailabilityStatus =
        linkVariants.length !== 0 ? linkVariants[0].availability_status : null;
      const linkUnAvailableDates =
        linkVariants.length !== 0 ? linkVariants[0].un_available_dates : null;

      const newVariant = {
        variant_id: item2.variant_id,
        variant_name: item2.variant_name,
        variant_price: linkVariantPrice || item2.variant_price,
        availability_status:
          linkAvailabilityStatus && item2.availability_status === 'AVAILABLE'
            ? linkAvailabilityStatus
            : item2.availability_status,
        un_available_dates:
          linkUnAvailableDates && linkUnAvailableDates.length !== 0
            ? checkUnavailableDates(linkUnAvailableDates, location)
            : item2.un_available_dates,
      };
      temp2.push(newVariant);
    });
    temp.push({
      prompt_id: item.prompt_id,
      prompt: item.prompt,
      variants: temp2,
    });
  });

  data.variant = temp;
  return data;
};

export const checkCurrentOrderOrNewOrder = (previousOrderIds, currentOrderIds) => {
  let newCart = false;
  if (
    previousOrderIds.place_id !== currentOrderIds.place_id ||
    previousOrderIds.service_type_setting_id !== currentOrderIds.service_type_setting_id ||
    previousOrderIds.menu_id !== currentOrderIds.menu_id
  ) {
    newCart = true;
  }
  return newCart;
};

export const mergeMenuItemAndLinkVariant = (variantFromLink, variant) => {
  let data;
  if (variantFromLink && variantFromLink.variants) {
    const variants = [];
    forEach(variantFromLink.variants, item => {
      const itemVariant =
        variant && variant.variants
          ? variant.variants.filter(item2 => item2.variant_id === item.variant_id)
          : [];
      variants.push({
        variant_id: item.variant_id,
        variant_name:
          itemVariant.length !== 0 ? itemVariant[0].variant_name : 'variant_id is not a same',
        variant_price: item.variant_price,
        availability_status: item.availability_status,
      });
    });

    data = {
      prompt_id: variant && variant.prompt_id,
      prompt: variant && variant.prompt,
      variants,
    };
  } else {
    data = variant;
  }
  return data;
};

export const validateAddOrder = (menuItem, values) => {
  let isValid = true;
  if (menuItem && menuItem.variant && menuItem.variant.length !== 0) {
    forEach(menuItem.variant, item => {
      forEach(item.variants, () => {
        if (!values.itemVariant) {
          isValid = false;
        }
      });
    });
  }

  forEach(values.groups, item => {
    forEach(item.modifier, item2 => {
      if (item2.variant && item2.variant.length !== 0) {
        if (item2.isSelected) {
          const variant =
            item2.selectedVariant && item2.selectedVariant.length !== 0
              ? item2.selectedVariant[0]
              : null;
          const variants = variant
            ? variant.variants.filter(item3 => item3.isSelected === true)
            : [];
          if (variants.length === 0) {
            isValid = false;
          }
        }
      }
    });

    const filter = item.modifier.filter(edge => edge.isSelected === true);
    if (item.is_required) {
      if (filter.length === 0) {
        isValid = false;
      }
      if (item.selection_type === 'CUSTOM') {
        if (filter.length < item.selection_quantity) {
          isValid = false;
        }
      } else if (filter.length < item.selection_quantity_from) {
        isValid = false;
      }
    } else if (filter.length !== 0 && item.selection_type === 'RANGE') {
      if (filter.length < item.selection_quantity_from) {
        isValid = false;
      }
    }
    // if (item.is_required) {
    //   if (filter.length === 0) {
    //     isValid = false;
    //   }
    //   if (item.selection_quantity_from === item.selection_quantity_to) {
    //     if (filter.length < item.selection_quantity_from) {
    //       isValid = false;
    //     }
    //   }
    //   if (
    //     item.selection_quantity_from !== item.selection_quantity_to &&
    //     filter.length < item.selection_quantity_from
    //   ) {
    //     isValid = false;
    //   }
    // } else if (filter.length !== 0) {
    //   if (
    //     item.selection_quantity_from !== item.selection_quantity_to &&
    //     filter.length < item.selection_quantity_from
    //   ) {
    //     isValid = false;
    //   }
    // }
  });

  return isValid;
};
export const parseOrderSelectionInputItem = (item, userId) => {
  const data = {
    item_link_id: item.item_link_id,
    menu_item_id: item.menu_item_id,
    quantity: item.quantity,
    special_request: item.special_request,
    user_id: userId,
    // caption: item,
  };

  Object.assign(
    data,
    item.selectedVariant && {
      variant_id: item.selectedVariant.variant_id,
      prompt_id: item.selectedVariant.prompt_id,
    },
  );

  const modifierData = [];
  forEach(item.groups, group => {
    forEach(group.modifiers, modifier => {
      const custom = {
        modifier_group_id: group.modifier_group_id,
        menu_item_id: modifier.menu_item.menu_item_id,
        quantity: 1,
      };
      Object.assign(
        custom,
        modifier.selectedVariant && {
          prompt_id: modifier.selectedVariant.prompt_id,
          variant_id: modifier.selectedVariant.variant_id,
        },
      );
      modifierData.push(custom);
    });
  });

  Object.assign(data, {
    modifier: modifierData,
  });

  return data;
};

export const parseOrderDatabaseInputItemsWhileSubmissionAgain = items => {
  const data = [];
  forEach(items, item => {
    const modifier = [];
    forEach(item.modifier, item2 => {
      const final = {
        modifier_group_id: item2.modifier_group_id,
        menu_item_id: item2.menu_item_id,
        prompt_id: item2.prompt_id,
        variant_id: item2.variant_id,
        quantity: item2.quantity,
      };
      modifier.push(final);
    });
    const final = {
      item_link_id: item.item_link_id,
      menu_item_id: item.menu_item_id,
      variant_id: item.variant_id,
      prompt_id: item.prompt_id,
      modifier,
      quantity: item.quantity,
      menu_item_rating: item.menu_item_rating,
      special_request: item.special_request,
      user_id: item.user_id,
      caption: item.caption,
      private: item.private,
    };
    data.push(final);
  });
  return data;
};

export const parseOrderDatabaseInputItems = items => {
  const data = [];
  forEach(items, item => {
    let selectedVariant = null;
    if (item.variant_id) {
      selectedVariant = {
        variant_id: item.variant_id,
        variant_name: item.variant_name,
        variant_price: item.price_per_item,
        prompt_id: item.prompt_id,
      };
    }

    const groupsTemp = [];
    const groups = groupBy(item.modifier, 'modifier_group_id');

    map(groups, item2 => {
      const modifiersTemp = [];
      forEach(item2, item3 => {
        let selectedVariant2 = null;
        if (item3.variant_id) {
          selectedVariant2 = {
            prompt_id: item3.prompt_id,
            variant_id: item3.variant_id,
            variant_name: item3.variant_name,
            variant_price: item3.price_per_item,
          };
        }
        modifiersTemp.push({
          menu_item: { menu_item_id: item3.menu_item_id, name: item3.name },
          override_price: item3.price_per_item,
          override_variant: [],
          selectedVariant: selectedVariant2,
          ...item3,
        });
      });

      const group = { modifier_group_id: item2[0].modifier_group_id, modifiers: modifiersTemp };
      groupsTemp.push(group);
    });

    const newItem = {
      id: item.id,
      displayOrder: item.displayOrder,
      item_link_id: item.item_link_id,
      menu_item_id: item.menu_item_id,
      quantity: item.quantity,
      name: item.name,
      price_per_item: item.price_per_item,
      tax: item.tax,
      net_price_per_item: item.net_price_per_item,
      price: item.price,
      overall_price: item.overall_price,
      special_request: item.special_request,
      selectedVariant,
      groups: groupsTemp,
    };
    data.push(newItem);
  });
  return data;
};

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menu(input: $input) {
      menu_listing {
        place_id
        service_type_setting {
          service_type_setting_id
        }
        service_type
        menu_id
        name
        internal_name
        slug
        description
        menu_hour {
          day
          is_active
          option {
            type
            start
            end
            start_in_sec
            end_in_sec
          }
        }
        stopped_hour {
          start
          end
        }
        holiday_hour {
          day
          is_active
          option {
            type
            start
            end
            start_in_sec
            end_in_sec
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getMenus = ({ placeId, serviceType }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchMenusQuery,
        variables: {
          input: {
            filter: {
              menu_filter: {
                place_id: placeId,
                service_type: serviceType,
                status: 'ACTIVE',
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menu && data.search_menu.menu_listing) {
          resolve(data.search_menu.menu_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchSectionsQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menu_section(input: $input) {
      menu_section_listing {
        menu_section_id
        name
        menu_id
        display_order
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getSections = ({ menuId, placeId }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchSectionsQuery,
        variables: {
          input: {
            size: 30,
            filter: { menu_section_filter: { menu_id: menuId, place_id: placeId } },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menu_section && data.search_menu_section.menu_section_listing) {
          resolve(sortBy(data.search_menu_section.menu_section_listing, 'display_order'));
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchSectionsAndLinksQuery = gql`
  query searchMenus($input: SearchInput, $linkInput: SearchInput) {
    search_menu_section(input: $input) {
      menu_section_listing {
        menu_section_id
        name
        menu_id
        display_order
      }
    }
    search_menu_item_links(input: $linkInput) {
      menu_item_link_listing {
        item_link_id
        display_order
        un_available_dates {
          start_date_time
          end_date_time
        }
        availability_status
        price
        variant {
          prompt
          prompt_id
          variants {
            variant_id
            variant_price
            availability_status
            un_available_dates {
              start_date_time
              end_date_time
            }
          }
        }
        modifier {
          modifier_group_id
          menu_item_id
          menu_item {
            menu_item_id
          }
          override_price
          override_variant {
            prompt_id
            prompt
            variants {
              variant_id
              variant_price
              availability_status
              un_available_dates {
                start_date_time
                end_date_time
              }
            }
          }
          availability_status
          un_available_dates {
            start_date_time
            end_date_time
          }
          min_qty
          max_qty
          display_order
        }
        menu_section {
          menu_section_id
        }
        menu_item {
          menu_item_id
          name
          price
          image_url
          item_id
          description
          calories
          kilojoules
          popular
          allow_item_review
          availability_status
          modifier_group_id
          un_available_dates {
            start_date_time
            end_date_time
          }
          variant {
            prompt
            prompt_id
            variants {
              variant_id
              variant_name
              variant_price
              availability_status
              un_available_dates {
                start_date_time
                end_date_time
              }
            }
          }
        }
      }
    }
  }
`;

export const getSectionsAndLinks = ({ menuId, placeId, query }) =>
  new Promise((resolve, reject) => {
    const menu_item_link_filter = {};
    Object.assign(menu_item_link_filter, menuId && { menu_id: menuId });
    client.clientPublic
      .query({
        query: searchSectionsAndLinksQuery,
        variables: {
          input: {
            size: 30,
            filter: { menu_section_filter: { menu_id: menuId, place_id: placeId } },
          },
          linkInput: {
            size: 200,
            search_criteria: query || '',
            filter: { menu_item_link_filter },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_section &&
          data.search_menu_item_links &&
          data.search_menu_section.menu_section_listing &&
          data.search_menu_item_links.menu_item_link_listing
        ) {
          resolve({
            sections: sortBy(data.search_menu_section.menu_section_listing, 'display_order'),
            links: data.search_menu_item_links.menu_item_link_listing,
          });
        } else {
          resolve({ sections: [], links: [] });
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchLinksQuery = gql`
  query searchLinks($input: SearchInput) {
    search_menu_item_links(input: $input) {
      menu_item_link_listing {
        item_link_id
        display_order
        un_available_dates {
          start_date_time
          end_date_time
        }
        availability_status
        price
        variant {
          prompt
          prompt_id
          variants {
            variant_id
            variant_price
            availability_status
            un_available_dates {
              start_date_time
              end_date_time
            }
          }
        }
        modifier {
          modifier_group_id
          menu_item_id
          menu_item {
            menu_item_id
          }
          override_price
          override_variant {
            prompt_id
            prompt
            variants {
              variant_id
              variant_price
              availability_status
              un_available_dates {
                start_date_time
                end_date_time
              }
            }
          }
          availability_status
          un_available_dates {
            start_date_time
            end_date_time
          }
          min_qty
          max_qty
          display_order
        }
        menu_section {
          menu_section_id
        }
        menu_item {
          menu_item_id
          name
          price
          image_url
          description
          calories
          kilojoules
          popular
          allow_item_review
          availability_status
          modifier_group_id
          un_available_dates {
            start_date_time
            end_date_time
          }
          variant {
            prompt
            prompt_id
            variants {
              variant_id
              variant_name
              variant_price
              availability_status
              un_available_dates {
                start_date_time
                end_date_time
              }
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getLinks = ({ menuId, query, linkId }) =>
  new Promise((resolve, reject) => {
    const menu_item_link_filter = {};
    Object.assign(
      menu_item_link_filter,
      menuId && { menu_id: menuId },
      linkId && { item_link_id: linkId },
    );
    client.clientPublic
      .query({
        query: searchLinksQuery,
        variables: {
          input: {
            size: 200,
            search_criteria: query || '',
            filter: { menu_item_link_filter },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menu_item_links && data.search_menu_item_links.menu_item_link_listing) {
          resolve(data.search_menu_item_links.menu_item_link_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchMenuItemQuery = gql`
  query searchMenuItem($input: SearchInput) {
    search_menu_items(input: $input) {
      menu_item_listing {
        menu_item_id
        name
        price
        image_url
        description
        calories
        kilojoules
        availability_status
        un_available_dates {
          start_date_time
          end_date_time
        }
        variant {
          prompt
          prompt_id
          variants {
            variant_id
            variant_name
            variant_price
            availability_status
          }
        }
        modifier_groups {
          modifier_group_id
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getMenuItem = ({ menuItemId }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchMenuItemQuery,
        variables: {
          input: {
            filter: { menu_item_filter: { menu_item_id: menuItemId } },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_items &&
          data.search_menu_items.menu_item_listing &&
          data.search_menu_items.menu_item_listing.length !== 0
        ) {
          resolve(data.search_menu_items.menu_item_listing[0]);
        } else {
          resolve(null);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchMenuModifiersGroupItemQuery = gql`
  query searchMenuModifiersGroup($input: SearchInput) {
    search_menu_modifier_groups(input: $input) {
      modifier_group_listing {
        modifier_group_id
        name
        description
        is_required
        selection_type
        selection_quantity
        selection_quantity_to
        selection_quantity_from
        modifier {
          menu_item {
            menu_item_id
            name
            price
            availability_status
            un_available_dates {
              start_date_time
              end_date_time
            }
            variant {
              prompt
              prompt_id
              variants {
                variant_id
                variant_name
                variant_price
                availability_status
                un_available_dates {
                  start_date_time
                  end_date_time
                }
              }
            }
          }
          override_price
          override_variant {
            prompt
            prompt_id
            variants {
              variant_id
              variant_price
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getMenuModifiersGroups = ({ modifiersGroupsIds }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchMenuModifiersGroupItemQuery,
        variables: {
          input: {
            filter: { menu_modifier_group_filter: { modifier_group_id: modifiersGroupsIds } },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_modifier_groups &&
          data.search_menu_modifier_groups.modifier_group_listing &&
          data.search_menu_modifier_groups.modifier_group_listing.length !== 0
        ) {
          resolve(data.search_menu_modifier_groups.modifier_group_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchCartQuery = gql`
  query searchCart($input: SearchInput) {
    search_menu_orders(input: $input) {
      menu_order_listing {
        menu_order_id
        place_id
        service_type
        service_type_setting_id
        menu_id
        place {
          place_id
          name
          address_line_1
          city
          state
          post_code
        }
        address {
          address_id
          type
          is_default
          contact_name
          contact_phone
          address_line_1
          address_line_2
          city
          state
          country
          post_code
          latitude
          longitude
          status
        }
        billing {
          sub_total

          delivery_fee

          order_total
          order_amount
          tax {
            type
            value
          }
        }
        line_item {
          name
          item_link_id
          menu_item_id
          prompt_id
          variant_id
          variant_name
          name
          quantity
          price_per_item
          net_price_per_item
          tax_per_item
          overall_price
          price
          tax
          modifier {
            name
            modifier_group_id
            menu_item_id
            prompt_id
            variant_id
            variant_name
            name
            quantity
            price_per_item
            net_price_per_item
            tax_per_item
            price
            tax
          }
          menu_item_rating
          special_request
          user_id
          caption
          private
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getCart = ({ size = 100, filter }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchCartQuery,
        variables: {
          input: {
            size,
            filter,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          data.search_menu_orders.menu_order_listing &&
          data.search_menu_orders.menu_order_listing.length !== 0
        ) {
          resolve(data.search_menu_orders.menu_order_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchMenuDealsQuery = gql`
  query searchMenuDeals($input: SearchInput) {
    search_place_menu_deal(input: $input) {
      place_menu_deal_listing {
        deal_id
        name
        end
        promo_code
        promotion
        description
      }
    }
  }
`;

export const getDeals = ({ size = 100, filter }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchMenuDealsQuery,
        variables: {
          input: {
            size,
            filter,
          },
        },
      })
      .then(({ data }) => {
        if (data.search_place_menu_deal && data.search_place_menu_deal.place_menu_deal_listing) {
          const items = data.search_place_menu_deal.place_menu_deal_listing;
          resolve(items);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const deleteCartMutation = gql`
  mutation deleteCart($input: NavigateMenuOrderInput) {
    delete_menu_order(input: $input) {
      menu_order_id
      error {
        description
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const deleteCart = ({ input }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .mutate({
        mutation: deleteCartMutation,
        variables: {
          input,
        },
      })
      .then(({ data }) => {
        if (data && data.delete_menu_order && data.delete_menu_order.error === null) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchUserAddressQuery = gql`
  query user($userId: String) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      address {
        address_id
        type
        is_default
        contact_name
        contact_phone
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        status
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getUserAddress = ({ userId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: searchUserAddressQuery,
        variables: {
          userId,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.fetch_user && data.fetch_user.address && data.fetch_user.address.length !== 0) {
          resolve(data.fetch_user.address);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchOrderFoodQuery = gql`
  query searchPlaceQuery($input: SearchInput) {
    search_food_orders(input: $input) {
      total_pages
      total_size
      place_and_service_type_listing {
        service_type_setting {
          image_url
        }
        place {
          place_id
        }
      }
    }
  }
`;

const getFoodOrder = ({ input }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchOrderFoodQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_food_orders &&
          data.search_food_orders.place_and_service_type_listing &&
          data.search_food_orders.place_and_service_type_listing.length !== 0
        ) {
          resolve(data.search_food_orders.place_and_service_type_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const validateAddress = async (address, placeId) => {
  let temp = true;
  console.log('placeId', placeId);
  const list = await getFoodOrder({
    input: {
      location: `${address.latitude},${address.longitude}`,
      filter: {
        place_filter: {
          service_type: 'Delivery',
          place_id: placeId,
        },
        service_type_setting_filter: { service_type: 'Delivery' },
      },
    },
  });
  if (list.length !== 0) {
    temp = true;
  } else {
    temp = false;
  }
  return temp;
};

const updateOrderMutation = gql`
  mutation updateOrder($input: MenuOrderInput) {
    update_menu_order(input: $input) {
      ...OrderFragments
      error {
        description
      }
    }
  }
  ${OrderFragments}
`;

export const guestUserFlowCartConversation = async ({
  guestMenuOrderId,
  userId,
  history,
  setAnonymousUserIdForCart,
  setGuestUserLoading,
}) => {
  setGuestUserLoading(true);
  try {
    const list = await getCart({
      filter: { menu_order_filter: { user_id: userId, status: 'DRAFT' } },
    });
    const finalList = list.filter(item => item.menu_order_id !== guestMenuOrderId);
    if (finalList.length !== 0) {
      try {
        await deleteCart({
          input: {
            user_id: userId,
            menu_order_id: list[0].menu_order_id,
          },
        });
        client.clientPublic
          .mutate({
            mutation: updateOrderMutation,
            variables: { input: { menu_order_id: guestMenuOrderId, user_id: userId } },
          })
          .then(({ data: { update_menu_order } }) => {
            setGuestUserLoading(false);
            setAnonymousUserIdForCart(null);
            history.push('/checkout', { order: update_menu_order });
          })
          .catch(error => {
            console.log(error);
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      client.clientPublic
        .mutate({
          mutation: updateOrderMutation,
          variables: { input: { menu_order_id: guestMenuOrderId, user_id: userId } },
        })
        .then(({ data: { update_menu_order } }) => {
          setGuestUserLoading(false);
          setAnonymousUserIdForCart(null);
          history.push('/checkout', { order: update_menu_order });
        })
        .catch(error => {
          console.log(error);
        });
    }
  } catch (error) {
    console.error(error);
  }
};
