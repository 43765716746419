import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { Button, Icon, Text } from '../../../../components/elements';
import GetImages from '../../../../components/GetImages';

const Container = styled.div`
  padding: 1rem 0rem;
  padding-right: 20%;
`;

const Wrapper = styled.div`
  margin-top: 1.6rem;
  margin-left: 1rem;
`;

const ButtonWrapper = styled.div`
  margin-top: 2.23rem;
`;

const SearchInput = styled.div`
  &&& {
    p {
      margin-bottom: 0.7em !important;
    }
  }
`;

const ItemReviewActionButton = ({ itemId, history, itemName, placeName }) => {
  const { isLoggedIn, user, userImage } = useStoreState(state => state.auth);

  return (
    <Container>
      <article className="media">
        <figure className="media-left">
          <Wrapper>
            {isLoggedIn ? (
              <GetImages
                name={user.display_name}
                maskProps={{ width: 50, height: 50 }}
                maskTextProps={{
                  color: 'white',
                  weight: 'bold',
                  size: 'medium',
                  letterSpacing: 'loose',
                }}
                borderRadius={25}
                randomColorCode={user.color_code}
                userImage={userImage}
              />
            ) : (
              <Icon name="myProfileBlue" size={3} />
            )}
          </Wrapper>
        </figure>
        <div className="media-content">
          <div className="content">
            <SearchInput>
              <Text size="bmedium" weight="semibold">
                Share this Item
              </Text>
              <p className="control has-icons-left has-icons-right">
                <input
                  className="input"
                  placeholder={`Rate and Share ${itemName} in ${placeName}...`}
                  onFocus={() => history.push(`/item-review/${itemId}`)}
                />
                <span className="icon is-small is-left">
                  <Icon name="itemRatingGoldSolid" size={1.5} />
                </span>
              </p>
            </SearchInput>
          </div>
        </div>
        <div className="media-right">
          <ButtonWrapper>
            <Button
              name="Spot and Share"
              borderColor="primaryColor"
              backgroundColor="primaryColor"
              textProps={{ size: 'tiny', color: 'white', weight: 'semibold' }}
              onClick={() => history.push(`/item-review/${itemId}`)}
            />
          </ButtonWrapper>
        </div>
      </article>
    </Container>
  );
};
export default withRouter(ItemReviewActionButton);
