import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { withRouter } from 'react-router-dom';
import { Text, Button, FlexRows, TextArea, Select } from '../../components/elements';

const BoxWrapper = styled.nav`
  padding: 0.5rem, 2rem;
  margin-right: 1rem;
`;
const Wrapper = styled.nav`
  padding: 0.5rem, 2rem;
  margin-right: 1rem;
`;

const Span = styled.span`
  color: red;
`;
const Container = styled.div`
  padding: 0rem, 2rem;
`;

const TitleWrapper = styled.div`
  padding-left: 0.4rem;
`;

const Heading = styled(Text)`
  span {
    color: ${({ theme: { colors: color } }) => color.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
  }
`;

const SubHeading = styled(Text)`
  span {
    color: ${({ theme: { colors: themeColor } }) => themeColor.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
  }
`;

const Label = styled(Text).attrs({ color: 'darkGrey', size: 'medium' })``;

const DesktopForm = ({ history }) => {
  return (
    <Container>
      <TitleWrapper>
        <Heading size="big" weight="semibold" paddingBottom={0.5} paddingTop={0.5}>
          Feedback
        </Heading>
        <SubHeading size="small" weight="regular" color="darkGrey">
          We would love your feedback to improve your experience. Please select a feedback type and
          write a brief description
        </SubHeading>
      </TitleWrapper>
      <hr />
      <FlexRows className="columns level-left">
        <div className="column is-two-thirds is-vcentered">
          <div className="field">
            <Label>Feedback Type</Label>
          </div>
          <Select name="feedback" />
          {/* <InputErrorMessage touched={touched.state} errors={errors.state} /> */}
        </div>

        <div className="column " size="4">
          <Wrapper>
            <div className="box">
              <Label weight="semibold">Email Support</Label>
              <br />
              <Text size="small" color="darkGrey">
                Customer Support
              </Text>
              <Text size="tiny" color="primaryColor">
                support@kravein.com
              </Text>
              <br />
              <Text size="small" color="darkGrey">
                Business Support
              </Text>
              <Text size="tiny" color="primaryColor">
                business@kravein.com
              </Text>
            </div>
          </Wrapper>
        </div>
      </FlexRows>

      <FlexRows className="columns level-left">
        <div className="column is-two-thirds is-vcentered">
          <div className="field">
            <Label>
              Description<Span>*</Span>
            </Label>
          </div>
          <TextArea name="feedback" />
        </div>

        <div className="column " size="4">
          <BoxWrapper>
            <div className="box">
              <Label weight="semibold">Join Kravein Family</Label>
              <br />
              <Text size="small" color="darkGrey">
                Be a part of our growing Kravein family to enjoy unparalleled experience ...
              </Text>
              <br />
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <Button
                      name="Add a place"
                      onClick={() => history.push('/add-place')}
                      backgroundColor="primaryColor"
                    />
                  </div>
                  <div className="level-item">
                    <Button
                      name="Claim you place"
                      onClick={() => history.push('/search-place-for-claim')}
                      backgroundColor="primaryColor"
                    />
                  </div>
                </div>
              </div>
            </div>
          </BoxWrapper>
        </div>
      </FlexRows>

      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <Button name="Cancel" backgroundColor="primaryColor" />
          </div>
          <div className="level-item">
            <Button name="Submit" backgroundColor="primaryColor" />
          </div>
        </div>
      </div>
    </Container>
  );
};
export default withRouter(DesktopForm);
