import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import { capitalize } from 'lodash';
import MobileLocationSuggestion from './mobile/MobileLocationSuggestion';
import client from '../../utils/apolloClient';

const searchLocationQuery = gql`
  query searchLocation($search_criteria: String!) {
    search_location(input: { search_criteria: $search_criteria }) {
      location_listing {
        code
        name
        post_code
        state_name
        country_name
        latitude
        longitude
        location
      }
    }
  }
`;

// const renderLocationSuggestion = location => (
//   <div>
//     <p className="has-text-weight-bold">{location.name}</p>
//     <p>
//       {location.state_name}, {location.post_code}
//     </p>
//   </div>
// );
// const getSuggestionValue = suggestion => suggestion.suburbName;

const LocationSuggestForm = ({ back }) => {
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState(`${capitalize(usedLocation.city)}, ${usedLocation.state}`);
  const onSuggestionsFetchRequested = ({ value }) => {
    setQuery(value);
    if (value === '') {
      setSuggestions([]);
    } else {
      client.clientPublic
        .query({
          query: searchLocationQuery,
          variables: { search_criteria: value },
        })
        .then(({ data }) => {
          if (data.search_location) {
            setSuggestions(data.search_location.location_listing);
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  };

  return (
    <MobileLocationSuggestion
      query={query}
      back={back}
      locations={suggestions}
      onSearchInputChange={value => onSuggestionsFetchRequested({ value })}
    />
  );
};

export default LocationSuggestForm;
