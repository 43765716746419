import React from 'react';
import posed from 'react-pose';

import Text from './Text';
import Icon from './Icon';
import { FlexColumns, FlexRows } from '.';

const ButtonAnimation = posed.a({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const IconButton = ({ type, buttonName, textStyle, className, iconName, iconStyle, ...props }) => (
  <ButtonAnimation className={className} {...props}>
    {type === 'primary' ? (
      <FlexColumns>
        <Icon name={iconName} {...iconStyle} />
        {buttonName && <Text {...textStyle}>{buttonName}</Text>}
      </FlexColumns>
    ) : (
      <FlexRows>
        <Icon name={iconName} {...iconStyle} />
        {buttonName && <Text {...textStyle}>{buttonName}</Text>}
      </FlexRows>
    )}
  </ButtonAnimation>
);

export default IconButton;
