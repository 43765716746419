import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCopy } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Text from '../Text';
import colors from '../../../theme/styles/colors';
import config from '../../../utils/config';

const Wrapper = styled.div`
  padding: 0.5rem 0rem;
  padding-left: 0.5rem;
  cursor: pointer;
  align-items: center;
  background: ${({ active }) => (active ? `${colors.lightGreyAlpha}` : 'transparent')};
`;

const Copy = ({ text }) => {
  const [copied, setCopied] = useState(false);
  return (
    <Wrapper active={copied} className="is-flex">
      <FaCopy size={18} /> &nbsp;&nbsp;
      <CopyToClipboard
        text={text}
        onCopy={() => {
          if (!copied) {
            setCopied({ copied: true });
          }
        }}
        disabled={copied}
      >
        <Text color="coolGrey" size="small">
          {!copied ? 'Copy to clipboard' : 'Clipboard copied'}
        </Text>
      </CopyToClipboard>
    </Wrapper>
  );
};

Copy.defaultProps = {
  text: `https://${config.apiPrefix}admin.portal.kravein.com.au/`,
};

Copy.propTypes = {
  text: PropTypes.string,
};

export default Copy;
