import React from 'react';
import styled from 'styled-components';
import { Image } from './elements';
import { getEncodedImage } from '../utils/s3';

const ContainerDesk = styled.div`
  padding: 1rem 0rem;
  margin-right: 2rem;
`;

const ContainerMob = styled.div`
  &&& {
    padding: 0.5rem;
    .column {
      padding: 0.2rem;
    }
  }
`;

const ImageGallery = ({ isPreviewOpen, images }) => (
  <>
    <div className="is-hidden-desktop">
      <ContainerMob>
        <div className="columns is-multiline is-mobile is-1">
          {images.map((image, index) => (
            <div className="column is-4">
              <a onClick={() => isPreviewOpen(image, index)}>
                <figure className="image is-square">
                  <Image source={getEncodedImage(image.url, 209, 209)} radius={0} />
                </figure>
              </a>
            </div>
          ))}
        </div>
      </ContainerMob>
    </div>
    <div className="is-hidden-touch">
      <ContainerDesk>
        <div className="columns is-multiline">
          {images.map((image, index) => (
            <div className="column is-2">
              <a onClick={() => isPreviewOpen(image, index)}>
                <figure className="image is-square">
                  <Image source={getEncodedImage(image.url, 209, 209)} radius={0} />
                </figure>
              </a>
            </div>
          ))}
        </div>
      </ContainerDesk>
    </div>
  </>
);

export default ImageGallery;
