import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStoreActions } from 'easy-peasy';
import queryString from 'query-string';

import { Link, withRouter } from 'react-router-dom';
import { first, forEach, omit } from 'lodash';
import { FlexRows, Text, Pager } from '../../components/elements';
import TopView from './components/desktop/TopView';
import { MenuLoader } from './Loader';

import MenuSectionsAndItem from './components/desktop/MenuSectionsAndItem';

// import Loading from '../../components/global/mobile/MobileLoading';

// import EmptyCart from './components/desktop/EmptyCart';
// import YourOrder from './components/desktop/YouOrder';

import ReportMenuErrorModal from '../../components/global/Layout/ReportMenuErrorModal';
// import ApplyPromoCodeModal from './components/desktop/ApplyPromoCodeModal';
import { isMenuOpen } from '../../utils/hoursHelpers';

const Container = styled.div`
  .improve-data {
    margin-top: 7rem;
  }
`;

const Tabs = styled(FlexRows).attrs({ justify: 'flex-start' })`
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyTheta};
  background: #ffffff;
  z-index: 200;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;
const Item = styled.div`
  padding: 0.5rem 0rem;
  padding-left: 0;
  margin-right: 2rem;
  margin-bottom: -1px;
  border-bottom: 2px solid
    ${({ theme: { colors }, active }) => (active ? colors.primaryColor : 'transparent')};
`;

const checkWhenMenuOrderIsOpen = (menus, place, serviceTypeSetting) => {
  const menuIds = [];
  forEach(menus, item => {
    const isOpen = isMenuOpen(
      {
        menu_hour: item.menu_hour,
        stopped_hour: item.stopped_hour,
      },
      { latitude: place.latitude, longitude: place.longitude },
      serviceTypeSetting.cut_off_time,
      item.holiday_hour,
    );
    if (isOpen.is_open && !isOpen.is_stopped) {
      menuIds.push(item.menu_id);
    }
  });
  return menuIds;
};

const Tab = ({ item, activeTab, placeId, serviceType, routeData }) => {
  const variables = queryString.stringify({
    ...routeData,
    menuId: item.menu_id,
    deliveryAddress: JSON.stringify(
      omit(routeData.deliveryAddress, ['type', `__typename`, 'error', 'location']),
    ),
  });
  return (
    <Item active={activeTab} key={item.menu_id}>
      <Link to={`/order-food/${serviceType}/${placeId}?${variables}`}>
        <Text
          className="is-capitalized"
          size="smedium"
          weight="medium"
          color={activeTab ? 'primaryColor' : 'dark'}
        >
          {item.name}
        </Text>
      </Link>
    </Item>
  );
};

const calculateCartQuantity = items => {
  let quantity = 0;
  forEach(items, item => {
    quantity += item.quantity;
  });
  return quantity;
};

const DesktopView = ({
  addToCartLoading,
  cartLoading,
  menuLoading,
  place,
  serviceTypeSetting,
  order,
  orderDataFromDataBase,
  menus,
  func,
  state,
  menuId,
  onSubmit,
  updateQuantity,
  emptyCart,
  routeData,
  onUpdateDelivery,
  onUpdateUserWithOrder,
  history,
}) => {
  const { setCartQuantity } = useStoreActions(state1 => state1.global);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [noItemThere, setNoItemThere] = useState(false);
  const [openHoursData, setOpenHoursData] = useState({
    isOpen: false,
    hours: [],
    isStopped: false,
    messageOfClosingAndOpening: '',
  });

  const menuFirst = menus.length !== 0 ? first(menus) : { menu_id: '' };
  const menuRouteId = menuId || menuFirst.menu_id;

  useEffect(() => {
    if (order.line_item) {
      const qty = calculateCartQuantity(order.line_item);
      setCartQuantity(qty);
    }
  }, [order.line_item, setCartQuantity]);

  useEffect(() => {
    const currentMenu = menus.filter(item => item.menu_id === menuRouteId);
    if (currentMenu.length !== 0) {
      const isOpen = isMenuOpen(
        {
          menu_hour: currentMenu[0].menu_hour,
          stopped_hour: currentMenu[0].stopped_hour,
        },
        { latitude: place.latitude, longitude: place.longitude },
        serviceTypeSetting.cut_off_time,
        currentMenu[0].holiday_hour,
      );
      const messageOfClosingAndOpening = isOpen.nextClosingOrOpeningHours
        ? `${
            isOpen.nextClosingOrOpeningHours.isStart
              ? // eslint-disable-next-line max-len
                `Opens in ${isOpen.nextClosingOrOpeningHours.time_in_mins} ${
                  isOpen.nextClosingOrOpeningHours.time_in_mins === 1 ? 'minute' : 'minutes'
                }.`
              : // eslint-disable-next-line max-len
                `You have ${isOpen.nextClosingOrOpeningHours.time_in_mins} minutes remaining to order from this menu.`
          }`
        : '';
      setOpenHoursData({
        isOpen: isOpen.is_open,
        hours: isOpen.options,
        isStopped: isOpen.is_stopped,
        todayDay: isOpen.today_day,
        messageOfClosingAndOpening,
      });
    }
  }, [
    menuRouteId,
    menus,
    order.line_item,
    place,
    place.latitude,
    place.longitude,
    serviceTypeSetting,
    serviceTypeSetting.cut_off_time,
  ]);

  useEffect(() => {
    if (place && serviceTypeSetting) {
      const openMenus = checkWhenMenuOrderIsOpen(menus, place, serviceTypeSetting);
      if (openMenus.length !== 0) {
        history.push(
          `/order-food/${serviceTypeSetting.service_type}/${place.place_id}?menuId=${openMenus[0]}`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus, place, serviceTypeSetting]);

  const isCustomService = serviceTypeSetting.is_custom_service;

  return (
    <Container>
      <TopView
        onUpdateDelivery={onUpdateDelivery}
        openHoursData={openHoursData}
        currentMenu={menus.filter(item => item.menu_id === menuRouteId)[0]}
        place={place}
        serviceTypeSetting={serviceTypeSetting}
        func={func}
        state={state}
        socials={[]}
        isCustomService={isCustomService}
      />

      <ReportMenuErrorModal isActiveModal={isActiveModal} setIsActiveModal={setIsActiveModal} />
      {/* <ApplyPromoCodeModal isActiveModal={promoCodeModal} \
      setIsActiveModal={setPromoCodeModal} /> */}
      {menuLoading && <MenuLoader />}
      <Pager>
        {menus.length !== 0 && (
          <Tabs>
            {menus.map(menu => (
              <Tab
                routeData={routeData}
                item={menu}
                activeTab={menu.menu_id === menuRouteId}
                placeId={place.place_id}
                serviceType={serviceTypeSetting.service_type}
              />
            ))}
          </Tabs>
        )}
      </Pager>

      <Pager>
        <MenuSectionsAndItem
          place={place}
          location={{ latitude: place.latitude, longitude: place.longitude }}
          isOpen={openHoursData.isStopped ? false : openHoursData.isOpen}
          placeName={place.name}
          addToCartLoading={addToCartLoading}
          cartLoading={cartLoading}
          order={order}
          orderDataFromDataBase={orderDataFromDataBase}
          menuId={menuRouteId}
          placeId={place.place_id}
          settingId={serviceTypeSetting.service_type_setting_id}
          serviceType={serviceTypeSetting.service_type}
          onSubmit={onSubmit}
          updateQuantity={updateQuantity}
          emptyCart={emptyCart}
          setNoItemThere={setNoItemThere}
          onUpdateUserWithOrder={onUpdateUserWithOrder}
          isCustomService={isCustomService}
        />
      </Pager>
    </Container>
  );
};

// eslint-disable-next-line react/no-redundant-should-component-update

export default withRouter(DesktopView);
