import React from 'react';
import { first } from 'lodash';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';

import TopView from './components/desktop/TopView';
import ItemReviewActionButton from './components/desktop/ItemReviewActionButton';
import Reviews from './components/desktop/Reviews';
import ImageView from './components/desktop/ImageView';
import { Pager, ScrollToTopButton } from '../../components/elements';
import { parseSocialItem } from '../../utils/helpers';

const Container = styled(Pager)`
  padding-top: 2rem;
`;

const DesktopView = ({ data, socials }) => {
  const { userId } = useStoreState(state => state.auth);
  const item = first(data.fetch_item);
  const socialArray = socials.length !== 0 ? first(socials).total_social_count : [];
  const social = parseSocialItem(socialArray, userId);

  return (
    <Container>
      <TopView item={item} social={social} />
      <ImageView placeId={item.place_id} item={item} />
      <ItemReviewActionButton
        placeId={item.place_id}
        itemId={item.item_id}
        itemName={item.name}
        placeName={item.place_name}
      />
      <Reviews item={item} />
      <ScrollToTopButton />
    </Container>
  );
};

export default DesktopView;
