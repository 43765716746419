import React from 'react';
import styled from 'styled-components';
import { FlexRows, FlexColumns, ToggleableButton, Text } from '../../../../components/elements';

const Container = styled.div`
  background: ${({ theme: { colors } }) => colors.white};
  padding: 1rem 0rem;
`;

const ActionButtons = () => (
  <Container>
    <FlexRows justify="space-around">
      <ToggleableButton
        name="BookMark"
        textStyle={{ size: 'small', weight: 'semibold' }}
        defaultIcon="likeBlack"
        activeIcon="likeSolid"
        leftIconStyle={{ size: 160 }}
      />

      <ToggleableButton
        name="review"
        textStyle={{ size: 'small', weight: 'semibold' }}
        defaultIcon="likeBlack"
        activeIcon="likeSolid"
        leftIconStyle={{ size: 160 }}
      />

      <ToggleableButton
        name="12kj"
        textStyle={{ size: 'small', weight: 'semibold' }}
        defaultIcon="likeBlack"
        activeIcon="likeSolid"
        leftIconStyle={{ size: 160 }}
      />

      <ToggleableButton
        textStyle={{ size: 'small', weight: 'semibold' }}
        defaultIcon="bookMarkBlack"
        activeIcon="bookMarkSolid"
        leftIconStyle={{ size: 100 }}
      />
    </FlexRows>
  </Container>
);

export default ActionButtons;
