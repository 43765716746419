import { createStore } from 'easy-peasy';
import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import localForage from 'localforage';

import global from './global';
import place from './place';
import event from './event';
import item from './item';
import auth from './auth';

const model = {
  ...global,
  ...place,
  ...event,
  ...item,
  ...auth,
};
const store = createStore(model, {
  reducerEnhancer: reducer =>
    persistReducer(
      {
        key: 'easypeasystate',
        storage: localForage,
      },
      reducer,
    ),
});

export default store;
