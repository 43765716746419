import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';

import { Image, Icon, Button } from '../../../../components/elements';
import { getEncodedImage } from '../../../../utils/s3';
import config from '../../../../utils/config';

const Figure = styled.figure`
  && {
    img {
      border-radius: 4px;
    }
  }
`;

const EmptyWrapper = styled.div`
  &&& {
    position: relative;
    .img-opacity {
      opacity: 0.6;
    }
  }
`;

const AddPhotosButtonWrapper = styled.div`
  right: 1rem;
  position: absolute;
  bottom: 0.5rem;
  z-index: 2;
`;

const EmptyImage = ({ placeId, history }) => (
  <EmptyWrapper>
    <AddPhotosButtonWrapper>
      <Button
        height={0}
        name="Add Photo"
        icon="photoAddSolid"
        textProps={{ size: 'micro' }}
        onClick={() => history.push(`/add-place-photos/${placeId}`)}
        borderRadius={20}
      >
        <span className="icon is-small">
          <Icon name="photoAddSolid" size={1} />
        </span>
      </Button>
    </AddPhotosButtonWrapper>
    <Link to={`/place/${placeId}`}>
      <Figure className="image is-square img-opacity">
        <Image source={config.dummyImage} />
      </Figure>
    </Link>
  </EmptyWrapper>
);

const ImageView = ({ place, history }) => {
  if (place.default_image_url === null || place.default_image_url === '') {
    return <EmptyImage placeId={place.place_id} history={history} />;
  }

  return (
    <Link to={`/place/${place.place_id}`}>
      <Figure className="image is-square">
        <Image source={getEncodedImage(place.default_image_url, 307, 307)} />
      </Figure>
    </Link>
  );
};

export default withRouter(ImageView);
