import React from 'react';
import styled from 'styled-components';
import { MobileUploadImage } from '../../../../components/elements';

const Container = styled.div`
  width: 100%;
`;

const ImageUpload = ({ setFieldValue }) => {
  return (
    <Container className="columns is-mobile is-gapless is-vcentered is-centered is-multiline">
      <MobileUploadImage
        totalNumberOfUploadedImage={1}
        s3UploadPath="place/"
        metaData={{
          place_id: '',
          user_id: '2290802a-c911-4718-a829-5edc420e0d05',
        }}
        setFieldValue={setFieldValue}
      />
    </Container>
  );
};

export default ImageUpload;
