import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import GoogleMapReact from 'google-map-react';
import { geolocated } from 'react-geolocated';
import { first } from 'lodash';

import Icon from './Icon';
import { getApiImages } from '../../utils/reusableQuery';
import { getEncodedImage } from '../../utils/s3';
import blankImage from '../../assets/images/blank.png';
import config from '../../utils/config';

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const ImageView = ({ images }) => {
  if (images === null) {
    return (
      <figure className="image is-4by3">
        <img src={blankImage} alt="Placeholder image" />
      </figure>
    );
  }
  if (images.length === 0) {
    return (
      <figure className="image is-4by3">
        <img src={config.dummyImage} alt="Placeholder image" />
      </figure>
    );
  }
  const image = first(images);
  return (
    <figure className="image is-4by3">
      <img src={getEncodedImage(image.url, 307, 307)} alt="Placeholder image" />
    </figure>
  );
};

const Marker = ({ item }) => {
  const [isActive, setIsActive] = useState(false);
  const [images, setImage] = useState(null);
  useEffect(() => {
    const get = async () => {
      const data = await getApiImages({
        ...item.imageObject,
      });
      setImage(data);
    };
    get();
  }, [item.id, item.imageObject, item.type]);
  return (
    <div>
      <div className={`dropdown is-up is-hoverable ${isActive && 'is-active'}`}>
        <div className="dropdown-trigger">
          <button
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            style={{
              borderRadius: '24px',
              padding: '0.5rem',
              border: 0,
              background: 'transparent',
            }}
            onClick={() => setIsActive(!isActive)}
          >
            <Icon name="eventMapMarkerSolid" size={1.8} />
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div>
            <div className="card">
              <div className="card-image">
                <ImageView images={images} />
              </div>
              <div style={{ padding: '1rem' }}>
                <div className="media">
                  <div className="media-content">
                    {item.titleHead && <p className="is-size-6">{item.titleHead}</p>}
                    {item.type === 'PLACE' && (
                      <Link to={`/place/${item.id}`}>
                        <p className="title is-2 has-text-weight-semibold has-text-primary">
                          {item.title}
                        </p>
                      </Link>
                    )}
                    {item.type === 'ITEM' && (
                      <Link to={`/item/${item.id}`}>
                        <p className="title is-2 has-text-weight-semibold has-text-primary">
                          {item.title}
                        </p>
                      </Link>
                    )}
                    {item.type === 'EVENT' && (
                      <Link to={`/event/${item.id}`}>
                        <p className="title is-2 has-text-weight-semibold has-text-primary">
                          {item.title}
                        </p>
                      </Link>
                    )}
                    <p className="is-size-4">{item.subtitle}</p>
                    {item.subtitle1 && <p className="is-size-6">{item.subtitle1}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MapView = ({ data }) => {
  return (
    <Wrapper style={{ height: '80vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.googleApiKey }}
        defaultCenter={{
          lat: parseFloat(data[0].latitude),
          lng: parseFloat(data[0].longitude),
        }}
        yesIWantToUseGoogleMapApiInternals
        // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps, data)}
        defaultZoom={14.5}
      >
        {data.map(item => (
          <Marker lat={item.latitude} lng={item.longitude} item={item} />
        ))}
      </GoogleMapReact>
    </Wrapper>
  );
};
export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
  watchPosition: true,
})(MapView);
