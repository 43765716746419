import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { first } from 'lodash';

import { Text } from '../../../components/elements';
import Loading from '../../../components/global/Loading';
import DeskEvent from './DeskEvent';
import client from '../../../utils/apolloClient';

const Container = styled.div`
  padding: 1rem;
`;

const PageHeader = styled.div`
  background: #ffffff;
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const socialQuery = gql`
  query social($input: SearchInput) {
    search_my_social(input: $input) {
      social_listing_aggregation {
        total_pages
        total_size
        social_listing {
          social_id
          object_id
          object_type
          type
        }
      }
      event_listing {
        place_id
        event_id
        status
        display_order
        featured
        default_url
        start
        end
        ticket_sale_start
        ticket_sale_end
        place_name
        venue_name
        name
        description
        tagline
        slug
        voucher_count
        minimum_price
        maximum_price
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
      }
      image_listing {
        image_id
        object_id
        url
      }
    }
  }
`;

const DesktopView = () => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Container>
      <PageHeader>
        <Text size="large" weight="semibold" paddingBottom={0.5}>
          My Saved Events
        </Text>
      </PageHeader>
      <Query
        client={client.clientPrivate}
        query={socialQuery}
        variables={{
          input: {
            filter: {
              social_filter: { user_id: userId, object_type: 'EVENT', type: 'BOOKMARK' },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }
          const {
            search_my_social: {
              event_listing,
              image_listing,
              social_listing_aggregation: { social_listing },
            },
          } = data;

          if (social_listing.length === 0 || event_listing.length === 0) {
            return (
              <Container>
                <Text size="medium" color="darkGrey">
                  No saved events yet!
                </Text>
                <Text size="tiny" color="coolGrey">
                  Add Events to your Bookmarks for easy reference.
                </Text>
              </Container>
            );
          }

          return (
            <Container>
              <div className="columns is-multiline">
                {social_listing.map(item => (
                  <DeskEvent
                    key={item.social_id}
                    event={first(event_listing.filter(event => event.event_id === item.object_id))}
                    image={first(image_listing.filter(image => image.object_id === item.object_id))}
                  />
                ))}
              </div>
            </Container>
          );
        }}
      </Query>
    </Container>
  );
};

export default DesktopView;
