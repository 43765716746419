import React from 'react';
import styled from 'styled-components';

import { FlexRows, Text } from '../../../../components/elements';
import Drafts from './Drafts';
import PlaceReviews from './PlaceReview';
import ItemReviews from './ItemReviews';

const Wrapper = styled(FlexRows)`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;

const Heading = styled.div`
  padding: 1rem;
`;

const Item = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding: 1rem;
  cursor: pointer;
  /* :hover {
    background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha};
  } */
  border-bottom:2px solid ${({ theme: { colors }, active }) =>
    active ? colors.primaryColor : 'transparent'};
  /* background-color: ${({ theme: { colors }, active }) => active && colors.lightGreyAlpha}; */
`;

const myReviews = [
  { name: 'Draft', count: 10 },
  { name: 'Place Reviews', count: 10 },
  { name: 'Item Reviews', count: 10 },
];

const MyReviews = () => {
  const [active, setActive] = React.useState('Draft');
  return (
    <>
      <Heading>
        <Text className="is-capitalized" size="large" weight="semibold">
          My Reviews
        </Text>
      </Heading>

      <Wrapper justify="flex-start">
        {myReviews.map(item => (
          <Item
            key={item.name}
            justify="flex-start"
            active={active === item.name}
            onClick={() => setActive(item.name)}
          >
            <Text
              className="is-capitalized"
              weight="semibold"
              size="small"
              color={active === item.name ? 'primaryColor' : 'darkGrey'}
            >
              {item.name}
            </Text>
          </Item>
        ))}
      </Wrapper>
      {active === 'Draft' && <Drafts />}
      {active === 'Place Reviews' && <PlaceReviews />}
      {active === 'Item Reviews' && <ItemReviews />}
    </>
  );
};

export default MyReviews;
