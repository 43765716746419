import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { useStoreState } from 'easy-peasy';
import { FaFilter, FaListUl, FaMapMarkerAlt } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import { capitalize } from 'lodash';

import { Text, FlexRows, SearchInput, Icon } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';
import font from '../../../../theme/styles/fonts';

const Container = styled.div`
  position: fixed;
  background-color: ${({ theme: { colors: color } }) => color.lightGreyBeta};
  padding-top: 0.5rem;
  z-index: 100;
  width: 100%;
`;

const Columns = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`;

const ScrollView = styled.div`
  overflow: auto;
  white-space: nowrap !important;
  padding-top: 3rem;
  width: 100%;
`;

const ScrollViewItem = styled.a`
  display: inline-block;
  text-align: center;
  padding: 0.5rem 0.5rem;
  border-bottom: 4px solid
    ${({ active, theme: { colors: color } }) => (active ? color.primaryColor : 'transparent')};
`;

const ButtonAnimation = posed.button({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const Button = styled(ButtonAnimation)`
  margin: 0rem 0.3rem !important;
  &&& {
    border-color: transparent;
    background-color: ${({ theme: { colors: color } }) => color.lightGreyAlpha};
    border-radius: 25px;
    :focus {
      border: 0;
    }
    :active {
      border: 0;
    }
  }
`;

const ButtonText = styled.span`
  color: ${colors.dark};
  font-size: ${font.size.tiny};
  font-weight: ${font.weight.semibold};
`;

const ButtonWrapper = styled.div`
  padding-top: 7rem;
`;

const Filter = ({
  setActiveFilterModal,
  handleProductType,
  setActiveCuisinesModal,
  setMapActive,
  activeMap,
  history,
}) => {
  const productType = useStoreState(state => state.place.filterInputs.productType);
  const [filterTags, setFilterTags] = React.useState(productType);
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const productTypes = useStoreState(state => state.place.productTypes);
  const addAllInProductTypes = [
    {
      id: 'ck0z91on18o8nv099856p4odh9',
      name: 'all',
      description: 'All',
      display_order: '0',
    },
  ];
  const finalProductTypes = addAllInProductTypes.concat(productTypes);

  return (
    <>
      <Container>
        <Columns>
          <SearchInput
            value={`${capitalize(usedLocation.city)}, ${capitalize(usedLocation.state)}`}
            onFocus={() => history.push('/search-location')}
            leftIcon={<MdLocationOn size={20} color={colors.coolGrey} />}
            hideRightIcon
          />
          {/* <Button className="button is-fullwidth" onClick={() => setActiveSortByModal(true)}>
            <span className="icon">
              <FaSort size={18} color={colors.coolGrey} />
            </span>
            <ButtonText>SORT</ButtonText>
          </Button>

          <Button className="button is-fullwidth" onClick={() => setMapActive(true)}>
            <span className="icon">
              <FaMapMarkerAlt size={16} color={colors.coolGrey} />
            </span>
            <ButtonText>MAP</ButtonText>
          </Button>
          <Button className="button is-fullwidth" onClick={() => setMapActive(false)}>
            <span className="icon">
              <FaListUl size={16} color={colors.coolGrey} />
            </span>
            <ButtonText>LIST</ButtonText>
          </Button>
          <Button className="button is-fullwidth" onClick={() => setActiveFilterModal(true)}>
            <span className="icon">
              <FaFilter size={16} color={colors.coolGrey} />
            </span>
            <ButtonText>FILTER</ButtonText>
          </Button> */}
        </Columns>
        <ScrollView>
          {finalProductTypes.map(product => {
            const active = product.name === filterTags;
            return (
              <ScrollViewItem
                className="item"
                key={product.id}
                active={active}
                onClick={() => {
                  setFilterTags(product.name);
                  handleProductType(product.name);
                }}
              >
                <Text className="is-uppercase" weight="bold" size="tiny" letterSpacing="loose">
                  {product.description}
                </Text>
              </ScrollViewItem>
            );
          })}
        </ScrollView>
      </Container>
      <ButtonWrapper>
        <FlexRows justify="space-around">
          <Button className="button is-fullwidth" onClick={() => setActiveFilterModal(true)}>
            <span className="icon">
              <FaFilter size={16} color={colors.coolGrey} />
            </span>
            <ButtonText>Filters</ButtonText>
          </Button>
          <Button className="button is-fullwidth" onClick={() => setActiveCuisinesModal(true)}>
            <span className="icon">
              <Icon name="reviewItemGrey" size={1.3} />
            </span>
            <ButtonText>Cuisines</ButtonText>
          </Button>
          <Button className="button is-fullwidth" onClick={() => setMapActive(!activeMap)}>
            <span className="icon">
              {activeMap ? (
                <FaListUl size={16} color={colors.coolGrey} />
              ) : (
                <FaMapMarkerAlt size={16} color={colors.coolGrey} />
              )}
            </span>
            <ButtonText>{activeMap ? 'List' : 'Map'}</ButtonText>
          </Button>
        </FlexRows>
      </ButtonWrapper>
    </>
  );
};

export default withRouter(Filter);
