import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { MdClear } from 'react-icons/md';

import { FlexColumns, Text, Modal, FlexRows } from '../../elements';
import SignUpForm from './SignUpForm';

const ModalHeader = styled(FlexRows)`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const ErrorMessage = styled.div`
  padding: 0.5rem;
  background: red;
  color: white;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const DeskTopSignInModal = () => {
  const { loading, isError } = useStoreState(state => state.auth);
  const clearError = useStoreActions(state => state.auth.clearError);
  const signUp = useStoreActions(state => state.auth.signUp);
  const isActiveModal = useStoreState(state => state.auth.isActiveDesktopSignInModal);
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopSignInModal);
  const setIsActiveDesktopLoginModal = useStoreActions(
    state => state.auth.setIsActiveDesktopLoginModal,
  );

  useEffect(() => {
    clearError();
  }, [clearError]);

  const handleSignupModal = () => {
    setIsActiveDesktopLoginModal({ value: true });
    setIsActiveModal({ value: false });
  };

  return (
    <Modal isActive={isActiveModal}>
      <ModalHeader justify="space-between">
        <a onClick={() => setIsActiveModal({ value: false })}>
          <Text color="white">
            <MdClear size={25} />
          </Text>
        </a>
        <Text className="is-uppercase" align="center" color="white" weight="bold" size="lmedium">
          Sign In
        </Text>
        <Text />
      </ModalHeader>
      <div className="modal-card">
        <section className="modal-card-body">
          <FlexColumns>
            <div>
              <Text color="primaryColor" size="large" weight="semibold" paddingBottom={0.5}>
                Sign Up for Kravein
              </Text>
            </div>
            <div>
              <Text color="darkGrey" size="tiny">
                Crave in, dig in....Create your experience
              </Text>
            </div>
          </FlexColumns>
          <br />
          {isError.message !== '' && (
            <ErrorMessage>
              <p>{isError.message}</p>
            </ErrorMessage>
          )}
          <SignUpForm
            isLoading={loading}
            onSubmit={values => {
              const input = {
                firstName: values.firstName,
                lastName: values.lastName,
                displayName: values.displayName,
                email: values.email.toLowerCase(),
                password: values.password,
                confirmPassword: values.confirmPassword,
              };
              signUp({ ...input });
            }}
          />

          <br />
          <Text color="darkGrey" size="micro">
            By Signing up. I agree to Kravein&apos;s <a>Terms of Service</a> and{' '}
            <a>Privacy Policy</a>. I understand that the content post is public. I also consent to
            receiving information events, deals,dishes, items or places on Kravein.
          </Text>
          <br />
          <Text color="darkGrey" size="micro">
            Already on Kravein&apos;s? <a onClick={handleSignupModal}>Sign in</a>
          </Text>
        </section>
      </div>
    </Modal>
  );
};

export default withRouter(DeskTopSignInModal);
