import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MdSearch } from 'react-icons/md';
import { FaTimesCircle } from 'react-icons/fa';
import colors from '../../theme/styles/colors';

const Container = styled.div`
  width: 100%;
  .icon {
    pointer-events: initial !important;
  }
  .input {
    box-shadow: 0 0 0 0.08em #f0f0f0 !important;
    border: 0;
    text-transform: capitalize;
  }
  .input:focus {
    border-color: #eeeeee !important;
    box-shadow: 0 0 0 0.08em #f0f0f0 !important;
  }
  .input:hover {
    border-color: #eeeeee !important;
    box-shadow: 0 0 0 0.08em #f0f0f0 !important;
  }
`;

const SearchInput = ({ className, value, onClear, leftIcon, hideRightIcon, ...props }) => {
  return (
    <Container className="field">
      <p className="control has-icons-left has-icons-right">
        <input className={`input is-fullwidth ${className}`} type="text" value={value} {...props} />
        <span className="icon is-small is-left">{leftIcon}</span>
        {!hideRightIcon && value.length !== 0 && (
          <span className="icon is-small is-right" onClick={onClear}>
            <FaTimesCircle size={16} color={colors.specialGrey} />
          </span>
        )}
      </p>
    </Container>
  );
};

SearchInput.defaultProps = {
  value: '',
  onClear: () => {},
  hideRightIcon: false,
  leftIcon: <MdSearch size={20} color={colors.coolGrey} />,
};

SearchInput.propTypes = {
  value: PropTypes.string,
  onClear: PropTypes.func,
  hideRightIcon: PropTypes.bool,
  leftIcon: PropTypes.node,
};

export default SearchInput;
