import React from 'react';
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';

const PoseModal = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 300 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 },
  },
});

const Container = styled(PoseModal)`
  && {
    justify-content: flex-start;
    z-index: 2000;
    .modal-background {
      background-color: #ffffff;
    }
  }
`;

const Shade = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});

const Modal = ({ isActive, children }) => (
  <PoseGroup>
    <Container key="modal" className={`modal ${isActive && 'is-active'}`}>
      <Shade key="shade" className="modal-background" />
      {children}
    </Container>
  </PoseGroup>
);

export default Modal;
