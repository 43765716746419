import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import MobileTabItem from './MobileTabItem';

const Container = styled.div``;

const MobileView = ({ children }) => (
  <>
    <Container>
      <a>
        <div>
          <MobileTabItem name="Frequently Asked Questions (FAQ's)" />
        </div>
      </a>
      <Link to="contact-us">
        <div>
          <MobileTabItem name="Contact Us" />
        </div>
      </Link>
      <Link to="feedback">
        <div>
          <MobileTabItem name="Feedback" />
        </div>
      </Link>
      <a>
        <div>
          <MobileTabItem name="Terms of Service" />
        </div>
      </a>
      <a>
        <div>
          <MobileTabItem name="Privacy Policy" />
        </div>
      </a>
      <a>
        <div>
          <MobileTabItem name="Add your Listing" />
        </div>
      </a>
      <a>
        <div>
          <MobileTabItem name="Claim your Listing" />
        </div>
      </a>
    </Container>
    {children}
  </>
);

export default MobileView;
