import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import {
  Field,
  CheckBox,
  Button,
  FlexColumns,
  Text,
  Notification,
} from '../../components/elements';
import { trackEvent } from '../../utils/analytics';

const Container = styled.div`
  padding: 1rem;
`;

const FieldWrapper = styled.div`
  padding: 1rem 0rem;
`;

const FieldStyle = styled(Field).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;

const ButtonWrapper = styled(FlexColumns)`
  padding: 0rem 1rem;
`;
const ButtonStyle = styled(Button)`
  &&&& {
    border-radius: 0rem;
    padding: 1.5rem;
  }
`;

const Form = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isLoading,
  isError,
  clearError,
}) => (
  <Container>
    {isError.error && <Notification message={isError.message} onClose={() => clearError()} />}
    <FieldWrapper>
      <FieldStyle
        label="Email"
        placeholder="john123@gmail.com"
        name="username"
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.username}
      />
      {errors.username && touched.username && (
        <div className="has-text-danger">{errors.username}</div>
      )}
    </FieldWrapper>

    <FieldWrapper>
      <FieldStyle
        label="password"
        placeholder="••••••••••••••"
        rightIcon="fas fa-eye-slash"
        name="password"
        type="password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
      />
      {errors.password && touched.password && (
        <div className="has-text-danger">{errors.password}</div>
      )}
    </FieldWrapper>

    <FieldWrapper>
      <CheckBox
        label="Remember me"
        onChange={value => console.log(value)}
        labelStyle={{ weight: 'regular' }}
      />
    </FieldWrapper>
    <FieldWrapper>
      <Text color="coolGrey" size="micro">
        By Signing in, i accept the Terms of Services & Private Policy
      </Text>
    </FieldWrapper>
    <ButtonWrapper>
      <ButtonStyle
        name={isLoading ? 'Processing...' : 'Sign in'}
        backgroundColor="primaryColor"
        textStyle={{ color: 'white', weight: 'bold', size: 'large' }}
        onClick={handleSubmit}
      />
      <br />
      <a>
        <Text className="is-uppercase" color="primaryColor">
          Forgot Password ?
        </Text>
      </a>
    </ButtonWrapper>
  </Container>
);

const MyEnhancedForm = withFormik({
  mapPropsToValues: () => ({ username: '', password: '' }),

  // Custom sync validation
  validate: values => {
    const errors = {};
    if (!values.username) {
      errors.username = 'Required';
    }
    if (!values.password) {
      errors.password = 'Required';
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  },
  displayName: 'LoginForm',
})(Form);

export default MyEnhancedForm;
