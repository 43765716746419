import React, { useEffect, useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';

import Layout from '../../components/global/MenuLayout';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import { getCart, parseOrderDatabaseInputItems } from './helpers';
import { isDesktop } from '../../utils/helpers';
import Loading from '../../components/global/mobile/MobileLoading';
import apolloSubscriptionClient from '../../utils/apolloSubscriptionClient';
import client from '../../utils/apolloClient';

const COMMENTS_SUBSCRIPTION = gql`
  subscription subscribe_to_menu_order($menu_order_id: String) {
    subscribe_to_menu_order(menu_order_id: $menu_order_id) {
      menu_order_id
      preferred_time
      status
      payment {
        payment_method
      }
      menu_name
      large_order
      no_of_attendees
      payment_preference
      address {
        type
        address_line_1
        address_line_2
        city
        state
        post_code
        country
      }
      place_id
      include_cuttlery
      order_reference
      service_type
      status_history {
        status
        note {
          value
        }
        created_at
      }

      overall_quantity
      line_item {
        name
        variant_name
        price
        overall_price
        modifier {
          name
          variant_name
          price
          price_per_item
          net_price_per_item
        }
        special_request
        quantity
      }
      contact {
        contact_name
        contact_phone
      }
      billing {
        sub_total
        order_total
        deal_discount
        adjustment_amount
        order_amount
        delivery_fee
      }
      adjustments {
        value
      }
      estimates {
        type
        value_in_mins
      }
      audit {
        created_at
        created_by
      }
      error {
        description
      }
    }
  }
`;

const actionOrderMutation = gql`
  mutation actionOrder($input: NavigateMenuOrderInput) {
    action_menu_order(input: $input) {
      menu_order_id
      status
      error {
        description
      }
    }
  }
`;

const View = ({ order, loading, handleStatus }) => {
  const isMobile = !isDesktop();
  const { userId } = useStoreState(state => state.auth);

  if (loading) {
    return <Loading />;
  }

  const lineItem = order ? parseOrderDatabaseInputItems(order.line_item) : [];

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={actionOrderMutation}
      onCompleted={({ action_menu_order }) => {
        if (action_menu_order.error) {
          action_menu_order.error.map(item => toast.error(item.description));
        } else {
          handleStatus(action_menu_order);
        }
      }}
    >
      {(action_menu_order, { loading: actionLoading }) => (
        <>
          {isMobile ? (
            <div className="is-hidden-desktop">
              <MobileView />
            </div>
          ) : (
            <div className="is-hidden-touch">
              <DesktopView
                userId={userId}
                order={{ ...order, line_item: lineItem }}
                actionLoading={actionLoading}
                onCancel={() => {
                  swal({
                    title: 'Are you sure ?',
                    text: 'Your order has not been confirmed so there will be no charge to cancel.',
                    icon: 'warning',
                    buttons: ['No do not cancel', 'Cancel Order'],
                    dangerMode: true,
                  }).then(willDelete => {
                    if (willDelete) {
                      const input = {
                        user_id: userId,
                        menu_order_id: order.menu_order_id,
                        action: 'CANCELRECEIVED',
                      };

                      action_menu_order({ variables: { input } });
                    }
                  });
                }}
              />
            </div>
          )}
        </>
      )}
    </Mutation>
  );
};

const OrderDetails = ({ match, location }) => {
  const { menuOrderId } = match.params;
  const [order, setOrder] = useState({ line_item: [] });
  const [cartLoading, setCartLoading] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState();

  const fetchCart = useCallback(async () => {
    setCartLoading(true);
    try {
      const list = await getCart({
        filter: { menu_order_filter: { menu_order_id: menuOrderId } },
      });
      if (list.length !== 0) {
        setOrder(list[0]);
      }

      setCartLoading(false);
    } catch (err) {
      console.error(err);
      setCartLoading(false);
    }
  }, [menuOrderId]);

  useEffect(() => {
    apolloSubscriptionClient
      .hydrated()
      .then(clientSubscription => {
        // Now subscribe to results
        const observable = clientSubscription.subscribe({
          query: COMMENTS_SUBSCRIPTION,
          variables: {
            menu_order_id: menuOrderId,
          },
        });
        const realtimeResults = function realtimeResults(data) {
          setSubscriptionData(data.data.subscribe_to_menu_order);
          console.log('data.data.subscribe_to_menu_order', data.data.subscribe_to_menu_order);
        };
        observable.subscribe({
          next: realtimeResults,
          complete: console.log,
          error: console.log,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, [menuOrderId]);

  useEffect(() => {
    if (location && location.state && location.state.order) {
      setOrder(location.state.order);
      setCartLoading(false);
    } else {
      fetchCart();
    }
  }, [fetchCart, location, location.state]);

  const orderData = order;
  Object.assign(orderData, subscriptionData && { status: subscriptionData.status });

  return (
    <Layout disableCart>
      <View order={orderData} loading={cartLoading} handleStatus={setSubscriptionData} />
    </Layout>
  );
};

export default OrderDetails;
