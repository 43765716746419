import React from 'react';
import Autosuggest from 'react-autosuggest';
import { omit } from 'lodash';

import Input from './Input';

const renderSuggestionsContainer = ({ containerProps, children }) => (
  <div {...containerProps}>{children}</div>
);

const Field = ({ inputProps }) => {
  const props = omit(inputProps, ['className', 'width']);
  const { leftIcon, rightIcon, onClickRightIcon, inputClass } = inputProps;
  return (
    <div className={`control ${leftIcon && 'has-icons-left'} ${leftIcon && 'has-icons-right'}`}>
      <Input
        className={`is-fullwidth ${inputClass}`}
        style={inputProps.width && { width: inputProps.width, maxWidth: inputProps.maxWidth }}
        {...props}
      />
      {leftIcon && <span className="icon is-small is-left">{leftIcon}</span>}
      {rightIcon && (
        <span className="icon is-small is-right" onClick={onClickRightIcon}>
          {rightIcon}
        </span>
      )}
    </div>
  );
};

const renderInputComponent = inputProps => <Field inputProps={inputProps} />;

const AutoCompleteInput = ({
  value,
  placeholder,
  leftIcon,
  rightIcon,
  suggestions,
  onChange,
  onSuggestionSelected,
  onBlur,
  getSuggestionValue,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  renderSuggestion,
  width,
  maxWidth,
  liteInputType,
  disabled,
  onClickRightIcon,
  inputClass,
  customRenderSuggestionsContainer,
}) => {
  const inputProps = {
    placeholder,
    value,
    onChange,
    onBlur,
    leftIcon,
    rightIcon,
    addOnButton: '',
    width,
    maxWidth,
    lite: !!liteInputType,
    disabled,
    onClickRightIcon,
    inputClass,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={customRenderSuggestionsContainer || renderSuggestionsContainer}
      focusInputOnSuggestionClick
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};

export default AutoCompleteInput;
