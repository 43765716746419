import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { isNull } from 'lodash';
import { Text, ShowMoreText, Loading } from '../../../../components/elements';
import { getZoneTime } from '../../../../utils/eventHelpers';

const dealsQuery = gql`
  query search_place_deal($input: SearchInput) {
    search_place_deal(input: $input) {
      place_deal_listing {
        place_id
        deal_id
        name
        voucher_code
        description
        tagline
        audit {
          created_at
        }
      }
    }
  }
`;

const Container = styled.div`
  padding-top: 1rem;
`;

const Header = styled.div`
  padding: 1rem 0rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  margin-bottom: 2rem;
`;

const Content = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  border-radius: 6px;
  margin-bottom: 1rem;
`;

const Deals = ({ place, handleElement }) => {
  return (
    <Container>
      <Header>
        <Text size="large" weight="semibold">
          Deals
        </Text>
      </Header>
      <Query
        query={dealsQuery}
        variables={{
          input: {
            filter: {
              place_deal_filter: {
                place_id: place.place_id,
                status: 'ACTIVE',
                end_date_range: {
                  start_date: getZoneTime(moment(), place.latitude, place.longitude),
                },
              },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading }) => {
          if (loading) {
            return <Loading />;
          }

          if (isNull(data.search_place_deal)) {
            return (
              <Text size="small" color="darkGrey">
                No deals available at this moment!
              </Text>
            );
          }

          return (
            <View deals={data.search_place_deal.place_deal_listing} handleElement={handleElement} />
          );
        }}
      </Query>
    </Container>
  );
};

const View = ({ deals, handleElement }) => {
  useEffect(() => {
    if (deals.length === 0) {
      handleElement('dealsElements');
    }
  }, [handleElement, deals.length]);

  if (deals.length === 0) {
    return (
      <Text size="small" color="darkGrey">
        No deals available at this moment!
      </Text>
    );
  }

  return (
    <>
      {deals.map(deal => (
        <>
          <Content key={deal.deal_id}>
            <Text size="lmedium" weight="semibold" color="supportingColor2" paddingBottom={0.2}>
              {deal.name}
            </Text>
            <Text size="micro" color="darkGrey" paddingBottom={0.5}>
              {moment(deal.audit.created_at).format('LLLL')}
            </Text>
            <ShowMoreText size="tiny" color="darkGrey" content={deal.description} lines={3} />
            {deal.voucher_code && (
              <>
                <br />
                <div className="is-flex">
                  <Text className="is-uppercase" size="small" color="darkGrey" weight="regular">
                    To Claim User Voucher Code :
                  </Text>
                  &nbsp;
                  <Text className="is-uppercase" size="small" color="darkGrey" weight="semibold">
                    {deal.voucher_code}
                  </Text>
                </div>
              </>
            )}
          </Content>
        </>
      ))}
    </>
  );
};

export default Deals;
