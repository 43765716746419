import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { first, isNull } from 'lodash';
import gql from 'graphql-tag';
import { useStoreState, useStoreActions } from 'easy-peasy';
import uuid from 'uuid';

import { Mutation } from 'react-apollo';
import { FlexRows, Icon, Text, Button, FlexColumns, Avatar } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';
import client from '../../../../utils/apolloClient';
import ImageCropModal from '../../../../components/ImageCropModal';
import { getEncodedImage } from '../../../../utils/s3';

const createImageMutation = gql`
  mutation createImage($input: ImageInput, $imageInput: UserInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
    update_user(input: $imageInput) {
      user_id
      error {
        description
      }
    }
  }
`;

const updateImageMutation = gql`
  mutation createImage($input: ImageInput, $imageInput: UserInput) {
    update_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
    update_user(input: $imageInput) {
      user_id
      error {
        description
      }
    }
  }
`;

const Container = styled(FlexRows)`
  padding: 2rem 0rem;
`;

const TextStyle = styled(Text)`
  padding-left: 2rem;
`;

const Span = styled.span`
  color: ${colors.dark} !important;
`;

const PageHeader = ({ user, history }) => {
  const [isActiveImageUploadModal, setIsActiveImageUploadModal] = useState(false);
  const { setUserImage } = useStoreActions(state => state.auth);
  const { userId, userImage } = useStoreState(state => state.auth);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const newImageId = uuid();

  return (
    <Container className="container" justify="space-between">
      <FlexRows>
        <Avatar
          name={user.display_name}
          borderRadius={80}
          maskProps={{ width: 140 }}
          maskTextProps={{
            color: 'white',
            weight: 'bold',
            size: 'h1',
            letterSpacing: 'loose',
          }}
          image={userImage}
          color={user.color_code}
          isEncodedImage={false}
        />
        <div>
          <TextStyle size="big" weight="semibold" paddingBottom={0.4}>
            {user && user.first_name} {user && user.last_name}
          </TextStyle>
          <TextStyle size="small" weight="semibold" color="darkGrey" paddingBottom={0.2}>
            @{user.user_handle}
          </TextStyle>
          <TextStyle size="tiny" weight="semibold" color="darkGrey">
            <Span>{user.followerCount}</Span> Follower &nbsp;
            <Span>{user.followingCount}</Span> Following
          </TextStyle>
        </div>
      </FlexRows>
      <FlexColumns justify="flex-end" align="justify">
        <Button
          name="Update Profile"
          textProps={{ size: 'small', weight: 'semibold' }}
          onClick={() => history.push(`/profile-update/`)}
        >
          <span className="icon is-small">
            <Icon name="profileEditBlue" />
          </span>
        </Button>
        <br />
        <Button
          className={isUploadingImage && 'is-loading'}
          name="Add / Edit Profile Photos"
          textProps={{
            size: 'small',
            weight: 'semibold',
            color: isUploadingImage ? 'white' : 'dark',
          }}
          onClick={() => setIsActiveImageUploadModal(true)}
        >
          <span className="icon is-small">
            <Icon name="photoAddSolid" />
          </span>
        </Button>
      </FlexColumns>
      {isNull(user.profile_image) ? (
        <Mutation
          client={client.clientPrivate}
          mutation={createImageMutation}
          onCompleted={({ create_image }) => {
            setUserImage(getEncodedImage(first(create_image).url, 128, 128));
            setIsUploadingImage(false);
          }}
        >
          {(create_image, { loading }) => (
            <ImageCropModal
              loading={loading}
              isActive={isActiveImageUploadModal}
              onClose={() => setIsActiveImageUploadModal(false)}
              onChange={imageUrl => {
                setIsUploadingImage(true);
                setIsActiveImageUploadModal(false);
                create_image({
                  variables: {
                    imageInput: {
                      user_id: userId,
                      object_id: userId,
                      profile_image: [{ image_id: newImageId }],
                    },
                    input: {
                      user_id: userId,
                      object_id: userId,
                      object_type: 'USER_IMAGE',
                      parent_id: userId,
                      parent_type: 'USER_IMAGE',
                      upload_type: 'USER_IMAGE',
                      links_to: [
                        {
                          id: userId,
                          type: 'USER_IMAGE',
                        },
                      ],
                      images: [{ image_id: newImageId, url: imageUrl.url }],
                    },
                  },
                });
              }}
            />
          )}
        </Mutation>
      ) : (
        <Mutation
          client={client.clientPrivate}
          mutation={updateImageMutation}
          onCompleted={({ update_image }) => {
            setUserImage(getEncodedImage(first(update_image).url, 128, 128));
            setIsUploadingImage(false);
          }}
        >
          {update_image => (
            <ImageCropModal
              isActive={isActiveImageUploadModal}
              onClose={() => setIsActiveImageUploadModal(false)}
              onChange={imageUrl => {
                setIsUploadingImage(true);
                setIsActiveImageUploadModal(false);
                update_image({
                  variables: {
                    imageInput: {
                      user_id: userId,
                      object_id: userId,
                      profile_image: [{ image_id: first(user.profile_image).image_id }],
                    },
                    input: {
                      user_id: userId,
                      object_id: userId,
                      object_type: 'USER_IMAGE',
                      parent_id: userId,
                      parent_type: 'USER_IMAGE',
                      upload_type: 'USER_IMAGE',
                      links_to: [
                        {
                          id: userId,
                          type: 'USER_IMAGE',
                        },
                      ],
                      images: [{ image_id: first(user.profile_image).image_id, url: imageUrl.url }],
                    },
                  },
                });
              }}
            />
          )}
        </Mutation>
      )}
    </Container>
  );
};

export default withRouter(PageHeader);
