import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {
  Text,
  FlexRows,
  FlexItem,
  FlexColumns,
  Icon,
  Button,
  Image,
} from '../../../../components/elements';
import ActionButtons from './ActionsButtons';
import dummy from '../../../../assets/images/food.jpg';
import { getEncodedImage } from '../../../../utils/s3';

const Container = styled.div`
  padding: 0.5rem 0rem;
  margin: 0rem 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const Content = styled(FlexRows)`
  padding: 0.5rem 0rem;
`;

const Figure = styled.figure`
  && {
    img {
      border-radius: 4px;
    }
  }
`;

const EmptyWrapper = styled.div`
  &&& {
    position: relative;
    .img-opacity {
      opacity: 0.6;
    }
  }
`;

const AddPhotosButtonWrapper = styled.div`
  right: 1rem;
  position: absolute;
  bottom: 1rem;
  z-index: 2;
`;

const EmptyImage = ({ itemId, handleOnClickAddPhotoButton }) => (
  <EmptyWrapper>
    <AddPhotosButtonWrapper>
      <Button
        name="Add Photo"
        icon="photoAddSolid"
        textProps={{ size: 'small' }}
        onClick={() => handleOnClickAddPhotoButton(`/add-item-photos/${itemId}`)}
      >
        <span className="icon is-small">
          <Icon name="photoAddSolid" />
        </span>
      </Button>
    </AddPhotosButtonWrapper>
    <Link to={`/item/${itemId}`}>
      <Figure className="image is-3by2 img-opacity">
        <Image source={dummy} />
      </Figure>
    </Link>
  </EmptyWrapper>
);

const ImageView = ({ itemId, handleOnClickAddPhotoButton, itemImage }) => {
  if (itemImage === null) {
    return <EmptyImage itemId={itemId} handleOnClickAddPhotoButton={handleOnClickAddPhotoButton} />;
  }
  return (
    <Link to={`/item/${itemId}`}>
      <Figure className="image image is-3by2">
        <Image source={getEncodedImage(itemImage, 1024, 682)} />
      </Figure>
    </Link>
  );
};

const ItemCard = ({ item, history }) => {
  const handleOnClickAddPhotoButton = path => {
    history.push(path);
  };

  return (
    <Container>
      <Link to={`/item/${item.item_id}`}>
        <Content justify="space-between" align="flex-end">
          <FlexItem grow={6}>
            <FlexColumns align="flex-start">
              <Text className="is-capitalized" size="small" weight="semibold">
                {item.name}
              </Text>

              <Text className="is-capitalized	" weight="tiny" color="dark" size="small">
                {item.place_name}
              </Text>
              <Text className="is-capitalized	" weight="semibold" color="specialGrey" size="tiny">
                95% Loved it
              </Text>
            </FlexColumns>
          </FlexItem>
          <FlexItem grow={1}>
            <Text color="specialGrey">
              <MdKeyboardArrowRight size={25} />
            </Text>
          </FlexItem>
        </Content>
      </Link>
      <ImageView
        itemId={item.item_id}
        handleOnClickAddPhotoButton={handleOnClickAddPhotoButton}
        itemImage={item.default_image_url}
      />
      <ActionButtons item={item} />
    </Container>
  );
};

export default withRouter(ItemCard);
