import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FlexColumns from './FlexColumns';
import Text from './Text';

const Container = styled(FlexColumns)`
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 50;
  background: ${({ theme: { colors }, backgroundColor }) => colors.get(backgroundColor)};
`;

const PageHeader = ({ text, subTitle, backgroundColor, textColor }) => (
  <Container backgroundColor={backgroundColor}>
    <Text className="is-uppercase" weight="bold" letterSpacing="loose" color={textColor}>
      {text}
    </Text>
    {subTitle && (
      <Text className="is-uppercase" weight="bold" letterSpacing="loose" size="tiny">
        {subTitle}
      </Text>
    )}
  </Container>
);

PageHeader.defaultProps = {
  text: '',
  subTitle: '',
  backgroundColor: 'lightGreyBeta',
  textColor: 'dark',
};

PageHeader.propTypes = {
  text: PropTypes.string,
  subTitle: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
};

export default PageHeader;
