import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  &&& {
    display: flex;
    justify-content: ${({ justify }) => justify};
    align-items: ${({ align }) => align};
    flex-direction: ${({ direction }) => direction};
  }
`;

const Flex = ({ children, direction, justify, align, ...props }) => (
  <Container justify={justify} align={align} {...props}>
    {children}
  </Container>
);

Flex.defaultProps = {
  justify: 'center',
  align: 'center',
  direction: 'row',
};

Flex.propTypes = {
  justify: PropTypes.string,
  align: PropTypes.string,
  direction: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Flex;
