import React from 'react';
import styled from 'styled-components';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import colors from '../../theme/styles/colors';

import Text from './Text';

const Wrapper = styled.div`
  /* border-bottom: 1px solid
    ${({ borderBottomColor }) =>
      borderBottomColor ? colors[borderBottomColor] : 'transparent'}; */
  &&& {
    .react-tel-input .flag-dropdown {
      background-color: transparent;
      border: 0;
      border-radius: 0;
    }
    .react-tel-input input[type='text'],
    .react-tel-input input[type='tel'] {
      background: transparent;
      /* border: 0; */
      width: 100%;
      box-shadow: 0 0 0 0;
    }
  }
`;

const Span = styled.span`
  &&& {
    font-size: 11px;
    color: #737373;
  }
`;

const MobileInput = ({
  className,
  borderBottomColor,
  labelTextProps,
  optional,
  label,
  value,
  onChange,
  ...props
}) => {
  const [phone, setPhone] = React.useState(value || '');

  const handleChange = number => {
    setPhone(number);
    onChange(number);
  };

  return (
    <Wrapper className="field" borderBottomColor={borderBottomColor}>
      {label && (
        <label className="label">
          <Text {...labelTextProps}>
            {label} {optional && <Span>(OPTIONAL)</Span>}
          </Text>
        </label>
      )}
      <ReactPhoneInput
        country="au"
        inputClass={`input ${className}`}
        value={phone}
        onChange={input => handleChange(input)}
        {...props}
      />
    </Wrapper>
  );
};

export default MobileInput;
