import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

import { Text, Button, ReactDatePicker, FlexRows } from '../../../../components/elements';
import { getZoneTime } from '../../../../utils/eventHelpers';

const Container = styled.nav`
  padding: 0.5rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  &&& {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
`;
const Wrapper = styled.nav`
  padding: 0.5rem;
`;
const Heading = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding-top: 0.25rem;
  padding-right: 1rem;
`;

const DateFilter = () => {
  const dateFilter = useStoreState(state => state.event.dateFilter);
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const updateDateFilter = useStoreActions(state => state.event.updateDateFilter);
  const [toggle, setToggle] = useState(true);
  const [start, setStart] = useState(
    dateFilter.range.start_date_range.start_date
      ? dateFilter.range.start_date_range.start_date
      : null,
  );
  const [end, setEnd] = useState(null);

  const onHandleClearItem = () => {
    setEnd(null);
    setStart(null);
    updateDateFilter({
      name: 'upcoming-event',
      description: 'Upcoming Events',
      range: {
        start_date_range: {
          start_date: moment()
            .add(1, 'day')
            .format('YYYY-MM-DD'),
        },
      },
    });
  };

  const onHandleUpdate = () => {
    const startDate = getZoneTime(moment(start), usedLocation.latitude, usedLocation.longitude);
    const endDate = getZoneTime(
      moment(`${moment(end).format('L')} 11:59 PM`),
      usedLocation.latitude,
      usedLocation.longitude,
    );
    updateDateFilter({
      name: 'upcoming-event',
      description: 'Upcoming Events',
      range: {
        start_date_range: {
          end_date: moment(endDate).toISOString(),
        },
        end_date_range: {
          start_date: moment(startDate).toISOString(),
        },
      },
    });
  };

  if (dateFilter.name !== 'upcoming-event') {
    return null;
  }

  return (
    <Container>
      <Heading>
        <div className="is-flex">
          <span className="icon">
            <a onClick={() => setToggle(!toggle)}>
              {toggle ? (
                <Text color="dark" size="smaller" weight="semibold">
                  <FaAngleUp />
                </Text>
              ) : (
                <Text color="dark" size="smaller" weight="semibold">
                  <FaAngleDown />
                </Text>
              )}
            </a>
          </span>
          <Text className="is-uppercase" size="tiny" weight="semibold">
            Select Date Range
          </Text>
        </div>
        <a onClick={onHandleClearItem}>
          <Text className="is-uppercase" color="primaryColor" size="tiny" weight="semibold">
            Reset
          </Text>
        </a>
      </Heading>
      {toggle && (
        <>
          <Wrapper>
            <ReactDatePicker
              minDate={new Date(moment().add(1, 'day'))}
              value={start}
              placeholderText="From"
              onChange={value => {
                setStart(value);
              }}
            />
          </Wrapper>
          <Wrapper>
            <ReactDatePicker
              minDate={new Date(start)}
              value={end}
              placeholderText="To"
              onChange={value => {
                setEnd(value);
              }}
            />
          </Wrapper>
          <Wrapper>
            <Button
              name="Update"
              backgroundColor="primaryColor"
              textProps={{ color: 'white', weight: 'semibold', size: 'small' }}
              onClick={onHandleUpdate}
            />
          </Wrapper>
        </>
      )}
    </Container>
  );
};

export default DateFilter;
