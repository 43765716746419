import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
// import ReactDOM from 'react-dom';

import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Text from './Text';
// import { trackEvent } from '../../utils/analytics';

// import Icon from './Icon';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 0.5rem 0rem;
  width: 100%;
  border-radius: 8px;
  margin: 1rem 1rem 0rem 1rem;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  .DateRangePickerInput__withBorder {
    border: 0;
  }
  @media only screen and (max-width: 380px) {
    .DateInput {
      margin: 0;
      width: 114px;
    }
  }

  .DateInput_input {
    border: 1px solid ${({ theme: { colors } }) => colors.specialGrey};
    padding: 7px 13px;
    /* text-align: center; */
    font-size: 14px;
    border-radius: 8px;
  }
  .DateRangePickerInput_arrow_svg {
    fill: #ffffff;
  }

  .DateRangePickerInput {
    background-color: #fff;
    display: flex;
  }
  table td:not([align]),
  table th:not([align]) {
    text-align: center;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #209cee;
    border: 1px double #118fe4;
    color: #fff;
  }
  .CalendarDay__selected_span {
    background: #209ceeb8;
    border: 1px double #209cee94;
    color: #fff;
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #118fe4;
    border: 1px double #209cee;
    color: #fff;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #118fe4;
    border: 1px double #209cee;
    color: #fff;
  }

  .CalendarDay__selected {
    background: #118fe4;
  }
`;

const TextStyle = styled(Text)`
  align-self: center;
  padding-left: 0.5rem;
`;

const DatePicker = ({ handleEventDate }) => {
  const [dates, setDates] = React.useState({ startDate: null, endDate: null });
  const [focusedInput, setFocusedInput] = React.useState(null);

  const onDatesChange = (startDate, endDate) => {
    setDates({ startDate, endDate });
  };

  const onFocusChange = focused => {
    setFocusedInput(focused);
  };

  const onHandleUpdate = () => {
    handleEventDate({
      name: 'upcoming-event',
      description: 'Upcoming Events',
      range: {
        start_date_range: {
          start_date: moment(dates.startDate)
            .add(1, 'day')
            .format('YYYY-MM-DD'),
          end_date: moment(dates.endDate)
            .add(6, 'months')
            .format('YYYY-MM-DD'),
        },
        end_date_range: '',
      },
    });
  };

  return (
    <Wrapper>
      <DateRangePicker
        numberOfMonths={1}
        startDate={dates.startDate}
        startDateId="your_unique_start_date_id"
        endDate={dates.endDate}
        endDateId="your_unique_end_date_id"
        onDatesChange={({ startDate, endDate }) => onDatesChange(startDate, endDate)}
        focusedInput={focusedInput}
        onFocusChange={value => onFocusChange(value)}
        readOnly
        customArrowIcon=""
        showDefaultInputIcon={false}
        // showDefaultInputIcon
      />

      <TextStyle
        onClick={() => onHandleUpdate()}
        className="is-uppercase"
        color="primaryColor"
        weight="bold"
        size="small"
      >
        Update
      </TextStyle>

      {/* <DatePickerIconPortal /> */}
    </Wrapper>
  );
};

export default DatePicker;
