import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isNull, omit, first } from 'lodash';
import { MdAdd } from 'react-icons/md';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

import { Text, FlexRows, FlexColumns } from '../../../../../components/elements';
import Address from './Address';
import AddressForm from './AddressForm';
import client from '../../../../../utils/apolloClient';

const Heading = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding: 1rem;
`;
const Wrapper = styled.div`
  padding: 1rem;
`;

const AddAddressButtonContainer = styled(FlexColumns)`
  border: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  padding: 3rem;
`;

const createAddressMutation = gql`
  mutation create_user_address($input: UserAddressInput) {
    create_user_address(input: $input) {
      address_id
      type
      is_default
      contact_name
      contact_phone
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      timezone
      status
      error {
        description
      }
    }
  }
`;
const updateAddressMutation = gql`
  mutation update_user_address($input: UserAddressInput) {
    update_user_address(input: $input) {
      address_id
      type
      is_default
      contact_name
      contact_phone
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      timezone
      status
      error {
        description
      }
    }
  }
`;

const AddAddressButton = ({ onClick }) => (
  <a onClick={onClick}>
    <AddAddressButtonContainer>
      <div>
        <Text color="darkGrey" weight="semibold">
          <MdAdd size={25} />
        </Text>
      </div>
      <div>
        <Text color="darkGrey" weight="semibold">
          Add New Address
        </Text>
      </div>
    </AddAddressButtonContainer>
  </a>
);

const MyAddress = ({ addresses }) => {
  const { userId } = useStoreState(state => state.auth);
  const [addEditAction, setAddEditAction] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editAddressData, setEditAddressData] = useState();
  const [addressesData, setAddressesData] = useState(addresses);
  const [defaultAddress, setDefaultAddress] = useState({});

  useEffect(() => {
    const defaultAddressData = addressesData
      ? addressesData.filter(item => item.is_default === true)
      : {};
    if (defaultAddressData.length !== 0) {
      setDefaultAddress(first(defaultAddressData));
    }
  }, [addressesData]);

  const onHandleDelete = id => {
    const updateAddress = addressesData.filter(item => item.address_id !== id);
    setAddressesData(updateAddress);
  };

  const handleChangeDefault = async address => {
    if (defaultAddress) {
      const input = omit(defaultAddress, ['is_default', 'location', '__typename']);
      await client.clientPrivate
        .query({
          query: updateAddressMutation,
          variables: {
            input: { user_id: userId, is_default: false, ...input },
          },
        })
        .then(() => {})
        .catch(() => {});
    }
    setDefaultAddress(address);
    const input2 = omit(address, ['is_default', 'location', '__typename']);
    client.clientPrivate
      .query({
        query: updateAddressMutation,
        variables: {
          input: { user_id: userId, is_default: true, ...input2 },
        },
      })
      .then(() => {})
      .catch(() => {});
  };

  const handleCompleteCreate = async address => {
    if (defaultAddress) {
      const input = omit(defaultAddress, ['is_default', 'location', '__typename']);
      await client.clientPrivate
        .query({
          query: updateAddressMutation,
          variables: {
            input: { user_id: userId, is_default: false, ...input },
          },
        })
        .then(() => {})
        .catch(() => {});
    }
    setDefaultAddress(address);
  };

  return (
    <>
      <Heading>
        <Text className="is-capitalized" size="large" weight="semibold">
          My Address Details
        </Text>
      </Heading>

      {!addEditAction && !editAddress && (
        <Wrapper>
          <div className="columns is-multiline">
            {addressesData &&
              addressesData.length !== 0 &&
              addressesData.map(address => (
                <>
                  <div className="column is-6" key={address.address_id}>
                    <Address
                      address={address}
                      defaultAddress={defaultAddress}
                      handleEditAddress={() => {
                        setEditAddress(true);
                        setEditAddressData(address);
                      }}
                      onHandleDelete={onHandleDelete}
                      handleChangeDefault={handleChangeDefault}
                    />
                  </div>
                </>
              ))}

            <div className="column is-6">
              <AddAddressButton onClick={() => setAddEditAction(true)} />
              {addressesData && isNull(addressesData) && addressesData.length === 0 && (
                <>
                  <br />
                  <Text color="darkGrey" weight="semibold">
                    No Saved Address
                  </Text>
                </>
              )}
            </div>
          </div>
        </Wrapper>
      )}

      {addEditAction && (
        <Mutation
          client={client.clientPrivate}
          mutation={createAddressMutation}
          onCompleted={({ create_user_address }) => {
            if (!isNull(create_user_address.error)) {
              create_user_address.error.map(item => toast.error(item.description));
            } else {
              const updateAddress = addressesData.concat(create_user_address);
              setAddressesData(updateAddress);
              swal('Great!', 'Address had been created successfully!', 'success').then(() => {
                setAddEditAction(false);
              });
              handleCompleteCreate(create_user_address);
            }
          }}
        >
          {(create_user_address, { loading }) => (
            <AddressForm
              isUpdateFormType={false}
              loading={loading}
              onCancel={() => {
                setAddEditAction(false);
                setEditAddress(false);
              }}
              onSubmit={values => {
                const input = omit(values, 'additionalInfo');
                create_user_address({
                  variables: { input: { user_id: userId, ...input } },
                });
              }}
            />
          )}
        </Mutation>
      )}
      {editAddress && editAddressData && (
        <Mutation
          client={client.clientPrivate}
          mutation={updateAddressMutation}
          onCompleted={({ update_user_address }) => {
            if (!isNull(update_user_address.error)) {
              update_user_address.error.map(item => toast.error(item.description));
            } else {
              const deleteExistedAddress = addressesData.filter(
                item => item.address_id !== update_user_address.address_id,
              );
              const updateAddress = deleteExistedAddress.concat(update_user_address);
              setAddressesData(updateAddress);
              swal('Great!', 'Address had been updated successfully!', 'success').then(() => {
                setEditAddress(false);
                setEditAddressData();
              });
            }
          }}
        >
          {(update_user_address, { loading }) => (
            <AddressForm
              isUpdateFormType
              address={editAddressData}
              loading={loading}
              onCancel={() => {
                setAddEditAction(false);
                setEditAddress(false);
              }}
              onSubmit={values => {
                const input = omit(values, 'additionalInfo');
                update_user_address({
                  variables: { input: { user_id: userId, ...input } },
                });
              }}
            />
          )}
        </Mutation>
      )}
    </>
  );
};

export default MyAddress;
