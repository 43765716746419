import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FaAngleRight } from 'react-icons/fa';
import { MdAccessTime } from 'react-icons/md';
import { GoLocation } from 'react-icons/go';
import { first } from 'lodash';
import { useStoreState } from 'easy-peasy';

import { Text, FlexRows, FlexItem, Icon } from '../../../../components/elements';
import ImageView from './ImageView';
import Social from './Social';
import colors from '../../../../theme/styles/colors';
import { getEventDealsCount } from '../../../../utils/reusableQuery';
import { getZoneTime } from '../../../../utils/eventHelpers';
import { parseSocialPlace } from '../../../../utils/helpers';

const Container = styled.div`
  &&& {
    background-color: ${colors.white};
    margin: 1rem 0rem;
    /* :hover {
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
      transition: box-shadow 0.5s ease-out;
    } */
    .icon {
      justify-content: flex-start;
    }
  }
`;

const FigureWrapper = styled.div`
  &&& {
    /* padding: 1rem; */
    padding-bottom: 0.2rem;
  }
`;

const RightContainer = styled(FlexRows)`
  &&& {
    padding: 1rem;
    padding-top: 0rem;
    border: 0;
    padding-bottom: 0rem;
  }
`;

const CardFooter = styled.div`
  position: relative;
  /* padding: 0.2rem 1.5rem; */
`;

const Wrapper = styled.div`
  padding: 0.5rem 1.2rem;
`;

const BusinessCard = ({ event, socials }) => {
  const { userId } = useStoreState(state => state.auth);
  const [price, setPrice] = useState('');
  const [dealCounts, setDealsCounts] = useState(0);
  const [eventStartDate, setStartDate] = useState(0);
  useEffect(() => {
    if (event.minimum_price === null && event.maximum_price === null) {
      setPrice('');
    } else if (event.minimum_price === 0 && event.maximum_price === 0) {
      setPrice(`Book Now: Free`);
    } else if (event.minimum_price === event.maximum_price) {
      setPrice(`$${parseFloat(event.minimum_price).toFixed(2)}`);
    } else if (event.minimum_price !== 0 && event.maximum_price !== 0) {
      setPrice(`$${parseFloat(event.minimum_price).toFixed(2)} - $
      ${parseFloat(event.maximum_price).toFixed(2)}`);
    } else {
      setPrice(`$${parseFloat(event.minimum_price).toFixed(2)} - $
      ${parseFloat(event.maximum_price).toFixed(2)}`);
    }
  }, [event.maximum_price, event.minimum_price]);

  useEffect(() => {
    const fetData = async () => {
      const count = await getEventDealsCount(
        event.place_id,
        event.event_id,
        event.latitude,
        event.longitude,
      );
      const startDate = await getZoneTime(event.start, event.latitude, event.longitude);
      setStartDate(moment(startDate).format('LLLL'));
      setDealsCounts(count);
    };
    fetData();
  }, [event.event_id, event.latitude, event.longitude, event.place_id, event.start]);

  const socialArray = socials && socials.length !== 0 ? first(socials).total_social_count : [];
  const social = parseSocialPlace(socialArray, userId);

  return (
    <Container>
      <div className="columns is-gapless">
        <div className="column">
          <FigureWrapper>
            <ImageView event={event} />
            {price !== '' && (
              <FlexRows justify="flex-start">
                <Text color="darkGrey" size="small" weight="semibold">
                  {price}
                </Text>
              </FlexRows>
            )}
          </FigureWrapper>
          <CardFooter>
            <Social
              objectId={event.event_id}
              objectType="EVENT"
              likeActive={social.like.active}
              bookMarkActive={social.bookmark.active}
              likeCountInt={social.like.count}
              bookMarkCountInt={social.bookmark.count}
              likeSocialIdS={social.like.id}
              bookMarkSocialIdS={social.bookmark.id}
            />
          </CardFooter>
        </div>
        <div className="column is-three-fifths">
          <RightContainer justify="space-between" align="flex-start">
            <FlexItem grow={6}>
              <Link to={`/event/${event.event_id}`}>
                <Text className="is-capitalized" size="large" weight="semibold" paddingBottom={0.2}>
                  {event.name}
                </Text>
              </Link>
              <Text color="darkGrey" size="small" paddingBottom={0.5}>
                {event.tagline}
              </Text>
              <div className="is-flex">
                <span className="icon">
                  <GoLocation size={18} color={colors.specialGrey} />
                </span>
                <Text size="smaller" color="dark" weight="regular" paddingBottom={0.5}>
                  {event.place_name}, {event.address_line_1}, {event.city}, {event.state}{' '}
                  {event.post_code}
                </Text>
              </div>
              <div className="is-flex">
                <span className="icon">
                  <MdAccessTime size={18} color={colors.specialGrey} />
                </span>
                <Text size="smaller" color="darkGrey">
                  {eventStartDate}
                </Text>
              </div>
            </FlexItem>
          </RightContainer>

          <Wrapper>
            <div className="is-flex">
              {event.event_type && event.event_type !== 0 && (
                <span className="icon">
                  <Icon size={0.8} name="featureIcon" />
                </span>
              )}
              <Text color="dark" size="smaller" weight="regular">
                {event.event_type && event.event_type !== 0 && (
                  <>
                    {event.event_type &&
                      event.event_type.length !== 0 &&
                      first(event.event_type).description}
                    {event.event_category &&
                      event.event_category.length !== 0 &&
                      ` | ${event.event_category.map(item => ` ${item.description}`)}`}
                  </>
                )}
              </Text>
              <Text color="supportingColor2" size="smaller" weight="regular">
                {dealCounts !== 0 && <span style={{ color: 'black' }}> | </span>}{' '}
                {dealCounts !== 0 && `${dealCounts} deal${dealCounts !== 1 ? 's' : ''} Available`}
              </Text>
            </div>
          </Wrapper>

          <FlexRows justify="flex-end">
            <Link to={`/event/${event.event_id}`} className="is-flex">
              <Text color="primaryColor" size="small" weight="semibold">
                {first(event.listing_type) === 'public_listing' ? 'READ MORE' : 'BOOK NOW'}
              </Text>
              <span className="icon">
                <FaAngleRight size={20} color={colors.specialGrey} />
              </span>
            </Link>
          </FlexRows>
        </div>
      </div>
    </Container>
  );
};

export default BusinessCard;
