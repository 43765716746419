import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { forEach, isEmpty, capitalize } from 'lodash';
import uuid from 'uuid';
import RatingInput from './RatingInput';
import Images from './Images';
import PlaceSearchInput from './PlaceSearchInput';
import ItemSearchInput from './ItemSearchInput';
import DeskAddPlaceModal from './DeskAddPlaceModal';

import { Text, Button, InputErrorMessage, TagInputs } from '../../../../components/elements';
import { getPlaceTypes, getCuisines, getItemTypes } from '../../../../utils/fetchStatic';

const ImageUploadWrapper = styled.div`
  padding: 1rem;
`;
const Span = styled.span`
  color: red;
`;

const Header = styled.div`
  padding: 1rem 0rem;
  border-top: 2px solid ${({ theme: { colors } }) => colors.lightGreyTheta};
`;
const Label = styled(Text).attrs({ color: 'darkGrey', size: 'medium', weight: 'semibold' })``;

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const parseInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

const handleImageSubmissionWhileUploading = image => {
  let isDisabled = false;
  image.forEach(element => {
    if (element.uploadingStatus === 'uploading') {
      isDisabled = true;
    }
  });
  return isDisabled;
};

const Form = props => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [placeNameValue, setPlaceNameValue] = useState('');
  const [data, setData] = useState([{ businessTypes: [], itemTypes: [], cuisines: [] }]);
  const {
    loading,
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    history,
    haveInitialPlace,
  } = props;

  const handleOnClick = async () => {
    await setFieldValue('isDraft', false);
    handleSubmit();
  };

  const handleOnClickDraft = async () => {
    await setFieldValue('isDraft', true);
    handleSubmit();
  };

  useEffect(() => {
    const getData = async () => {
      const businessTypes = await getPlaceTypes();
      const itemTypes = await getItemTypes();
      const cuisines = await getCuisines();

      setData({
        businessTypes,
        itemTypes,
        cuisines,
      });
    };
    getData();
  }, []);

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleOnClick();
      }}
    >
      {haveInitialPlace && values.placeData.name && (
        <Header>
          <Text className="is-capitalized" size="large" weight="semibold" paddingBottom={0.5}>
            {capitalize(values.placeData.name)}
          </Text>
          <Text size="tiny" color="darkGrey">
            {values.placeData.address_line_1}
          </Text>
          <Text size="tiny" color="darkGrey">
            {values.placeData.city}, {values.placeData.state}, {values.placeData.post_code}
          </Text>
        </Header>
      )}
      <div className="columns">
        <div className="column is-7">
          <div className="columns is-gapless is-vcentered">
            <div className="column">
              <Label>
                How was it ?<Span>*</Span>
              </Label>
            </div>
            <div className="column ">
              <RatingInput handleChange={value => setFieldValue('overall_rating', value)} />
              <InputErrorMessage touched={touched.overall_rating} errors={errors.overall_rating} />
            </div>
          </div>

          <PlaceSearchInput
            haveInitialPlace={haveInitialPlace}
            placeAction={values.placeAction}
            place={values.placeData}
            onHandleModalActive={async value => {
              setPlaceNameValue(value);
              setIsActiveModal(true);
              setFieldValue('placeAction', 'new');
            }}
            onHandlePlaceSelection={value => {
              setFieldValue('placeData', value);
              setFieldValue('placeAction', 'existed');
            }}
            touched={touched}
            errors={errors}
            // onHandleClearItem={() => {
            //   setFieldValue('item_name', '');
            // }}
          />

          <div className="columns is-gapless is-vcentered">
            <div className="column">
              <Label>
                What was it ?<Span>*</Span>
              </Label>
            </div>
            <div className="column">
              <div>
                <ItemSearchInput
                  itemAction={values.itemAction}
                  placeData={values.placeData}
                  handleItemName={(value, itemDictionaryId) => {
                    setFieldValue('item_id', uuid());
                    setFieldValue('item_name', value);
                    setFieldValue('item_dictionary_id', itemDictionaryId);
                    setFieldValue('itemAction', 'new');
                  }}
                  onHandleItemSelection={value => {
                    setFieldValue('item_id', value.item_id);
                    setFieldValue('item_name', value.name);
                    setFieldValue('item_dictionary_id', value.item_dictionary_id);
                    setFieldValue('itemAction', 'existed');
                  }}
                  onHandleClearItem={() => {
                    setFieldValue('item_id', uuid());
                  }}
                  onHandleItemDictionarySelection={value => {
                    setFieldValue('dictionary', {
                      item_dictionary_id: value.item_dictionary_id,
                      name: value.name,
                    });
                    setFieldValue('dictionaryAction', value.action);
                  }}
                />
                <InputErrorMessage touched={touched.item_name} errors={errors.item_name} />
              </div>
              <br />
            </div>
          </div>

          <div className="field">
            <Label paddingBottom={0.5}>
              Enter your thoughts here<Span>*</Span> ( Min 50 and Max 1500 characters )
            </Label>
            <div className="control">
              <textarea
                className="textarea"
                name="content"
                id="Review_WriteAReview"
                value={values.content}
                // eslint-disable-next-line max-len
                placeholder="What do you like about this item? Say something such as appearance, tasting notes etc. Use @ to mention someone or use # to mention a dish, item, cuisines, taste, etc"
                onChange={handleChange}
                // onBlur={handleBlur}
              />
              <Text color="darkGrey" size="micro">
                Characters Left: {values.content.length} / 1500
              </Text>
              <InputErrorMessage touched={touched.content} errors={errors.content} />
              <br />
            </div>
          </div>
          <div className="columns">
            <div className="column is-half">
              <TagInputs
                label={<Label paddingBottom={0.5}>Item Type</Label>}
                value={parseData(values.item_type)}
                suggestions={parseData(data.itemTypes)}
                onChange={value => setFieldValue('item_type', parseInput(value))}
              />
            </div>
            <div className="column is-half">
              <TagInputs
                label={<Label paddingBottom={0.5}>Cuisines</Label>}
                value={parseData(values.cuisine_style)}
                suggestions={parseData(data.cuisines)}
                onChange={value => setFieldValue('cuisine_style', parseInput(value))}
              />
            </div>
          </div>
        </div>
        <div className="column is-4">
          <ImageUploadWrapper>
            <Label>
              Add Photos
              <Span>*</Span>
            </Label>
            <Text size="tiny" color="darkGrey">
              Drag and drop or upload upto 4 images. Formats accepted - &quot;.png, .jpg,
              .jpeg&quot; <br />
              Maximum size accepted - 10 MB
            </Text>
            <br />
          </ImageUploadWrapper>
          <Images values={values} setFieldValue={setFieldValue} />

          <InputErrorMessage touched={touched.image} errors={errors.image} />
        </div>
      </div>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <Button
              name="Back"
              type="button"
              backgroundColor="primaryColor"
              textProps={{ color: 'white', weight: 'semibold' }}
              onClick={e => {
                e.preventDefault();
                history.goBack();
              }}
            />
          </div>
          <div className="level-item">
            <Button
              name="Save Draft"
              type="button"
              backgroundColor="primaryColor"
              className={values.isDraft && loading && 'is-loading'}
              textProps={{
                color: values.isDraft && loading ? 'primaryColor' : 'white',
                weight: 'semibold',
              }}
              onClick={handleOnClickDraft}
              disabled={handleImageSubmissionWhileUploading(values.image)}
            />
          </div>
        </div>
        <div className="level-right">
          <Button
            type="submit"
            className={!values.isDraft && loading && 'is-loading'}
            name="Post Review"
            backgroundColor="primaryColor"
            textProps={{
              color: !values.isDraft && loading ? 'primaryColor' : 'white',
              weight: 'semibold',
            }}
            disabled={handleImageSubmissionWhileUploading(values.image)}
          />
        </div>
      </nav>
      {placeNameValue !== '' && (
        <DeskAddPlaceModal
          placeNameValue={placeNameValue}
          isActive={isActiveModal}
          onClose={() => {
            setIsActiveModal(false);
            setPlaceNameValue('');
          }}
          businessTypes={data.businessTypes}
          onSubmit={value => {
            setFieldValue('placeData', value);
            setIsActiveModal(false);
          }}
        />
      )}
    </form>
  );
};

const DeskForm = withFormik({
  mapPropsToValues: () => ({
    isDraft: false,
    placeAction: '',
    itemAction: '',
    dictionaryAction: '',
    dictionary: null,
    placeData: {},
    item_id: uuid(),
    item_dictionary_id: '',
    item_name: '',
    item_type: [],
    cuisine_style: [],
    overall_rating: 0,
    content: '',
    image: [
      {
        url: '',
        preview: null,
        tooltip: '',
        link: '',
        tag: [],
        uploadingStatus: 'pending',
      },
    ],
  }),

  validate: values => {
    const errors = {};
    if (isEmpty(values.placeData)) {
      errors.placeData = 'Please select place from suggestion';
    }

    return errors;
  },

  validationSchema: yup.object().shape({
    content: yup
      .string()
      .min(50, 'At least 50 Characters Required!')
      .max(1500, 'Not more than 1500 Characters!')
      .required('Review Content is required!'),
    item_name: yup.string().required('Please select dish from suggestion'),
    overall_rating: yup
      .number()
      .moreThan(0, 'Rating is required!')
      .required('Rating is required!'),
    image: yup
      .array()
      .min(2, 'Please upload at least one image')
      .required('Please upload at least one image'),
  }),

  handleSubmit: (values, { props }) => {
    const { isLoggedIn, setIsActiveDesktopLoginModal } = props;
    if (!isLoggedIn) {
      setIsActiveDesktopLoginModal({
        value: true,
        path: '',
        callBackFunction: () => props.onSubmit(values),
      });
    } else {
      props.onSubmit(values);
    }
  },

  displayName: 'SpotAndShareForm',
})(Form);

export default DeskForm;
