import React, { useState } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { isNull, uniqBy, dropRight } from 'lodash';

import { Text } from '../../../../components/elements';

import UserInfo from '../../../../components/UserInfo';

const searchCommentsQuery = gql`
  query searchComments($input: SearchInput) {
    search_comments(input: $input) {
      comment_listing {
        comment_id
        object_id
        object_type
        parent_id
        parent_type
        content
        status

        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const Container = styled.form`
  &&& {
    background: ${({ isActive }) => (isActive ? '#00aeef29' : 'transparent')};
    border-radius: 4px;
    .media {
      border: 0;
      padding: 0;
    }
    .columns:not(:last-child) {
      margin: 0;
    }
    .content p:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

const PlaceReviewCommentList = ({ itemId, itemReviewId }) => {
  const [numberOfCommentArray, setNumberOfCommentArray] = useState(3);

  const variables = {
    input: {
      filter: {
        comment_filter: {
          object_id: itemReviewId,
          object_type: 'ITEM_REVIEW',
          parent_id: itemId,
          parent_type: 'ITEM',
          status: 'ACTIVE',
        },
      },
    },
  };
  return (
    <Query query={searchCommentsQuery} variables={variables} fetchPolicy="network-only">
      {({ data, loading }) => {
        if (loading) {
          return '';
        }

        if (isNull(data.search_comments)) {
          return '';
        }

        if (data.search_comments.comment_listing.length === 0) {
          return '';
        }

        const comments = uniqBy(data.search_comments.comment_listing, 'comment_id');
        const numberOfDropArray = comments.length - numberOfCommentArray;
        const firstFiveComments = dropRight(comments, numberOfDropArray);

        return (
          <>
            <br />
            <div className="columns is-multiline">
              {firstFiveComments.map(comment => (
                <Container className="column is-12" key={comment.comment_id} isActive={false}>
                  <UserInfo
                    userId={comment.audit ? comment.audit.created_by : ''}
                    // subHeading={moment(comment.audit && comment.audit.updated_at).calendar()}
                    maskProps={{ width: 30 }}
                    maskTextProps={{
                      color: 'white',
                      weight: 'bold',
                      size: 'smaller',
                      letterSpacing: 'loose',
                    }}
                    variables={{
                      object_type: ['USER_IMAGE'],
                      object_id: comment.audit ? comment.audit.created_by : '',
                    }}
                  >
                    <Text size="tiny" color="darkGrey" paddingBottom={0.5}>
                      {comment.content}
                    </Text>
                    <nav className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <Text size="tiny" color="specialGrey">
                            {moment(comment.audit && comment.audit.updated_at).calendar()}
                          </Text>
                        </div>
                      </div>
                    </nav>
                  </UserInfo>
                </Container>
              ))}
            </div>
            {numberOfDropArray > 1 && (
              <a onClick={() => setNumberOfCommentArray(comments.length)}>
                <Text color="primaryColor" size="tiny" weight="regular">
                  Show {comments.length} More Comments
                </Text>
              </a>
            )}
          </>
        );
      }}
    </Query>
  );
};
export default PlaceReviewCommentList;
