import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { Text, FlexRows, Icon } from '../../../components/elements';
import Item from './Item';
import { getKeywordTagline } from '../../../utils/helpers';

const Wrapper = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyTheta};
`;

const TextWrapper = styled.div`
  padding-left: 1rem;
`;

const PageRoute = ({ icon, text, path }) => (
  <Link to={path}>
    <Wrapper>
      <FlexRows justify="flex-start">
        <Icon name={icon} size={1.5} />
        <TextWrapper>
          <Text size="small" weight="regular">
            {text}
          </Text>
        </TextWrapper>
      </FlexRows>
    </Wrapper>
  </Link>
);
const MainRecentSearch = () => {
  const places = useStoreState(state => state.place.recentViews);
  const items = useStoreState(state => state.item.recentViews);
  const events = useStoreState(state => state.event.recentViews);
  // TODO: Merge recent into single arry and sort by search date
  const totalItems = places.length + items.length + events.length;
  console.log('MainRecentSearch -> totalItems', totalItems);

  return (
    <>
      <PageRoute
        icon="itemRatingGoldSolid"
        text="Spot and share an item"
        path="/spot-and-share-an-item/"
      />
      <PageRoute icon="placesAroundMeBlue" text="Places around Me" path="/places" />
      <PageRoute icon="reviewItemBlue" text="Find or Share an Item" path="/items" />
      <PageRoute icon="discoverEventsSolid" text="Discover Events" path="/events" />
      <PageRoute icon="reviewAPlaceBlue" text="Review a Place" path="/place-search-for-review/" />
      {totalItems !== 0 && (
        <Wrapper>
          <Text size="small" weight="semibold">
            Recent Search
          </Text>
        </Wrapper>
      )}
      {places.map(place => (
        <Item
          title={place.name}
          subtitle={getKeywordTagline(place.business_type)}
          subtitle1={`${place.address_line_1}, ${place.city}`}
          image={place.default_image_url}
          path={`/place/${place.place_id}`}
        />
      ))}
      {items.map(item => (
        <Item
          title={item.name}
          subtitle={item.place_name}
          subtitle1={`${item.city}, ${item.state}`}
          image={item.default_image_url}
          path={`/item/${item.item_id}`}
        />
      ))}
      {events.map(event => (
        <Item
          title={event.name}
          subtitle={`${event.address_line_1}, ${event.city}`}
          image={event.default_image_url}
          path={`/event/${event.event_id}`}
        />
      ))}
    </>
  );
};

export default MainRecentSearch;
