import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Text, FlexRows, Icon } from '../../../components/elements';
import Following from './mobile/Following';
import Follower from './mobile/Follower';

const Container = styled.div``;

const Wrapper = styled(FlexRows)`
  position: sticky;
  top: 3rem;
  margin-bottom: 0.5rem;
  background: white;
`;

const TopWrapper = styled(FlexRows)`
  position: sticky;
  top: 0rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;

const Item = styled.div`
  padding: 0.5rem 0rem;
  margin: 0rem 0.5rem;
  width: 100%;
  border-bottom: 3px solid
    ${({ theme: { colors }, active }) => (active ? colors.primaryColor : 'transparent')};
  text-align: center;
  cursor: pointer;
`;

const MobileView = () => {
  const { userId } = useStoreState(state => state.auth);
  const [activeTab, setActiveTab] = React.useState('following');
  return (
    <Container>
      <Wrapper justify="space-around">
        <Item
          active={activeTab === 'followers'}
          onClick={() => {
            setActiveTab('followers');
          }}
        >
          <Text
            className="is-uppercase"
            weight="bold"
            size="small"
            color={activeTab === 'followers' ? 'primaryColor' : 'dark'}
          >
            Followers
          </Text>
        </Item>
        <Item
          active={activeTab === 'following'}
          onClick={() => {
            setActiveTab('following');
          }}
        >
          <Text
            className="is-uppercase"
            weight="bold"
            size="small"
            color={activeTab === 'following' ? 'primaryColor' : 'dark'}
          >
            Following
          </Text>
        </Item>
      </Wrapper>
      <Link to="/profile/find-friends">
        <TopWrapper justify="flex-start">
          <Icon name="findFriendsBlue" size={2} />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Text size="tiny" color="darkGrey" weight="semibold" lineHeight="normal">
            Connect with friends to discover what they krave...
          </Text>
        </TopWrapper>
      </Link>
      {activeTab === 'following' && <Following userId={userId} />}
      {activeTab === 'followers' && <Follower userId={userId} />}
    </Container>
  );
};

export default MobileView;
