import React from 'react';
import styled from 'styled-components';

import { FaTimes } from 'react-icons/fa';

import { Text, Modal, FlexRows } from '../../../components/elements';
import AddressForm from './AddressForm';

const ModalHeader = styled(FlexRows)`
  background-color: ${({ theme: { white } }) => white};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const AddAddressModal = ({ isActive, onClose, isUpdateFormType, loading, onSubmit, address }) => {
  return (
    <Modal isActive={isActive}>
      <ModalHeader justify="space-between">
        <a onClick={onClose}>
          <Text color="darkGrey">
            <FaTimes size={25} />
          </Text>
        </a>
        <Text
          className="is-uppercase"
          align="center"
          color="primaryColor"
          weight="bold"
          size="medium"
        >
          {isUpdateFormType ? 'UPDATE ADDRESS' : 'ADD NEW ADDRESS'}
        </Text>
        <Text />
      </ModalHeader>
      <div className="modal-card">
        <AddressForm
          isUpdateFormType={isUpdateFormType}
          loading={loading}
          onSubmit={onSubmit}
          address={address}
        />
      </div>
    </Modal>
  );
};

export default AddAddressModal;
