import React from 'react';
import styled from 'styled-components';
import { Icon, Text } from '../elements';

const Container = styled.section`
  height: 70vh;
`;

const Blank = ({ message }) => (
  <Container className="hero">
    <div className="hero-body">
      <div className="container has-text-centered">
        <Text size="large" weight="semibold" color="darkGrey">
          {message}
        </Text>
        <br />
        <h1>
          <Icon name="blank" size={20} />
        </h1>
      </div>
    </div>
  </Container>
);

export default Blank;
