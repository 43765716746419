import React from 'react';
import styled from 'styled-components';
import { FlexRows, FlexItem, Text, Icon } from '../../../../components/elements';
import ActionButtons from './ActionButtons';
import { trackEvent } from '../../../../utils/analytics';

const Container = styled.div`
  margin: 0rem 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;
const Item = styled(FlexRows)`
  padding: 1rem 0rem;
`;
const Card = styled.div``;
const DetailWrapper = styled(FlexRows).attrs({ align: 'flex-start' })`
  padding: 0.4rem 0rem;
`;
const Details = styled(FlexItem)`
  padding: 0rem 0.5rem;
`;
const Image = styled.img`
  border-radius: 12%;
`;

const MenuTabList = () => {
  const [active, setActive] = React.useState(false);
  return (
    <Container>
      <Item>
        <FlexItem grow={4}>
          <Text className="is-capitalized" size="medium" weight="bold">
            Delivery Menu
          </Text>
        </FlexItem>
        <FlexItem grow={1}>
          <a
            onClick={() => {
              trackEvent('anonymous', 'Menu_Item', {
                details: 'Click to get the details of menu items of Place Menu',
              })(active ? setActive(false) : setActive(true));
            }}
          >
            <Icon
              iconName={active ? `fas fa-angle-down` : `fas fa-angle-right`}
              align="right"
              size="large"
              color="specialGrey"
            />
          </a>
        </FlexItem>
      </Item>
      {active && (
        <Card>
          <FlexRows>
            <FlexItem grow={4}>
              <Text>Spicy Maki Combinations</Text>
            </FlexItem>
            <FlexItem grow={1}>
              <Text className="is-capitalized" align="right" size="medium" weight="bold">
                $18:00
              </Text>
            </FlexItem>
          </FlexRows>
          <DetailWrapper>
            <FlexItem grow={1}>
              <figure className="image is-64x64">
                <Image src="https://bulma.io/images/placeholders/128x128.png" alt="ima" />
              </figure>
            </FlexItem>
            <Details grow={12}>
              <Text size="micro">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centurie
              </Text>
            </Details>
          </DetailWrapper>
          <ActionButtons />
        </Card>
      )}
    </Container>
  );
};

export default MenuTabList;
