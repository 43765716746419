import React, { useState } from 'react';
import styled from 'styled-components';

const Field = styled.div`
  position: relative;
  width: 100%;
  input {
    font-size: 15px;
    width: 100%;
    padding: 8px 20px;
    border: 2px solid #fafafa;
    border-radius: 5px;
    background: #f0f0f0;
    outline: none;
    text-transform: capitalize;
  }
  input:focus {
    border: 2px solid #00aeef !important;
    border-radius: 5px;
    background: #fafafa;
  }
`;

const SearchBar = ({ onFetchRequest }) => {
  const [value, setValue] = useState('');
  return (
    <Field>
      <input
        value={value}
        placeholder="Search Items"
        onChange={e => {
          setValue(e.target.value);
          if (e.target.value && e.target.value.length > 2) {
            onFetchRequest(e.target.value);
          }
        }}
      />
    </Field>
  );
};

export default SearchBar;
