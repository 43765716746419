import React, { useState, useEffect } from 'react';

import { Query } from 'react-apollo';
import { first } from 'lodash';
import gql from 'graphql-tag';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';
import Loading from '../../components/global/mobile/MobileLoading';
import client from '../../utils/apolloClient';

const eventQuery = gql`
  query event($eventId: String) {
    fetch_event(input: { event_id: $eventId }) {
      place_id
      event_id
      status
      place_name
      listing_type
      description
      start
      end
      place_name
      name
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      default_image_url
    }
  }
`;

const eventSummeryQuery = gql`
  query event($eventId: String, $bookingId: String) {
    search_event_bookings(input: { filter: { event_booking_filter: { booking_id: $bookingId } } }) {
      event_booking_listing {
        place_id
        event_id
        booking_number
        quantity
        sub_total_price
        total_price
        total_fee
        booking_fee
        transaction_fee
        discount
        url
      }
    }
    search_event_ticket_records(
      input: {
        filter: { event_ticket_record_filter: { booking_id: $bookingId, event_id: $eventId } }
      }
    ) {
      ticket_record_listing {
        event_id
        ticket_name
        ticket_number
        ticket_status
        ticket_type
        price
        fee {
          name
          value
        }
        attendee {
          attendee_name
          attendee_email
          attendee_age
          attendee_gender
          attendee_phone
        }
      }
    }
  }
`;

const EventBooking = ({ history, match, location }) => {
  const [booking, setBooking] = useState();
  const [ticketRecord, setTicketRecord] = useState([]);
  const bookingState = location.state;

  useEffect(() => {
    if (bookingState.booking) {
      setBooking(bookingState.booking);
      setTicketRecord(bookingState.booking.ticket_record);
    } else {
      client.clientPrivate
        .query({
          query: eventSummeryQuery,
          variables: { eventId: match.params.eventId, bookingId: match.params.bookingId },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          const { search_event_bookings, search_event_ticket_records } = data;
          if (search_event_bookings && search_event_ticket_records) {
            setBooking(first(search_event_bookings.event_booking_listing));
            setTicketRecord(search_event_ticket_records.ticket_record_listing);
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  }, [bookingState.booking, match.params.bookingId, match.params.eventId]);
  return (
    <Layout history={history} match={match} service="events">
      <Analytics pageName="EventBookingDetails" category="event" title="Events-Booking-List">
        <Query
          query={eventQuery}
          variables={{ eventId: match.params.eventId }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return 'error';
            }
            const { fetch_event } = data;
            return (
              <>
                {booking && (
                  <>
                    <div className="is-hidden-desktop">
                      <MobileView
                        event={fetch_event}
                        booking={booking}
                        ticketRecord={ticketRecord}
                      />
                    </div>
                    <div className="is-hidden-touch">
                      <DesktopView
                        event={fetch_event}
                        booking={booking}
                        ticketRecord={ticketRecord}
                      />
                    </div>
                  </>
                )}
              </>
            );
          }}
        </Query>
      </Analytics>
    </Layout>
  );
};

export default EventBooking;
