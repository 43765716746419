import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Sticky from 'react-stickynode';
import { Link, Element, Events as ScEvent } from 'react-scroll';
import uuid from 'uuid';
import { MdArrowDropDown } from 'react-icons/md';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { dropRight, differenceBy, first } from 'lodash';

import { FlexRows, Text, Loading } from '../../../../components/elements';
import { getSections, getLinks, getDeals, getSectionsAndLinks } from '../../helpers';

import SearchBar from './SearchBar';
import YourOrder from './YouOrder';
import Deals from './Deals';
import PopularPicks from './PopularPicks';
import ItemAndModifiersSelection from './ItemAndModifiersSelection';
import CustomItemModal from './CustomItemModal';
import { SectionLoader } from '../../Loader';

const Container = styled(FlexRows)`
  padding-bottom: 3rem;
`;

const FilterWrapper = styled(FlexRows)`
  background-color: #ffffff;
  padding-bottom: 0.2rem;
  /* -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15); */
`;

const FilterItem = styled(Text)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1.5rem;
  color: ${({ theme: { colors }, active }) => (active ? colors.primaryColor : colors.dark)};
  cursor: pointer;
`;

const LeftContainer = styled.div`
  width: ${({ isCustomService }) => (isCustomService ? '100%' : '66%')};
`;

const RightContainer = styled.div`
  width: 33%;
  padding: 1.5rem;
  padding-top: 1.1rem;
  padding-right: 0;
  background-color: #ffffff;
  margin-bottom: 10rem;
`;

const MoreSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  padding: 0rem 1rem;
  overflow: ${({ active }) => (active ? 'inherit' : 'hidden')};
`;

const Popup = styled.div`
  position: absolute;
  top: 2rem;
  width: 12rem;
  background: #fafafa;
  border-radius: 4px;
  z-index: 201;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
`;

const MoreTabItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 0.5rem 1rem;
  :hover {
    background: #f0f0f0;
  }
`;

const MoreTab = ({ section, active }) => {
  return (
    <Link to={section.menu_section_id} spy smooth duration={200} offset={-200} isDynamic>
      <MoreTabItem>
        <Text color={active === section.menu_section_id ? 'primaryColor' : 'dark'} size="small">
          {section.name}
        </Text>
      </MoreTabItem>
    </Link>
  );
};

const Tab = ({ section, active }) => {
  return (
    <Link to={section.menu_section_id} spy smooth duration={200} offset={-200} isDynamic>
      <FilterItem active={active === section.menu_section_id} size="small">
        {section.name}
      </FilterItem>
    </Link>
  );
};

const MenuSectionsAndItem = ({
  isOpen,
  placeId,
  place,
  placeName,
  menuId,
  order,
  orderDataFromDataBase,
  onSubmit,
  addToCartLoading,
  cartLoading,
  updateQuantity,
  emptyCart,
  setNoItemThere,
  onUpdateUserWithOrder,
  location,
  isCustomService,
}) => {
  const allEventDateFilter = useStoreState(state => state.global.allEventDateFilter);
  const { setHideSubHeader } = useStoreActions(state => state.global);
  const [activeSection, setActiveSection] = useState();
  const [sections, setSections] = useState([]);
  const [sectionLoading, setSectionLoading] = useState(false);
  const [modifiersModal, setModifiersModal] = useState(false);
  const [customModal, setCustomModal] = useState(false);

  const [menuItem, setMenuItem] = useState();
  const [itemLinks, setItemLinks] = useState([]);
  const [linkSearchLoading, setLinkSearchLoading] = useState(false);
  const [isActiveMoreSection, setIsActiveMoreSection] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [deals, setDeals] = useState([]);

  const [editCartItem, setEditCartItem] = useState({
    initialsSelectedVariant: null,
    initialsSelectedGroups: [],
    initialsQuantity: 1,
    initialsItemUniqueId: null,
  });

  const moreRef = useRef();

  const searchItemsLinks = useCallback(
    async value => {
      setLinkSearchLoading(true);
      setSearchQuery(value);
      try {
        const list = await getSections({ placeId, menuId });
        const links = await getLinks({ menuId, query: value });
        setItemLinks(links);
        if (links.length === 0) {
          setNoItemThere(true);
        }
        const mergeLinksInSections = list.map(item => ({
          ...item,
          items: links.filter(item2 => item2.menu_section.menu_section_id === item.menu_section_id),
        }));
        const finalList = mergeLinksInSections.filter(item => item.items.length !== 0);
        setSections(finalList);
        setLinkSearchLoading(false);
      } catch (err) {
        setLinkSearchLoading(false);
      }
    },
    [menuId, placeId, setNoItemThere],
  );

  const fetchSectionsData = useCallback(async () => {
    setSectionLoading(true);
    try {
      const data = await getSectionsAndLinks({ placeId, menuId });
      setItemLinks(data.links);
      if (data.links.length === 0) {
        setNoItemThere(true);
      }
      const mergeLinksInSections = data.sections.map(item => ({
        ...item,
        items: data.links.filter(
          item2 => item2.menu_section.menu_section_id === item.menu_section_id,
        ),
      }));
      const finalList = mergeLinksInSections.filter(item => item.items.length !== 0);
      setSections(finalList);
      setSectionLoading(false);
    } catch (err) {
      setSectionLoading(false);
    }
  }, [menuId, placeId, setNoItemThere]);

  const fetchDeals = useCallback(async () => {
    const current = first(allEventDateFilter.filter(item => item.name === 'today'));
    const place_menu_deal_filter = { place_id: placeId, menu_id: [menuId], is_published: true };
    Object.assign(
      place_menu_deal_filter,
      current &&
        current.range && {
          start_date_range: current.range.start_date_range,
        },
      current &&
        current.range && {
          end_date_range: current.range.end_date_range,
        },
    );
    try {
      const list = await getDeals({
        filter: { place_menu_deal_filter },
      });
      setDeals(list);
    } catch (err) {
      console.error(err);
    }
  }, [allEventDateFilter, menuId, placeId]);

  useEffect(() => {
    if (menuId) {
      fetchSectionsData();
      fetchDeals();
    }

    ScEvent.scrollEvent.register('begin', () => {});
    ScEvent.scrollEvent.register('end', () => {});
    function handleClickOutside(event) {
      if (moreRef.current && !moreRef.current.contains(event.target)) {
        setIsActiveMoreSection(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [fetchDeals, fetchSectionsData, menuId]);

  if (sectionLoading) {
    return <SectionLoader />;
  }

  const dealSectionTab = { menu_section_id: 'deals', name: 'Deals' };
  const popularLinks = itemLinks.filter(edge => edge.menu_item.popular === true);
  const popularPicksTab = {
    menu_section_id: 'popular-picks',
    name: 'Popular Picks',
    items: popularLinks,
  };

  const sectionTabs = dropRight(sections, sections.length - 4);
  const moreSectionTabs = differenceBy(sections, sectionTabs, 'menu_section_id');
  const moreActiveSectionArr = moreSectionTabs.filter(
    item => item.menu_section_id === activeSection,
  );
  const moreActiveSection =
    moreActiveSectionArr.length !== 0 ? first(moreActiveSectionArr).name : null;

  console.log('allEventDateFilter', allEventDateFilter);

  return (
    <>
      <Sticky
        top={75}
        innerZ={180}
        onStateChange={({ status }) => {
          if (status === 2) {
            setHideSubHeader(false);
          } else {
            setHideSubHeader(true);
          }
        }}
      >
        <FilterWrapper justify="space-between">
          <FlexRows justify="space-between" style={{ width: '68%' }}>
            <FlexRows justify="flex-start">
              {deals.length !== 0 && <Tab section={dealSectionTab} active={activeSection} />}
              {popularLinks.length !== 0 && (
                <Tab section={popularPicksTab} active={activeSection} />
              )}
              {sectionTabs.map(section => (
                <Tab section={section} active={activeSection} />
              ))}
            </FlexRows>
            {moreSectionTabs.length !== 0 && (
              <MoreSection active={isActiveMoreSection} ref={moreRef}>
                <a onClick={() => setIsActiveMoreSection(!isActiveMoreSection)}>
                  <Text size="small">
                    {moreActiveSection || 'More'} <MdArrowDropDown />
                  </Text>
                </a>
                <Popup>
                  {moreSectionTabs.map(section => (
                    <MoreTab section={section} active={activeSection} />
                  ))}
                </Popup>
              </MoreSection>
            )}
          </FlexRows>
          <FlexRows justify="space-between" style={{ width: '32.5%', paddingLeft: '10px' }}>
            <SearchBar onFetchRequest={input => searchItemsLinks(input)} />
          </FlexRows>
        </FilterWrapper>
      </Sticky>

      {/* {itemLinks.length === 0 && (
        <Element name={dealSectionTab.menu_section_id}>
          <Deals
            tab={dealSectionTab}
            handleTabSwitch={() => setActiveSection(dealSectionTab.menu_section_id)}
            size
          />
        </Element>
      )} */}
      <Container justify="space-between" align="flex-start">
        <LeftContainer isCustomService={isCustomService}>
          {searchQuery.length !== 0 && (
            <Text size="large" weight="semibold" paddingBottom={2}>
              Search result for &apos;{searchQuery}&apos;
            </Text>
          )}
          {!linkSearchLoading && searchQuery.length !== 0 && itemLinks.length === 0 && (
            <Text size="small" color="darkGrey" weight="semibold">
              Our search came with no result. Please try again by checking the spelling or search
              something else.
            </Text>
          )}

          {deals.length !== 0 && (
            <Element name={dealSectionTab.menu_section_id}>
              <Deals
                isCustomService={isCustomService}
                placeLocation={location}
                deals={deals}
                tab={dealSectionTab}
                handleTabSwitch={() => setActiveSection(dealSectionTab.menu_section_id)}
              />
            </Element>
          )}
          {linkSearchLoading && <Loading />}
          {!linkSearchLoading && popularLinks.length !== 0 && (
            <Element name={popularPicksTab.menu_section_id}>
              <PopularPicks
                isCustomService={isCustomService}
                location={location}
                lineItems={order.line_item}
                section={popularPicksTab}
                handleTabSwitch={() => setActiveSection(popularPicksTab.menu_section_id)}
                setModifiersModal={item => {
                  if (!isCustomService) {
                    setModifiersModal(true);
                  } else if (item.image) {
                    setCustomModal(true);
                  }
                  setMenuItem(item);
                  setEditCartItem({
                    initialsSelectedVariant: null,
                    initialsSelectedGroups: [],
                    initialsQuantity: 1,
                    initialsItemUniqueId: uuid(),
                  });
                }}
              />
            </Element>
          )}
          {!linkSearchLoading &&
            sections.map(
              section =>
                section.items.length !== 0 && (
                  <Element name={section.menu_section_id}>
                    <PopularPicks
                      isCustomService={isCustomService}
                      location={location}
                      lineItems={order.line_item}
                      section={section}
                      handleTabSwitch={() => setActiveSection(section.menu_section_id)}
                      setModifiersModal={item => {
                        if (!isCustomService) {
                          setModifiersModal(true);
                        } else if (item.image) {
                          setCustomModal(true);
                        }
                        setMenuItem(item);
                        setEditCartItem({
                          initialsItemUniqueId: uuid(),
                        });
                      }}
                    />
                  </Element>
                ),
            )}
        </LeftContainer>
        {!isCustomService && itemLinks.length !== 0 && (
          <RightContainer>
            <Sticky
              top={150}
              enableTransforms
              onStateChange={value => console.log('onStateChange', value)}
            >
              <YourOrder
                onUpdateUserWithOrder={onUpdateUserWithOrder}
                addToCartLoading={addToCartLoading}
                updateQuantity={updateQuantity}
                emptyCart={emptyCart}
                placeName={order.place ? order.place.name : placeName}
                place={place}
                cartLoading={cartLoading}
                orderDataFromDataBase={orderDataFromDataBase}
                lineItems={order.line_item}
                billing={order.billing}
                onItemEdit={input => {
                  setModifiersModal(true);
                  setMenuItem({
                    id: input.id,
                    item_link_id: input.item_link_id,
                    menu_item_id: input.menu_item_id,
                    price: input.price,
                  });
                }}
                setIsActiveModal={() => {}}
              />
            </Sticky>
          </RightContainer>
        )}
      </Container>
      <div id="content" />
      {menuItem && menuItem.menu_item_id && customModal && (
        <CustomItemModal
          menuItem={menuItem}
          isActiveModal={customModal}
          isOpen={isOpen}
          onClose={() => {
            setCustomModal(false);
            setMenuItem();
          }}
        />
      )}
      {menuItem && menuItem.menu_item_id && modifiersModal && (
        <ItemAndModifiersSelection
          location={location}
          isOpen={isOpen}
          lineItems={order.line_item}
          initialsItemUniqueId={editCartItem.initialsItemUniqueId}
          menuItemFromLink={menuItem} // this menu from link but original variants
          menuItemId={menuItem.menu_item_id}
          isActiveModal={modifiersModal}
          onClose={() => {
            setModifiersModal(false);
            setMenuItem();
          }}
          addToCartLoading={addToCartLoading}
          addToOrder={(input, callback) => {
            onSubmit(input, menuId, {
              func: () => {
                callback.func();
                setModifiersModal(false);
              },
            });
          }}
        />
      )}
    </>
  );
};

export default MenuSectionsAndItem;
