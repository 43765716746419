import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { trim, replace } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useState, useEffect } from 'react';
import { FlexColumns, Text, FlexRows, Input, CheckBox, Button } from '../../elements';
import colors from '../../../theme/styles/colors';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 35rem;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    }
    .modal-card-body {
      padding: 2rem;
    }
    a {
      color: ${colors.primaryColor};
    }
  }
`;
const ButtonStyle = styled.div`
  &&& {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;
const ErrorMessage = styled.div`
  padding: 0.5rem;
  /*background: red*/
  color: red;
  border-radius: 4px;
  margin-bottom: 1rem;
`;
const DeskTopSignInModal = ({ history }) => {
  const remember = useStoreState(state => state.auth.remember);
  const [username, setUsername] = useState(remember.username);
  const [password, setPassword] = useState(remember.password);
  const [isRemember, setRememberMe] = useState(remember.isRemember);
  const setRemember = useStoreActions(state => state.auth.setRemember);
  const isError = useStoreState(state => state.auth.isError);
  const isLoading = useStoreState(state => state.auth.loading);
  const migrationPath = useStoreState(state => state.auth.migrationPath);
  const clearError = useStoreActions(state => state.auth.clearError);
  const login = useStoreActions(state => state.auth.logIn);
  const isActiveModal = useStoreState(state => state.auth.isActiveDesktopLoginModal);
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const callBackFunction = useStoreState(state => state.auth.callBackFunction);
  const setIsActiveDesktopSignInModal = useStoreActions(
    state => state.auth.setIsActiveDesktopSignInModal,
  );
  const setIsActiveForgotPassModal = useStoreActions(
    state => state.auth.setIsActiveForgotPassModal,
  );

  useEffect(() => {
    clearError();
  }, [clearError]);

  const handleSubmit = () => {
    login({ username: username.toLowerCase(), password, history, migrationPath, callBackFunction });
    if (isRemember) {
      setRemember({ username, password, isRemember });
    } else {
      setRemember({ username: '', password: '', isRemember: false });
    }
  };

  const handleSignupModal = () => {
    setIsActiveDesktopSignInModal({ value: true });
    setIsActiveModal({ value: false });
  };

  const handleActiveForgotPassModal = () => {
    setIsActiveForgotPassModal({ value: true });
    setIsActiveModal({ value: false });
  };

  return (
    <Container className={`modal ${isActiveModal && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <form
          className="modal-card-body"
          onSubmit={event => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <ButtonStyle
            className="delete"
            label="close"
            onClick={() => setIsActiveModal({ value: false })}
          />
          <FlexColumns>
            <div>
              <Text color="primaryColor" size="large" weight="semibold" paddingBottom={0.5}>
                Sign In to Kravein
              </Text>
            </div>
            <div>
              <Text color="darkGrey" size="tiny">
                New to Kravien? <a onClick={handleSignupModal}>Sign up</a>
              </Text>
            </div>
          </FlexColumns>
          <br />
          {isError.message !== '' && (
            <ErrorMessage>
              <p>{isError.message}</p>
            </ErrorMessage>
          )}
          <div className="field">
            <label className="label">Email Address</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={trim(username)}
                placeholder="Email"
                onChange={event => setUsername(trim(replace(event.target.value), ' ', ''))}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Password</label>
            <div className="control">
              <Input
                className="input"
                type="Password"
                value={trim(password)}
                placeholder="password"
                onChange={event => setPassword(event.target.value)}
              />
            </div>
          </div>
          <FlexRows justify="space-between" align="flex-start">
            <div>
              <CheckBox
                label="Remember me"
                initialValue={isRemember}
                onChange={value => {
                  setRememberMe(value);
                }}
              />
            </div>
            <div>
              <Button
                className={isLoading && 'is-loading'}
                type="submit"
                name="Sign In"
                width={3}
                backgroundColor="primaryColor"
                textProps={{
                  color: isLoading ? 'primaryColor' : 'white',
                  weight: 'semibold',
                  size: 'small',
                }}
                onClick={handleSubmit}
              />

              <a onClick={handleActiveForgotPassModal}>
                <Text color="primaryColor" size="tiny">
                  Forgot your password?
                </Text>
              </a>
            </div>
          </FlexRows>
          <br />
          <Text color="darkGrey" size="tiny">
            By Sign in your agree to our <a>Terms of Service</a> and <a>Privacy Policy</a>
          </Text>
          <Text color="darkGrey" size="tiny">
            Don&apos;t have a account? <a onClick={handleSignupModal}>Sign up</a>
          </Text>
        </form>
      </div>
    </Container>
  );
};

export default withRouter(DeskTopSignInModal);
