import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { GoLocation } from 'react-icons/go';
import { times, capitalize } from 'lodash';
import queryString from 'query-string';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';

import { getKeywordTagline } from '../../../../utils/helpers';
import { Text, FlexRows, Icon } from '../../../../components/elements';
import ImageView from './ImageView';
import colors from '../../../../theme/styles/colors';
import { isPlaceOpen } from '../../../../utils/hoursHelpers';
import client from '../../../../utils/apolloClient';

const Container = styled.div`
  /* padding: 1rem; */
  &&& {
    background-color: ${({ theme: { colors: themeColors } }) => themeColors.white};
    margin-bottom: 3rem;
    /* :hover {
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
      transition: box-shadow 0.5s ease-out;
    } */
    .icon {
      justify-content: flex-start;
    }
    .media {
      position: relative;
    }
  }
`;

const Span = styled.span`
  color: ${({ theme: { colors: color } }) => color.primaryColor};
`;

const Span2 = styled.span`
  color: ${({ theme: { colors: color } }) => color.secondaryColor};
`;

const SubTitleWrapper = styled.div`
  padding-top: 0.3rem;
`;

const TaglineWrapper = styled.div`
  padding-top: 0rem;
`;

const AddressWrapper = styled.div`
  padding-top: 0.8rem;
`;
const Wrapper = styled(FlexRows)`
  &&& {
    padding: 0.5rem 0rem;
    border: 0;
    padding-bottom: 0rem;
  }
`;
const KeywordsWrapper = styled.div`
  padding-top: 0rem;
`;

const DealsWrapper = styled.div`
  padding-top: 0.5rem;
`;

const TextStyled = styled(Text)`
  margin-left: 2rem;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 2rem;
`;

const OrderOnline = styled.a`
  margin-bottom: 0.5rem;
`;

const fetchDeliveryZoneQuery = gql`
  query fetchDeliveryZoneQ($input: NavigateZoneSettingsInput) {
    fetch_zone_settings(input: $input) {
      zone_found
      matched_zone {
        name
        delivery_fee
        delivery_estimate
      }
    }
  }
`;

const MenuListItem = ({ place, service_type_setting, location }) => {
  const routeData = queryString.parse(location.search);
  const currentLocation = useStoreState(state => state.global.usedLocation);
  const serviceType = capitalize(routeData.service_type) || 'Pickup';
  const [openHoursData, setOpenHoursData] = useState({
    isOpen: false,
    hours: [],
    messageOfClosingAndOpening: '',
  });
  const [deliveryEstimate, setDeliveryEstimate] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);

  const fetchZone = useCallback(
    ({ service_type_setting_id }) => {
      client.clientPublic
        .query({
          query: fetchDeliveryZoneQuery,
          variables: {
            input: {
              service_type_setting_id,
              address: {
                latitude: currentLocation.latitude,
                longitude: currentLocation.longitude,
              },
            },
          },
        })
        .then(({ data }) => {
          if (data.fetch_zone_settings && data.fetch_zone_settings.zone_found) {
            setDeliveryEstimate(
              parseInt(data.fetch_zone_settings.matched_zone.delivery_estimate, 10),
            );
            setDeliveryFee(data.fetch_zone_settings.matched_zone.delivery_fee);
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    [currentLocation.latitude, currentLocation.longitude],
  );

  useEffect(() => {
    if (
      service_type_setting &&
      service_type_setting.service_type === 'Delivery' &&
      service_type_setting.service_type_setting_id
    ) {
      fetchZone({ service_type_setting_id: service_type_setting.service_type_setting_id });
    }
    if (
      service_type_setting &&
      service_type_setting.menu_hour &&
      service_type_setting.menu_hour.length !== 0
    ) {
      const openNow = isPlaceOpen(service_type_setting.menu_hour, {
        latitude: place.latitude,
        longitude: place.longitude,
      });
      const messageOfClosingAndOpening = openNow.nextClosingOrOpeningHours
        ? `${
            openNow.nextClosingOrOpeningHours.isStart
              ? // eslint-disable-next-line max-len
                `Opens in ${openNow.nextClosingOrOpeningHours.time_in_mins} ${
                  openNow.nextClosingOrOpeningHours.time_in_mins === 1 ? 'minute' : 'minutes'
                }.`
              : // eslint-disable-next-line max-len
                `Close in ${openNow.nextClosingOrOpeningHours.time_in_mins} minutes.`
          }`
        : '';
      setOpenHoursData({
        isOpen: openNow.is_open,
        hours: openNow.options,
        messageOfClosingAndOpening,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place.business_hour, place.latitude, place.longitude, service_type_setting]);

  const orderTime = service_type_setting
    ? parseInt(service_type_setting.preparation_estimate, 10) + deliveryEstimate
    : 0;

  return (
    <Link to={`/order-food/${serviceType}/${place.place_id}`}>
      <Container>
        <article className="media">
          <figure className="media-left">
            <ImageView
              place={place}
              image={service_type_setting && service_type_setting.image_url}
            />
          </figure>
          <div className="media-content">
            <div>
              <Link to={`/order-food/${serviceType}/${place.place_id}`}>
                <Text className="is-capitalized" size="mlarge" weight="semibold">
                  {place.name}
                </Text>
              </Link>
              <SubTitleWrapper>
                <Text color="darkGrey" size="small">
                  {parseFloat(place.distance).toFixed(2)} Kms{' '}
                  {place.price_range && place.price_range !== 0 && '|'}{' '}
                  {place.price_range && times(place.price_range).map(() => '$')}{' '}
                  {service_type_setting &&
                    service_type_setting.service_type === 'Delivery' &&
                    deliveryFee !== 0 &&
                    `| $${parseFloat(deliveryFee).toFixed(2)} Delivery Fee `}
                  {orderTime !== 0 && `| ${orderTime} mins `}
                  {openHoursData.isOpen ? (
                    <>
                      | <Span>Open Now</Span>
                    </>
                  ) : (
                    <>
                      | <Span2>Closed</Span2>
                    </>
                  )}
                </Text>
              </SubTitleWrapper>

              {place.tagline && (
                <TaglineWrapper>
                  <Text color="darkGrey" size="small">
                    {place.tagline}
                  </Text>
                </TaglineWrapper>
              )}
            </div>
            <AddressWrapper className="is-flex">
              <span className="icon" style={{ marginRight: '0.3rem', marginLeft: '-0.15rem' }}>
                <GoLocation size={18} color={colors.specialGrey} />
              </span>
              <Text size="small" color="dark" weight="regular">
                {place.address_line_1}, {place.city}, {place.state}
              </Text>
            </AddressWrapper>
            <KeywordsWrapper>
              <Wrapper justify="flex-start">
                <div className="is-flex">
                  <span className="icon" style={{ marginRight: '0.3rem' }}>
                    <Icon size={0.8} name="featureIcon" />
                  </span>
                  <Text color="darkGrey" size="small">
                    {getKeywordTagline(
                      place.business_type,
                      place.cuisine_style,
                      place.feature,
                      place.dietary_information,
                    )}
                  </Text>
                </div>
              </Wrapper>
            </KeywordsWrapper>
            <DealsWrapper className="is-flex">
              <span className="icon" style={{ marginRight: '0.3rem', marginLeft: '-0.25rem' }}>
                <Icon size={1.2} name="deals" />
              </span>
              <Text size="small" color="darkGrey" weight="regular">
                10 Deals Available
              </Text>
              <TextStyled size="small" color="secondaryColor" weight="regular">
                {openHoursData.messageOfClosingAndOpening}
              </TextStyled>
            </DealsWrapper>
          </div>
          <div className="media-right" />
          <IconWrapper>
            <FlexRows justify="flex-start">
              <OrderOnline>
                <Span>Order Online</Span>
              </OrderOnline>
              <Text color="coolGrey">
                <MdKeyboardArrowRight size={30} />
              </Text>
            </FlexRows>
          </IconWrapper>
        </article>
      </Container>
    </Link>
  );
};

export default withRouter(MenuListItem);
