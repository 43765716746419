import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { getZoneTime } from '../../../utils/eventHelpers';
import { Text, FlexRows, Image } from '../../../components/elements';
import colors from '../../../theme/styles/colors';
import eventDefault from '../../../assets/images/event-default.png';
import { getEncodedImage } from '../../../utils/s3';

const Container = styled.div`
  &&& {
    background-color: ${colors.white};
    margin: 1rem 0rem;
    /* :hover {
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
      transition: box-shadow 0.5s ease-out;
    } */
    .icon {
      justify-content: flex-start;
    }
  }
`;

const FigureWrapper = styled.div`
  &&& {
    /* padding: 1rem; */
    padding-bottom: 0.2rem;
  }
`;

const Figure = styled.figure`
  && {
    img {
      border-radius: 4px;
    }
  }
`;

const ImageView = ({ id, image }) => {
  if (image === null || image === '') {
    return (
      <Link to={`/event/${id}`}>
        <Figure className="image is-3by2 img-opacity">
          <Image source={eventDefault} />
        </Figure>
      </Link>
    );
  }

  return (
    <Link to={`/event/${id}`}>
      <Figure className="image image is-3by2">
        <Image source={getEncodedImage(image, 452, 301)} />
      </Figure>
    </Link>
  );
};

const BusinessCard = ({ event }) => {
  const [price, setPrice] = useState('');
  const [eventStartDate, setStartDate] = useState(0);

  useEffect(() => {
    if (event.minimum_price === null && event.maximum_price === null) {
      setPrice('');
    } else if (event.minimum_price === 0 && event.maximum_price === 0) {
      setPrice(`Book Now: Free`);
    } else if (event.minimum_price === event.maximum_price) {
      setPrice(`$${parseFloat(event.minimum_price).toFixed(2)}`);
    } else if (event.minimum_price !== 0 && event.maximum_price !== 0) {
      setPrice(`$${parseFloat(event.minimum_price).toFixed(2)} - $
      ${parseFloat(event.maximum_price).toFixed(2)}`);
    } else {
      setPrice(`$${parseFloat(event.minimum_price).toFixed(2)} - $
      ${parseFloat(event.maximum_price).toFixed(2)}`);
    }
  }, [event.maximum_price, event.minimum_price]);

  useEffect(() => {
    const fetData = async () => {
      const startDate = await getZoneTime(event.start, event.latitude, event.longitude);
      setStartDate(moment(startDate).format('LLLL'));
    };
    fetData();
  }, [event.event_id, event.latitude, event.longitude, event.place_id, event.start]);

  return (
    <Container>
      <Link to={`/event/${event.event_id}`}>
        <Text className="is-capitalized" size="medium" weight="semibold">
          {event.name}
        </Text>
      </Link>
      <Text size="tiny" color="darkGrey">
        {eventStartDate}
      </Text>
      <Text size="tiny" color="dark" weight="regular" paddingBottom={0.5}>
        {event.place_name}, {event.address_line_1}, {event.city}, {event.state} {event.post_code}
      </Text>
      <FigureWrapper>
        <ImageView id={event.event_id} image={event.default_image_url} />
        {price !== '' && (
          <FlexRows justify="flex-start">
            <Text color="darkGrey" size="small" weight="semibold">
              {price}
            </Text>
          </FlexRows>
        )}
      </FigureWrapper>
    </Container>
  );
};

export default BusinessCard;
