import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from './Text';

const InputStyle = styled.div`
  && {
    width: 100%;
    border-radius: 7px;
    select {
      width: 100%;
    }
  }
`;

const InputLiteStyle = styled.div`
  &&& {
    width: 100%;
    padding-left: 0;
    select {
      width: 100%;
      box-shadow: 0 0 0 0;
      border: 0;
      border-color: 'transparent';
    }
  }
`;

const Select = ({ className, lite, options, ...props }) => {
  if (lite) {
    return (
      <InputLiteStyle className={`select ${className}`}>
        <select {...props}>
          <option>Select</option>
          {options.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </InputLiteStyle>
    );
  }
  return (
    <InputStyle className={`select ${className}`}>
      <select {...props}>
        {options.map(item => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </InputStyle>
  );
};

Select.defaultProps = {
  className: '',
  lite: false,
  options: ['Select'],
};

Select.propTypes = {
  className: PropTypes.string,
  lite: PropTypes.bool,
  options: PropTypes.array,
};

export default Select;
