import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import { isNull } from 'lodash';
import { Text } from '../../../../../components/elements';
import UnFollowActionButton from './UnFollowActionButton';

import GetImages from '../../../../../components/GetImages';
import { getPlaceTagline } from '../../../../../utils/helpers';

const ButtonAnimation = posed.a({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const IconButton = styled(ButtonAnimation)`
  &&& {
    border: 0;
    ${({ paddingLess }) => paddingLess && `padding:0.2rem 0.5rem`};
    background-color: transparent;
  }
`;

const Place = ({ place, placeId, followId }) => {
  if (isNull(place)) {
    return '';
  }
  return (
    <>
      <figure className="media-left">
        <p className="image is-64x64">
          <GetImages
            name={place.name}
            maskProps={{ width: 64, height: 64 }}
            variables={{
              object_type: ['PLACE'],
              object_id: placeId,
              links_to_id: [placeId],
              links_to_type: ['PLACE'],
            }}
          />
        </p>
      </figure>
      <div className="media-content">
        <div>
          <Text size="small" weight="semibold">
            {place.name}
          </Text>
          <Text color="darkGrey" size="tiny">
            {getPlaceTagline(place.business_type, place.cuisine_style)}
          </Text>
          <Text color="darkGrey" size="tiny">
            {place.city}, {place.state}, {place.country}
          </Text>
        </div>
      </div>
      <div className="media-right">
        <IconButton className="button">
          <UnFollowActionButton placeId={placeId} followId={followId} />
        </IconButton>
      </div>
    </>
  );
};

export default Place;
