import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { times, forEach, range, upperCase, omit, lowerCase } from 'lodash';
import moment from 'moment';
import { IoMdClose } from 'react-icons/io';
import queryString from 'query-string';
import { useStoreState } from 'easy-peasy';

import { Text, FlexRows, Button } from '../../../../components/elements';
import { Input } from '../../../../components/lib';
import colors from '../../../../theme/styles/colors';
import DiningOptionTabs from './DiningOptionTabs';
import PickUpTime from './PickUpTime';
import DeliveryAddress from './DeliveryAddress';
import { formatCurrency } from '../../../../utils/helpers';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 30rem;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    }
    .modal-card-head {
      padding: 1rem 2rem;
      padding-bottom: 0.6rem;
      background-color: rgba(255, 255, 255, 1);
      border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
    }
    .modal-card-body {
      padding: 2rem;
      padding-top: 0rem;
    }
    a {
      color: ${colors.primaryColor};
    }
  }
`;

const Header = styled(FlexRows)`
  padding: 0rem 0rem;
  width: 100%;
  .pickup-time {
    margin-right: 11rem;
  }
`;

const DiningOption = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  margin-bottom: 2rem;
`;

const OrderTime = styled.div`
  .order-time {
    padding: 1.5rem 0rem;
  }
  .change {
    margin-right: 3rem;
  }
  margin-bottom: 2rem;
  border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};

  ${'' /* border-bottom: 1px solid grey; */}
`;

const DiningAddress = styled.div`
  padding-bottom: 3rem;
`;

const Attendees = styled.div`
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  display: flex;
  .control {
    margin-left: 3rem;
  }

  .input {
    width: 4rem;
    border: 1px solid #e2e2e2;
    border-radius: 0;
    padding-left: 1.7rem;
  }
`;

const Footer = styled.footer`
  && {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 1);
    border-top: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  }
`;

const getTimeRange = (options, estimateRange = 15) => {
  const timeData = [];
  forEach(options, item => {
    const startHours = moment(item.start, 'hh:mm A').hours();
    const startMin = moment(item.start, 'hh:mm A').minutes();
    const endHours = moment(item.end, 'hh:mm A').hours();
    const endMin = moment(item.end, 'hh:mm A').minutes();
    const rangeOfHours = range(startHours, endHours);
    const minRange = range(0, 59, estimateRange);
    console.log('options', rangeOfHours, minRange);
    forEach(rangeOfHours, item2 => {
      forEach(minRange, item3 => {
        const isPM = item2 > 12;
        const hours = item2 > 12 ? item2 - 12 : item2;
        const addZeroWithHours = hours < 10 ? `0${hours}` : hours;
        const final = `${addZeroWithHours}:${item3 === 0 ? '00' : item3} ${isPM ? 'PM' : 'AM'}`;
        const withMin = item3 === 60 - estimateRange ? '00' : item3 + estimateRange;
        const withHours = item3 === 60 - estimateRange ? item2 + 1 : item2;
        const finalHours = withHours > 12 ? withHours - 12 : withHours;
        const isPMWith = withHours > 12;
        const withFinal = `${finalHours}:${withMin === 0 ? '00' : withMin} ${
          isPMWith ? 'PM' : 'AM'
        }`;
        timeData.push(`${final} - ${withFinal}`);
      });
    });
  });

  return timeData;
};

const DiningOptionsModal = ({
  place,
  serviceTypeSetting,
  isActiveModal,
  setIsActiveModal,
  history,
  state,
  func,
  currentMenu,
  onUpdateDelivery,
  asap,
  asapFirstValue,
  serviceTypes,
}) => {
  const { isLoggedIn } = useStoreState(state1 => state1.auth);
  const [currentTab, setCurrentTab] = useState(serviceTypeSetting.service_type);
  const [checkBox, setCheckBox] = useState(state.deliveryMode);
  const [timeRange, setTimeRange] = useState([]);
  const [step, setStep] = useState(1);
  const today = moment().format('ddd');
  const [selectedDay, setSelectedDay] = useState(today);
  const [selectedDate, setSelectedDate] = useState(state.date);
  const [selectedTime, setSelectedTime] = useState(state.time);
  const [deliveryAddress, setDeliverAddress] = useState(state.deliveryAddress);

  const handleCheck = value => {
    setCheckBox(value);
  };

  const days =
    serviceTypeSetting.schedule_order_duration !== 0
      ? times(serviceTypeSetting.schedule_order_duration + 1).map(item =>
          moment()
            .add(item, 'days')
            .format('ddd, MMM, DD'),
        )
      : [];

  useEffect(() => {
    if (currentMenu && currentMenu.menu_hour) {
      const currentDayHours = currentMenu.menu_hour.filter(
        item => item.day === upperCase(selectedDay),
      );
      if (currentDayHours.length !== 0) {
        const timeRangeData = getTimeRange(currentDayHours[0].option);
        setTimeRange(timeRangeData);
      }
    }
  }, [currentMenu, selectedDay]);

  return (
    <Container className={`modal ${isActiveModal && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        {step === 1 && (
          <header className="modal-card-head">
            <Header justify="space-between">
              <Text size="large" weight="semibold" paddingBottom={0.5}>
                Your Order Settings
              </Text>
              <IoMdClose
                size={20}
                color={colors.dark}
                style={{ marginLeft: '1rem', cursor: 'pointer' }}
                onClick={() => setIsActiveModal(false)}
              />
            </Header>
          </header>
        )}
        {step === 1 && (
          <div className="modal-card-body">
            <DiningOption>
              <Text size="medium" weight="semibold">
                Dining Options
              </Text>
              <DiningOptionTabs
                serviceType={serviceTypes}
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
              />
            </DiningOption>
            {currentTab === 'Dinein' ? (
              <Attendees>
                <Text size="medium" weight="semibold">
                  Attendees
                </Text>
                <div className="control">
                  <input className="input" type="number" placeholder="3" />
                </div>
              </Attendees>
            ) : (
              <Text size="medium" weight="regular" paddingBottom="2">
                {serviceTypeSetting.minimum_order_amount &&
                serviceTypeSetting.minimum_order_amount !== 0
                  ? `${formatCurrency(serviceTypeSetting.minimum_order_amount)} Minimum Amount`
                  : 'No minimum order amount'}
              </Text>
            )}

            <OrderTime>
              <Text size="medium" weight="semibold" paddingBottom={0.5}>
                When would you like to order?
              </Text>
              <FlexRows justify="space-between" className="order-time">
                <Text size="smedium" weight="regular" paddingBottom={0.5}>
                  {checkBox === 'asap'
                    ? `ASAP ( ${asapFirstValue} - ${asap}m )`
                    : `${selectedDate}, ${selectedTime}`}
                </Text>
                {/* <Text
                  size="smedium"
                  weight="regular"
                  paddingBottom={0.5}
                  color="primaryColor"
                  className="change"
                  onClick={() => setStep(2)}
                  style={{ cursor: 'pointer' }}
                >
                  Change
                </Text> */}
              </FlexRows>
            </OrderTime>
            <DiningAddress>
              <Text size="medium" weight="semibold" paddingBottom={0.5}>
                {isLoggedIn && currentTab} Address
              </Text>
              {isLoggedIn && currentTab === 'Delivery' ? (
                <>
                  {deliveryAddress ? (
                    <>
                      <Text size="medium" weight="medium" paddingBottom={0.5}>
                        {deliveryAddress.address_line_1}, {deliveryAddress.city},{' '}
                        {deliveryAddress.state} {deliveryAddress.post_code}
                      </Text>
                      <a onClick={() => setStep(3)}>
                        <Text
                          size="smedium"
                          weight="regular"
                          paddingBottom={0.5}
                          color="primaryColor"
                          className="change"
                          style={{ cursor: 'pointer' }}
                        >
                          Change
                        </Text>
                      </a>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Input
                        placeholder="start typing street address or suburb name"
                        onFocus={() => setStep(3)}
                        isLocation
                      />
                      <Text
                        size="small"
                        weight="regular"
                        color="darkGrey"
                        paddingBottom={0.5}
                        onClick={() => {}}
                      >
                        Enter street address or suburb name
                      </Text>
                    </>
                  )}
                </>
              ) : (
                <Text size="medium" weight="medium" paddingBottom={0.5}>
                  {place.address_line_1}, {place.city}, {place.state} {place.post_code}
                </Text>
              )}
            </DiningAddress>
          </div>
        )}
        {step === 2 && (
          <PickUpTime
            days={days}
            timeRange={timeRange}
            checkBox={checkBox}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            handleCheck={handleCheck}
            handleSelectDate={value => {
              const day = moment(value, 'ddd, MMM, DD').format('ddd');
              setSelectedDay(day);
              setSelectedDate(value);
            }}
            handleSelectTime={value => {
              setSelectedTime(value);
            }}
            onBack={() => setStep(1)}
          />
        )}

        {step === 3 && (
          <DeliveryAddress
            userId={state.userId}
            placeId={place.place_id}
            placeName={place.name}
            userAddress={state.userAddress}
            onBack={() => setStep(1)}
            updateUserDelivery={func.updateUserAddress}
            deliveryAddress={deliveryAddress}
            handleDeliverAddress={value => setDeliverAddress(value)}
          />
        )}
        {step !== 3 && (
          <Footer className="modal-card-foot">
            {step === 1 && (
              <Button
                // className={isLoading && 'is-loading'}
                type="submit"
                name="Update"
                width={3}
                backgroundColor="primaryColor"
                textProps={{
                  color: colors.white,
                  weight: 'semibold',
                  size: 'small',
                }}
                style={{ width: '100%' }}
                onClick={() => {
                  const custom = {};
                  Object.assign(
                    custom,
                    lowerCase(serviceTypeSetting.service_type) === 'delivery' && {
                      deliveryAddress: JSON.stringify(
                        omit(deliveryAddress, ['type', `__typename`, 'error', 'location']),
                      ),
                    },
                  );
                  const variables = queryString.stringify({
                    mode: checkBox,
                    ...custom,
                  });
                  if (deliveryAddress && deliveryAddress.address_line_1) {
                    onUpdateDelivery(
                      omit(deliveryAddress, ['type', `__typename`, 'error', 'location']),
                      currentTab,
                    );
                  }
                  history.push(`/order-food/${currentTab}/${place.place_id}?${variables}`);
                  setIsActiveModal(false);
                }}
              />
            )}
            {step === 2 && (
              <Button
                // className={isLoading && 'is-loading'}
                type="submit"
                name="CONFIRM ORDER TIME"
                width={3}
                backgroundColor="primaryColor"
                textProps={{
                  color: colors.white,
                  weight: 'semibold',
                  size: 'small',
                }}
                style={{ width: '100%' }}
                onClick={() => setStep(1)}
              />
            )}
          </Footer>
        )}
      </div>
    </Container>
  );
};

export default withRouter(DiningOptionsModal);
