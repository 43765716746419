import React from 'react';

import { UploadImage, Text } from '../../components/elements';

const MobileImages = ({ setFieldValue, values }) => {
  const labelTextProps = {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  };
  const optionalTextProps = {
    size: 'tiny',
    color: 'specialGrey',
    weight: 'semibold',
  };
  return (
    <>
      <Text {...labelTextProps}>Add a photo</Text>
      <Text {...optionalTextProps}>Include a photo to represent this place</Text>
      <br />
      <div className="columns is-multiline is-mobile">
        <UploadImage
          totalNumberOfUploadedImage={0}
          colWidth={6}
          s3UploadPath="place/"
          metaData={{
            place_id: values.place_id,
            user_id: '2290802a-c911-4718-a829-5edc420e0d05',
          }}
          setFieldValue={setFieldValue}
        />
      </div>
    </>
  );
};
export default MobileImages;
