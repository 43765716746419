import React, { useState } from 'react';
import styled from 'styled-components';
import { capitalize, first } from 'lodash';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Layout from '../../components/global/MenuLayout';
import { Text, Button, Icon, Pager } from '../../components/elements';
import ImageGallery from '../../components/ImageGallery';
import PhotoPreviewModal from './PhotoPreviewModal';
import Loading from '../../components/global/mobile/MobileLoading';

const itemQuery = gql`
  query fetch_item($placeInput: NavigateItemInput, $imageInput: SearchInput) {
    fetch_item(input: $placeInput) {
      place_id
      name
      item_id
      audit {
        created_at
        created_by
      }
    }
    search_images(input: $imageInput) {
      url
      image_id
      audit {
        created_by
        created_at
      }
    }
  }
`;

const Container = styled(Pager)`
  padding-top: 2rem;
`;

const ItemPhotos = ({ history, match }) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const isLoggedIn = useStoreState(state => state.auth.isLoggedIn);

  const [modalActive, setModalActive] = useState(false);
  const [preViewImage, setPreViewImage] = useState({});
  const [whichImage, setWhichImage] = useState(0);
  const { itemId, placeId } = match.params;

  const handleOnClickAddPhotoButton = path => {
    if (!isLoggedIn) {
      setIsActiveModal({ value: true, path });
    } else {
      history.push(path);
    }
  };

  return (
    <Layout history={history} match={match}>
      <Container>
        <Query
          query={itemQuery}
          variables={{
            placeInput: { place_id: placeId, item_id: itemId },
            imageInput: {
              filter: {
                image_filter: {
                  object_id: itemId,
                  object_type: ['ITEM'],
                  parent_id: placeId,
                  parent_type: ['PLACE'],
                  links_to_id: [itemId, placeId],
                  links_to_type: ['ITEM'],
                },
              },
            },
          }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return 'error';
            }
            const { fetch_item, search_images } = data;
            const item = first(fetch_item);

            return (
              <>
                <Text className="is-capitalized" size="big" weight="bold">
                  Photos in {capitalize(item.name)}
                </Text>
                <br />
                <Button
                  name="Add Photo"
                  icon="photoAddSolid"
                  textProps={{ size: 'small' }}
                  onClick={() => handleOnClickAddPhotoButton(`/add-item-photos/${itemId}`)}
                >
                  <span className="icon is-small">
                    <Icon name="photoAddSolid" />
                  </span>
                </Button>
                <ImageGallery
                  isPreviewOpen={(image, index) => {
                    setModalActive(true);
                    setPreViewImage(image);
                    setWhichImage(index);
                  }}
                  images={search_images}
                />
                {modalActive && (
                  <PhotoPreviewModal
                    whichImage={whichImage}
                    item={first(fetch_item)}
                    isActive={modalActive}
                    images={search_images}
                    preViewImage={preViewImage}
                    isClose={() => setModalActive(false)}
                    onSliderChange={value => {
                      setPreViewImage(search_images[value]);
                    }}
                  />
                )}
              </>
            );
          }}
        </Query>
        <br />
        <Button
          name="Back"
          backgroundColor="primaryColor"
          textProps={{ color: 'white', weight: 'semibold' }}
          onClick={() => history.goBack()}
        />
      </Container>
    </Layout>
  );
};

export default ItemPhotos;
