import React from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { Text } from '../../components/elements';

const Container = styled.div`
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  padding-bottom: 1rem;
`;

const Heading = styled(Text)`
  span {
    color: ${({ theme: { colors: color } }) => color.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
  }
`;

const SubHeading = styled(Text)`
  span {
    color: ${({ theme: { colors: themeColor } }) => themeColor.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
  }
`;

const Header = ({ item }) => {
  return (
    <Container>
      <Heading className="is-capitalized" size="larger" weight="semibold" paddingBottom={0.3}>
        {capitalize(item.name)}
      </Heading>
      <Link to={`/place/${item.place_id}`}>
        <SubHeading size="medium" weight="semibold">
          {capitalize(item.place_name)}
        </SubHeading>
      </Link>
      <Text size="tiny" color="darkGrey">
        {item.address_line_1}
      </Text>
      <Text size="tiny" color="darkGrey">
        {item.city}, {item.state}, {item.post_code}
      </Text>
    </Container>
  );
};
export default Header;
