import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Text, Button, Field, FlexRows, Select, MobileInput } from '../../components/elements';

const Wrapper = styled.nav`
  padding: 0.5rem, 2rem;
  margin-right: 1rem;
`;

const BoxWrapper = styled.nav`
  padding: 0.5rem, 2rem;
  margin-right: 1rem;
`;

const Container = styled.nav`
  margin-bottom: 0.5rem;
  margin-right: 1rem;
`;
const FieldStyle = styled(Field).attrs({ labelTextProps: { color: 'dark', label: 'small' } })``;

const Span = styled.span`
  color: red;
`;

const Label = styled(Text).attrs({ color: 'darkGrey', size: 'medium' })``;

const DesktopForm = ({ history }) => {
  return (
    <>
      <FlexRows className="columns level-left">
        <div className="column is-one-third is-vcentered multiline">
          <div className="field">
            <Label>
              Full name<Span>*</Span>
            </Label>
          </div>
          <FieldStyle name="name" />
          {/* <InputErrorMessage touched={touched.name} errors={errors.name} /> */}
        </div>
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>Phone</Label>
          </div>
          <MobileInput name="mobile" />
        </div>

        <div className="column " size="4">
          <Wrapper>
            <div className="box">
              <Label weight="semibold">Email Support</Label>
              <br />
              <Text size="small" color="darkGrey">
                Customer Support
              </Text>
              <Text size="tiny" color="primaryColor">
                support@kravein.com
              </Text>
              <br />
              <Text size="small" color="darkGrey">
                Business Support
              </Text>
              <Text size="tiny" color="primaryColor">
                business@kravein.com
              </Text>
            </div>
          </Wrapper>
        </div>
      </FlexRows>

      <FlexRows className="columns level-left">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>
              Email Address<Span>*</Span>
            </Label>
          </div>
          <FieldStyle name="email" />
          {/* <InputErrorMessage
            touched={touched.email}
            errors={errors.email}
          /> */}
        </div>
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>
              City / Suburb<Span>*</Span>
            </Label>
          </div>
          <FieldStyle name="city" />
          {/* <InputErrorMessage touched={touched.city} errors={errors.city} /> */}
        </div>
        <div className="column " size="4">
          <BoxWrapper>
            <div className="box">
              <Label weight="semibold">Join Kravein Family</Label>
              <br />
              <Text size="small" color="darkGrey">
                Be a part of our growing Kravein family to enjoy unparalleled experience ...
              </Text>
              <br />
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <Button
                      name="Add a place"
                      onClick={() => history.push('/add-place')}
                      backgroundColor="primaryColor"
                    />
                  </div>
                  <div className="level-item">
                    <Button
                      name="Claim you place"
                      onClick={() => history.push('/search-place-for-claim')}
                      backgroundColor="primaryColor"
                    />
                  </div>
                </div>
              </div>
            </div>
          </BoxWrapper>
        </div>
      </FlexRows>
      <FlexRows className="columns">
        <div className="column is-two-thirds is-vcentered">
          <div className="field">
            <Label>
              Subject<Span>*</Span>
            </Label>
          </div>
          <Select name="subject" />
          {/* <InputErrorMessage touched={touched.subject} errors={errors.subject} /> */}
        </div>
        <div className="column " size="4">
          <BoxWrapper>
            <div className="box">
              <Label weight="semibold">Share what you love</Label>
              <br />
              <Text size="small" color="darkGrey">
                Create something awesome and earn KraveINs and Badges
              </Text>
              <br />
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <Button
                      name="Share an Item"
                      onClick={() => history.push('/spot-and-share-an-item/')}
                      backgroundColor="primaryColor"
                    />
                  </div>
                  <div className="level-item">
                    <Button
                      name="Review an Item"
                      onClick={() => history.push('/place-search-for-review/')}
                      backgroundColor="primaryColor"
                    />
                  </div>
                </div>
              </div>
            </div>
          </BoxWrapper>
        </div>
      </FlexRows>

      <div className="columns">
        <div className="column is-two-thirds">
          <Label weight="regular" size="medium" paddingBottom={0.5}>
            Message<Span>*</Span>
          </Label>
          <textarea className="textarea" name="message" />
          {/* <InputErrorMessage touched={touched.message} errors={errors.message} /> */}
        </div>
      </div>

      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <Button name="Send Message" backgroundColor="primaryColor" />
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(DesktopForm);
