import React from 'react';
import styled from 'styled-components';
import { MdClear, MdDone } from 'react-icons/md';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { Modal, Text, FlexRows } from '../../../../components/elements';

const options = [
  { label: 'Best Match', value: 'RATING' },
  // { label: 'Rating', value: 'rating' },
  { label: 'Distance', value: 'LOCATION' },
  { label: 'Price Low to High', value: 'PRICE_LOW_TO_HIGH' },
  { label: 'Price High to Low', value: 'PRICE_HIGH_TO_LOW' },
];

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
  &&& {
    .column {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const ModalHeaderLeft = styled.div`
  position: absolute;
  left: 0.5rem;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const Flex = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.specialGrey};
`;

const Item = ({ title, isActive, onSelect }) => (
  <Flex onClick={onSelect}>
    <div>
      <Text>{title}</Text>
    </div>
    <div className="is-flex">
      {isActive && (
        <Text color="primaryColor">
          <MdDone size={25} />
        </Text>
      )}
    </div>
  </Flex>
);

const SortByModal = ({ isActive, onCloseModal }) => {
  const updateSort = useStoreActions(state => state.place.updateSort);
  const sort = useStoreState(state => state.place.sort);
  return (
    <Modal isActive={isActive}>
      <ModalHeader>
        <ModalHeaderLeft>
          <a onClick={() => onCloseModal(false)}>
            <Text color="white">
              <MdClear size={25} />
            </Text>
          </a>
        </ModalHeaderLeft>
        <FlexRows>
          <Text className="is-uppercase" align="center" color="white" weight="bold" size="lmedium">
            Sort By
          </Text>
        </FlexRows>
      </ModalHeader>
      <Content>
        {options.map(item => (
          <Item
            key={item.value}
            title={item.label}
            isActive={item.value === sort.value}
            onSelect={() => {
              updateSort(item);
              onCloseModal();
            }}
          />
        ))}
      </Content>
    </Modal>
  );
};

export default SortByModal;
