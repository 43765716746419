import React, { useState } from 'react';
import styled from 'styled-components';
import { first, forEach, filter, isNull } from 'lodash';

import Header from './desktop/Header';
import TicketSelection from './desktop/TicketSelection';
import AttendeeForm from './desktop/AttendeeForm';
import OrderSummary from './desktop/OrderSummary';
import PaymentDetails from './desktop/PaymentDetails';
import ApplyVoucherForm from './desktop/ApplyVoucherForm';

import colors from '../../theme/styles/colors';
import { Text, FlexRows, Button, Pager } from '../../components/elements';
import Loading from '../../components/global/mobile/MobileLoading';

const Container = styled(Pager)`
  display: block;
  padding-top: 2rem;
`;
const Wrapper = styled.div`
  padding: 0.5rem 1rem;
  background-color: ${colors.lightGreyAlpha};
`;

const TicketContainer = styled.div`
  margin: 1rem 0rem;
  background-color: ${colors.white};
`;

const Element = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding: 0.5rem 1rem;
`;
const Heading = styled(FlexRows)`
  padding: 1rem;
  border-bottom: 2px solid ${colors.lightGreyTheta};
`;

const parseOfflineTicket = listOfTickets => {
  const finalTicket = [];
  forEach(listOfTickets, item => {
    forEach(item.sales_method, item2 => {
      if (item2 !== 'offline') {
        finalTicket.push(item);
      }
    });
  });
  return finalTicket;
};

const DesktopView = ({
  userId,
  eventData,
  loading,
  bookingFeeOfEvent,
  setBookingFeeOfEvent,
  subTotalPrice,
  setSubTotalPrice,
  totalQuantity,
  setTotalQuantity,
  ticketSelections,
  setTicketSelections,
  bookingFee,
  setBookingFee,
  discount,
  setDiscount,
  setCouponCode,
  checkSelectionHaveAttendeeDetailsOrNot,
  setCheckSelectionHaveAttendeeDetailsOrNot,
  finalSubmission,
  bookingLoading,
}) => {
  const [step, setStep] = useState(1);
  const [bookingInputs, setBookingInputs] = useState();
  const [storeOfTicketPlusAttendee, setStoreOfTicketPlusAttendee] = useState();
  const [applyCouponLoading, handleApplyCouponLoading] = useState(false);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [isCouponModalActive, setIsCouponModalActive] = useState(false);
  const [stepTwoHandleSubmitFormData, setStepTwoHandleSubmitFormData] = useState(false);

  if (loading) {
    return <Loading />;
  }

  const {
    fetch_event,
    fetch_event_occurrence,
    fetch_event_occurrence_ticket,
    search_place_event_voucher,
  } = eventData;

  const allVouchers = search_place_event_voucher.place_event_voucher_listing;

  const clearAppliedCoupon = () => {
    setDiscount(0);
    setIsCouponApplied(false);
    setCouponCode('');
  };

  const bookingFeeValues =
    fetch_event.fee.length !== 0
      ? fetch_event.fee.filter(
          item => item.name === 'booking_fee' && item.sales_method === 'online',
        )
      : [];

  const eventBookingFee = bookingFeeValues.length !== 0 ? first(bookingFeeValues).value : 0;
  const tickets = filter(fetch_event_occurrence_ticket, item => item.status !== 'HIDDEN');

  return (
    <Container>
      <Header event={fetch_event} />
      <Wrapper>
        <Text className="is-capitalized" size="medium" weight="semibold" paddingBottom={0.5}>
          Select a Ticket Type and Quantity
        </Text>
        <div className="columns">
          <div className="column">
            <TicketContainer>
              {step === 1 && (
                <TicketSelection
                  occurrence={first(fetch_event_occurrence)}
                  setStoreOfTicketPlusAttendee={setStoreOfTicketPlusAttendee}
                  event={fetch_event}
                  eventBookingFee={eventBookingFee}
                  bookingFeeOfEvent={bookingFeeOfEvent}
                  setBookingFeeOfEvent={setBookingFeeOfEvent}
                  tickets={parseOfflineTicket(tickets)}
                  subTotalPrice={subTotalPrice}
                  setSubTotalPrice={setSubTotalPrice}
                  totalQuantity={totalQuantity}
                  setTotalQuantity={setTotalQuantity}
                  ticketSelections={ticketSelections}
                  setTicketSelections={setTicketSelections}
                  bookingFee={bookingFee}
                  setBookingFee={setBookingFee}
                  discount={discount}
                  clearAppliedCoupon={clearAppliedCoupon}
                  checkSelectionHaveAttendeeDetailsOrNot={checkSelectionHaveAttendeeDetailsOrNot}
                  setCheckSelectionHaveAttendeeDetailsOrNot={
                    setCheckSelectionHaveAttendeeDetailsOrNot
                  }
                  loading={false}
                  back={() => setStep(1)}
                  onHandleConfirmBooking={() => {
                    if (!checkSelectionHaveAttendeeDetailsOrNot) {
                      const ticketsData = ticketSelections.map(ticket => {
                        const customTicket = {
                          ticket_id: ticket.id,
                          quantity: ticket.quantity,
                        };
                        return customTicket;
                      });
                      const input = {
                        user_id: userId,
                        place_id: fetch_event.place_id,
                        event_id: fetch_event.event_id,
                        event_occurrence_id: first(fetch_event_occurrence).event_occurrence_id,
                        sales_method: 'online',
                        ticket: ticketsData,
                      };
                      setBookingInputs(input);
                      setStep(3);
                    } else {
                      setStep(2);
                    }
                  }}
                />
              )}

              {step === 2 && (
                <AttendeeForm
                  setStepTwoHandleSubmitFormData={setStepTwoHandleSubmitFormData}
                  ticketSelections={ticketSelections}
                  storeOfTicketPlusAttendee={storeOfTicketPlusAttendee}
                  totalQuantity={totalQuantity}
                  loading={false}
                  orderTotal={subTotalPrice + bookingFee - discount + bookingFeeOfEvent}
                  back={() => setStep(1)}
                  onSubmit={values => {
                    setStoreOfTicketPlusAttendee(values.ticketSelections);
                    const ticketsData = values.ticketSelections.map(ticket => {
                      const customTicket = {
                        ticket_id: ticket.ticket_id,
                        quantity: ticket.quantity,
                        attendee: ticket.attendees.map(attendee => {
                          const name = attendee.attributes.filter(item => item.name === 'name');
                          const email = attendee.attributes.filter(item => item.name === 'email');
                          const mobile = attendee.attributes.filter(item => item.name === 'mobile');
                          const age = attendee.attributes.filter(item => item.name === 'age');
                          const gender = attendee.attributes.filter(item => item.name === 'gender');
                          return {
                            attendee_name: name.length !== 0 ? first(name).values : null,
                            attendee_email: email.length !== 0 ? first(email).values : null,
                            attendee_age: age.length !== 0 ? parseInt(first(age).values, 10) : null,
                            attendee_gender: gender.length !== 0 ? first(gender).values : null,
                            attendee_phone: mobile.length !== 0 ? first(mobile).values : null,
                          };
                        }),
                      };
                      return customTicket;
                    });
                    const input = {
                      user_id: userId,
                      place_id: fetch_event.place_id,
                      event_id: fetch_event.event_id,
                      event_occurrence_id: first(fetch_event_occurrence).event_occurrence_id,
                      sales_method: 'online',
                      ticket: ticketsData,
                    };
                    setBookingInputs(input);
                    setStep(3);
                  }}
                />
              )}
              {step === 3 && (
                <OrderSummary
                  tickets={fetch_event_occurrence_ticket}
                  ticketSelections={ticketSelections}
                  subTotalPrice={subTotalPrice}
                  totalQuantity={totalQuantity}
                  bookingFee={bookingFee}
                  discount={discount}
                  bookingFeeOfEvent={bookingFeeOfEvent}
                />
              )}
            </TicketContainer>
          </div>

          <div className="column is-3">
            <TicketContainer
              style={{ position: `${isCouponModalActive ? 'inherit' : 'sticky'}`, top: '7rem' }}
            >
              <div style={{ position: 'sticky', top: '7rem' }} />
              <Heading>
                <Text weight="semibold" color="darkGrey" size="large">
                  Order Details
                </Text>
              </Heading>
              <Element>
                <div>
                  <Text weight="semibold" color="darkGrey" size="small">
                    Total Quantity :
                  </Text>
                </div>
                <div>
                  <Text weight="semibold" color="darkGrey" size="small">
                    {totalQuantity}
                  </Text>
                </div>
              </Element>
              {subTotalPrice !== 0 && (
                <Element>
                  <div>
                    <Text weight="semibold" color="darkGrey" size="small">
                      Sub Total :
                    </Text>
                  </div>
                  <div>
                    <Text weight="semibold" color="darkGrey" size="small">
                      ${parseFloat(subTotalPrice).toFixed(2)}
                    </Text>
                  </div>
                </Element>
              )}
              {bookingFee !== 0 && (
                <Element>
                  <div>
                    <Text weight="semibold" color="darkGrey" size="small">
                      Ticket Fee
                    </Text>
                  </div>
                  <div>
                    <Text weight="semibold" color="darkGrey" size="small">
                      ${parseFloat(bookingFee).toFixed(2)}
                    </Text>
                  </div>
                </Element>
              )}
              {bookingFeeOfEvent !== 0 && (
                <Element>
                  <div>
                    <Text weight="semibold" color="darkGrey" size="small">
                      Booking Fee :
                    </Text>
                  </div>
                  <div>
                    <Text weight="semibold" color="darkGrey" size="small">
                      ${parseFloat(bookingFeeOfEvent).toFixed(2)}
                    </Text>
                  </div>
                </Element>
              )}
              {discount !== 0 && (
                <Element>
                  <div>
                    <Text weight="semibold" color="primaryColor" size="small">
                      Promo
                    </Text>
                  </div>
                  <div>
                    <Text weight="semibold" color="primaryColor" size="small">
                      - ${parseFloat(discount).toFixed(2)}
                    </Text>
                  </div>
                </Element>
              )}
              <Element>
                <div>
                  <Text weight="semibold" color="dark" size="small">
                    Order Total :
                  </Text>
                </div>
                <div>
                  <Text weight="semibold" color="dark" size="small">
                    $
                    {parseFloat(subTotalPrice + bookingFee - discount + bookingFeeOfEvent).toFixed(
                      2,
                    )}
                  </Text>
                </div>
              </Element>
              {totalQuantity !== 0 && (
                <>
                  <hr />
                  <div style={{ padding: '0.5rem 1rem' }}>
                    {step === 1 ? (
                      <Button
                        name={
                          checkSelectionHaveAttendeeDetailsOrNot ? 'Confirm Booking' : 'Checkout'
                        }
                        backgroundColor="primaryColor"
                        textProps={{
                          color: 'white',
                          weight: 'semibold',
                        }}
                        onClick={() => {
                          if (step === 1) {
                            if (!checkSelectionHaveAttendeeDetailsOrNot) {
                              const ticketsData = ticketSelections.map(ticket => {
                                const customTicket = {
                                  ticket_id: ticket.id,
                                  quantity: ticket.quantity,
                                };
                                return customTicket;
                              });
                              const input = {
                                user_id: userId,
                                place_id: fetch_event.place_id,
                                event_id: fetch_event.event_id,
                                event_occurrence_id: first(fetch_event_occurrence)
                                  .event_occurrence_id,
                                sales_method: 'online',
                                ticket: ticketsData,
                              };
                              setBookingInputs(input);
                              setStep(3);
                            } else {
                              setStep(2);
                            }
                          }
                        }}
                        isFullWidth
                      />
                    ) : (
                      <Button
                        className={bookingLoading && 'is-loading'}
                        name={step === 3 ? 'Complete Booking' : 'Checkout'}
                        backgroundColor="primaryColor"
                        textProps={{
                          color: bookingLoading ? 'primaryColor' : 'white',
                          weight: 'semibold',
                        }}
                        onClick={() => {
                          if (step === 3) {
                            finalSubmission(bookingInputs);
                          }
                          if (step === 2) {
                            stepTwoHandleSubmitFormData.handleSubmit();
                          }
                        }}
                        isFullWidth
                      />
                    )}
                  </div>
                </>
              )}

              {totalQuantity !== 0 && step === 1 && subTotalPrice !== 0 && (
                <>
                  <ApplyVoucherForm
                    userId={userId}
                    allVouchers={allVouchers}
                    setIsCouponModalActive={setIsCouponModalActive}
                    applyCouponLoading={applyCouponLoading}
                    handleApplyCouponLoading={value => handleApplyCouponLoading(value)}
                    eventId={fetch_event.event_id}
                    event={fetch_event}
                    eventOccurrenceId={first(fetch_event_occurrence).event_occurrence_id}
                    placeId={fetch_event.place_id}
                    tickets={ticketSelections}
                    subTotalPrice={subTotalPrice}
                    isCouponApplied={isCouponApplied}
                    removedCoupon={() => {
                      setDiscount(0);
                      setCouponCode('');
                      setIsCouponApplied(false);
                    }}
                    handleDisCount={(discountPrice, voucherCode) => {
                      setDiscount(parseFloat(discountPrice).toFixed(2));
                      setCouponCode(isNull(voucherCode) ? '' : voucherCode);
                      setIsCouponApplied(true);
                    }}
                  />
                </>
              )}
            </TicketContainer>
          </div>
        </div>
        {step === 3 && (
          <PaymentDetails
            loading={bookingLoading}
            handleCompleteBooking={() => {
              finalSubmission(bookingInputs);
            }}
            back={() => {
              if (!checkSelectionHaveAttendeeDetailsOrNot) {
                setStep(1);
              } else {
                setStep(2);
              }
            }}
          />
        )}
      </Wrapper>
    </Container>
  );
};

export default DesktopView;
