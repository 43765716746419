import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import client from '../../utils/apolloClient';

const searchPlacesQuery = gql`
  query searchPlaceQuery($placeName: String, $location: String) {
    search_places(
      input: {
        location: $location
        search_criteria: $placeName
        filter: {
          place_filter: { status: "ACTIVE", listing_status: "APPROVED", claim_status: "UNCLAIMED" }
        }
      }
    ) {
      place_listing {
        place_id
        name
        tagline
        cuisine_style {
          name
          description
        }
        business_type {
          name
          description
        }
        address_line_1
        address_line_2
        city
        state
        country
        post_code
      }
    }
  }
`;

const PlaceSearchForClaim = ({ history, match }) => {
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const [placeQueryTag, setPlaceQueryTag] = useState('');
  const [location, setLocation] = useState('');
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const recentPlaceViews = useStoreState(state => state.place.recentViews);
  const recentLocationViews = useStoreState(state => state.global.recentViewLocations);

  const onSuggestionsFetchRequested = () => {
    setLoading(true);
    client.clientPublic
      .query({
        query: searchPlacesQuery,
        variables: { placeName: placeQueryTag, location },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        setPlaces(data.search_places.place_listing);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onMobileSuggestionsFetchRequested = value => {
    setLoading(true);
    client.clientPublic
      .query({
        query: searchPlacesQuery,
        variables: { placeName: value, location },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        setPlaces(data.search_places.place_listing);
        setLoading(false);
      })
      .catch(error => {
        console.log('error', error);
        setLoading(false);
      });
  };

  return (
    <Layout history={history} match={match}>
      <div className="is-hidden-desktop">
        <MobileView
          placeLoading={loading}
          places={places}
          placeQueryTag={placeQueryTag}
          setPlaceQueryTag={setPlaceQueryTag}
          onSuggestionsFetchRequested={onMobileSuggestionsFetchRequested}
          recentPlaceViews={recentPlaceViews}
          recentLocationViews={recentLocationViews}
          onSelectLocation={setLocation}
          usedLocation={usedLocation}
        />
      </div>
      <div className="is-hidden-touch">
        <DesktopView
          places={places}
          loading={loading}
          placeQueryTag={placeQueryTag}
          setPlaceQueryTag={setPlaceQueryTag}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          setLocation={setLocation}
        />
      </div>
    </Layout>
  );
};

export default PlaceSearchForClaim;
