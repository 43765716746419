import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { first, uniqBy } from 'lodash';
import moment from 'moment';
import { Text, FlexRows } from '../../../../components/elements';

const Container = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;
const Wrapper = styled.div`
  padding: 1rem 0.5rem;
  .hours-right {
    padding-left: 1rem;
  }
`;
const Header = styled(FlexRows)`
  background: ${({ theme: { colors } }) => colors.primaryColor};
  padding: 0.8rem;
`;

const Tabs = styled(FlexRows).attrs({ justify: 'space-between' })`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  padding: 0rem 1rem;
`;
const Item = styled.a`
  padding: 0.5rem 0rem;
  border-bottom: 1px solid
    ${({ theme: { colors }, active }) => (active ? colors.primaryColor : 'transparent')};
`;

const HoursHeading = styled(Text).attrs({ weight: 'medium', size: 'small', paddingBottom: 0.5 })``;
const HoursContent = styled(Text).attrs({
  color: 'primaryColor',
  weight: 'medium',
  size: 'small',
  paddingBottom: 0.5,
})``;

const PlaceHours = ({ place }) => {
  const [currentHour, setCurrentHour] = useState();

  const hours =
    place.business_hour && place.business_hour.length !== 0
      ? uniqBy(place.business_hour, 'day')
      : [];

  useEffect(() => {
    if (place.business_hour && place.business_hour.length !== 0) {
      const day = moment().day();
      const time_range_for_a_day =
        place.business_hour && place.business_hour.length !== 0
          ? place.business_hour[day - 1]
          : { option: [] };
      setCurrentHour(time_range_for_a_day);
    }
  }, [place.business_hour]);
  console.log('PlaceHours -> currentHour', currentHour);
  if (hours.length === 0) {
    return null;
  }

  return (
    <Container>
      <Header>
        <Text color="white" weight="semibold">
          HOURS
        </Text>
      </Header>
      <Tabs>
        {hours.map(item => (
          <Item
            key={item.day}
            active={currentHour && item.day === currentHour.day}
            onClick={() => setCurrentHour(item)}
          >
            <Text className="is-capitalized" size="small" weight="semibold">
              {item.day}
            </Text>
          </Item>
        ))}
      </Tabs>
      {currentHour && (
        <Wrapper>
          {currentHour &&
            currentHour.option.length !== 0 &&
            currentHour.option.map(data => (
              <FlexRows justify="flex-start">
                <div>
                  <HoursHeading className="is-capitalized">{data.type}</HoursHeading>
                </div>

                {data.type !== 'Open 24 hours' && data.type !== 'Closed' && (
                  <div className="hours-right">
                    <HoursContent>
                      {data.start} {data.type === '' ? '' : 'to'} {data.end}
                    </HoursContent>
                  </div>
                )}
              </FlexRows>
            ))}
        </Wrapper>
      )}
    </Container>
  );
};

export default PlaceHours;
