import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useStoreActions, useStoreState } from 'easy-peasy';
import { Icon, Text, FlexRows } from '../../../../components/elements';
import GetImages from '../../../../components/GetImages';

const Container = styled.form`
  && {
    border-radius: 4px;
    .media {
      border: 0;
      padding: 0;
    }
    .columns:not(:last-child) {
      margin: 0;
    }
    .input {
      border-radius: 19px;
      padding-left: 1rem;
    }
  }
`;

const Wrapper = styled.div``;

const SearchInput = styled.div`
  &&& {
    margin-top: 6px;
  }
`;

const PlaceReviewCommentInput = ({ loading, onSubmit, commentTextProps }) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn, user, userImage } = useStoreState(state => state.auth);
  const [commentText, setCommentText] = useState(commentTextProps || '');
  const [trackSubmit, setTrackSubmit] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (loading === false && trackSubmit === true) {
      setCommentText('');
      setTrackSubmit(false);
    }
  }, [loading, trackSubmit]);

  const onSubmitComments = () => {
    if (commentText.length !== 0 && commentText.length <= 144) {
      onSubmit(commentText);
      setTimeout(() => {
        setTrackSubmit(true);
      }, 100);
    } else {
      setIsError(true);
    }
  };

  const handleCheckLogged = () => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
        // callBackFunction: () => onSubmitComments(),
      });
    } else if (!loading) {
      onSubmitComments();
    }
  };

  return (
    <Container
      onSubmit={event => {
        event.preventDefault();
        handleCheckLogged();
      }}
    >
      <article className="media">
        <figure className="media-left">
          <Wrapper>
            {isLoggedIn ? (
              <GetImages
                name={user.display_name}
                maskProps={{ width: 40, height: 40 }}
                maskTextProps={{
                  color: 'white',
                  weight: 'bold',
                  size: 'medium',
                  letterSpacing: 'loose',
                }}
                borderRadius={25}
                randomColorCode={user.color_code}
                userImage={userImage}
              />
            ) : (
              <Icon name="myProfileBlue" size={2.5} />
            )}
          </Wrapper>
        </figure>
        <div className="media-content">
          <div className="content">
            <SearchInput className={`control ${loading && 'is-loading'}`}>
              <input
                className="input"
                placeholder="Write a comment...."
                value={commentText}
                onChange={event => {
                  const { value } = event.target;
                  setCommentText(value);
                  setIsError(value.length > 144);
                }}
              />
              {isError && (
                <Text color="danger" size="micro">
                  {commentText.length === 0
                    ? 'Please enter something...'
                    : 'Comment length is not greater than 144 characters'}
                </Text>
              )}
              <FlexRows justify="space-between">
                <Text size="micro" color="specialGrey">
                  Character left {commentText.length} / 144
                </Text>
                <Text size="micro" color="specialGrey">
                  Press enter to post
                </Text>
              </FlexRows>
            </SearchInput>
          </div>
        </div>
      </article>
    </Container>
  );
};
export default PlaceReviewCommentInput;
