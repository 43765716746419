import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import gql from 'graphql-tag';

import { FlexRows, IconSocialActionButton } from '../../../../components/elements';
// import ShareActionButton from '../../../../components/ShareActionButton';
import client from '../../../../utils/apolloClient';

const SocialActionButtonStyle = styled(IconSocialActionButton).attrs({
  textStyle: { size: 'micro', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1.2 },
})``;

// const ShareActionButtonButtonStyle = styled(ShareActionButton).attrs({
//   textStyle: { size: 'micro', weight: 'semibold', color: 'darkGrey' },
//   paddingLess: true,
//   iconStyle: { size: 1 },
//   showCount: true,
// })``;

const socialMutation = gql`
  mutation socialAction($input: SocialNetworkInput) {
    create_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const removeSocialMutation = gql`
  mutation removeSocialAction($input: NavigateSocialInput) {
    delete_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const Social = ({
  objectId,
  objectType,
  triedActive,
  lovedActive,
  bookMarkActive,
  triedCountInt,
  lovedCountInt,
  bookMarkCountInt,
  triedSocialIdS,
  lovedSocialIdS,
  bookMarkSocialIdS,
}) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn, userId } = useStoreState(state => state.auth);
  const [triedCount, setTriedCount] = useState(triedCountInt);
  const [lovedCount, setLovedCount] = useState(lovedCountInt);
  const [bookMarkCount, setBookMarkCount] = useState(bookMarkCountInt);

  const [triedSocialId, setTriedSocialId] = useState(triedSocialIdS);
  const [lovedSocialId, setLovedSocialId] = useState(lovedSocialIdS);
  const [bookMarkSocialId, setBookMarkSocialId] = useState(bookMarkSocialIdS);

  const [isActiveTried, setIsActiveTried] = useState(triedActive);
  const [isActiveLoved, setIsActiveLoved] = useState(lovedActive);
  const [isActiveBookMark, setIsActiveBookMark] = useState(bookMarkActive);

  const [triedLoading, setTriedLoading] = useState(false);
  const [lovedLoading, setLovedLoading] = useState(false);
  const [bookMarkLoading, setBookMarkLoading] = useState(false);

  useEffect(() => {
    setTriedCount(triedCountInt);
    setLovedCount(lovedCountInt);
    setBookMarkCount(bookMarkCountInt);

    setTriedSocialId(triedSocialIdS);
    setLovedSocialId(lovedSocialIdS);
    setBookMarkSocialId(bookMarkSocialIdS);

    setIsActiveTried(triedActive);
    setIsActiveLoved(lovedActive);
    setIsActiveBookMark(bookMarkActive);
  }, [
    bookMarkActive,
    bookMarkCountInt,
    bookMarkSocialIdS,
    lovedActive,
    lovedCountInt,
    lovedSocialIdS,
    triedActive,
    triedCountInt,
    triedSocialIdS,
  ]);

  const handleCountIncrement = type => {
    if (type === 'TRIED') {
      setTriedCount(triedCount + 1);
      setIsActiveTried(true);
      setTriedLoading(false);
    }
    if (type === 'LOVE_IT') {
      setLovedCount(lovedCount + 1);
      setIsActiveLoved(true);
      setLovedLoading(false);
    }
    if (type === 'BOOKMARK') {
      setBookMarkCount(bookMarkCount + 1);
      setIsActiveBookMark(true);
      setBookMarkLoading(false);
    }
  };

  const handleCountDecrement = type => {
    if (type === 'TRIED') {
      setTriedCount(triedCount - 1);
      setIsActiveTried(false);
      setTriedLoading(false);
    }
    if (type === 'LOVE_IT') {
      setLovedCount(lovedCount - 1);
      setIsActiveLoved(false);
      setLovedLoading(false);
    }
    if (type === 'BOOKMARK') {
      setBookMarkCount(bookMarkCount - 1);
      setIsActiveBookMark(false);
      setBookMarkLoading(false);
    }
  };

  const handleOnClickActive = type => {
    if (type === 'TRIED') {
      setTriedLoading(true);
    }
    if (type === 'LOVE_IT') {
      setLovedLoading(true);
    }
    if (type === 'BOOKMARK') {
      setBookMarkLoading(true);
    }
    client.clientPrivate
      .mutate({
        mutation: socialMutation,
        variables: {
          input: {
            user_id: userId,
            object_id: objectId,
            object_type: objectType,
            type,
            value: 1,
          },
        },
      })
      .then(({ data }) => {
        handleCountIncrement(type);
        if (type === 'TRIED') {
          setTriedSocialId(data.create_social.social_id);
        }
        if (type === 'LOVE_IT') {
          setLovedSocialId(data.create_social.social_id);
        }
        if (type === 'BOOKMARK') {
          setBookMarkSocialId(data.create_social.social_id);
        }
      });
  };

  const handleOnClickRemove = (type, socialId) => {
    if (type === 'TRIED') {
      setTriedLoading(true);
    }
    if (type === 'LOVE_IT') {
      setLovedLoading(true);
    }
    if (type === 'BOOKMARK') {
      setBookMarkLoading(true);
    }
    client.clientPrivate
      .mutate({
        mutation: removeSocialMutation,
        variables: {
          input: {
            user_id: userId,
            social_id: socialId,
          },
        },
      })
      .then(() => {
        handleCountDecrement(type);
      });
  };

  const handleSocialAction = (type, action, socialId) => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
      });
    } else if (action) {
      handleOnClickActive(type);
    } else {
      handleOnClickRemove(type, socialId);
    }
  };

  return (
    <FlexRows justify="space-between">
      <SocialActionButtonStyle
        loading={triedLoading}
        active={isActiveTried}
        name="Tried"
        defaultIcon="triedGrey"
        activeIcon="triedBlue"
        socialCount={triedCount}
        handleOnClick={() => handleSocialAction('TRIED', !isActiveTried, triedSocialId)}
      />
      <SocialActionButtonStyle
        loading={lovedLoading}
        active={isActiveLoved}
        name="Loved"
        defaultIcon="loveTheItemGrey"
        activeIcon="loveTheItemBlue"
        socialCount={lovedCount}
        handleOnClick={() => handleSocialAction('LOVE_IT', !isActiveLoved, lovedSocialId)}
      />
      <SocialActionButtonStyle
        loading={bookMarkLoading}
        active={isActiveBookMark}
        name="Bookmark"
        defaultIcon="bookmarkSolidGrey"
        activeIcon="bookmarkSolid"
        socialCount={bookMarkCount}
        handleOnClick={() => handleSocialAction('BOOKMARK', !isActiveBookMark, bookMarkSocialId)}
      />
    </FlexRows>
  );
};

Social.defaultProps = {
  objectId: '',
  objectType: '',
  bookMarkActive: false,
  bookMarkCountInt: 0,
};

Social.propTypes = {
  objectId: PropTypes.string,
  objectType: PropTypes.string,
  bookMarkActive: PropTypes.bool,
  bookMarkCountInt: PropTypes.number,
};

export default Social;
