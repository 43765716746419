import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { forEach, omit } from 'lodash';

import RatingInput from './RatingInput';
import Images from './Images';
import { Text, Button, ReactSelect, TagInputs, InputErrorMessage } from '../../components/elements';
import { getServiceCategory } from '../../utils/fetchStatic';

const Heading = styled.div`
  padding: 1rem 0rem;
`;
const ImageUploadWrapper = styled.div`
  padding: 1rem;
`;
const Span = styled.span`
  color: red;
`;
const Wrapper = styled.div`
  padding: 0rem 1rem;
`;

const Label = styled(Text).attrs({ color: 'darkGrey', size: 'medium' })``;

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const parseInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const handleImageSubmissionWhileUploading = image => {
  let isDisabled = false;
  image.forEach(element => {
    if (element.uploadingStatus === 'uploading') {
      isDisabled = true;
    }
  });
  return isDisabled;
};

const Form = props => {
  const [data, setData] = useState([{ serviceCategories: [] }]);
  const { loading, values, setFieldValue, handleSubmit, touched, errors, history } = props;
  const [monthList, setMonthList] = useState([]);

  const handleOnClick = async () => {
    await setFieldValue('isDraft', false);
    handleSubmit();
  };

  const handleOnClickDraft = async () => {
    await setFieldValue('isDraft', true);
    handleSubmit();
  };

  const getLast12Months = async () => {
    const monthName = moment.months();
    const d = new Date();
    // const withoutCurrentMonth = monthName.filter(item => item !== moment().format('MMMM'));
    const listOfMonths = [];
    d.setDate(1);
    await forEach(monthName, () => {
      listOfMonths.push(`${monthName[d.getMonth()]} ${d.getFullYear()}`);
      d.setMonth(d.getMonth() - 1);
    });

    setMonthList(listOfMonths);
  };

  useEffect(() => {
    const getData = async () => {
      const serviceCategories = await getServiceCategory();
      setData({
        serviceCategories,
      });
    };
    getData();
    getLast12Months();
  }, []);

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleOnClick();
      }}
    >
      <Heading>
        <Text size="large" weight="semibold">
          Write a Review
        </Text>
      </Heading>
      <div className="columns">
        <div className="column is-6">
          <div className="columns is-gapless is-vcentered">
            <div className="column">
              <Label>
                How much do you like this place ?<Span>*</Span>
              </Label>
            </div>
            <div className="column ">
              <RatingInput
                initialValue={values.overall_rating}
                handleChange={value => setFieldValue('overall_rating', value)}
              />
              <InputErrorMessage touched={touched.overall_rating} errors={errors.overall_rating} />
            </div>
          </div>
          <div className="columns is-gapless is-vcentered">
            <div className="column">
              <Label>
                When did you visit this place ?<Span>*</Span>
              </Label>
            </div>
            <div className="column ">
              <ReactSelect
                options={monthList.map(item => ({ label: item, value: item }))}
                value={{
                  label:
                    values.month_visited !== ''
                      ? moment(values.month_visited).format('MMMM YYYY')
                      : '',
                  value: moment(values.month_visited).format('MMMM YYYY'),
                }}
                onChange={value =>
                  setFieldValue(
                    'month_visited',
                    moment()
                      .month(value.value)
                      .toISOString(),
                  )
                }
              />
              <InputErrorMessage touched={touched.month_visited} errors={errors.month_visited} />
            </div>
          </div>
          <div className="columns is-gapless is-vcentered">
            <div className="column">
              <Label>What service do you attend ?</Label>
            </div>
            <div className="column">
              <TagInputs
                value={parseData(values.service_category)}
                suggestions={parseData(data.serviceCategories)}
                onChange={value => setFieldValue('service_category', parseInput(value))}
              />
            </div>
          </div>
          <div className="field">
            <Label paddingBottom={0.5}>
              Write your review<Span>*</Span> ( Min 50 and Max 1500 characters )
            </Label>
            <div className="control">
              <textarea
                value={values.content}
                className="textarea"
                id="Review_WriteAReview"
                // eslint-disable-next-line max-len
                placeholder="What do you like about this place? Use @ to mention someone or use # to mention a dish"
                onChange={event => setFieldValue('content', event.target.value)}
              />
              <Text color="darkGrey" size="micro">
                Characters Left: {values.content.length}/ 1500
              </Text>
              <InputErrorMessage touched={touched.content} errors={errors.content} />
            </div>
          </div>
        </div>
        <div className="column is-4">
          <ImageUploadWrapper>
            <Label weight="semibold">Add Photos</Label>
            <Text size="small" color="darkGrey">
              Drag and drop or upload upto 4 images. Formats accepted - &quot;.png, .jpg,
              .jpeg&quot; <br />
              Maximum size accepted - 10 MB
            </Text>
            <br />
          </ImageUploadWrapper>
          <Wrapper>
            <Images values={values} setFieldValue={setFieldValue} />
          </Wrapper>
        </div>
      </div>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <Button
              name="Back"
              type="button"
              backgroundColor="primaryColor"
              textProps={{ color: 'white', weight: 'semibold' }}
              onClick={() => history.goBack()}
            />
          </div>
          <div className="level-item">
            <Button
              name="Save Draft"
              className={values.isDraft && loading && 'is-loading'}
              type="button"
              backgroundColor="primaryColor"
              textProps={{
                color: values.isDraft && loading ? 'primaryColor' : 'white',
                weight: 'semibold',
              }}
              onClick={handleOnClickDraft}
              disabled={handleImageSubmissionWhileUploading(values.image)}
            />
          </div>
        </div>
        <div className="level-right">
          <Button
            type="submit"
            className={!values.isDraft && loading && 'is-loading'}
            name="Post Review"
            backgroundColor="primaryColor"
            textProps={{
              color: !values.isDraft && loading ? 'primaryColor' : 'white',
              weight: 'semibold',
            }}
            disabled={handleImageSubmissionWhileUploading(values.image)}
          />
        </div>
      </nav>
    </form>
  );
};

const DeskForm = withFormik({
  mapPropsToValues: ({ place, placeReview, uploadedImages }) => ({
    isDraft: false,
    place_id: place ? place.place_id : '',
    content: placeReview ? placeReview.content : '',
    overall_rating: placeReview ? parseFloat(placeReview.overall_rating).toFixed(0) : 0,
    month_visited: placeReview ? placeReview.month_visited : '',
    service_category: placeReview ? removeTypename(placeReview.service_category) : [],
    uploadedImages,
    image: [
      {
        url: '',
        preview: null,
        tooltip: '',
        link: '',
        tag: [],
        uploadingStatus: 'pending',
      },
    ],
  }),

  validationSchema: yup.object().shape({
    content: yup
      .string()
      .min(50, 'At least 50 Characters Required!')
      .max(1500, 'Not more than 1500 Characters!')
      .required('Review Content is required!'),
    overall_rating: yup
      .number()
      .moreThan(0, 'Rating is required!')
      .required('Rating is required!'),
    month_visited: yup.string().required('Month visited is required!'),
  }),

  handleSubmit: (values, { props }) => {
    const { isLoggedIn, setIsActiveDesktopLoginModal } = props;
    if (!isLoggedIn) {
      setIsActiveDesktopLoginModal({
        value: true,
        path: '',
        callBackFunction: () => props.onSubmit(values),
      });
    } else {
      props.onSubmit(values);
    }
  },

  displayName: 'PostForm',
})(Form);

export default DeskForm;
