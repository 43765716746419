import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed from 'react-pose';
import gql from 'graphql-tag';
import { isNull, first } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withRouter } from 'react-router-dom';
import { Icon, Text, FlexColumns } from './elements';
import client from '../utils/apolloClient';

const socialQuery = gql`
  query social($input: SearchInput) {
    search_social(input: $input) {
      social_listing {
        social_id
      }
      social_aggregation {
        total_social_count {
          type
          count
        }
        total_social_per_object_id {
          object_id
          total_social_count {
            user {
              user_id
              time
              social_id
            }
          }
        }
      }
    }
  }
`;

const socialMutation = gql`
  mutation socialAction($input: SocialNetworkInput) {
    create_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const removeSocialMutation = gql`
  mutation removeSocialAction($input: NavigateSocialInput) {
    delete_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const ButtonAnimation = posed.a({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const Button = styled(ButtonAnimation)`
  &&& {
    border: 0;
    ${({ paddingLess }) => paddingLess && `padding:0.2rem 0.5rem`};
    background-color: transparent;
  }
`;

const VerticalSpan = styled.span`
  padding-top: 5px;
`;

const SocialActionButton = ({
  objectId,
  objectType,
  type,
  name,
  defaultIcon,
  activeIcon,
  textStyle,
  iconStyle,
  paddingLess,
  vertical,
  withoutIcon,
  showCount,
  trackOnChange,
}) => {
  // const findArray = !isNull(social) ? social.filter(item => item.type === type) : [];
  // const count = findArray.length !== 0 ? first(findArray).value : 0;
  const [active, setActive] = React.useState(false);
  const [socialCount, setSocialCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [socialId, setSocialId] = React.useState('');
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn, userId } = useStoreState(state => state.auth);

  //   const isLoggedIn = useStoreState(state => state.auth.isLoggedIn);
  const social_filter = {};
  Object.assign(
    social_filter,
    { object_type: objectType, object_id: [objectId], type },
    // userId && { user_id: '48532fd5-fa6a-4def-adec-b8f55e2563af' },
  );

  // const fetchSocial = useCallback(() => {});

  useEffect(() => {
    client.clientPublic
      .query({
        query: socialQuery,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            filter: {
              social_filter,
            },
          },
        },
      })
      .then(async ({ data: { search_social } }) => {
        if (search_social && !isNull(search_social)) {
          if (search_social.social_aggregation && !isNull(search_social.social_aggregation)) {
            const totalSocialCount = first(search_social.social_aggregation.total_social_count);
            if (totalSocialCount) {
              setSocialCount(totalSocialCount.count);
            } else {
              setSocialCount(0);
              setActive(false);
            }
            const total_social_per_object_id = first(
              search_social.social_aggregation.total_social_per_object_id,
            );
            // setSocialId(total_social_per_object_id.object_id);
            if (total_social_per_object_id && isLoggedIn) {
              const total_social_count = first(total_social_per_object_id.total_social_count);
              if (total_social_count) {
                const user = await total_social_count.user.filter(item => item.user_id === userId);
                if (user.length !== 0) {
                  setActive(user.length !== 0 && true);
                  setSocialId(first(user).social_id);
                } else {
                  setActive(false);
                }
              }
            }
          }
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId]);

  const handleOnClickActive = () => {
    setActive(true);
    setSocialCount(socialCount + 1);
    setLoading(true);
    client.clientPrivate
      .mutate({
        mutation: socialMutation,
        variables: {
          input: {
            user_id: userId,
            object_id: objectId,
            object_type: objectType,
            type,
            value: 1,
          },
        },
      })
      .then(({ data }) => {
        setLoading(false);
        setSocialId(data.create_social.social_id);
        trackOnChange();
      });
  };

  const handleOnClickRemove = () => {
    setActive(false);
    setSocialCount(socialCount - 1);
    setLoading(true);
    client.clientPrivate
      .mutate({
        mutation: removeSocialMutation,
        variables: {
          input: {
            user_id: userId,
            social_id: socialId,
          },
        },
      })
      .then(() => {
        setLoading(false);
        trackOnChange();
      });
  };

  const handleOnClick = () => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
        // callBackFunction: userId && (active ? handleOnClickRemove : handleOnClickActive),
      });
    } else if (active) {
      handleOnClickRemove();
    } else {
      handleOnClickActive();
    }
  };

  // if (onLoadLoading) {
  //   return <ReactLoader x={0} y={10} height={40} width={window.innerWidth / 1.5} />;
  // }

  return (
    <>
      {!vertical ? (
        <Button
          className="button"
          paddingLess={paddingLess}
          onClick={handleOnClick}
          // disabled={loading || onLoadLoading}
        >
          {defaultIcon && activeIcon && (
            <span className="icon">
              <Icon name={active ? activeIcon : defaultIcon} {...iconStyle} />
            </span>
          )}
          <span>
            {withoutIcon ? (
              <Text color={active ? 'primaryColor' : 'waterMarkGrey'} size="small" weight="regular">
                {name} {showCount && socialCount !== 0 && `(${socialCount})`}
              </Text>
            ) : (
              <Text {...textStyle}>
                {name} {showCount && socialCount !== 0 && `(${socialCount})`}
              </Text>
            )}
          </span>
        </Button>
      ) : (
        <Button className="button" onClick={handleOnClick} disabled={loading}>
          <FlexColumns>
            {defaultIcon && activeIcon && (
              <span>
                <Icon name={active ? activeIcon : defaultIcon} {...iconStyle} />
              </span>
            )}
            <VerticalSpan>
              {withoutIcon ? (
                <Text
                  color={active ? 'primaryColor' : 'waterMarkGrey'}
                  size="small"
                  weight="regular"
                >
                  {name} {showCount && socialCount !== 0 && `(${socialCount})`}
                </Text>
              ) : (
                <Text {...textStyle}>
                  {name} {showCount && socialCount !== 0 && `(${socialCount})`}
                </Text>
              )}
            </VerticalSpan>
          </FlexColumns>
        </Button>
      )}
    </>
  );
};

SocialActionButton.defaultProps = {
  vertical: false,
  social: null,
  type: '',
  value: false,
  name: 'github',
  defaultIcon: undefined,
  activeIcon: undefined,
  textStyle: {},
  iconStyle: {},
  paddingLess: false,
  showCount: true,
  trackOnChange: () => {},
};

SocialActionButton.propTypes = {
  vertical: PropTypes.bool,
  social: PropTypes.array,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  defaultIcon: PropTypes.string,
  activeIcon: PropTypes.string,
  textStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  paddingLess: PropTypes.bool,
  showCount: PropTypes.bool,
  trackOnChange: PropTypes.func,
};

export default withRouter(SocialActionButton);
