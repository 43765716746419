import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { first } from 'lodash';
import client from '../../../utils/apolloClient';

import { Text } from '../../../components/elements';
import Loading from '../../../components/global/Loading';
import DeskItem from './DeskItem';

const Container = styled.div`
  padding: 1rem;
`;

const PageHeader = styled.div`
  background: #ffffff;
  padding: 0rem 1rem;
`;

const socialQuery = gql`
  query social($input: SearchInput) {
    search_my_social(input: $input) {
      social_listing_aggregation {
        total_pages
        total_size
        social_listing {
          social_id
          object_id
          object_type
          type
        }
      }
      item_listing {
        item_id
        name
        address_line_1
        city
        state
        country
        post_code
      }

      image_listing {
        image_id
        object_id
        url
      }
    }
  }
`;

const DesktopView = () => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Container>
      <PageHeader>
        <Text size="large" weight="semibold" paddingBottom={0.5}>
          My Saved Items
        </Text>
      </PageHeader>
      <Query
        client={client.clientPrivate}
        query={socialQuery}
        variables={{
          input: {
            filter: {
              social_filter: { user_id: userId, object_type: 'ITEM', type: 'BOOKMARK' },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }
          const {
            search_my_social: {
              item_listing,
              image_listing,
              social_listing_aggregation: { social_listing },
            },
          } = data;
          if (social_listing.length === 0) {
            return (
              <Container>
                <Text size="medium" color="darkGrey">
                  No saved items yet!
                </Text>
                <Text size="tiny" color="coolGrey">
                  Add Items to your Bookmarks for easy reference.
                </Text>
              </Container>
            );
          }
          return (
            <Container>
              <div className="columns is-multiline">
                {social_listing.map(item => (
                  <div className="column is-6" key={item.social_id}>
                    <DeskItem
                      item={first(item_listing.filter(item2 => item2.item_id === item.object_id))}
                      image={first(
                        image_listing.filter(image => image.object_id === item.object_id),
                      )}
                    />
                  </div>
                ))}
              </div>
            </Container>
          );
        }}
      </Query>
    </Container>
  );
};

export default DesktopView;
