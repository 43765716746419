import React, { useState } from 'react';
import styled from 'styled-components';
import { times, first } from 'lodash';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';

import { Icon, ShowMoreText } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';
import ReviewImageView from './ReviewImageView';
import ReviewCommentList from './ReviewCommentList';
import UserArticle from '../../../../components/UserArticle';
import FollowButtonAction from '../../../../components/FollowButtonAction';
import ReviewSocial from './ReviewSocial';
import { parseSocialPlace } from '../../../../utils/helpers';

const Container = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${colors.lightGreyBeta};
`;
const IconWrapper = styled.span`
  padding: 0.2rem;
`;

const IconActionButtonWrapper = styled.div`
  padding-top: 0rem;
`;
const ButtonWrapper = styled.div`
  padding-top: 0.5rem;
`;

const IconStyle = styled(Icon).attrs({ size: 2 })``;

const ReviewItem = ({ review, socials }) => {
  const { userId } = useStoreState(state => state.auth);
  const socialData = socials.filter(item => item.object_id === review.item_review_id);
  const socialArray = socialData.length !== 0 ? first(socialData).total_social_count : [];
  const social = parseSocialPlace(socialArray, userId);
  const [totalComments, setTotalComments] = useState(0);
  const [totalLike, setTotalLike] = useState(social.like.count);

  return (
    <Container>
      <UserArticle
        user={review.user_details}
        subHeading={`${moment(review.audit.created_at).fromNow()}`}
        maskProps={{ width: 50 }}
        maskTextProps={{ color: 'white', weight: 'bold', size: 'medium', letterSpacing: 'loose' }}
        headingLeftElement={
          <ButtonWrapper>
            <FollowButtonAction
              objectId={review.audit.created_by}
              objectType="USER"
              textProps={{ size: 'micro', weight: 'semibold' }}
              buttonProps={{ width: 0.4 }}
            />
          </ButtonWrapper>
        }
        headingRightElement={
          <div className="is-flex">
            {times(review.rating).map(() => (
              <IconWrapper>
                <IconStyle name="itemRatingGoldSolid" />
              </IconWrapper>
            ))}
            {times(5 - review.rating).map(() => (
              <IconWrapper>
                <IconStyle name="itemRatingSolidGrey" />
              </IconWrapper>
            ))}
          </div>
        }
        variables={{
          object_type: ['USER_IMAGE'],
          object_id: review.audit.created_by,
        }}
      >
        <ShowMoreText content={review.content} limit={500} size="smaller" />
        <br />
        <ReviewImageView placeId={review.place_id} itemReviewId={review.item_review_id} />
        <div className="columns">
          <div className="column">
            <IconActionButtonWrapper>
              <ReviewSocial
                totalLike={totalLike}
                totalComments={totalComments}
                likeUsers={social.like.likeUsers}
                review={review}
                objectId={review.item_review_id}
                objectType="ITEM_REVIEW"
                likeActive={social.like.active}
                likeCountInt={social.like.count}
                likeSocialIdS={social.like.id}
                setTotalLike={setTotalLike}
              />
            </IconActionButtonWrapper>
          </div>
        </div>
        <ReviewCommentList
          placeId={review.place_id}
          itemId={review.item_id}
          itemReviewId={review.item_review_id}
          totalComments={totalComments}
          setTotalComments={setTotalComments}
        />
      </UserArticle>
    </Container>
  );
};

export default ReviewItem;
