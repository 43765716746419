import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { forEach, omit, isNull, groupBy, map } from 'lodash';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import uuid from 'uuid';
import { toast } from 'react-toastify';
import randomColor from 'randomcolor';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';
import client from '../../utils/apolloClient';

const createPlaceMutation = gql`
  mutation create_place($input: PlaceInput) {
    create_place(input: $input) {
      place_id
      name
      error {
        description
      }
    }
  }
`;

const createImageMutation = gql`
  mutation createImage($input: ImageInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
  }
`;

const parseImage = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        image_id: item.id,
        url: item.url,
        link: item.link,
        tags: item.tags,
        tooltip: item.tooltip,
        width: item.width,
        height: item.height,
      });
    }
  });
  return imageValues;
};

const normalizeBusinessHours = values => {
  const normalize = map(groupBy(values, 'day'));
  const final = normalize.map(item => {
    return {
      day: item[0].day,
      is_active: true,
      option: item.map(item2 => ({
        type: '',
        start: item2.start,
        end: item2.end,
        start_in_sec: item2.end_in_sec,
        end_in_sec: item2.end_in_sec,
      })),
    };
  });
  return final;
};

const AddNewPlace = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const [images, setImages] = useState();

  return (
    <Layout
      history={history}
      match={match}
      bottomNavigationEnable={false}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading="ADD NEW PLACE"
    >
      <Mutation
        client={client.clientPrivate}
        mutation={createImageMutation}
        onCompleted={() => {
          swal(
            'Thank You!',
            // eslint-disable-next-line max-len
            'Your Place Listing has been successfully recorded. We will validate the listing and get in touch if required',
            'success',
          ).then(() => {
            history.push('/places');
          });
        }}
      >
        {(create_image, { loading: imageLoading, error: imageError }) => (
          <Mutation
            client={client.clientPrivate}
            mutation={createPlaceMutation}
            onCompleted={({ create_place }) => {
              if (!isNull(create_place.error)) {
                create_place.error.map(item => toast.error(item.description));
              } else if (images.length !== 1) {
                create_image({
                  variables: {
                    input: {
                      user_id: userId,
                      object_id: create_place.place_id,
                      object_type: 'PLACE',
                      parent_id: create_place.place_id,
                      upload_type: 'USER',
                      parent_type: 'PLACE',
                      images: parseImage(images),
                    },
                  },
                });
              } else {
                swal(
                  'Thank You!',
                  // eslint-disable-next-line max-len
                  'Your Place Listing has been successfully recorded. We will validate the listing and get in touch if required',
                  'success',
                ).then(() => {
                  history.push('/places');
                });
              }
            }}
          >
            {(create_place, { loading, error }) => (
              <>
                <Analytics pageName="add_place" category="place" title="Add Place Page">
                  <div className="is-hidden-desktop">
                    <MobileView
                      loading={loading || imageLoading}
                      error={error || imageError}
                      onSubmit={async values => {
                        await setImages(values.image);
                        const primaryContacts = [
                          {
                            type: 'email_primary',
                            value: values.email,
                            display: false,
                            is_primary: true,
                          },

                          {
                            type: 'phone_primary',
                            value: values.mobile,
                            display: true,
                            is_primary: true,
                          },
                          {
                            type: 'website',
                            value: values.website,
                            display: true,
                            is_primary: true,
                          },
                        ];

                        const socialContact = [
                          {
                            type: 'facebook',
                            logo: '',
                            description: '',
                            value: values.facebook,
                            display: true,
                          },
                        ];

                        const businessHour = normalizeBusinessHours(values.business_hour);

                        const finalInput = {
                          user_id: userId,
                          place_id: uuid(),
                          listing_status: 'UNLISTED',
                          claim_status: 'UNCLAIMED',
                          listing_role: values.relationship,
                          // approval_status: 'SUBMITTED',
                          business_type: values.business_types,
                          service_category: values.service_category,
                          cuisine_style: values.cuisine_style,
                          good_for: values.cuisine_style,
                          feature: values.feature_types,
                          name: values.name,
                          description: values.whatsWrong,
                          social: socialContact,
                          address_line_1: values.streetAddressLine1,
                          address_line_2: values.additionalInfo,
                          city: values.city,
                          state: values.state,
                          country: values.country,
                          post_code: values.postcode,
                          latitude: values.latitude,
                          longitude: values.longitude,
                          timezone: values.timezone,
                          contact: primaryContacts,
                          business_hour: businessHour,
                          event_business: false,
                          color_code: randomColor({
                            luminosity: 'dark',
                          }),
                        };

                        create_place({ variables: { input: finalInput } });
                      }}
                    />
                  </div>
                  <div className="is-hidden-touch">
                    <DesktopView
                      loading={loading || imageLoading}
                      error={error || imageError}
                      onSubmit={async values => {
                        await setImages(values.image);
                        const primaryContacts = [
                          {
                            type: 'email_primary',
                            value: values.email,
                            display: false,
                            is_primary: true,
                          },

                          {
                            type: 'phone_primary',
                            value: values.mobile,
                            display: true,
                            is_primary: true,
                          },
                          {
                            type: 'website',
                            value: values.website,
                            display: true,
                            is_primary: true,
                          },
                        ];

                        const socialContact = [
                          {
                            type: 'facebook',
                            logo: '',
                            description: '',
                            value: values.facebook,
                            display: true,
                          },
                        ];

                        const businessHour = values.business_hour.map(item => {
                          const customItem = omit(item, ['open24Hours', 'closed']);
                          return customItem;
                        });

                        const finalInput = {
                          user_id: userId,
                          place_id: uuid(),
                          listing_status: 'UNLISTED',
                          claim_status: 'UNCLAIMED',
                          listing_role: values.relationship,
                          // approval_status: 'SUBMITTED',
                          business_type: values.business_types,
                          service_category: values.service_category,
                          cuisine_style: values.cuisine_style,
                          good_for: values.cuisine_style,
                          feature: values.feature_types,
                          name: values.name,
                          description: values.whatsWrong,
                          social: socialContact,
                          address_line_1: values.streetAddressLine1,
                          address_line_2: values.additionalInfo,
                          city: values.city,
                          state: values.state,
                          country: values.country,
                          post_code: values.postcode,
                          latitude: values.latitude,
                          longitude: values.longitude,
                          timezone: values.timezone,
                          contact: primaryContacts,
                          business_hour: businessHour,
                          event_business: false,
                          color_code: randomColor({
                            luminosity: 'dark',
                          }),
                        };

                        create_place({ variables: { input: finalInput } });
                      }}
                    />
                  </div>
                </Analytics>
              </>
            )}
          </Mutation>
        )}
      </Mutation>
    </Layout>
  );
};

export default AddNewPlace;
