import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
// import moment from 'moment';
import { isNull } from 'lodash';
import { Text } from '../../../../components/elements';
import PlaceReviewActionButton from './PlaceReviewActionButton';
import ReviewItem from './ReviewItem';

const searchPlaceReviewQuery = gql`
  query searchPlaceReview($input: SearchInput) {
    search_place_review(input: $input) {
      place_review_listing {
        place_id
        place_review_id
        month_visited
        overall_rating
        service_category {
          name
          description
        }
        content
        audit {
          created_at
          created_by
        }
        user_details {
          user_id
          display_name
          profile_image_url
          color_code
        }
      }
    }
    search_social(input: { filter: { social_filter: { object_type: "PLACE_REVIEW" } } }) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 0rem 0rem;
`;
const Wrapper = styled.div`
  padding-right: 20%;
`;

const Header = styled.div`
  padding: 1rem 0rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  margin-bottom: 0rem;
`;

const Reviews = ({ place, handleElement }) => {
  return (
    <Container>
      <Header>
        <Text size="large" weight="semibold">
          Reviews
        </Text>
      </Header>
      <PlaceReviewActionButton placeId={place.place_id} placeName={place.name} />
      <Query
        query={searchPlaceReviewQuery}
        variables={{
          input: {
            sort: 'CREATED_DESC',
            filter: {
              place_review_filter: {
                place_id: place.place_id,
                status: 'APPROVED',
              },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading }) => {
          if (loading) {
            return '';
          }
          if (!data) {
            return '';
          }
          if (isNull(data.search_place_review)) {
            return '';
          }

          const reviews = data.search_place_review.place_review_listing;
          const socials = data.search_social.social_aggregation.total_social_per_object_id;
          return (
            <View reviews={reviews} place={place} socials={socials} handleElement={handleElement} />
          );
        }}
      </Query>
    </Container>
  );
};

const View = ({ reviews, socials, handleElement }) => {
  useEffect(() => {
    if (reviews.length === 0) {
      handleElement('reviewElements');
    }
  }, [handleElement, reviews.length]);

  if (reviews.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {reviews.map(review => (
        <ReviewItem
          review={review}
          key={review.place_review_id}
          socials={socials.filter(item => item.object_id === review.place_review_id)}
        />
      ))}
    </Wrapper>
  );
};

export default Reviews;
