import React, { useState } from 'react';
import styled from 'styled-components';

import { FaArrowUp } from 'react-icons/fa';
import { animateScroll } from 'react-scroll';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const Button = styled.button`
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 40px;
  background-color: #ffc841;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  border: 0px;
  z-index: 1500;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

const ScrollToTopButton = () => {
  const [isActive, setIsActive] = useState(false);

  useScrollPosition(({ currPos }) => {
    if (currPos.y <= -150) {
      setIsActive(true);
    }
    if (currPos.y >= -150) {
      setIsActive(false);
    }
  }, []);

  if (!isActive) {
    return null;
  }
  return (
    <Button onClick={() => animateScroll.scrollToTop()}>
      <FaArrowUp size={25} />
    </Button>
  );
};

export default ScrollToTopButton;
