import './setupTests';
import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'isomorphic-fetch';
import { render } from 'react-snapshot';
import * as Sentry from '@sentry/browser';

import App from './App';

if (process.env.REACT_APP_ENV === 'DEV1') {
  Sentry.init({ dsn: 'https://52f06fdbeabb49d185dc0eca4be23b77@o397028.ingest.sentry.io/5251182' });
}

toast.configure({
  position: 'bottom-right',
  enableMultiContainer: false,
});

render(<App />, document.getElementById('root'));
