import React from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { IoMdCloseCircle } from 'react-icons/io';
import { getEncodedImage } from '../../utils/s3';

const ImageContainer = styled.div``;

const Figure = styled.figure`
  &&& {
    opacity: ${opacity => opacity};
    img {
      border-radius: 6px;
    }
  }
`;

const UploadedImages = ({ colWidth, setFieldValue }) => (
  <FieldArray
    name="image"
    render={arrayHelpers => (
      <>
        {arrayHelpers.form.values &&
          arrayHelpers.form.values.uploadedImages.map(item => (
            <div className={`column is-${colWidth || 6}`}>
              <ImageContainer>
                <Figure className="image is-square" opacity={1}>
                  <img
                    src={getEncodedImage(item.url, 145, 145)}
                    alt="image-upload"
                    style={{ borderRadius: '6px' }}
                  />

                  <div
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                    }}
                  >
                    <a
                      onClick={() => {
                        const existedImage = arrayHelpers.form.values.uploadedImages.filter(
                          item2 => item2.image_id !== item.image_id,
                        );
                        setFieldValue('uploadedImages', existedImage);
                        console.log('item', item);
                      }}
                    >
                      <span>
                        <IoMdCloseCircle color="#ffffff" size={25} />
                      </span>
                    </a>
                  </div>
                </Figure>
              </ImageContainer>
            </div>
          ))}
      </>
    )}
  />
);

export default UploadedImages;
