import React, { useEffect, useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { animateScroll } from 'react-scroll';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Layout from '../../components/global/MenuLayout';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import LoadingOverlay from '../../components/loader/LoadingOverlay';
import Loading from '../../components/global/mobile/MobileLoading';
import client from '../../utils/apolloClient';
import config from '../../utils/config';

const itemsQuery = gql`
  query itemsQuery($input: SearchInput) {
    search_items(input: $input) {
      total_pages
      total_size
      item_listing {
        item_id
        place_id
        item_dictionary_id
        place_name
        status
        approval_status
        listing_approved
        item_review_place
        default_url
        display_order
        name
        slug
        star_count
        love_count
        tried_count
        share_count
        loved_percentage
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        default_image_url
      }
      item_aggregation {
        aggregation_name
        aggregation_listing {
          name
          description
          count
        }
      }
    }
  }
`;

const searchSocialQuery = gql`
  query search_social($input: SearchInput) {
    search_social(input: $input) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const View = ({
  data,
  loading,
  filterStateInputs,
  filterFuncInputs,
  currentLocation,
  handlePageClick,
  handleMobileFilter,
  fetchMoreInMobile,
}) => {
  const updateFilterTags = useStoreActions(state => state.item.updateFilterTags);
  const [socials, setSocials] = useState([]);

  useEffect(() => {
    updateFilterTags(data.search_items.item_aggregation);
    const itemIds = data.search_items.item_listing.map(item => item.item_id);
    const social_filter = { object_type: 'ITEM', object_id: itemIds };
    // Object.assign(social_filter, isLoggedIn && { user_id: userId });
    client.clientPublic
      .query({
        query: searchSocialQuery,
        variables: {
          input: { filter: { social_filter } },
        },
      })
      .then(({ data: data2 }) => {
        if (data2) {
          setSocials(data2.search_social.social_aggregation.total_social_per_object_id);
        }
      });
  }, [data.search_items, updateFilterTags]);

  return (
    <>
      <div className="is-hidden-desktop">
        <MobileView
          data={data}
          socials={socials}
          loading={loading}
          filterStateInputs={filterStateInputs}
          filterFuncInputs={filterFuncInputs}
          currentLocation={currentLocation}
          handlePageClick={handlePageClick}
          handleMobileFilter={handleMobileFilter}
          fetchMoreInMobile={fetchMoreInMobile}
        />
      </div>
      <div className="is-hidden-touch">
        {loading && <LoadingOverlay isActive text="" content="" />}
        <DesktopView
          data={data}
          socials={socials}
          loading={loading}
          filterStateInputs={filterStateInputs}
          filterFuncInputs={filterFuncInputs}
          currentLocation={currentLocation}
          handlePageClick={handlePageClick}
        />
      </div>
    </>
  );
};

const totalItemSize = 10;

const Items = ({ history, match }) => {
  const currentLocation = useStoreState(state => state.global.usedLocation);
  // const fetchEventFilters = useStoreActions(state => state.item.fetchFilterTags);
  const filterInputs = useStoreState(state => state.item.filterInputs);
  const updateFilterInputs = useStoreActions(state => state.item.updateFilterInputs);

  const [cuisineInputs, setCuisineInputs] = useState(filterInputs.cuisines);
  const [itemTypeInputs, setItemTypeInputs] = useState(filterInputs.itemTypes);
  const [cursor, setCursor] = useState(0);

  const setToMainFilter = useCallback(() => {
    setCursor(0);
    updateFilterInputs({
      cuisines: cuisineInputs,
      itemTypes: itemTypeInputs,
    });
  }, [updateFilterInputs, cuisineInputs, itemTypeInputs]);

  useEffect(() => {
    setToMainFilter();
    animateScroll.scrollToTop();
  }, [setToMainFilter]);

  const addCuisineInputs = async cuisine => {
    const newCuisines = await cuisineInputs.concat([cuisine]);
    setCuisineInputs(newCuisines);
  };

  const removeCuisineInputs = name => {
    const newCuisines = cuisineInputs.filter(item => item.name !== name);
    setCuisineInputs(newCuisines);
  };

  const addItemTypeInputs = async itemType => {
    const newItemTypes = await itemTypeInputs.concat([itemType]);
    setItemTypeInputs(newItemTypes);
  };

  const removeItemTypeInputs = name => {
    const newItemTypes = itemTypeInputs.filter(item => item.name !== name);
    setItemTypeInputs(newItemTypes);
  };

  const clearFilters = () => {
    setCuisineInputs([]);
    setItemTypeInputs([]);
  };

  const handleMobileFilter = mobileFilter => {
    setCuisineInputs(mobileFilter.cuisineStyleInputs);
    setItemTypeInputs(mobileFilter.itemTypeInputs);
  };

  const onHandleClearItem = value => {
    switch (value) {
      case 'cuisines':
        setCuisineInputs([]);
        break;
      case 'itemType':
        setItemTypeInputs([]);
        break;
      default:
    }
  };

  const filterStateInputs = {
    cuisineInputs,
    itemTypeInputs,
  };

  const filterFuncInputs = {
    addCuisineInputs,
    removeCuisineInputs,
    addItemTypeInputs,
    removeItemTypeInputs,
    clearFilters,
    onHandleClearItem,
  };

  const cuisineInputsArray = cuisineInputs.map(item => item.name);
  const itemTypeInputsArray = itemTypeInputs.map(item => item.name);

  const itemFilters = {};

  Object.assign(
    itemFilters,
    cuisineInputsArray.length > 0 && { cuisine_style: cuisineInputsArray },
    itemTypeInputsArray.length > 0 && { item_type: itemTypeInputsArray },
    {
      status: 'ACTIVE',
      approval_status: 'APPROVED',
      distance: config.distanceRadius,
    },
  );

  return (
    <Layout searchInputEnable service="items" history={history} match={match}>
      <Query
        query={itemsQuery}
        variables={{
          input: {
            size: totalItemSize,
            from: cursor,
            location: `${currentLocation.latitude},${currentLocation.longitude}`,
            filter: {
              item_filter: itemFilters,
              place_filter: {
                status: 'ACTIVE',
              },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (error) {
            return 'error';
          }
          if (!data.search_items) {
            return <Loading />;
          }
          return (
            <View
              data={data}
              loading={loading}
              filterStateInputs={filterStateInputs}
              filterFuncInputs={filterFuncInputs}
              currentLocation={currentLocation}
              handlePageClick={value => {
                setCursor(value.selected * totalItemSize);
              }}
              fetchMoreInMobile={() => {
                setCursor(cursor + totalItemSize);
              }}
              handleMobileFilter={handleMobileFilter}
            />
          );
        }}
      </Query>
    </Layout>
  );
};

export default Items;
