import React from 'react';
import styled from 'styled-components';
import { FlexRows, Text } from '../../../../components/elements';

const Container = styled.div`
  position: sticky;
  padding: 0rem;
  width: 100%;
  z-index: 100;
  top: 5.5rem;
  padding: 0rem 0.5rem;
  background: white;
`;

const PageHeaderTabs = styled(FlexRows).attrs({ justify: 'space-between' })``;
const Item = styled.a`
  padding: 0.2rem 0rem;
  border-bottom: 4px solid
    ${({ active, theme: { colors } }) => (active ? colors.primaryColor : 'transparent')};
`;

const InsightsContentHeader = ({ tab, setTab }) => (
  <Container>
    <PageHeaderTabs>
      <Item active={tab === 'feeds'} onClick={() => setTab('feeds')}>
        <Text className="is-uppercase" weight="semibold" size="smaller" color="primaryColor">
          Latest Feed
        </Text>
      </Item>
      <Item active={tab === 'items'} onClick={() => setTab('items')}>
        <Text className="is-uppercase" weight="semibold" size="smaller" color="primaryColor">
          Trending Items
        </Text>
      </Item>
      <Item active={tab === 'reviews'} onClick={() => setTab('reviews')}>
        <Text className="is-uppercase" weight="semibold" size="smaller" color="primaryColor">
          Reviews
        </Text>
      </Item>
    </PageHeaderTabs>
  </Container>
);

export default InsightsContentHeader;
