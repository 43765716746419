import React from 'react';
import styled from 'styled-components';
import { FaRegCircle } from 'react-icons/fa';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { Text, Button, FlexRows } from '../../components/elements';

const Container = styled.div``;
const Item = styled.div``;
const DeskIdentityForm = ({ values, loading, handleSubmit, setFieldValue }) => (
  <Container className="columns">
    <div className="column is-10">
      <Item>
        <div className="is-flex">
          <a onClick={() => setFieldValue('identity', 'facebook')}>
            {values.identity === 'facebook' ? (
              <Text color="primaryColor">
                <IoIosCheckmarkCircle size={32} />
              </Text>
            ) : (
              <Text color="specialGrey">
                <FaRegCircle size={30} />
              </Text>
            )}
          </a>
          &nbsp; &nbsp;
          <div>
            <Text size="large">Facebook</Text>
            <Text size="tiny" color="specialGrey">
              ( recommended )
            </Text>
          </div>
        </div>
        <Text color="darkGrey" size="small">
          Choosing Facebook will be your quickest option to confirm your identity, We will only use
          this information for verification purpose only
        </Text>
      </Item>
      <hr />
      <Item>
        <div className="is-flex">
          <a onClick={() => setFieldValue('identity', 'phone')}>
            {values.identity === 'phone' ? (
              <Text color="primaryColor">
                <IoIosCheckmarkCircle size={32} />
              </Text>
            ) : (
              <Text color="specialGrey">
                <FaRegCircle size={30} />
              </Text>
            )}
          </a>
          &nbsp; &nbsp;
          <div>
            <Text size="large">Phone</Text>
          </div>
        </div>
        <Text color="darkGrey" size="small">
          You will be contacted on the number listed for this business shortly for verification.
        </Text>
      </Item>
      <FlexRows justify="flex-end">
        <Button
          className={loading && 'is-loading'}
          name="Submit"
          width={3}
          height={0.5}
          backgroundColor="primaryColor"
          textProps={{ color: loading ? 'primaryColor' : 'white', weight: 'semibold' }}
          onClick={() => handleSubmit()}
        />
      </FlexRows>
    </div>
  </Container>
);

export default DeskIdentityForm;
