import React, { useEffect, useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { animateScroll } from 'react-scroll';
import { Query } from 'react-apollo';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { first } from 'lodash';

import client from '../../utils/apolloClient';
import Layout from '../../components/global/MenuLayout';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import LoadingOverlay from '../../components/loader/LoadingOverlay';
import Loading from '../../components/global/mobile/MobileLoading';
import config from '../../utils/config';

const eventsQuery = gql`
  query eventQuery($input: SearchInput) {
    search_events(input: $input) {
      total_pages
      total_size
      event_listing {
        place_id
        event_id
        status
        display_order
        listing_type
        featured
        default_url
        start
        end
        place_name
        venue_name
        name
        description
        tagline
        slug
        voucher_count
        minimum_price
        maximum_price
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        event_type {
          description
        }
        event_category {
          description
        }
        default_image_url
      }
      event_aggregation {
        aggregation_name
        aggregation_listing {
          name
          description
          count
        }
      }
    }
  }
`;

const searchSocialQuery = gql`
  query search_social($input: SearchInput) {
    search_social(input: $input) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const View = ({
  data,
  loading,
  filterStateInputs,
  filterFuncInputs,
  currentLocation,
  handlePageClick,
  handleMobileFilter,
  fetchMoreInMobile,
}) => {
  const updateFilterTags = useStoreActions(state => state.event.updateFilterTags);
  const { isLoggedIn, userId } = useStoreState(state => state.auth);
  const [socials, setSocials] = useState([]);

  useEffect(() => {
    updateFilterTags(data.search_events.event_aggregation);
    const eventIds = data.search_events.event_listing.map(item => item.event_id);
    const social_filter = { object_type: 'EVENT', object_id: eventIds };
    // Object.assign(social_filter, isLoggedIn && { user_id: userId });
    client.clientPublic
      .query({
        query: searchSocialQuery,
        variables: {
          input: { filter: { social_filter } },
        },
      })
      .then(({ data: data2 }) => {
        if (data2) {
          setSocials(data2.search_social.social_aggregation.total_social_per_object_id);
        }
      });
  }, [
    data.search_events.event_aggregation,
    data.search_events,
    updateFilterTags,
    isLoggedIn,
    userId,
  ]);

  return (
    <>
      <div className="is-hidden-desktop">
        <MobileView
          data={data}
          socials={socials}
          loading={loading}
          filterStateInputs={filterStateInputs}
          filterFuncInputs={filterFuncInputs}
          currentLocation={currentLocation}
          handlePageClick={handlePageClick}
          handleMobileFilter={handleMobileFilter}
          fetchMoreInMobile={fetchMoreInMobile}
        />
      </div>
      <div className="is-hidden-touch">
        {loading && <LoadingOverlay isActive text="" content="" />}
        <DesktopView
          data={data}
          socials={socials}
          loading={loading}
          filterStateInputs={filterStateInputs}
          filterFuncInputs={filterFuncInputs}
          currentLocation={currentLocation}
          handlePageClick={handlePageClick}
        />
      </div>
    </>
  );
};

const totalEventSize = 10;

const Events = ({ history, match }) => {
  const currentLocation = useStoreState(state => state.global.usedLocation);
  const allEventDateFilter = useStoreState(state => state.global.allEventDateFilter);
  const eventCurrentDateFilter = useStoreState(state => state.global.eventCurrentDateFilter);
  const setEventCurrentDateFilter = useStoreActions(
    state => state.global.setEventCurrentDateFilter,
  );

  // const fetchEventFilters = useStoreActions(state => state.item.fetchFilterTags);
  const filterInputs = useStoreState(state => state.event.filterInputs);
  const updateFilterInputs = useStoreActions(state => state.event.updateFilterInputs);
  const [listingTypeInputs, setListingTypeInputs] = useState(filterInputs.listingType);
  const [eventTypeInputs, setEventTypeInputs] = useState(filterInputs.eventType);
  const [ticketTypeInputs, setTicketTypeInputs] = useState(filterInputs.eventType);
  const [eventCategoryInputs, setEventCategoryInputs] = useState(filterInputs.eventCategory);
  const [featureInputs, setFeatureInputs] = useState(filterInputs.feature);
  const [ageRestrictionInputs, setAgeRestrictionInputs] = useState(filterInputs.ageRestriction);
  const [dressCodeInputs, setDressCodeInputs] = useState(filterInputs.dressCode);
  const [cursor, setCursor] = useState(0);

  const setToMainFilter = useCallback(() => {
    setCursor(0);
    updateFilterInputs({
      ticketType: ticketTypeInputs,
      listingType: listingTypeInputs,
      eventType: eventTypeInputs,
      eventCategory: eventCategoryInputs,
      feature: featureInputs,
      ageRestriction: ageRestrictionInputs,
      dressCode: dressCodeInputs,
    });
  }, [
    ageRestrictionInputs,
    dressCodeInputs,
    eventCategoryInputs,
    eventTypeInputs,
    featureInputs,
    listingTypeInputs,
    ticketTypeInputs,
    updateFilterInputs,
  ]);

  useEffect(() => {
    setToMainFilter();
    animateScroll.scrollToTop();
  }, [setToMainFilter]);

  const addTicketTypeInputs = async ticketTypeValue => {
    const newTicketTypeInputs = await ticketTypeInputs.concat([ticketTypeValue]);
    setTicketTypeInputs(newTicketTypeInputs);
  };

  const removeTicketTypeInputs = name => {
    const newTicketTypeInputs = ticketTypeInputs.filter(item => item.name !== name);
    setTicketTypeInputs(newTicketTypeInputs);
  };

  const addListingTypeInputs = async listingTypeValue => {
    const newListingTypeInputs = await listingTypeInputs.concat([listingTypeValue]);
    setListingTypeInputs(newListingTypeInputs);
  };

  const removeListingTypeInputs = name => {
    const newListingTypeInputs = eventTypeInputs.filter(item => item.name !== name);
    setListingTypeInputs(newListingTypeInputs);
  };

  const addEventTypeInputs = async eventTypeValue => {
    const newEventTypeInputs = await eventTypeInputs.concat([eventTypeValue]);
    setEventTypeInputs(newEventTypeInputs);
  };

  const removeEventTypeInputs = name => {
    const newEventTypeInputs = eventTypeInputs.filter(item => item.name !== name);
    setEventTypeInputs(newEventTypeInputs);
  };

  const addEventCategory = async eventCategoryValue => {
    const newEventCategoryInputs = await eventCategoryInputs.concat([eventCategoryValue]);
    setEventCategoryInputs(newEventCategoryInputs);
  };

  const removeEventCategory = name => {
    const newEventCategoryInputs = eventCategoryInputs.filter(item => item.name !== name);
    setEventCategoryInputs(newEventCategoryInputs);
  };

  const addFeatures = async featureValue => {
    const newFeatureInputs = await featureInputs.concat([featureValue]);
    setFeatureInputs(newFeatureInputs);
  };

  const removeFeatures = name => {
    const newFeatureInputs = featureInputs.filter(item => item.name !== name);
    setFeatureInputs(newFeatureInputs);
  };

  const addAgeRestriction = async ageRestrictionValue => {
    const newAgeRestrictionInputs = await ageRestrictionInputs.concat([ageRestrictionValue]);
    setAgeRestrictionInputs(newAgeRestrictionInputs);
  };

  const removeAgeRestriction = name => {
    const newAgeRestrictionInputs = ageRestrictionInputs.filter(item => item.name !== name);
    setAgeRestrictionInputs(newAgeRestrictionInputs);
  };

  const addDressCode = async dressCodeValue => {
    const newDressCodeInputs = await dressCodeInputs.concat([dressCodeValue]);
    setDressCodeInputs(newDressCodeInputs);
  };

  const removeDressCode = name => {
    const newDressCodeInputs = dressCodeInputs.filter(item => item.name !== name);
    setDressCodeInputs(newDressCodeInputs);
  };

  const clearFilters = () => {
    setListingTypeInputs([]);
    setEventTypeInputs([]);
    setEventCategoryInputs([]);
    setFeatureInputs([]);
    setAgeRestrictionInputs([]);
    setDressCodeInputs([]);
  };

  const handleMobileFilter = mobileFilter => {
    setEventTypeInputs(mobileFilter.eventTypeInputs);
    setEventCategoryInputs(mobileFilter.eventCategoryInputs);
    setFeatureInputs(mobileFilter.featuresInput);
    setAgeRestrictionInputs(mobileFilter.ageRestrictionInputs);
    setDressCodeInputs(mobileFilter.dressCodeInputs);
  };

  const handleTabDateFilter = key => {
    const current = first(allEventDateFilter.filter(item => item.name === key));
    setEventCurrentDateFilter(current);
    setCursor(0);
  };

  const onHandleClearItem = value => {
    switch (value) {
      case 'listingType':
        setListingTypeInputs([]);
        setTicketTypeInputs([]);
        break;
      case 'eventType':
        setEventTypeInputs([]);
        break;
      case 'eventCategory':
        setEventCategoryInputs([]);
        break;
      case 'feature':
        setFeatureInputs([]);
        break;
      case 'ageRestriction':
        setAgeRestrictionInputs([]);
        break;
      case 'dressCode':
        setDressCodeInputs([]);
        break;
      default:
    }
  };

  const filterStateInputs = {
    ticketTypeInputs,
    listingTypeInputs,
    eventTypeInputs,
    eventCategoryInputs,
    featureInputs,
    ageRestrictionInputs,
    dressCodeInputs,
  };

  const filterFuncInputs = {
    addTicketTypeInputs,
    removeTicketTypeInputs,
    addListingTypeInputs,
    removeListingTypeInputs,
    addEventTypeInputs,
    removeEventTypeInputs,
    addEventCategory,
    removeEventCategory,
    addFeatures,
    removeFeatures,
    addAgeRestriction,
    removeAgeRestriction,
    addDressCode,
    removeDressCode,
    clearFilters,
    onHandleClearItem,
    handleTabDateFilter,
  };

  const ticketTypeArray = ticketTypeInputs.map(item => item.name);
  const listingTypeArray = listingTypeInputs.map(item => item.name);
  const eventTypeInputsArray = eventTypeInputs.map(item => item.name);
  const eventCategoryInputsArray = eventCategoryInputs.map(item => item.name);
  const featureInputsArray = featureInputs.map(item => item.name);
  const ageRestrictionInputsArray = ageRestrictionInputs.map(item => item.name);
  const dressCodeInputsArray = dressCodeInputs.map(item => item.name);

  const eventFilter = {};

  Object.assign(
    eventFilter,
    ticketTypeArray.length > 0 && { ticket_type: ticketTypeArray },
    listingTypeArray.length > 0 && { listing_type: listingTypeArray },
    eventTypeInputsArray.length > 0 && { event_type: eventTypeInputsArray },
    eventCategoryInputsArray.length > 0 && { event_category: eventCategoryInputsArray },
    featureInputsArray.length > 0 && { feature: featureInputsArray },
    ageRestrictionInputsArray.length > 0 && { restriction: ageRestrictionInputsArray },
    dressCodeInputsArray.length > 0 && { dress_code: dressCodeInputsArray },
    { status: 'ACTIVE', draft: false, distance: config.distanceRadius },
    eventCurrentDateFilter &&
      eventCurrentDateFilter.range && {
        start_date_range: eventCurrentDateFilter.range.start_date_range,
      },
    eventCurrentDateFilter &&
      eventCurrentDateFilter.range && {
        end_date_range: eventCurrentDateFilter.range.end_date_range,
      },
  );

  return (
    <Layout searchInputEnable service="events" history={history} match={match}>
      <Query
        query={eventsQuery}
        variables={{
          input: {
            size: totalEventSize,
            from: cursor,
            location: `${currentLocation.latitude},${currentLocation.longitude}`,
            filter: {
              place_filter: {
                status: 'ACTIVE',
              },
              event_filter: eventFilter,
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (error) {
            return 'error';
          }
          if (!data.search_events) {
            return <Loading />;
          }

          console.log('data', data);
          return (
            <View
              data={data}
              loading={loading}
              filterStateInputs={filterStateInputs}
              filterFuncInputs={filterFuncInputs}
              currentLocation={currentLocation}
              handlePageClick={value => setCursor(value.selected * totalEventSize)}
              fetchMoreInMobile={() => {
                setCursor(cursor + totalEventSize);
              }}
              handleMobileFilter={handleMobileFilter}
            />
          );
        }}
      </Query>
    </Layout>
  );
};

export default Events;
