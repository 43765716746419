import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';

import {
  Flex,
  Text,
  Field,
  MobileInput,
  CheckBox,
  FlexRows,
  InputErrorMessage,
} from '../../components/elements';
// import PlaceDataCorrections from '../PlaceDataCorrections';

const Container = styled.div`
  padding: 1rem;
  padding-bottom: 5rem;
`;
const FieldWrapper = styled.div`
  padding: 1rem 0.5rem;
`;

const FieldStyle = styled(Field).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;
const MobileFieldStyle = styled(MobileInput).attrs({
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    color: 'primaryColor',
    weight: 'bold',
    size: 'small',
    letterSpacing: 'loose',
  },
})``;
const FooterButton = styled.a`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.8rem 1rem;
  position: fixed;
  bottom: 0;
`;

const MobileForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  loading,
  setFieldValue,
}) => (
  <>
    <Container>
      <FieldWrapper>
        <FieldStyle
          name="name"
          label="Full Name"
          placeholder="Jodie Square"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <InputErrorMessage touched={touched.name} errors={errors.name} />
      </FieldWrapper>
      <FieldWrapper>
        <MobileFieldStyle
          label="Mobile"
          placeholder="+41000866866868"
          name="mobile"
          onChange={value => setFieldValue('mobile', value)}
          value={values.mobile}
        />
        <InputErrorMessage touched={touched.mobile} errors={errors.mobile} />
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          id="email"
          name="email"
          label="Email"
          placeholder="John @gmail.com"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <InputErrorMessage touched={touched.email} errors={errors.email} />
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          name="relation"
          label="RelationShip to the place"
          placeholder="Manger, Owner etc"
          value={values.relation}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <InputErrorMessage touched={touched.relation} errors={errors.relation} />
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          name="linkedin"
          label="Linkdin Profile URL"
          placeholder="https://example.com"
          value={values.linkedin}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <InputErrorMessage touched={touched.linkedin} errors={errors.linkedin} />
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          name="website"
          label="Business Url"
          placeholder="https://example.com"
          value={values.website}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <InputErrorMessage touched={touched.website} errors={errors.website} />
      </FieldWrapper>
      <FieldWrapper>
        <FlexRows justify="flex-start" align="flex-start">
          <CheckBox onChange={value => setFieldValue('agree', value)} />
          <Text color="darkGrey" size="micro">
            I am authorized representative of this business and have authority to claim this place.
            i have read and accept Kravein&apos;s <a>Term of service</a> and <a>private policy</a>
          </Text>
        </FlexRows>
        <InputErrorMessage touched={touched.agree} errors={errors.agree} />
      </FieldWrapper>
    </Container>

    <FooterButton
      onClick={() => {
        handleSubmit();
      }}
    >
      <Flex>
        <Text
          className="is-uppercase"
          color="white"
          align="center"
          weight="bold"
          size="medium"
          letterSpacing="loose"
        >
          {loading ? 'Loading...' : 'Verify'}
        </Text>
      </Flex>
    </FooterButton>
  </>
);

const MyEnhancedForm = withFormik({
  mapPropsToValues: ({ place }) => ({
    place_id: place ? place.place_id : '',
    name: '',
    relation: '',
    email: '',
    mobile: '',
    website: '',
    linkedin: '',
    agree: false,
    service_type: [],
    identity: 'facebook',
  }),

  validate: values => {
    const errors = {};
    if (values.agree === false) {
      errors.agree = 'Agree terms and conditions is required';
    }

    return errors;
  },
  validationSchema: yup.object().shape({
    name: yup.string().required('Full name is required!'),
    relation: yup.string().required('Relationship is required!'),
    email: yup
      .string()
      .email('Enter valid email address!')
      .required('Email address is required!'),
    mobile: yup.string().required('Mobile contact is required!'),
    website: yup.string().url('Enter valid url!'),
    linkedin: yup.string().url('Enter valid url!'),
  }),

  handleSubmit: (values, { props }) => {
    if (!props.isLoggedIn) {
      props.setIsActiveModal({
        value: true,
        path: '',
        callBackFunction: () => props.onSubmit(values),
      });
    } else if (props.step === 2) {
      props.onSubmit(values);
    } else {
      props.setStep(2);
    }
  },

  displayName: 'ContactUsForm',
})(MobileForm);

export default MyEnhancedForm;
