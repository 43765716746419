import React from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import localForage from 'localforage';
import { Link as RLink } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { AiOutlineUser } from 'react-icons/ai';

import { Avatar, Text, Icon } from '../../elements';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
`;

const Popup = styled.div`
  position: absolute;
  top: 3rem;
  width: 18rem;
  background: #fafafa;
  border-radius: 4px;
  z-index: 202;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
`;

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 1rem;
  background: #009cd7;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const Bottom = styled.div`
  padding: 0rem 0rem;
  border-right: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
  border-left: 2px solid #f0f0f0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const Link = styled(RLink)`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 0.8rem 1rem;
  :hover {
    background: #f0f0f0;
  }
`;
const Logout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 0.8rem 1rem;
  border-top: 2.5px solid #f0f0f0;
  :hover {
    background: #f0f0f0;
  }
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-left: 1rem;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-left: 1rem;
`;

const TextStyle = styled(Text).attrs({ color: 'darkGrey', weight: 'semibold', size: 'small' })``;

const SignInButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

const SignInButton = styled.button`
  background: #009cd7;
  color: white;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  padding: 11px 34px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  :hover {
    background: #0588ba;
  }
`;

const User = () => {
  const { setIsActiveDesktopLoginModal, logout } = useStoreActions(state => state.auth);
  const { isLoggedIn, user, userImage } = useStoreState(state => state.auth);
  return (
    <Container>
      {!isLoggedIn && (
        <SignInButtonWrapper>
          <SignInButton onClick={() => setIsActiveDesktopLoginModal({ value: true })}>
            Sign in
          </SignInButton>
        </SignInButtonWrapper>
      )}

      {isLoggedIn && (
        <>
          <Avatar
            name={user.display_name}
            borderRadius={25}
            maskProps={{ width: 45 }}
            borderColor="#ffffff"
            maskTextProps={{
              color: 'white',
              weight: 'bold',
              size: 'smedium',
              letterSpacing: 'loose',
            }}
            image={userImage}
            color={user.color_code}
          />
          <Popup>
            <RLink to="/profile">
              <Item>
                <Avatar
                  name={user.display_name}
                  borderRadius={40}
                  maskProps={{ width: 60 }}
                  borderColor="#ffffff"
                  maskTextProps={{
                    color: 'white',
                    weight: 'bold',
                    size: 'medium',
                    letterSpacing: 'loose',
                  }}
                  color={user.color_code}
                />

                <UserDetails>
                  <Text color="white" size="medium" weight="semibold">
                    {user.first_name} {user.last_name}
                  </Text>
                  <Text color="white" size="tiny" weight="semibold">
                    {user.email}
                  </Text>
                </UserDetails>
              </Item>
            </RLink>
            <Bottom>
              <Link to="/profile">
                <AiOutlineUser color="#a8a8a8" size={25} />
                <TextWrapper>
                  <TextStyle>My Profile</TextStyle>
                </TextWrapper>
              </Link>
              <Link to="/profile/my-bookmarks">
                <Icon name="bookmarkSolidGrey" size={1.5} />
                <TextWrapper>
                  <TextStyle>My Bookmarks</TextStyle>
                </TextWrapper>
              </Link>
              <Link to="/profile/find-friends">
                <Icon name="findFriendsGrey" size={1.5} />
                <TextWrapper>
                  <TextStyle>Find friends</TextStyle>
                </TextWrapper>
              </Link>
              <Link to="/profile/my-orders">
                <Icon name="myOrdersGrey" size={1.5} />
                <TextWrapper>
                  <TextStyle>My Orders</TextStyle>
                </TextWrapper>
              </Link>
              {/* <Link to="/profile/my-badges">
                <Icon name="flagGrey" size={1.5} />
                <TextWrapper>
                  <TextStyle>Badges</TextStyle>
                </TextWrapper>
              </Link> */}
              <Link to="/profile/settings">
                <Icon name="settingsGrey" size={1.5} />
                <TextWrapper>
                  <TextStyle>Settings</TextStyle>
                </TextWrapper>
              </Link>
              <a
                onClick={async () => {
                  await localForage.clear();
                  await logout();
                  await window.localStorage.clear();
                }}
              >
                <Logout>
                  <FiLogOut color="#a8a8a8" size={25} />
                  <TextWrapper>
                    <TextStyle>Sign out</TextStyle>
                  </TextWrapper>
                </Logout>
              </a>
            </Bottom>
          </Popup>
        </>
      )}
    </Container>
  );
};

export default User;
