import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';

import { getKeywordTagline } from '../../../utils/helpers';
import { Text, FlexRows, Image } from '../../../components/elements';
import { getEncodedImage } from '../../../utils/s3';
import config from '../../../utils/config';

const Container = styled.div`
  /* padding: 1rem; */
  &&& {
    background-color: ${({ theme: { colors: themeColors } }) => themeColors.white};
    margin-bottom: 1rem;
    /* :hover {
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
      transition: box-shadow 0.5s ease-out;
    } */
    .icon {
      justify-content: flex-start;
    }
  }
`;

const Figure = styled.figure`
  && {
    img {
      border-radius: 4px;
    }
  }
`;

const ImageView = ({ id, image }) => {
  if (image === null || image === '') {
    return (
      <Link to={`/place/${id}`}>
        <Figure className="image is-3by2 img-opacity">
          <Image source={config.dummyImage} />
        </Figure>
      </Link>
    );
  }

  return (
    <Link to={`/place/${id}`}>
      <Figure className="image image is-3by2">
        <Image source={getEncodedImage(image, 452, 301)} />
      </Figure>
    </Link>
  );
};

const BusinessCard = ({ place, history, isActiveOrderFood }) => {
  const serviceType =
    place.service_type && place.service_type.length !== 0 ? place.service_type : [];
  return (
    <Container>
      <Link to={`/place/${place.place_id}`}>
        <Text className="is-capitalized" size="medium" weight="semibold">
          {place.name}
        </Text>
      </Link>
      <Text size="tiny" color="dark" weight="regular">
        {place.address_line_1}, {place.city}, {place.state}
      </Text>
      <Text color="darkGrey" size="tiny">
        {getKeywordTagline(
          place.business_type,
          place.cuisine_style,
          // place.feature,
          //   place.dietary_information,
        )}
      </Text>

      {isActiveOrderFood && serviceType.length !== 0 && (
        <FlexRows justify="flex-start">
          {isActiveOrderFood && (
            <Text color="secondaryColor" size="small">
              Order now:{' '}
            </Text>
          )}
          {serviceType.map(item2 => (
            <a onClick={() => history.push(`/order-food/${item2.name}/${place.place_id}`)}>
              <div style={{ padding: '0rem 0.5rem' }}>
                <Text color="primaryColor" weight="semibold" size="small">
                  {item2.name}
                </Text>
              </div>
            </a>
          ))}
        </FlexRows>
      )}
      <br />
      <figure className="media-left">
        <ImageView id={place.place_id} image={place.default_image_url} />
      </figure>
    </Container>
  );
};

export default withRouter(BusinessCard);
