import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { truncate } from 'lodash';

import { Text, FlexRows } from '../../components/elements';
import colors from '../../theme/styles/colors';
import InputOptionModal from './InputOptionModal';

const Wrapper = styled.div`
  border-bottom: 1px solid
    ${({ borderBottomColor }) => (borderBottomColor ? colors[borderBottomColor] : 'transparent')};
`;

// const Span = styled.span`
//   &&& {
//     font-size: 11px;
//     color: #737373;
//   }
// `;

const Span2 = styled.span`
  &&& {
    font-size: 11px;
    color: red;
    position: relative;
    bottom: 4px;
  }
`;

const MobileTagInput = ({ borderBottomColor, label, optional, required, options, onChange }) => {
  const [isActive, setIsActive] = useState(false);
  const [typeInputs, setTypeInputs] = useState([]);

  const labelTextProps = {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  };

  const optionalTextProps = {
    size: 'tiny',
    color: 'specialGrey',
    weight: 'semibold',
  };

  const valuesTextProps = {
    size: 'tiny',
  };

  const addTypeInputs = types => {
    const newTypeInputs = typeInputs.concat([types]);
    setTypeInputs(newTypeInputs);
  };

  const removeTypeInputs = name => {
    const newTypeInputs = typeInputs.filter(item => item.name !== name);
    setTypeInputs(newTypeInputs);
  };

  return (
    <Wrapper className="field" borderBottomColor={borderBottomColor}>
      <InputOptionModal
        label={label}
        options={options}
        isActive={isActive}
        onCloseModal={() => setIsActive(false)}
        done={() => {
          setIsActive(false);
          onChange(typeInputs);
        }}
        typeInputs={typeInputs}
        addType={addTypeInputs}
        removeType={removeTypeInputs}
      />
      {label && (
        <label className="label">
          <Text {...labelTextProps}>
            {label} {required && <Span2>*</Span2>}
          </Text>
          <Text {...optionalTextProps}>{optional}</Text>
        </label>
      )}
      <a onClick={() => setIsActive(true)}>
        <div className="control">
          <FlexRows justify="space-between">
            <Text {...valuesTextProps}>
              {truncate(
                typeInputs.map(item => item.description),
                { length: 30 },
              )}
            </Text>
            <Text color="specialGrey">
              <MdKeyboardArrowRight size={20} />
            </Text>
          </FlexRows>
        </div>
      </a>
    </Wrapper>
  );
};

MobileTagInput.defaultProps = {
  options: [],
  label: '',
  borderBottomColor: 'specialGrey',
  optional: false,
  onChange: () => {},
};

MobileTagInput.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  borderBottomColor: PropTypes.string,
  optional: PropTypes.bool,
  onChange: PropTypes.func,
};

export default MobileTagInput;
