/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  &&& {
    margin: 0;
    span {
      margin: 3px;
    }
  }
`;

const Tags = ({ filterStateInputs, filterFuncInputs }) => (
  <Container>
    {filterStateInputs.cuisineInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small"
          onClick={() => filterFuncInputs.removeCuisineInputs(item.name)}
        />
      </span>
    ))}
    {filterStateInputs.itemTypeInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small has-color-danger"
          onClick={() => filterFuncInputs.removeItemTypeInputs(item.name)}
        />
      </span>
    ))}
    {filterStateInputs.businessTypeInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small has-color-danger"
          onClick={() => filterFuncInputs.removeBusinessTypeInputs(item.name)}
        />
      </span>
    ))}
    {filterStateInputs.featureInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small has-color-danger"
          onClick={() => filterFuncInputs.removeFeatureInputs(item.name)}
        />
      </span>
    ))}

    {filterStateInputs.dressCodeInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small has-color-danger"
          onClick={() => filterFuncInputs.removeDressCodeInputs(item.name)}
        />
      </span>
    ))}
    {filterStateInputs.diningStyleInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small has-color-danger"
          onClick={() => filterFuncInputs.removeDiningStyleInputs(item.name)}
        />
      </span>
    ))}
    {filterStateInputs.publicTransportInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small has-color-danger"
          onClick={() => filterFuncInputs.removePublicTransportInputs(item.name)}
        />
      </span>
    ))}
    {filterStateInputs.ambienceInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small has-color-danger"
          onClick={() => filterFuncInputs.removeAmbienceInputs(item.name)}
        />
      </span>
    ))}
  </Container>
);

export default Tags;
