import React, { useState } from 'react';
import styled from 'styled-components';
import { take } from 'lodash';
import { useStoreState } from 'easy-peasy';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

import { Text, CheckBox, FlexRows } from '../../../../components/elements';
import ListingTypeFilter from './ListingTypeFilter';
import DateFilter from './DateFilter';

const Container = styled.div`
  &&& {
    padding-top: 0.5rem;
    background-color: ${({ theme: { colors } }) => colors.white};
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.04);
    margin-right: 1rem;
    border-radius: 4px;
  }
`;
const TopHeading = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding: 0rem 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;
const Wrapper = styled.div`
  padding-bottom: 0.5rem;
  /* border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyAlpha}; */
`;

const Item = styled.div`
  padding-bottom: 0.5rem;
 /* border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};*/
`;
const ItemContent = styled.div`
  padding: 0.25rem 1rem;
`;

const Heading = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding-top: 0.25rem;
  padding-right: 1rem;
`;
const CheckBoxWrapper = styled.div`
  padding: 0rem;
`;

const Filter = ({ heading, items, OnClick, initialInputs, onHandleClearItem }) => {
  const [numberOfItems, setNumberOfItems] = useState(5);
  const data = take(items, numberOfItems);
  const [toggle, setToggle] = useState(true);

  return (
    <Item>
      <Heading>
        <div className="is-flex">
          <span className="icon">
            <a onClick={() => setToggle(!toggle)}>
              {toggle ? (
                <Text color="dark" size="small" weight="regular">
                  <FaAngleUp />
                </Text>
              ) : (
                <Text color="dark" size="small" weight="regular">
                  <FaAngleDown />
                </Text>
              )}
            </a>
          </span>
          <Text className="is-capitalized" color="dark" size="lmedium" weight="regular">
            {heading}
          </Text>
        </div>

        {initialInputs.length !== 0 && (
          <a onClick={onHandleClearItem}>
            <Text className="is-uppercase" color="primaryColor" size="micro" weight="semibold">
              Clear
            </Text>
          </a>
        )}
      </Heading>
      {toggle && (
        <ItemContent>
          <CheckBoxWrapper>
            {data.map(item => (
              <CheckBox
                initialValue={initialInputs.filter(item2 => item2.name === item.name).length === 1}
                label={`${item.description || item.name} ( ${item.count} )`}
                onChange={value => OnClick(item, value)}
              />
            ))}
          </CheckBoxWrapper>
          {items.length - 5 > 0 && (
            <a
              onClick={() =>
                items.length !== numberOfItems
                  ? setNumberOfItems(items.length)
                  : setNumberOfItems(5)
              }
            >
              <Text
                color="primaryColor"
                size="tiny"
                weight="semibold"
                style={{ paddingTop: '10px' }}
              >
                {items.length === numberOfItems ? 'Show Less' : `Show All`}{' '}
                {items.length !== numberOfItems && (
                  <span style={{ color: '#737373' }}>({items.length - 5} more)</span>
                )}
              </Text>
            </a>
          )}
        </ItemContent>
      )}
    </Item>
  );
};

const Filters = ({ filterFuncInputs }) => {
  const filterTags = useStoreState(state => state.event.filterTags);
  const filterInputs = useStoreState(state => state.event.filterInputs);
  const {
    ticketType,
    listingType,
    eventType,
    eventCategory,
    feature,
    ageRestriction,
    dressCode,
  } = filterTags;

  const listType = listingType.filter(item => item.name === 'public_listing');
  const paidTicket = ticketType.filter(item => item.name === 'PAID_TICKET');
  const freeTicket = ticketType.filter(item => item.name === 'FREE_TICKET');

  return (
    <Container>
      <Wrapper>
        <TopHeading>
          <Text className="is-capitalized" size="large" weight="semibold">
            Refine Search
          </Text>
          <a onClick={() => filterFuncInputs.clearFilters()}>
            <Text size="micro" weight="semibold" color="primaryColor">
              CLEAR ALL
            </Text>
          </a>
        </TopHeading>
      </Wrapper>
      <DateFilter />
      <ListingTypeFilter
        listType={listType}
        paidTicket={paidTicket}
        freeTicket={freeTicket}
        filterTags={filterTags}
        filterInputs={filterInputs}
        filterFuncInputs={filterFuncInputs}
        onHandleClearItem={() => filterFuncInputs.onHandleClearItem('listingType')}
      />
      {eventType.length !== 0 && (
        <Filter
          heading="Event Types"
          items={eventType}
          initialInputs={filterInputs.eventType}
          OnClick={(item, value) =>
            value
              ? filterFuncInputs.addEventTypeInputs(item)
              : filterFuncInputs.removeEventTypeInputs(item.name)
          }
          onHandleClearItem={() => filterFuncInputs.onHandleClearItem('eventType')}
        />
      )}
      {eventCategory.length !== 0 && (
        <Filter
          heading="Event Category"
          items={eventCategory}
          initialInputs={filterInputs.eventCategory}
          OnClick={(item, value) =>
            value
              ? filterFuncInputs.addEventCategory(item)
              : filterFuncInputs.removeEventCategory(item.name)
          }
          onHandleClearItem={() => filterFuncInputs.onHandleClearItem('eventCategory')}
        />
      )}
      {feature.length !== 0 && (
        <Filter
          heading="Features"
          items={feature}
          initialInputs={filterInputs.feature}
          OnClick={(item, value) =>
            value ? filterFuncInputs.addFeatures(item) : filterFuncInputs.removeFeatures(item.name)
          }
          onHandleClearItem={() => filterFuncInputs.onHandleClearItem('feature')}
        />
      )}
      {ageRestriction.length !== 0 && (
        <Filter
          heading="Age Restriction"
          items={ageRestriction}
          initialInputs={filterInputs.ageRestriction}
          OnClick={(item, value) =>
            value
              ? filterFuncInputs.addAgeRestriction(item)
              : filterFuncInputs.removeAgeRestriction(item.name)
          }
          onHandleClearItem={() => filterFuncInputs.onHandleClearItem('ageRestriction')}
        />
      )}

      {dressCode.length !== 0 && (
        <Filter
          heading="Dress Code"
          items={dressCode}
          initialInputs={filterInputs.dressCode}
          OnClick={(item, value) =>
            value
              ? filterFuncInputs.addDressCode(item)
              : filterFuncInputs.removeDressCode(item.name)
          }
          onHandleClearItem={() => filterFuncInputs.onHandleClearItem('dressCode')}
        />
      )}
    </Container>
  );
};

export default Filters;
