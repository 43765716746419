import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { geolocated } from 'react-geolocated';
import { useStoreActions, useStoreState } from 'easy-peasy';

import useHandleLocation from '../utils/useHandleLocation';

import {
  IconList,
  Signup,
  Home,
  NotFound,
  Places,
  Place,
  PlaceMenu,
  PlaceSearchForReview,
  PlaceReview,
  PlacePhotos,
  PlaceSearchForClaim,
  AddPlacePhotos,
  Events,
  Event,
  EventOccurrence,
  // EventOccurrenceTicket,
  EventBookingDetails,
  Items,
  Item,
  ItemReview,
  ItemSpotAndShare,
  AddItemPhotos,
  ItemPhotos,
  Test,
  Login,
  SupportAndFeedback,
  ContactUs,
  Feedback,
  Profile,
  FindFriends,
  ProfileUpdate,
  PlaceDataCorrections,
  MyBookmarks,
  MyBookmarksEvents,
  MyBookmarksPlaces,
  // MyBookmarksSharedItems,
  // MyBookmarksMenuItems,
  MyOrders,
  MyReviews,
  MyActivity,
  MyFriends,
  MyBadges,
  PaymentDetails,
  AddressDetails,
  Settings,
  AllBadges,
  AddAddress,
  EditAddress,
  AddNewCard,
  AddNewPlace,
  ClaimYourPlace,
  SearchLocation,
  GlobalSearch,
  Menus,
  Menu,
  MenuCheckout,
  MenuOrderDetails,
} from '../pages';

const PrivateRoute = ({ component: Component, access, location, ...rest }) => {
  const { isLoggedIn } = useStoreState(state => state.auth);
  const updateEventFilterOnEveryCall = useStoreActions(
    state => state.global.updateEventFilterOnEveryCall,
  );
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const eventCurrentDateFilter = useStoreState(state => state.global.eventCurrentDateFilter);

  useEffect(() => {
    updateEventFilterOnEveryCall({ usedLocation, eventCurrentDateFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      forceRefresh={false}
      render={props => {
        return <Component {...props} />;
      }}
    />
  );
};

const AuthRoute = ({ component: Component, access, location, ...rest }) => {
  animateScroll.scrollToTop({ smooth: 'linear', duration: 50 });
  const updateEventFilterOnEveryCall = useStoreActions(
    state => state.global.updateEventFilterOnEveryCall,
  );
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const eventCurrentDateFilter = useStoreState(state => state.global.eventCurrentDateFilter);

  useEffect(() => {
    updateEventFilterOnEveryCall({ usedLocation, eventCurrentDateFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      forceRefresh
      render={props => {
        return <Component {...props} />;
      }}
    />
  );
};

// const RouteContainer = posed.div({
//   enter: { opacity: 1, delay: 50, beforeChildren: true },
//   exit: { opacity: 0 },
// });

const AppRouter = ({ coords }) => {
  useHandleLocation(coords);
  return (
    <Router>
      <Switch>
        <AuthRoute exact path="/" component={Home} key="home" />
        <AuthRoute path="/search-location/" component={SearchLocation} key="login" />
        <AuthRoute exact path="/global-search" component={GlobalSearch} key="search" />
        <AuthRoute path="/places/" component={Places} exact key="places" />
        <AuthRoute path="/place/:placeId" component={Place} key="place" />
        <AuthRoute path="/order-food/" component={Menus} exact key="order" />
        <AuthRoute
          path="/order-food/:serviceType/:placeId"
          component={Menu}
          exact
          key="order-detail"
        />
        <PrivateRoute
          path="/order-details/:menuOrderId"
          component={MenuOrderDetails}
          exact
          key="order-detail"
        />
        <PrivateRoute path="/checkout" component={MenuCheckout} exact key="checkout" />
        <AuthRoute path="/login" component={Login} key="login" />
        <AuthRoute path="/signup" component={Signup} key="signup" />
        <AuthRoute path="/events/" component={Events} key="events" />
        <AuthRoute path="/event/:eventId/" component={Event} exact key="event" />
        <AuthRoute
          path="/event-booking/:eventId/"
          component={EventOccurrence}
          key="event-occurrence"
        />
        {/* <Route
                path="/event/:eventSlug/:eventId/occurrence/:occurrenceId/ticket/:ticketId"
                component={EventOccurrenceTicket}
                exact
                key="event-tickets"
              /> */}
        <AuthRoute
          path="/event-booking-details/:eventId/:bookingId"
          component={EventBookingDetails}
          exact
          key="event-booking-details"
        />

        <AuthRoute
          path="/place-search-for-review/"
          component={PlaceSearchForReview}
          key="place-search-for-review"
          exact
        />
        <AuthRoute path="/place-review/:placeId" component={PlaceReview} key="place-review" exact />
        <AuthRoute path="/place/menu" component={PlaceMenu} exact key="place-menu" />
        <AuthRoute
          path="/claim-your-place/:placeId"
          component={ClaimYourPlace}
          key="claim-you-place"
        />
        <AuthRoute
          path="/search-place-for-claim"
          component={PlaceSearchForClaim}
          key="search=place-for-claim"
        />
        <AuthRoute
          component={PlaceDataCorrections}
          path="/place-data-corrections"
          exact
          key="place-data-corrections"
        />
        <AuthRoute component={PlacePhotos} path="/place-photos/:placeId" exact key="place-photos" />
        <AuthRoute
          component={AddPlacePhotos}
          path="/add-place-photos/:placeId"
          exact
          key="add-place-photos"
        />
        <AuthRoute path="/items/" component={Items} exact key="items" />
        <AuthRoute path="/item/:itemId/" component={Item} exact key="item" />
        <AuthRoute path="/item-review/:itemId" component={ItemReview} exact key="item-review" />
        <AuthRoute
          path="/spot-and-share-an-item"
          component={ItemSpotAndShare}
          exact
          key="item-review"
        />
        <AuthRoute
          path="/add-item-photos/:itemId"
          component={AddItemPhotos}
          exact
          key="add-item-photos"
        />
        <AuthRoute
          component={ItemPhotos}
          path="/item-photos/:placeId/:itemId"
          exact
          key="item-photos"
        />

        <PrivateRoute path="/profile" component={Profile} exact key="profile" />
        <PrivateRoute
          path="/profile/my-reviews"
          component={MyReviews}
          exact
          key="profile-my-reviews"
        />
        <PrivateRoute
          path="/profile/find-friends"
          component={FindFriends}
          exact
          key="find-friends"
        />
        <PrivateRoute
          path="/profile/my-activity"
          component={MyActivity}
          exact
          key="profile-my-activity"
        />
        <PrivateRoute
          path="/profile/my-friends"
          component={MyFriends}
          exact
          key="profile-my-friends"
        />

        <PrivateRoute
          path="/profile/my-badges"
          component={MyBadges}
          exact
          key="profile-my-badges"
        />
        <PrivateRoute
          path="/profile/payment-details"
          component={PaymentDetails}
          exact
          key="profile-my-payment-details"
        />
        <PrivateRoute
          path="/profile/address-details"
          component={AddressDetails}
          exact
          key="profile-my-profile"
        />
        <PrivateRoute
          path="/profile/address-details/add-new-address"
          component={AddAddress}
          exact
          key="profile-my-add-new-address"
        />
        <PrivateRoute
          path="/profile/profile/payment-details/add-card"
          component={AddNewCard}
          exact
          key="profile-my-add-card"
        />
        <PrivateRoute
          path="/profile/address-details/edit"
          component={EditAddress}
          exact
          key="profile-address-details"
        />
        <PrivateRoute path="/profile-update" component={ProfileUpdate} exact key="profile-update" />

        <AuthRoute path="/all-badges" component={AllBadges} exact key="all-badges" />

        <AuthRoute
          path="/support-and-feedback"
          component={SupportAndFeedback}
          exact
          key="support-and-feedback"
        />
        <AuthRoute path="/contact-us" component={ContactUs} exact key="contact-us" />
        <AuthRoute path="/feedback" component={Feedback} exact key="feedback" />

        <PrivateRoute
          path="/profile/my-bookmarks"
          component={MyBookmarks}
          exact
          key="my-bookmarks"
        />
        <PrivateRoute
          path="/my-bookmarks-events"
          component={MyBookmarksEvents}
          exact
          key="my-bookmarks-events"
        />
        <PrivateRoute
          path="/my-bookmarks-places"
          component={MyBookmarksPlaces}
          exact
          key="my-bookmarks-places"
        />
        {/* <AuthRoute
          path="/my-bookmarks-shared-items"
          component={MyBookmarksSharedItems}
          exact
          key="my-bookmarks-shared-items"
        /> */}
        {/* <AuthRoute
          path="/my-bookmarks-menu-items"
          component={MyBookmarksMenuItems}
          exact
          key="my-bookmarks-menu-items"
        /> */}
        <PrivateRoute path="/profile/my-orders" component={MyOrders} exact key="my-orders" />
        <PrivateRoute path="/profile/settings" component={Settings} exact key="settings" />
        <AuthRoute path="/add-place" component={AddNewPlace} key="add-place" />

        <AuthRoute path="/test" component={Test} exact key="test" />
        <AuthRoute path="/icon-list" component={IconList} exact key="icon-list" />
        <AuthRoute component={NotFound} key="not-found" />
      </Switch>
    </Router>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(AppRouter);
