import React from 'react';
import gql from 'graphql-tag';
import qs from 'querystringify';
import { lowerCase, capitalize, first, isNull } from 'lodash';
// import { MdMyLocation } from 'react-icons/md';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import uuid from 'uuid';

import { AutoCompleteInput, Text } from '../../../../components/elements';
import client from '../../../../utils/apolloClient';

const Container = styled.div`
  &&& {
    .react-autosuggest__container {
      position: relative;
    }
    .react-autosuggest__suggestions-container {
      display: none;
    }
    .react-autosuggest__suggestions-container--open {
      max-height: 15rem;
      overflow-y: scroll;
      display: block;
      position: absolute;
      width: 100%;
      background: #fff;
      border: 1px solid #f6f6f6;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
      z-index: 2000;
    }
    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 5px 10px;
      border-bottom: 1px solid #f6f6f6 !important;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: #f6f6f6;
    }
  }
`;

const itemsQuery = gql`
  query itemsQuery($input: SearchInput) {
    search_items(input: $input) {
      item_listing {
        item_id
        item_dictionary_id
        place_id
        place_name
        name
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        timezone
      }
    }
  }
`;

const itemQuery = gql`
  query fetch_item($input: NavigateItemInput) {
    fetch_item(input: $input) {
      item_id
      place_id
      place_name
      name
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      timezone
    }
  }
`;

const itemsDictionaryQuery = gql`
  query itemsDictionary($input: SearchInput) {
    search_item_dictionary(input: $input) {
      item_dictionary_listing {
        item_dictionary_id
        name
      }
    }
  }
`;

const renderLocationSuggestion = location => {
  if (location.type) {
    return (
      <div className="is-flex">
        <Text color="darkGrey" size="tiny">
          {location.text}
        </Text>
        <Text color="primaryColor" size="tiny" weight="bold">
          {location.query}
        </Text>
      </div>
    );
  }
  return (
    <div>
      <Text className="is-capitalized" color="primaryColor" size="tiny" weight="bold">
        {location.name}
      </Text>
      {/* <Text color="darkGrey" size="micro">
        {location.address_line_1}
        {location.city} {location.state}, {location.post_code}
      </Text> */}
    </div>
  );
};
const getSuggestionValue = suggestion => suggestion.suburbName;

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: '',
      suggestions: [],
      loading: false,
    };
  }

  componentDidMount = () => {
    const params = qs.parse(this.props.location.search);
    if (params.item_id) {
      this.setState({ loading: true });
      const { onHandleItemSelection, onHandleItemDictionarySelection } = this.props;
      client.clientPublic
        .query({
          query: itemQuery,
          variables: { input: { place_id: params.place_id, item_id: params.item_id } },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          const item = first(data.fetch_item);
          onHandleItemSelection(item);
          onHandleItemDictionarySelection({
            item_dictionary_id: item.item_dictionary_id,
            name: item.name,
            action: 'existed',
          });
          this.setState({
            location: `${capitalize(item.name)}`,
          });
          this.setState({ loading: false });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  setSuburbSuggestions = suggestions => {
    this.setState({ suggestions });
  };

  onChange = (event, { newValue, method }) => {
    if (method === 'type') {
      this.setState({
        location: newValue,
      });
    }
  };

  checkItemExistOrNot = (value, itemDictionaryId) => {
    const { handleItemName, onHandleItemSelection, placeData } = this.props;
    console.log('checkItemExistOrNot', value, itemDictionaryId, placeData);
    client.clientPublic
      .query({
        query: itemsQuery,
        fetchPolicy: 'network-only',
        variables: {
          // search_criteria: lowerCase(value),
          input: {
            filter: {
              item_filter: {
                item_dictionary_id: itemDictionaryId,
                place_id: placeData.place_id,
                status: 'ACTIVE',
              },
            },
          },
        },
      })
      .then(({ data }) => {
        const item =
          data.search_items.item_listing.length !== 0
            ? first(data.search_items.item_listing)
            : null;
        if (!isNull(item)) {
          onHandleItemSelection({
            ...item,
            item_dictionary_id: itemDictionaryId,
          });
        } else {
          handleItemName(value, itemDictionaryId);
        }
      });
  };

  onSuggestionSelected = async (event, { suggestion }) => {
    const { onHandleItemDictionarySelection } = this.props;
    if (suggestion.type) {
      const item_dictionary_id = uuid();
      onHandleItemDictionarySelection({
        item_dictionary_id,
        name: suggestion.query,
        action: 'new',
      });
      this.checkItemExistOrNot(suggestion.query, item_dictionary_id);
    } else {
      await onHandleItemDictionarySelection({ ...suggestion, action: 'existed' });
      await this.checkItemExistOrNot(suggestion.name, suggestion.item_dictionary_id);
      this.setState({
        location: `${capitalize(suggestion.name)}`,
      });
    }
  };

  onBlur = (event, { highlightedSuggestion }) => {
    console.log('onBlur', highlightedSuggestion);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    // const { placeData } = this.props;
    client.clientPublic
      .query({
        query: itemsDictionaryQuery,
        variables: {
          // search_criteria: lowerCase(value),
          input: {
            filter: { item_dictionary_filter: { name: lowerCase(value), status: 'ACTIVE' } },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const addPlaceData = [
          {
            type: 'custom',
            text: 'Would you like to add this dish?',
            query: capitalize(value),
          },
        ];
        const customData = addPlaceData.concat(data.search_item_dictionary.item_dictionary_listing);
        this.setSuburbSuggestions(customData);
        // console.log('data', data);
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { location, suggestions, loading } = this.state;
    const { placeData } = this.props;
    return (
      <Container>
        <div className={`control ${loading && 'is-loading'}`}>
          <AutoCompleteInput
            value={location}
            placeholder="Name of the item. eg: Caramel Ice Cream, Fruit Punch.."
            // leftIcon={<GoLocation size={18} />}
            // rightIcon={<MdMyLocation size={18} />}
            enableInputWithAllElements
            onChange={this.onChange}
            onBlur={this.onBlur}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderLocationSuggestion}
            onSuggestionSelected={this.onSuggestionSelected}
            disabled={!placeData.place_id && true}
          />
        </div>
      </Container>
    );
  }
}

export default withRouter(Search);
