import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FlexRows, Text, Avatar } from '../../../components/elements';

const TextWrapper = styled.div`
  padding-left: 1rem;
`;

const ItemWrapper = styled(FlexRows)`
  padding: 0.5rem 1rem;
`;

const Item = styled(ItemWrapper)`
  &&& {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  }
`;

const SuggestionItem = ({ title, subtitle, subtitle1, image, path }) => (
  <Link to={path}>
    <Item justify="flex-start" align="flex-start">
      <Avatar name={title} maskProps={{ width: 60, height: 60 }} image={image} borderRadius={4} />
      <TextWrapper>
        <Text className="is-capitalized" size="small">
          {title}
        </Text>
        <Text size="micro" color="darkGrey">
          {subtitle}
        </Text>
        {subtitle1 && (
          <Text size="micro" color="darkGrey">
            {subtitle1}
          </Text>
        )}
      </TextWrapper>
    </Item>
  </Link>
);

export default SuggestionItem;
