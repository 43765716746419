import React from 'react';
import styled from 'styled-components';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';
import { Pager } from '../../../components/elements';

const Container = styled.div`
  margin-top: 2rem;
`;

const ParentComponent = () => {
  return (
    <Pager>
      <Container>
        <div className="columns">
          <div className="column is-8">
            <RectShape color="#f0f0f0" style={{ width: '100%', height: '2.5rem' }} />
          </div>
          <div className="column is-4">
            <RectShape color="#f0f0f0" style={{ width: '100%', height: '2.5rem' }} />
          </div>
        </div>
      </Container>
      <Container>
        <div className="columns">
          <div className="column is-8">
            <div className="columns is-multiline">
              <div className="column is-6">
                <RectShape color="#f0f0f0" style={{ width: '100%', height: '10rem' }} />
              </div>
              <div className="column is-6">
                <RectShape color="#f0f0f0" style={{ width: '100%', height: '10rem' }} />
              </div>
              <div className="column is-6">
                <RectShape color="#f0f0f0" style={{ width: '100%', height: '10rem' }} />
              </div>
              <div className="column is-6">
                <RectShape color="#f0f0f0" style={{ width: '100%', height: '10rem' }} />
              </div>
              <div className="column is-6">
                <RectShape color="#f0f0f0" style={{ width: '100%', height: '10rem' }} />
              </div>
              <div className="column is-6">
                <RectShape color="#f0f0f0" style={{ width: '100%', height: '10rem' }} />
              </div>
            </div>
          </div>
          <div className="column is-4">
            <RectShape color="#f0f0f0" style={{ width: '100%', height: '35rem' }} />
          </div>
        </div>
      </Container>
    </Pager>
  );
};

const ParentLoader = () => {
  return (
    <ReactPlaceholder ready showLoadingAnimation>
      <ParentComponent />
    </ReactPlaceholder>
  );
};

export default ParentLoader;
