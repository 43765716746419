import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { isNull, trim, capitalize } from 'lodash';
import uuid from 'uuid';
import { MdDone, MdKeyboardArrowRight } from 'react-icons/md';

import {
  FlexRows,
  Text,
  Radio,
  CheckBox,
  LocationSuggestion,
  Button,
} from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';

import InputField from './InputField';

const AddressWrapper = styled.form`
  min-height: 20rem;
  background-color: #fff;
  .radio-btns {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  }
  .radio-btn {
    align-items: baseline;
  }
`;

const Footer = styled.footer`
  && {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 1);
    border-top: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  }
`;

const Wrapper = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
`;

const InputWrapper = styled.div`
  padding: 1rem 0rem;
`;

const AddressList = styled.div`
  margin-bottom: 4rem;
`;

const AddressListItem = styled.div`
  cursor: pointer;
  border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  padding: 1rem 0rem;
`;

const DeliveryAddressForm = ({
  placeName,
  isDeliverableAddress,
  loading,
  address,
  onSubmit,
  isActiveSuggestion,
  setIsActiveSuggestion,
  selectedAddress,
  handleSelectAddress,
  handleFinalAddress,
  userAddress,
  checkIsDeliverableAddressLoading,
}) => {
  const [suggestionError, setSuggestionError] = useState();

  const addressId = uuid();
  const formik = useFormik({
    initialValues: {
      address_id: address && !isNull(address.address_id) ? address.address_id : addressId,
      is_default: address && !isNull(address.is_default) ? address.is_default : false,
      type: address && !isNull(address.type) ? address.type : '',
      contact_name: address && !isNull(address.contact_name) ? address.contact_name : '',
      contact_phone: address && !isNull(address.contact_phone) ? address.contact_phone : '',
      address_line_1: address && !isNull(address.address_line_1) ? address.address_line_1 : '',
      address_line_2: address && !isNull(address.address_line_2) ? address.address_line_2 : '',
      city: address && !isNull(address.city) ? address.city : '',
      post_code: address && !isNull(address.post_code) ? address.post_code : '',
      state: address && !isNull(address.state) ? address.state : '',
      country: address && !isNull(address.country) ? address.country : '',
      latitude: address && !isNull(address.latitude) ? address.latitude : '',
      longitude: address && !isNull(address.longitude) ? address.longitude : '',
      status: address && !isNull(address.status) ? address.status : 'ACTIVE',
      deliverToWhere: 'Deliver to door',
      call: '',
      bell: '',
    },
    onSubmit,
  });

  const { values, setFieldValue, handleChange, handleBlur, handleSubmit } = formik;

  return (
    <>
      <AddressWrapper className="modal-card-body">
        {isActiveSuggestion ? (
          <>
            <InputWrapper>
              <Text size="small" weight="semibold" color="darkGrey" paddingBottom={1}>
                Enter street address or suburb name
              </Text>
              <LocationSuggestion
                initialValue={
                  values.address_line_1
                    ? // eslint-disable-next-line max-len
                      `${values.address_line_1} ${values.city} ${values.state} ${values.post_code} ${values.country}`
                    : ''
                }
                onSelect={location => {
                  if (location.addressLine1 && location.length !== 0) {
                    setFieldValue('address_line_1', trim(location.addressLine1));
                    setFieldValue('city', location.city);
                    setFieldValue('post_code', location.postcode);
                    setFieldValue('state', location.state);
                    setFieldValue('country', location.country || 'AUSTRALIA');
                    setFieldValue('latitude', location.latitude);
                    setFieldValue('longitude', location.longitude);
                    setIsActiveSuggestion(false);
                  } else {
                    setSuggestionError('Street address required!');
                  }
                }}
              />
              {suggestionError && (
                <Text color="danger" size="small">
                  {suggestionError}
                </Text>
              )}
            </InputWrapper>
            <AddressList>
              {userAddress.map(item => (
                <AddressListItem onClick={() => handleSelectAddress(item)}>
                  <Text size="small" weight="regular" color="darkGrey">
                    {item.type}
                  </Text>
                  <FlexRows justify="space-between">
                    <div>
                      <Text size="small" weight="regular" color="specialGrey">
                        {item.address_line_1}
                      </Text>
                      <Text size="small" weight="regular" color="specialGrey">
                        {item.city} {item.state} {item.post_code}, {item.country}
                      </Text>
                      <Text size="small" weight="regular" color="specialGrey">
                        Deliver to door
                      </Text>
                      {item.address_id === selectedAddress &&
                        !checkIsDeliverableAddressLoading &&
                        !isDeliverableAddress && (
                          <Text size="small" weight="regular" color="danger">
                            {capitalize(placeName)} doesn&apos;t deliver to the selected address
                          </Text>
                        )}
                    </div>
                    <div>
                      {item.address_id === selectedAddress && (
                        <MdDone size={25} color={colors.primaryColor} />
                      )}
                    </div>
                  </FlexRows>
                </AddressListItem>
              ))}
            </AddressList>
          </>
        ) : (
          <>
            <InputField
              label="ADDRESS TYPE"
              placeholder="Home, Office, Beach House"
              name="type"
              value={values.type}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Wrapper>
              <Text color="primaryColor" size="small">
                ADDRESS
              </Text>
              <a onClick={() => setIsActiveSuggestion(true)}>
                <FlexRows justify="space-between">
                  <Text size="small" weight="medium" color="darkGrey">
                    {values.address_line_1}, {values.city} {values.state} {values.post_code},{' '}
                    {values.country}
                  </Text>
                  <Text color="coolGrey">
                    <MdKeyboardArrowRight size={30} />
                  </Text>
                </FlexRows>
              </a>
            </Wrapper>
            <InputField
              label="ADDITIONAL ADDRESS INFORMATION"
              placeholder="Flat or floor / Business, building, Hotel or shopping center name"
              name="address_line_2"
              value={values.address_line_2}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className="radio-btns">
              <div className="is-flex radio-btn">
                <Radio
                  label=""
                  value={values.deliverToWhere === 'Deliver to door'}
                  onChange={() => {
                    setFieldValue('deliverToWhere', 'Deliver to door');
                  }}
                  disabled={false}
                />
                <Text size="small" weight="regular" color="darkGrey">
                  Deliver to door
                </Text>
              </div>

              <div className="is-flex radio-btn">
                <Radio
                  label=""
                  value={values.deliverToWhere === 'Pickup Outside'}
                  onChange={() => {
                    setFieldValue('deliverToWhere', 'Pickup Outside');
                  }}
                  disabled={false}
                />
                <Text size="small" weight="regular" color="darkGrey">
                  Pickup Outside
                </Text>
              </div>
            </div>
            <CheckBox
              value={values.call === 'Call upon reaching my location'}
              label="Call upon reaching my location"
              labelTextProps={{ size: 'small', color: colors.darkGrey, weight: 'regular' }}
              onChange={() => {
                setFieldValue('call', 'Call upon reaching my location');
              }}
              style={{ marginBottom: '0.5rem' }}
            />
            <CheckBox
              value={values.call === 'Please do not ring the bell or knock the door'}
              label="Please do not ring the bell or knock the door"
              labelTextProps={{ size: 'small', color: colors.darkGrey, weight: 'regular' }}
              onChange={() => {
                setFieldValue('bell', 'Please do not ring the bell or knock the door');
              }}
            />
          </>
        )}
      </AddressWrapper>
      <Footer className="modal-card-foot">
        {isActiveSuggestion ? (
          <Button
            // className={loading && 'is-loading'}
            type="button"
            name="Update"
            width={3}
            backgroundColor="primaryColor"
            textProps={{
              color: loading ? 'primaryColor' : colors.white,
              weight: 'semibold',
              size: 'small',
            }}
            style={{ width: '100%' }}
            onClick={() => {
              if (isDeliverableAddress && !checkIsDeliverableAddressLoading) {
                handleFinalAddress();
              }
            }}
            disabled={checkIsDeliverableAddressLoading || !isDeliverableAddress}
          />
        ) : (
          <Button
            className={loading && 'is-loading'}
            type="button"
            name="Update"
            width={3}
            backgroundColor="primaryColor"
            textProps={{
              color: loading ? 'primaryColor' : colors.white,
              weight: 'semibold',
              size: 'small',
            }}
            style={{ width: '100%' }}
            onClick={() => {
              handleSubmit();
            }}
          />
        )}
      </Footer>
    </>
  );
};

export default DeliveryAddressForm;
