import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import PhotoPreviewModal from '../../../PlacePhotos/PhotoPreviewModal';
import { getApiImages } from '../../../../utils/reusableQuery';
import { Image, Button, Icon, FlexRows } from '../../../../components/elements';
import blankImage from '../../../../assets/images/blank.png';
import { getEncodedImage } from '../../../../utils/s3';
import config from '../../../../utils/config';

const Figure = styled.figure`
  &&& {
    .figure-img {
      opacity: 0.5;
    }
    img {
      padding: 0.5em;
      border-radius: 12px;
    }
  }
`;

const AddPhotoWrapper = styled.div`
  position: relative;
  right: 2rem;
  float: right;
  bottom: 4rem;
`;

const FigureEmpty = styled.figure`
  &&& {
    padding-top: 20%;
    opacity: 0.5;
    img {
      object-fit: cover;
    }
  }
`;

const ButtonWrapper = styled(FlexRows)`
  padding: 0rem 1rem;
`;

const ImageView = ({ history, place }) => {
  const [images, setImage] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [preViewImage, setPreViewImage] = useState({});
  const [whichImage, setWhichImage] = useState(0);

  useEffect(() => {
    const get = async () => {
      const data = await getApiImages({
        object_id: place.place_id,
        object_type: ['PLACE'],
        parent_id: place.place_id,
        parent_type: ['PLACE'],
        links_to_id: [place.place_id],
        links_to_type: ['PLACE'],
      });
      setImage(data);
    };
    get();
  }, [place.place_id]);

  if (images === null) {
    return (
      <FigureEmpty className="image is-16by9">
        <Image source={blankImage} objectFit="contain" />
      </FigureEmpty>
    );
  }

  if (images.length === 0) {
    return (
      <>
        <FigureEmpty className="image is-16by9">
          <Image source={config.dummyImage} objectFit="contain" />
        </FigureEmpty>
        <AddPhotoWrapper>
          <Button
            name="Add Photo"
            icon="photoAddSolid"
            textProps={{ size: 'micro' }}
            borderRadius={20}
            onClick={() => history.push(`/add-place-photos/${place.place_id}`)}
          >
            <span className="icon is-small">
              <Icon name="photoAddSolid" />
            </span>
          </Button>
        </AddPhotoWrapper>
      </>
    );
  }

  if (images.length === 1) {
    return (
      <div className="columns is-mobile is-vcentered">
        {images.map((image, index) => (
          <div className="column is-3" key={image.url}>
            <a
              key={image.url}
              onDoubleClick={() => {
                setModalActive(true);
                setPreViewImage(image);
                setWhichImage(index);
              }}
            >
              <Figure className="image is-5by4">
                <Image source={getEncodedImage(image.url, 344, 275)} objectFit="contain" />
              </Figure>
            </a>
          </div>
        ))}

        <div className="column is-3">
          <Button
            name="Add Photo"
            height={0}
            icon="photoAddSolid"
            textProps={{ size: 'micro' }}
            borderRadius={20}
            onClick={() => history.push(`/add-place-photos/${place.place_id}`)}
          >
            <span className="icon is-small">
              <Icon name="photoAddSolid" />
            </span>
          </Button>
        </div>
        {modalActive && (
          <PhotoPreviewModal
            whichImage={whichImage}
            place={place}
            isActive={modalActive}
            images={images}
            preViewImage={preViewImage}
            isClose={() => setModalActive(false)}
            onSliderChange={value => {
              setPreViewImage(images[value]);
            }}
          />
        )}
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    // centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: null,
    prevArrow: null,
  };

  return (
    <>
      <Slider {...settings}>
        {images.map((image, index) => (
          <a
            key={image.url}
            onDoubleClick={() => {
              setModalActive(true);
              setPreViewImage(image);
              setWhichImage(index);
            }}
          >
            <Figure className="image is-5by4">
              <Image source={getEncodedImage(image.url, 344, 275)} objectFit="contain" />
            </Figure>
          </a>
        ))}
      </Slider>
      <ButtonWrapper justify="space-between">
        <Button
          height={0}
          name="Add Photo"
          icon="photoAddSolid"
          textProps={{ size: 'micro' }}
          borderRadius={20}
          onClick={() => history.push(`/add-place-photos/${place.place_id}`)}
        >
          <span className="icon">
            <Icon name="photoAddSolid" size={1} />
          </span>
        </Button>
        <Button
          height={0}
          onClick={() => history.push(`/place-photos/${place.place_id}`)}
          name="See all Photo"
          icon="photoAddSolid"
          borderRadius={20}
          textProps={{ size: 'micro' }}
        />
      </ButtonWrapper>
      {modalActive && (
        <PhotoPreviewModal
          whichImage={whichImage}
          place={place}
          isActive={modalActive}
          images={images}
          preViewImage={preViewImage}
          isClose={() => setModalActive(false)}
          onSliderChange={value => {
            setPreViewImage(images[value]);
          }}
        />
      )}
    </>
  );
};

export default withRouter(ImageView);
