/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { isNull, trim } from 'lodash';
import uuid from 'uuid';
import * as yup from 'yup';

import { Flex, Text, Field, FlexRows } from '../../../components/elements';
// import PlaceDataCorrections from '../PlaceDataCorrections';
import MobileSearchLocationModal from '../../../components/global/mobile/MobileSearchLocationModal';

const Container = styled.div`
  padding: 0rem 1rem;
  background: transparent;
`;
const FieldWrapper = styled.div`
  padding: 1rem 0.5rem;
  background: transparent;
`;

const Wrapper = styled.div`
  border: 2px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  background: ${({ theme: { colors } }) => colors.lightGreyAlpha};
  .input {
    background: transparent !important;
  }
`;

const FieldStyle = styled(Field).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;

const FooterButton = styled.a`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.8rem 1rem;
  position: fixed;
  bottom: 0;
`;

const AddressForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
  loading,
  isUpdateFormType,
}) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  return (
    <>
      <MobileSearchLocationModal
        isActive={isActiveModal}
        onClose={() => setIsActiveModal(false)}
        onSelect={place => {
          setFieldValue('address_line_1', trim(place.addressLine1));
          setFieldValue('city', place.city);
          setFieldValue('post_code', place.postcode);
          setFieldValue('state', place.state);
          setFieldValue('country', place.country || 'AUSTRALIA');
          setFieldValue('timezone', place.timezone || '+10');
          setFieldValue('latitude', place.latitude);
          setFieldValue('longitude', place.longitude);
        }}
      />
      <Container>
        <FieldWrapper>
          <FieldStyle
            name="type"
            label="Address Type"
            placeholder="Home , Office , Beach House"
            value={values.type}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.type && touched.type && <div className="has-text-danger">{errors.type}</div>}
        </FieldWrapper>
        <FieldWrapper>
          <FieldStyle
            label="Name"
            placeholder="Enter"
            name="contact_name"
            value={values.contact_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.contact_name && touched.contact_name && (
            <div className="has-text-danger">{errors.name}</div>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <FieldStyle
            label="Look up Address"
            placeholder="Start Typing Address"
            onFocus={() => setIsActiveModal(true)}
          />
          {errors.lookUpAddress && touched.lookUpAddress && (
            <div className="has-text-danger">{errors.lookUpAddress}</div>
          )}
        </FieldWrapper>
        <Wrapper>
          <FieldWrapper>
            <FieldStyle
              id="address_line_1"
              name="address_line_1"
              label=" Address Line 1"
              placeholder="Enter"
              value={values.address_line_1}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.address_line_1 && touched.address_line_1 && (
              <div className="has-text-danger">{errors.address_line_1}</div>
            )}
          </FieldWrapper>

          <FieldWrapper>
            <FieldStyle
              name="address_line_2"
              label=" Address Line 2"
              placeholder="Enter"
              value={values.address_line_2}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.address_line_2 && touched.address_line_2 && (
              <div className="has-text-danger">{errors.address_line_2}</div>
            )}
          </FieldWrapper>
          <FlexRows justify="space-between" align="flex-start">
            <FieldWrapper>
              <FieldStyle
                id="suburb"
                name="suburb"
                label="Suburb"
                placeholder="Enter"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.city && touched.city && <div className="has-text-danger">{errors.city}</div>}
            </FieldWrapper>
            <FieldWrapper>
              <FieldStyle
                name="state"
                label="State"
                placeholder="select"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.state && touched.state && (
                <div className="has-text-danger">{errors.state}</div>
              )}
            </FieldWrapper>
          </FlexRows>
          <FlexRows justify="space-between" align="flex-start">
            <FieldWrapper>
              <FieldStyle
                id="postcode"
                name="postcode"
                label="Post Code"
                placeholder="Enter"
                value={values.post_code}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.post_code && touched.post_code && (
                <div className="has-text-danger">{errors.post_code}</div>
              )}
            </FieldWrapper>
            <FieldWrapper>
              <FieldStyle
                id="country"
                name="country"
                label="Country"
                placeholder="select"
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.country && touched.country && (
                <div className="has-text-danger">{errors.country}</div>
              )}
            </FieldWrapper>
          </FlexRows>
        </Wrapper>
      </Container>

      <a onClick={handleSubmit}>
        <FooterButton>
          <Flex>
            <Text
              className="is-uppercase"
              color="white"
              align="center"
              weight="bold"
              size="medium"
              letterSpacing="loose"
            >
              {loading ? 'Loading...' : isUpdateFormType ? 'UPDATE' : 'ADD'}
            </Text>
          </Flex>
        </FooterButton>
      </a>
    </>
  );
};

const MyEnhancedForm = withFormik({
  mapPropsToValues: ({ address, isUpdateFormType }) => ({
    address_id: address && !isNull(address.address_id) ? address.address_id : uuid(),
    is_default: address && !isNull(address.is_default) ? address.is_default : !isUpdateFormType,
    type: address && !isNull(address.type) ? address.type : '',
    contact_name: address && !isNull(address.contact_name) ? address.contact_name : '',
    contact_phone: address && !isNull(address.contact_phone) ? address.contact_phone : '',
    address_line_1: address && !isNull(address.address_line_1) ? address.address_line_1 : '',
    address_line_2: address && !isNull(address.address_line_2) ? address.address_line_2 : '',
    city: address && !isNull(address.city) ? address.city : '',
    post_code: address && !isNull(address.post_code) ? address.post_code : '',
    state: address && !isNull(address.state) ? address.state : '',
    country: address && !isNull(address.country) ? address.country : '',
    latitude: address && !isNull(address.latitude) ? address.latitude : '',
    longitude: address && !isNull(address.longitude) ? address.longitude : '',
    timezone: address && !isNull(address.timezone) ? address.timezone : '',
    status: address && !isNull(address.status) ? address.status : 'ACTIVE',
    additionalInfo: '',
  }),

  // Custom sync validation

  validationSchema: yup.object().shape({
    type: yup.string().required('Address type is required'),
    contact_name: yup.string().required('Full name is required'),
    address_line_1: yup.string().required('Street Address is required'),
    city: yup.string().required('City is required'),
    post_code: yup.string().required('Postcode is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'ADDRESSERS',
})(AddressForm);

export default MyEnhancedForm;
