import React from 'react';
import styled from 'styled-components';

import { FlexRows, FlexColumns, Text } from '../../../../components/elements';

const Wrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha};
  padding: 1rem 4rem;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;

const Item = styled(FlexColumns)`
  height: 5rem;
  width: 10rem;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 2px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  border-radius: 4px;
`;

const Elements = ({ user }) => {
  const items = [
    { name: 'Badges', iconName: 'myBadgesBlue', count: user.myBadgesBlueCount },
    {
      name: 'Kravien Points',
      iconName: 'rewardPointsBlue',
      count: user.rewardPointsBlueCount,
    },
    { name: 'Liked Places', iconName: 'likeStarBlue', count: user.likeCount },
    { name: 'Loved Items', iconName: 'itemRatingGoldSolid', count: user.itemCount },
    { name: 'Photos', iconName: 'Photos', count: user.photosCount },
    { name: 'Shares', iconName: 'Share', count: user.shareCount },
    { name: 'Reviews', iconName: 'Reviews', count: user.reviewCount },
  ];

  return (
    <Wrapper>
      <FlexRows justify="space-between">
        {items.map(item => (
          <Item key={item.name}>
            <Text color="darkGrey" weight="semibold" size="small" paddingBottom={0.5}>
              {item.count}
            </Text>

            {/* <IconWrapper>
            <Icon name={item.iconName} size={4} />
          </IconWrapper> */}
            <Text color="darkGrey" weight="semibold" size="small">
              {item.name}
            </Text>
          </Item>
        ))}
      </FlexRows>
    </Wrapper>
  );
};

export default Elements;
