import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { forEach, omit } from 'lodash';
import RatingInput from './RatingInput';

import Images from './Images';
import { Text, Button, InputErrorMessage, TagInputs, TextArea } from '../../components/elements';
import { getPlaceTypes, getCuisines, getItemTypes } from '../../utils/fetchStatic';

const ImageUploadWrapper = styled.div`
  padding: 1rem;
`;
const Span = styled.span`
  color: red;
`;

const FormStyle = styled.div`
  padding: 2rem 0rem;
`;

const Header = styled.div`
  padding-bottom: 1rem;
`;

const Label = styled(Text).attrs({ color: 'darkGrey', size: 'medium', weight: 'semibold' })``;

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const parseInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};
const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const handleImageSubmissionWhileUploading = image => {
  let isDisabled = false;
  image.forEach(element => {
    if (element.uploadingStatus === 'uploading') {
      isDisabled = true;
    }
  });
  return isDisabled;
};

const Form = props => {
  const [data, setData] = useState([{ businessTypes: [], itemTypes: [], cuisines: [] }]);
  const {
    loading,
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    history,
  } = props;
  const handleOnClick = async () => {
    await setFieldValue('isDraft', false);
    handleSubmit();
  };

  const handleOnClickDraft = async () => {
    await setFieldValue('isDraft', true);
    handleSubmit();
  };
  useEffect(() => {
    const getData = async () => {
      const businessTypes = await getPlaceTypes();
      const itemTypes = await getItemTypes();
      const cuisines = await getCuisines();

      setData({
        businessTypes,
        itemTypes,
        cuisines,
      });
    };
    getData();
  }, []);

  return (
    <FormStyle>
      <Header>
        <Text size="large" weight="semibold" paddingBottom={0.5}>
          Share this item
        </Text>
      </Header>
      <div className="columns">
        <div className="column is-7">
          <div className="columns is-gapless is-vcentered">
            <div className="column">
              <Label>
                How was it ?<Span>*</Span>
              </Label>
            </div>
            <div className="column ">
              <RatingInput
                initialRating={values.overall_rating}
                handleChange={value => setFieldValue('overall_rating', value)}
              />
              <InputErrorMessage touched={touched.overall_rating} errors={errors.overall_rating} />
            </div>
          </div>

          <div className="field">
            <Label paddingBottom={0.5}>
              Enter your thoughts here<Span>*</Span> ( Min 50 and Max 1500 characters )
            </Label>
            <div className="control">
              <TextArea
                className="textarea"
                name="content"
                value={values.content}
                // eslint-disable-next-line max-len
                placeholder="What do you like about this item? Say something such as appearance, tasting notes etc. Use @ to mention someone or use # to mention a dish, item, cuisines, taste, etc"
                onChange={handleChange}
                // onBlur={handleBlur}
              />
              <Text color="darkGrey" size="micro">
                Characters Left: {values.content.length} / 1500
              </Text>
              <InputErrorMessage touched={touched.content} errors={errors.content} />
              <br />
            </div>
          </div>
          <div className="columns">
            <div className="column is-half">
              <TagInputs
                label={<Label paddingBottom={0.5}>Item Type</Label>}
                value={parseData(values.item_type)}
                suggestions={parseData(data.itemTypes)}
                onChange={value => setFieldValue('item_type', parseInput(value))}
              />
            </div>
            <div className="column is-half">
              <TagInputs
                label={<Label paddingBottom={0.5}>Cuisines</Label>}
                value={parseData(values.cuisine_style)}
                suggestions={parseData(data.cuisines)}
                onChange={value => setFieldValue('cuisine_style', parseInput(value))}
              />
            </div>
          </div>
        </div>
        <div className="column is-4">
          <ImageUploadWrapper>
            <Label>
              Add Photos
              <Span>*</Span>
            </Label>
            <Text size="tiny" color="darkGrey">
              Drag and drop or upload upto 4 images. Formats accepted - &quot;.png, .jpg,
              .jpeg&quot; <br />
              Maximum size accepted - 10 MB
            </Text>
            <br />
          </ImageUploadWrapper>
          <Images values={values} setFieldValue={setFieldValue} />
          <InputErrorMessage touched={touched.image} errors={errors.image} />
        </div>
      </div>
      {/* <div className="level">
        <div className="level-left">
          <div className="level-item">
            <Text>Share On</Text>
          </div>
          <div className="level-item">
            <CheckBox label="Facebook" onChange={() => {}} />
          </div>

          <div className="level-item">
            <CheckBox label="Twitter" onChange={() => {}} />
          </div>

          <div className="level-left">
            <CheckBox label="Instagram" onChange={() => {}} />
          </div>
        </div>
      </div>
      <br /> */}
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <Button
              name="Back"
              type="reset"
              backgroundColor="primaryColor"
              textProps={{ color: 'white', weight: 'semibold' }}
              onClick={() => history.goBack()}
            />
          </div>
          <div className="level-item">
            <Button
              name="Save Draft"
              type="submit"
              backgroundColor="primaryColor"
              className={values.isDraft && loading && 'is-loading'}
              textProps={{
                color: values.isDraft && loading ? 'primaryColor' : 'white',
                weight: 'semibold',
              }}
              onClick={handleOnClickDraft}
              disabled={handleImageSubmissionWhileUploading(values.image)}
            />
          </div>
        </div>
        <div className="level-right">
          <Button
            type="submit"
            className={!values.isDraft && loading && 'is-loading'}
            name="Post Review"
            backgroundColor="primaryColor"
            textProps={{
              color: !values.isDraft && loading ? 'primaryColor' : 'white',
              weight: 'semibold',
            }}
            onClick={handleOnClick}
            disabled={handleImageSubmissionWhileUploading(values.image)}
          />
        </div>
      </nav>
    </FormStyle>
  );
};

const DeskForm = withFormik({
  mapPropsToValues: ({ itemReview, uploadedImages }) => ({
    isDraft: false,
    item_name: '',
    item_type: itemReview ? removeTypename(itemReview.item_type) : [],
    cuisine_style: itemReview ? removeTypename(itemReview.cuisine_style) : [],
    overall_rating: itemReview ? itemReview.rating : 0,
    content: itemReview ? itemReview.content : '',
    uploadedImages,
    image: [
      {
        url: '',
        preview: null,
        tooltip: '',
        link: '',
        tag: [],
        uploadingStatus: 'pending',
      },
    ],
  }),

  validate: values => {
    const errors = {};

    if (values.image.length + values.uploadedImages.length < 2) {
      errors.image = 'Please upload at least one image';
    }

    return errors;
  },

  validationSchema: yup.object().shape({
    content: yup
      .string()
      .min(50, 'At least 50 Characters Required!')
      .max(1500, 'Not more than 1500 Characters!')
      .required('Review Content is required!'),
    overall_rating: yup
      .number()
      .moreThan(0, 'Rating is required!')
      .required('Rating is required!'),
    // image: yup
    //   .array()
    //   .min(2, 'Please upload at least one image')
    //   .required('Please upload at least one image'),
  }),

  handleSubmit: (values, { props }) => {
    const { isLoggedIn, setIsActiveDesktopLoginModal } = props;
    if (!isLoggedIn) {
      setIsActiveDesktopLoginModal({
        value: true,
        path: '',
        callBackFunction: () => props.onSubmit(values),
      });
    } else {
      props.onSubmit(values);
    }
  },

  displayName: 'Item Review PostForm',
})(Form);

export default DeskForm;
