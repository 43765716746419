import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdStar } from 'react-icons/md';
import { FlexRows } from '../../components/elements';
import colors from '../../theme/styles/colors';

const Icon = styled(MdStar)`
  padding: ${({ padding }) => `${padding}px`};
`;

const RatingInput = ({ handleChange, initialValue, padding, iconSize }) => {
  const [rating, setRating] = React.useState(initialValue || 0);
  return (
    <>
      <FlexRows align="flex-start">
        <a
          onClick={() => {
            setRating(1);
            handleChange(1);
          }}
        >
          <Icon
            iconName="fas fa-star"
            color={rating < 1 ? colors.specialGrey : colors.tertiaryColor}
            size={iconSize}
            type="button"
            id="rating_1"
            padding={padding}
          />
        </a>
        <a
          onClick={() => {
            setRating(2);
            handleChange(2);
          }}
        >
          <Icon
            iconName="fas fa-star"
            color={rating < 2 ? colors.specialGrey : colors.tertiaryColor}
            size={iconSize}
            type="button"
            id="rating_2"
            padding={padding}
          />
        </a>
        <a
          onClick={() => {
            setRating(3);
            handleChange(3);
          }}
        >
          <Icon
            iconName="fas fa-star"
            color={rating < 3 ? colors.specialGrey : colors.tertiaryColor}
            size={iconSize}
            type="button"
            id="rating_3"
            padding={padding}
          />
        </a>
        <a
          onClick={() => {
            setRating(4);
            handleChange(4);
          }}
        >
          <Icon
            iconName="fas fa-star"
            color={rating < 4 ? colors.specialGrey : colors.tertiaryColor}
            size={iconSize}
            type="button"
            id="rating_4"
            padding={padding}
          />
        </a>
        <a
          onClick={() => {
            setRating(5);
            handleChange(5);
          }}
        >
          <Icon
            iconName="fas fa-star"
            color={rating < 5 ? colors.specialGrey : colors.tertiaryColor}
            size={iconSize}
            type="button"
            id="rating_5"
            padding={padding}
          />
        </a>
      </FlexRows>
    </>
  );
};

RatingInput.defaultProps = {
  padding: 4,
  iconSize: 60,
};

RatingInput.propTypes = {
  padding: PropTypes.number,
  iconSize: PropTypes.number,
};

export default RatingInput;
