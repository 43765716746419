import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { first, truncate } from 'lodash';
import styled from 'styled-components';
import { MdClear } from 'react-icons/md';
import { FlexRows, Text, Avatar } from '../../../../components/elements';
import client from '../../../../utils/apolloClient';

const Container = styled.div`
  && {
    .dropdown-menu {
      min-width: 18rem;
      z-index: 99;
    }
  }
`;

const Header = styled(FlexRows)`
  padding: 0rem 1rem;
  padding-bottom: 0.5rem;
`;

const ScrollView = styled.div`
  max-height: 17rem;
  overflow: scroll;
`;

const searchUsersQuery = gql`
  query search_place_deal($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        profile_image_url
        color_code
        slug
      }
    }
  }
`;

const LikeUserPopup = ({ isActive, totalLike, onClick, likeUsers, onClose, userId }) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    client.clientPublic
      .query({
        query: searchUsersQuery,
        variables: {
          input: { filter: { user_filter: { user_id: likeUsers } } },
        },
      })
      .then(({ data }) => {
        if (data) {
          setUsers(data.search_users.user_listing);
        }
      });
  }, [likeUsers]);

  const currentUser = users.filter(item => item.user_id === userId);
  const restUser = users.filter(item => item.user_id !== userId);
  return (
    <Container className={`dropdown ${isActive && 'is-active'}`}>
      <div className="dropdown-trigger">
        <a aria-haspopup="true" aria-controls="dropdown-menu2" onClick={onClick}>
          <Text size="smaller" color="primaryColor" weight="semibold">
            {totalLike > 1 && `${totalLike} likes`}
            {totalLike === 1 && `${totalLike} like`}
          </Text>
        </a>
      </div>
      <div className="dropdown-menu" id="dropdown-menu2" role="menu">
        <div className="dropdown-content">
          <Header justify="space-between">
            <Text color="primaryColor" size="smaller" weight="semibold">
              {totalLike === 1 ? 'Like' : 'Likes'} ({totalLike})
            </Text>
            <a onClick={onClose}>
              <Text color="coolGrey">
                <MdClear size={20} />
              </Text>
            </a>
          </Header>
          <ScrollView>
            {currentUser.length !== 0 && (
              <div className="dropdown-item">
                <FlexRows justify="flex-start">
                  <Avatar
                    name={first(currentUser).display_name}
                    maskProps={{ width: 40 }}
                    maskTextProps={{
                      color: 'white',
                      weight: 'bold',
                      size: 'small',
                      letterSpacing: 'loose',
                    }}
                    borderRadius={25}
                    color={first(currentUser).color_code}
                    image={first(currentUser).profile_image_url}
                  />
                  &nbsp; &nbsp;
                  <div className="content">
                    <Text size="smaller">You</Text>
                  </div>
                </FlexRows>
              </div>
            )}
            {restUser.map(user => (
              <div className="dropdown-item" key={user.user_id}>
                <FlexRows justify="flex-start">
                  <Avatar
                    name={user && user.display_name}
                    maskProps={{ width: 40 }}
                    maskTextProps={{
                      color: 'white',
                      weight: 'bold',
                      size: 'small',
                      letterSpacing: 'loose',
                    }}
                    borderRadius={25}
                    color={user && user.color_code}
                    image={user && user.profile_image_url}
                  />
                  &nbsp; &nbsp;
                  <div className="content">
                    <Text size="smaller">{truncate(user.display_name, { length: 25 })}</Text>
                  </div>
                </FlexRows>
              </div>
            ))}
          </ScrollView>
        </div>
      </div>
    </Container>
  );
};
export default LikeUserPopup;
