import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { Flex, Text, Field, SelectField, TextArea } from '../../components/elements';
import { trackEvent } from '../../utils/analytics';

const Container = styled.div`
  padding: 1rem;
`;
const FieldWrapper = styled.div`
  padding: 1rem 0rem;
`;

const FieldStyle = styled(Field).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;

const SelectFieldStyle = styled(SelectField).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    color: 'primaryColor',
    size: 'small',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;

const FooterButton = styled.a`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.8rem 1rem;
  position: fixed;
  bottom: 0;
`;

const MobileForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  loading,
}) => (
  <>
    <Container>
      <FieldWrapper>
        <SelectFieldStyle
          id="feedback_type"
          name="feedback_type"
          label=" Feedback Type"
          options={['Other', 'performance']}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.feedback_type && touched.feedback_type && (
          <div className="has-text-danger">{errors.feedback_type}</div>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <label className="level">
          <Text className="is-uppercase" color="primaryColor" weight="bold" letterSpacing="loose">
            Description
          </Text>
        </label>
        <TextArea
          id="description"
          name="description"
          placeholder="Description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.description && touched.description && (
          <div className="has-text-danger">{errors.description}</div>
        )}
      </FieldWrapper>
    </Container>
    <FooterButton onClick={handleSubmit}>
      <Flex>
        <Text
          className="is-uppercase"
          color="white"
          align="center"
          weight="bold"
          size="medium"
          letterSpacing="loose"
        >
          {loading ? 'Loading...' : 'Submit'}
        </Text>
      </Flex>
    </FooterButton>
  </>
);
const EnhancedFeedbackForm = withFormik({
  mapPropsToValues: () => ({
    description: '',
    feedback_type: 'Other',
  }),

  // Custom sync validation
  validate: values => {
    const errors = {};
    if (!values.feedback_type) {
      errors.feedback_type = 'Required';
    }
    if (!values.description) {
      errors.description = 'Required';
    }
    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    console.log('valueeeeeeeeeeee', values);
    props.onSubmit(values);
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  },

  displayName: 'FeedbackForm',
})(MobileForm);

export default EnhancedFeedbackForm;
