import React from 'react';

import LoadingOverlay from 'react-loading-overlay';
import styled from 'styled-components';

const StyledLoader = styled(LoadingOverlay)`
  && {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    ._loading_overlay_overlay {
      position: fixed;
      background: rgba(13, 13, 13, 0.5);
    }
  }
`;

const MyLoadingOverlay = ({ isActive, text, content }) => (
  <StyledLoader
    active={isActive}
    spinner
    text={text}
    styles={{
      spinner: base => ({
        ...base,
        '& svg circle': {
          stroke: 'rgba(44, 86, 151, 1)',
          strokeWidth: '4px',
        },
      }),
    }}
  >
    <p>{content}</p>
  </StyledLoader>
);

export default MyLoadingOverlay;
