import React from 'react';
import Slider from 'react-slick';

import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CarouselStyle = styled(Slider)`
  &&& {
    .slick-dots {
      bottom: 2rem;
    }
    .slick-dots li.slick-active button:before {
      color: ${({ theme: { colors } }) => colors.white};
      font-size: 12px;
      opacity: 1;
    }
    .slick-dots li button:before {
      color: ${({ theme: { colors } }) => colors.white};
      opacity: 0.6;
      font-size: 12px;
    }
  }
`;

const Carousel = ({ children, ...props }) => {
  const settings = {
    ...props,
  };
  return <CarouselStyle {...settings}>{children}</CarouselStyle>;
};

export default Carousel;
