import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import Tags from './Tags';

import {
  MobileInput,
  Text,
  Field,
  CheckBox,
  TextArea,
  Button,
  InputErrorMessage,
} from '../../../components/elements';

const FieldStyle = styled(Field).attrs({ labelTextProps: { color: 'dark', label: 'small' } })``;
const Label = styled(Text).attrs({})``;

const Span = styled.span`
  color: red;
`;

const SubHeading = styled(Text)`
  span {
    color: ${({ theme: { colors: themeColor } }) => themeColor.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
  }
`;

const LocationForm = props => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    loading,
    setFieldValue,
    touched,
    errors,
  } = props;
  return (
    <>
      <div className="columns">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>
              Place name<Span>*</Span>
            </Label>
          </div>
          <FieldStyle name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
          <InputErrorMessage touched={touched.name} errors={errors.name} />
        </div>
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>
              Street Address<Span>*</Span>
            </Label>
          </div>
          <FieldStyle
            name="address"
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputErrorMessage touched={touched.address} errors={errors.address} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>
              City/Suburb<Span>*</Span>
            </Label>
          </div>
          <FieldStyle name="city" value={values.city} onChange={handleChange} onBlur={handleBlur} />
          <InputErrorMessage touched={touched.city} errors={errors.city} />
        </div>
        <div className="column is-3 is-vcentered">
          <div className="field">
            <Label>
              State<Span>*</Span>
            </Label>
          </div>
          <FieldStyle
            name="state"
            value={values.state}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputErrorMessage touched={touched.state} errors={errors.state} />
        </div>
        <div className="column is-2 is-vcentered">
          <div className="field">
            <Label>
              PostCode<Span>*</Span>
            </Label>
          </div>
          <FieldStyle
            name="postcode"
            value={values.postcode}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputErrorMessage touched={touched.postcode} errors={errors.postcode} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>
              Website Address<Span>*</Span>
            </Label>
          </div>
          <FieldStyle
            name="website"
            value={values.website}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputErrorMessage touched={touched.website} errors={errors.website} />
        </div>
        <div className="column is-3 is-vcentered">
          <div className="field">
            <Label>Phone</Label>
          </div>
          <MobileInput
            name="mobile"
            value={values.mobile}
            onChange={mobile => setFieldValue('mobile', mobile)}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <br />
      <SubHeading size="medium" weight="regular">
        <div className="is-flex">
          <CheckBox /> Place Edit Details
        </div>
      </SubHeading>
      <br />
      <Tags {...props} />
      <div className="columns">
        <div className="column is-one-third">
          <SubHeading size="medium" weight="regular" paddingBottom={0.5}>
            <div className="is-flex">
              <CheckBox /> Place Open and Closed time edits
            </div>
          </SubHeading>
          <TextArea
            name="placeOpenClose"
            value={values.placeOpenClose}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="column is-one-third">
          <SubHeading size="medium" weight="regular" paddingBottom={0.5}>
            <div className="is-flex">
              <CheckBox /> Menu Edits
            </div>
          </SubHeading>
          <TextArea
            name="menuEdits"
            value={values.menuEdits}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-two-thirds">
          <Text weight="regular" size="medium" paddingBottom={0.5}>
            Other Details
          </Text>
          <textarea
            className="textarea"
            rows="3"
            name="details"
            value={values.details}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <Button
              className="button "
              name="Cancel"
              backgroundColor="waterMarkGrey"
              textProps={{ color: 'white', weight: 'semibold' }}
              // onClick={handleSubmit}
            />
          </div>
        </div>
        <div className="is-flex">
          <div className="level-item">
            <Button
              className={loading && 'is-loading'}
              name="Submit"
              backgroundColor="primaryColor"
              textProps={{ color: 'white', weight: 'semibold' }}
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Form = withFormik({
  mapPropsToValues: () => ({
    name: '',
    address: '',
    city: '',
    state: '',
    postcode: '',
    website: '',
    mobile: '',
    details: '',
    menuEdits: '',
    placeOpenClose: '',
  }),

  validationSchema: yup.object().shape({
    name: yup.string().required('Place name is required!'),
    address: yup.string().required('Street Address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    postcode: yup.string().required('Postcode is required'),
    website: yup.string().url('Enter valid url!'),
    mobile: yup.string().required('Mobile contact is required!'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    console.log('values', values);
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  },
  displayName: 'LocationForm',
})(LocationForm);

export default Form;
