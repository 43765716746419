import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PageHeader, FlexRows, Text, Icon } from '../../components/elements';
import { trackEvent } from '../../utils/analytics';
const profileItem = [
  {
    name: 'Visible to all',
    key: 'all',
  },
  {
    name: 'Visible to friends',
    key: '/settings/social-sharing',
  },
  {
    name: 'Visible to none',
    key: '/settings/location',
  },
];
const ProfileItem = styled(FlexRows).attrs({ justify: 'flex-start' })`
  background: ${({ theme: { colors } }) => colors.white};
  padding: 1rem;

  margin: 0.5rem 0rem;
`;
const Flex = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.specialGrey};
`;
const ProfileSubItem = ({ title, isActive, onSelect }) => (
  <Flex onClick={onSelect}>
    <div style={{ paddingLeft: '8%' }}>
      <Text size="tiny">{title}</Text>
    </div>
    <div className="is-flex">
      {isActive && <Icon iconName="fas fa-check" color="primaryColor" />}
    </div>
  </Flex>
);

const IconWrapper = styled.div`
  width: 10%;
`;
const TextWrapper = styled.div`
  width: 60%;
`;
const ProfileSettings = () => (
  <React.Fragment>
    {
      <React.Fragment>
        <ProfileItem>
          <IconWrapper>
            <Icon name="profileSettings" size={2} />
          </IconWrapper>
          <TextWrapper>
            <Text style={{ paddingLeft: '17%' }} className="is-capitalized" align="left">
              Profile Settings
            </Text>
          </TextWrapper>
        </ProfileItem>
        <div>
          {profileItem.map(item => (
            <ProfileSubItem
              key={item.key}
              title={item.name}
              isActive={item.key === 'sortBy'}
              onSelect={() => 'handleSortBy(item.key)'}
            />
          ))}
        </div>
      </React.Fragment>
    }
  </React.Fragment>
);

export default ProfileSettings;
