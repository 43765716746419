import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

import MobileForm from './MobileForm';
import MobileTop from './MobileTop';
import { PageHeader } from '../../components/elements';

const Container = styled.div``;

const MobileView = ({ history }) => {
  const isError = useStoreState(state => state.auth.isError);
  const isLoading = useStoreState(state => state.auth.loading);
  const clearError = useStoreActions(state => state.auth.clearError);
  const login = useStoreActions(state => state.auth.logIn);

  useEffect(() => {
    clearError();
    console.log('calling');
  }, [clearError]);

  return (
    <>
      <PageHeader text="Sign In" />
      <Container>
        <MobileTop />
        <MobileForm
          isLoading={isLoading}
          isError={isError}
          clearError={clearError}
          onSubmit={input => login({ ...input, history })}
        />
      </Container>
    </>
  );
};

export default withRouter(MobileView);
