import React from 'react';
import styled from 'styled-components';

import { FlexRows, Icon, Text } from '../../../../../components/elements';

const Container = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const TextStyle = styled(Text).attrs({ size: 'small' })`
  padding-left: 6px;
`;

const PlaceActivities = () => (
  <Container>
    <div className="columns">
      <div className="column">
        <FlexRows justify="flex-start">
          <Icon name="placeAroundMe" size={1} />
          <TextStyle color="primaryColor">Nasim</TextStyle>
          <TextStyle>reviewed and shared</TextStyle>
          <TextStyle color="primaryColor">Tandoori chappathi</TextStyle>
          <TextStyle>at</TextStyle>
          <TextStyle color="primaryColor">Flakes on Sydney Rd Pizzeria</TextStyle>
        </FlexRows>
      </div>
      <div className="column is-2">
        <Text size="small">10 hour ago</Text>
      </div>
    </div>
  </Container>
);

export default PlaceActivities;
