import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';

const ParentComponent = () => {
  return (
    <>
      <RectShape color="#f0f0f0" style={{ width: '100%', height: '35rem' }} />
    </>
  );
};

const CartLoader = () => {
  return (
    <ReactPlaceholder ready showLoadingAnimation>
      <ParentComponent />
    </ReactPlaceholder>
  );
};

export default CartLoader;
