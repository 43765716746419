import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { isNull, first } from 'lodash';

import {
  Field,
  CheckBox,
  FlexColumns,
  Text,
  FlexRows,
  Flex,
  TextArea,
  InputErrorMessage,
} from '../../components/elements';
import ProfilePhotoUpload from './ProfilePhotoUpload';

const Container = styled.div`
  padding: 1rem;
  padding-bottom: 4rem;
`;

const FieldWrapper = styled.div`
  padding: 0.5rem 0rem;
`;

const FieldStyle = styled(Field).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
    size: 'micro',
  },
})``;

const FieldStyleFull = styled(Field).attrs({
  borderBottomColor: 'transparent',
  labelTextProps: {
    className: 'is-uppercase',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
    size: 'micro',
  },
})``;

const Footer = styled(Flex)`
  padding: 0.6rem;
  position: fixed;
  width: 100%;
  bottom: 0;
  background: ${({ theme: { colors } }) => colors.primaryColor};
`;

const MobileForm = ({
  loading,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
  touched,
  errors,
  user,
  history,
}) => (
  <form
    onSubmit={event => {
      event.preventDefault();
      handleSubmit();
    }}
  >
    <Container>
      <FlexRows justify="space-between">
        <ProfilePhotoUpload user={user} />
        <FlexColumns>
          <FieldWrapper>
            <FieldStyle
              label="First Name"
              placeholder="John"
              name="first_name"
              value={values.first_name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputErrorMessage touched={touched.first_name} errors={errors.first_name} />
          </FieldWrapper>
          <FieldWrapper>
            <FieldStyle
              label="Last Name"
              placeholder="David"
              name="last_name"
              value={values.last_name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputErrorMessage touched={touched.last_name} errors={errors.last_name} />
          </FieldWrapper>
        </FlexColumns>
      </FlexRows>
      <br />
      <FieldWrapper>
        <FieldStyle
          label="Display Name"
          placeholder="john123"
          name="display_name"
          value={values.display_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <InputErrorMessage touched={touched.display_name} errors={errors.display_name} />
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyleFull label="User Handle" placeholder="john123" disabled />
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyleFull
          label="Email"
          placeholder="John@gmail.com"
          value={values.primaryEmail}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <InputErrorMessage touched={touched.primaryEmail} errors={errors.primaryEmail} />
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle label="Mobile" placeholder="+919056349046" />
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle label="Date of Birth" placeholder="12 March 1993" />
      </FieldWrapper>
      <FieldWrapper>
        <FlexRows justify="flex-start">
          <CheckBox
            label="Male"
            initialValue={values.gender === 'MALE'}
            onChange={value => {
              if (value) {
                setFieldValue('gender', 'MALE');
              }
            }}
            labelTextProps={{ weight: 'semibold', size: 'small' }}
          />
          &nbsp;&nbsp;
          <CheckBox
            label="Female"
            initialValue={values.gender === 'FEMALE'}
            onChange={value => {
              if (value) {
                setFieldValue('gender', 'FEMALE');
              }
            }}
            labelTextProps={{ weight: 'semibold', size: 'small' }}
          />
        </FlexRows>
      </FieldWrapper>
      <FieldWrapper>
        <div className="field">
          <div className="label">
            <Text
              className="is-uppercase"
              color="primaryColor"
              weight="bold"
              letterSpacing="loose"
              size="micro"
            >
              About me
            </Text>
          </div>
          <div className="control">
            <TextArea
              placeholder="A few word about you"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputErrorMessage touched={touched.description} errors={errors.description} />
          </div>
        </div>
      </FieldWrapper>
    </Container>
    <a onClick={handleSubmit}>
      <Footer>
        <Text className="is-uppercase" color="white" weight="bold" letterSpacing="loose">
          {loading ? 'Loading...' : 'Done'}
        </Text>
      </Footer>
    </a>
  </form>
);

const MyEnhancedForm = withFormik({
  mapPropsToValues: ({ user }) => {
    const primaryMobile = !isNull(user.contact)
      ? user.contact.filter(item => item.type === 'mobile' && item.is_primary === true)
      : [];

    const alternateMobile = !isNull(user.contact)
      ? user.contact.filter(item => item.type === 'mobile' && item.is_primary === false)
      : [];

    const alternateEmail = !isNull(user.contact)
      ? user.contact.filter(item => item.type === 'email' && item.is_primary === false)
      : [];

    const address = !isNull(user.address)
      ? user.address.filter(item => item.is_default === true)
      : [];

    return {
      user_id: user.user_id,
      primaryEmail: user.email,
      alternateEmail: alternateEmail.length !== 0 ? first(alternateEmail).value : '',
      primaryMobile: primaryMobile.length !== 0 ? first(primaryMobile).value : '',
      alternateMobile: alternateMobile.length !== 0 ? first(alternateMobile).value : '',
      first_name: user.first_name,
      last_name: user.last_name,
      preferred_username: user.preferred_username,
      display_name: user.display_name,
      date_of_birth: user.date_of_birth,
      gender: user.gender,
      description: user.description,
      location: '',
      color_code: user.color_code,
      address,
    };
  },

  validationSchema: yup.object().shape({
    first_name: yup.string().required('First name is required!'),
    last_name: yup.string().required('Last name is required!'),
    primaryEmail: yup
      .string()
      .email('Enter valid email address!')
      .required('Email address is required!'),
    alternateEmail: yup.string().email('Enter valid email address!'),
    // .required('Email address is required!'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
  },

  displayName: 'ContactUsForm',
})(MobileForm);

export default MyEnhancedForm;
