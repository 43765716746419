/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import { concat, uniqBy, first, reverse } from 'lodash';
import config from '../utils/config';
import { getAllExactEventFilter } from '../utils/eventHelpers';
import store from './index';

export default {
  global: {
    anonymousUserIdForCart: null,
    hideSubHeader: true,
    cartQuantity: 0,
    eventCurrentDateFilter: null,
    allEventDateFilter: [],
    currentLocation: config.defaultCurrentLocation,
    usedLocation: config.defaultCurrentLocation,
    isHandleCurrentLocation: true,
    recentViewLocations: [],
    setHideSubHeader: action((state, payload) => {
      state.hideSubHeader = payload;
    }),
    setAnonymousUserIdForCart: action((state, payload) => {
      state.anonymousUserIdForCart = payload;
    }),
    setCartQuantity: action((state, payload) => {
      state.cartQuantity = payload;
    }),
    setCurrentLocation: action((state, payload) => {
      state.currentLocation = payload;
    }),
    setUsedLocation: action((state, payload) => {
      state.usedLocation = payload;
    }),
    setEventCurrentDateFilter: action((state, payload) => {
      state.eventCurrentDateFilter = payload;
    }),
    setAllEventCurrentDateFilter: action((state, payload) => {
      state.allEventDateFilter = payload;
    }),
    setIsHandleCurrentLocation: action(state => {
      state.isHandleCurrentLocation = false;
    }),
    addRecentViewLocation: action((state, payload) => {
      const newRecentView = concat(state.recentViewLocations, payload);
      state.recentViewLocations = reverse(uniqBy(newRecentView, 'code'));
    }),
    updateCurrentLocation: thunk(async (actions, payload) => {
      const data = await getAllExactEventFilter(
        parseFloat(payload.latitude),
        parseFloat(payload.longitude),
      );

      actions.setEventCurrentDateFilter(first(data));
      actions.setAllEventCurrentDateFilter(data);
      actions.setIsHandleCurrentLocation();
      actions.setCurrentLocation(payload);
      actions.setUsedLocation(payload);
    }),
    updateEventFilterOnEveryCall: thunk(async (actions, payload) => {
      try {
        const { usedLocation, eventCurrentDateFilter } = payload;
        const data = await getAllExactEventFilter(
          parseFloat(usedLocation.latitude),
          parseFloat(usedLocation.longitude),
        );

        const eventFilter = eventCurrentDateFilter
          ? data.filter(item => item.name === eventCurrentDateFilter.name)
          : data;
        actions.setEventCurrentDateFilter(first(eventFilter));
        actions.setAllEventCurrentDateFilter(data);
      } catch (err) {
        console.log(err);
      }
    }),
    updateUsedLocation: thunk(async (actions, payload) => {
      try {
        const {
          place: { resetPlaceFilter },
          event: { resetEventFilter },
          item: { resetItemFilter },
        } = await store.getActions();
        await resetPlaceFilter();
        await resetEventFilter();
        await resetItemFilter();
        actions.setUsedLocation(payload);
        const data = await getAllExactEventFilter(
          parseFloat(payload.latitude),
          parseFloat(payload.longitude),
        );

        actions.setEventCurrentDateFilter(first(data));
        actions.setAllEventCurrentDateFilter(data);
      } catch (err) {
        console.log(err);
      }
    }),
  },
};
