import React from 'react';
import styled from 'styled-components';
import { MdLocationOn } from 'react-icons/md';

import { IoMdLocate } from 'react-icons/io';
import { FlexRows, Text } from '../../../components/elements';
import color from '../../../theme/styles/colors';

const TextWrapper = styled.div`
  padding-left: 1rem;
`;

const ItemWrapper = styled(FlexRows)`
  padding: 0.5rem 1rem;
`;

const Item = styled(ItemWrapper)`
  &&& {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  }
`;

const SuggestionItem = ({ title, subtitle, onClick, isEnableCurrent }) => (
  <a onClick={onClick}>
    <Item justify="flex-start" align="center">
      {isEnableCurrent ? (
        <IoMdLocate size={25} color={color.specialGrey} />
      ) : (
        <MdLocationOn size={25} color={color.specialGrey} />
      )}
      <TextWrapper>
        <Text
          className="is-uppercase"
          weight={isEnableCurrent ? 'semibold' : 'regular'}
          size="small"
        >
          {title}
        </Text>
        {subtitle && (
          <Text size="micro" color="darkGrey">
            {subtitle}
          </Text>
        )}
      </TextWrapper>
    </Item>
  </a>
);

export default SuggestionItem;
