import React, { useState } from 'react';
import gql from 'graphql-tag';
import { omit } from 'lodash';
import uuid from 'uuid';
import swal from 'sweetalert';

import { withRouter } from 'react-router-dom';
import { FlexRows } from '../../../components/elements';
import client from '../../../utils/apolloClient';
import { OrderFragments } from '../../../utils/fragments';

const createOrderMutation = gql`
  mutation createOrder($input: MenuOrderInput) {
    create_menu_order(input: $input) {
      ...OrderFragments
      error {
        code
        description
      }
    }
  }
  ${OrderFragments}
`;

const RepeatOrderAction = ({ order, userId, history }) => {
  const [loading, setLoading] = useState(false);

  const lineItems = order.line_item.map(edge => ({
    item_link_id: edge.item_link_id,
    menu_item_id: edge.menu_item_id,
    variant_id: edge.variant_id,
    prompt_id: edge.prompt_id,
    modifier: edge.modifier.map(edge2 => ({
      modifier_group_id: edge2.modifier_group_id,
      menu_item_id: edge2.menu_item_id,
      prompt_id: edge2.prompt_id,
      variant_id: edge2.variant_id,
      quantity: edge2.quantity,
    })),
    quantity: edge.quantity,
    menu_item_rating: edge.menu_item_rating,
    special_request: edge.special_request,
    user_id: edge.user_id,
    caption: edge.caption,
    private: edge.private,
  }));

  const handleAction = () => {
    setLoading(true);
    client.clientPublic
      .mutate({
        mutation: createOrderMutation,
        variables: {
          input: {
            menu_order_id: uuid(),
            user_id: userId,
            place_id: order.place_id,
            // service_type: order.service_type,
            service_type_setting_id: order.service_type_setting_id,
            menu_id: order.menu_id,
            address: omit(order.address, '__typename'),
            line_item: lineItems,
          },
        },
      })
      .then(({ data: { create_menu_order } }) => {
        if (create_menu_order.error) {
          create_menu_order.error.map(item2 => {
            if (item2.code === '801') {
              swal(
                'You have already existing cart',
                'Please clear the cart and try again',
              ).then(() => {});
            } else {
              swal(item2.description).then(() => {});
            }
          });
        } else {
          history.push('/checkout', { order: create_menu_order });
        }
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <FlexRows justify="space-between">
      <button className={`button ${loading && 'is-loading'}`} onClick={() => handleAction()}>
        Repeat Order
      </button>
    </FlexRows>
  );
};

export default withRouter(RepeatOrderAction);
