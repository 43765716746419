import React from 'react';
import styled from 'styled-components';

import MobileLanding from './MobileLanding';
import MobileHero from './MobileHero';
import TrendingItems from './TrendingItems';
import PlaceNearYou from './PlaceNearYou';
import AppStorePromo from './AppStorePromo';

import MobilePopularEvents from './MobilePopularEvents';

const MobileView = () => (
  <>
    <MobileHero />

    <MobileLanding />
    <TrendingItems />
    <PlaceNearYou />
    <MobilePopularEvents />
    <AppStorePromo />
  </>
);

export default MobileView;
