import React from 'react';
import styled from 'styled-components';

import ReactLoading from 'react-loading';
import Layout from './Layout';
import theme from '../../theme';

const Flex = styled.div`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 84vh;
  }
`;
const Loading = ({ layout }) => (
  <>
    {layout ? (
      <Layout>
        <div className="columns is-centered">
          <Flex className="column is-12">
            <ReactLoading type="spin" color={theme.primaryColor} width={50} height={50} />
          </Flex>
        </div>
      </Layout>
    ) : (
      <div className="columns is-centered">
        <Flex className="column is-12">
          <ReactLoading type="spin" color={theme.primaryColor} width={50} height={50} />
        </Flex>
      </div>
    )}
  </>
);

export default Loading;
