import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import swal from 'sweetalert';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';
import client from '../../utils/apolloClient';

const contactUsMutation = gql`
  mutation contact_customer($input: SupportInput) {
    contact_customer(input: $input) {
      id
      message
      status
    }
  }
`;

const ContactUs = ({ history, match }) => (
  <Layout history={history} match={match}>
    <Mutation
      client={client.clientPrivate}
      mutation={contactUsMutation}
      onError={() => swal('Something went wrong!', 'try after some time', 'error')}
      onCompleted={() =>
        swal('Thank you for contacting us !', ' We will reach you soon! 🙂 🙂 🙂 ', 'success').then(
          () => {
            history.push('/support-and-feedback');
          },
        )
      }
    >
      {(contact_customer, { loading }) => (
        <>
          <Analytics pageName="ContactUs" category="contactus" title="ContactUs-List">
            <div className="is-hidden-desktop">
              <MobileView
                loading={loading}
                onSubmit={input => {
                  contact_customer({ variables: { input } });
                }}
              />
            </div>
            <div className="is-hidden-touch">
              <DesktopView />
            </div>
          </Analytics>
        </>
      )}
    </Mutation>
  </Layout>
);

export default ContactUs;
