import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import uuid from 'uuid';
import { BsFillXCircleFill } from 'react-icons/bs';

import config from '../../../utils/config';
import { Text, FlexRows } from '../../../components/elements';
import Ingredient from './Ingredient';
import FavoriteAction from './FavoriteAction';
import OrderDetailsModal from './OrderDetailsModal';
import RepeatOrderAction from './RepeatOrderAction';

const Container = styled.div`
  border: 2px solid ${({ theme: { colors } }) => colors.borderColor};
  padding: 1rem;
  height: 100%;
  .btn-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Top = styled(FlexRows)`
  width: 100%;
  .button {
    border-color: ${({ theme: { colors } }) => colors.primaryColor};
    color: ${({ theme: { colors } }) => colors.primaryColor};
  }
`;

const Left = styled(FlexRows)`
  .address {
    margin-left: 0.5rem;
  }
  .info-right {
    margin-left: 1rem;
  }
  .favourite {
    margin-left: 0.1rem;
  }
  .view-details {
    margin-left: 5px;
  }
  img {
    border-radius: 12px;
  }
`;

const Flex = styled(FlexRows)`
  margin-bottom: -0.2rem;
`;

const Ingredients = styled.div``;

const Figure = styled.figure`
  img {
    width: 4.5rem;
    height: 4.5rem;
    object-fit: cover;
  }
`;

const FoodItem = ({ order, userId }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const adddIdInLineItemOrder = {
    ...order,
    line_item: order.line_item.map(item2 => ({ ...item2, id: uuid() })),
  };
  return (
    <>
      <OrderDetailsModal
        isActiveModal={isActiveModal}
        onClose={() => setIsActiveModal(false)}
        order={order}
      />
      <Container>
        <Top justify="space-between">
          <div>
            <Left justify="flex-start">
              <Figure>
                <img
                  src={order.service_type_setting.image_url || config.dummyImage}
                  alt="food-order"
                />
              </Figure>
              <div className="info-right">
                <Flex justify="flex-start">
                  <Text className="is-capitalized" weight="semibold" size="medium">
                    {order.place.name}
                  </Text>
                  <Text weight="regular" size="small" color="darkGrey" className="address">
                    {order.place.city} {order.place.state}
                  </Text>
                </Flex>
                <div className="">
                  <Text weight="regular" size="tiny" color="darkGrey">
                    {moment(order.audit.created_at).format('llll')}| {order.overall_quantity} Items
                    | ${parseFloat(order.billing.order_total).toFixed(2)}
                  </Text>
                  <Flex justify="flex-start">
                    <FavoriteAction order={order} />
                    <a onClick={() => setIsActiveModal(true)}>
                      <Text
                        weight="regular"
                        size="tiny"
                        color="secondaryColor"
                        className="view-details"
                      >
                        View Details
                      </Text>
                    </a>
                  </Flex>
                </div>
              </div>
            </Left>
          </div>
          <div className="btn-wrapper">
            <FlexRows>
              <Text size="smaller" color="secondaryColor" paddingBottom="0.5">
                {order.service_type}
              </Text>
              {order.status === 'CANCELLED' ? (
                <FlexRows justify="flex-start">
                  <Text size="smaller" color="darkGrey" paddingBottom="0.5">
                    {' '}
                    |{' '}
                  </Text>
                  <BsFillXCircleFill
                    size={15}
                    color="red"
                    style={{ marginRight: '0.2rem', marginBottom: '0.4rem', cursor: 'pointer' }}
                  />
                  <Text size="smaller" color="darkGrey" paddingBottom="0.5">
                    {order.status}
                  </Text>
                </FlexRows>
              ) : (
                <Text size="smaller" color="darkGrey" paddingBottom="0.5">
                  | {order.status}
                </Text>
              )}
            </FlexRows>
            {order.status === 'COMPLETE' ? (
              <RepeatOrderAction order={order} userId={userId} />
            ) : (
              <>
                <Link to={`/order-details/${order.menu_order_id}`} className="button">
                  Track this order
                </Link>
              </>
            )}
          </div>
        </Top>
        <hr />
        <div>
          <Text size="small" weight="semibold" color="waterMarkGrey">
            Order Number
          </Text>
          <Text size="small" weight="regular">
            {order.order_reference}
          </Text>
        </div>
        <Ingredients>
          <Text size="small" weight="semibold" color="waterMarkGrey">
            Items
          </Text>
          {adddIdInLineItemOrder.line_item.map(item => (
            <Ingredient order={order} item={item} status={order.status} />
          ))}
        </Ingredients>
      </Container>
    </>
  );
};

export default FoodItem;
