import React from 'react';
import styled from 'styled-components';
import { Text } from '../../../components/elements';

const Container = styled.div`
  &&& {
    .table thead td,
    .table thead th,
    tr {
      border-width: 0 0 0px;
    }
    .table td,
    .table th {
      border: 0;
    }
    .table thead {
      background-color: #ffffff;
    }
  }
`;

const ThTextStyle = styled(Text).attrs({
  className: 'is-uppercase',
  color: 'primaryColor',
  size: 'small',
})``;

const TrTextStyle = styled(Text).attrs({
  color: 'darkGrey',
  size: 'tiny',
})``;

// const Wrapper = styled(FlexRows)`
//   padding: 0rem 1rem;
//   margin-right: 5.5%;
// `;

const OrderSummary = ({
  // tickets,
  ticketSelections,
  // subTotalPrice,
  // totalQuantity,
  // bookingFee,
  // discount,
  // bookingFeeOfEvent,
}) => {
  // console.log('order Summery', ticketSelections, tickets);
  return (
    <>
      <Container className="table-container">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>
                <ThTextStyle>Ticket Type</ThTextStyle>
              </th>
              <th>
                <ThTextStyle>QTY</ThTextStyle>
              </th>
              <th>
                <ThTextStyle>Price</ThTextStyle>
              </th>
              <th>
                <ThTextStyle>Fee</ThTextStyle>
              </th>
              <th>
                <ThTextStyle>Cost</ThTextStyle>
              </th>
            </tr>
          </thead>
          <tbody>
            {ticketSelections &&
              ticketSelections.length !== 0 &&
              ticketSelections.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <TrTextStyle>{item.name}</TrTextStyle>
                    </td>
                    <td>
                      <TrTextStyle>{item.quantity}</TrTextStyle>
                    </td>
                    <td>
                      <TrTextStyle>${parseFloat(item.price).toFixed(2)}</TrTextStyle>
                    </td>
                    <td>
                      <TrTextStyle>${parseFloat(item.ticketBookingFee).toFixed(2)}</TrTextStyle>
                    </td>
                    <td>
                      <TrTextStyle>
                        $
                        {parseFloat((item.price + item.ticketBookingFee) * item.quantity).toFixed(
                          2,
                        )}
                      </TrTextStyle>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {/* <hr />

        <Wrapper justify="space-between" align="flex-start">
          <div>
            <Text className="is-capitalized" size="medium" weight="semibold" paddingBottom={1}>
              {totalQuantity !== 0 && <>Total Quantity : {totalQuantity}</>}
            </Text>
          </div>

          <div>
            {totalQuantity !== 0 && (
              <>
                <Text weight="semibold" color="darkGrey" size="small" paddingBottom={0.1}>
                  Sub Total : &nbsp;&nbsp;${parseFloat(subTotalPrice).toFixed(2)}
                </Text>

                <Text weight="semibold" color="darkGrey" size="small" paddingBottom={0.1}>
                  Ticket Fee : &nbsp;&nbsp;${parseFloat(bookingFee).toFixed(2)}
                </Text>

                <Text weight="semibold" color="darkGrey" size="small" paddingBottom={0.1}>
                  Booking Fee :&nbsp;&nbsp; ${parseFloat(bookingFeeOfEvent).toFixed(2)}
                </Text>

                <Text weight="semibold" size="small" color="darkGrey" paddingBottom={0.5}>
                  Voucher Discount :&nbsp;&nbsp; ${parseFloat(discount).toFixed(2)}
                </Text>

                <Text weight="semibold" size="small" paddingBottom={1}>
                  Order Total :&nbsp;&nbsp; $
                  {parseFloat(subTotalPrice + bookingFee - discount + bookingFeeOfEvent).toFixed(2)}
                </Text>
              </>
            )}
          </div>
        </Wrapper>
        <hr /> */}
      </Container>
    </>
  );
};

export default OrderSummary;
