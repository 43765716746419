import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MdSearch, MdArrowBack } from 'react-icons/md';

import { Image, FlexRows } from '../../elements';
import logo from '../../../assets/images/KraveIn-Logo-typelogo-white@2x.png';
import MySidebar from '../SideBar';
import colors from '../../../theme/styles/colors';

const NavBar = styled.div`
  &&& {
    background: ${({ theme: { primaryColor } }) => primaryColor};
    height: 3rem;
    color: #ffffff;
    margin: 0rem;
    padding: 0rem 0.2rem;
    position: fixed;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

const Logo = styled(Image)`
  width: 6rem;
`;

const IconWrapper = styled.div`
  padding-top: 8px;
`;

const LogoWrapper = styled.div`
  padding-left: 30px;
`;

const MobileHeader = ({ searchInputEnable, history, home, hideMobileSearchIcon }) => (
  <>
    <NavBar>
      <div>
        {searchInputEnable || home ? (
          <Link to="/">
            <Logo source={logo} objectfit="contain" />
          </Link>
        ) : (
          <a onClick={() => window.history.back()}>
            <MdArrowBack size={25} color={colors.white} />
          </a>
        )}
      </div>
      {!searchInputEnable && !home && (
        <Link to="/">
          <LogoWrapper>
            <Logo source={logo} objectfit="contain" />
          </LogoWrapper>
        </Link>
      )}
      <FlexRows>
        {!hideMobileSearchIcon && (
          <a onClick={() => history.push('/global-search')}>
            <IconWrapper>
              <MdSearch size={25} color={colors.white} />
            </IconWrapper>
          </a>
        )}
        <MySidebar history={history} />
      </FlexRows>
    </NavBar>
  </>
);

export default MobileHeader;
