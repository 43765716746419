import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { isNull, first } from 'lodash';

import moment from 'moment';
import {
  Text,
  Button,
  Input,
  InputErrorMessage,
  MobileInput,
  TextArea,
  ReactDatePicker,
  FlexRows,
  Radio,
  Avatar,
} from '../../components/elements';

import ProfilePhotoUpload from './ProfilePhotoUpload';

const Form = styled.form`
  &&& {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
`;

const ImageUploadWrapper = styled.div`
  &&& {
    .box {
      border-radius: 10rem;
      height: 10rem;
      width: 10rem;
      padding: 0rem;
      margin: 0rem;
    }
  }
`;

const Span = styled.span`
  color: red;
`;
const PaddedLeft = styled.div`
  padding-left: 1rem;
`;
const Label = styled(Text).attrs({
  className: 'is-capitalized',
  color: 'darkGrey',
  size: 'small',
  weight: 'semibold',
})``;

const DesktopForm = props => {
  const {
    loading,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    history,
  } = props;

  return (
    <Form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <div className="columns is-vcentered is-multiline">
        <div className="column is-10">
          <div className="field">
            <Label paddingBottom={0.5}>Profile photo</Label>
            <FlexRows className="control" justify="flex-start">
              <ImageUploadWrapper>
                <Avatar
                  name={values.display_name}
                  borderRadius={80}
                  maskProps={{ width: 140 }}
                  maskTextProps={{
                    color: 'white',
                    weight: 'bold',
                    size: 'h1',
                    letterSpacing: 'loose',
                  }}
                  image={values.profile_image}
                  color={values.color_code}
                />
              </ImageUploadWrapper>
              <PaddedLeft>
                <ProfilePhotoUpload {...props} />
                <Text color="darkGrey">Format accepted - &quot;.png, .jpg, .jpeg&quot;</Text>
                <Text color="darkGrey">Recommended Image resolution 200x200</Text>
                <Text color="darkGrey">Maximum size accepted - 5MB</Text>
              </PaddedLeft>
            </FlexRows>
          </div>
        </div>
        <div className="column is-5">
          <div className="field">
            <Label paddingBottom={0.5}>
              First name<Span>*</Span>
            </Label>
            <div className="control">
              <Input
                name="first_name"
                id="User_FirstName"
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputErrorMessage touched={touched.first_name} errors={errors.first_name} />
            </div>
          </div>
        </div>
        <div className="column is-5">
          <div className="field">
            <Label paddingBottom={0.5}>
              Last name<Span>*</Span>
            </Label>
            <div className="control">
              <Input
                name="last_name"
                id="User_LastName"
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputErrorMessage touched={touched.last_name} errors={errors.last_name} />
            </div>
          </div>
        </div>
        <div className="column is-5">
          <div className="field">
            <Label paddingBottom={0.5}>
              Display name<Span>*</Span>
            </Label>
            <div className="control">
              <Input
                name="display_name"
                id="User_DisplayName"
                value={values.display_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputErrorMessage touched={touched.display_name} errors={errors.display_name} />
            </div>
          </div>
        </div>
        <div className="column is-5">
          <div className="field">
            <Label paddingBottom={0.5}>User Handle</Label>
            <div className="control">
              <Input
                name="user_handle"
                id="User_UserHandle"
                value={values.user_handle}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputErrorMessage touched={touched.user_handle} errors={errors.user_handle} />
            </div>
          </div>
        </div>
        <div className="column is-5">
          <div className="field">
            <Label paddingBottom={0.5}>Date of birth</Label>
            <div className="control">
              <ReactDatePicker
                value={values.date_of_birth}
                onChange={value => {
                  setFieldValue('date_of_birth', value);
                }}
                maxDate={new Date(moment().subtract(14, 'years'))}
              />
              <InputErrorMessage touched={touched.date_of_birth} errors={errors.date_of_birth} />
            </div>
          </div>
        </div>
        <div className="column is-5">
          <div className="field">
            <Label paddingBottom={0.5}>Gender</Label>
            <div className="control">
              <FlexRows justify="flex-start">
                <PaddedLeft>
                  <Radio
                    label="Male"
                    value={values.gender === 'MALE'}
                    onChange={value => {
                      if (value) {
                        setFieldValue('gender', 'MALE');
                      }
                    }}
                  />
                </PaddedLeft>
                <PaddedLeft>
                  <Radio
                    label="Female"
                    value={values.gender === 'FEMALE'}
                    onChange={value => {
                      if (value) {
                        setFieldValue('gender', 'FEMALE');
                      }
                    }}
                  />
                </PaddedLeft>
                <PaddedLeft>
                  <Radio
                    label="Rather not say"
                    value={values.gender === 'OTHER'}
                    onChange={value => {
                      if (value) {
                        setFieldValue('gender', 'OTHER');
                      }
                    }}
                  />
                </PaddedLeft>
              </FlexRows>
              <InputErrorMessage touched={touched.gender} errors={errors.gender} />
            </div>
          </div>
        </div>
        <div className="column is-5">
          <div className="field">
            <Label paddingBottom={0.5}>Primary mobile phone</Label>
            <div className="control">
              <MobileInput
                name="primaryMobile"
                value={values.primaryMobile}
                onChange={value => {
                  setFieldValue('primaryMobile', value);
                }}
              />
              <InputErrorMessage touched={touched.primaryMobile} errors={errors.primaryMobile} />
            </div>
          </div>
        </div>
        <div className="column is-5">
          <div className="field">
            <Label paddingBottom={0.5}>
              Primary email <Span>*</Span>
            </Label>
            <div className="control">
              <Input
                name="primaryEmail"
                id="User_Email"
                value={values.primaryEmail}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputErrorMessage touched={touched.primaryEmail} errors={errors.primaryEmail} />
            </div>
          </div>
        </div>
        <div className="column is-5">
          <div className="field">
            <Label paddingBottom={0.5}>Alternate mobile phone</Label>
            <div className="control">
              <MobileInput
                name="alternateMobile"
                value={values.alternateMobile}
                onChange={value => {
                  setFieldValue('alternateMobile', value);
                }}
              />
              <InputErrorMessage
                touched={touched.alternateMobile}
                errors={errors.alternateMobile}
              />
            </div>
          </div>
        </div>
        <div className="column is-5">
          <div className="field">
            <Label paddingBottom={0.5}>Alternate email</Label>
            <div className="control">
              <Input
                name="alternateEmail"
                id="User_AlternateEmail"
                value={values.alternateEmail}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputErrorMessage touched={touched.alternateEmail} errors={errors.alternateEmail} />
            </div>
          </div>
        </div>
        <div className="column is-10">
          <div className="field">
            <Label paddingBottom={0.5}>About you ( Min 50 and Max 1500 characters )</Label>
            <div className="control">
              <TextArea
                name="description"
                id="User_Description"
                placeholder="A few words about you.."
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Text color="darkGrey" size="micro">
                Characters Left: {values.description ? values.description.length : 0} / 1500
              </Text>
              <InputErrorMessage touched={touched.description} errors={errors.description} />
            </div>
          </div>
        </div>
        <div className="column is-10">
          <nav className="level">
            <div className="level-left">
              <Button
                type="button"
                name="Back"
                width={3}
                height={0.5}
                backgroundColor="primaryColor"
                textProps={{ color: 'white', weight: 'semibold' }}
                onClick={() => history.push('/profile')}
              />
            </div>
            <div className="level-right">
              <Button
                type="submit"
                className={loading && 'is-loading'}
                name="Update Profile"
                width={3}
                height={0.5}
                backgroundColor="primaryColor"
                textProps={{ color: loading ? 'primaryColor' : 'white', weight: 'semibold' }}
              />
            </div>
          </nav>
        </div>
      </div>
    </Form>
  );
};

const MyEnhancedForm = withFormik({
  mapPropsToValues: ({ user }) => {
    const primaryMobile = !isNull(user.contact)
      ? user.contact.filter(item => item.type === 'mobile' && item.is_primary === true)
      : [];

    const alternateMobile = !isNull(user.contact)
      ? user.contact.filter(item => item.type === 'mobile' && item.is_primary === false)
      : [];

    const alternateEmail = !isNull(user.contact)
      ? user.contact.filter(item => item.type === 'email' && item.is_primary === false)
      : [];

    return {
      primaryEmail: user.email,
      alternateEmail: alternateEmail.length !== 0 ? first(alternateEmail).value : '',
      primaryMobile: primaryMobile.length !== 0 ? first(primaryMobile).value : '',
      alternateMobile: alternateMobile.length !== 0 ? first(alternateMobile).value : '',
      user_handle: user.user_handle,
      first_name: user.first_name,
      last_name: user.last_name,
      preferred_username: user.preferred_username,
      display_name: user.display_name,
      date_of_birth: user.date_of_birth,
      gender: user.gender,
      description: user.description,
      color_code: user.color_code,
      profile_image: null,
    };
  },

  validationSchema: yup.object().shape({
    first_name: yup
      .string()
      .max(18, 'Max length should smaller or equal to 18 characters')
      .required('First name is required!'),
    last_name: yup
      .string()
      .max(18, 'Max length should smaller or equal to 18 characters')
      .required('Last name is required!'),
    display_name: yup.string().max(37, 'Max length should smaller or equal to 37 characters'),
    primaryEmail: yup
      .string()
      .email('Enter valid email address!')
      .required('Email address is required!'),
    // alternateEmail: yup
    //   .string()
    //   .email('Enter valid email address!')
    //   .required('Email address is required!'),
    description: yup
      .string()
      .min(50, 'At least 50 Characters Required!')
      .max(1500, 'Not more than 1500 Characters!'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
  },

  displayName: 'ContactUsForm',
})(DesktopForm);

export default MyEnhancedForm;
