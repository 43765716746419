import React, { useEffect, useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import queryString from 'query-string';
import { animateScroll } from 'react-scroll';
import { capitalize } from 'lodash';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import { getZoneTime } from '../../utils/eventHelpers';
import client from '../../utils/apolloClient';
import { isDesktop } from '../../utils/helpers';
import config from '../../utils/config';

const searchPlacesQuery = gql`
  query search($search_criteria: String, $location: String, $from: Int, $distanceRadius: String) {
    search_places(
      input: {
        size: 10
        from: $from
        location: $location
        filter: {
          place_filter: {
            place_name: $search_criteria
            listing_status: "APPROVED"
            event_business: false
            status: "ACTIVE"
            distance: $distanceRadius
          }
        }
      }
    ) {
      total_pages
      total_size
      place_listing {
        place_id
        status
        price_range
        tagline
        voucher_list {
          code
          description
        }
        business_type {
          name
          description
        }
        service_category {
          name
          description
        }
        service_type {
          name
          description
        }
        cuisine_style {
          name
          description
        }
        item_type {
          name
          description
        }
        feature {
          name
          description
        }
        dress_code {
          name
          description
        }
        dining_style {
          name
          description
        }
        public_transport {
          name
          description
        }
        ambience {
          name
          description
        }
        dietary_information {
          name
          description
        }
        distance
        trading_name
        like_percentage
        display_order
        featured
        name
        description
        tagline
        tag
        slug
        voucher_count
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        timezone
        default_image_url
      }
    }
  }
`;

const searchItemsQuery = gql`
  query search($search_criteria: String, $location: String, $from: Int, $distanceRadius: String) {
    search_items(
      input: {
        size: 10
        from: $from
        location: $location
        filter: {
          item_filter: {
            name: $search_criteria
            status: "ACTIVE"
            approval_status: "APPROVED"
            distance: $distanceRadius
          }
          place_filter: { status: "ACTIVE" }
        }
      }
    ) {
      total_pages
      total_size
      item_listing {
        item_id
        place_id
        item_dictionary_id
        place_name
        status
        approval_status
        listing_approved
        item_review_place
        default_url
        display_order
        name
        slug
        star_count
        love_count
        tried_count
        share_count
        loved_percentage
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        default_image_url
      }
    }
  }
`;

const searchEventsQuery = gql`
  query search($location: String, $eventFilter: EventFilter, $from: Int) {
    search_events(
      input: { size: 10, from: $from, location: $location, filter: { event_filter: $eventFilter } }
    ) {
      total_pages
      total_size
      event_listing {
        place_id
        event_id
        status
        display_order
        featured
        default_url
        start
        end
        listing_type
        place_name
        venue_name
        name
        description
        tagline
        slug
        voucher_count
        minimum_price
        maximum_price
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        default_image_url
      }
    }
  }
`;

const searchLocationsQuery = gql`
  query searchLocation($search_criteria: String!) {
    search_location(input: { search_criteria: $search_criteria }) {
      location_listing {
        code
        country_id
        name
        post_code
        state_name
        country_name
        latitude
        longitude
        location
      }
    }
  }
`;

const Search = ({ history, match, location }) => {
  const isMobile = isDesktop();
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const { query } = queryString.parse(location.search);
  const [eventDateFilter, setEventDateFilter] = useState('');
  const [currentDate, setCurrentDate] = useState(moment().toISOString());
  const [searchQueryTag, setSearchQueryTag] = useState(query || '');
  const [currentUsedLocation, setCurrentUsedLocation] = useState(
    `${usedLocation.latitude},${usedLocation.longitude}`,
  );

  const [searchLocationQuery, setSearchLocationQuery] = useState(
    `${capitalize(usedLocation.city)}, ${usedLocation.state}`,
  );

  const [locations, setLocations] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);

  const [places, setPlaces] = useState([]);
  const [placeLoading, setPlaceLoading] = useState(false);
  const [placeFrom, setPlaceFrom] = useState(0);
  const [totalPlacePage, setTotalPlacePage] = useState(0);

  const [events, setEvents] = useState([]);
  const [eventLoading, setEventLoading] = useState(false);
  const [eventFrom, setEventFrom] = useState(0);
  const [totalEventPage, setTotalEventPage] = useState(0);

  const [items, setItems] = useState([]);
  const [itemLoading, setItemLoading] = useState(false);
  const [itemFrom, setItemFrom] = useState(0);
  const [totalItemPage, setTotalItemPage] = useState(0);

  const event_filter = {};

  Object.assign(
    event_filter,
    { event_name: searchQueryTag, status: 'ACTIVE', draft: false },
    eventDateFilter !== '' && {
      end_date_range: { start_date: eventDateFilter, distance: config.eventSearchDistanceRadius },
    },
  );

  useEffect(() => {
    if (query) {
      setSearchQueryTag(query);
    }
    if (!isMobile) {
      setCurrentUsedLocation(`${usedLocation.latitude},${usedLocation.longitude}`);
    }
  }, [isMobile, query, usedLocation.latitude, usedLocation.longitude]);

  const fetchLocation = tag => {
    setSearchLocationQuery(tag);
    if (tag.length !== 0) {
      setLocationLoading(true);
      client.clientPublic
        .query({
          query: searchLocationsQuery,
          variables: {
            search_criteria: tag,
          },
        })
        .then(({ data }) => {
          setLocations(data.search_location.location_listing);
          setLocationLoading(false);
        })
        .catch(error => {
          console.log('fetchLocation -> error', error);
          setLocationLoading(false);
        });
    } else {
      setLocations([]);
    }
  };

  const fetchPlaces = useCallback(() => {
    setPlaceLoading(true);
    client.clientPublic
      .query({
        query: searchPlacesQuery,
        variables: {
          from: placeFrom,
          search_criteria: searchQueryTag,
          location: currentUsedLocation,
          distanceRadius: config.searchDistanceRadius,
        },
      })
      .then(({ data }) => {
        setPlaces(data.search_places.place_listing);
        setTotalPlacePage(data.search_places.total_pages);
        setPlaceLoading(false);
      })
      .catch(error => {
        console.log('fetchPlaces -> error', error);
        setPlaceLoading(false);
      });
  }, [currentUsedLocation, placeFrom, searchQueryTag]);

  const fetchItems = useCallback(() => {
    setItemLoading(true);
    client.clientPublic
      .query({
        query: searchItemsQuery,
        variables: {
          from: itemFrom,
          search_criteria: searchQueryTag,
          location: currentUsedLocation,
          distanceRadius: config.searchDistanceRadius,
        },
      })
      .then(({ data }) => {
        setItems(data.search_items.item_listing);
        setTotalItemPage(data.search_items.total_pages);
        setItemLoading(false);
      })
      .catch(error => {
        console.log('fetchItems -> error', error);
        setItemLoading(false);
      });
  }, [currentUsedLocation, itemFrom, searchQueryTag]);

  const fetchEvents = useCallback(() => {
    setEventLoading(true);
    client.clientPublic
      .query({
        query: searchEventsQuery,
        variables: {
          from: eventFrom,
          search_criteria: searchQueryTag,
          location: currentUsedLocation,
          eventFilter: event_filter,
        },
      })
      .then(({ data }) => {
        setEvents(data.search_events.event_listing);
        setTotalEventPage(data.search_events.total_pages);
        setEventLoading(false);
      })
      .catch(error => {
        console.log('fetchEvents -> error', error);
        setEventLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventFrom, searchQueryTag, currentUsedLocation]);

  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27 && location.pathname === '/global-search') {
        history.goBack();
      }
    },
    [history, location.pathname],
  );

  useEffect(() => {
    setPlaceFrom(0);
    setItemFrom(0);
    setEventFrom(0);
  }, [searchQueryTag]);

  useEffect(() => {
    window.addEventListener('keydown', escFunction);
    const fetchData = async () => {
      const date = await getZoneTime(moment(), usedLocation.latitude, usedLocation.longitude);
      setEventDateFilter(moment(date).toISOString());
    };
    fetchData();
    if (searchQueryTag.length !== 0) {
      fetchPlaces();
      fetchItems();
      fetchEvents();
    }
    if (searchQueryTag.length === 0) {
      setPlaces([]);
      setItems([]);
      setEvents([]);
    }
    setCurrentDate(moment().toISOString());
  }, [
    escFunction,
    fetchEvents,
    fetchItems,
    fetchPlaces,
    query,
    searchQueryTag,
    searchQueryTag.length,
    usedLocation.latitude,
    usedLocation.longitude,
  ]);

  const handleOnChange = value => {
    setSearchQueryTag(value);
  };

  const handleSelectLocation = value => {
    setCurrentUsedLocation(value.location);
    setSearchLocationQuery(`${capitalize(value.name)}, ${value.state_name}`);
    setLocations([]);
  };

  return (
    <View
      match={match}
      currentDate={currentDate}
      history={history}
      searchTag={searchQueryTag}
      places={places}
      items={items}
      events={events}
      placeLoading={placeLoading}
      itemLoading={itemLoading}
      eventLoading={eventLoading}
      totalPlacePage={totalPlacePage}
      totalEventPage={totalEventPage}
      totalItemPage={totalItemPage}
      handleOnChange={handleOnChange}
      searchLocationQuery={searchLocationQuery}
      locationLoading={locationLoading}
      locations={locations}
      fetchLocation={fetchLocation}
      handleSelectLocation={handleSelectLocation}
      handlePageClick={(value, forWhich) => {
        if (forWhich === 'place') {
          setPlaceFrom(parseInt(value, 10) * 10);
        }
        if (forWhich === 'item') {
          setItemFrom(parseInt(value, 10) * 10);
        }
        if (forWhich === 'event') {
          setEventFrom(parseInt(value, 10) * 10);
        }
        animateScroll.scrollToTop();
      }}
    />
  );
};

const View = ({
  searchTag,
  history,
  match,
  currentDate,
  places,
  items,
  events,
  placeLoading,
  itemLoading,
  eventLoading,
  totalPlacePage,
  totalEventPage,
  totalItemPage,
  handleOnChange,
  searchLocationQuery,
  locationLoading,
  locations,
  fetchLocation,
  handleSelectLocation,
  handlePageClick,
}) => {
  const isMobile = !isDesktop();
  return (
    <>
      {isMobile ? (
        <MobileView
          history={history}
          places={places}
          items={items}
          events={events}
          query={searchTag}
          currentDate={currentDate}
          totalPlacePage={totalPlacePage}
          totalEventPage={totalEventPage}
          totalItemPage={totalItemPage}
          handleOnChange={handleOnChange}
          searchLocationQuery={searchLocationQuery}
          locationLoading={locationLoading}
          loading={placeLoading || itemLoading || eventLoading}
          locations={locations}
          fetchLocation={fetchLocation}
          handleSelectLocation={handleSelectLocation}
          handlePageClick={handlePageClick}
        />
      ) : (
        <Layout history={history} match={match}>
          <DesktopView
            history={history}
            places={places}
            items={items}
            events={events}
            query={searchTag}
            currentDate={currentDate}
            placeLoading={placeLoading}
            itemLoading={itemLoading}
            eventLoading={eventLoading}
            totalPlacePage={totalPlacePage}
            totalEventPage={totalEventPage}
            totalItemPage={totalItemPage}
            handlePageClick={handlePageClick}
          />
        </Layout>
      )}
    </>
  );
};

export default Search;
