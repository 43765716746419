import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import gql from 'graphql-tag';

import { IconSocialActionButton, Text } from '../../../../components/elements';
import FlaggedActionButton from '../../../../components/FlaggedActionButton';
import LikeUserPopup from './LikeUserPopup';
import client from '../../../../utils/apolloClient';

const SocialActionButtonStyle = styled(IconSocialActionButton).attrs({
  textStyle: { size: 'smaller', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1.2 },
})``;

const FlaggedActionButtonStyle = styled(FlaggedActionButton).attrs({
  textStyle: { size: 'smaller', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1.2 },
})``;

const socialMutation = gql`
  mutation socialAction($input: SocialNetworkInput) {
    create_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const removeSocialMutation = gql`
  mutation removeSocialAction($input: NavigateSocialInput) {
    delete_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const ReviewSocial = ({
  likeUsers,
  totalLike,
  totalComments,
  review,
  objectId,
  objectType,
  likeActive,
  likeCountInt,
  likeSocialIdS,
  setTotalLike,
}) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn, userId } = useStoreState(state => state.auth);
  const [likeCount, setLikeCount] = useState(likeCountInt);

  const [likeSocialId, setLikeSocialId] = useState(likeSocialIdS);

  const [isActiveLike, setIsActiveLike] = useState(likeActive);
  const [likeLoading, setLikeLoading] = useState(false);
  const [isActiveLikeUserPopup, setIsActiveLikeUserPopup] = useState(false);
  const [totalLikeUsers, setTotalLikeUsers] = useState(likeUsers);

  const handleCountIncrement = type => {
    if (type === 'LIKE') {
      setLikeCount(likeCount + 1);
      setIsActiveLike(true);
      setTotalLike(likeCount + 1);
    }
  };

  const handleCountDecrement = type => {
    if (type === 'LIKE') {
      setLikeCount(likeCount - 1);
      setIsActiveLike(false);
      setTotalLike(likeCount - 1);
    }
  };

  const handleOnClickActive = type => {
    handleCountIncrement(type);
    setLikeLoading(true);
    client.clientPrivate
      .mutate({
        mutation: socialMutation,
        variables: {
          input: {
            user_id: userId,
            object_id: objectId,
            object_type: objectType,
            type,
            value: 1,
          },
        },
      })
      .then(({ data }) => {
        if (type === 'LIKE') {
          setLikeSocialId(data.create_social.social_id);
          setTotalLikeUsers(totalLikeUsers.concat(userId));
          setLikeLoading(false);
        }
      });
  };

  const handleOnClickRemove = (type, socialId) => {
    handleCountDecrement(type);
    setLikeLoading(true);
    client.clientPrivate
      .mutate({
        mutation: removeSocialMutation,
        variables: {
          input: {
            user_id: userId,
            social_id: socialId,
          },
        },
      })
      .then(() => {
        setTotalLikeUsers(totalLikeUsers.filter(item => item !== userId));
        setLikeLoading(false);
      });
  };

  const handleSocialAction = (type, action, socialId) => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
      });
    } else if (action) {
      handleOnClickActive(type);
    } else {
      handleOnClickRemove(type, socialId);
    }
  };

  return (
    <nav className="level">
      <div className="level-left">
        <div className="level-item">
          <SocialActionButtonStyle
            loading={likeLoading}
            active={isActiveLike}
            name="Like"
            defaultIcon="likeGrey"
            activeIcon="likeBlue"
            socialCount={likeCount}
            handleOnClick={() => handleSocialAction('LIKE', !isActiveLike, likeSocialId)}
            showCount={false}
          />
        </div>

        <div className="level-item">
          <LikeUserPopup
            userId={userId}
            likeUsers={totalLikeUsers}
            totalLike={totalLike}
            isActive={isActiveLikeUserPopup}
            onClick={() => setIsActiveLikeUserPopup(!isActiveLikeUserPopup)}
            onClose={() => setIsActiveLikeUserPopup(false)}
          />
        </div>

        <div className="level-item">
          <Text size="smaller" color="coolGrey" weight="semibold">
            {totalComments > 1 && `${totalComments} comments`}
            {totalComments === 1 && `${totalComments} comment`}
          </Text>
        </div>
      </div>

      <div className="level-right">
        <div className="level-item">
          <FlaggedActionButtonStyle
            createdBy={review.audit.created_by}
            objectId={review.place_review_id}
            parentId={review.place_id}
            objectType="PLACE_REVIEW"
            /* name="Flag" */
            icon="flagGrey"
          />
        </div>
      </div>
    </nav>
  );
};

ReviewSocial.defaultProps = {
  objectId: '',
  objectType: '',
  likeActive: false,
  likeCountInt: 0,
};

ReviewSocial.propTypes = {
  objectId: PropTypes.string,
  objectType: PropTypes.string,
  likeActive: PropTypes.bool,

  likeCountInt: PropTypes.number,
};

export default ReviewSocial;
