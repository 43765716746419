import React, { useState } from 'react';
import gql from 'graphql-tag';
import { isNull } from 'lodash';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';
import apolloClient from '../../utils/apolloClient';

const searchUserQuery = gql`
  query searchUser($input: SearchInput) {
    search_friends(input: $input) {
      user_listing_aggregation {
        total_pages
        total_size
        user_listing {
          user_id
          full_name
          display_name
          color_code
          profile_image_url
        }
      }

      item_review_aggregation {
        total_review_by_user {
          user
          count
        }
      }
      place_review_aggregation {
        total_review_by_user {
          user
          count
        }
      }
      follow_aggregation {
        total_follow_by_user {
          user
          count
        }
      }
    }
  }
`;

const FindFriends = ({ history, match }) => {
  const [users, setUsers] = useState([]);
  const [itemReviews, setItemReviews] = useState([]);
  const [placeReviews, setPlaceReviews] = useState([]);
  const [follows, setFollows] = useState([]);

  const [loading, setLoading] = useState(false);
  const onHandleFindFriends = query => {
    setLoading(true);
    apolloClient.clientPrivate
      .query({
        query: searchUserQuery,
        variables: {
          input: {
            search_criteria: query,
            filter: {},
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data && !isNull(data.search_friends)) {
          setUsers(data.search_friends.user_listing_aggregation.user_listing);
          setItemReviews(data.search_friends.item_review_aggregation.total_review_by_user);
          setPlaceReviews(data.search_friends.place_review_aggregation.total_review_by_user);
          setFollows(data.search_friends.follow_aggregation.total_follow_by_user);
          setLoading(false);
          console.log('data', data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Layout
      history={history}
      match={match}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading="FIND FRIENDS"
    >
      <Analytics pageName="FindFriends" category="findfriends" title="Find-Friends">
        <div className="is-hidden-desktop">
          <MobileView
            users={users}
            placeReviews={placeReviews}
            itemReviews={itemReviews}
            follows={follows}
            onHandleFindFriends={onHandleFindFriends}
            loading={loading}
          />
        </div>
        <div className="is-hidden-touch">
          <DesktopView
            users={users}
            placeReviews={placeReviews}
            itemReviews={itemReviews}
            follows={follows}
            onHandleFindFriends={onHandleFindFriends}
            loading={loading}
          />
        </div>
      </Analytics>
    </Layout>
  );
};

export default FindFriends;
