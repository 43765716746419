import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  &&& {
    margin: 0;
    span {
      margin: 3px;
    }
  }
`;

const Tags = ({ filterStateInputs, filterFuncInputs }) => (
  <Container>
    {filterStateInputs.cuisineInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small"
          onClick={() => filterFuncInputs.removeCuisineInputs(item.name)}
        />
      </span>
    ))}
    {filterStateInputs.itemTypeInputs.map(item => (
      <span className="tag">
        {item.description}
        <button
          className="delete is-small has-color-danger"
          onClick={() => filterFuncInputs.removeItemTypeInputs(item.name)}
        />
      </span>
    ))}
  </Container>
);

export default Tags;
