import React from 'react';
import styled from 'styled-components';

import cartImage from '../../../../assets/images/cart.svg';
import { Text } from '../../../../components/elements';

const Container = styled.div`
  && {
    margin-top: 5.5rem;
    figure {
      margin-bottom: 1rem;
    }
    .is-2by1 {
      padding-top: 40%;
      margin-top: 4rem;
    }
  }
`;

const TextWrapper = styled.div`
  align-items: center;
`;

const EmptyCart = () => {
  return (
    <>
      <figure className="image is-2by1">
        <img src={cartImage} alt="empty-cart" />
      </figure>
      <TextWrapper>
        <Text align="center" paddingBottom="0.2" weight="semibold" color="coolGrey">
          Your Cart is Empty
        </Text>
        <Text align="center" weight="semibold" color="coolGrey">
          Add Items to make me happy
        </Text>
      </TextWrapper>
    </>
  );
};

export default EmptyCart;
