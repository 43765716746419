import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
// import moment from 'moment';
import { isNull } from 'lodash';
import { useStoreState } from 'easy-peasy';

import ReviewItem from './ReviewItem';
import Loading from './Loading';
import Empty from './Empty';

const searchItemReviewQuery = gql`
  query searchItemReview($input: SearchInput) {
    search_item_review(input: $input) {
      item_review_listing {
        place_id
        item_id
        item_review_id
        rating
        content
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 0rem 1rem;
`;

const Reviews = () => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Container>
      <Query
        query={searchItemReviewQuery}
        variables={{
          input: {
            sort: 'CREATED_DESC',
            filter: {
              item_review_filter: {
                user_id: userId,
                // status: 'APPROVED',
              },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <Empty isError />;
          }

          if (isNull(data.search_item_review)) {
            return <Empty />;
          }

          if (data.search_item_review.item_review_listing.length === 0) {
            return <Empty />;
          }
          const reviews = data.search_item_review.item_review_listing;

          return (
            <>
              {reviews.map(review => (
                <ReviewItem review={review} />
              ))}
            </>
          );
        }}
      </Query>
    </Container>
  );
};

export default Reviews;
