import React from 'react';
import PropTypes from 'prop-types';
import { FaFacebookF } from 'react-icons/fa';
import styled from 'styled-components';
import { FacebookProvider, Initialize } from 'react-facebook';
import config from '../../../utils/config';
import Text from '../Text';

const Wrapper = styled.div`
  padding: 0.5rem 0rem;
  padding-left: 0.5rem;
  align-items: center;
  cursor: pointer;
`;

const FaceBookShare = ({ url, shareAction }) => {
  return (
    <Wrapper className="is-flex">
      <FaFacebookF size={20} /> &nbsp;&nbsp;
      <FacebookProvider appId={config.facebook.appId}>
        <Initialize>
          {({ isReady, api }) => (
            <a
              onClick={() => {
                try {
                  api.ui({
                    method: 'feed',
                    link: url,
                    display: 'popup',
                  });
                } catch (error) {
                  console.log(error);
                }
                shareAction();
              }}
              disabled={isReady}
            >
              <Text color="coolGrey" size="small">
                Share on Facebook
              </Text>
            </a>
          )}
        </Initialize>
      </FacebookProvider>
    </Wrapper>
  );
};

FaceBookShare.defaultProps = {
  url: `https://${config.apiPrefix}admin.portal.kravein.com.au/`,
};

FaceBookShare.propTypes = {
  url: PropTypes.string,
};

export default FaceBookShare;
