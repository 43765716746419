import gql from 'graphql-tag';

export const OrderFragments = gql`
  fragment OrderFragments on MenuOrder {
    menu_order_id
    place_id
    service_type
    service_type_setting_id
    menu_id
    address {
      address_id
      type
      is_default
      contact_name
      contact_phone
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      status
    }
    audit {
      created_at
      updated_by
      updated_at
    }
    billing {
      sub_total
      delivery_fee
      order_total
      order_amount
      tax {
        type
        value
      }
    }
    line_item {
      item_link_id
      menu_item_id
      prompt_id
      variant_id
      variant_name
      name
      quantity
      price_per_item
      net_price_per_item
      tax_per_item
      price
      overall_price
      tax
      sold_out
      modifier {
        modifier_group_id
        menu_item_id
        prompt_id
        variant_id
        variant_name
        name
        quantity
        price_per_item
        net_price_per_item
        tax_per_item
        price
        tax
      }
      menu_item_rating
      special_request
      user_id
      caption
      private
    }
  }
`;

export const User = {
  comment: gql`
    fragment CommentsPageComment on Comment {
      id
      postedBy {
        login
        html_url
      }
      createdAt
      content
    }
  `,
};
