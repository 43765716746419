import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { isNull, first } from 'lodash';
import { useStoreState } from 'easy-peasy';

import { Text, ShowMoreText, Loading } from '../../../../components/elements';
import FeesSocial from '../FeesSocial';
import { parseSocialPlace } from '../../../../utils/helpers';

const feedQuery = gql`
  query fetch_feed($placeName: String) {
    search_place_feed(
      input: {
        sort: "CREATED_DESC"
        filter: { place_feed_filter: { place_name: $placeName, status: "ACTIVE" } }
      }
    ) {
      place_feed_listing {
        place_id
        feed_id
        name
        city
        title
        content
        audit {
          created_at
          updated_at
          created_by
        }
      }
    }
    search_social(input: { filter: { social_filter: { object_type: "PLACE_FEED" } } }) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  padding-top: 1rem;
`;

const Header = styled.div`
  padding: 1rem 0rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  margin-bottom: 2rem;
`;

const Content = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const IconActionButtonWrapper = styled.div`
  padding-top: 1rem;
`;

const Feed = ({ feed, socials }) => {
  const { userId } = useStoreState(state => state.auth);
  const socialArray = socials.length !== 0 ? first(socials).total_social_count : [];
  const social = parseSocialPlace(socialArray, userId);
  return (
    <Content key={feed.feed_id}>
      <Text size="bmedium" weight="semibold" paddingBottom={0.2}>
        {feed.title}
      </Text>
      <Text size="micro" color="darkGrey" paddingBottom={0.5}>
        {moment(feed.audit.updated_at).fromNow()}
      </Text>
      <ShowMoreText content={feed.content} limit={500} size="tiny" color="darkGrey" />
      <div className="columns is-gapless" style={{ margin: 0 }}>
        <div className="column is-3">
          <IconActionButtonWrapper>
            <FeesSocial
              feed={feed}
              objectId={feed.feed_id}
              objectType="PLACE_FEED"
              likeActive={social.like.active}
              likeCountInt={social.like.count}
              likeSocialIdS={social.like.id}
            />
          </IconActionButtonWrapper>
        </div>
      </div>
    </Content>
  );
};

const LatestFeeds = ({ place, handleElement }) => {
  return (
    <Container>
      <Header>
        <Text size="large" weight="semibold">
          Latest Feeds
        </Text>
      </Header>
      <Query
        query={feedQuery}
        variables={{
          placeName: place.name,
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading }) => {
          if (loading) {
            return <Loading />;
          }

          if (isNull(data.search_place_feed)) {
            return (
              <Text size="small" color="darkGrey">
                No feeds available at this moment!
              </Text>
            );
          }

          return <View data={data} handleElement={handleElement} />;
        }}
      </Query>
    </Container>
  );
};

const View = ({ data, handleElement }) => {
  const feeds = data.search_place_feed.place_feed_listing;
  useEffect(() => {
    if (feeds.length === 0) {
      handleElement('feedsElements');
    }
  }, [handleElement, feeds.length]);

  if (feeds.length === 0) {
    return (
      <Text size="small" color="darkGrey">
        No feeds available at this moment!
      </Text>
    );
  }

  const socials = data.search_social.social_aggregation.total_social_per_object_id;

  return (
    <>
      {feeds.map(feed => (
        <Feed
          feed={feed}
          socials={socials.filter(item => item.object_id === feed.feed_id)}
          key={feed.feed_id}
        />
      ))}
    </>
  );
};

export default LatestFeeds;
