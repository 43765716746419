import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';

import Loading from './Loading';
import Empty from './Empty';
import PlaceReviewItem from './PlaceReview/PlaceReviewItem';
import ItemReviewItem from './ItemReviews/ReviewItem';
import { Text } from '../../../../components/elements';

const Container = styled.div`
  padding: 1.5rem 2rem;
`;

const Header = styled.div`
  padding: 0.5rem 0rem;
`;

const searchReviewQuery = gql`
  query searchItemReview(
    $itemReviewFilter: ItemReviewFilter
    $placeReviewFilter: PlaceReviewFilter
  ) {
    search_place_review(input: { filter: { place_review_filter: $placeReviewFilter } }) {
      place_review_listing {
        place_id
        place_review_id
        month_visited
        overall_rating
        service_category {
          name
          description
        }
        content
        audit {
          created_at
          created_by
        }
        place {
          place_id
          name
          tagline
          description
          address_line_1
          city
          state
          post_code
          country
        }
      }
    }
    search_item_review(input: { filter: { item_review_filter: $itemReviewFilter } }) {
      item_review_listing {
        place_id
        item_id
        item_review_id
        rating
        content
        status
        audit {
          created_at
          created_by
        }
        place {
          name
          address_line_1
          address_line_2
          city
          state
          country
          post_code
          latitude
          longitude
          timezone
        }
        item {
          item_id
          place_id
          place_name
          name
        }
      }
    }
  }
`;

const Drafts = () => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Query
      query={searchReviewQuery}
      variables={{
        placeReviewFilter: {
          user_id: userId,
          draft: true,
          status: 'SUBMITTED',
        },
        itemReviewFilter: {
          user_id: userId,
          draft: true,
          status: 'SUBMITTED',
        },
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <Empty isError />;
        }

        if (isNull(data.search_item_review) && isNull(data.search_item_review)) {
          return <Empty isError />;
        }

        const itemReviews = data.search_item_review.item_review_listing;
        const placeReviews = data.search_place_review.place_review_listing;
        // const placeReviews = [];

        if (placeReviews.length === 0 && itemReviews.length === 0) {
          return <Empty />;
        }

        return (
          <Container>
            <Header>
              <Text size="large" weight="semibold">
                My Draft Reviews
              </Text>
            </Header>
            {placeReviews.map(review => (
              <PlaceReviewItem review={review} draft socials={[]} />
            ))}
            {itemReviews.map(review => (
              <ItemReviewItem review={review} draft socials={[]} />
            ))}
          </Container>
        );
      }}
    </Query>
  );
};

export default Drafts;
