import React from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { times, isEmpty } from 'lodash';
import { MdArrowBack } from 'react-icons/md';

import { Text, FlexRows, Flex, Button } from '../../../components/elements';
import AttendeesForms from './AttendeesForms';

const PageHeader = styled(FlexRows).attrs({ justify: 'center' })`
  padding: 0.5rem;
  padding-bottom:2rem;
  /* background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha}; */
`;

const PageHeaderIconWrapper = styled.a`
  padding-right: 10%;
  position: absolute;
  top: 10px;
  z-index: 1000;
  left: 5px;
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
`;

const Heading = styled(Flex)`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  padding: 0.5rem 1rem;
`;
const ButtonWrapper = styled.div`
  padding: 0.8rem 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 50;
  background-color: white;
`;

// const FooterButton = styled.div`
//   background-color: ${({ theme: { primaryColor } }) => primaryColor};
//   opacity: ${({ active }) => (active ? 1 : 0.7)};
//   z-index: 50;
//   width: 100%;
//   padding: 0.5rem;
//   position: fixed;
//   bottom: 0;
// `;

const Form = ({ values, setFieldValue, handleSubmit, loading, back, errors }) => {
  const isError = !isEmpty(errors);
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const isLoggedIn = useStoreState(state => state.auth.isLoggedIn);
  const handleOnClick = () => {
    if (!isLoggedIn) {
      setIsActiveModal({ value: true, path: '' });
    } else {
      handleSubmit();
    }
  };

  return (
    <div>
      <PageHeader>
        <PageHeaderIconWrapper onClick={back}>
          <Text size="large" weight="semibold" color="white">
            <MdArrowBack size={25} />
          </Text>
        </PageHeaderIconWrapper>
        <Heading>
          <Text
            className="is-uppercase"
            color="white"
            weight="semibold"
            size="small"
            lineSpacing="loose"
          >
            Attendee Information
          </Text>
        </Heading>
      </PageHeader>

      {values.ticketSelections.map((item, index) => (
        <AttendeesForms item={item} ticketIndex={index} setFieldValue={setFieldValue} />
      ))}

      <ButtonWrapper>
        <Button
          className={`${loading && 'is-loading'}`}
          name="CHECKOUT"
          isFullWidth
          type="button"
          backgroundColor="primaryColor"
          textProps={{
            color: loading ? 'primaryColor' : 'white',
            weight: 'semibold',
            size: 'medium',
          }}
          onClick={handleOnClick}
          disabled={isError}
        />
      </ButtonWrapper>
    </div>
  );
};

const CheckoutForm = withFormik({
  mapPropsToValues: ({ ticketSelections, storeOfTicketPlusAttendee }) => {
    const ticketAttendeeInputs = ticketSelections.map(item => ({
      name: item.name,
      ticket_id: item.id,
      quantity: item.quantity,
      attendees: times(item.quantity).map(quantity => ({
        name: `${item.name} - Attendee ${quantity + 1}`,
        attributes: item.attributes,
      })),
    }));

    return {
      ticketSelections: storeOfTicketPlusAttendee || ticketAttendeeInputs,
    };
  },

  validationSchema: yup.object().shape({
    ticketSelections: yup.array().of(
      yup.object().shape({
        attendees: yup.array().of(
          yup.object().shape({
            attributes: yup.array().of(
              yup.object().shape(
                {
                  required: yup.bool(),
                  name: yup.string(),
                  values: yup
                    .string()
                    .when(['required'], {
                      is: required => required === true,
                      then: yup.string().required('Required'),
                    })
                    .when(['name'], {
                      is: name => name === 'email',
                      then: yup.string().email('Please enter valid email'),
                    }),
                },
                ['required'],
              ),
            ),
          }),
        ),
      }),
    ),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setSubmitting(false);
  },

  displayName: 'AttendeeForm',
})(Form);

export default CheckoutForm;
