import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import GetImages from '../../../components/GetImages';

import { Text } from '../../../components/elements';
// import AvatarWithText from '../../../../../components/loader/AvatarWithText';

const Container = styled.article`
  padding-bottom: 1rem;
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const fetchPlaceQuery = gql`
  query fetch_place($input: NavigatePlaceInput) {
    fetch_place(input: $input) {
      place_id
      name
      tagline
      description
      address_line_1
      city
      state
      post_code
      country
    }
  }
`;

const ReviewedPlaceHeader = ({ placeId, children }) => {
  return (
    <Query
      query={fetchPlaceQuery}
      variables={{ input: { place_id: placeId } }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return '';
        }
        if (error) {
          return '';
        }
        const { fetch_place } = data;
        return (
          <Container className="media">
            <figure className="media-left">
              <GetImages
                name={fetch_place.name}
                maskProps={{ width: 64, height: 64 }}
                variables={{
                  object_type: ['PLACE'],
                  object_id: fetch_place.place_id,
                  links_to_id: [fetch_place.place_id],
                  links_to_type: ['PLACE'],
                }}
              />
            </figure>
            <div className="media-content">
              <div className="content">
                <div>
                  <Text className="is-capitalized" size="small" weight="semibold">
                    {fetch_place.name}
                  </Text>
                  <Text size="micro" color="darkGrey">
                    {fetch_place.address_line_1} <br /> {fetch_place.city}
                    {fetch_place.post_code}, {fetch_place.state}, {fetch_place.country}
                  </Text>
                </div>
              </div>
            </div>
            <div className="media-right">{children}</div>
          </Container>
        );
      }}
    </Query>
  );
};

ReviewedPlaceHeader.defaultProps = {};

ReviewedPlaceHeader.propTypes = {};

export default ReviewedPlaceHeader;
