import React from 'react';
import ContentLoader from 'react-content-loader';
import theme from '../../theme';

const RectLoader = ({ x, y, width, height, children }) => (
  <>
    <ContentLoader
      height={height}
      width={window.innerWidth}
      speed={2}
      primaryColor={theme.colors.lightGreyBeta}
      secondaryColor={theme.colors.lightGreyAlpha}
    >
      <rect x={`${x}`} y={`${y}`} rx="0" ry="0" width={`${width}`} height={`${height}`} />
      {children}
    </ContentLoader>
  </>
);

export default RectLoader;
