import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';

import { Text } from '../../../../components/elements';
import MenuItem from './MenuItem';
import client from '../../../../utils/apolloClient';

const searchServiceSettingQuery = gql`
  query searchServiceSetting($input: SearchInput) {
    search_service_type_setting(input: $input) {
      service_type_setting_listing {
        place_id
        service_type
        description
      }
    }
  }
`;

const Container = styled.div`
  padding: 0rem 0rem;
`;
const Wrapper = styled.div``;

const Header = styled.div`
  padding: 1rem 0rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  margin-bottom: 0rem;
`;

const Menu = ({ place, handleElement }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    client.clientPublic
      .query({
        query: searchServiceSettingQuery,
        variables: {
          input: {
            filter: {
              service_type_setting_filter: { place_id: place.place_id },
            },
          },
        },
      })
      .then(({ data }) => {
        if (
          data.search_service_type_setting &&
          data.search_service_type_setting.service_type_setting_listing &&
          data.search_service_type_setting.service_type_setting_listing.length !== 0
        ) {
          setServices(data.search_service_type_setting.service_type_setting_listing);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [place]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading || services.length === 0) {
    return null;
  }

  return (
    <Container>
      <Header>
        <Text size="large" weight="semibold">
          Menu
        </Text>
      </Header>
      {/* <PlaceReviewActionButton placeId={place.place_id} placeName={place.name} /> */}
      <View handleElement={handleElement} services={services} />
    </Container>
  );
};

const View = ({ handleElement, services }) => {
  useEffect(() => {
    handleElement('menuElements');
  }, [handleElement]);

  return (
    <Wrapper>
      {services.map(item => (
        <MenuItem
          placeId={item.place_id}
          title={item.service_type}
          description={item.description}
        />
      ))}
    </Wrapper>
  );
};

export default Menu;
