import React, { useState } from 'react';
import styled from 'styled-components';
import { MdAdd } from 'react-icons/md';
import { useFormik } from 'formik';

import {
  FlexRows,
  Text,
  ScrollToTopButton,
  SEO,
  MobileInput,
  Pager,
  Input,
} from '../../components/elements';
import YourOrder from './components/desktop/YouOrder';
import colors from '../../theme/styles/colors';
import DiningOptionsModal from './components/desktop/DiningOptionsModal';
import AddNewAddressModal from './components/desktop/AddNewAddressModal';
import AddPaymentMethodModal from './components/desktop/AddPaymentMethodModal';
import Loading from '../../components/global/mobile/MobileLoading';

const Container = styled.div`
  .order-status {
    padding-top: 1rem;
  }
  .review-and-place-order {
    padding-top: 1rem;
  }
`;

const DiningOption = styled.div`
  padding-bottom: 2rem;
  .change {
    margin-left: 10rem;
  }
`;

const DeliveryAddress = styled.div`
  padding-bottom: 2rem;
`;

const AddDeliveryAddress = styled.div`
  border-radius: 4px;
  padding: 0.5rem;
  border: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  width: 35.5%;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const OrderingFood = styled.div`
  margin-bottom: 2rem;
  width: 35%;
  .field:not(:last-child) {
    margin-bottom: 1.75rem;
  }
`;

const Help = styled.p`
  margin-top: 5px;
`;

const Payment = styled.div`
  padding-bottom: 2rem;
`;

const Attendees = styled.div`
  padding-bottom: 2rem;
  display: flex;
  .control {
    margin-left: 3rem;
  }

  .input {
    width: 4rem;
    border: 1px solid #e2e2e2;
    border-radius: 0;
    padding-left: 1rem;
  }
`;

const DesktopView = ({
  order,
  dataBaseLineItem,
  loading,
  onSubmit,
  actionLoading,
  state,
  func,
  setToMainOrderCart,
  cartUpdateLoading,
}) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [deliveryAddressActive, setDeliveryAddressActive] = useState(false);
  const [paymentMethodModal, setPaymentMethodModal] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address_line_1:
        state.deliveryAddress && state.deliveryAddress.address_line_1
          ? state.deliveryAddress.address_line_1
          : '',
      payment_method: null,
      contact_name: state.name || null,
      contact_phone: state.mobile || null,
      no_of_attendees: null,
      include_cuttlery: false,
      notes: '',
    },
    validate: values => {
      const errors = {};
      if (order.service_type === 'Delivery' && !values.address_line_1) {
        errors.address_line_1 = 'Delivery address is required';
      }
      if (
        order.service_type === 'Delivery' &&
        values.address_line_1 &&
        values.address_line_1.length === 0
      ) {
        errors.address_line_1 = 'Delivery address is required';
      }

      if (!values.contact_name) {
        errors.contact_name = 'Please enter name';
      }
      if (!values.contact_phone) {
        errors.contact_phone = 'Please enter mobile number';
      }
      if (!values.payment_method) {
        errors.payment_method = 'Please select payment method';
      }
      if (order.service_type === 'Dinein' && !values.no_of_attendees) {
        errors.no_of_attendees = 'Please enter the value of attendees';
      }

      return errors;
    },
    onSubmit,
  });

  if (loading) {
    return <Loading />;
  }

  const { values, handleChange, handleBlur, setFieldValue, errors, touched, handleSubmit } = formik;

  return (
    <Pager>
      <Container>
        <SEO title="" description="" />
        <DiningOptionsModal isActiveModal={isActiveModal} setIsActiveModal={setIsActiveModal} />
        <AddNewAddressModal
          isActiveModal={deliveryAddressActive}
          setIsActiveModal={setDeliveryAddressActive}
          state={state}
          func={func}
          place={order.place}
          setAddressLine={setFieldValue}
        />
        <AddPaymentMethodModal
          values={values}
          setFieldValue={setFieldValue}
          isActiveModal={paymentMethodModal}
          setIsActiveModal={setPaymentMethodModal}
        />
        <div className="columns">
          <div className="column is-two-thirds">
            <Text
              weight="semibold"
              size="bmedium"
              paddingBottom="2"
              className="review-and-place-order"
            >
              Review and Place Order
            </Text>
            <DiningOption>
              <Text size="medium" weight="semibold" paddingBottom={1.5}>
                Dining Options
              </Text>
              <FlexRows justify="flex-start">
                <Text size="smedium" weight="regular" paddingBottom={0.5}>
                  {order.service_type} ASAP (15-25m)
                </Text>
                {/* <Text
                size="smedium"
                weight="regular"
                paddingBottom={0.5}
                color="primaryColor"
                className="change"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsActiveModal(true)}
              >
                Change
              </Text> */}
              </FlexRows>
            </DiningOption>
            <DeliveryAddress>
              <Text size="medium" weight="semibold" paddingBottom={1.5}>
                {order.service_type} Address
              </Text>
              {order.service_type === 'Delivery' ? (
                <>
                  {state.deliveryAddress && state.deliveryAddress.address_line_1 ? (
                    <FlexRows justify="space-between" style={{ width: '60%' }}>
                      <Text size="small">
                        {state.deliveryAddress.address_line_1}, {state.deliveryAddress.city}{' '}
                        {state.deliveryAddress.state} {state.deliveryAddress.post_code}
                      </Text>
                      <Text
                        size="smedium"
                        weight="regular"
                        color="primaryColor"
                        className="change"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setDeliveryAddressActive(true)}
                      >
                        Change
                      </Text>
                    </FlexRows>
                  ) : (
                    <AddDeliveryAddress
                      onClick={() => {
                        setDeliveryAddressActive(true);
                      }}
                    >
                      <FlexRows justify="flex-start">
                        <MdAdd
                          size={30}
                          style={{ cursor: 'pointer', marginRight: '1rem' }}
                          color={colors.primaryColor}
                        />
                        <Text size="small" color="primaryColor">
                          Add New Address
                        </Text>
                      </FlexRows>
                    </AddDeliveryAddress>
                  )}
                  {errors.address_line_1 && touched.address_line_1 && (
                    <Help className="has-text-danger">{errors.address_line_1}</Help>
                  )}
                </>
              ) : (
                <Text size="small">
                  {order.place.address_line_1}, {order.place.city} {order.place.state}{' '}
                  {order.place.post_code}
                </Text>
              )}
            </DeliveryAddress>
            {order.service_type === 'Dinein' && (
              <Attendees>
                <div>
                  <Text size="medium" weight="semibold">
                    Number of Attendees
                  </Text>
                  {errors.no_of_attendees && touched.no_of_attendees && (
                    <Help className="has-text-danger">{errors.no_of_attendees}</Help>
                  )}
                </div>

                <div className="control">
                  <input
                    className="input"
                    type="number"
                    placeholder="3"
                    values={values.no_of_attendees}
                    onChange={e => {
                      if (e.target.value === '') {
                        setFieldValue('no_of_attendees', null);
                      } else {
                        setFieldValue('no_of_attendees', parseFloat(e.target.value).toFixed(0));
                      }
                    }}
                  />
                </div>
              </Attendees>
            )}

            <OrderingFood>
              <Text size="medium" weight="semibold" paddingBottom={1.5}>
                Ordering For
              </Text>
              <div className="field">
                <label className="label">Name</label>
                <div className="control">
                  <Input
                    type="text"
                    name="contact_name"
                    value={values.contact_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.contact_name && touched.contact_name && (
                  <Help className="has-text-danger">{errors.contact_name}</Help>
                )}
              </div>
              <div className="field">
                <label className="label">Mobile</label>
                <div className="control">
                  <MobileInput
                    name="mobile"
                    value={values.contact_phone}
                    onChange={value => setFieldValue('contact_phone', value)}
                  />
                </div>
                {errors.contact_phone && touched.contact_phone && (
                  <Help className="has-text-danger">{errors.contact_phone}</Help>
                )}
              </div>
            </OrderingFood>
            <Payment>
              <Text size="medium" weight="semibold" paddingBottom={1.5}>
                Payment
              </Text>
              {values.payment_method ? (
                <Text size="small" paddingBottom={1.5}>
                  {values.payment_method === 'cash' && 'Cash'}
                </Text>
              ) : (
                <AddDeliveryAddress onClick={() => setPaymentMethodModal(true)}>
                  <FlexRows justify="flex-start">
                    <MdAdd
                      size={30}
                      style={{ cursor: 'pointer', marginRight: '1rem' }}
                      color={colors.primaryColor}
                    />
                    <Text size="small" color="primaryColor">
                      Add payment method
                    </Text>
                  </FlexRows>
                </AddDeliveryAddress>
              )}
              {errors.payment_method && touched.payment_method && (
                <div className="has-text-danger">{errors.payment_method}</div>
              )}
            </Payment>
          </div>
          <div className="column">
            <YourOrder
              cartUpdateLoading={cartUpdateLoading}
              state={state}
              dataBaseLineItem={dataBaseLineItem}
              actionLoading={actionLoading}
              onSubmit={handleSubmit}
              loading={loading}
              placeName={order.place ? order.place.name : ''}
              order={order}
              values={values}
              setFieldValue={setFieldValue}
              setToMainOrderCart={setToMainOrderCart}
            />
          </div>
        </div>
        <ScrollToTopButton />
      </Container>
    </Pager>
  );
};

// eslint-disable-next-line react/no-redundant-should-component-update

export default DesktopView;
