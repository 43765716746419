import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { isNull } from 'lodash';

import client from '../../../utils/apolloClient';
import ApplyVoucherModal from './ApplyVoucherModal';
import { Text } from '../../../components/elements';

const validateCouponQuery = gql`
  query validateCoupon($input: ValidatePlaceEventVoucherInput) {
    validate_place_event_voucher(input: $input) {
      discount
      error {
        code
        description
      }
    }
  }
`;

const Container = styled.div`
  padding: 0.5rem 1rem;
`;

const Form = props => {
  const [isActive, setIsActive] = useState(false);
  const {
    handleSubmit,
    isCouponApplied,
    removedCoupon,
    setFieldValue,
    values,
    setIsCouponModalActive,
    allVouchers,
  } = props;

  const handleCoupon = async value => {
    await setFieldValue('voucherCode', value);
    handleSubmit();
  };
  useEffect(() => {
    setIsActive(false);
  }, [isCouponApplied]);

  return (
    <Container>
      {isCouponApplied ? (
        <>
          <Text color="primaryColor" size="tiny">
            Promo Code &apos;{values.voucherCode}&apos; has been applied
          </Text>
          <a className="button is-light is-medium is-fullwidth" onClick={() => removedCoupon()}>
            <Text color="danger" size="small">
              <span> Remove Promo Code</span>
            </Text>
          </a>
        </>
      ) : (
        <a
          className="button  is-light is-medium is-fullwidth"
          onClick={() => {
            setIsCouponModalActive(true);
            setFieldValue('voucherCode', '');
            setIsActive(true);
          }}
        >
          <Text color="primaryColor" size="small">
            <span> Apply Promo Code</span>
          </Text>
        </a>
      )}
      <ApplyVoucherModal
        allVouchers={allVouchers}
        isActive={isActive}
        handleCoupon={handleCoupon}
        isClose={() => {
          setIsCouponModalActive(false);
          setIsActive(false);
        }}
        {...props}
      />
    </Container>
  );
};

const ApplyVoucherForm = withFormik({
  mapPropsToValues: ({ subTotalPrice }) => ({
    voucherType: 'apply',
    voucherCode: '',
    defaultDiscountPrice: '',
    subTotalPrice,
  }),

  validate: values => {
    const errors = {};
    if (parseFloat(values.defaultDiscountPrice) > values.subTotalPrice) {
      errors.defaultDiscountPrice = 'Discount price is less than or equal to sub total';
    }
    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    props.handleApplyCouponLoading(true);
    setTimeout(() => {
      const { eventId, eventOccurrenceId, placeId, tickets, subTotalPrice } = props;
      client.clientPublic
        .query({
          query: validateCouponQuery,
          variables: {
            input: {
              event_id: eventId,
              event_occurrence_id: eventOccurrenceId,
              place_id: placeId,
              voucher_code: values.voucherCode,
              tickets: tickets.map(item => ({
                ticket_id: item.id,
                price: item.price,
                quantity: item.quantity,
              })),
            },
          },
        })
        .then(({ data: { validate_place_event_voucher } }) => {
          console.log(validate_place_event_voucher);
          if (!isNull(validate_place_event_voucher.error)) {
            validate_place_event_voucher.error.map(item => toast.error(item.description));
            props.handleApplyCouponLoading(false);
          } else {
            const discountPrice =
              subTotalPrice < validate_place_event_voucher.discount
                ? subTotalPrice
                : validate_place_event_voucher.discount;
            props.handleDisCount(discountPrice, values.voucherCode);
            props.handleApplyCouponLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          props.handleApplyCouponLoading(false);
        });

      setSubmitting(false);
    }, 100);
  },

  displayName: 'ApplyVoucherForm',
})(Form);

export default ApplyVoucherForm;
