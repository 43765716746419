import React, { useState } from 'react';
import styled from 'styled-components';
import { capitalize, first } from 'lodash';
import gql from 'graphql-tag';
import { MdChevronLeft } from 'react-icons/md';
import uuid from 'uuid';
import { Modal, SearchInput, Text, FlexRows, FlexColumns } from '../../../../components/elements';

import client from '../../../../utils/apolloClient';
import Loading from '../../../../components/global/mobile/MobileLoading';

import SelectItem from './SelectItem';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const ModalHeaderLeftWrapper = styled.div`
  position: absolute;
  left: 0.5rem;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const TextWrapper = styled.div`
  padding-left: 1rem;
`;

const ItemWrapper = styled(FlexRows)`
  padding: 0.5rem;
`;

const Item = styled(ItemWrapper)`
  &&& {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
    padding: 1rem 0rem;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  padding: 1rem 0.5rem;
`;

const AddItemWrapper = styled(FlexColumns)`
  &&& {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
    padding: 1rem 0rem;
  }
`;

const Span = styled.span`
  color: ${({ theme: { colors } }) => colors.dark};
`;

const itemsDictionaryQuery = gql`
  query itemsDictionary($input: SearchInput) {
    search_item_dictionary(input: $input) {
      item_dictionary_listing {
        item_dictionary_id
        name
      }
    }
  }
`;

const itemsQuery = gql`
  query itemsQuery($input: SearchInput) {
    search_items(input: $input) {
      item_listing {
        item_id
        item_dictionary_id
        place_id
        place_name
        name
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        timezone
      }
    }
  }
`;

const SuggestionItem = ({ dictionary, onSelect }) => (
  <a onClick={onSelect}>
    <Item justify="flex-start" align="flex-start">
      <TextWrapper>
        <Text className="is-capitalized" size="small" weight="semibold">
          {capitalize(dictionary.name)}
        </Text>
      </TextWrapper>
    </Item>
  </a>
);

const SelectItemDictionary = ({ isActive, onClose, placeData, values, setFieldValue }) => {
  const [dictionaryItems, setDictionaryItems] = useState([]);
  const [queryTag, setQueryTag] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [itemName, setItemName] = useState(values.item_name);
  const [itemId, setItemId] = useState(values.item_id);
  const [itemAction, setItemAction] = useState(values.itemAction);
  const [dictionaryAction, setDictionaryAction] = useState(values.dictionaryAction);
  const [dictionaryName, setDictionaryName] = useState(values.item_name);
  const [dictionaryId, setDictionaryId] = useState(values.item_dictionary_id);

  const onSuggestionsFetchPlaceRequested = value => {
    setQueryTag(value);
    setLoading(true);
    setStep(1);
    client.clientPublic
      .query({
        query: itemsDictionaryQuery,
        variables: {
          input: {
            filter: { item_dictionary_filter: { name: value, status: 'ACTIVE' } },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const dictionaryItemsData = data.search_item_dictionary.item_dictionary_listing;
        setDictionaryItems(dictionaryItemsData);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const checkItemExistOrNot = (itemDictionaryId, name) => {
    client.clientPublic
      .query({
        query: itemsQuery,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            filter: {
              item_filter: {
                item_dictionary_id: itemDictionaryId,
                place_id: placeData.place_id,
                status: 'ACTIVE',
              },
            },
          },
        },
      })
      .then(({ data }) => {
        const itemData =
          data.search_items.item_listing.length !== 0
            ? first(data.search_items.item_listing)
            : null;
        if (itemData) {
          setItemName(itemData.name);
          setItemId(itemData.item_id);
          setDictionaryName(name);
          setDictionaryId(itemData.item_dictionary_id);
          setItemAction('existed');
          setDictionaryAction('existed');
          setStep(2);
        } else {
          setItemId(uuid());
          setItemName(name);
          setDictionaryName(name);
          setDictionaryId(itemDictionaryId);
          setItemAction('new');
          setDictionaryAction('existed');
          setStep(2);
        }

        // const item =
        //   data.search_items.item_listing.length !== 0
        //     ? first(data.search_items.item_listing)
        //     : null;
        // if (!isNull(item)) {
        //   onHandleItemSelection({
        //     ...item,
        //     item_dictionary_id: itemDictionaryId,
        //   });
        // } else {
        //   handleItemName(value, itemDictionaryId);
        // }
      });
  };

  return (
    <Modal isActive={isActive}>
      <ModalHeader align="center">
        <ModalHeaderLeftWrapper>
          <a onClick={onClose}>
            <Text color="white">
              <MdChevronLeft size={30} />
            </Text>
          </a>
        </ModalHeaderLeftWrapper>
        <FlexRows>
          <Text className="is-uppercase" weight="semibold" size="lmedium" color="white">
            Item Name
          </Text>
        </FlexRows>
      </ModalHeader>
      <InputWrapper>
        <SearchInput
          placeholder="Search by typing place item name"
          value={queryTag}
          onChange={event => onSuggestionsFetchPlaceRequested(event.target.value)}
          onClear={() => {
            setDictionaryItems([]);
            setQueryTag('');
            setStep(2);
          }}
        />
      </InputWrapper>

      <Content>
        {step === 1 && (
          <>
            {queryTag.length !== 0 && (
              <a
                onClick={() => {
                  setItemName(queryTag);
                  setItemId(uuid());
                  setDictionaryId(uuid());
                  setDictionaryName(queryTag);
                  setItemAction('new');
                  setDictionaryAction('new');
                  setStep(2);
                }}
              >
                <AddItemWrapper>
                  <Text className="is-capitalized" size="small" weight="semibold">
                    Be the first to share the dish
                  </Text>
                  <Text
                    className="is-uppercase"
                    size="smaller"
                    color="primaryColor"
                    weight="semibold"
                  >
                    Would You Like to add <Span>`{queryTag}`</Span> ?
                  </Text>
                </AddItemWrapper>
              </a>
            )}
            {loading ? (
              <Loading />
            ) : (
              <>
                {dictionaryItems.map(dictionary => (
                  <SuggestionItem
                    dictionary={dictionary}
                    onSelect={() => {
                      checkItemExistOrNot(dictionary.item_dictionary_id, dictionary.name);
                    }}
                  />
                ))}
              </>
            )}
          </>
        )}
        {step === 2 && itemName && (
          <SelectItem
            itemName={itemName}
            onFinalItemSubmission={(itemType, cuisines) => {
              setFieldValue('item_id', itemId);
              setFieldValue('item_name', itemName);
              setFieldValue('itemAction', itemAction);
              setFieldValue('item_dictionary_id', dictionaryId);
              setFieldValue('dictionary', {
                item_dictionary_id: dictionaryId,
                name: dictionaryName,
              });
              setFieldValue('dictionaryAction', dictionaryAction);
              setFieldValue('item_type', itemType);
              setFieldValue('cuisine_style', cuisines);
              onClose();
            }}
          />
        )}
      </Content>
    </Modal>
  );
};

export default SelectItemDictionary;
