import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { Text, FlexColumns } from '../../../../components/elements';
import MobileEventCard from '../../../Events/components/mobile/MobileEventCard';
import { getZoneTime } from '../../../../utils/eventHelpers';
import theme from '../../../../theme';

const eventsQuery = gql`
  query events($input: SearchInput) {
    search_events(input: $input) {
      event_listing {
        event_id
        name
        tagline
        place_name
        slug
        city
        post_code
        start
        end
        maximum_price
        minimum_price
        address_line_1
        city
        state
        latitude
        longitude
        post_code
        venue_name
        city
        state
        featured
        voucher_count
        default_image_url
      }
    }
  }
`;

const Container = styled.div`
  padding: 3rem 1rem;
`;

const MessageContainer = styled(FlexColumns)`
  height: 50vh;
`;

const Events = ({ place }) => {
  const [eventDateFilter, setEventDateFilter] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const date = await getZoneTime(moment(), place.latitude, place.longitude);
      setEventDateFilter(moment(date).toISOString());
    };
    fetchData();
  }, [place.latitude, place.longitude]);

  const event_filter = {};

  Object.assign(
    event_filter,
    { place_name: place.name },
    { status: 'ACTIVE' },
    eventDateFilter !== '' && { end_date_range: { start_date: eventDateFilter } },
  );

  return (
    <Container>
      <Query
        query={eventsQuery}
        variables={{ input: { filter: { event_filter } } }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return (
              <MessageContainer>
                <ReactLoading type="spin" color={theme.primaryColor} width={50} height={50} />
              </MessageContainer>
            );
          }
          if (error) {
            return (
              <MessageContainer>
                <Text weight="semibold" color="darkGrey">
                  {error.message}
                </Text>
              </MessageContainer>
            );
          }
          return <View events={data.search_events.event_listing} />;
        }}
      </Query>
    </Container>
  );
};

const View = ({ events }) => {
  if (events.length === 0) {
    return (
      <MessageContainer>
        <Text weight="semibold" color="darkGrey">
          No Event Found
        </Text>
      </MessageContainer>
    );
  }

  return (
    <div className="columns is-multiline">
      {events.map(event => (
        <MobileEventCard event={event} />
      ))}
    </div>
  );
};

export default Events;
