import React, { useState } from 'react';
import styled from 'styled-components';
import { take } from 'lodash';
import { useStoreState } from 'easy-peasy';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

import { Text, CheckBox, FlexRows } from '../../../../components/elements';
import PriceRangeFilter from './PriceRangeFilter';

const Container = styled.div`
  &&& {
    padding-top: 0.5rem;
    background-color: ${({ theme: { colors } }) => colors.white};
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.04);
    margin-right: 1rem;
    border-radius: 4px;
  }
`;
const TopHeading = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding: 0rem 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;
const Wrapper = styled.div`
  padding-bottom: 0.5rem;
  /* border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyAlpha}; */
`;

const Item = styled.div``;
const ItemContent = styled.div`
  padding: 0.25rem 1rem;
`;

const Heading = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding-top: 0.25rem;
  padding-right: 1rem;
`;
const CheckBoxWrapper = styled.div`
  padding: 0rem;
`;

const Filter = ({ heading, items, OnClick, initialInputs, onHandleClearItem }) => {
  const [numberOfItems, setNumberOfItems] = useState(5);
  const data = take(items, numberOfItems);
  const [toggle, setToggle] = useState(true);

  return (
    <Item>
      <Heading>
        <div className="is-flex">
          <span className="icon">
            <a onClick={() => setToggle(!toggle)}>
              {toggle ? (
                <Text color="dark" size="lmedium" weight="medium">
                  <FaAngleUp />
                </Text>
              ) : (
                <Text color="dark" size="lmedium" weight="medium">
                  <FaAngleDown />
                </Text>
              )}
            </a>
          </span>
          <Text className="is-capitalized" color="dark" size="lmedium" weight="regular">
            {heading}
          </Text>
        </div>

        {initialInputs.length !== 0 && (
          <a onClick={onHandleClearItem}>
            <Text className="is-uppercase" color="primaryColor" size="micro" weight="semibold">
              Clear
            </Text>
          </a>
        )}
      </Heading>
      {toggle && (
        <ItemContent>
          <CheckBoxWrapper>
            {data.map(item => (
              <CheckBox
                initialValue={initialInputs.filter(item2 => item2.name === item.name).length === 1}
                label={`${item.description} (${item.count})`}
                onChange={value => OnClick(item, value)}
              />
            ))}
          </CheckBoxWrapper>
          {items.length - 5 > 0 && (
            <a
              onClick={() =>
                items.length !== numberOfItems
                  ? setNumberOfItems(items.length)
                  : setNumberOfItems(5)
              }
            >
              <Text
                color="primaryColor"
                size="tiny"
                weight="semibold"
                style={{ paddingTop: '10px' }}
              >
                {items.length === numberOfItems ? 'Show Less' : `Show All`}{' '}
                {items.length !== numberOfItems && (
                  <span style={{ color: '#737373' }}>({items.length - 5} more)</span>
                )}
              </Text>
            </a>
          )}
        </ItemContent>
      )}
    </Item>
  );
};

const Filters = ({ filterFuncInputs }) => {
  const filterTags = useStoreState(state => state.place.filterTags);
  const filterInputs = useStoreState(state => state.place.filterInputs);
  const {
    cuisines,
    itemTypes,
    businessTypes,
    features,
    serviceType,
    dressCode,
    diningStyle,
    publicTransports,
    ambience,
    priceRange,
  } = filterTags;
  return (
    <Container>
      <Wrapper>
        <TopHeading>
          <Text className="is-capitalized" size="large" weight="semibold">
            Refine Search
          </Text>
          <a onClick={() => filterFuncInputs.clearFilters()}>
            <Text size="micro" weight="semibold" color="primaryColor">
              CLEAR ALL
            </Text>
          </a>
        </TopHeading>
      </Wrapper>
      {cuisines.length !== 0 && (
        <Wrapper>
          <Filter
            heading="Cuisine"
            items={cuisines}
            initialInputs={filterInputs.cuisines}
            OnClick={(item, value) =>
              value
                ? filterFuncInputs.addCuisineInputs(item)
                : filterFuncInputs.removeCuisineInputs(item.name)
            }
            onHandleClearItem={() => filterFuncInputs.onHandleClearItem('cuisines')}
          />
        </Wrapper>
      )}
      {itemTypes.length !== 0 && (
        <Wrapper>
          <Filter
            heading="Item Type"
            items={itemTypes}
            initialInputs={filterInputs.itemTypes}
            OnClick={(item, value) =>
              value
                ? filterFuncInputs.addItemTypeInputs(item)
                : filterFuncInputs.removeItemTypeInputs(item.name)
            }
            onHandleClearItem={() => filterFuncInputs.onHandleClearItem('itemType')}
          />
        </Wrapper>
      )}
      {businessTypes.length !== 0 && (
        <Wrapper>
          <Filter
            heading="Business Types"
            items={businessTypes}
            initialInputs={filterInputs.businessTypes}
            OnClick={(item, value) =>
              value
                ? filterFuncInputs.addBusinessTypeInputs(item)
                : filterFuncInputs.removeBusinessTypeInputs(item.name)
            }
            onHandleClearItem={() => filterFuncInputs.onHandleClearItem('businessType')}
          />
        </Wrapper>
      )}
      {features.length !== 0 && (
        <Wrapper>
          <Filter
            heading="Features"
            items={features}
            initialInputs={filterInputs.features}
            OnClick={(item, value) =>
              value
                ? filterFuncInputs.addFeatureInputs(item)
                : filterFuncInputs.removeFeatureInputs(item.name)
            }
            onHandleClearItem={() => filterFuncInputs.onHandleClearItem('feature')}
          />
        </Wrapper>
      )}
      {serviceType.length !== 0 && (
        <Wrapper>
          <Filter
            heading="Service Types"
            items={serviceType}
            initialInputs={filterInputs.serviceTypes}
            OnClick={(item, value) =>
              value
                ? filterFuncInputs.addServiceTypeInputs(item)
                : filterFuncInputs.removeServiceTypeInputs(item.name)
            }
            onHandleClearItem={() => filterFuncInputs.onHandleClearItem('serviceType')}
          />
        </Wrapper>
      )}
      {dressCode.length !== 0 && (
        <Wrapper>
          <Filter
            heading="Dress Codes"
            items={dressCode}
            initialInputs={filterInputs.dressCodes}
            OnClick={(item, value) =>
              value
                ? filterFuncInputs.addDressCodeInputs(item)
                : filterFuncInputs.removeDressCodeInputs(item.name)
            }
            onHandleClearItem={() => filterFuncInputs.onHandleClearItem('dressCode')}
          />
        </Wrapper>
      )}
      {diningStyle.length !== 0 && (
        <Wrapper>
          <Filter
            heading="Dining Styles"
            items={diningStyle}
            initialInputs={filterInputs.diningStyles}
            OnClick={(item, value) =>
              value
                ? filterFuncInputs.addDiningStyleInputs(item)
                : filterFuncInputs.removeDiningStyleInputs(item.name)
            }
            onHandleClearItem={() => filterFuncInputs.onHandleClearItem('diningStyle')}
          />
        </Wrapper>
      )}
      {publicTransports.length !== 0 && (
        <Wrapper>
          <Filter
            heading="Public Transports"
            items={publicTransports}
            initialInputs={filterInputs.publicTransports}
            OnClick={(item, value) =>
              value
                ? filterFuncInputs.addPublicTransportInputs(item)
                : filterFuncInputs.removePublicTransportInputs(item.name)
            }
            onHandleClearItem={() => filterFuncInputs.onHandleClearItem('publicTrans')}
          />
        </Wrapper>
      )}
      {ambience.length !== 0 && (
        <Wrapper>
          <Filter
            heading="Ambience"
            items={ambience}
            initialInputs={filterInputs.ambiences}
            OnClick={(item, value) =>
              value
                ? filterFuncInputs.addAmbienceInputs(item)
                : filterFuncInputs.removeAmbienceInputs(item.name)
            }
            onHandleClearItem={() => filterFuncInputs.onHandleClearItem('ambience')}
          />
        </Wrapper>
      )}
      <Wrapper>
        <PriceRangeFilter
          priceRangeInputs={filterInputs.priceRanges ? filterInputs.priceRanges : []}
          filterFuncInputs={filterFuncInputs}
          priceRange={priceRange}
        />
      </Wrapper>
    </Container>
  );
};

export default Filters;
