import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { MdDone, MdClear } from 'react-icons/md';

import { Modal, Text, FlexRows } from '../../../../components/elements';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  padding: 0.5rem;
  width: 100%;
`;

const ModalFooter = styled.a`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem;
  position: absolute;
  bottom: 0;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const Header = styled.div`
  background-color: ${({ theme: { colors } }) => colors.lightGreyBeta};
  padding: 1rem;
`;

const Flex = styled(FlexRows)`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.specialGrey};
`;
const Wrapper = styled.div`
  padding: 1rem;
`;

const Item = ({ title, isIconActive, onSelect }) => (
  <a onClick={onSelect}>
    <Flex justify="space-between">
      <div>
        <Text>{title}</Text>
      </div>
      <div className="is-flex">
        {isIconActive && (
          <>
            <Text color="primaryColor">
              <MdDone size={25} />
            </Text>
          </>
        )}
      </div>
    </Flex>
  </a>
);

const MobileFilterModal = ({
  isActive,

  cuisineStyleInputs,
  addCuisineStyle,
  removeCuisineStyle,

  itemTypeInputs,
  addItemTypes,
  removeItemTypes,

  setActiveFilterModal,
  setToMainFilter,
  clearFilter,
}) => {
  const filterTags = useStoreState(state => state.item.filterTags);
  const { cuisines, itemTypes } = filterTags;
  return (
    <Modal isActive={isActive}>
      <ModalHeader>
        <FlexRows justify="space-between">
          <a onClick={() => setActiveFilterModal(false)}>
            <Text color="white">
              <MdClear size={25} />
            </Text>
          </a>
          <a onClick={clearFilter}>
            <Text className="is-uppercase" color="white" weight="semibold" size="small">
              Clear All
            </Text>
          </a>
        </FlexRows>
        {/* <div className="columns is-mobile">
          <div className="column is-2">
          </div>
          <div className="column">
            <Text className="is-uppercase" align="center" color="white" weight="bold" size="small">
              Filter
            </Text>
          </div>
          <div className="column is-4">
            
          </div>
        </div> */}
      </ModalHeader>
      <Content>
        <Header>
          <Text className="is-uppercase" weight="semibold" lineSpacing="loose">
            Item Types
          </Text>
        </Header>
        {itemTypes.length === 0 && (
          <Wrapper>
            <Text>No item types filter Found</Text>
          </Wrapper>
        )}
        {itemTypes.map(type => {
          const typeActive = itemTypeInputs.filter(item => item.name === type.name);
          const activeElement = typeActive.length !== 0;
          return (
            <Item
              key={type.name}
              title={`${type.description} `}
              isIconActive={activeElement}
              onSelect={() => (activeElement ? removeItemTypes(type.name) : addItemTypes(type))}
            />
          );
        })}

        <Header>
          <Text className="is-uppercase" weight="semibold">
            Cuisine Style
          </Text>
        </Header>
        {cuisines.length === 0 && (
          <Wrapper>
            <Text>No item types filter Found</Text>
          </Wrapper>
        )}
        {cuisines.map(type => {
          const typeActive = cuisineStyleInputs.filter(item => item.name === type.name);
          const activeElement = typeActive.length !== 0;
          return (
            <Item
              key={type.name}
              title={`${type.description}`}
              isIconActive={activeElement}
              onSelect={() =>
                activeElement ? removeCuisineStyle(type.name) : addCuisineStyle(type)
              }
            />
          );
        })}
      </Content>
      <ModalFooter onClick={setToMainFilter}>
        <Text className="is-uppercase" color="white" align="center" weight="bold" size="lmedium">
          Filter
        </Text>
      </ModalFooter>
    </Modal>
  );
};

export default MobileFilterModal;
