import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
import theme from '../../theme';

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Loading = ({ width, height }) => (
  <Flex>
    <ReactLoading type="spin" color={theme.primaryColor} width={width} height={height} />
  </Flex>
);

Loading.defaultProps = {
  height: 50,
  width: 50,
};

Loading.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Loading;
