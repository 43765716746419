import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MdChevronRight } from 'react-icons/md';
import { isNull, kebabCase } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Text, FlexRows } from '../../../components/elements';
import { getTicketUrlPdf } from '../../../utils/s3';

const Container = styled.div`
  padding: 1rem 0rem;
  margin: 0rem 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const Span = styled.span`
  font-weight: bold;
`;

const MobileOrderEvent = ({ event, bookingId, orderNumber, orderDate, bookingUrl }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const getTicket = async () => {
    setLoading(true);
    const link = await getTicketUrlPdf(bookingUrl);
    setPdfUrl(link);
    setLoading(false);
  };

  useEffect(() => {
    getTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  if (isNull(event)) {
    return '';
  }
  return (
    <Container>
      <Text color="coolGrey" size="tiny" paddingBottom={0.5}>
        {moment(event.start).format('LLLL')}
      </Text>
      <Link to={`/event/${event.event_id}`}>
        <Text size="medium" weight="bold">
          {event.name}
        </Text>
      </Link>

      <Text size="small" color="darkGrey" paddingBottom={0.5}>
        {event.place_name} <br />
        {event.city}, {event.state}, {event.country}
      </Text>
      <FlexRows justify="space-between">
        <div>
          <Text size="tiny" paddingBottom={0.2}>
            Order Date: <Span>{moment(orderDate).format('LLLL')}</Span>
          </Text>
          <Text size="tiny" paddingBottom={1}>
            Order Number: <Span>{orderNumber}</Span>
          </Text>
        </div>
        <Link to={`/event/${event.event_id}`}>
          <Text color="specialGrey">
            <MdChevronRight size={25} />
          </Text>
        </Link>
      </FlexRows>
      <FlexRows justify="space-between">
        <a href={pdfUrl} download={`${kebabCase(event.name)}-ticket.pdf`} disabled={loading}>
          <Text className="is-uppercase" color="primaryColor" size="tiny" weight="semibold">
            Download Tickets
          </Text>
        </a>
        <a>
          <Text className="is-uppercase" color="primaryColor" size="tiny" weight="semibold">
            Show Tickets
          </Text>
        </a>
      </FlexRows>
    </Container>
  );
};

export default MobileOrderEvent;
