import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';

import { Flex, Text, Field, SelectField, TextArea } from '../../components/elements';
import PlaceDataCorrections from '../PlaceDataCorrections';
import { trackEvent } from '../../utils/analytics';

const Container = styled.div`
  padding: 1rem;
`;
const FieldWrapper = styled.div`
  padding: 1rem 0rem;
`;

const FieldStyle = styled(Field).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;
const SelectFieldStyle = styled(SelectField).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;
const FooterButton = styled.a`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.8rem 1rem;
  position: fixed;
  bottom: 0;
`;

const MobileForm = ({ values, touched, errors, handleChange, handleBlur, handleSubmit, loading }) => (
  <>
    <Container>
      <FieldWrapper>
        <FieldStyle
          id="name"
          name="name"
          label="Full name"
          placeholder="John"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.name && touched.name && <div className="has-text-danger">{errors.name}</div>}
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          id="mobile"
          name="mobile"
          label="Phone Number"
          placeholder="+919056349046"
          value={values.mobile}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.mobile && touched.mobile && <div className="has-text-danger">{errors.name}</div>}
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          id="email"
          name="email"
          label="Email"
          placeholder="john@gmail.com"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.email && touched.email && <div className="has-text-danger">{errors.name}</div>}
      </FieldWrapper>
      <FieldWrapper>
        <SelectFieldStyle
          id="subject"
          name="subject"
          label="Subject"
          options={['Other', 'hello']}
          value={values.subject}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.subject && touched.subject && <div className="has-text-danger">{errors.name}</div>}
      </FieldWrapper>
      <FieldWrapper>
        <label className="level">
          <Text className="is-uppercase" color="primaryColor" weight="bold" letterSpacing="loose">
            Message
          </Text>
        </label>
        <TextArea
          id="message"
          name="message"
          placeholder="Enter your message"
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.message && touched.message && <div className="has-text-danger">{errors.name}</div>}
      </FieldWrapper>
    </Container>

    <FooterButton onClick={handleSubmit}>
      <Flex>
        <Text className="is-uppercase" color="white" align="center" weight="bold" size="medium" letterSpacing="loose">
          {loading ? 'Processing...' : 'Submit'}
        </Text>
      </Flex>
    </FooterButton>
  </>
);

const MyEnhancedForm = withFormik({
  mapPropsToValues: () => ({
    name: '',
    mobile: '',
    email: '',
    subject: 'Other',
    message: '',
  }),

  // Custom sync validation
  validate: values => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.mobile) {
      errors.mobile = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    }
    if (!values.subject) {
      errors.subject = 'Required';
    }
    if (!values.message) {
      errors.message = 'Required';
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  },

  displayName: 'ContactUsForm',
})(MobileForm);

export default MyEnhancedForm;
