import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import uuid from 'uuid';
import colors from '../../../../../theme/styles/colors';
import ModifiersItemSelectionForm from './ModifiersItemSelectionForm';
import {
  parseLinkOverrideModifiersIntoGroups,
  finalModifierGroupModifiers,
  getMenuModifiersGroups,
  parseOrderDataForEdit,
  getLinks,
  finalLinkListingItemDataParser,
} from '../../../helpers';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 650px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
      position: relative;
      .cross {
        position: fixed;
        z-index: 100;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 22px;
        margin: 0.8rem;
      }
    }
    .modal-card-head {
      padding: 1rem 1rem;
      padding-bottom: 0.6rem;
      background-color: rgba(255, 255, 255, 1);
      border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
    }
    .modal-card-body {
      padding: 0rem;
    }
    a {
      color: ${colors.primaryColor};
    }
    .select:not(.is-multiple):not(.is-loading)::after {
      border-color: ${({ theme: { colors: themeColor } }) => themeColor.coolGrey};
    }
    .hide {
      display: none;
    }
  }
`;

const ModifiersModal = ({
  location,
  addToCartLoading,
  isOpen,
  menuItemFromLink,
  isActiveModal,
  onClose,
  addToOrder,
  lineItems,
}) => {
  const modalForEdit = Object.keys(menuItemFromLink).length === 4;
  const [loading, setLoading] = useState(true);
  const [modifiersGroups, setModifiersGroups] = useState([]);
  const [menuItem, setMenuItem] = useState(menuItemFromLink);

  const fetchData = useCallback(async () => {
    setModifiersGroups([]);
    setLoading(true);
    try {
      const link = await getLinks({ linkId: menuItemFromLink.item_link_id });
      const extractLinkData = finalLinkListingItemDataParser({ link: link[0], location });
      setMenuItem(extractLinkData);
      if (extractLinkData.modifier_groups_id && extractLinkData.modifier_groups_id.length !== 0) {
        const list = await getMenuModifiersGroups({
          modifiersGroupsIds: extractLinkData.modifier_groups_id,
        });
        const linkModifierGroups = parseLinkOverrideModifiersIntoGroups(
          extractLinkData.linkModifierGroup,
        );

        const groups = list.map(item => {
          const linkModifier = linkModifierGroups.filter(
            edge => edge.modifier_group_id === item.modifier_group_id,
          );
          const temp = finalModifierGroupModifiers({
            modifiers: item.modifier,
            linkModifiers: linkModifier.length !== 0 ? linkModifier[0].modifiers : [],
            location,
          });
          return { ...item, modifier: temp };
        });
        setModifiersGroups(groups);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }, [location, menuItemFromLink.item_link_id]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItemFromLink.item_link_id]);

  const currentLineItemForEdit = lineItems.filter(item => item.id === menuItemFromLink.id);

  const selectedGroupsFromOrder =
    currentLineItemForEdit.length !== 0 && currentLineItemForEdit[0].groups
      ? currentLineItemForEdit[0].groups
      : [];

  const selectedVariantFromOrder =
    currentLineItemForEdit.length !== 0 && currentLineItemForEdit[0].selectedVariant
      ? currentLineItemForEdit[0].selectedVariant
      : null;
  return (
    <Container className={`modal ${isActiveModal && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <ModifiersItemSelectionForm
          isOpen={isOpen}
          menuItem={menuItem}
          soldOut={
            currentLineItemForEdit.length !== 0 && modalForEdit
              ? currentLineItemForEdit[0].sold_out
              : 'CONTACT_ME'
          }
          modifiersGroups={parseOrderDataForEdit({
            modifiersGroups,
            modifiersOrderGroups: modalForEdit ? selectedGroupsFromOrder : [],
          })}
          quantity={
            currentLineItemForEdit.length !== 0 && modalForEdit
              ? currentLineItemForEdit[0].quantity
              : 1
          }
          selectedVariantFromOrder={modalForEdit ? selectedVariantFromOrder : null}
          isUpdate={modalForEdit}
          loading={loading}
          onClose={() => {
            setModifiersGroups([]);
            onClose();
          }}
          addToOrder={addToOrder}
          addToCartLoading={addToCartLoading}
          onSubmit={(input, func) => {
            const final = {
              id: menuItemFromLink.id || uuid(),
              menu_item_id: menuItem.menu_item_id,
              item_link_id: menuItem.item_link_id,
              ...input,
            };
            addToOrder(
              final,
              {
                func: () => {
                  func();
                  setModifiersGroups([]);
                },
              },
              modalForEdit,
            );
          }}
        />
      </div>
    </Container>
  );
};

export default ModifiersModal;
