import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';
import { forEach } from 'lodash';

const get_now_in_seconds = date_time_now => {
  return date_time_now.hour() * 3600 + date_time_now.minute() * 60 + date_time_now.second();
};

const check_open_for_day_range = (now_in_seconds, options) => {
  let temp = false;
  forEach(options, option => {
    const start_in_sec = option.start_in_sec ? option.start_in_sec : 0;
    const { end_in_sec } = option;
    if (start_in_sec <= now_in_seconds && now_in_seconds <= end_in_sec) {
      temp = true;
    }
  });
  return temp;
};

const check_if_open_in_overlapping_previous_day = (date_time_now, options, buffer_in_mins) => {
  let now_in_seconds = get_now_in_seconds(date_time_now);
  now_in_seconds += 24 * 60 * 60;
  now_in_seconds += buffer_in_mins * 60;
  return check_open_for_day_range(now_in_seconds, options);
};

const get_option_for_the_day = (menu_hours, day) => {
  let option_for_the_day = {
    option: [],
  };
  if (menu_hours && menu_hours.length !== 0) {
    option_for_the_day = menu_hours[day];
  }
  return option_for_the_day.option;
};

const get_previous_day_matching_hours = (menu_hour, date_time_now) => {
  let day = date_time_now.day();
  day = day > 0 ? day - 1 : 6;
  return get_option_for_the_day(menu_hour, day);
};

const get_matching_menu_hours = (menu_hours, date_time_now) => {
  const day = date_time_now.day();
  return get_option_for_the_day(menu_hours, day);
};

const check_if_open = (date_time_now, options, buffer_in_mins) => {
  let now_in_seconds = get_now_in_seconds(date_time_now);
  now_in_seconds += buffer_in_mins * 60;
  return check_open_for_day_range(now_in_seconds, options);
};

const get_matching_custom_hour = (custom_hours, date_time_now) => {
  const date_now = date_time_now.format('YYYY-MM-DD');
  let temp = null;
  forEach(custom_hours, custom_hour => {
    const custom_date = moment(custom_hour.day).format('YYYY-MM-DD');
    if (custom_date === date_now) {
      temp = custom_hour.option;
    }
  });
  return temp;
};

const is_menu_stopped = menu_stopped_hour => {
  const start_time = moment(menu_stopped_hour.start);
  const end_time = moment(menu_stopped_hour.end);
  const time_now = moment();
  console.log('fbefwehvfhf', moment().format('llll'), moment(menu_stopped_hour.end).format('llll'));
  if (time_now >= start_time && time_now <= end_time) {
    return true;
  }
  return false;
};

export const get_current_time_from_location = (latitude, longitude) => {
  const time_zone = tzLookup(parseFloat(latitude), parseFloat(longitude));
  return moment().tz(time_zone);
};

export const getNextClosingOrOpeningHours = (hour_options, location, date_time_now) => {
  const date_now =
    date_time_now || get_current_time_from_location(location.latitude, location.longitude);
  const now_in_seconds = get_now_in_seconds(date_now);
  let temp = null;
  forEach(hour_options, option => {
    const start_in_sec = option.start_in_sec ? option.start_in_sec : 0;
    const { end_in_sec } = option;
    if (start_in_sec <= now_in_seconds && now_in_seconds <= end_in_sec) {
      const mins = Math.floor((end_in_sec - now_in_seconds) / 60);
      if (mins <= 60) {
        temp = { isStart: false, time_in_mins: mins };
      }
    } else if (start_in_sec > now_in_seconds) {
      const mins = Math.floor((start_in_sec - now_in_seconds) / 60);
      if (mins <= 60) {
        temp = { isStart: true, time_in_mins: mins };
      }
    }
  });
  return temp;
};

export const isOpen = (menu_hours, location, buffer_in_mins, date_time_now) => {
  const date_now =
    date_time_now || get_current_time_from_location(location.latitude, location.longitude);
  const options = get_matching_menu_hours(menu_hours, date_now);
  let is_open = check_if_open(date_now, options, buffer_in_mins);
  if (!is_open) {
    const options_prev = get_previous_day_matching_hours(menu_hours, date_now);
    is_open = check_if_open_in_overlapping_previous_day(date_now, options_prev, buffer_in_mins);
  }
  return { is_open, options };
};

export const isPlaceOpen = (place_hour, location = {}, cut_off_time = 0, custom_hours = []) => {
  const date_now = get_current_time_from_location(location.latitude, location.longitude);
  const buffer_time = cut_off_time;

  let is_open = false;
  let options = null;
  // Validate stopped hours

  // Get matching holiday hour
  if (custom_hours) {
    options = get_matching_custom_hour(custom_hours, date_now);
    if (options) {
      is_open = check_if_open(date_now, options, buffer_time);
    }
  }
  if (!options) {
    const open_hours = isOpen(place_hour, location, buffer_time, date_now);
    is_open = open_hours.is_open;
    options = open_hours.options;
  }
  const nextClosingOrOpeningHours = getNextClosingOrOpeningHours(options, location, date_now);
  return { is_open, options, nextClosingOrOpeningHours };
};

export const isMenuOpen = (
  menu = { menu_hour: [], stopped_hour: null },
  location = {},
  cut_off_time = 0,
  custom_hours = [],
) => {
  const date_now = get_current_time_from_location(location.latitude, location.longitude);
  const buffer_time = cut_off_time;

  let is_open = false;
  let options = null;
  // Validate stopped hours
  const is_stopped = menu.stopped_hour && is_menu_stopped(menu.stopped_hour);

  // Get matching holiday hour
  if (custom_hours) {
    options = get_matching_custom_hour(custom_hours, date_now);
    if (options) {
      is_open = check_if_open(date_now, options, buffer_time);
    }
  }
  if (!options) {
    const open_hours = isOpen(menu.menu_hour, location, buffer_time, date_now);
    is_open = open_hours.is_open;
    options = open_hours.options;
  }
  const nextClosingOrOpeningHours = getNextClosingOrOpeningHours(options, location, date_now);
  const data = {
    is_open,
    is_stopped,
    today_day: moment(date_now).format('dddd'),
    options,
    nextClosingOrOpeningHours,
  };
  return data;
};
