import React from 'react';
import styled from 'styled-components';
import swal from 'sweetalert';
import { capitalize, trim } from 'lodash';

import { Text, FlexRows } from '../../../../components/elements';
import Counter from '../../../../components/global/Counter';
import { formatCurrency } from '../../../../utils/helpers';
import { CartInsideLoader } from '../../Loader';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Wrapper = styled(FlexRows)`
  /* padding: 1rem; */
  &&& {
    align-items: flex-start;
  }
`;

const Item = styled.div`
  width: 55%;
`;

const YourOrderItem = ({ item, onItemEdit, updateQuantity, addToCartLoading }) => {
  return (
    <Container>
      <Wrapper justify="space-between">
        <Item>
          <a onClick={onItemEdit}>
            <Text weight="regular" size="smedium" paddingBottom="0.2" color="primaryColor">
              {item.name}
            </Text>
          </a>

          {item && item.selectedVariant && (
            <Text weight="medium" size="tiny" color="darkGrey" lineHeight="normal">
              {item.selectedVariant.variant_name} (
              {formatCurrency(item.selectedVariant.variant_price)})
            </Text>
          )}
          {item.groups &&
            item.groups.map(group =>
              group.modifiers.map(modifier => (
                <Text weight="medium" size="tiny" color="darkGrey" lineHeight="normal">
                  {modifier.menu_item.name} (
                  {modifier.selectedVariant
                    ? `${modifier.selectedVariant.variant_name} (${formatCurrency(
                        modifier.selectedVariant.variant_price,
                      )})`
                    : formatCurrency(modifier.override_price)}
                  )
                </Text>
              )),
            )}
        </Item>

        <Counter
          disabled={addToCartLoading}
          quantity={item.quantity}
          onChange={count => {
            if (count === 0) {
              swal({
                title: 'Remove Item from Cart?',
                text: `Remove '${capitalize(item.name)}' from the cart?.`,
                buttons: ['Cancel', 'OK'],
              }).then(async willDelete => {
                if (willDelete) {
                  updateQuantity(item.id, count, item.displayOrder);
                }
              });
            } else {
              updateQuantity(item.id, count, item.displayOrder);
            }
          }}
        />

        {addToCartLoading ? (
          <div style={{ width: '4.6rem' }}>
            <CartInsideLoader />
          </div>
        ) : (
          <Text weight="medium" size="smedium" color="darkGrey" style={{ width: '4.6rem' }}>
            {formatCurrency(item.overall_price)}
          </Text>
        )}
      </Wrapper>
      {item.special_request && item.special_request.length !== 0 && (
        <Text weight="medium" size="tiny" color="darkGrey" lineHeight="normal">
          Request: {item.special_request}
        </Text>
      )}
    </Container>
  );
};

export default YourOrderItem;
