import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
// import { trackPage } from '../../utils/analytics';

const Analytics = ({ pageName, category, title, children, match }) => (
  // useEffect(() => {
  //   trackPage('anonymous', category, pageName, {
  //     url: match.url,
  //     path: match.path,
  //     title,
  //   });
  //   // if (process.env.NODE_ENV !== 'development') {
  //   //   trackPage('Anynomus', category, pageName, {
  //   //     url: match.url,
  //   //     path: match.path,
  //   //     title,
  //   //   });
  //   // }
  // }, [category, match.path, match.url, pageName, title]);
  <>{children}</>
);
export default withRouter(Analytics);
