import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { isNull } from 'lodash';
import { Text, Avatar } from '../../../components/elements';
import { getPlaceTagline } from '../../../utils/helpers';

const PlaceContainer = styled.article`
  padding: 1rem;
  justify-content: center;
  border: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const Place = ({ place, image }) => {
  if (isNull(place)) {
    return '';
  }
  return (
    <Link to={`/place/${place.place_id}`}>
      <PlaceContainer className="media">
        <figure className="media-left">
          <p className="image is-64x64">
            <Avatar
              name={place.name}
              image={image && image.url}
              maskProps={{ width: 64, height: 64 }}
            />
          </p>
        </figure>
        <div className="media-content">
          <div>
            <Text size="small" weight="semibold">
              {place.name}
            </Text>
            <Text color="darkGrey" size="tiny">
              {getPlaceTagline(place.business_type, place.cuisine_style)}
            </Text>
            <Text color="darkGrey" size="tiny">
              {place.city}, {place.state}, {place.country} {place.post_code}
            </Text>
          </div>
        </div>
        <div className="media-right">
          {/* <IconButton className="button">
          <UnFollowActionButton placeId={placeId} followId={followId} />
        </IconButton> */}
        </div>
      </PlaceContainer>
    </Link>
  );
};

export default Place;
