import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { first, uniqBy } from 'lodash';
import { Text, FlexRows, Button } from '../../../../components/elements';

const Container = styled.div`
  &&& {
    .table thead td,
    .table thead th,
    tr {
      border-width: 0 0 0px;
    }
    .table td,
    .table th {
      border: 0;
    }
    .table thead {
      background-color: #ffffff;
    }
  }
`;

const ThTextStyle = styled(Text).attrs({
  className: 'is-uppercase',
  color: 'primaryColor',
  size: 'small',
})``;

const TrTextStyle = styled(Text).attrs({
  color: 'darkGrey',
  size: 'tiny',
})``;

const ButtonWrapper = styled(FlexRows)`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Tickets = ({ eventId, ticketRecord, history }) => {
  const tickets = uniqBy(ticketRecord, 'ticket_name');
  return (
    <>
      <Container className="table-container">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>
                <ThTextStyle>Ticket Type</ThTextStyle>
              </th>
              <th>
                <ThTextStyle>QTY</ThTextStyle>
              </th>
              <th>
                <ThTextStyle>Price</ThTextStyle>
              </th>
              <th>
                <ThTextStyle>Fee</ThTextStyle>
              </th>
              <th>
                <ThTextStyle>Cost</ThTextStyle>
              </th>
            </tr>
          </thead>
          <tbody>
            {tickets.map(item => {
              const quantity = ticketRecord.filter(
                ticket => ticket.ticket_name === item.ticket_name,
              ).length;

              const feeArray =
                item.fee && item.fee.length !== 0
                  ? item.fee.filter(ticketFree => ticketFree.name === 'online_passon_fee')
                  : [];
              const fee = feeArray.length !== 0 ? first(feeArray).value : 0;
              const totalPrice = item.price + fee;

              return (
                <tr key={item.id}>
                  <td>
                    <TrTextStyle>{item.ticket_name}</TrTextStyle>
                  </td>
                  <td>
                    <TrTextStyle>{quantity}</TrTextStyle>
                  </td>
                  <td>
                    <TrTextStyle>${parseFloat(item.price).toFixed(2)}</TrTextStyle>
                  </td>
                  <td>
                    <TrTextStyle>${parseFloat(fee).toFixed(2)}</TrTextStyle>
                  </td>
                  <td>
                    <TrTextStyle>${parseFloat(totalPrice * quantity).toFixed(2)}</TrTextStyle>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
      <hr />
      <ButtonWrapper justify="flex-start">
        <Button
          name="Back"
          backgroundColor="primaryColor"
          textProps={{ color: 'white', weight: 'semibold', size: 'small' }}
          onClick={() => history.push(`/event/${eventId}`)}
        />
      </ButtonWrapper>
    </>
  );
};

export default withRouter(Tickets);
