import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { useStoreState } from 'easy-peasy';

import { ScrollView, Text, FlexRows, Icon } from '../../../../components/elements';

const Container = styled(FlexRows).attrs({ justify: 'flex-between' })`
  padding-top: 1rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  &&& {
    .menu-wrapper--inner {
      width: 10 !important;
    }
  }
`;
const Item = styled.div`
  padding: 0.3rem 1.5rem;
  cursor: pointer;
  p {
    color: ${({ theme: { primaryColor }, isActive }) => (isActive ? primaryColor : '#000000')};
  }
  border-bottom: 3px solid
    ${({ theme: { primaryColor }, isActive }) => (isActive ? primaryColor : 'transparent')};
`;

const ShareItemWrapper = styled.div`
  &&& {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
    position: relative;
    bottom: 0.5rem;
    span {
      position: relative;
      top: 2px;
    }
  }
`;

const Menu = (productTypes, selected) =>
  productTypes.map(item => (
    <Item
      key={item.name}
      isActive={selected === item.name}
      className="has-text-weight-bold has-text-centered"
    >
      <Text size="medium" weight="semibold">
        {item.description}
      </Text>
    </Item>
  ));

const options = [
  { name: 'best_dishes', description: 'Best Dishes' },
  { name: 'nearest-dishes', description: 'Nearest Dishes' },
  { name: 'latest_dishes', description: 'Latest Dishes' },
];

const MenuCategoryTabs = () => {
  // const sortByInput = useStoreState(state => state.item.filterInputs.sortByInput);
  const [filterTags, setFilterTags] = React.useState('best_dishes');
  // const sortBy = useStoreState(state => state.item.filterTags.sortBy);
  // console.log('sortBy', filterTags, sortBy);
  const menu = Menu(options, filterTags);

  const handleFilter = key => {
    setFilterTags(key);
    // onClick(key);
    console.log(key);
  };
  return (
    <Container className="level">
      <div className="level-left">
        <ScrollView
          menu={menu}
          onSelect={key => handleFilter(key)}
          innerWrapperStyle={{
            transform: 'translate3d(0px, 0px, 0px)',
            transition: 'transform 0.4s ease 0s',
            width: '900px',
            textAlign: 'left',
            userSelect: 'none',
            whiteSpace: 'nowrap',
          }}
        />
      </div>

      <div className="level-right">
        <Link to="/spot-and-share-an-item/">
          <ShareItemWrapper>
            <div className="is-flex">
              <span>
                <Icon name="itemRatingGoldSolid" size={1} />
              </span>
              &nbsp;
              <Text size="small" color="darkGrey" weight="semibold">
                Share an Item, Dish and Drink...
              </Text>
            </div>
          </ShareItemWrapper>
        </Link>
      </div>
    </Container>
  );
};

export default MenuCategoryTabs;
