import React, { useRef } from 'react';
import styled from 'styled-components';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { forEach, sortBy } from 'lodash';

import { Text } from '../../../../components/elements';
import PopularItem from './PopularItem';
import { finalLinkListingItemDataParser, calculateItemPrice } from '../../helpers';

const Container = styled.div`
  padding-bottom: 1rem;
`;

const Heading = styled(Text)`
  padding-bottom: 1.5rem;
`;

const calculateQuantity = value => {
  let qyt = 0;
  forEach(value, item => {
    qyt += item.quantity;
  });
  return qyt;
};

const PopularPicks = ({
  lineItems,
  section,
  handleTabSwitch,
  setModifiersModal,
  size,
  location,
  isCustomService,
}) => {
  const reference = useRef();

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y <= 250) {
        handleTabSwitch();
      }
    },
    [],
    reference,
  );

  const items = sortBy(section.items, 'display_order');

  return (
    <Container ref={reference}>
      <Heading className="is-capitalized" weight="semibold" size="bmedium">
        {section.name}
      </Heading>
      <div className="columns is-multiline">
        {items.map(link => {
          const extractLinkData = finalLinkListingItemDataParser({ link, location });
          const isAddedThisItemArray = lineItems.filter(
            item => item.item_link_id === extractLinkData.item_link_id,
          );

          const isAddedThisItem = isAddedThisItemArray.length !== 0;
          const numberOfAddedItem = isAddedThisItem ? calculateQuantity(isAddedThisItemArray) : 0;

          return (
            <PopularItem
              isCustomService={isCustomService}
              unAvailableDates={extractLinkData.un_available_dates}
              itemId={extractLinkData.item_id}
              allowItemReview={extractLinkData.allow_item_review}
              key={extractLinkData.item_link_id}
              isAddedThisItem={isAddedThisItem}
              numberOfAddedItem={numberOfAddedItem}
              name={extractLinkData.name}
              image={extractLinkData.image}
              price={calculateItemPrice(extractLinkData.variant, extractLinkData.price)}
              joules={extractLinkData.kilojoules}
              availability={extractLinkData.availability_status}
              hasShare
              description={extractLinkData.description}
              setModifiersModal={() => {
                setModifiersModal(extractLinkData);
              }}
              size={size}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default PopularPicks;
