import React, { useState } from 'react';

import InsightsContentHeader from './InsightsContentHeader';
import LatestFeeds from './LatestFeeds';
import TrendingItems from './TrendingItems';
import Reviews from './Reviews';

const Insights = ({ place }) => {
  const [tab, setTab] = useState('feeds');
  return (
    <>
      <InsightsContentHeader tab={tab} setTab={setTab} />
      {tab === 'feeds' && <LatestFeeds place={place} />}
      {tab === 'items' && <TrendingItems place={place} />}
      {tab === 'reviews' && <Reviews place={place} />}
    </>
  );
};

export default Insights;
