import React from 'react';
import styled from 'styled-components';
import { FlexRows, Text } from '../../../../components/elements';
import { trackEvent } from '../../../../utils/analytics';

const Container = styled.div`
  position: fixed;
  padding: 0rem 0.5rem;
  width: 100%;
  z-index: 1000;
  background: ${({ theme: { colors } }) => colors.lightGreyBeta};
`;
const PageHeadingWrapper = styled(FlexRows)`
  padding: 0.5rem;
`;
const PageHeaderTabs = styled(FlexRows).attrs({ justify: 'space-between' })``;
const Item = styled.a`
  padding: 0.7rem 0rem;
  border-bottom: 4px solid ${({ active, theme: { colors } }) => (active ? colors.primaryColor : 'transparent')};
`;

const PageHeader = ({ tab, setTab }) => (
  <Container>
    <PageHeadingWrapper>
      <Text className="is-capitalized" weight="bold">
        Buffet Menu
      </Text>
    </PageHeadingWrapper>
    <PageHeaderTabs>
      <Item
        active={tab === 'info'}
        onClick={() => {
          trackEvent('anonymous', 'Page_Header_Info', { details: 'click to get page header info' });
          setTab('info');
        }}
      >
        <Text className="is-uppercase" align="center" weight="bold" size="tiny" letterSpacing="loose">
          BreakFast
        </Text>
        <Text weight="semibold" align="center" size="micro">
          8AM - 11:00AM
        </Text>
      </Item>
      <Item
        active={tab === 'insights'}
        onClick={() => {
          trackEvent('anonymous', 'Page_Header_Insights', {details: 'click to get page header insights'});
          setTab('insights');
        }}
      >
        <Text className="is-uppercase" align="center" weight="bold" size="tiny" letterSpacing="loose">
          Launch
        </Text>
        <Text weight="semibold" align="center" size="micro">
          7PM - 11:00PM
        </Text>
      </Item>
      <Item
        active={tab === 'menu'}
        onClick={() => {
          trackEvent('anonymous', 'Page_Header_Menu', { details: 'click to get page header menu' });
          setTab('menu');
        }}
      >
        <Text className="is-uppercase" align="center" weight="bold" size="tiny" letterSpacing="loose">
          Dinner
        </Text>
        <Text weight="semibold" align="center" size="micro">
          7PM - 11:00PM
        </Text>
      </Item>
      {/* <Item
        active={tab === 'deals-and-events'}
        onClick={() => {
          
          setTab('deals-and-events');
        }}
      >
        <Text className="is-uppercase" align="center" weight="bold" size="tiny" letterSpacing="loose">
          Group C
        </Text>
        <Text weight="semibold" align="center" size="micro">
          10PM - 4:00AM
        </Text>
      </Item> */}
    </PageHeaderTabs>
  </Container>
);

export default PageHeader;
