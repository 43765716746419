import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { times, first } from 'lodash';
// import uuidv4 from 'uuid/v4';
// import moment from 'moment';

import AttendeeInputs from './AttendeeInputs';
import { Text, FlexRows, Button } from '../../../components/elements';
import colors from '../../../theme/styles/colors';

const Header = styled(FlexRows)`
  padding: 1rem;
  border-bottom: 2px solid ${colors.lightGreyTheta};
`;
const ContainerHeader = styled.div`
  padding: 1rem;
  border-bottom: 2px solid ${colors.lightGreyTheta};
`;

const Form = props => {
  const {
    values,
    totalQuantity,
    orderTotal,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    loading,
    back,
    setStepTwoHandleSubmitFormData,
  } = props;

  useEffect(() => {
    setStepTwoHandleSubmitFormData({ handleSubmit });
  }, [handleSubmit, setStepTwoHandleSubmitFormData]);

  return (
    <>
      <Header justify="space-between">
        <div>
          <Text size="small" weight="semibold">
            Total Quantity: {totalQuantity}
          </Text>
        </div>
        <div>
          <Text size="small" weight="semibold">
            Order Total :${parseFloat(orderTotal).toFixed(2)}
          </Text>
        </div>
      </Header>

      {values.ticketSelections.map((item, index) => {
        const { attributes } = first(item.attendees);
        if (attributes.length === 0) {
          return null;
        }
        return (
          <ContainerHeader>
            {item.attendees.map((item2, ind2) => (
              <>
                {item2.attributes.length !== 0 && (
                  <>
                    <Text size="small" weight="semibold" paddingBottom={1}>
                      Ticket: {item2.name}
                    </Text>
                    <AttendeeInputs
                      ticketName={item2.name}
                      attributes={item2.attributes}
                      ticketIndex={index}
                      attendeeIndex={ind2}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
              </>
            ))}
          </ContainerHeader>
        );
      })}

      <Header justify="space-between">
        <div>
          <Button
            className={`${loading && 'is-loading'}`}
            name="Back"
            backgroundColor="primaryColor"
            textProps={{ color: 'white', weight: 'semibold', size: 'small' }}
            onClick={back}
          />
        </div>
        {/* <div>
          <Button
            className={`${loading && 'is-loading'}`}
            name="Checkout"
            backgroundColor="primaryColor"
            textProps={{ color: 'white', weight: 'semibold', size: 'small' }}
            onClick={handleSubmit}
          />
        </div> */}
      </Header>
    </>
  );
};

const AttendeeForm = withFormik({
  mapPropsToValues: ({ ticketSelections, storeOfTicketPlusAttendee }) => {
    const ticketAttendeeInputs = ticketSelections.map(item => ({
      name: item.name,
      ticket_id: item.id,
      quantity: item.quantity,
      attendees: times(item.quantity).map(quantity => ({
        name: `${item.name} - Attendee ${quantity + 1}`,
        attributes: item.attributes,
      })),
    }));

    return {
      ticketSelections: storeOfTicketPlusAttendee || ticketAttendeeInputs,
    };
  },

  validationSchema: yup.object().shape({
    ticketSelections: yup.array().of(
      yup.object().shape({
        attendees: yup.array().of(
          yup.object().shape({
            attributes: yup.array().of(
              yup.object().shape(
                {
                  required: yup.bool(),
                  name: yup.string(),
                  values: yup
                    .string()
                    .when(['required'], {
                      is: required => required === true,
                      then: yup.string().required('Required'),
                    })
                    .when(['name'], {
                      is: name => name === 'email',
                      then: yup.string().email('Please enter valid email'),
                    }),
                },
                ['required'],
              ),
            ),
          }),
        ),
      }),
    ),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'AttendeeForm',
})(Form);

export default AttendeeForm;
