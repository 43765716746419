import React from 'react';
import PropTypes from 'prop-types';

import helpers from '../../utils/addToCalendar';
// import { trackEvent } from '../../utils/analytics';

class AddToCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsOpen: props.optionsOpen || false,
      isCrappyIE: false,
    };

    this.handleDropdownLinkClick = this.handleDropdownLinkClick.bind(this);
  }

  componentWillMount() {
    let isCrappyIE = false;
    if (typeof window !== 'undefined' && window.navigator.msSaveOrOpenBlob && window.Blob) {
      isCrappyIE = true;
    }

    this.setState({ isCrappyIE });
  }

  handleDropdownLinkClick(e) {
    e.preventDefault();
    const url = e.currentTarget.getAttribute('href');

    if (!helpers.isMobile() && (url.startsWith('data') || url.startsWith('BEGIN'))) {
      const filename = 'download.ics';
      const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

      if (this.state.isCrappyIE) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      window.open(url, '_blank');
    }
  }

  render() {
    const { event, currentItem, children } = this.props;

    return (
      <a
        className={`${currentItem}-link`}
        onClick={() => {
          this.handleDropdownLinkClick();
        }}
        href={helpers.buildUrl(event, currentItem, this.state.isCrappyIE)}
        target="blank"
      >
        {children}
      </a>
    );
  }
}

AddToCalendar.defaultProps = {
  event: {
    title: 'Sample Event',
    description: 'This is the sample event provided as an example only',
    location: 'Portland, OR',
    startTime: '2016-09-16T20:15:00-04:00',
    endTime: '2016-09-16T21:45:00-04:00',
  },
  currentItem: 'google',
};

AddToCalendar.propTypes = {
  currentItem: PropTypes.string,

  event: PropTypes.object,
};

export default AddToCalendar;
