import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Text, FlexRows } from '../../elements';
import ReportMenuErrorModal from './ReportMenuErrorModal';

const Container = styled.footer`
  background: ${({ theme: { colors } }) => colors.lightGreyAlpha};
  padding: 1rem;
`;

const Heading = styled(Text).attrs({ paddingBottom: 0.2 })``;

const Footer = () => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  return (
    <Container id="footer">
      <div className="columns">
        <ReportMenuErrorModal isActiveModal={isActiveModal} setIsActiveModal={setIsActiveModal} />
        <div className="column is-4">
          <a className="navbar-item" href="/">
            <span>
              <span>
                <Text
                  color="primaryColor"
                  size="h1"
                  weight="semibold"
                  letterSpacing="loose"
                  paddingBottom={0.2}
                >
                  Kraveln
                </Text>
                <Text className="is-uppercase" color="tertiaryColor" size="tiny" weight="semibold">
                  Create your experience
                </Text>
              </span>
            </span>
          </a>
        </div>
        <div className="column is-8">
          <FlexRows justify="space-around" align="flex-start">
            <div>
              <Heading size="medium" weight="semibold" color="darkGrey">
                About
              </Heading>
              <Link>
                <Text size="small" color="primaryColor">
                  About Us
                </Text>
              </Link>
              <Link>
                <Text size="small" color="primaryColor">
                  Term of Service
                </Text>
              </Link>
              <Link>
                <Text size="small" color="primaryColor">
                  Privacy Policy
                </Text>
              </Link>
              <Link>
                <Text size="small" color="primaryColor">
                  Frequently Asked Questions (FAQ&apos;s)
                </Text>
              </Link>
              <Link>
                <Text size="small" color="primaryColor">
                  Site Map
                </Text>
              </Link>
            </div>
            <div>
              <Heading size="medium" weight="semibold" color="darkGrey">
                Resource
              </Heading>
              <Link to="/contact-us">
                <Text size="small" color="primaryColor">
                  Contact Us
                </Text>
              </Link>
              <Link to="/add-place">
                <Text size="small" color="primaryColor">
                  Add a Place
                </Text>
              </Link>
              <Link to="/search-place-for-claim">
                <Text size="small" color="primaryColor">
                  Claim a Place
                </Text>
              </Link>
              <Link>
                <Text size="small" color="primaryColor">
                  Business Owners
                </Text>
              </Link>
            </div>
            <div>
              <Heading size="medium" weight="semibold" color="darkGrey">
                Social
              </Heading>
              <Link>
                <Text size="small" color="primaryColor">
                  Facebook
                </Text>
              </Link>
              <Link>
                <Text size="small" color="primaryColor">
                  Twitter
                </Text>
              </Link>
              <Link>
                <Text size="small" color="primaryColor">
                  Instagram
                </Text>
              </Link>
              <Link>
                <Text size="small" color="primaryColor">
                  LinkedIn
                </Text>
              </Link>
            </div>
          </FlexRows>
        </div>
      </div>
      <Text size="micro" color="darkGrey">
        Copyright @2020 KraveIn. All rights reserved
      </Text>
    </Container>
  );
};
export default Footer;
