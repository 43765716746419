import React, { useState } from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Layout from '../../components/global/MenuLayout';
import { Text, Button, Icon, Pager } from '../../components/elements';
import ImageGallery from '../../components/ImageGallery';
import PhotoPreviewModal from './PhotoPreviewModal';
import MobilePreviewModal from './MobilePreviewModal';
import Loading from '../../components/global/mobile/MobileLoading';

const placeQuery = gql`
  query fetch_place($placeInput: NavigatePlaceInput, $imageInput: SearchInput) {
    fetch_place(input: $placeInput) {
      place_id
      name
      cuisine_style {
        name
        description
      }
      business_type {
        name
        description
      }
      audit {
        created_at
      }
    }
    search_images(input: $imageInput) {
      image_id
      url
      audit {
        created_by
        created_at
      }
    }
  }
`;

const Container = styled(Pager)`
  padding-top: 1rem;
`;

const PlacePhotos = ({ history, match }) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const isLoggedIn = useStoreState(state => state.auth.isLoggedIn);

  const [modalActive, setModalActive] = useState(false);
  const [preViewImage, setPreViewImage] = useState({});
  const [whichImage, setWhichImage] = useState(0);
  const { placeId } = match.params;

  const handleOnClickAddPhotoButton = path => {
    if (!isLoggedIn) {
      setIsActiveModal({ value: true, path });
    } else {
      history.push(path);
    }
  };

  return (
    <Layout
      history={history}
      match={match}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading="GALLERY"
    >
      <Query
        query={placeQuery}
        variables={{
          placeInput: { place_id: placeId },
          imageInput: {
            filter: {
              image_filter: {
                object_id: placeId,
                object_type: ['PLACE'],
                parent_id: placeId,
                parent_type: ['PLACE'],
                links_to_id: [placeId],
                links_to_type: ['PLACE'],
              },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }
          const { fetch_place, search_images } = data;

          return (
            <>
              <div className="is-hidden-desktop">
                {modalActive && (
                  <MobilePreviewModal
                    whichImage={whichImage}
                    place={fetch_place}
                    isActive={modalActive}
                    images={search_images}
                    preViewImage={preViewImage}
                    isClose={() => setModalActive(false)}
                    onSliderChange={value => {
                      setPreViewImage(search_images[value]);
                    }}
                  />
                )}
                <ImageGallery
                  isPreviewOpen={(image, index) => {
                    setModalActive(true);
                    setPreViewImage(image);
                    setWhichImage(index);
                  }}
                  images={search_images}
                />
              </div>
              <div className="is-hidden-touch">
                <Container>
                  <Text className="is-capitalized" size="big" weight="bold">
                    {capitalize(fetch_place.name)}
                  </Text>
                  <br />
                  <Button
                    name="Add Photo"
                    icon="photoAddSolid"
                    textProps={{ size: 'small' }}
                    onClick={() => handleOnClickAddPhotoButton(`/add-place-photos/${placeId}`)}
                  >
                    <span className="icon is-small">
                      <Icon name="photoAddSolid" />
                    </span>
                  </Button>
                  <ImageGallery
                    isPreviewOpen={(image, index) => {
                      setModalActive(true);
                      setPreViewImage(image);
                      setWhichImage(index);
                    }}
                    images={search_images}
                  />

                  {modalActive && (
                    <PhotoPreviewModal
                      whichImage={whichImage}
                      place={fetch_place}
                      isActive={modalActive}
                      images={search_images}
                      preViewImage={preViewImage}
                      isClose={() => setModalActive(false)}
                      onSliderChange={value => {
                        setPreViewImage(search_images[value]);
                      }}
                    />
                  )}
                </Container>
              </div>
            </>
          );
        }}
      </Query>
      <div className="is-hidden-touch">
        <br />
        <Button
          name="Back"
          backgroundColor="primaryColor"
          textProps={{ color: 'white', weight: 'semibold' }}
          onClick={() => history.goBack()}
        />
      </div>
    </Layout>
  );
};

export default PlacePhotos;
