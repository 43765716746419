/* eslint-disable consistent-return */
import React, { useState, useCallback, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';

import { CheckBox } from '../../../components/elements';
import Loader from './Loader';
import FoodItem from './FoodItem';
import { getCart } from './helpers';

const Wrapper = styled.div`
  padding: 1rem 0rem;
`;

const DesktopView = () => {
  const { isLoggedIn, userId } = useStoreState(state => state.auth);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fav, setFav] = useState(false);

  const fetchBooking = useCallback(async () => {
    setLoading(true);
    const menu_order_filter = {
      status: ['RECEIVED', 'CONFIRMED', 'READY', 'COMPLETE', 'CANCELLED'],
    };
    Object.assign(
      menu_order_filter,
      isLoggedIn && { user_id: userId },
      fav === true && { favourite_order: true },
    );
    try {
      const list = await getCart({
        input: { sort: 'CREATED_DESC', filter: { menu_order_filter } },
      });
      setOrders(list);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [fav, isLoggedIn, userId]);

  useEffect(() => {
    fetchBooking();
  }, [fetchBooking]);

  return (
    <>
      <Wrapper>
        <CheckBox label="Favorite Order" initialValue={fav} onChange={value => setFav(value)} />
      </Wrapper>
      {loading && <Loader />}
      {!loading && <View orders={orders} userId={userId} />}
    </>
  );
};

const View = ({ userId, orders }) => {
  if (orders.length === 0) {
    return 'No Data Found';
  }
  return (
    <div className="columns is-multiline">
      {orders.map(order => (
        <div className="column is-half" key={order.menu_order_id}>
          <FoodItem userId={userId} order={order} />
        </div>
      ))}
    </div>
  );
};

export default DesktopView;
