import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { forEach, omit } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { FlexItem, FlexRows, Text } from '../../components/elements';
import Card from './components/mobile/Card';

const Container = styled.form``;
const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  padding: 1rem;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 50;
  width: 100%;
`;

const Save = styled.div`
  padding: 0.5rem 1rem;
  border-right: 1px solid ${({ theme: { colors } }) => colors.white};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
  background: ${({ theme: { colors } }) => colors.primaryColor};
`;
const Post = styled.div`
  padding: 0.5rem 1rem;
  border-left: 1px solid ${({ theme: { colors } }) => colors.white};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
  background: ${({ theme: { colors } }) => colors.primaryColor};
`;

const handleImageSubmissionWhileUploading = image => {
  let isDisabled = false;
  image.forEach(element => {
    if (element.uploadingStatus === 'uploading') {
      isDisabled = true;
    }
  });
  return isDisabled;
};

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const Form = props => {
  const { loading, values, setFieldValue, handleSubmit } = props;
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn } = useStoreState(state => state.auth);

  const handleOnClick = () => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
        callBackFunction: () => handleSubmit(),
      });
    } else {
      handleSubmit();
    }
  };
  const handleOnClickDraft = async () => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
        callBackFunction: () => handleSubmit(),
      });
    } else {
      await setFieldValue('isDraft', true);
      handleSubmit();
    }
  };
  return (
    <Container
      onSubmit={event => {
        event.preventDefault();
        handleOnClick();
      }}
    >
      <Content>
        <Card {...props} />
      </Content>
      <Footer>
        <FlexRows>
          <FlexItem grow={1}>
            <a
              onClick={() => {
                if (!loading && !handleImageSubmissionWhileUploading(values.image)) {
                  handleOnClickDraft();
                }
              }}
            >
              <Save isDisabled={handleImageSubmissionWhileUploading(values.image)}>
                <Text
                  className="is-uppercase"
                  color="white"
                  size="medium"
                  weight="bold"
                  align="center"
                >
                  {values.isDraft && loading ? 'Loading...' : 'Save Draft'}
                </Text>
              </Save>
            </a>
          </FlexItem>
          <FlexItem grow={1}>
            <a
              onClick={() => {
                if (!loading && !handleImageSubmissionWhileUploading(values.image)) {
                  handleSubmit();
                }
              }}
            >
              <Post isDisabled={handleImageSubmissionWhileUploading(values.image)}>
                <Text
                  className="is-uppercase"
                  color="white"
                  size="medium"
                  weight="bold"
                  align="center"
                >
                  {!values.isDraft && loading ? 'Loading...' : 'Post Review'}
                </Text>
              </Post>
            </a>
          </FlexItem>
        </FlexRows>
      </Footer>
    </Container>
  );
};

const MobileForm = withFormik({
  mapPropsToValues: ({ itemReview, uploadedImages }) => ({
    isDraft: false,
    item_name: '',
    item_type: itemReview ? removeTypename(itemReview.item_type) : [],
    cuisine_style: itemReview ? removeTypename(itemReview.cuisine_style) : [],
    overall_rating: itemReview ? itemReview.rating : 0,
    content: itemReview ? itemReview.content : '',
    uploadedImages,
    image: [
      {
        url: '',
        preview: null,
        tooltip: '',
        link: '',
        tag: [],
        uploadingStatus: 'pending',
      },
    ],
  }),

  validate: values => {
    const errors = {};

    if (values.image.length + values.uploadedImages.length < 2) {
      errors.image = 'Please upload at least one image';
    }

    return errors;
  },

  validationSchema: yup.object().shape({
    content: yup
      .string()
      .min(50, 'At least 50 Characters Required!')
      .max(1500, 'Not more than 1500 Characters!')
      .required('Review Content is required!'),
    overall_rating: yup
      .number()
      .moreThan(0, 'Rating is required!')
      .required('Rating is required!'),
    // image: yup
    //   .array()
    //   .min(2, 'Please upload at least one image')
    //   .required('Please upload at least one image'),
  }),

  handleSubmit: (values, { props }) => {
    const { isLoggedIn, setIsActiveDesktopLoginModal } = props;
    if (!isLoggedIn) {
      setIsActiveDesktopLoginModal({
        value: true,
        path: '',
        callBackFunction: () => props.onSubmit(values),
      });
    } else {
      props.onSubmit(values);
    }
  },

  displayName: 'Item Review PostForm',
})(Form);

export default MobileForm;
