import React from 'react';
import styled from 'styled-components';
import { BsFillXCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { capitalize, forEach } from 'lodash';
import moment from 'moment';
import { FlexRows, Text, ScrollToTopButton, SEO, Pager } from '../../components/elements';
import YourOrder from './components/desktop/YouOrder';

const Container = styled.div`
  padding-top: 2rem;
  .order-status {
    padding-top: 1rem;
  }
  .review-and-place-order {
    padding-top: 1rem;
  }
`;

const OrderStatus = styled(FlexRows)`
  padding: 2rem;
  border: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  margin-bottom: 2rem;
`;

const Left = styled.div`
  height: 19rem;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  .item {
    padding-top: 1rem;
  }
`;

const Span = styled(Text)`
  margin-left: 0.3rem;
`;

const Right = styled.div`
  width: 50%;
  height: 19rem;
`;

const TextWrapper = styled.div`
  margin-left: 2.2rem;
  margin-bottom: 1rem;
`;

const CancelWrapper = styled.div``;

const DesktopView = ({ userId, order, actionLoading, onCancel }) => {
  const asap = () => {
    let time = 0;
    forEach(order.estimates, item => {
      time += item.value_in_mins;
    });
    return time;
  };
  return (
    <Pager>
      <Container>
        <SEO title="" description="" />
        <div className="columns">
          <div className="column is-two-thirds">
            <>
              <Text
                weight="semibold"
                size="large"
                paddingBottom="2"
                align="center"
                className="order-status"
              >
                Order Status
              </Text>
              <OrderStatus justify="space-between">
                <Left>
                  <div className="">
                    <Text size="smedium" weight="regular">
                      Order Number
                    </Text>
                    <Text size="small" color="coolGrey" weight="regular" paddingBottom={0.5}>
                      {order.order_reference}
                    </Text>
                  </div>
                  <div className="item">
                    <Text size="smedium" weight="regular">
                      Service
                    </Text>
                    <Text size="small" color="coolGrey" weight="regular" paddingBottom={0.5}>
                      {order.service_type}
                    </Text>
                  </div>
                  {order.status !== 'CANCELLED' && (
                    <div className="item">
                      <Text size="smedium" weight="regular">
                        Estimated Time
                      </Text>
                      <Text size="small" color="coolGrey" weight="regular" paddingBottom={0.5}>
                        ASAP (
                        {moment(order.audit.updated_at)
                          .add(asap() - 5, 'minutes')
                          .format('hh:mm A')}{' '}
                        -{' '}
                        {moment(order.audit.updated_at)
                          .add(asap(), 'minutes')
                          .format('hh:mm A')}
                        )
                      </Text>
                    </div>
                  )}
                  {order.service_type === 'Delivery' && (
                    <div className="item">
                      <Text size="smedium" weight="regular">
                        Delivery Address
                      </Text>
                      <Text size="small" color="coolGrey" weight="regular" paddingBottom={0.5}>
                        {order.address.address_line_1} {order.address.city}, {order.address.state},{' '}
                        {order.address.post_code}
                      </Text>
                    </div>
                  )}
                </Left>
                <Right>
                  {order.status === 'CANCELLED' ? (
                    <CancelWrapper>
                      <FlexRows justify="flex-start">
                        <BsFillXCircleFill
                          size={20}
                          color="red"
                          style={{ marginRight: '1rem', cursor: 'pointer' }}
                        />
                        <Text size="small" weight="regular">
                          Order Cancelled
                        </Text>
                      </FlexRows>

                      {order.audit.updated_by === userId ? (
                        <>
                          <TextWrapper>
                            <Text size="small" weight="regular" color="waterMarkGrey">
                              You order has been cancelled successfully!
                            </Text>
                          </TextWrapper>
                          <Text size="small" weight="regular" color="darkGrey">
                            Your order has been canceled. If you have paid online, your payment will
                            be refunded.Your order has been canceled.
                          </Text>
                        </>
                      ) : (
                        <>
                          <TextWrapper>
                            <Text size="small" weight="regular" color="waterMarkGrey">
                              We are sorry to inform you that your order has been cancelled by the
                              restaurant
                            </Text>
                          </TextWrapper>
                          <Text size="small" weight="regular" paddingBottom="0.5">
                            Message from restaurant
                          </Text>
                          <Text size="small" weight="regular" color="darkGrey">
                            Sorry this happened due to an issue with your address. Please place
                            another order with correct address
                          </Text>
                        </>
                      )}
                      {/* <Text size="small" weight="regular" color="darkGrey">
                          Tue, March 15, 2020
                        </Text> */}
                    </CancelWrapper>
                  ) : (
                    <ul className="steps is-vertical is-thin">
                      <li
                        className={`steps-segment  ${order.status === 'RECEIVED' && 'is-active'}`}
                      >
                        <span href="#" className="steps-marker" />
                        <div className="steps-content">
                          <Text size="small" weight="regular">
                            Order Received
                          </Text>
                          <Text size="smedium" weight="regular" color="darkGrey">
                            Thank you for your order. We are confirming your order with{' '}
                            {capitalize(order.place.name)}
                          </Text>
                        </div>
                      </li>
                      <li
                        className={`steps-segment  ${order.status === 'CONFIRMED' && 'is-active'}`}
                      >
                        <span href="#" className="steps-marker" />
                        <div className="steps-content">
                          <Text
                            size="small"
                            weight="regular"
                            color={
                              order.status === 'CONFIRMED' ||
                              order.status === 'READY' ||
                              order.status === 'COMPLETE'
                                ? 'darkGrey'
                                : 'waterMarkGrey'
                            }
                          >
                            Order Confirmed{' '}
                          </Text>
                          <Text
                            size="smedium"
                            weight="regular"
                            color={
                              order.status === 'CONFIRMED' ||
                              order.status === 'READY' ||
                              order.status === 'COMPLETE'
                                ? 'darkGrey'
                                : 'waterMarkGrey'
                            }
                          >
                            {order.service_type === 'Pickup' &&
                              'Your order has been confirmed and is being prepared'}
                            {order.service_type === 'Dinein' && 'Your order has been confirmed'}
                            {order.service_type === 'Delivery' &&
                              'Your order has been confirmed and is being prepared'}
                          </Text>
                        </div>
                      </li>
                      <li className={`steps-segment  ${order.status === 'READY' && 'is-active'}`}>
                        <span className="steps-marker" />
                        <div className="steps-content">
                          <Text
                            size="small"
                            weight="regular"
                            color={
                              order.status === 'READY' || order.status === 'COMPLETE'
                                ? 'darkGrey'
                                : 'waterMarkGrey'
                            }
                          >
                            Order Ready{' '}
                          </Text>
                          <Text
                            size="smedium"
                            weight="regular"
                            color={
                              order.status === 'READY' || order.status === 'COMPLETE'
                                ? 'darkGrey'
                                : 'waterMarkGrey'
                            }
                          >
                            {order.service_type === 'Pickup' && 'Your order is waiting for you'}
                            {order.service_type === 'Dinein' &&
                              'Your order is getting ready to be served'}
                            {order.service_type === 'Delivery' &&
                              'Your order is ready and on its way to you'}
                          </Text>
                        </div>
                      </li>
                      <li
                        className={`steps-segment  ${order.status === 'COMPLETE' && 'is-active'}`}
                      >
                        <span className="steps-marker" />
                        <div className="steps-content">
                          <Text
                            size="small"
                            weight="regular"
                            color={order.status === 'COMPLETE' ? 'darkGrey' : 'waterMarkGrey'}
                          >
                            Order Completed{' '}
                          </Text>
                          <Text
                            size="smedium"
                            weight="regular"
                            color={order.status === 'COMPLETE' ? 'darkGrey' : 'waterMarkGrey'}
                          >
                            {order.service_type === 'Pickup' &&
                              'Your order has been picked up.  Enjoy!!'}
                            {order.service_type === 'Dinein' && 'Your order has been fulfilled!'}
                            {order.service_type === 'Delivery' &&
                              'Your order has been delivered.  Enjoy!!'}
                          </Text>
                        </div>
                      </li>
                    </ul>
                  )}
                </Right>
              </OrderStatus>
              <Text size="smedium" weight="regular" paddingBottom="1">
                If you have any questions or for any information, you can reach out to &quot;
                {order.place.name}&quot; at <Span color="primaryColor">+61 239649283</Span>
              </Text>
              <FlexRows justify="flex-start">
                <Text size="smedium" weight="regular" paddingBottom="1rem">
                  You can also get in touch with us{' '}
                </Text>
                <Link to="/contact-us">
                  <Span color="primaryColor" textDecoration="underline">
                    here
                  </Span>
                </Link>
              </FlexRows>
            </>
          </div>
          <div className="column">
            <YourOrder order={order} actionLoading={actionLoading} onCancel={onCancel} />
          </div>
        </div>
        <ScrollToTopButton />
      </Container>
    </Pager>
  );
};

export default DesktopView;
