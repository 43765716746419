import React from 'react';
import styled from 'styled-components';
import { replace } from 'lodash';
import { FaTimes } from 'react-icons/fa';
import { withFormik, getIn, Field } from 'formik';
import * as yup from 'yup';

import {
  Modal,
  Text,
  FlexRows,
  Input,
  MobileInput,
  ReactSelect,
} from '../../../components/elements';
import colors from '../../../theme/styles/colors';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const ModalFooter = styled.a`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem;
  position: fixed;
  bottom: 0;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
  padding: 0rem 1rem;
`;

const HeadingWrapper = styled.div`
  padding: 1rem 0rem;
  span {
    font-weight: 550;
  }
`;

const Span = styled.span`
  &&& {
    color: red;
  }
`;

const Span2 = styled.span`
  padding: 0.5rem;
  background-color: ${colors.lightGreyAlpha};
`;

const FieldStyle = styled.div``;
const Toast = ({ error, error2 }) => {
  return <div className="has-text-danger	is-size-5">{error || error2}</div>;
};

const ErrorMessage = ({ name, message }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? <Toast error={`${message} ${error}`} error2={error} /> : null;
    }}
  />
);

const Form = ({
  isActive,
  setActiveAttendeeModal,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  ticketName,
}) => (
  <Modal isActive={isActive}>
    <ModalHeader>
      <FlexRows justify="space-between">
        <a
          onClick={() => {
            setActiveAttendeeModal(false);
          }}
        >
          <Text color="white">
            <FaTimes size={25} />
          </Text>
        </a>
        <Text className="is-uppercase" align="center" color="white" weight="bold" size="medium">
          Attendee
        </Text>
        <div />
      </FlexRows>
    </ModalHeader>
    <Content>
      <HeadingWrapper>
        <Text className="is-capitalized" size="tiny" color="darkGrey">
          <Span2>
            <span>Ticket Type</span> : {ticketName}
          </Span2>
        </Text>
      </HeadingWrapper>
      {values.attributes.map((attribute, index) => {
        if (attribute.name === 'name') {
          return (
            <FieldStyle className="field">
              <label className="label">
                <Text color="darkGrey" size="small" weight="semibold">
                  Attendee Name{attribute.required && <Span color="has-text-danger">*</Span>}
                </Text>
              </label>
              <div className="control">
                <Input
                  name={`attributes.${index}.values`}
                  value={attribute.values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name={`attributes.${index}.values`} message="Attendee Name is" />
              </div>
            </FieldStyle>
          );
        }

        if (attribute.name === 'email') {
          return (
            <FieldStyle className="field">
              <label className="label">
                <Text color="darkGrey" size="small" weight="semibold">
                  Attendee Email{attribute.required && <Span color="has-text-danger">*</Span>}
                </Text>
              </label>
              <div className="control">
                <Input
                  name={`attributes.${index}.values`}
                  value={attribute.values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name={`attributes.${index}.values`} message="Attendee Email is" />
              </div>
            </FieldStyle>
          );
        }

        if (attribute.name === 'mobile') {
          return (
            <FieldStyle className="field">
              <label className="label">
                <Text color="darkGrey" size="small" weight="semibold">
                  Attendee Mobile{attribute.required && <Span color="has-text-danger">*</Span>}
                </Text>
              </label>
              <div className="control">
                <MobileInput
                  value={attribute.values}
                  onChange={value =>
                    setFieldValue(`attributes.${index}.values`, replace(value, ' ', ''))
                  }
                  onBlur={handleBlur}
                />
                <ErrorMessage name={`attributes.${index}.values`} message="Attendee Mobile is" />
              </div>
            </FieldStyle>
          );
        }

        if (attribute.name === 'gender') {
          return (
            <FieldStyle className="field">
              <label className="label">
                <Text color="darkGrey" size="small" weight="semibold">
                  Attendee Gender{attribute.required && <Span color="has-text-danger">*</Span>}
                </Text>
              </label>
              <div className="control">
                <ReactSelect
                  options={[
                    { value: 'MALE', label: 'MALE' },
                    { value: 'FEMALE', label: 'FEMALE' },
                    { value: 'OTHER', label: 'OTHER' },
                  ]}
                  value={[{ value: attribute.values, label: attribute.values }]}
                  onChange={value => setFieldValue(`attributes.${index}.values`, value.value)}
                  required={attribute.required}
                />
                <ErrorMessage name={`attributes.${index}.values`} message="Attendee Gender is" />
              </div>
            </FieldStyle>
          );
        }

        if (attribute.name === 'age') {
          return (
            <FieldStyle className="field">
              <label className="label">
                <Text color="darkGrey" size="small" weight="semibold">
                  Attendee Age{attribute.required && <Span color="has-text-danger">*</Span>}
                </Text>
              </label>
              <div className="control">
                <Input
                  type="number"
                  required={attribute.required}
                  name={`attributes.${index}.values`}
                  value={attribute.values}
                  onChange={event => {
                    if (
                      (parseFloat(event.target.value) <= 120 &&
                        parseFloat(event.target.value) > 0 &&
                        event.target.value !== 'e') ||
                      event.target.value === ''
                    ) {
                      setFieldValue(`attributes.${index}.values`, event.target.value);
                    }
                  }}
                  onBlur={handleBlur}
                  min="1"
                  max="120"
                />
                <ErrorMessage name={`attributes.${index}.values`} message="Attendee Age is" />
              </div>
            </FieldStyle>
          );
        }

        return null;
      })}
    </Content>
    <ModalFooter
      onClick={() => {
        // setActiveAttendeeModal(false);
        handleSubmit();
      }}
    >
      <Text className="is-uppercase" color="white" align="center" weight="bold" size="h4">
        Add
      </Text>
    </ModalFooter>
  </Modal>
);

const MobileFilterModal = withFormik({
  mapPropsToValues: ({ attributes }) => {
    return {
      attributes,
    };
  },

  validationSchema: yup.object().shape({
    attributes: yup.array().of(
      yup.object().shape(
        {
          required: yup.bool(),
          name: yup.string(),
          values: yup
            .string()
            .when(['required'], {
              is: required => required === true,
              then: yup.string().required('Required'),
            })
            .when(['name'], {
              is: name => name === 'email',
              then: yup.string().email('Please enter valid email'),
            }),
        },
        ['required'],
      ),
    ),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    props.setActiveAttendeeModal(false);
    setSubmitting(false);
  },

  displayName: 'MobileFilterModal',
})(Form);

export default MobileFilterModal;
