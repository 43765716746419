import React from 'react';
import styled from 'styled-components';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';
import { Pager } from '../../../components/elements';

const Container = styled.div`
  margin-top: 2rem;
`;

const ParentComponent = () => {
  return (
    <Pager>
      <Container>
        <div className="columns">
          <div className="column is-2">
            <RectShape color="#f0f0f0" style={{ width: '100%', height: '2.5rem' }} />
          </div>
          <div className="column is-2">
            <RectShape color="#f0f0f0" style={{ width: '100%', height: '2.5rem' }} />
          </div>
          <div className="column is-2">
            <RectShape color="#f0f0f0" style={{ width: '100%', height: '2.5rem' }} />
          </div>
        </div>
      </Container>
    </Pager>
  );
};

const MenuLoader = () => {
  return (
    <ReactPlaceholder ready showLoadingAnimation>
      <ParentComponent />
    </ReactPlaceholder>
  );
};

export default MenuLoader;
