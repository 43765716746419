import React from 'react';
import styled from 'styled-components';
import { MdDone, MdClear } from 'react-icons/md';

import { Modal, Text, FlexRows } from '../../components/elements';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const ModalHeaderLeft = styled.div`
  position: absolute;
  left: 0.5rem;
`;
const ModalHeaderRight = styled.div`
  position: absolute;
  right: 0.5rem;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const Flex = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.specialGrey};
`;

const Item = ({ title, isIconActive, onSelect }) => (
  <Flex onClick={onSelect}>
    <div>
      <Text>{title}</Text>
    </div>
    <div className="is-flex">
      {isIconActive && (
        <Text color="primaryColor">
          <MdDone size={25} />
        </Text>
      )}
    </div>
  </Flex>
);

const InputOptionModal = ({
  label,
  done,
  isActive,
  typeInputs,
  options,
  addType,
  removeType,
  onCloseModal,
}) => {
  return (
    <Modal isActive={isActive}>
      <ModalHeader>
        <ModalHeaderLeft>
          <a onClick={() => onCloseModal()}>
            <Text color="white">
              <MdClear size={25} />
            </Text>
          </a>
        </ModalHeaderLeft>
        <ModalHeaderRight>
          <a onClick={() => done()}>
            <Text className="is-uppercase" color="white" weight="semibold" size="small">
              Done
            </Text>
          </a>
        </ModalHeaderRight>

        <FlexRows>
          <Text className="is-uppercase" align="center" color="white" weight="bold" size="lmedium">
            {label}
          </Text>
        </FlexRows>
      </ModalHeader>
      <Content>
        {options.map(type => {
          const isActiveItem = typeInputs.filter(item => type.name === item.name);
          return (
            <Item
              key={type.name}
              title={`${type.description}`}
              isIconActive={isActiveItem.length !== 0}
              onSelect={() =>
                isActiveItem.length !== 0
                  ? removeType(type.name)
                  : addType({
                      description: type.description,
                      display_order: type.display_order,
                      name: type.name,
                    })
              }
            />
          );
        })}
      </Content>
    </Modal>
  );
};

export default InputOptionModal;
