import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';

import { Text, Image, Icon, Button } from '../../../components/elements';
import dummy from '../../../assets/images/food.jpg';
import { getEncodedImage } from '../../../utils/s3';

const Container = styled.div`
  &&& {
    background-color: ${({ theme: { colors } }) => colors.white};
    cursor: pointer;
    /* margin:0rem 1rem; */
    border-radius: 4px;
    height: 100%;
    /* :hover {
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
      transition: box-shadow .5s ease-out;
      background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha};
    } */
  }
`;

const Figure = styled.figure`
  && {
    img {
      border-radius: 4px;
    }
  }
`;

const Wrapper = styled.div`
  &&& {
    padding: 1rem;
    border: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const EmptyWrapper = styled.div`
  &&& {
    position: relative;
    .img-opacity {
      opacity: 0.6;
    }
  }
`;

const AddPhotosButtonWrapper = styled.div`
  right: 1rem;
  position: absolute;
  bottom: 1rem;
  z-index: 2;
`;

const EmptyImage = ({ itemId, handleOnClickAddPhotoButton }) => (
  <EmptyWrapper>
    <AddPhotosButtonWrapper>
      <Button
        name="Add Photo"
        icon="photoAddSolid"
        textProps={{ size: 'small' }}
        onClick={() => handleOnClickAddPhotoButton(`/add-item-photos/${itemId}`)}
      >
        <span className="icon is-small">
          <Icon name="photoAddSolid" />
        </span>
      </Button>
    </AddPhotosButtonWrapper>
    <Link to={`/item/${itemId}`}>
      <Figure className="image is-3by2 img-opacity">
        <Image source={dummy} />
      </Figure>
    </Link>
  </EmptyWrapper>
);

const ImageView = ({ itemId, handleOnClickAddPhotoButton, itemImage }) => {
  if (itemImage === null) {
    return <EmptyImage itemId={itemId} handleOnClickAddPhotoButton={handleOnClickAddPhotoButton} />;
  }

  return (
    <Link to={`/item/${itemId}`}>
      <Figure className="image image is-3by2">
        <Image source={getEncodedImage(itemImage, 452, 301)} />
      </Figure>
    </Link>
  );
};

const ItemCard = ({ item, history }) => {
  const handleOnClickAddPhotoButton = path => {
    history.push(path);
  };

  return (
    <Container>
      <Wrapper>
        <div>
          <Link to={`/item/${item.item_id}`}>
            <Text className="is-capitalized" size="large" weight="semibold" paddingBottom={0.2}>
              {item.name}
            </Text>
          </Link>
          <Text size="small" color="dark" weight="semibold" paddingBottom={0.2}>
            {item.place_name}, {item.city}, {item.post_code}
          </Text>
          <div className="is-flex">
            <span className="icon">
              <Icon size={1} name="itemRatingGoldSolid" />
            </span>
            <Text color="darkGrey" size="tiny" weight="regular" paddingBottom={0.2}>
              90% loved it
            </Text>
          </div>
        </div>
        <ImageView
          itemId={item.item_id}
          itemImage={item.default_image_url}
          handleOnClickAddPhotoButton={handleOnClickAddPhotoButton}
        />
      </Wrapper>
    </Container>
  );
};

export default withRouter(ItemCard);
