import React, { useState } from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import {} from 'react-router-dom';

import { Image, Text, Pagination } from '../../../../components/elements';
import PhotoPreviewModal from './PhotoPreviewModal';
import Loading from './Loading';
import Empty from './Empty';
import { getEncodedImage } from '../../../../utils/s3';
import client from '../../../../utils/apolloClient';

const Container = styled.div`
  padding: 1rem;
`;

const PaginationWrapper = styled.div`
  padding: 1rem;
`;

const Figure = styled.figure`
  &&& {
    .figure-img {
      opacity: 0.5;
    }
    img {
      padding: 1em;
    }
  }
`;

const Heading = styled.div`
  padding: 0rem 1rem;
`;

const imagesQuery = gql`
  query images($input: SearchInput) {
    search_my_image(input: $input) {
      total_pages
      total_size
      image_listing {
        image {
          image_id
          object_type
          url
          audit {
            created_by
            created_at
          }
        }
        place {
          place_id
          name
          business_type {
            name
            description
          }
          cuisine_style {
            name
            description
          }
        }
        item {
          item_id
          name
          place_name
        }
      }
    }
  }
`;

const MyPhotos = () => {
  const { userId } = useStoreState(state => state.auth);
  const [modalActive, setModalActive] = useState(false);
  const [preViewImage, setPreViewImage] = useState({});
  const [whichImage, setWhichImage] = useState(0);
  const [cursor, setCursor] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  return (
    <Container>
      <Query
        client={client.clientPrivate}
        query={imagesQuery}
        variables={{
          input: {
            size: 10,
            from: cursor,
            filter: {
              image_filter: {
                user_id: userId,
                object_type: ['PLACE', 'ITEM', 'PLACE_REVIEW', 'ITEM_REVIEW'],
              },
            },
          },
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <Empty />;
          }
          const imagesData = data.search_my_image.image_listing;
          if (imagesData.length === 0) {
            return <Empty />;
          }

          return (
            <>
              <Heading>
                <Text className="is-capitalized" size="large" weight="semibold">
                  My Photos ({data.search_my_image.total_size})
                </Text>
              </Heading>

              <div className="columns is-multiline">
                {imagesData.map((item, index) => (
                  <div className="column is-3">
                    <a
                      onClick={() => {
                        setModalActive(true);
                        setPreViewImage(item);
                        setWhichImage(index);
                      }}
                    >
                      <Figure className="image is-5by4">
                        <Image
                          source={getEncodedImage(item.image.url, 226, 180)}
                          objectFit="contain"
                        />
                      </Figure>
                    </a>
                  </div>
                ))}
              </div>

              {modalActive && (
                <PhotoPreviewModal
                  whichImage={whichImage}
                  isActive={modalActive}
                  images={imagesData}
                  preViewImage={preViewImage}
                  isClose={() => setModalActive(false)}
                  onSliderChange={value => {
                    setPreViewImage(imagesData[value]);
                  }}
                />
              )}
              {data.search_my_image.total_pages > 1 && (
                <PaginationWrapper>
                  <Pagination
                    currentPage={currentPage}
                    pageCount={data.search_my_image.total_pages}
                    handlePageClick={value => {
                      setCursor(value.selected * 10);
                      setCurrentPage(value.selected + 1);
                    }}
                  />
                </PaginationWrapper>
              )}
            </>
          );
        }}
      </Query>
    </Container>
  );
};

export default MyPhotos;
