/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import { concat, uniq, uniqBy, forEach } from 'lodash';

import {
  // getPlaceTypes,
  getCuisines,
  getItemTypes,
  // getFeatures,
  // getServiceCategory,
  // getServicesTypes,
  // getDressCodes,
  // getDiningStyle,
  // getPublicTransports,
  // getAmbience,
} from '../utils/fetchStatic';

const sortBy = [
  { name: 'best-dishes', description: 'Best Dishes' },
  { name: 'nearest-dishes', description: 'Nearest Dishes' },
  { name: 'latest-dishes', description: 'Latest Dishes' },
];

export default {
  item: {
    filterTags: {
      // sortBy,
      cuisines: [],
      itemTypes: [],
    },

    filterInputs: {
      // sortByInput: 'best-dishes',
      cuisines: [],
      itemTypes: [],
    },
    resetItemFilter: action(state => {
      state.filterInputs = {
        cuisines: [],
        itemTypes: [],
      };
    }),
    updateFilterTags: action((state, payload) => {
      const emptyArray = [];
      let cuisines = emptyArray;
      let itemTypes = emptyArray;

      forEach(payload, item => {
        if (item.aggregation_name === 'cuisine_style') {
          cuisines = item.aggregation_listing;
        }
        if (item.aggregation_name === 'item_type') {
          itemTypes = item.aggregation_listing;
        }
      });

      const updateData = {
        cuisines,
        itemTypes,
      };
      state.filterTags = updateData;
    }),

    fetchFilterTags: thunk(async actions => {
      const cuisines = await getCuisines();
      const itemTypes = await getItemTypes();

      const data = {
        sortBy,
        cuisines: uniqBy(cuisines, 'name'),
        itemTypes: uniqBy(itemTypes, 'name'),
      };
      actions.updateFilterTags(data);
    }),

    updateFilterInputs: action((state, payload) => {
      const filterEventInputs = {
        sortByInput: 'best-dishes',
        cuisines: payload.cuisines,
        itemTypes: payload.itemTypes,
      };
      state.filterInputs = filterEventInputs;
    }),

    recentViews: [],
    updateFilters: action((state, payload) => {
      concat(state.currentLocation, payload);
    }),
    fetchFilters: thunk(async (actions, payload) => {
      actions.updateFilters(payload);
    }),
    addRecentView: action((state, payload) => {
      const newRecentView = concat(state.recentViews, payload);
      state.recentViews = uniq(newRecentView);
    }),
  },
};
