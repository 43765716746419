import React, { useState } from 'react';
import styled from 'styled-components';
import { Text, TextArea, Button, FlexColumns } from './elements';
import colors from '../theme/styles/colors';
import font from '../theme/styles/fonts';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5) !important;
    }
    .modal-card {
      border-radius: 6px;
      width: 45rem !important;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    }
    .modal-card-body {
      padding: 1.5rem 2rem;
    }
  }
`;
const ButtonStyle = styled.div`
  &&& {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

const Span = styled.span`
  &&& {
    color: ${colors.darkGrey};
    font-size: ${font.size.small};
  }
`;

const FlaggedReviewModal = ({ isActive, onClose, flag, onSubmit, loading }) => {
  const [content, setContent] = useState('');
  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <form
          className="modal-card-body"
          onSubmit={async event => {
            event.preventDefault();
            if (content.length <= 280) {
              await onSubmit(content);
              if (!loading) {
                setTimeout(() => {
                  setContent('');
                }, 100);
              }
            }
          }}
        >
          <ButtonStyle className="delete" label="close" onClick={onClose} />
          <div>
            <FlexColumns>
              <Text className="is-capitalized" size="large" weight="bold">
                Flag: {flag.name}
              </Text>
            </FlexColumns>
            <hr />
            <div className="field">
              <label>
                <Text
                  className="is-capitalized"
                  size="medium"
                  weight="semibold"
                  paddingBottom={0.5}
                >
                  What&apos;s Wrong ? <Span>( Minimum 20 Characters)</Span>
                </Text>
              </label>
              <div className="control">
                <TextArea value={content} onChange={e => setContent(e.target.value)} minRows={5} />
                <Text
                  className="is-capitalized"
                  size="tiny"
                  color={content.length <= 280 ? 'darkGrey' : 'danger'}
                >
                  Characters left {content.length}/280
                </Text>
              </div>
            </div>
            <FlexColumns>
              <Button
                className={loading ? 'is-loading' : ''}
                type="submit"
                name="Submit"
                width={5}
                backgroundColor="primaryColor"
                textProps={{
                  color: loading ? 'primaryColor' : 'white',
                  weight: 'semibold',
                  size: 'small',
                }}
                disabled={content.length < 20 || content.length > 280}
              />
            </FlexColumns>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default FlaggedReviewModal;
