import React, { useState } from 'react';
import styled from 'styled-components';
// import { useStoreState } from 'easy-peasy';
import { MdSearch } from 'react-icons/md';
import { Text, ScrollToTopButton, Pager } from '../../components/elements';
import DeskUser from './DeskUser';

const Container = styled(Pager)`
  padding-top: 2rem;
`;
const PageHeader = styled.div`
  position: sticky;
  top: 4rem;
  z-index: 100;
  background: #ffffff;
  padding: 1rem;
`;

const Form = styled.form`
  position: sticky;
  top: 8.5rem;
  z-index: 100;
  background: #ffffff;
  padding: 1rem;
`;

const DesktopView = ({
  loading,
  users,
  placeReviews,
  itemReviews,
  follows,
  onHandleFindFriends,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  // const { userId } = useStoreState(state => state.auth);

  return (
    <>
      <PageHeader>
        <Text size="large" weight="semibold">
          Find Friends
        </Text>
        <Text color="darkGrey" size="tiny">
          Connect with friends or find people to follow and discover what they Krave...
        </Text>
      </PageHeader>
      <div className="columns">
        <div className="column is-6">
          <Form
            onSubmit={event => {
              event.preventDefault();
              onHandleFindFriends(searchQuery);
            }}
          >
            <div className="field has-addons">
              <div className="control is-expanded has-icons-left">
                <input
                  className="input is-fullwidth"
                  type="text"
                  placeholder="Search by name, display name and email"
                  id="FindFriend_Search"
                  value={searchQuery}
                  onChange={event => setSearchQuery(event.target.value)}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user" />
                </span>
              </div>
              <div className="control">
                <button type="submit" className={`button is-info ${loading && 'is-loading'}`}>
                  <MdSearch size={20} />
                </button>
              </div>
            </div>
          </Form>
          <Container>
            {users.map(user => (
              <DeskUser
                key={user.user_id}
                user={user}
                itemReview={itemReviews.filter(item => item.user === user.user_id)}
                placeReview={placeReviews.filter(item => item.user === user.user_id)}
                follow={follows.filter(item => item.user === user.user_id)}
              />
            ))}
          </Container>
          <ScrollToTopButton />
        </div>
      </div>
    </>
  );
};

export default DesktopView;
