import Analytics from 'analytics-node';
import config from './config';

const analytics = new Analytics(config.analyticsKey);

export const trackEvent = (userId, eventName, properties) => {
  try {
    analytics.track({
      userId,
      event: eventName,
      properties: properties || {},
    });
  } catch (error) {
    console.log(error, error.stack);
  }
};

export const trackIdentity = (userId, traits) => {
  try {
    analytics.identify({
      userId,
      traits: traits || {},
    });
  } catch (error) {
    console.log(error, error.stack);
  }
};

export const trackPage = (userId, category, pageName, properties) => {
  try {
    analytics.page({
      userId,
      category,
      name: pageName,
      properties: properties || {},
    });
  } catch (error) {
    console.log(error, error.stack);
  }
};
