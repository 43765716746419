import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
// import { Breadcrumbs } from 'react-breadcrumbs';
import { Text, FlexRows, Icon, Flex } from '../../elements';

const Wrapper = styled(FlexRows).attrs({ justify: 'flex-start' })`
  margin: 0rem auto;
  max-width: 1330px;
  z-index: 201;
`;

const Item = styled.div`
  margin: 0rem 0.8rem;
  padding: 0.5rem 0.2rem;
  position: relative;
  top: 2px;
  border-bottom: 2px solid
    ${({ theme: { primaryColor }, isActive }) => (isActive ? primaryColor : 'transparent')};
`;

const FlexStyle = styled(Flex).attrs({ justify: 'flex-start' })``;

const TextStyle = styled(Text).attrs({ size: 'tiny', color: 'dark' })`
  padding-left: 0.5rem;
  &&& {
    :hover {
      color: ${({ theme: { primaryColor } }) => primaryColor};
    }
  }
`;

const SubHeader = ({ location, match, history }) => {
  const { place, item, event } = useStoreActions(state => state);

  const handlePush = path => {
    place.resetPlaceFilter();
    event.resetEventFilter();
    item.resetItemFilter();
    history.push(path);
  };

  return (
    <Wrapper>
      <a onClick={() => handlePush('/places')}>
        <Item isActive={location.pathname === '/places'}>
          <FlexStyle>
            <Icon name="headerPlaceAroundMe" size={1.6} />
            <TextStyle weight={location.pathname === '/places' ? 'semibold' : 'regular'}>
              Places Around Me
            </TextStyle>
          </FlexStyle>
        </Item>
      </a>
      <a onClick={() => handlePush('/items')}>
        <Item isActive={location.pathname === '/items'}>
          <FlexStyle>
            <Icon name="headerFindItem" size={1.6} />
            <TextStyle weight={location.pathname === '/items' ? 'semibold' : 'regular'}>
              Find An Item
            </TextStyle>
          </FlexStyle>
        </Item>
      </a>
      <a onClick={() => handlePush('/order-food')}>
        <Item
          isActive={
            location.pathname === '/order-food' ||
            location.pathname === `/order-food/${match.params.serviceType}/${match.params.placeId}`
          }
        >
          <FlexStyle>
            <Icon name="corporateCateringSolidGrey" size={1.6} />
            <TextStyle weight={location.pathname === '/order-food' ? 'semibold' : 'regular'}>
              Order Food
            </TextStyle>
          </FlexStyle>
        </Item>
      </a>
      <a onClick={() => handlePush('/events')}>
        <Item isActive={location.pathname === '/events'}>
          <FlexStyle>
            <Icon name="headerDiscoverEvent" size={1.6} />
            <TextStyle>Discover Events</TextStyle>
          </FlexStyle>
        </Item>
      </a>
      <a
        onClick={() =>
          location.pathname !== '/spot-and-share-an-item/' && handlePush('/spot-and-share-an-item/')
        }
      >
        <Item isActive={location.pathname === '/spot-and-share-an-item/'}>
          <FlexStyle>
            <Icon name="headerSpotShareItem" size={1.3} />
            <TextStyle>Spot and Share An Item</TextStyle>
          </FlexStyle>
        </Item>
      </a>

      <a
        onClick={() =>
          location.pathname !== '/place-search-for-review/' &&
          handlePush('/place-search-for-review/')
        }
      >
        <Item
          isActive={
            location.pathname === '/place-search-for-review/' ||
            location.pathname === `/place-review/${match.params.placeId}`
          }
        >
          <FlexStyle>
            <Icon name="headerWriteReview" size={1.3} />
            <TextStyle>Write a Review</TextStyle>
          </FlexStyle>
        </Item>
      </a>
    </Wrapper>
  );
};

export default withRouter(SubHeader);
