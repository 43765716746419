import React from 'react';
import styled from 'styled-components';
import { PageHeader } from '../../components/elements';
import MobileForm from './MobileForm';

const Container = styled.div`
  padding-top: 4rem;
`;

const MobileView = ({ children }) => (
  <>
    <PageHeader text="Report error" />
    <Container>
      <MobileForm />
    </Container>
    {children}
  </>
);

export default MobileView;
