import React, { useState } from 'react';
import styled from 'styled-components';
import MobileUser from './MobileUser';
import Loading from '../../components/global/mobile/MobileLoading';

const Container = styled.div`
  padding: 1rem 0rem;
`;

const Form = styled.form`
  padding: 0rem 1rem;
`;

const MobileView = ({
  loading,
  users,
  placeReviews,
  itemReviews,
  follows,
  onHandleFindFriends,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <Container>
      <Form
        onSubmit={event => {
          event.preventDefault();
          onHandleFindFriends(searchQuery);
        }}
      >
        <input
          className="input is-fullwidth"
          type="text"
          placeholder="Search by name, display name and email"
          value={searchQuery}
          onChange={event => setSearchQuery(event.target.value)}
        />
      </Form>
      <br />
      {loading && <Loading />}
      {users.map(user => (
        <MobileUser
          key={user.user_id}
          user={user}
          itemReview={itemReviews.filter(item => item.user === user.user_id)}
          placeReview={placeReviews.filter(item => item.user === user.user_id)}
          follow={follows.filter(item => item.user === user.user_id)}
        />
      ))}
    </Container>
  );
};

export default MobileView;
