import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed from 'react-pose';

import { Icon, Text } from '.';
import FlexColumns from './FlexColumns';

const ButtonAnimation = posed.a({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const Button = styled(ButtonAnimation)`
  &&& {
    border: 0;
    ${({ paddingLess }) => paddingLess && `padding:0.2rem 0.5rem`};
    background-color: transparent;
  }
`;

const VerticalSpan = styled.span`
  padding-top: 5px;
`;

const IconActionButton = ({ vertical, name, icon, textStyle, iconStyle, paddingLess, onClick }) => {
  return (
    <>
      {!vertical ? (
        <Button className="button" onClick={onClick} paddingLess={paddingLess}>
          {icon && (
            <span className="icon">
              <Icon name={icon} {...iconStyle} />
            </span>
          )}
          <span>
            <Text className="is-capitalized" {...textStyle}>
              {name}
            </Text>
          </span>
        </Button>
      ) : (
        <Button className="button" onClick={onClick}>
          <FlexColumns>
            {icon && (
              <span className="icon">
                <Icon name={icon} {...iconStyle} />
              </span>
            )}
            <VerticalSpan>
              <Text className="is-uppercase" {...textStyle}>
                {name}
              </Text>
            </VerticalSpan>
          </FlexColumns>
        </Button>
      )}
    </>
  );
};

IconActionButton.defaultProps = {
  vertical: false,
  name: 'github',
  textStyle: {},
  iconStyle: {},
  paddingLess: false,
  onClick: () => {},
};

IconActionButton.propTypes = {
  vertical: PropTypes.bool,
  name: PropTypes.string,
  textStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  paddingLess: PropTypes.bool,
  onClick: PropTypes.func,
};

export default IconActionButton;
