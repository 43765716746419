import React from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import gql from 'graphql-tag';
import { MdChevronLeft } from 'react-icons/md';
import { withRouter, Link } from 'react-router-dom';
import { Modal, Field, Text, FlexRows } from '../../components/elements';
import GetImages from '../../components/GetImages';
import { getPlaceTagline } from '../../utils/helpers';

import client from '../../utils/apolloClient';
import Loading from '../../components/global/mobile/MobileLoading';

const ModalHeader = styled(FlexRows).attrs({
  justify: 'space-between',
  align: 'center',
})`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
`;

const ModalHeaderRightWrapper = styled.div`
  width: 100%;
  padding: 0.5rem;
`;
const ModalHeaderLeftWrapper = styled.div`
  padding: 0.5rem;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const TextWrapper = styled.div`
  padding-left: 1rem;
`;

const ItemWrapper = styled(FlexRows)`
  padding: 0.5rem;
`;

const Item = styled(ItemWrapper)`
  &&& {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  }
`;

const RecentContainer = styled.div`
  margin-top: 1rem;
`;
const RecentViewHeader = styled.div`
  background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha};
  padding: 0.5rem 0.8rem;
`;

const searchLocationQuery = gql`
  query searchLocation($search_criteria: String!) {
    search_location(input: { search_criteria: $search_criteria }) {
      location_listing {
        code
        name
        post_code
        state_name
        location
        country_name
        latitude
        longitude
      }
    }
  }
`;

const PlaceSuggestionItem = ({ place }) => (
  <Link to={`/place-review/${place.place_id}`}>
    <Item justify="flex-start" align="flex-start">
      <GetImages
        name={place.name}
        maskProps={{ width: 50, height: 50 }}
        variables={{
          object_type: ['PLACE'],
          object_id: place.place_id,
          links_to_id: [place.place_id],
          links_to_type: ['PLACE'],
        }}
      />
      <TextWrapper>
        <Text className="is-capitalized" size="small">
          {capitalize(place.name)}
        </Text>
        <Text size="micro" color="darkGrey">
          {getPlaceTagline(place.business_type, place.cuisine_style)}
        </Text>
      </TextWrapper>
    </Item>
  </Link>
);

const LocationSuggestionItem = ({ location, onSelect }) => (
  <a onClick={onSelect}>
    <Item justify="flex-start" align="flex-start">
      <TextWrapper>
        <Text className="is-capitalized" size="small">
          {capitalize(location.name)}
        </Text>
        <Text size="tiny" color="darkGrey">
          {location.state_name}, {location.post_code}
        </Text>
      </TextWrapper>
    </Item>
  </a>
);

const MobileSearchModal = ({
  placeLoading,
  places,
  onSuggestionsFetchRequested,
  onSelectLocation,
  usedLocation,
  recentLocationViews,
  recentPlaceViews,
  history,
}) => {
  const [visibleLocationList, setVisibleLocationList] = React.useState(false);
  const [locations, setLocations] = React.useState([]);
  const [locationQueryTag, setLocationQueryTag] = React.useState(usedLocation.city);
  const [locationLoading, setLocationLoading] = React.useState(false);

  const onSuggestionsFetchLocationRequested = value => {
    setLocationQueryTag(value);
    setLocationLoading(true);
    client.clientPublic
      .query({
        query: searchLocationQuery,
        variables: { search_criteria: value },
      })
      .then(({ data }) => {
        setLocations(data.search_location.location_listing);
        setLocationLoading(false);
      })
      .catch(error => {
        console.log('error', error);
        setLocationLoading(false);
      });
  };

  return (
    <Modal isActive>
      <ModalHeader align="center">
        <ModalHeaderLeftWrapper>
          <a onClick={() => history.goBack()}>
            <Text color="white">
              <MdChevronLeft size={30} />
            </Text>
          </a>
        </ModalHeaderLeftWrapper>
        <ModalHeaderRightWrapper>
          <Field
            leftIcon="fas fa-search"
            placeholder={`Search place in ${capitalize(locationQueryTag)}`}
            onFocus={() => {
              setVisibleLocationList(false);
            }}
            onChange={event => onSuggestionsFetchRequested(event.target.value)}
          />
          <Field
            leftIcon="fas fa-map-marker-alt"
            placeholder="Search by Location"
            value={capitalize(locationQueryTag)}
            onFocus={() => {
              setVisibleLocationList(true);
            }}
            onChange={event => onSuggestionsFetchLocationRequested(event.target.value)}
          />
        </ModalHeaderRightWrapper>
      </ModalHeader>
      <Content>
        {placeLoading || locationLoading ? (
          <Loading />
        ) : (
          <>
            {!visibleLocationList && places.map(place => <PlaceSuggestionItem place={place} />)}
            {visibleLocationList &&
              locations.map(location => (
                <LocationSuggestionItem
                  location={location}
                  onSelect={() => {
                    setLocationQueryTag(location.name);
                    onSelectLocation(location.location);
                  }}
                />
              ))}
            {recentPlaceViews.length !== 0 && (
              <RecentContainer>
                <RecentViewHeader>
                  <Text className="is-uppercase" color="primaryColor" weight="bold">
                    Recently Viewed
                  </Text>
                </RecentViewHeader>
                {!visibleLocationList &&
                  recentPlaceViews.map(place => <PlaceSuggestionItem place={place} />)}
                {visibleLocationList &&
                  recentLocationViews.map(location => (
                    <LocationSuggestionItem
                      location={{
                        name: location.city,
                        state_name: location.state,
                        post_code: location.postcode,
                      }}
                      onSelect={() => {
                        setLocationQueryTag(location.name);
                        onSelectLocation(location.location);
                      }}
                    />
                  ))}
              </RecentContainer>
            )}
          </>
        )}
      </Content>
    </Modal>
  );
};

export default withRouter(MobileSearchModal);
