import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed from 'react-pose';
import gql from 'graphql-tag';
import { isNull, omit } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withRouter } from 'react-router-dom';
import uuid from 'uuid';
import swal from 'sweetalert';

import { Icon, Text } from './elements';
import FlaggedReviewModal from './FlaggedReviewModal';

import client from '../utils/apolloClient';

const searchFlagQuery = gql`
  query searchFlag($input: SearchInput) {
    search_flags(input: $input) {
      flag_listing {
        flag_id
        flag_type
        object_id
        object_type
      }
    }
  }
`;

const flagMutation = gql`
  mutation createFlag($input: FlagInput) {
    create_flag(input: $input) {
      flag_id
      error {
        description
      }
    }
  }
`;

const ButtonAnimation = posed.a({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const Button = styled(ButtonAnimation)`
  &&& {
    border: 0;
    ${({ paddingLess }) => paddingLess && `padding:0.2rem 0.5rem`};
    background-color: transparent;
  }
`;

const FlaggedActionButton = ({
  className,
  objectId,
  parentId,
  objectType,
  name,
  icon,
  textStyle,
  iconStyle,
  paddingLess,
  suggestions,
  dropdownHeading,
  dropdownHeadingFlagIcon,
  createdBy,
}) => {
  const [isActiveModal, setIsActiveModal] = React.useState(false);
  const [flag, setFlag] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [flagActive, setFlagActive] = React.useState(true);
  const [allReadyFlagged, setAllReadyFlagged] = React.useState(false);
  const setIsActiveDesktopLoginModal = useStoreActions(
    state => state.auth.setIsActiveDesktopLoginModal,
  );
  const { isLoggedIn, userId } = useStoreState(state => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      if (userId === createdBy) {
        setFlagActive(false);
      }
      client.clientPrivate
        .query({
          query: searchFlagQuery,
          variables: {
            input: {
              filter: {
                flag_filter: {
                  user_id: userId,
                  object_id: objectId,
                  object_type: objectType,
                },
              },
            },
          },
        })
        .then(({ data }) => {
          if (data.search_flags && data.search_flags.flag_listing) {
            if (data.search_flags.flag_listing.length !== 0) {
              console.log('search_flag', data.search_flags.flag_listing);
              setAllReadyFlagged(true);
            }
          }
        })
        .catch(() => {});
    } else {
      setFlagActive(true);
    }
  }, [createdBy, isLoggedIn, objectId, objectType, parentId, userId]);

  const createMutation = content => {
    setLoading(true);
    client.clientPrivate
      .mutate({
        mutation: flagMutation,
        variables: {
          input: {
            user_id: userId,
            flag_id: uuid(),
            object_id: objectId,
            object_type: objectType,
            flag_type: flag.key,
            flag_content: content,
            parent_id: parentId,
            parent_type: 'PLACE',
            status: 'SUBMITTED',
          },
        },
      })
      .then(({ data: { create_flag } }) => {
        setLoading(false);
        setIsActiveModal(false);
        if (!isNull(create_flag.error)) {
          swal('Something went wrong!', 'error');
        } else {
          swal(
            'Thank You!',
            // eslint-disable-next-line max-len
            'Thanks for flagging this content. We will review your comments and action them appropriately!',
            'success',
          );
          setAllReadyFlagged(true);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleFlag = value => {
    if (!isLoggedIn) {
      setIsActiveDesktopLoginModal({
        value: true,
        path: '',
        callBackFunction: () => {
          setIsActiveModal(true);
          setFlag(value);
        },
      });
    } else {
      setIsActiveModal(true);
      setFlag(value);
    }
  };

  if (allReadyFlagged) {
    const textProps = omit(textStyle, ['color']);
    return (
      <Button className="button" paddingLess={paddingLess}>
        {icon && (
          <span className="icon">
            <Icon name={icon} {...iconStyle} />
          </span>
        )}
        <span>
          <Text className="is-capitalized" color="danger" {...textProps}>
            Flagged
          </Text>
        </span>
      </Button>
    );
  }

  return (
    <>
      <div className={`dropdown is-hoverable ${className}`}>
        <div className="dropdown-trigger">
          <Button className="button" paddingLess={paddingLess} disabled={!flagActive}>
            {icon && (
              <span className="icon">
                <Icon name={icon} {...iconStyle} />
              </span>
            )}
            <span>
              <Text className="is-capitalized" {...textStyle}>
                {name}
              </Text>
            </span>
          </Button>
        </div>
        {flagActive && (
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              <div className="dropdown-item">
                <div className="is-flex">
                  {icon && (
                    <span className="icon">
                      <Icon name={dropdownHeadingFlagIcon} size={1.1} />
                    </span>
                  )}
                  &nbsp;
                  <Text size="small" weight="semibold" color="primaryColor">
                    {dropdownHeading}
                  </Text>
                </div>
              </div>
              {suggestions.map(item => (
                <a onClick={() => handleFlag(item)} className="dropdown-item" key={item.key}>
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
      {flag && (
        <FlaggedReviewModal
          isActive={isActiveModal}
          onClose={() => setIsActiveModal(false)}
          flag={flag}
          loading={loading}
          onSubmit={content => {
            createMutation(content);
          }}
        />
      )}
    </>
  );
};

FlaggedActionButton.defaultProps = {
  dropdownHeading: 'Flag this Review',
  className: 'is-up',
  dropdownHeadingFlagIcon: 'flagThisReviewGrey',
  suggestions: [
    { key: 'REPORT_REVIEW', name: 'Report this review' },
    { key: 'REPORT_PICTURE_REVIEW', name: 'Report this picture' },
    { key: 'INAPPROPRIATE_REVIEW_COMMENT', name: 'Inappropriate comments' },
    { key: 'PLACE_CLOSED', name: 'Place is Closed' },
    { key: 'OTHER_REVIEW_REPORT', name: 'Other' },
  ],
};

FlaggedActionButton.propTypes = {
  dropdownHeading: PropTypes.string,
  className: PropTypes.string,
  dropdownHeadingFlagIcon: PropTypes.string,
  suggestions: PropTypes.array,
};

export default withRouter(FlaggedActionButton);
