import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

import { Text, Modal, FlexRows, Input } from '../../../components/elements';

const CouponsSection = styled.div`
  padding-top: 0.5rem;
  padding-top: 0rem;
  /* height: 70vh;
  overflow: scroll; */
`;

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const InputWrapper = styled.div`
  z-index: 50;
  width: 100%;
  padding: 0.5rem;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
  padding: 1rem;
`;

const Element = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const CouponHeader = styled.div`
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
`;

const ApplyVoucherModal = ({
  allVouchers,
  isActive,
  event,
  isClose,
  handleCoupon,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  applyCouponLoading,
}) => {
  return (
    <Modal isActive={isActive}>
      <ModalHeader>
        <FlexRows justify="space-between">
          <a onClick={isClose}>
            <Text color="white">
              <FaTimes size={25} />
            </Text>
          </a>
          <Text className="is-uppercase" align="center" color="white" weight="bold" size="medium">
            Promo Code
          </Text>
          <div />
        </FlexRows>
      </ModalHeader>
      <InputWrapper>
        <div className="field has-addons">
          <div className="control is-expanded">
            <Input
              className="input"
              type="text"
              placeholder="Enter Promo Code"
              name="voucherCode"
              value={values.voucherCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="control">
            <a
              className={`button is-primary ${applyCouponLoading && 'is-loading'}`}
              onClick={handleSubmit}
            >
              Apply
            </a>
          </div>
        </div>
      </InputWrapper>
      <Content>
        <CouponsSection>
          <CouponHeader>
            <Text className="is-capitalized" size="small" weight="semibold" color="primaryColor">
              Available Promo Codes
            </Text>
          </CouponHeader>
          {allVouchers.map(deal => (
            <Element key={deal.deal_id}>
              <Text className="is-capitalized" size="small" weight="semibold" paddingBottom={0.3}>
                {deal.tagline}
              </Text>
              {/* <Text size="small" weight="semibold" color="darkGrey" paddingBottom={0.2}>
                        {deal.tagline}
                      </Text> */}
              <Text size="micro" color="darkGrey" paddingBottom={0.3}>
                {deal.description}
              </Text>

              <FlexRows justify="space-between" style={{ paddingTop: '1rem' }}>
                <span className="tag is-primary" style={{ padding: '1rem' }}>
                  <Text size="small" weight="semibold" color="white">
                    {deal.voucher_code}
                  </Text>
                </span>

                <a onClick={() => handleCoupon(deal.voucher_code)}>
                  <Text
                    className="is-uppercase"
                    size="small"
                    weight="semibold"
                    color="primaryColor"
                  >
                    <span style={{ marginRight: '0.5rem' }}>Apply</span>
                  </Text>
                </a>
              </FlexRows>
            </Element>
          ))}
        </CouponsSection>
      </Content>
    </Modal>
  );
};

export default ApplyVoucherModal;
