import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';

import PlaceReviewItem from './PlaceReviewItem';
import Loading from '../Loading';
import Empty from '../Empty';

const searchPlaceReviewQuery = gql`
  query searchPlaceReview($input: SearchInput) {
    search_place_review(input: $input) {
      place_review_listing {
        place_id
        place_review_id
        month_visited
        overall_rating
        service_category {
          name
          description
        }
        content
        status
        audit {
          created_at
          created_by
        }
        place {
          place_id
          name
          tagline
          description
          address_line_1
          city
          state
          post_code
          country
        }
      }
    }
    search_social(input: { filter: { social_filter: { object_type: "PLACE_REVIEW" } } }) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 0rem 1rem;
  margin-top: 2rem;
`;

const Reviews = () => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Query
      query={searchPlaceReviewQuery}
      variables={{
        input: {
          filter: {
            place_review_filter: {
              user_id: userId,
              draft: false,
              // status: 'APPROVED',
            },
          },
        },
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <Empty isError />;
        }
        if (!data) {
          return '';
        }

        if (isNull(data.search_place_review)) {
          return <Empty />;
        }

        if (data.search_place_review.place_review_listing.length === 0) {
          return <Empty />;
        }
        const reviews = data.search_place_review.place_review_listing;
        const socials = data.search_social.social_aggregation.total_social_per_object_id;
        return (
          <Container>
            {reviews.map(review => (
              <PlaceReviewItem review={review} key={review.place_review_id} socials={socials} />
            ))}
          </Container>
        );
      }}
    </Query>
  );
};

export default Reviews;
