import React, { useRef } from 'react';
import styled from 'styled-components';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { Text } from '../../../../components/elements';
import DealItem from './DealItem';

const Container = styled.div`
  padding-bottom: 2rem;
  .columns {
    flex-wrap: wrap;
  }
`;

const Heading = styled(Text)`
  padding-top: 1rem;
  padding-bottom: 2rem;
`;

const Deals = ({ isCustomService, placeLocation, handleTabSwitch, deals, size }) => {
  const reference = useRef();
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y <= 250) {
        handleTabSwitch();
      }
    },
    [],
    reference,
  );
  return (
    <Container ref={reference}>
      <Heading weight="semibold" size="bmedium">
        Deals
      </Heading>
      <div className="columns is-multiline">
        {deals.map(deal => (
          <DealItem
            isCustomService={isCustomService}
            key={deal.deal_id}
            placeLocation={placeLocation}
            deal={deal}
            size={size}
          />
        ))}
      </div>
    </Container>
  );
};

export default Deals;
