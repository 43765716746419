import React from 'react';
import styled from 'styled-components';

const Container = styled.input`
  width: 100%;
  padding: 10px;
  border: 1.5px solid #fafafa;
  ${({ lite }) => (lite ? 'border-radius: 0px;' : 'border-radius: 5px;')}
  background: #fafafa;
  outline: none;
  font-weight: ${({ weight, theme: { fonts } }) => fonts.weight[weight || 'regular']};
  font-size: ${({ size, theme: { fonts } }) => fonts.size[size || 'small']};
  text-transform: capitalize;
  :focus {
    ${({ lite }) => (lite ? 'border-bottom: 1.5px solid #00aeef' : 'border: 1.5px solid #00aeef')}
    background: #fafafa;
  }
  [type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background: #fafafa;
  }
`;

const Input = ({ lite = false, ...props }) => {
  return <Container lite={lite} {...props} />;
};

export default Input;
