import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import uuid from 'uuid';
import swal from 'sweetalert';
import { forEach, first } from 'lodash';
import { useStoreState } from 'easy-peasy';
import qs from 'querystringify';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';
import ErrorMessage from '../../components/global/ErrorMessage';
import Loading from '../../components/global/Loading';
import client from '../../utils/apolloClient';

const searchImageReviewQuery = gql`
  query searchImageReview($placeId: String, $itemReviewId: String) {
    search_image_review(
      input: {
        filter: {
          image_review_filter: {
            object_id: $itemReviewId
            parent_id: $placeId
            object_type: "ITEM_REVIEW"
            parent_type: "PLACE"
            approval_status: "SUBMITTED"
          }
        }
      }
    ) {
      image_review_listing {
        image_id
        image_review_id
        url
        tags
        approval_status
        status
      }
    }
  }
`;

const itemReviewQuery = gql`
  query itemReview($placeId: String, $itemId: String, $itemReviewId: String) {
    fetch_item_review(
      input: { place_id: $placeId, item_id: $itemId, item_review_id: $itemReviewId }
    ) {
      place_id
      item_id
      item_review_id
      rating
      cuisine_style {
        name
        description
      }
      item_type {
        name
        description
      }
      content
      audit {
        created_at
        created_by
      }
    }
  }
`;

const itemQuery = gql`
  query fetch_item($input: NavigateItemInput) {
    fetch_item(input: $input) {
      item_id
      place_id
      place_name
      name
      slug
      loved_percentage
      tag
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      timezone
      cuisine_style {
        name
        description
        display_order
      }
      item_type {
        name
        description
        display_order
      }
      audit {
        created_at
      }
    }
  }
`;

const createImageMutation = gql`
  mutation createImage($input: ImageInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
  }
`;

const createItemReview = gql`
  mutation itemReview($input: ItemReviewInput) {
    create_item_review(input: $input) {
      item_review_id
      place_id
      item_id
      error {
        description
      }
    }
  }
`;

const updateItemReview = gql`
  mutation itemReview($input: ItemReviewInput) {
    update_item_review(input: $input) {
      item_review_id
      place_id
      item_id
      error {
        description
      }
    }
  }
`;

const titleMessage = 'Thanks for sharing your review.';
const textMessage = 'Your review will be posted shortly.';

const draftTitleMessage = 'Your review has been saved as a draft!.';
const draftTextMessage = 'Your draft can be reviewed, edited and posted from your profile. ';

const parseImage = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        image_id: item.id,
        url: item.url,
        link: item.link,
        tags: item.tags,
        tooltip: item.tooltip,
        width: item.width,
        height: item.height,
      });
    }
  });
  return imageValues;
};

const ItemSpotAndShare = ({ history, match, location }) => {
  const { userId } = useStoreState(state => state.auth);
  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [itemReview, setItemReview] = useState();
  const [isCreated, setIsCreated] = useState(true);
  const [currentReviewId, setCurrentReviewId] = useState();
  const { itemId } = match.params;
  const params = qs.parse(location.search);
  const [reviewFetchLoading, setReviewFetchLoading] = useState(false);
  const [draft, setDraft] = useState(false);
  // console.log('params', params);

  useEffect(() => {
    if (params.reviewId && params.placeId) {
      setReviewFetchLoading(true);
      client.clientPublic
        .query({
          query: itemReviewQuery,
          variables: { placeId: params.placeId, itemReviewId: params.reviewId },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          if (data.fetch_item_review) {
            setItemReview(first(data.fetch_item_review));
            client.clientPrivate
              .query({
                query: searchImageReviewQuery,
                variables: { itemId, placeId: params.placeId, itemReviewId: params.reviewId },
                fetchPolicy: 'network-only',
              })
              .then(({ data: data2 }) => {
                if (data2.search_image_review) {
                  setUploadedImages(data2.search_image_review.image_review_listing);
                  setIsCreated(false);
                  setReviewFetchLoading(false);
                }
              });
          }
        });
    }
  }, [itemId, params.placeId, params.reviewId]);

  return (
    <Layout
      history={history}
      match={match}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading="REVIEW ITEM"
      headerLikeModal
    >
      <Analytics pageName="MyBookmarks" category="mybookmark" title="MyBookmark-List">
        <Query
          query={itemQuery}
          variables={{ input: { item_id: itemId } }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading || reviewFetchLoading) {
              return <Loading />;
            }
            if (error) {
              return <ErrorMessage />;
            }

            const item = first(data.fetch_item);

            return (
              <Mutation
                client={client.clientPrivate}
                mutation={createImageMutation}
                onCompleted={() => {
                  swal(
                    draft ? draftTitleMessage : titleMessage,
                    draft ? draftTextMessage : textMessage,
                    'success',
                  ).then(() => {
                    history.goBack();
                  });
                }}
              >
                {(create_image, { loading: imageLoading }) => (
                  <Mutation
                    client={client.clientPrivate}
                    mutation={updateItemReview}
                    onCompleted={() => {
                      create_image({
                        variables: {
                          input: {
                            user_id: userId,
                            object_id: currentReviewId,
                            object_type: 'ITEM_REVIEW',
                            parent_id: item.place_id,
                            parent_type: 'PLACE',
                            upload_type: 'USER',
                            links_to: [
                              {
                                id: itemId,
                                type: 'ITEM',
                              },
                            ],
                            images: parseImage(images),
                          },
                        },
                      });
                    }}
                  >
                    {(update_item_review, { loading: updateItemReviewLoading }) => (
                      <Mutation
                        client={client.clientPrivate}
                        mutation={createItemReview}
                        onCompleted={() => {
                          create_image({
                            variables: {
                              input: {
                                user_id: userId,
                                object_id: currentReviewId,
                                object_type: 'ITEM_REVIEW',
                                parent_id: item.place_id,
                                parent_type: 'PLACE',
                                upload_type: 'USER',
                                links_to: [
                                  {
                                    id: itemId,
                                    type: 'ITEM',
                                  },
                                ],
                                images: parseImage(images),
                              },
                            },
                          });
                        }}
                      >
                        {(create_item_review, { loading: itemReviewLoading }) => (
                          <>
                            <div className="is-hidden-desktop">
                              <MobileView
                                item={item}
                                uploadedImages={uploadedImages}
                                itemReview={itemReview}
                                isCreated={isCreated}
                                history={history}
                                loading={
                                  imageLoading || itemReviewLoading || updateItemReviewLoading
                                }
                                onSubmit={values => {
                                  setDraft(values.isDraft);
                                  setImages(values.image);
                                  const reviewInput = {
                                    user_id: userId,
                                    item_review_id: isCreated ? uuid() : itemReview.item_review_id,
                                    item_id: item.item_id,
                                    place_id: item.place_id,
                                    item_name: item.name,
                                    rating: values.overall_rating,
                                    cuisine_style: values.cuisine_style,
                                    item_type: values.item_type,
                                    content: values.content,
                                    draft: values.isDraft,
                                  };
                                  setCurrentReviewId(reviewInput.item_review_id);
                                  if (isCreated) {
                                    create_item_review({
                                      variables: { input: reviewInput },
                                    });
                                  } else {
                                    update_item_review({
                                      variables: { input: reviewInput },
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="is-hidden-touch">
                              <DesktopView
                                item={item}
                                uploadedImages={uploadedImages}
                                itemReview={itemReview}
                                isCreated={isCreated}
                                history={history}
                                loading={
                                  imageLoading || itemReviewLoading || updateItemReviewLoading
                                }
                                onSubmit={values => {
                                  setDraft(values.isDraft);
                                  setImages(values.image);
                                  const reviewInput = {
                                    user_id: userId,
                                    item_review_id: isCreated ? uuid() : itemReview.item_review_id,
                                    item_id: item.item_id,
                                    place_id: item.place_id,
                                    item_name: item.name,
                                    rating: values.overall_rating,
                                    cuisine_style: values.cuisine_style,
                                    item_type: values.item_type,
                                    content: values.content,
                                    draft: values.isDraft,
                                  };
                                  setCurrentReviewId(reviewInput.item_review_id);
                                  if (isCreated) {
                                    create_item_review({
                                      variables: { input: reviewInput },
                                    });
                                  } else {
                                    update_item_review({
                                      variables: { input: reviewInput },
                                    });
                                  }
                                }}
                              />
                            </div>
                          </>
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                )}
              </Mutation>
            );
          }}
        </Query>
      </Analytics>
    </Layout>
  );
};

export default ItemSpotAndShare;
