import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';

import Loading from './Loading';
import Empty from './Empty';
import PlaceReviewItem from './Place';
import ItemReviewItem from './Item';

const Container = styled.div`
  padding: 1.5rem 1rem;
`;

const searchReviewQuery = gql`
  query searchItemReview(
    $itemReviewFilter: ItemReviewFilter
    $placeReviewFilter: PlaceReviewFilter
  ) {
    search_place_review(input: { filter: { place_review_filter: $placeReviewFilter } }) {
      place_review_listing {
        place_id
        place_review_id
        month_visited
        overall_rating
        service_category {
          name
          description
        }
        content
        audit {
          created_at
          created_by
        }
      }
    }
    search_item_review(input: { filter: { item_review_filter: $itemReviewFilter } }) {
      item_review_listing {
        place_id
        item_id
        item_review_id
        rating
        content
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const Drafts = () => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Query
      query={searchReviewQuery}
      variables={{
        placeReviewFilter: {
          user_id: userId,
          draft: true,
          status: 'SUBMITTED',
        },
        itemReviewFilter: {
          user_id: userId,
          draft: true,
          status: 'SUBMITTED',
        },
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <Empty isError />;
        }

        if (isNull(data.search_item_review) && isNull(data.search_item_review)) {
          return <Empty isError />;
        }

        const itemReviews = data.search_item_review.item_review_listing;
        const placeReviews = data.search_place_review.place_review_listing;

        if (placeReviews.length === 0 && itemReviews.length === 0) {
          return <Empty />;
        }

        return (
          <Container>
            {placeReviews.map(review => (
              <PlaceReviewItem review={review} />
            ))}
            {itemReviews.map(review => (
              <ItemReviewItem review={review} />
            ))}
          </Container>
        );
      }}
    </Query>
  );
};

export default Drafts;
