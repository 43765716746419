import React from 'react';
import { geolocated } from 'react-geolocated';
import Layout from '../../components/global/Layout';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import Analytics from '../../components/global/Analytics';

const Home = ({ history, match }) => {
  const [activeSearchModal, setActiveSearchModal] = React.useState(false);
  return (
    <Layout
      setActiveLocationSearchModal={setActiveSearchModal}
      service="home"
      history={history}
      match={match}
      bottomNavigationEnable={false}
      home
      hideMobileSearchIcon
    >
      <Analytics pageName="Home" category="home" title="Kravein-Home">
        {process.env.ENVIRONMENT}
        <div className="is-hidden-desktop">
          <MobileView
            activeSearchModal={activeSearchModal}
            setActiveSearchModal={setActiveSearchModal}
          />
        </div>
        <div className="is-hidden-touch">
          <DesktopView />
        </div>
      </Analytics>
    </Layout>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Home);
