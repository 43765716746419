import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { Text } from '../../components/elements';
import MobileForm from './MobileForm';
import Loading from '../../components/global/mobile/MobileLoading';

const Container = styled.div``;
const Wrapper = styled.div`
  padding: 1rem;
  padding-bottom: 0rem;
`;

const MobileView = ({ loading, data, claimLoading, createPlaceClaim, history }) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn } = useStoreState(state => state.auth);
  const [step, setStep] = useState(1);
  if (loading) {
    return <Loading />;
  }
  const place = data.fetch_place;
  return (
    <Container>
      <Wrapper>
        <Text size="small" color="darkGrey">
          Claim your free business listing and customized to your taste! If you are an authorised
          representative or the owner of this place, sign up to manage your place and keep all the
          information up to date. You will also be able to upload photos, respond to customer
          feedback, receive notifications, add links to your website and social media. Features
          include unlimited free posts to your users, displaying multiple menu's, black board
          specials, publishing deals or offers for free, creating and managing events and much much
          more.
        </Text>
      </Wrapper>
      <MobileForm
        step={step}
        setStep={setStep}
        setIsActiveModal={setIsActiveModal}
        isLoggedIn={isLoggedIn}
        history={history}
        loading={claimLoading}
        place={place}
        onSubmit={values => {
          createPlaceClaim(values);
        }}
      />
    </Container>
  );
};

export default withRouter(MobileView);
