import AWS from 'aws-sdk';
import {
  replace
} from 'lodash';
import {
  checkTokenExpiration
} from './auth';
import config from './config';

const set_credentials = () =>
  new Promise(resolve => {
    const cognito_id_token = window.localStorage.getItem('token');
    const aws_cognito_config = {};
    aws_cognito_config.IdentityPoolId = config.userPoolConfig.IdentityPoolId;
    aws_cognito_config.Logins = {};
    aws_cognito_config.Logins[
      `cognito-idp.us-east-1.amazonaws.com/${config.userPoolConfig.UserPoolId}`
    ] = cognito_id_token;
    // console.log('aws_cognito_config ---> ', aws_cognito_config);
    AWS.config.update({
      region: 'us-east-1',
      credentials: new AWS.CognitoIdentityCredentials(aws_cognito_config),
    });
    setTimeout(() => resolve(), 2000);
  });

export const upload_image_s3 = async (file, s3Key) => {
  await checkTokenExpiration();
  await set_credentials();
  AWS.config.credentials.get(err => {
    if (err) console.log(err);
  });

  const metadata = {
    type: s3Key
  };
  console.log('metadata', metadata);

  const s3obj = new AWS.S3({
    params: {
      Bucket: `${config.apiPrefix}images.kravein.com.au`,
      Key: s3Key,
      // Metadata: metadata,
    },
  });

  return new Promise((resolve, reject) => {
    // to buffer 10 megabyte chunks and reduce concurrency down to 2
    const params = {
      Key: s3Key,
      ContentType: file.type,
      Body: file,
      ACL: 'public-read',
    };
    s3obj.upload(
      params, {
        queueSize: 2,
        partSize: 1024 * 1024 * 10,
        leavePartsOnError: true,
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }
        console.log(data);
        return resolve(data);
      },
    );
  });
};

export const uploadProfileImageS3 = async (image, filName) => {
  await set_credentials();
  AWS.config.credentials.get(err => {
    if (err) console.log(err);
  });

  const s3obj = new AWS.S3({
    params: {
      Bucket: `${config.apiPrefix}images.kravein.com.au`,
      Key: filName,
      // Metadata: metadata,
    },
  });
  // eslint-disable-next-line no-buffer-constructor
  const buf = new Buffer(image.replace(/^data:image\/\w+;base64,/, ''), 'base64');

  return new Promise((resolve, reject) => {
    // to buffer 10 megabyte chunks and reduce concurrency down to 2
    const params = {
      Key: filName,
      Body: buf,
      ContentEncoding: 'base64',
      ContentType: 'image/jpeg',
      ACL: 'public-read',
    };
    s3obj.upload(
      params, {
        queueSize: 2,
        partSize: 1024 * 1024 * 10,
        leavePartsOnError: true,
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }
        console.log(data);
        return resolve(data);
      },
    );
  });
};

export const getTicketUrlPdf = async key => {
  await set_credentials();
  return new Promise(resolve => {
    const s3 = new AWS.S3({
      params: {
        Bucket: `${config.apiPrefix}static.kravein.com.au`,
        Key: key
      },
    });
    s3.getObject({
      Bucket: `${config.apiPrefix}static.kravein.com.au`,
      Key: key
    }, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        const url = new Blob([data.Body]);
        resolve(URL.createObjectURL(url));
      }
    });
  });
};

export const getEncodedImage = (imageUrl, width, height) => {
  const image_request = JSON.stringify({
    bucket: `${config.apiPrefix}images.kravein.com.au`,
    key: replace(
      imageUrl,
      `https://s3.amazonaws.com/${config.apiPrefix}images.kravein.com.au/`,
      '',
    ),
    edits: {
      rotate: null,
      resize: {
        width,
        height,
        fit: 'cover',
      },
    },
  });

  const url_prefix = `https://${config.apiPrefix}images.kravein.com.au/`;
  const url = url_prefix + btoa(image_request);
  return url;
};