/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import { concat, uniq, forEach, reverse, sortBy, dropRight } from 'lodash';

import { getServiceCategory } from '../utils/fetchStatic';

const filterInputs = {
  productType: 'all',
  cuisines: [],
  itemTypes: [],
  businessTypes: [],
  features: [],
  serviceTypes: [],
  dressCodes: [],
  diningStyles: [],
  publicTransports: [],
  ambiences: [],
  priceRanges: [],
};

export default {
  place: {
    sort: { label: 'Best Match', value: 'RATING' },
    productTypes: [],
    filterTags: {
      cuisines: [],
      businessTypes: [],
      features: [],
      itemTypes: [],
      serviceType: [],
      dressCode: [],
      diningStyle: [],
      publicTransports: [],
      ambience: [],
      priceRange: [],
    },
    filterInputs,
    resetPlaceFilter: action(state => {
      state.filterInputs = filterInputs;
    }),
    updateProductTypes: action((state, payload) => {
      state.productTypes = payload;
    }),
    updateSort: action((state, payload) => {
      state.sort = payload;
    }),
    updateFilterTags: action((state, payload) => {
      const emptyArray = [];
      let productTypes = emptyArray;
      let cuisines = emptyArray;
      let itemTypes = emptyArray;
      let features = emptyArray;
      let businessTypes = emptyArray;
      let serviceType = emptyArray;
      let dressCode = emptyArray;
      let diningStyle = emptyArray;
      let publicTransports = emptyArray;
      let ambience = emptyArray;
      let priceRange = emptyArray;

      forEach(payload, item => {
        if (item.aggregation_name === 'service_category') {
          productTypes = sortBy(item.aggregation_listing, [o => o.name]);
        }
        if (item.aggregation_name === 'cuisine_style') {
          cuisines = item.aggregation_listing;
        }
        if (item.aggregation_name === 'item_type') {
          itemTypes = item.aggregation_listing;
        }
        if (item.aggregation_name === 'feature') {
          features = item.aggregation_listing;
        }
        if (item.aggregation_name === 'business_type') {
          businessTypes = item.aggregation_listing;
        }
        if (item.aggregation_name === 'service_type') {
          serviceType = item.aggregation_listing;
        }
        if (item.aggregation_name === 'dress_code') {
          dressCode = item.aggregation_listing;
        }
        if (item.aggregation_name === 'dining_style') {
          diningStyle = item.aggregation_listing;
        }
        if (item.aggregation_name === 'public_transport') {
          publicTransports = item.aggregation_listing;
        }

        if (item.aggregation_name === 'ambience') {
          ambience = item.aggregation_listing;
        }
        if (item.aggregation_name === 'price_range') {
          priceRange = item.aggregation_listing;
        }
      });

      const updateData = {
        productTypes,
        cuisines,
        itemTypes,
        features,
        businessTypes,
        serviceType,
        dressCode,
        diningStyle,
        publicTransports,
        ambience,
        priceRange,
      };
      state.filterTags = updateData;
    }),

    fetchProductTypes: thunk(async actions => {
      const productTypes = await getServiceCategory();
      // const cuisines = await getCuisines();
      // const itemTypes = await getItemTypes();
      // const features = await getFeatures();
      // const businessTypes = await getPlaceTypes();
      // const serviceType = await getServicesTypes();
      // const dressCode = await getDressCodes();
      // const diningStyle = await getDiningStyle();
      // const publicTransports = await getPublicTransports();
      // const ambience = await getAmbience();
      // const data = {
      //   productTypes: uniqBy(productTypes, 'name'),
      //   cuisines: uniqBy(cuisines, 'name'),
      //   itemTypes: uniqBy(itemTypes, 'name'),
      //   features: uniqBy(features, 'name'),
      //   businessTypes: uniqBy(businessTypes, 'name'),
      //   serviceType: uniqBy(serviceType, 'name'),
      //   dressCode: uniqBy(dressCode, 'name'),
      //   diningStyle: uniqBy(diningStyle, 'name'),
      //   publicTransports: uniqBy(publicTransports, 'name'),
      //   ambience: uniqBy(ambience, 'name'),
      // };
      actions.updateProductTypes(productTypes);
    }),

    updateFilterInputs: action((state, payload) => {
      const filterEventInputs = {
        productType: payload.productType,
        cuisines: payload.cuisines,
        businessTypes: payload.businessTypes,
        features: payload.features,
        itemTypes: payload.itemTypes,
        business: payload.business,
        serviceTypes: payload.serviceTypes,
        dressCodes: payload.dressCodes,
        diningStyles: payload.diningStyles,
        publicTransports: payload.publicTransports,
        ambiences: payload.ambiences,
        priceRanges: payload.priceRanges,
      };
      state.filterInputs = filterEventInputs;
    }),

    recentViews: [],
    updateFilters: action((state, payload) => {
      concat(state.currentLocation, payload);
    }),
    fetchFilters: thunk(async (actions, payload) => {
      actions.updateFilters(payload);
    }),
    addRecentView: action((state, payload) => {
      const places = state.recentViews.filter(item => item.place_id !== payload.place_id);
      const newRecentView = concat(places, payload);
      const data = reverse(sortBy(uniq(newRecentView), 'createdAt'));
      const dropData = dropRight(data, data ? data.length - 10 : 0);
      state.recentViews = dropData;
    }),
  },
};
