/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { MdKeyboardArrowRight } from 'react-icons/md';
import colors from '../../theme/styles/colors';
import FlexRows from './FlexRows';

const Span = styled.span`
  color: ${({ theme: { colors: color } }) => color.primaryColor};
  text-decoration: underline;
  padding-left: 1rem;
  font-size: ${({ theme: { fonts } }) => fonts.size.smaller};
  position: relative;
  bottom: 3px;
`;

const Span2 = styled.span`
  color: ${({ theme: { colors: color } }) => color.primaryColor};
  font-size: ${({ theme: { fonts } }) => fonts.size.small};
  font-weight: 600;
`;

const GetDirections = ({ latitude, longitude, mobile }) => {
  if (mobile) {
    return (
      <a
        href={`https://maps.google.com/?saddr=Current+Location&daddr=${latitude},${longitude}&driving`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FlexRows justify="flex-start">
          <Span2>GET DIRECTIONS</Span2>&nbsp;
          <MdKeyboardArrowRight size={25} color={colors.darkGrey} />
        </FlexRows>
      </a>
    );
  }
  return (
    <a
      href={`https://maps.google.com/?saddr=Current+Location&daddr=${latitude},${longitude}&driving`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Span>Get Directions </Span>
    </a>
  );
};

export default GetDirections;
