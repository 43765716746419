import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { IoMdClose } from 'react-icons/io';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { first } from 'lodash';

import { Button } from '../../../../../components/lib';
import { FlexRows, Text, FlexItem } from '../../../../../components/elements';
import client from '../../../../../utils/apolloClient';
import Loader from './Loader';

const Container = styled.div`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      top: 6rem;
      max-height: calc(100vh - 20%);
      position: absolute;
      width: 40%;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    .button.is-info {
      padding-left: 3.5em;
      padding-right: 3.5em;
      p {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
`;

const SecondaryHeader = styled.div`
  background: white;
`;
const InputWrapper = styled.div`
  padding: 0.5rem 1rem;
`;

const Content = styled.div`
  padding: 1rem;
`;
const Item = styled.div``;
const ButtonWrapper = styled.div`
  width: 10rem;
  float: right;
`;
const Tag = styled.div`
  &&& {
    background: ${({ theme: { colors } }) => colors.primaryColor};
    padding: 1rem;
  }
`;

const searchMenuDealsQuery = gql`
  query searchMenuDeals($input: SearchInput) {
    search_place_menu_deal(input: $input) {
      place_menu_deal_listing {
        deal_id
        name
        promo_code
        description
      }
    }
  }
`;

const validateDealMutation = gql`
  mutation validateDeal($input: NavigateVerifyOrderDealInput) {
    verify_menu_order_deal(input: $input) {
      deal_id
      deal_type
      promotion
      promo_code
      name
      error {
        code
        description
      }
    }
  }
`;

const DealItem = ({ updateLoading, deal, onClick }) => (
  <>
    <Item>
      <Text
        color="darkYellow"
        weight="semibold"
        size="smedium"
        className="deal-title"
        paddingBottom="0.5"
      >
        {deal.name}
      </Text>
      <Text weight="regular" size="smaller" paddingBottom="0.5">
        {deal.description}
      </Text>
      <FlexRows justify="space-between">
        <FlexItem grow={2}>
          {deal.promo_code && deal.promo_code.length !== 0 && (
            <Tag className="tag">
              <Text color="white" size="medium" weight="semibold">
                {deal.promo_code}
              </Text>
            </Tag>
          )}
        </FlexItem>

        <FlexItem grow={2}>
          <ButtonWrapper>
            <Button isGhost size="large" onClick={onClick} isLoading={updateLoading}>
              Apply
            </Button>
          </ButtonWrapper>
        </FlexItem>
      </FlexRows>
    </Item>
    <hr />
  </>
);

const PromoCodeModal = ({
  appliedDealId,
  placeId,
  menuId,
  userId,
  menuOrderId,
  updateLoading,
  isActive,
  onClose,
  onSubmitPromoCode,
  isErrorWhenFinalApply,
}) => {
  const allEventDateFilter = useStoreState(state => state.global.allEventDateFilter);
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [isError, setIsError] = useState('');

  const fetchItems = useCallback(() => {
    setLoading(true);
    const current = first(allEventDateFilter.filter(item => item.name === 'today'));
    const place_menu_deal_filter = { place_id: placeId, menu_id: [menuId], is_published: true };
    Object.assign(
      place_menu_deal_filter,
      current &&
        current.range && {
          start_date_range: current.range.start_date_range,
        },
      current &&
        current.range && {
          end_date_range: current.range.end_date_range,
        },
    );
    client.clientPublic
      .query({
        query: searchMenuDealsQuery,
        variables: {
          input: {
            size: 200,
            filter: {
              place_menu_deal_filter,
            },
          },
        },
      })
      .then(({ data }) => {
        if (data.search_place_menu_deal && data.search_place_menu_deal.place_menu_deal_listing) {
          const items = data.search_place_menu_deal.place_menu_deal_listing;
          setDeals(items);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [allEventDateFilter, menuId, placeId]);

  useEffect(() => {
    setIsError(isErrorWhenFinalApply);
  }, [isErrorWhenFinalApply]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Apply Promo Code</p>
          <a onClick={onClose} aria-label="close">
            <IoMdClose
              size={25}
              color="#000000"
              style={{ marginLeft: '1rem', cursor: 'pointer' }}
            />
          </a>
        </header>
        <SecondaryHeader>
          <InputWrapper>
            <div className="field has-addons">
              <div className="control" style={{ width: '100%' }}>
                <input
                  className="input is-medium"
                  type="text"
                  placeholder="Select or enter promo code"
                  value={code}
                  onChange={e => setCode(e.target.value)}
                />
              </div>
              <div className="control">
                <Mutation
                  client={client.clientPrivate}
                  mutation={validateDealMutation}
                  onCompleted={({ verify_menu_order_deal }) => {
                    if (verify_menu_order_deal.error) {
                      verify_menu_order_deal.error.map(item => setIsError(item.description));
                    } else {
                      onSubmitPromoCode({ ...verify_menu_order_deal });
                    }
                  }}
                >
                  {(verify_menu_order_deal, { loading: applyDealLoading }) => (
                    <a
                      className={`button is-info is-medium ${applyDealLoading && 'is-loading'}`}
                      onClick={() => {
                        if (code.length !== 0) {
                          verify_menu_order_deal({
                            variables: {
                              input: {
                                user_id: userId,
                                menu_order_id: menuOrderId,
                                promo_code: code,
                              },
                            },
                          });
                        }
                      }}
                      disabled={code.length === 0 || (code.length !== 0 && updateLoading)}
                    >
                      <Text color={!applyDealLoading ? 'white' : 'primaryColor'} size="small">
                        Apply
                      </Text>
                    </a>
                  )}
                </Mutation>
              </div>
            </div>
            {isError.length !== 0 && (
              <Text color="danger" size="small">
                {isError}
                {/* Promo code is invalid. Please try another promo code */}
              </Text>
            )}
          </InputWrapper>
          <Content>
            <Text
              paddingBottom="0.8"
              className=""
              color="primaryColor"
              size="lmedium"
              weight="regular"
            >
              Available Promo Codes
            </Text>
          </Content>
        </SecondaryHeader>

        <section className="modal-card-body">
          <Content>
            {loading ? (
              <Loader />
            ) : (
              <>
                {deals.length === 0 && (
                  <Text
                    paddingBottom="0.8"
                    className=""
                    color="darkGrey"
                    size="medium"
                    weight="regular"
                  >
                    No Promotions or deals available currently, If you have a promo code, please
                    enter the code above
                  </Text>
                )}
                {deals.map(deal => (
                  <DealItem
                    updateLoading={appliedDealId === deal.deal_id ? updateLoading : false}
                    key={deal.deal_id}
                    deal={deal}
                    onClick={() => {
                      onSubmitPromoCode(deal);
                    }}
                  />
                ))}
              </>
            )}
          </Content>
        </section>
      </div>
    </Container>
  );
};

export default PromoCodeModal;
