/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { first } from 'lodash';
import { useStoreState, useStoreActions } from 'easy-peasy';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Loading from '../../components/global/mobile/MobileLoading';
import client from '../../utils/apolloClient';
import {
  geReviewCount,
  getApiImages,
  getFollowerCount,
  getFollowingCount,
} from '../../utils/reusableQuery';
import { getEncodedImage } from '../../utils/s3';

const userQuery = gql`
  query user($userId: String) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      first_name
      last_name
      display_name
      preferred_username
      color_code
      user_handle
      profile_image {
        image_id
      }
      address {
        address_id
        type
        is_default
        contact_name
        contact_phone
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        status
      }
    }
  }
`;

const allCountQuery = gql`
  query allData($userId: String) {
    search_images(input: { filter: { image_filter: { user_id: $userId } } }) {
      url
    }
    search_social(input: { filter: { social_filter: { user_id: $userId } } }) {
      social_aggregation {
        total_social_count {
          type
          count
        }
      }
    }
  }
`;

const Profile = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [countData, setCountData] = useState({
    myBadgesBlueCount: 0,
    rewardPointsBlueCount: 0,
    likeCount: 0,
    itemCount: 0,
    photosCount: 0,
    shareCount: 0,
  });

  useEffect(() => {
    const fetData = async () => {
      const reviewCountData = await geReviewCount(userId);
      const followerCountData = await getFollowerCount(userId);
      const followingCountData = await getFollowingCount(userId);
      setFollowerCount(followerCountData);
      setFollowingCount(followingCountData);
      setReviewCount(reviewCountData);
    };

    fetData();
    client.clientPublic
      .query({
        query: allCountQuery,
        variables: { userId },
      })
      .then(({ data }) => {
        const socialCount = data.search_social.social_aggregation.total_social_count;
        const likeSocial =
          socialCount && socialCount.length !== 0
            ? socialCount.filter(item => item.type === 'LIKE')
            : [];
        const lovedSocial =
          socialCount && socialCount.length !== 0
            ? socialCount.filter(item => item.type === 'LOVE_IT')
            : [];
        const shareSocial =
          socialCount && socialCount.length !== 0
            ? socialCount.filter(item => item.type === 'SHARE')
            : [];

        setCountData({
          myBadgesBlueCount: 0,
          rewardPointsBlueCount: 0,
          likeCount: likeSocial.length !== 0 ? first(likeSocial).count : 0,
          itemCount: lovedSocial.length !== 0 ? first(lovedSocial).count : 0,
          photosCount: data.search_images.length,
          shareCount: shareSocial.length !== 0 ? first(shareSocial).count : 0,
        });
      });
  }, []);

  return (
    <Layout
      history={history}
      match={match}
      bottomNavigationEnable={false}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading="MY PROFILE"
    >
      <Query
        client={client.clientPrivate}
        query={userQuery}
        variables={{ userId }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }
          const { fetch_user } = data;
          const user = {
            ...fetch_user,
            followerCount,
            followingCount,
            ...countData,
            reviewCount,
          };
          return (
            <>
              <div className="is-hidden-desktop">
                <MobileView user={user} />
              </div>
              <div className="is-hidden-touch">
                <DesktopView user={user} />
              </div>
            </>
          );
        }}
      </Query>
    </Layout>
  );
};

export default Profile;
