import React from 'react';
import styled from 'styled-components';

import ReactLoading from 'react-loading';
import theme from '../../../../theme';

const Flex = styled.div`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35vh;
  }
`;
const Loading = () => (
  <div className="columns is-centered">
    <Flex className="column is-12">
      <ReactLoading type="cubes" color={theme.primaryColor} width={80} height={80} />
    </Flex>
  </div>
);

export default Loading;
