import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MobileHeader from './MobileHeader';

const Wrapper = styled.div`
  padding-top: 3rem;
  flex: 1;
  div {
    white-space: pre-wrap;
  }
`;

const MobileViewLayout = ({
  children,
  // service,
  searchInputEnable,
  // state,
  // func,
  setActiveLocationSearchModal,
  searchInputInitValue,
  history,
  // match,
  // bottomNavigationEnable,
  hideMobileSearchIcon,
  home,
}) => {
  // const [activeSearchModal, setActiveSearchModal] = React.useState(false);
  // const handleLocationOnSelectFromSuggestion = item => {
  //   if (item.place_id) {
  //     history.push(`/place/${item.place_id}`);
  //   }
  //   if (item.event_id) {
  //     history.push(`/event/${item.event_id}`);
  //   }
  //   if (item.item_id) {
  //     history.push(`/item/${item.item_id}`);
  //   }
  // };

  return (
    <>
      <MobileHeader
        history={history}
        searchInputEnable={searchInputEnable}
        home={home}
        searchInputInitValue={searchInputInitValue}
        hideMobileSearchIcon={hideMobileSearchIcon}
      />

      <Wrapper>{children}</Wrapper>

      {/* {bottomNavigationEnable && <MobileFooter />} */}
    </>
  );
};

MobileViewLayout.defaultProps = {
  bottomNavigationEnable: true,
  hideMobileSearchIcon: false,
  home: false,
};

MobileViewLayout.propTypes = {
  bottomNavigationEnable: PropTypes.bool,
  hideMobileSearchIcon: PropTypes.bool,
  home: PropTypes.bool,
};

export default MobileViewLayout;
