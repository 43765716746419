import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from 'react-apollo';
import { StoreProvider } from 'easy-peasy';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import AppRouter from './routers/AppRouter';
import bulmaTheme, { GlobalStyle } from './theme';
import client from './utils/apolloClient';
import store from './store';

import './theme/bulma.css';

const persistor = persistStore(store);

const styles = {
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const Loading = () => (
  <div style={styles}>
    <img src="/images/logo.png" alt="logo" style={{ height: '10rem', width: '10rem' }} />
  </div>
);

const App = () => (
  <ApolloProvider client={client.clientPublic}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <StoreProvider store={store}>
        <ThemeProvider theme={bulmaTheme}>
          <div>
            <AppRouter />
            <GlobalStyle />
          </div>
        </ThemeProvider>
      </StoreProvider>
    </PersistGate>
  </ApolloProvider>
);

export default App;
