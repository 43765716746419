import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { first } from 'lodash';
import moment from 'moment';
import { uploadProfileImageS3 } from '../utils/s3';
import { FlexColumns, Slider, FlexRows, Text, Button } from './elements';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-card-head {
      padding: 1rem;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background: white;
    }
    .modal-card-foot {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      justify-content: flex-end;
      background: white;
      padding: 1rem;
    }
    .modal-card {
      width: 760px;
    }
    .modal-card-body {
      min-height: 40vh;
    }
    .file.is-boxed .file-cta {
      padding: 7em 20em !important;
      border-style: dashed;
      :hover {
        border-color: 3px solid ${({ theme: { colors } }) => colors.primaryColor};
      }
    }
  }
`;
const SliderContainer = styled(FlexRows)`
  padding: 1rem;
`;
const SliderContainerElement = styled.div`
  padding: 0rem 1rem;
  width: 100%;
`;

class ImageCropModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      image: null,
      allowZoomOut: true,
      position: { x: 0.5, y: 0.5 },
      scale: 2,
      rotate: 0,
      borderRadius: 0,
      preview: null,
      width: 200,
      height: 200,
    };
  }

  handleDrop = dropped => {
    this.setState({ image: dropped[0] });
  };

  onClickSave = async () => {
    this.setState({ loading: true });
    const { onChange, s3UploadPath } = this.props;
    if (this.editor) {
      const canvas = this.editor.getImageScaledToCanvas().toDataURL();
      const name = `profile-pic-${moment().format('YYYYMMDD_hhmmss')}.png`;
      const { Location: url } = await uploadProfileImageS3(canvas, `${s3UploadPath}${name}`);
      const response = { path: name, url };
      onChange(response);
      this.setState({ loading: false, image: null });
    }
  };

  handlePositionChange = position => {
    this.setState({ position });
  };

  handleScale = value => {
    const scale = parseFloat(value * 0.2);
    this.setState({ scale });
  };

  handleRotate = value => {
    const rotate = parseFloat(value * 5);
    this.setState({ rotate });
  };

  // eslint-disable-next-line no-return-assign
  setEditorRef = editor => (this.editor = editor);

  render() {
    const { isActive, onClose, loading } = this.props;
    return (
      <Container className={`modal ${isActive && 'is-active'}`}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              <Text size="large" weight="semibold">
                Select profile photo
              </Text>
            </p>
            <button className="delete" type="button" aria-label="close" onClick={onClose} />
          </header>
          <section className="modal-card-body">
            <div>
              {this.state.image ? (
                <div>
                  <FlexColumns style={{ background: '#e8e8e8', padding: '1rem 0rem' }}>
                    <AvatarEditor
                      ref={this.setEditorRef}
                      onPositionChange={this.handlePositionChange}
                      position={this.state.position}
                      width={200}
                      height={200}
                      borderRadius={400}
                      scale={this.state.scale}
                      rotate={this.state.rotate}
                      allowZoomOut={this.state.allowZoomOut}
                      image={this.state.image}
                    />
                  </FlexColumns>
                  <SliderContainer justify="space-between">
                    <SliderContainerElement>
                      <Text size="tiny" weight="semibold" color="primaryColor">
                        Zoom
                      </Text>
                      <br />
                      <Slider onChange={value => this.handleScale(first(value))} />
                    </SliderContainerElement>
                    <SliderContainerElement>
                      <Text size="tiny" weight="semibold" color="primaryColor">
                        Rotate
                      </Text>
                      <br />
                      <Slider onChange={value => this.handleRotate(first(value))} />
                    </SliderContainerElement>
                  </SliderContainer>
                </div>
              ) : (
                <div>
                  <FlexColumns>
                    <Dropzone
                      onDrop={this.handleDrop}
                      noClick
                      noKeyboard
                      style={{ width: '250px', height: '250px' }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <div className="file is-boxed">
                            <label className="file-label">
                              <input
                                className="file-input"
                                type="file"
                                name="profile-pic"
                                {...getInputProps()}
                              />
                              <span className="file-cta">
                                <span className="file-label">
                                  <Text size="small" weight="semibold" color="primaryColor">
                                    +Upload photo
                                  </Text>
                                </span>
                              </span>
                              <br />
                              <Text color="darkGrey" size="small">
                                Format accepted - &quot;.png, .jpg, .jpeg&quot;
                              </Text>
                              <Text color="darkGrey" size="small">
                                Recommended Image resolution 200x200
                              </Text>
                              <Text color="darkGrey" size="small">
                                Maximum size accepted - 5MB
                              </Text>
                            </label>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </FlexColumns>
                </div>
              )}
            </div>
          </section>
          <footer className="modal-card-foot">
            <Button
              type="button"
              height={0}
              name="Cancel"
              textProps={{
                size: 'tiny',
                color: 'dark',
                weight: 'semibold',
              }}
              backgroundColor="white"
              borderHoverColor="transparent"
              onClick={onClose}
            />
            &nbsp;&nbsp;&nbsp;
            <Button
              type="button"
              height={0}
              className={`${(loading || this.state.loading) && 'is-loading'}`}
              name="Save Photo"
              textProps={{
                size: 'tiny',
                color: loading || this.state.loading ? 'primaryColor' : 'white',
                weight: 'semibold',
              }}
              backgroundColor="primaryColor"
              onClick={this.onClickSave}
            />
          </footer>
        </div>
      </Container>
    );
  }
}

ImageCropModal.defaultProps = {
  s3UploadPath: 'dummy/',
  metaData: {},
  currentImage: null,
  onChange: () => {},
};

ImageCropModal.propTypes = {
  s3UploadPath: PropTypes.string,
  metaData: PropTypes.object,
  onChange: PropTypes.func,
  currentImage: PropTypes.string,
};

export default ImageCropModal;
