import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';

import SearchSuggestionItem from './SearchSuggestionItem';
import { getKeywordTagline } from '../../../utils/helpers';
import { Text, FlexRows, Icon } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyTheta};
`;

const TextWrapper = styled.div`
  padding-left: 1rem;
`;

const PageRoute = ({ icon, text, path }) => (
  <Link to={path}>
    <Wrapper>
      <FlexRows justify="flex-start">
        <Icon name={icon} size={1.5} />
        <TextWrapper>
          <Text size="small" weight="regular">
            {text}
          </Text>
        </TextWrapper>
      </FlexRows>
    </Wrapper>
  </Link>
);

const SearchSuggestionList = ({ query, places, items, events }) => {
  const placeRecentViews = useStoreState(state => state.place.recentViews);
  const itemRecentViews = useStoreState(state => state.item.recentViews);
  const eventsRecentViews = useStoreState(state => state.event.recentViews);

  const totalRecentItems =
    placeRecentViews.length + itemRecentViews.length + eventsRecentViews.length;
  // TODO: Merge recent into single arry and sort by search date
  return (
    <>
      {places.map(place => (
        <SearchSuggestionItem
          type="place"
          title={place.name}
          subtitle={getKeywordTagline(place.business_type)}
          subtitle1={`${place.address_line_1}, ${place.city}`}
          path={`/place/${place.place_id}`}
        />
      ))}
      {items.map(item => (
        <SearchSuggestionItem
          type="item"
          title={item.name}
          subtitle={item.place_name}
          subtitle1={`${item.city}, ${item.state}`}
          path={`/item/${item.item_id}`}
        />
      ))}
      {events.map(event => (
        <SearchSuggestionItem
          type="event"
          title={event.name}
          subtitle={`${event.address_line_1}, ${event.city}`}
          path={`/event/${event.event_id}`}
        />
      ))}
      {query.length === 0 && (
        <>
          <PageRoute
            icon="itemRatingGoldSolid"
            text="Spot and share an item"
            path="/spot-and-share-an-item/"
          />
          <PageRoute icon="placesAroundMeBlue" text="Places around Me" path="/places" />
          <PageRoute icon="reviewItemBlue" text="Find or Share an Item" path="/items" />
          <PageRoute icon="discoverEventsSolid" text="Discover Events" path="/events" />
          <PageRoute icon="corporateCateringSolid" text="Order Food" path="/order-food" />

          <PageRoute
            icon="reviewAPlaceBlue"
            text="Review a Place"
            path="/place-search-for-review/"
          />
          {totalRecentItems !== 0 && (
            <Wrapper>
              <Text size="small" weight="semibold">
                Recent Search
              </Text>
            </Wrapper>
          )}
          {placeRecentViews.map(place => (
            <SearchSuggestionItem
              type="place"
              title={place.name}
              subtitle={getKeywordTagline(place.business_type)}
              subtitle1={`${place.address_line_1}, ${place.city}`}
              path={`/place/${place.place_id}`}
            />
          ))}
          {itemRecentViews.map(item => (
            <SearchSuggestionItem
              type="item"
              title={item.name}
              subtitle={item.place_name}
              subtitle1={`${item.city}, ${item.state}`}
              path={`/item/${item.item_id}`}
            />
          ))}
          {eventsRecentViews.map(event => (
            <SearchSuggestionItem
              type="event"
              title={event.name}
              subtitle={`${event.address_line_1}, ${event.city}`}
              path={`/event/${event.event_id}`}
            />
          ))}
        </>
      )}
    </>
  );
};

export default SearchSuggestionList;
