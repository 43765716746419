import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isUndefined } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactLoading from 'react-loading';

import MobileFilterContainer from './components/mobile/MobileFilterContainer';
import ItemCard from './components/mobile/ItemCard';
import { MapView, FlexColumns, Text } from '../../components/elements';
import SortByModal from './components/mobile/SortByModal';
import MobileFilterModal from './components/mobile/MobileFilterModal';
import CuisineModal from './components/mobile/CuisineModal';
import ItemReviewActionButton from './components/mobile/ItemReviewActionButton';
import theme from '../../theme';
import { isDesktop } from '../../utils/helpers';

const Container = styled.div`
  padding-top: 0rem;
`;

const getMapLocation = dta => {
  const data = dta.map(item => ({
    id: item.item_id,
    title: `${item.name}`,
    subtitle: item.place_name,
    subtitle1: `${item.address_line_1}, ${item.city}, ${item.state}`,
    imageObject: {
      object_type: ['ITEM'],
      object_id: item.item_id,
      links_to_id: [item.item_id],
      links_to_type: ['ITEM'],
    },
    latitude: parseFloat(item.latitude),
    longitude: parseFloat(item.longitude),
    type: 'ITEM',
  }));
  return data;
};

const MobileView = ({ data, loading, handleMobileFilter, fetchMoreInMobile }) => {
  const [items, setItems] = useState([]);
  const [categoryFilter, setCategoryFilter] = React.useState('best-dishes');
  const [activeSortByModal, setActiveSortByModal] = React.useState(false);
  const [activeMap, setActiveMap] = React.useState(false);
  const [activeFilterModal, setActiveFilterModal] = useState(false);
  const [activeCuisinesModal, setActiveCuisinesModal] = useState(false);
  const [cuisineStyleInputs, setCuisineStyleInputs] = React.useState([]);
  const [itemTypeInputs, setItemTypeInputs] = React.useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setItems(
      !isUndefined(data.search_items) && !isUndefined(data.search_items.item_listing)
        ? items.concat(data.search_items.item_listing)
        : [],
    );
    const totalItemLength = data.search_items.item_listing.length + items.length;
    if (data.search_items.total_size === totalItemLength) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.search_items]);

  const handleInfiniteScroll = () => {
    if (!loading && !isDesktop()) {
      fetchMoreInMobile();
    }
  };

  const setToMainFilter = () => {
    const finalFilter = {
      cuisineStyleInputs,
      itemTypeInputs,
    };
    handleMobileFilter(finalFilter);
    setActiveFilterModal(false);
    setItems([]);
  };

  const clearHelper = () => {
    setCuisineStyleInputs([]);
    setItemTypeInputs([]);
  };

  const clearFilter = async () => {
    await clearHelper();
    setToMainFilter(true);
  };

  const addItemTypes = itemType => {
    const newItemTypeInputs = itemTypeInputs.concat([itemType]);
    setItemTypeInputs(newItemTypeInputs);
  };

  const removeItemTypes = name => {
    const newItemTypeInputs = itemTypeInputs.filter(item => item.name !== name);
    setItemTypeInputs(newItemTypeInputs);
  };

  const addCuisineStyle = cuisineStyle => {
    const newCuisineStyle = cuisineStyleInputs.concat([cuisineStyle]);
    setCuisineStyleInputs(newCuisineStyle);
  };

  const removeCuisineStyle = name => {
    const newCuisineStyle = cuisineStyleInputs.filter(item => item.name !== name);
    setCuisineStyleInputs(newCuisineStyle);
  };
  return (
    <>
      <MobileFilterContainer
        categoryFilter={categoryFilter}
        setCategoryFilter={setCategoryFilter}
        setActiveFilterModal={setActiveFilterModal}
        setActiveMap={setActiveMap}
        activeMap={activeMap}
        setActiveCuisinesModal={setActiveCuisinesModal}
        setActiveSortByModal={setActiveSortByModal}
      />

      {(!activeFilterModal || !activeSortByModal) && (
        <Container>
          <>
            {activeMap ? (
              <MapView data={getMapLocation(items)} />
            ) : (
              <>
                <ItemReviewActionButton />
                {items.length === 0 && !loading && (
                  <FlexColumns style={{ padding: '2rem' }}>
                    <Text size="small" color="darkGrey" weight="semibold">
                      No Item found
                    </Text>
                  </FlexColumns>
                )}
                <InfiniteScroll
                  dataLength={items.length}
                  next={handleInfiniteScroll}
                  hasMore={hasMore}
                  loader={
                    <FlexColumns style={{ padding: '2rem' }}>
                      <ReactLoading type="spin" color={theme.primaryColor} width={50} height={50} />
                    </FlexColumns>
                  }
                >
                  {items.map(item => (
                    <ItemCard item={item} key={item.item_id} />
                  ))}
                </InfiniteScroll>
              </>
            )}
          </>
        </Container>
      )}
      <CuisineModal
        isActive={activeCuisinesModal}
        onCloseModal={() => setActiveCuisinesModal(false)}
        typeInputs={cuisineStyleInputs}
        addType={addCuisineStyle}
        removeType={removeItemTypes}
        done={() => {
          setToMainFilter();
          setActiveCuisinesModal(false);
        }}
      />
      <SortByModal isActive={activeSortByModal} setActiveSortByModal={setActiveSortByModal} />
      <MobileFilterModal
        isActive={activeFilterModal}
        // cuisineStyle
        cuisineStyleInputs={cuisineStyleInputs}
        addCuisineStyle={addCuisineStyle}
        removeCuisineStyle={removeCuisineStyle}
        // itemTypes
        itemTypeInputs={itemTypeInputs}
        addItemTypes={addItemTypes}
        removeItemTypes={removeItemTypes}
        // other
        setActiveFilterModal={setActiveFilterModal}
        setToMainFilter={setToMainFilter}
        clearFilter={clearFilter}
      />
    </>
  );
};
export default MobileView;
