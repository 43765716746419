import React from 'react';

import styled from 'styled-components';

import { Image } from '../../../../components/elements';

import eventDefault from '../../../../assets/images/event-default.png';
import { getEncodedImage } from '../../../../utils/s3';

const Figure = styled.figure``;

const ImageView = ({ image }) => {
  if (image === null || image === '') {
    return (
      <Figure className="image is-2by1">
        <Image source={eventDefault} />
      </Figure>
    );
  }

  return (
    <Figure className="image is-2by1">
      <Image source={getEncodedImage(image, 1024, 680)} />
    </Figure>
  );
};

export default ImageView;
