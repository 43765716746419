import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { first, snakeCase } from 'lodash';
import moment from 'moment';
import { FieldArray } from 'formik';
import uuid from 'uuid';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { IoMdCloseCircle } from 'react-icons/io';
import ReactLoading from 'react-loading';

import { toast } from 'react-toastify';
import { upload_image_s3 } from '../../utils/s3';
import Text from './Text';
import Icon from './Icon';
import FlexColumns from './FlexColumns';

const DropZoneWrapper = styled.div``;

const ImageContainer = styled.div`
  padding: 0.5rem;
`;

const Figure = styled.figure`
  &&& {
    opacity: ${opacity => opacity};
    img {
      border-radius: 6px;
    }
  }
`;

const handleDeleteDisabled = image => {
  let isDisabled = false;
  image.forEach(element => {
    if (element.uploadingStatus === 'uploading') {
      isDisabled = true;
    }
  });
  return isDisabled;
};

const MyDropzone = ({
  item,
  s3UploadPath,
  onChange,
  metaData,
  handleSelectLocalFile,
  totalImageLength,
  onDeleteImage,
  imageDeleteDisabled,
  iconProps,
  textProps,
}) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const isLoggedIn = useStoreState(state => state.auth.isLoggedIn);

  const onDrop = useCallback(
    async acceptedFiles => {
      if (!isLoggedIn) {
        setIsActiveModal({ value: true, path: '' });
      } else {
        const file = acceptedFiles[0];
        console.log('file', file);
        if (file.size < 60000) {
          toast.error('Your Image Quality is not good. Please upload more than 67 KB');
        } else {
          handleSelectLocalFile(URL.createObjectURL(file));
          const imageName = file.name.split('.');
          const name = `${snakeCase(first(imageName))}-${moment().format(
            'YYYYMMDD_hhmmss',
          )}.${file.type.substring(6)}`;

          const { Location: url } = await upload_image_s3(file, `${s3UploadPath}${name}`, metaData);
          const response = { path: name, url };
          onChange(response);
        }
      }
    },
    [handleSelectLocalFile, isLoggedIn, metaData, onChange, s3UploadPath, setIsActiveModal],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DropZoneWrapper>
      {item.preview !== null && (
        <ImageContainer>
          <Figure
            className="image is-square"
            opacity={item.uploadingStatus === 'uploading' ? 0.5 : 1}
          >
            <img src={item.preview} alt="image-upload" style={{ borderRadius: '6px' }} />

            {item.uploadingStatus === 'uploading' ? (
              <div
                style={{
                  position: 'absolute',
                  top: '4px',
                  right: '4px',
                }}
              >
                <ReactLoading type="spinningBubbles" color="#00aeef" height={30} width={30} />
              </div>
            ) : (
              <div
                style={{
                  position: 'absolute',
                  top: '0.5px',
                  right: '0.5px',
                }}
              >
                <a
                  onClick={() =>
                    imageDeleteDisabled
                      ? toast.info('Please wait for uploading completion...')
                      : onDeleteImage()
                  }
                >
                  <span>
                    <IoMdCloseCircle color="#ffffff" size={25} />
                  </span>
                </a>
              </div>
            )}
          </Figure>
        </ImageContainer>
      )}

      {totalImageLength <= 3 && item.uploadingStatus === 'pending' && (
        <FlexColumns style={{ cursor: 'pointer', padding: '0.5rem' }} {...getRootProps()}>
          <input {...getInputProps()} accept="image/x-png,image/jpeg,image/jpg" />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              <Icon name="photoAddSolid" {...iconProps} />
              {textProps.isActive && <Text {...textProps}>Add Photo</Text>}
            </>
          )}
        </FlexColumns>
      )}
    </DropZoneWrapper>
  );
};

const MobileUploadImage = ({
  s3UploadPath,
  metaData,
  setFieldValue,
  iconProps,
  textProps,
  totalNumberOfUploadedImage,
}) => {
  return (
    <FieldArray
      name="image"
      render={arrayHelpers => (
        <>
          {arrayHelpers.form.values &&
            arrayHelpers.form.values.image.map((item, index) => (
              <div className="column is-3">
                <MyDropzone
                  totalImageLength={
                    arrayHelpers.form.values.image.length - totalNumberOfUploadedImage
                  }
                  item={item}
                  s3UploadPath={s3UploadPath}
                  metaData={metaData}
                  index={index}
                  handleChange={arrayHelpers.form.handleChange}
                  handleBlur={arrayHelpers.form.handleBlur}
                  setFieldValue={setFieldValue}
                  handleSelectLocalFile={preview => {
                    setFieldValue(`image.${index}.preview`, preview);
                    setFieldValue(`image.${index}.uploadingStatus`, 'uploading');
                    arrayHelpers.push({
                      id: uuid(),
                      url: '',
                      preview: null,
                      tooltip: '',
                      link: '',
                      tag: [],
                      uploadingStatus: 'pending',
                    });
                  }}
                  onChange={value => {
                    setFieldValue(`image.${index}.url`, value.url);
                    setFieldValue(`image.${index}.uploadingStatus`, 'uploaded');
                  }}
                  imageDeleteDisabled={handleDeleteDisabled(arrayHelpers.form.values.image)}
                  onDeleteImage={() => {
                    const existedImage = arrayHelpers.form.values.image.filter(
                      item2 => item2.id !== item.id,
                    );
                    setFieldValue('image', existedImage);
                  }}
                  iconProps={iconProps}
                  textProps={textProps}
                />
              </div>
            ))}
        </>
      )}
    />
  );
};

MyDropzone.defaultProps = {
  s3UploadPath: 'dummy/',
  metaData: {},
  onChange: () => {},
  totalNumberOfUploadedImage: 1,
  iconProps: { size: 3 },
  textProps: { isActive: true, color: 'darkGrey', size: 'ultraMicro', weight: 'semibold' },
};

MyDropzone.propTypes = {
  s3UploadPath: PropTypes.string,
  metaData: PropTypes.object,
  onChange: PropTypes.func,
  iconProps: PropTypes.object,
  textProps: PropTypes.object,
  totalNumberOfUploadedImage: PropTypes.number,
};

export default MobileUploadImage;
