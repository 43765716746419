import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { isNull } from 'lodash';
import ReactLoading from 'react-loading';

import {
  Text,
  IconActionButton,
  FlexRows,
  ShowMoreText,
  FlexColumns,
} from '../../../../components/elements';
import SocialActionButton from '../../../../components/SocialActionButton';
import FlaggedActionButton from '../../../../components/FlaggedActionButton';
import theme from '../../../../theme';

const feedQuery = gql`
  query fetch_feed($input: SearchInput) {
    search_place_feed(input: $input) {
      place_feed_listing {
        place_id
        feed_id
        name
        city
        title
        content
        audit {
          created_at
          updated_at
          created_by
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 0.5rem 1rem;
`;

const MessageContainer = styled(FlexColumns)`
  height: 30vh;
`;

const Content = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const IconActionButtonWrapper = styled.div`
  padding-top: 1rem;
`;
const SocialActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'smaller', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const IconActionButtonStyle = styled(IconActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const FlaggedActionButtonStyle = styled(FlaggedActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const LatestFeeds = ({ place }) => {
  return (
    <>
      <Query
        query={feedQuery}
        variables={{
          input: {
            sort: 'CREATED_DESC',
            filter: { place_feed_filter: { place_name: place.name, status: 'ACTIVE' } },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return (
              <MessageContainer>
                <ReactLoading type="spin" color={theme.primaryColor} width={50} height={50} />
              </MessageContainer>
            );
          }

          if (error) {
            return (
              <MessageContainer>
                <Text weight="semibold" color="darkGrey">
                  {error.message}
                </Text>
              </MessageContainer>
            );
          }

          if (isNull(data.search_place_feed)) {
            return (
              <MessageContainer>
                <Text weight="semibold" color="darkGrey">
                  No Feed Found
                </Text>
              </MessageContainer>
            );
          }

          return <View feeds={data.search_place_feed.place_feed_listing} />;
        }}
      </Query>
    </>
  );
};

const View = ({ feeds }) => {
  if (feeds.length === 0) {
    return (
      <MessageContainer>
        <Text weight="semibold" color="darkGrey">
          No Feed Found
        </Text>
      </MessageContainer>
    );
  }

  return (
    <Container>
      {feeds.map(feed => (
        <Content key={feed.feed_id}>
          <Text size="small" weight="semibold" paddingBottom={0.2}>
            {feed.title}
          </Text>
          <Text size="micro" color="darkGrey" paddingBottom={0.5}>
            {moment(feed.audit.updated_at).fromNow()}
          </Text>
          <ShowMoreText content={feed.content} limit={500} size="tiny" color="darkGrey" />
          <div className="columns is-gapless" style={{ margin: 0 }}>
            <div className="column is-3">
              <IconActionButtonWrapper>
                <FlexRows justify="space-between">
                  <SocialActionButtonStyle
                    objectId={feed.feed_id}
                    objectType="PLACE_FEED"
                    type="LIKE"
                    name="LIKE"
                    defaultIcon="likeGrey"
                    activeIcon="likeBlue"
                  />
                  <IconActionButtonStyle
                    name="SHARE"
                    icon="shareBlue"
                    // onClick={() => setIsActiveComment(true)}
                  />
                  <FlaggedActionButtonStyle
                    createdBy={feed.audit.created_by}
                    objectId={feed.feed_id}
                    objectType="PLACE_FEED"
                    parentId={feed.place_id}
                    name="FLAG"
                    icon="flagGrey"
                    dropdownHeading="Flag this Feed"
                    suggestions={[
                      { key: 'REPORT_FEED', name: 'Report this feed' },
                      { key: 'INAPPROPRIATE_FEED', name: 'Inappropriate feed' },
                      { key: 'OTHER_FEED_REPORT', name: 'Other' },
                    ]}
                  />
                </FlexRows>
              </IconActionButtonWrapper>
            </div>
          </div>
        </Content>
      ))}
    </Container>
  );
};

export default LatestFeeds;
