import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { forEach, first, upperCase, truncate } from 'lodash';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';
import client from '../../utils/apolloClient';

import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';
import { UploadImage, Text, Button, Pager } from '../../components/elements';
import ErrorMessage from '../../components/global/ErrorMessage';
import Loading from '../../components/global/Loading';
import config from '../../utils/config';

const Wrapper = styled(Pager)`
  &&& {
    padding-top: 2rem;
  }
`;

const MobileWrapper = styled.div`
  padding: 1rem;
`;

const ModalFooter = styled.a`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem;
  position: fixed;
  bottom: 0;
`;

const handleImageSubmissionWhileUploading = image => {
  let isDisabled = false;
  image.forEach(element => {
    if (element.uploadingStatus === 'uploading') {
      isDisabled = true;
    }
  });
  return isDisabled;
};

const Form = props => {
  const { values, setFieldValue, handleSubmit, item, loading, setItemName } = props;
  useEffect(() => {
    setItemName(item.name);
  }, [item.name, setItemName]);

  return (
    <>
      <div className="is-hidden-desktop">
        <MobileWrapper>
          <Text size="small" weight="semibold" paddingBottom={2}>
            Upload upto 4 photos
          </Text>
          <div className="columns is-multiline is-mobile">
            <UploadImage
              totalNumberOfUploadedImage={0}
              colWidth={6}
              s3UploadPath="place/item/"
              metaData={{
                item_id: item.place_id,
                user_id: config.userId,
              }}
              setFieldValue={setFieldValue}
            />
          </div>
          <ModalFooter
            onClick={() =>
              values.image.length === 1
                ? toast.error('Please Upload at least one images')
                : handleSubmit()
            }
          >
            {handleImageSubmissionWhileUploading(values.image) ? (
              <Text
                className="is-uppercase"
                color="white"
                align="center"
                weight="bold"
                size="lmedium"
              >
                Uploading...
              </Text>
            ) : (
              <Text
                className="is-uppercase"
                color="white"
                align="center"
                weight="bold"
                size="lmedium"
              >
                {loading ? 'Loading...' : 'Submit'}
              </Text>
            )}
          </ModalFooter>
        </MobileWrapper>
      </div>
      <div className="is-hidden-touch">
        <Wrapper>
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <Text size="big" weight="semibold" paddingBottom={2}>
                  Add Photos in `{item.name}`
                </Text>
              </div>
            </div>

            <div className="level-right">
              <Button
                className={loading && 'is-loading'}
                name="Post Images"
                backgroundColor="primaryColor"
                textProps={{ color: loading ? 'primaryColor' : 'white', weight: 'semibold' }}
                onClick={() =>
                  values.image.length === 1
                    ? toast.error('Please Upload at least one images')
                    : handleSubmit()
                }
                disabled={handleImageSubmissionWhileUploading(values.image)}
              />
            </div>
          </nav>

          <div className="columns is-multiline">
            <UploadImage
              totalNumberOfUploadedImage={0}
              colWidth={3}
              s3UploadPath="place/item/"
              metaData={{
                item_id: item.place_id,
                user_id: config.userId,
              }}
              setFieldValue={setFieldValue}
            />
          </div>
        </Wrapper>
      </div>
    </>
  );
};

const PageForm = withFormik({
  mapPropsToValues: () => ({
    image: [
      {
        url: '',
        preview: null,
        tooltip: '',
        link: '',
        tag: [],
        uploadingStatus: 'pending',
      },
    ],
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'PostForm',
})(Form);

const fetchItemQuery = gql`
  query fetchItem($input: NavigateItemInput) {
    fetch_item(input: $input) {
      place_id
      item_id
      name
    }
  }
`;

const createImageMutation = gql`
  mutation createImage($input: ImageInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
  }
`;

const parseImage = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        image_id: item.id,
        url: item.url,
        link: item.link,
        tags: item.tags,
        tooltip: item.tooltip,
        width: item.width,
        height: item.height,
      });
    }
  });
  return imageValues;
};

const AddItemPhotos = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const [itemName, setItemName] = useState('');
  return (
    <Layout
      history={history}
      match={match}
      bottomNavigationEnable={false}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading={truncate(upperCase(itemName), { length: 30 })}
    >
      <Analytics pageName="AllBadges" category="allBadge" title="Badge-List">
        <Query
          query={fetchItemQuery}
          variables={{ input: { item_id: match.params.itemId } }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <ErrorMessage />;
            }
            const item = first(data.fetch_item);
            return (
              <Mutation
                client={client.clientPrivate}
                mutation={createImageMutation}
                onCompleted={() => {
                  swal('Great!', 'Thanks for sharing photos!', 'success').then(() => {
                    history.goBack();
                  });
                }}
              >
                {(create_image, { loading: imageLoading }) => (
                  <PageForm
                    setItemName={setItemName}
                    item={item}
                    loading={imageLoading}
                    onSubmit={values => {
                      create_image({
                        variables: {
                          input: {
                            user_id: userId,
                            object_id: item.item_id,
                            object_type: 'ITEM',
                            parent_id: item.place_id,
                            parent_type: 'PLACE',
                            upload_type: 'USER',
                            images: parseImage(values.image),
                          },
                        },
                      });
                    }}
                  />
                )}
              </Mutation>
            );
          }}
        </Query>
      </Analytics>
    </Layout>
  );
};

export default AddItemPhotos;
