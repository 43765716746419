import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import { first, isNull, omit } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState, useStoreActions } from 'easy-peasy';
import moment from 'moment';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Loading from '../../components/global/Loading';
import client from '../../utils/apolloClient';
import { getZoneTime } from '../../utils/eventHelpers';

const allEventQuery = gql`
  query allEventQuery($eventId: String) {
    fetch_event(input: { event_id: $eventId }) {
      place_id
      event_id
      status
      listing_type
      start
      end
      place_name
      venue_name
      name
      description
      tagline
      tag
      slug
      voucher_count
      minimum_price
      maximum_price
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      timezone
      fee {
        name
        value
        service_type
        applies_to
        sales_method
      }
      default_image_url
    }

    fetch_event_occurrence(input: { event_id: $eventId }) {
      event_id
      event_occurrence_id
      start
      end
      sold_tickets
      total_tickets
      remaining_tickets
      display_remaining_ticket
    }
  }
`;

const eventTicketsQuery = gql`
  query eventTicketsQuery($eventOccurrenceId: String, $voucherFilter: Filter) {
    fetch_event_occurrence_ticket(input: { event_occurrence_id: $eventOccurrenceId }) {
      event_occurrence_id
      ticket_id
      status
      sales_method
      name
      type
      description
      quantity
      sale_start
      sale_end
      minimum
      maximum
      sold
      price
      fee {
        name
        value
        service_type
        applies_to
        sales_method
      }
      attribute {
        name
        label
        required
        values
        validation
        display_order
      }
    }
    search_place_event_voucher(input: { filter: $voucherFilter }) {
      place_event_voucher_listing {
        deal_id
        name
        tagline
        description
        voucher_code
      }
    }
  }
`;

const createBookingMutation = gql`
  mutation createBooking($input: EventBookingInput) {
    create_event_booking(input: $input) {
      place_id
      event_id
      booking_id
      booking_number
      quantity
      sub_total_price
      total_price
      total_fee
      booking_fee
      transaction_fee
      discount
      url
      ticket_record {
        event_id
        ticket_name
        ticket_number
        ticket_status
        ticket_type
        price
        fee {
          name
          value
        }
        attendee {
          attendee_name
          attendee_email
          attendee_age
          attendee_gender
          attendee_phone
        }
      }
      error {
        code
        description
      }
    }
  }
`;

const PreventReRender = ({ history, match }) => {
  const currentDate = moment();
  return <EventOccurrence history={history} match={match} currentDate={currentDate} />;
};

const EventOccurrence = ({ history, match, currentDate }) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const isLoggedIn = useStoreState(state => state.auth.isLoggedIn);
  const { userId } = useStoreState(state => state.auth);
  const [subTotalPrice, setSubTotalPrice] = useState(0.0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [ticketSelections, setTicketSelections] = useState([]);
  const [bookingFee, setBookingFee] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [couponCode, setCouponCode] = useState('');
  const [bookingFeeOfEvent, setBookingFeeOfEvent] = useState(0);
  const [
    checkSelectionHaveAttendeeDetailsOrNot,
    setCheckSelectionHaveAttendeeDetailsOrNot,
  ] = useState(false);

  return (
    <Layout history={history} match={match} bottomNavigationEnable={false} service="events">
      <Query
        query={allEventQuery}
        variables={{
          eventId: match.params.eventId,
        }}
        fetchPolicy="network-only"
      >
        {({ data: allEventData, loading: queryLoading, error: queryError }) => {
          if (queryError) {
            return 'error';
          }
          if (queryLoading) {
            return <Loading />;
          }
          const { fetch_event, fetch_event_occurrence } = allEventData;

          return (
            <Query
              query={eventTicketsQuery}
              variables={{
                eventOccurrenceId:
                  fetch_event_occurrence.length !== 0
                    ? first(fetch_event_occurrence).event_occurrence_id
                    : '',
                voucherFilter: {
                  place_event_voucher_filter: {
                    place_id: fetch_event.place_id,
                    event_id: fetch_event.event_id,
                    status: 'ACTIVE',
                    end_date_range: {
                      start_date: getZoneTime(
                        currentDate,
                        fetch_event.latitude,
                        fetch_event.longitude,
                      ),
                    },
                  },
                },
              }}
              fetchPolicy="network-only"
            >
              {({ data: ticketsData, loading: ticketsLoading, error: ticketsQueryError }) => {
                if (ticketsQueryError) {
                  return 'error';
                }
                return (
                  <Mutation
                    client={client.clientPrivate}
                    mutation={createBookingMutation}
                    onCompleted={data => {
                      if (isNull(data.create_event_booking)) {
                        toast.error('Something went wrong');
                      } else if (!isNull(data.create_event_booking.error)) {
                        data.create_event_booking.error.map(item => toast.error(item.description));
                      } else {
                        // console.log('data.create_event_booking', data.create_event_booking);
                        history.push(
                          // eslint-disable-next-line max-len
                          `/event-booking-details/${data.create_event_booking.event_id}/${data.create_event_booking.booking_id}`,
                          { booking: data.create_event_booking },
                        );
                      }
                    }}
                  >
                    {(create_event_booking, { loading: bookingLoading }) => (
                      <>
                        <div className="is-hidden-desktop">
                          <MobileView
                            eventData={{ ...allEventData, ...ticketsData }}
                            loading={queryLoading || ticketsLoading}
                            bookingFeeOfEvent={bookingFeeOfEvent}
                            setBookingFeeOfEvent={setBookingFeeOfEvent}
                            subTotalPrice={subTotalPrice}
                            setSubTotalPrice={setSubTotalPrice}
                            totalQuantity={totalQuantity}
                            setTotalQuantity={setTotalQuantity}
                            ticketSelections={ticketSelections}
                            setTicketSelections={setTicketSelections}
                            bookingFee={bookingFee}
                            setBookingFee={setBookingFee}
                            discount={discount}
                            setDiscount={setDiscount}
                            setCouponCode={setCouponCode}
                            checkSelectionHaveAttendeeDetailsOrNot={
                              checkSelectionHaveAttendeeDetailsOrNot
                            }
                            setCheckSelectionHaveAttendeeDetailsOrNot={
                              setCheckSelectionHaveAttendeeDetailsOrNot
                            }
                            bookingLoading={bookingLoading}
                            finalSubmission={input => {
                              if (!isLoggedIn) {
                                setIsActiveModal({ value: true, path: '' });
                              } else {
                                Object.assign(
                                  input,
                                  couponCode !== '' && { voucher_code: [couponCode] },
                                );
                                const finalInput = omit(input, ['user_id']);
                                create_event_booking({
                                  variables: { input: { user_id: userId, ...finalInput } },
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="is-hidden-touch">
                          <DesktopView
                            userId={userId}
                            eventData={{ ...allEventData, ...ticketsData }}
                            loading={queryLoading || ticketsLoading}
                            bookingFeeOfEvent={bookingFeeOfEvent}
                            setBookingFeeOfEvent={setBookingFeeOfEvent}
                            subTotalPrice={subTotalPrice}
                            setSubTotalPrice={setSubTotalPrice}
                            totalQuantity={totalQuantity}
                            setTotalQuantity={setTotalQuantity}
                            ticketSelections={ticketSelections}
                            setTicketSelections={setTicketSelections}
                            bookingFee={bookingFee}
                            setBookingFee={setBookingFee}
                            discount={discount}
                            setDiscount={setDiscount}
                            setCouponCode={setCouponCode}
                            checkSelectionHaveAttendeeDetailsOrNot={
                              checkSelectionHaveAttendeeDetailsOrNot
                            }
                            setCheckSelectionHaveAttendeeDetailsOrNot={
                              setCheckSelectionHaveAttendeeDetailsOrNot
                            }
                            bookingLoading={bookingLoading}
                            finalSubmission={input => {
                              // console.log(' fwejhvfhgwe', input, couponCode);
                              Object.assign(
                                input,
                                couponCode !== '' && { voucher_code: [couponCode] },
                              );
                              const finalInput = omit(input, ['user_id']);
                              create_event_booking({
                                variables: { input: { user_id: userId, ...finalInput } },
                              });
                            }}
                          />
                        </div>
                      </>
                    )}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};

export default PreventReRender;
