import React from 'react';
import styled from 'styled-components';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';
import { Pager } from '../../../components/elements';
import MenuLoader from './MenuLoader';
import SectionLoader from './SectionLoader';

const Container = styled.div`
  margin-top: 2rem;
`;

const ParentComponent = () => {
  return (
    <>
      <RectShape color="#f0f0f0" style={{ width: '100%', height: '20rem' }} />
      <Pager>
        <Container>
          <RectShape color="#f0f0f0" style={{ width: '100%', height: '8rem' }} />
        </Container>
      </Pager>
      <MenuLoader />
      <SectionLoader />
    </>
  );
};

const ParentLoader = () => {
  return (
    <ReactPlaceholder ready showLoadingAnimation>
      <ParentComponent />
    </ReactPlaceholder>
  );
};

export default ParentLoader;
