import React from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
// import Icon from './Icon';

// const Arrow = ({ name }) => <Icon name={name} size={8} />;

// const ArrowLeft = Arrow({ name: 'discoverEvent' });
// const ArrowRight = Arrow({ name: 'discoverEvent' });

const ScrollView = ({ menu, onSelect, ...props }) => {
  const [selected, setSelect] = React.useState('');
  const handleSelect = key => {
    setSelect(key);
    onSelect(key);
  };

  return (
    <ScrollMenu
      data={menu}
      // arrowLeft={ArrowLeft}
      // arrowRight={ArrowRight}
      selected={selected}
      onSelect={handleSelect}
      alignCenter={false}
      itemStyle={{ outline: 0 }}
      {...props}
    />
  );
};

export default ScrollView;
