import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { Icon } from '../../../../components/elements';
import GetImages from '../../../../components/GetImages';

const Container = styled.div`
  padding: 0.5rem 0rem;
`;

const SearchInput = styled.div`
  padding-top: 5px;
  input {
    border-radius: 25px;
  }
`;

const PlaceReviewActionButton = ({ placeId, history, placeName }) => {
  const { isLoggedIn, user, userImage } = useStoreState(state => state.auth);
  return (
    <Container>
      <article className="media">
        <figure className="media-left">
          {isLoggedIn ? (
            <GetImages
              name={user && user.display_name}
              maskProps={{ width: 40, height: 40 }}
              maskTextProps={{
                color: 'white',
                weight: 'bold',
                size: 'small',
                letterSpacing: 'loose',
              }}
              borderRadius={25}
              randomColorCode={user.color_code}
              userImage={userImage}
            />
          ) : (
            <Icon name="myProfileBlue" size={3} />
          )}
        </figure>
        <div className="media-content">
          <div className="content">
            <SearchInput>
              <input
                className="input"
                placeholder={`Share your experience at ${placeName}..`}
                onFocus={() => history.push(`/place-review/${placeId}`)}
              />
            </SearchInput>
          </div>
        </div>
      </article>
    </Container>
  );
};
export default withRouter(PlaceReviewActionButton);
