import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MdClear, MdDone } from 'react-icons/md';
import { forEach } from 'lodash';

import { Modal, Text, FlexRows } from '../../../../components/elements';
import { getItemTypes } from '../../../../utils/fetchStatic';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
  &&& {
    .column {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const ModalHeaderLeft = styled.div`
  position: absolute;
  left: 0.5rem;
`;
const ModalHeaderRight = styled.div`
  position: absolute;
  right: 0.5rem;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const Flex = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.specialGrey};
`;

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const Item = ({ title, isIconActive, onSelect }) => (
  <Flex onClick={onSelect}>
    <div>
      <Text>{title}</Text>
    </div>
    <div className="is-flex">
      {isIconActive && (
        <Text color="primaryColor">
          <MdDone size={25} />
        </Text>
      )}
    </div>
  </Flex>
);

const ItemTypeModal = ({ isActive, onClose, itemType, onSelect }) => {
  const [types, setType] = useState([]);
  const [itemTypeInput, setItemTypes] = useState(itemType);

  useEffect(() => {
    const getData = async () => {
      const itemTypes = await getItemTypes();
      setType(parseData(itemTypes));
    };
    getData();
  }, []);

  const onAdd = value => {
    setItemTypes(itemTypeInput.concat(value));
  };

  const onRemove = key => {
    setItemTypes(itemTypeInput.filter(edge => edge.name !== key));
  };

  return (
    <Modal isActive={isActive}>
      <ModalHeader>
        <ModalHeaderLeft>
          <a onClick={onClose}>
            <Text color="white">
              <MdClear size={25} />
            </Text>
          </a>
        </ModalHeaderLeft>
        <ModalHeaderRight>
          <a onClick={() => onSelect(itemTypeInput)}>
            <Text className="is-uppercase" color="white" weight="semibold" size="small">
              Done
            </Text>
          </a>
        </ModalHeaderRight>
        <FlexRows>
          <Text className="is-uppercase" align="center" color="white" weight="bold" size="lmedium">
            Cuisines
          </Text>
        </FlexRows>
      </ModalHeader>
      <Content>
        {types.map(type => {
          const isIconActiveArr = itemTypeInput.filter(edge => edge.name === type.value);
          const isIconActive = isIconActiveArr.length !== 0;
          return (
            <Item
              key={type.value}
              title={type.label}
              isIconActive={isIconActive}
              onSelect={() =>
                isIconActive
                  ? onRemove(type.value)
                  : onAdd({ name: type.value, description: type.label })
              }
            />
          );
        })}
      </Content>
    </Modal>
  );
};

export default ItemTypeModal;
