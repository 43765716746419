import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { first } from 'lodash';

import { Text } from '../../../../components/elements';
import BookingButton from './BookingButton';
import { getZoneTime } from '../../../../utils/eventHelpers';

const Container = styled.div`
  margin: 0;
`;

const Header = styled.div`
  padding: 0.5rem 0rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  &&& {
    padding: 0.5rem 0rem;
  }
`;

const EventDateAndTime = ({ event, totalNumberOfTicket }) => {
  const [eventStartDate, setEventStartDate] = useState(event.start);
  useEffect(() => {
    const fetData = async () => {
      const startDate = await getZoneTime(event.start, event.latitude, event.longitude);
      setEventStartDate(startDate);
    };
    fetData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <Header>
        <Text size="lmedium" weight="semibold">
          Event Date and Time
        </Text>
      </Header>
      <Wrapper className="columns is-vcentered">
        <div className="column">
          <Text size="small" weight="semibold" color="dark" paddingBottom={0.2}>
            {moment(eventStartDate).format('LLLL')}
          </Text>
        </div>
        <div className="column">
          {first(event.listing_type) === 'public_tickets' && (
            <BookingButton event={event} disabled={totalNumberOfTicket === 0} />
          )}
        </div>
      </Wrapper>
    </Container>
  );
};

export default EventDateAndTime;
