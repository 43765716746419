import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
// import moment from 'moment';
import { isNull } from 'lodash';
import { useStoreState } from 'easy-peasy';

import ReviewItem from './ReviewItem';
import Loading from './Loading';
import Empty from './Empty';

const searchPlaceReviewQuery = gql`
  query searchPlaceReview($input: SearchInput) {
    search_place_review(input: $input) {
      place_review_listing {
        place_id
        place_review_id
        month_visited
        overall_rating
        service_category {
          name
          description
        }
        content
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 0rem 1rem;
`;

const Reviews = () => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Container>
      <Query
        query={searchPlaceReviewQuery}
        variables={{
          input: {
            sort: 'CREATED_DESC',
            filter: {
              place_review_filter: {
                user_id: userId,
              },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading }) => {
          if (loading) {
            return <Loading />;
          }
          if (!data) {
            return <Empty isError />;
          }
          if (isNull(data.search_place_review)) {
            return <Empty isError />;
          }
          if (data.search_place_review.place_review_listing.length === 0) {
            return <Empty />;
          }

          const reviews = data.search_place_review.place_review_listing;
          return <View reviews={reviews} />;
        }}
      </Query>
    </Container>
  );
};

const View = ({ reviews }) => {
  if (reviews.length === 0) {
    return null;
  }

  return (
    <>
      {reviews.map(review => (
        <ReviewItem review={review} key={review.place_review_id} />
      ))}
    </>
  );
};

export default Reviews;
