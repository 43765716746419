import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InputStyle = styled.input`
  && {
    border-radius: 4px;
  }
`;

const InputLiteStyle = styled.input`
  &&& {
    border: 0;
    box-shadow: 0 0 0 0;
    padding-left: 0;
    .input :focus :active {
      border: 0;
      box-shadow: 0 0 0 0;
    }
  }
`;

const Input = ({ className, lite, ...props }) => {
  if (lite) {
    return <InputLiteStyle className={`input ${className}`} {...props} />;
  }
  return <InputStyle className={`input ${className}`} {...props} />;
};

Input.defaultProps = {
  className: '',
  lite: false,
};

Input.propTypes = {
  className: PropTypes.string,
  lite: PropTypes.bool,
};

export default Input;
