import React from 'react';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../../components/global/Layout';
import Analytics from '../../../components/global/Analytics';

const MyActivity = ({ history, match }) => (
  <Layout
    history={history}
    match={match}
    isEnableMobileSecondaryHeader
    secondaryHeaderHeading="PAYMENT DETAILS"
  >
    <Analytics pageName="ProfilePayment" category="profilepayment" title="Profile-Payment">
      <div className="is-hidden-desktop">
        <MobileView />
      </div>
      <div className="is-hidden-touch">
        <DesktopView />
      </div>
    </Analytics>
  </Layout>
);

export default MyActivity;
