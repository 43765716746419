import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MdClear } from 'react-icons/md';
import { FaApple, FaGoogle, FaWindows } from 'react-icons/fa';
import { kebabCase } from 'lodash';

import { Modal, Text, FlexRows } from '../../../../components/elements';
import addToCalendar from '../../../../utils/addToCalendar';
import { getZoneTime } from '../../../../utils/eventHelpers';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const ModalHeaderLeft = styled.div`
  position: absolute;
  left: 0.5rem;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.specialGrey};
`;

const AddToCalenderModal = ({ isActive, onCloseModal, event }) => {
  const [eventStartDate, setEventStartDate] = useState(event.start);
  const [eventEndDate, setEventEndDate] = useState(event.end);

  useEffect(() => {
    const fetData = async () => {
      const startDate = await getZoneTime(event.start, event.latitude, event.longitude);
      const endDate = await getZoneTime(event.end, event.latitude, event.longitude);
      setEventStartDate(startDate);
      setEventEndDate(endDate);
    };
    fetData();
  }, [event]);

  const eventData = {
    startTime: eventStartDate,
    endTime: eventEndDate,
    title: event.name,
    description: event.description,
    location: `${event.address_line_1}, ${event.city} ${event.state}, ${event.country}`,
  };

  const handleCalender = value => {
    window.open(addToCalendar.buildUrl(eventData, value));
  };
  return (
    <Modal isActive={isActive}>
      <ModalHeader>
        <ModalHeaderLeft>
          <a onClick={() => onCloseModal()}>
            <Text color="white">
              <MdClear size={25} />
            </Text>
          </a>
        </ModalHeaderLeft>
        <FlexRows>
          <Text className="is-uppercase" align="center" color="white" weight="bold" size="lmedium">
            Add To Calender
          </Text>
        </FlexRows>
      </ModalHeader>
      <Content>
        <a
          href={addToCalendar.buildUrl(eventData, 'apple')}
          download={`${kebabCase(event.name)}-calender.ics`}
        >
          <Flex>
            <Text className="icon" color="darkGrey">
              <FaApple size={22} />
            </Text>
            &nbsp;
            <Text size="small" weight="semibold" color="darkGrey">
              Apple Calendar
            </Text>
          </Flex>
        </a>
        <a onClick={() => handleCalender('google')}>
          <Flex>
            <Text className="icon" color="darkGrey">
              <FaGoogle size={20} />
            </Text>
            &nbsp;
            <Text size="small" weight="semibold" color="darkGrey">
              Google (online)
            </Text>
          </Flex>
        </a>
        <a
          href={addToCalendar.buildUrl(eventData, 'apple')}
          download={`${kebabCase(event.name)}-calender.ics`}
        >
          <Flex>
            <Text className="icon" color="darkGrey">
              <FaWindows size={20} />
            </Text>
            &nbsp;
            <Text size="small" weight="semibold" color="darkGrey">
              Outlook
            </Text>
          </Flex>
        </a>
        <a onClick={() => handleCalender('outlookcom')}>
          <Flex>
            <Text className="icon" color="darkGrey">
              <FaWindows size={20} />
            </Text>
            &nbsp;
            <Text size="small" weight="semibold" color="darkGrey">
              Outlook.com (online)
            </Text>
          </Flex>
        </a>
      </Content>
    </Modal>
  );
};

export default AddToCalenderModal;
