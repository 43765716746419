import React from 'react';
import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';
import { Text, Icon } from '../../elements';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  transition: flex-grow 500ms ease-in-out;
  position: relative;
  cursor: pointer;
  && {
    p {
      padding-left: 1rem;
    }
  }
`;

const Popup = styled.div`
  position: absolute;
  top: 3rem;
  width: 14rem;
  background: #fafafa;
  border-radius: 4px;
  z-index: 202;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
`;

const Link = styled(RLink)`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 0.8rem 1rem;
  :hover {
    border-radius: 4px;
    background: #f0f0f0;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-left: 1rem;
`;

const TextStyle = styled(Text).attrs({ color: 'darkGrey', weight: 'semibold', size: 'small' })``;

const AddPlace = () => {
  return (
    <>
      <Container>
        <Icon name="addPlaceBlack" size={2} />
        <Text color="white" size="small" weight="semibold">
          Add a Place
        </Text>
        <Popup>
          <Link to="/add-place">
            <Icon name="addAPlaceGrey" size={1.5} />
            <TextWrapper>
              <TextStyle> Add a Place</TextStyle>
            </TextWrapper>
          </Link>
          <Link to="/search-place-for-claim">
            <Icon name="claimYourPlacesGrey" size={1.5} />
            <TextWrapper>
              <TextStyle>Claim a Place</TextStyle>
            </TextWrapper>
          </Link>
        </Popup>
      </Container>
    </>
  );
};

export default AddPlace;
