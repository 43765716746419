import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isUndefined } from 'lodash';
import { useStoreState } from 'easy-peasy';
import InfiniteScroll from 'react-infinite-scroll-component';

import Filter from './components/mobile/Filter';
import FilterModal from './components/mobile/FilterModal';
import SortByModal from './components/mobile/SortByModal';
import FilterTypeModal from './components/mobile/FilterTypeModal';
import MenuCard from './components/mobile/MenuCard';
import EventCardLoader from '../../components/loader/EventCardLoader';
import CuisineModal from './components/mobile/CuisineModal';
import { MapView, FlexColumns, Text } from '../../components/elements';

const BusinessCardsWrapper = styled.div`
  padding-top: 0rem;
`;

const getMapLocation = dta => {
  const data = dta.map(item => ({
    id: item.place_id,
    title: `${item.name}`,
    subtitle: `${item.address_line_1}, ${item.city}, ${item.state}`,
    imageObject: {
      object_id: item.place_id,
      object_type: ['PLACE'],
      parent_id: item.place_id,
      parent_type: ['PLACE'],
      links_to_id: [item.place_id],
      links_to_type: ['PLACE'],
    },
    latitude: parseFloat(item.latitude),
    longitude: parseFloat(item.longitude),
    type: 'PLACE',
  }));
  return data;
};

const MobileView = ({
  data,
  loading,
  handleMobileFilter,
  filterFuncInputs,
  follows,
  deals,
  fetchMoreInMobile,
}) => {
  const [places, setPlaces] = useState([]);
  const [socials, setSocials] = useState([]);
  const [followsData, setFollowsData] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setSocials([]);
    setPlaces(
      !isUndefined(data.search_food_orders) &&
        !isUndefined(data.search_food_orders.place_and_service_type_listing)
        ? data.search_food_orders.place_and_service_type_listing
        : [],
    );
    setFollowsData(followsData.concat(follows));
    // const totalItemLength = data.search_places.place_listing.length + places.length;
    // if (data.search_places.total_size === totalItemLength) {
    //   setHasMore(false);
    // } else {
    //   setHasMore(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.search_places, data.search_social]);

  const handleInfiniteScroll = () => {
    if (!loading) {
      fetchMoreInMobile();
    }
  };

  const [cuisineInputs, setCuisineInputs] = React.useState([]);
  const [itemTypeInputs, setItemTypeInputs] = React.useState([]);
  const [featureInputs, setFeatureInputs] = React.useState([]);
  const [businessTypeInputs, setBusinessTypeInputs] = React.useState([]);
  const [serviceTypeInputs, setServiceTypeInputs] = React.useState([]);
  const [dressCodeInputs, setDressCodeInputs] = React.useState([]);
  const [publicTransportsInputs, setPublicTransportsInputs] = React.useState([]);
  const [diningStyleInputs, setDiningStyleInputs] = React.useState([]);
  const [ambienceInputs, setAmbienceInputs] = React.useState([]);
  // const { productType, productTypes } = state;
  const [activeFilterModal, setActiveFilterModal] = React.useState(false);
  const [activeSortByModal, setActiveSortByModal] = React.useState(false);
  const [activeCuisinesModal, setActiveCuisinesModal] = useState(false);
  const [activeCuisinesFilterModal, setActiveCuisinesFilterModal] = React.useState(false);
  const [activeBusinessFilterModal, setActiveBusinessFilterModal] = React.useState(false);
  const [activeFeaturesFilterModal, setActiveFeaturesFilterModal] = React.useState(false);
  const [activeItemsFilterModal, setActiveItemsFilterModal] = React.useState(false);
  const [activeServiceTypeFilterModal, setActiveServiceTypeFilterModal] = React.useState(false);
  const [activeDressCodeFilterModal, setActiveDressCodeFilterModal] = React.useState(false);
  const [activeDiningStyleFilterModal, setActiveDiningStyleFilterModal] = React.useState(false);
  const [activePublicTransportsFilterModal, setActivePublicTransportsFilterModal] = React.useState(
    false,
  );
  const [activeAmbienceFilterModal, setActiveAmbienceFilterModal] = React.useState(false);
  const [mapActive, setMapActive] = useState(false);
  const [sortBy, setSortBy] = React.useState('');
  const filterTags = useStoreState(state => state.place.filterTags);

  const handleSortBy = sortByValue => {
    setSortBy(sortByValue);
  };
  const addServiceType = async serviceType => {
    const newServiceType = serviceTypeInputs.concat([serviceType]);
    await setServiceTypeInputs(newServiceType);
  };

  const removeServiceType = async serviceType => {
    const newServiceType = serviceTypeInputs.filter(item => item.name !== serviceType);
    await setServiceTypeInputs(newServiceType);
  };
  const addDressCode = async dressCode => {
    const newDressCode = dressCodeInputs.concat([dressCode]);
    await setDressCodeInputs(newDressCode);
  };

  const removeDressCode = async dressCode => {
    const newDressCode = dressCodeInputs.filter(item => item.name !== dressCode);
    await setDressCodeInputs(newDressCode);
  };
  const addDiningStyle = async diningStyle => {
    const newDiningStyle = diningStyleInputs.concat([diningStyle]);
    await setDiningStyleInputs(newDiningStyle);
  };

  const removeDiningStyle = async diningStyle => {
    const newDiningStyle = diningStyleInputs.filter(item => item.name !== diningStyle);
    await setDiningStyleInputs(newDiningStyle);
  };
  const addPublicTransports = async publicTransport => {
    const newPublicTransports = publicTransportsInputs.concat([publicTransport]);
    await setPublicTransportsInputs(newPublicTransports);
  };

  const removePublicTransports = async publicTransport => {
    const newPublicTransports = publicTransportsInputs.filter(
      item => item.name !== publicTransport,
    );
    await setPublicTransportsInputs(newPublicTransports);
  };
  const addAmbience = async ambience => {
    const newAmbience = ambienceInputs.concat([ambience]);
    await setAmbienceInputs(newAmbience);
  };

  const removeAmbience = async ambience => {
    const newAmbience = ambienceInputs.filter(item => item.name !== ambience);
    await setAmbienceInputs(newAmbience);
  };
  const addCuisine = async cuisine => {
    const newCuisine = cuisineInputs.concat([cuisine]);
    await setCuisineInputs(newCuisine);
  };

  const removeCuisine = async cuisine => {
    const newCuisine = cuisineInputs.filter(item => item.name !== cuisine);
    await setCuisineInputs(newCuisine);
  };

  const addBusinessType = async type => {
    const newBusinessTypeInputs = businessTypeInputs.concat([type]);
    await setBusinessTypeInputs(newBusinessTypeInputs);
  };

  const removeBusinessType = async name => {
    const newBusinessTypeInputs = businessTypeInputs.filter(item => item.name !== name);
    await setBusinessTypeInputs(newBusinessTypeInputs);
  };

  const addItemType = async feature => {
    const newItemTypeInputs = itemTypeInputs.concat([feature]);
    setItemTypeInputs(newItemTypeInputs);
  };

  const removeItemType = async itemType => {
    const newItemTypeInputs = itemTypeInputs.filter(item => item.name !== itemType);
    setItemTypeInputs(newItemTypeInputs);
  };

  const addFeature = async feature => {
    const newFeatureInputs = featureInputs.concat([feature]);
    setFeatureInputs(newFeatureInputs);
  };

  const removeFeature = async feature => {
    const newFeatureInputs = featureInputs.filter(item => item.name !== feature);
    setFeatureInputs(newFeatureInputs);
  };

  const setToMainFilter = clear => {
    const finalFilter = {
      cuisineInputs,
      itemTypeInputs,
      featureInputs,
      businessTypeInputs,
      serviceTypeInputs,
      dressCodeInputs,
      diningStyleInputs,
      publicTransportsInputs,
      ambienceInputs,
    };
    handleMobileFilter(finalFilter);
    setPlaces([]);
    if (!clear) {
      setActiveFilterModal(false);
      setActiveSortByModal(false);
    }
  };

  const clearHelper = () => {
    setCuisineInputs([]);
    setItemTypeInputs([]);
    setFeatureInputs([]);
    setBusinessTypeInputs([]);
    setServiceTypeInputs([]);
    setDressCodeInputs([]);
    setPublicTransportsInputs([]);
    setDiningStyleInputs([]);
    setAmbienceInputs([]);
  };

  const clearFilter = async () => {
    await clearHelper();
    setToMainFilter(true);
  };

  return (
    <>
      <Filter
        setActiveFilterModal={setActiveFilterModal}
        handleProductType={value => {
          setPlaces([]);
          filterFuncInputs.handleMenuCategory(value);
          setHasMore(false);
        }}
        setActiveSortByModal={setActiveSortByModal}
        setMapActive={setMapActive}
        activeMap={mapActive}
        setActiveCuisinesModal={setActiveCuisinesModal}
      />

      <BusinessCardsWrapper>
        {mapActive ? (
          <MapView data={getMapLocation(places)} />
        ) : (
          <>
            {!activeFilterModal && (
              <>
                {places.length === 0 && !loading && (
                  <FlexColumns style={{ padding: '2rem' }}>
                    <Text size="small" color="darkGrey" weight="semibold">
                      No Item found
                    </Text>
                  </FlexColumns>
                )}
                {places.length === 0 && loading && <EventCardLoader />}
                <InfiniteScroll
                  dataLength={places.length}
                  next={handleInfiniteScroll}
                  hasMore={hasMore}
                  loader={<EventCardLoader />}
                >
                  {places.map(({ place }) => (
                    <MenuCard
                      key={place.place_id}
                      place={place}
                      socials={socials.filter(item => item.object_id === place.place_id)}
                      follow={followsData.filter(item => item.object_id === place.place_id)}
                      deal={deals.filter(item => item.name === place.place_id)}
                    />
                  ))}
                </InfiniteScroll>
              </>
            )}
          </>
        )}
      </BusinessCardsWrapper>
      <CuisineModal
        isActive={activeCuisinesModal}
        onCloseModal={() => setActiveCuisinesModal(false)}
        typeInputs={cuisineInputs}
        addType={addCuisine}
        removeType={removeCuisine}
        done={() => {
          setToMainFilter();
          setActiveCuisinesModal(false);
        }}
      />
      <FilterModal
        isActive={activeFilterModal}
        cuisineInput={cuisineInputs}
        businessTypeInputs={businessTypeInputs}
        featureInputs={featureInputs}
        itemTypeInputs={itemTypeInputs}
        serviceTypeInputs={serviceTypeInputs}
        diningStyleInputs={diningStyleInputs}
        publicTransportsInputs={publicTransportsInputs}
        ambienceInputs={ambienceInputs}
        dressCodeInputs={dressCodeInputs}
        setActiveFilterModal={setActiveFilterModal}
        setActiveCuisinesFilterModal={setActiveCuisinesFilterModal}
        setActiveBusinessFilterModal={setActiveBusinessFilterModal}
        setActiveFeaturesFilterModal={setActiveFeaturesFilterModal}
        setActiveItemsFilterModal={setActiveItemsFilterModal}
        setActiveServiceTypeFilterModal={setActiveServiceTypeFilterModal}
        setActiveDressCodeFilterModal={setActiveDressCodeFilterModal}
        setActiveDiningStyleFilterModal={setActiveDiningStyleFilterModal}
        setActivePublicTransportsFilterModal={setActivePublicTransportsFilterModal}
        setActiveAmbienceFilterModal={setActiveAmbienceFilterModal}
        setToMainFilter={setToMainFilter}
        filterTags={filterTags}
        clearFilter={clearFilter}
      />

      <FilterTypeModal
        headerTitle="Cuisines"
        headerRightButtonText="Done"
        headerLeftButtonText
        typeDisplayObjectName="description"
        isActive={activeCuisinesFilterModal}
        typeInputs={cuisineInputs}
        types={filterTags.cuisines}
        onCloseModal={setActiveCuisinesFilterModal}
        addType={addCuisine}
        removeType={removeCuisine}
      />
      <FilterTypeModal
        headerTitle="Business Type"
        headerRightButtonText="Done"
        headerLeftButtonText
        typeDisplayObjectName="description"
        isActive={activeBusinessFilterModal}
        typeInputs={businessTypeInputs}
        types={filterTags.businessTypes}
        onCloseModal={setActiveBusinessFilterModal}
        addType={addBusinessType}
        removeType={removeBusinessType}
      />
      <FilterTypeModal
        headerTitle="Features"
        headerRightButtonText="Done"
        headerLeftButtonText
        typeDisplayObjectName="description"
        isActive={activeFeaturesFilterModal}
        typeInputs={featureInputs}
        types={filterTags.features}
        onCloseModal={setActiveFeaturesFilterModal}
        addType={addFeature}
        removeType={removeFeature}
      />
      <FilterTypeModal
        headerTitle="Item Types"
        headerRightButtonText="Done"
        headerLeftButtonText
        typeDisplayObjectName="description"
        isActive={activeItemsFilterModal}
        typeInputs={itemTypeInputs}
        types={filterTags.itemTypes}
        onCloseModal={setActiveItemsFilterModal}
        addType={addItemType}
        removeType={removeItemType}
      />
      <FilterTypeModal
        headerTitle="Service Types"
        headerRightButtonText="Done"
        headerLeftButtonText
        typeDisplayObjectName="description"
        isActive={activeServiceTypeFilterModal}
        typeInputs={serviceTypeInputs}
        types={filterTags.serviceType}
        onCloseModal={setActiveServiceTypeFilterModal}
        addType={addServiceType}
        removeType={removeServiceType}
      />
      <FilterTypeModal
        headerTitle="Dress Code"
        headerRightButtonText="Done"
        headerLeftButtonText
        typeDisplayObjectName="description"
        isActive={activeDressCodeFilterModal}
        typeInputs={dressCodeInputs}
        types={filterTags.dressCode}
        onCloseModal={setActiveDressCodeFilterModal}
        addType={addDressCode}
        removeType={removeDressCode}
      />
      <FilterTypeModal
        headerTitle="Dining Style"
        headerRightButtonText="Done"
        headerLeftButtonText
        typeDisplayObjectName="description"
        isActive={activeDiningStyleFilterModal}
        typeInputs={diningStyleInputs}
        types={filterTags.diningStyle}
        onCloseModal={setActiveDiningStyleFilterModal}
        addType={addDiningStyle}
        removeType={removeDiningStyle}
      />
      <FilterTypeModal
        headerTitle="Public Transport"
        headerRightButtonText="Done"
        headerLeftButtonText
        typeDisplayObjectName="description"
        isActive={activePublicTransportsFilterModal}
        typeInputs={publicTransportsInputs}
        types={filterTags.publicTransports}
        onCloseModal={setActivePublicTransportsFilterModal}
        addType={addPublicTransports}
        removeType={removePublicTransports}
      />
      <FilterTypeModal
        headerTitle="Ambience"
        headerRightButtonText="Done"
        headerLeftButtonText
        typeDisplayObjectName="description"
        isActive={activeAmbienceFilterModal}
        typeInputs={ambienceInputs}
        types={filterTags.ambience}
        onCloseModal={setActiveAmbienceFilterModal}
        addType={addAmbience}
        removeType={removeAmbience}
      />
      <SortByModal
        isActive={activeSortByModal}
        sortBy={sortBy}
        onCloseModal={setActiveSortByModal}
        handleSortBy={handleSortBy}
        setToMainFilter={setToMainFilter}
      />
    </>
  );
};

export default MobileView;
