import React from 'react';
import styled from 'styled-components';
import { FlexRows } from '../../../../components/elements';
import SocialActionButton from '../../../../components/SocialActionButton';
import FollowButtonAction from '../../../../components/FollowButtonAction';

const Container = styled.div`
  background: ${({ theme: { colors } }) => colors.white};
  padding-top: 1rem;
`;

const SocialActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'micro', weight: 'semibold', color: 'dark' },
  paddingLess: true,
  iconStyle: { size: 1.2 },
  vertical: true,
  showCount: false,
})``;

const BeenSocialActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'micro', weight: 'semibold', color: 'dark' },
  paddingLess: true,
  iconStyle: { size: 1.2 },
  vertical: true,
  showCount: false,
})``;

const FollowButtonActionStyle = styled(FollowButtonAction).attrs({
  textStyle: { size: 'micro', weight: 'semibold', color: 'dark' },
  paddingLess: true,
  iconStyle: { size: 1.2 },
  vertical: true,
})``;

const ActionButtons = ({ place }) => (
  <Container>
    <FlexRows justify="space-between">
      <FollowButtonActionStyle
        objectId={place.place_id}
        objectType="PLACE"
        type="FOLLOW"
        defaultIcon="follow"
        activeIcon="following"
        isIconButton
      />
      <BeenSocialActionButtonStyle
        objectId={place.place_id}
        objectType="PLACE"
        type="BEEN_THERE"
        name="BEEN THERE"
        defaultIcon="beenThereGrey"
        activeIcon="beenThereBlue"
      />
      <SocialActionButtonStyle
        objectId={place.place_id}
        objectType="PLACE"
        type="LIKE"
        name="LIKE IT"
        defaultIcon="likeStarGrey"
        activeIcon="likeStarBlue"
      />
      <SocialActionButtonStyle
        objectId={place.place_id}
        objectType="PLACE"
        type="BOOKMARK"
        name="BOOKMARK"
        defaultIcon="bookmarkSolidGrey"
        activeIcon="bookmarkSolid"
      />
    </FlexRows>
  </Container>
);

export default ActionButtons;
