import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { forEach, omit } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';

import {
  FlexItem,
  FlexRows,
  Text,
  FlexColumns,
  TextArea,
  InputErrorMessage,
} from '../../components/elements';
import Select from '../../components/elements/Select';
import MobileImage from './MobileImage';
import RatingInput from './RatingInput';

const Header = styled(FlexColumns)`
  padding: 1rem;
`;

const Card = styled.div`
  z-index: 50;
  background: ${({ theme: { colors } }) => colors.white};
  margin: 0rem 1rem;
  border-radius: 6px;
`;
const Element = styled(FlexColumns)`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;
const SelectWrapper = styled.div``;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 50;
  width: 100%;
`;

const Save = styled.div`
  padding: 0.5rem 1rem;
  border-right: 1px solid ${({ theme: { colors } }) => colors.white};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
  background: ${({ theme: { colors } }) => colors.primaryColor};
`;
const Post = styled.div`
  padding: 0.5rem 1rem;
  border-left: 1px solid ${({ theme: { colors } }) => colors.white};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
  background: ${({ theme: { colors } }) => colors.primaryColor};
`;

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const handleImageSubmissionWhileUploading = image => {
  let isDisabled = false;
  image.forEach(element => {
    if (element.uploadingStatus === 'uploading') {
      isDisabled = true;
    }
  });
  return isDisabled;
};

const Form = props => {
  const { loading, values, setFieldValue, handleSubmit, touched, errors, place, history } = props;
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn } = useStoreState(state => state.auth);
  const [monthList, setMonthList] = useState([]);

  const handleOnClick = () => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
        callBackFunction: () => handleSubmit(),
      });
    } else {
      handleSubmit();
    }
  };
  const handleOnClickDraft = async () => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
        callBackFunction: () => handleSubmit(),
      });
    } else {
      await setFieldValue('isDraft', true);
      handleSubmit();
    }
  };

  const getLast12Months = async () => {
    const monthName = moment.months();
    const d = new Date();
    // const withoutCurrentMonth = monthName.filter(item => item !== moment().format('MMMM'));
    const listOfMonths = [];
    d.setDate(1);
    await forEach(monthName, () => {
      listOfMonths.push(`${monthName[d.getMonth()]} ${d.getFullYear()}`);
      d.setMonth(d.getMonth() - 1);
    });

    setMonthList(listOfMonths);
  };

  useEffect(() => {
    getLast12Months();
  }, []);
  console.log('values', values);
  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleOnClick();
      }}
    >
      <Header>
        <Text className="is-capitalized" size="medium" weight="bold">
          {place.name}
        </Text>
      </Header>
      <Card>
        <Element>
          <MobileImage values={values} setFieldValue={setFieldValue} />
        </Element>
        <Element>
          <Text color="primaryColor" size="medium" weight="semibold">
            When did you visit this place?
          </Text>
          <SelectWrapper>
            <Select
              lite
              options={monthList}
              value={
                values.month_visited !== '' ? moment(values.month_visited).format('MMMM YYYY') : ''
              }
              onChange={event => {
                console.log('event.target.value', event.target.value);
                setFieldValue('month_visited', moment(event.target.value).toISOString());
              }}
            />
            <InputErrorMessage touched={touched.month_visited} errors={errors.month_visited} />
          </SelectWrapper>
        </Element>
        <Element>
          <Text color="primaryColor" size="medium" weight="semibold">
            Rate this place
          </Text>
          <RatingInput
            iconSize={50}
            initialValue={values.overall_rating}
            handleChange={value => setFieldValue('overall_rating', value)}
          />
          <InputErrorMessage touched={touched.overall_rating} errors={errors.overall_rating} />
        </Element>
        <FlexColumns style={{ padding: '1rem' }}>
          <Text color="primaryColor" size="medium" weight="semibold">
            What do you like about this place?
          </Text>
          <br />
          <TextArea
            value={values.content}
            borderLess
            className="textarea"
            // eslint-disable-next-line max-len
            placeholder="What do you like about this place? Use @ to mention someone or use # to mention a dish"
            onChange={event => setFieldValue('content', event.target.value)}
          />
          <InputErrorMessage touched={touched.content} errors={errors.content} />
        </FlexColumns>
      </Card>

      <Footer>
        <FlexRows>
          <FlexItem grow={1}>
            <a
              onClick={() => {
                if (!loading && !handleImageSubmissionWhileUploading(values.image)) {
                  handleOnClickDraft();
                }
              }}
            >
              <Save isDisabled={handleImageSubmissionWhileUploading(values.image)}>
                <Text
                  className="is-uppercase"
                  color="white"
                  size="medium"
                  weight="bold"
                  align="center"
                >
                  {values.isDraft && loading ? 'Loading...' : 'Save Draft'}
                </Text>
              </Save>
            </a>
          </FlexItem>
          <FlexItem grow={1}>
            <a
              onClick={() => {
                if (!loading && !handleImageSubmissionWhileUploading(values.image)) {
                  handleSubmit();
                }
              }}
            >
              <Post isDisabled={handleImageSubmissionWhileUploading(values.image)}>
                <Text
                  className="is-uppercase"
                  color="white"
                  size="medium"
                  weight="bold"
                  align="center"
                >
                  {!values.isDraft && loading ? 'Loading...' : 'Post Review'}
                </Text>
              </Post>
            </a>
          </FlexItem>
        </FlexRows>
      </Footer>
    </form>
  );
};

const DeskForm = withFormik({
  mapPropsToValues: ({ place, placeReview, uploadedImages }) => ({
    isDraft: false,
    place_id: place ? place.place_id : '',
    content: placeReview ? placeReview.content : '',
    overall_rating: placeReview ? parseFloat(placeReview.overall_rating).toFixed(0) : 0,
    month_visited: placeReview ? placeReview.month_visited : '',
    service_category: placeReview ? removeTypename(placeReview.service_category) : [],
    uploadedImages,
    image: [
      {
        url: '',
        preview: null,
        tooltip: '',
        link: '',
        tag: [],
        uploadingStatus: 'pending',
      },
    ],
  }),

  validationSchema: yup.object().shape({
    content: yup
      .string()
      .min(50, 'At least 50 Characters Required!')
      .max(1500, 'Not more than 1500 Characters!')
      .required('Review Content is required!'),
    overall_rating: yup
      .number()

      .moreThan(0, 'Rating is required!')
      .required('Rating is required!'),
    month_visited: yup
      .string()
      .nullable('Month visited is required!')
      .required('Month visited is required!'),
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'PostForm',
})(Form);

export default DeskForm;
