import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0rem auto;
  max-width: 1330px;
  padding: 0rem 1rem;
`;

const Pager = ({ children, ...props }) => <Container {...props}>{children}</Container>;

export default Pager;
