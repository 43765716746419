import React from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';

import DesktopForm from './DesktopForm';
import { Pager } from '../../components/elements';

const Container = styled(Pager)`
  padding-top: 2rem;
`;

const DesktopView = ({ loading, onSubmit }) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn } = useStoreState(state => state.auth);
  return (
    <Container>
      <br />
      <DesktopForm
        onSubmit={onSubmit}
        loading={loading}
        setIsActiveModal={setIsActiveModal}
        isLoggedIn={isLoggedIn}
      />
    </Container>
  );
};

export default DesktopView;
