import React, { useState } from 'react';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import { Text } from '../../../../../components/elements';
import Variant from './Variant';
import colors from '../../../../../theme/styles/colors';

const UpdateModifiersVariant = styled.div`
  background-color: ${({ theme: { colors: themeColor }, disabled }) =>
    disabled ? themeColor.coolGrey : themeColor.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1rem;
  width: 18rem;
  margin-top: 1rem;
  border-radius: 5px;
  cursor: pointer;
  p {
    text-align: center;
  }
`;

const Content = styled.div`
  padding: 1.5rem;
`;

const ModifierVariantSelection = ({ prompt, variants, handleVariantSelection }) => {
  const [selectedItem, setSelectedItem] = useState(
    variants.filter(item => item.isSelected === true),
  );

  const [newItems, setNewItems] = useState(variants.filter(item => item.isSelected === true));
  const newVariants = variants && variants.map((item, index) => ({ ...item, index }));

  return (
    <Content>
      <Text className="is-capitalized" size="medium" paddingBottom={0.4} weight="semibold">
        {prompt}
      </Text>
      <Text size="smaller" color="secondaryColor" paddingBottom="1">
        Please choose any one option ( Required )
      </Text>
      {newVariants &&
        newVariants.map(edge => {
          const isActiveArray = selectedItem.filter(item => item.variant_id === edge.variant_id);
          return (
            <Variant
              key={edge.variant_id}
              variant={edge}
              isActive={isActiveArray.length !== 0}
              handleVariantSelection={() => {
                const rest = newVariants.filter(item => item.variant_id !== edge.variant_id);
                setSelectedItem([edge]);
                setNewItems(rest.concat({ ...edge, isSelected: true }));
              }}
            />
          );
        })}
      <UpdateModifiersVariant
        onClick={() => {
          if (newItems.length !== 0) {
            handleVariantSelection(sortBy(newItems, 'index'));
          }
        }}
      >
        <Text size="large" weight="semibold" color={colors.white}>
          Update
        </Text>
      </UpdateModifiersVariant>
    </Content>
  );
};

export default ModifierVariantSelection;
