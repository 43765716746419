import React, { useState } from 'react';
import styled from 'styled-components';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { isNull, sortBy, uniqBy, reverse, dropRight, first } from 'lodash';
import uuid from 'uuid';
import swal from 'sweetalert';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Text, IconActionButton } from '../../../../components/elements';
import PlaceReviewCommentInput from './PlaceReviewCommentInput';
import UserArticle from '../../../../components/UserArticle';
import CommentSocial from './CommentSocial';
import apolloClient from '../../../../utils/apolloClient';
import { parseSocialPlace } from '../../../../utils/helpers';

const searchCommentsQuery = gql`
  query searchComments($input: SearchInput) {
    search_comments(input: $input) {
      comment_listing {
        comment_id
        object_id
        object_type
        parent_id
        parent_type
        content
        status
        audit {
          created_at
          created_by
          updated_at
        }
        user_details {
          user_id
          display_name
          profile_image_url
          color_code
        }
      }
    }
    search_social(input: { filter: { social_filter: { object_type: "PLACE_REVIEW_COMMENT" } } }) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const commentMutation = gql`
  mutation comment($input: CommentInput) {
    create_comment(input: $input) {
      comment_id
      object_id
      object_type
      parent_id
      parent_type
      content
      status
      audit {
        created_at
        created_by
        updated_at
      }
      error {
        description
      }
    }
  }
`;

const updateCommentMutation = gql`
  mutation updateComment($input: CommentInput) {
    update_comment(input: $input) {
      comment_id
      object_id
      object_type
      parent_id
      parent_type
      content
      status
      audit {
        created_at
        created_by
        updated_at
      }
      error {
        description
      }
    }
  }
`;

const deleteCommentMutation = gql`
  mutation deleteComment($input: NavigateCommentInput) {
    delete_comment(input: $input) {
      comment_id
      error {
        description
      }
    }
  }
`;

const Container = styled.form`
  && {
    background: ${({ isActive }) => (isActive ? '#00aeef29' : 'transparent')};
    border-radius: 4px;
    .media {
      border: 0;
      padding: 0;
    }
    .columns:not(:last-child) {
      margin: 0;
    }
    .content p:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

const PostComment = ({ placeId, placeReviewId }) => {
  const { userId, userImage, user } = useStoreState(state => state.auth);
  const variables = {
    input: {
      sort: 'CREATED_DESC',
      filter: {
        comment_filter: {
          object_id: placeReviewId,
          object_type: 'PLACE_REVIEW',
          parent_id: placeId,
          parent_type: 'PLACE',
          status: 'ACTIVE',
        },
      },
    },
  };
  return (
    <Mutation
      client={apolloClient.clientPrivate}
      mutation={commentMutation}
      // onCompleted={() => {
      //   afterHandleCreateComment();
      // }}
      update={(client, { data: { create_comment } }) => {
        const { clientPublic } = apolloClient;
        const { search_comments } = clientPublic.readQuery({
          query: searchCommentsQuery,
          variables,
        });

        const createdComment = {
          ...create_comment,
          user_details: {
            user_id: userId,
            display_name: user.display_name,
            profile_image_url: userImage,
            color_code: user.color_code,
            __typename: 'UserDetails',
          },
        };

        clientPublic.writeQuery({
          query: searchCommentsQuery,
          variables,
          data: {
            search_comments: {
              comment_listing: reverse(
                sortBy(
                  search_comments.comment_listing.concat([createdComment]),
                  'audit.updated_at',
                ),
              ),
              __typename: 'CommentListingAggregation',
            },
          },
        });
      }}
    >
      {(create_comment, { loading }) => (
        <PlaceReviewCommentInput
          loading={loading}
          onSubmit={value => {
            create_comment({
              variables: {
                input: {
                  user_id: userId,
                  comment_id: uuid(),
                  object_id: placeReviewId,
                  object_type: 'PLACE_REVIEW',
                  parent_id: placeId,
                  parent_type: 'PLACE',
                  content: value,
                  status: 'ACTIVE',
                },
              },
            });
          }}
        />
      )}
    </Mutation>
  );
};

const PostCommentUpdate = ({ placeId, placeReviewId, comment, afterHandleUpdateComment }) => {
  const { userId, userImage, user } = useStoreState(state => state.auth);
  const variables = {
    input: {
      sort: 'CREATED_DESC',
      filter: {
        comment_filter: {
          object_id: placeReviewId,
          object_type: 'PLACE_REVIEW',
          parent_id: placeId,
          parent_type: 'PLACE',
          status: 'ACTIVE',
        },
      },
    },
  };
  return (
    <Mutation
      client={apolloClient.clientPrivate}
      mutation={updateCommentMutation}
      onCompleted={() => {
        afterHandleUpdateComment();
      }}
      update={(client, { data: { update_comment } }) => {
        const { clientPublic } = apolloClient;
        const { search_comments } = clientPublic.readQuery({
          query: searchCommentsQuery,
          variables,
        });
        const commentList = search_comments.comment_listing.filter(
          item => item.comment_id !== update_comment.comment_id,
        );
        const updateComment = {
          ...update_comment,
          user_details: {
            user_id: userId,
            display_name: user.display_name,
            profile_image_url: userImage,
            color_code: user.color_code,
            __typename: 'UserDetails',
          },
        };
        clientPublic.writeQuery({
          query: searchCommentsQuery,
          variables,
          data: {
            search_comments: {
              comment_listing: reverse(
                sortBy(commentList.concat([updateComment]), 'audit.updated_at'),
              ),
              __typename: 'CommentListingAggregation',
            },
          },
        });
      }}
    >
      {(update_comment, { loading }) => (
        <>
          <PlaceReviewCommentInput
            commentTextProps={comment && comment.content}
            loading={loading}
            onSubmit={value => {
              update_comment({
                variables: {
                  input: {
                    user_id: userId,
                    comment_id: comment.comment_id,
                    object_id: placeReviewId,
                    object_type: 'PLACE_REVIEW',
                    parent_id: placeId,
                    parent_type: 'PLACE',
                    content: value,
                    status: 'ACTIVE',
                  },
                },
              });
            }}
          />
          <button onClick={() => afterHandleUpdateComment()} className="button is-danger">
            Cancel
          </button>
        </>
      )}
    </Mutation>
  );
};

const PlaceReviewCommentList = ({ placeId, placeReviewId }) => {
  const { userId, isLoggedIn } = useStoreState(state => state.auth);
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const [numberOfCommentArray, setNumberOfCommentArray] = useState(3);
  const [editableComment, setEditableComment] = useState();
  const [isEditableComment, setIsEditableComment] = useState(false);
  const variables = {
    input: {
      sort: 'CREATED_DESC',
      filter: {
        comment_filter: {
          object_id: placeReviewId,
          object_type: 'PLACE_REVIEW',
          parent_id: placeId,
          parent_type: 'PLACE',
          status: 'ACTIVE',
        },
      },
    },
  };

  const handleCommentEdit = async comment => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
        callBackFunction: async () => {
          await setEditableComment(comment);
          setIsEditableComment(true);
        },
      });
    } else {
      await setEditableComment(comment);
      setIsEditableComment(true);
    }
  };

  const afterHandleUpdateComment = async () => {
    await setIsEditableComment(false);
    setEditableComment();
  };

  return (
    <Query query={searchCommentsQuery} variables={variables} fetchPolicy="network-only">
      {({ data, loading }) => {
        if (loading) {
          return '';
        }

        if (isNull(data.search_comments)) {
          return <PostComment placeId={placeId} placeReviewId={placeReviewId} />;
        }

        if (data.search_comments.comment_listing.length === 0) {
          return <PostComment placeId={placeId} placeReviewId={placeReviewId} />;
        }

        const comments = uniqBy(data.search_comments.comment_listing, 'comment_id');
        const numberOfDropArray = comments.length - numberOfCommentArray;
        const firstFiveComments = dropRight(comments, numberOfDropArray);
        const socials = data.search_social.social_aggregation.total_social_per_object_id;

        return (
          <>
            <PostComment placeId={placeId} placeReviewId={placeReviewId} />
            <div className="columns is-multiline">
              {firstFiveComments.map(comment => {
                const socialData = socials.filter(item => item.object_id === comment.comment_id);
                const socialArray =
                  socialData.length !== 0 ? first(socialData).total_social_count : [];
                const social = parseSocialPlace(socialArray, userId);
                return (
                  <Container
                    className="column is-12"
                    key={comment.comment_id}
                    isActive={editableComment && comment.comment_id === editableComment.comment_id}
                  >
                    {isEditableComment && editableComment.comment_id === comment.comment_id ? (
                      <PostCommentUpdate
                        placeId={placeId}
                        placeReviewId={placeReviewId}
                        comment={editableComment}
                        afterHandleUpdateComment={afterHandleUpdateComment}
                      />
                    ) : (
                      <UserArticle
                        user={comment.user_details}
                        maskProps={{ width: 40, height: 40 }}
                        subHeading={moment(comment.audit && comment.audit.updated_at).fromNow()}
                        maskTextProps={{
                          color: 'white',
                          weight: 'bold',
                          size: 'smaller',
                          letterSpacing: 'loose',
                        }}
                      >
                        <Text size="tiny" color="darkGrey" paddingBottom={0.5}>
                          {comment.content}
                        </Text>
                        <nav className="level is-mobile">
                          {/* <div className="level-left">
                            <div className="level-item">
                              <Text size="smaller" color="specialGrey">
                                {moment(comment.audit && comment.audit.updated_at).fromNow()}
                              </Text>
                            </div>
                          </div> */}

                          <div className="level-left">
                            <CommentSocial
                              comment={comment}
                              objectId={comment.comment_id}
                              objectType="PLACE_REVIEW_COMMENT"
                              likeActive={social.like.active}
                              likeCountInt={social.like.count}
                              likeSocialIdS={social.like.id}
                            />
                            {comment.audit && comment.audit.created_by === userId && (
                              <>
                                <div className="level-item">
                                  <Mutation
                                    client={apolloClient.clientPrivate}
                                    mutation={deleteCommentMutation}
                                    variables={{
                                      input: {
                                        user_id: userId,
                                        comment_id: comment.comment_id,
                                      },
                                    }}
                                    // onCompleted={() => setTotalComments(totalComments - 1)}
                                    update={(client, { data: { delete_comment } }) => {
                                      const { clientPublic } = apolloClient;
                                      const { search_comments } = clientPublic.readQuery({
                                        query: searchCommentsQuery,
                                        variables,
                                      });
                                      clientPublic.writeQuery({
                                        query: searchCommentsQuery,
                                        variables,
                                        data: {
                                          search_comments: {
                                            comment_listing: reverse(
                                              sortBy(
                                                search_comments.comment_listing.filter(
                                                  item =>
                                                    item.comment_id !== delete_comment.comment_id,
                                                ),
                                                'audit.updated_at',
                                              ),
                                            ),
                                            __typename: 'CommentListingAggregation',
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    {delete_comment => (
                                      <IconActionButton
                                        name="Delete"
                                        textStyle={{
                                          size: 'smaller',
                                          weight: 'regular',
                                          color: 'danger',
                                        }}
                                        paddingLess
                                        onClick={() => {
                                          swal('Are you sure delete this comment ?', {
                                            dangerMode: true,
                                            buttons: true,
                                          }).then(willDelete => {
                                            if (willDelete) {
                                              delete_comment();
                                            }
                                          });
                                        }}
                                      />
                                    )}
                                  </Mutation>
                                </div>
                                <div className="level-item">
                                  <IconActionButton
                                    name="Edit"
                                    textStyle={{
                                      size: 'smaller',
                                      weight: 'regular',
                                      color: 'primaryColor',
                                    }}
                                    paddingLess
                                    onClick={() => handleCommentEdit(comment)}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </nav>
                      </UserArticle>
                    )}
                  </Container>
                );
              })}
            </div>
            {numberOfDropArray > 1 && (
              <a onClick={() => setNumberOfCommentArray(comments.length)}>
                <Text color="primaryColor" size="tiny" weight="regular">
                  Show {comments.length} more comments
                </Text>
              </a>
            )}
          </>
        );
      }}
    </Query>
  );
};
export default PlaceReviewCommentList;
