/* eslint-disable max-len */
import dummyImage from '../assets/images/resto.jpg';

// defaultVariables is local variables
const defaultVariables = {
  authEnvironmentPrefix: 'dev1',
  apiPrefix: 'dev1.',
  UserPoolId: 'us-east-1_9Xkdly93x',
  ClientId: '72mpdcurr7nvl98u7sb4inaq1c',
  IdentityPoolId: 'us-east-1:c8290d25-a498-49cf-a5a7-b08100615c9b',
  aws_appsync_apiKey: 'da2-bl4u5k6tqvbk5m5meuclwbxbb4',
  analyticsKey: 'HTjBdAzlMKtWou728rDVZULxUIidOB2S',
  redirect_uri: `https://dev1.portal.kravein.com.au/auth`,
  localhost_redirect_uri: 'http://localhost:3000/auth',
  graphqlApiPublic: 'https://dev1.publicapi.kravein.com.au/graphql',
  graphqlApiPrivate: 'https://dev1.graphql.kravein.com.au/graphql',
};

const variables = {
  DEV1: {
    authEnvironmentPrefix: 'dev1',
    apiPrefix: 'dev1.',
    UserPoolId: 'us-east-1_9Xkdly93x',
    ClientId: '72mpdcurr7nvl98u7sb4inaq1c',
    IdentityPoolId: 'us-east-1:c8290d25-a498-49cf-a5a7-b08100615c9b',
    aws_appsync_apiKey: 'da2-bl4u5k6tqvbk5m5meuclwbxbb4',
    analyticsKey: 'HTjBdAzlMKtWou728rDVZULxUIidOB2S',
    redirect_uri: `https://dev1.portal.kravein.com.au/auth`,
    localhost_redirect_uri: 'http://localhost:3000/auth',
    graphqlApiPublic: 'https://dev1.publicapi.kravein.com.au/graphql',
    graphqlApiPrivate: 'https://dev1.graphql.kravein.com.au/graphql',
  },
  TEST: {
    authEnvironmentPrefix: 'test',
    apiPrefix: 'test.',
    UserPoolId: 'us-east-1_JvKtjTImD',
    ClientId: '2u377klhn4ba2vspj2tu6g5l81',
    IdentityPoolId: 'us-east-1:a3176c3e-c65d-447a-83fd-fe9a94accb85',
    aws_appsync_apiKey: 'da2-koehqita7vftfeugp2rmyqwp6a',
    analyticsKey: 'HTjBdAzlMKtWou728rDVZULxUIidOB2S',
    redirect_uri: `https://test.portal.kravein.com.au/auth`,
    localhost_redirect_uri: 'http://localhost:3000/auth',
    graphqlApiPublic: 'https://test.publicapi.kravein.com.au/graphql',
    graphqlApiPrivate: 'https://test.graphql.kravein.com.au/graphql',
  },
  PROD: {
    authEnvironmentPrefix: '',
    apiPrefix: '',
    UserPoolId: 'us-east-1_PyOI52FsO',
    ClientId: '5lnjsqkmmgt66p976lssrh7qsa',
    IdentityPoolId: 'us-east-1:a6029dca-788f-4893-b398-1234cd454a78',
    aws_appsync_apiKey: 'da2-lvh7gxx7lbb7jbrxhwwvovwjf4',
    analyticsKey: 'HTjBdAzlMKtWou728rDVZULxUIidOB2S',
    redirect_uri: `https://portal.kravein.com.au/auth`,
    localhost_redirect_uri: 'http://localhost:3000/auth',
    graphqlApiPublic: 'https://publicapi.kravein.com.au/graphql',
    graphqlApiPrivate: 'https://graphql.kravein.com.au/graphql',
  },
};

const config = process.env.REACT_APP_ENV ? variables[process.env.REACT_APP_ENV] : defaultVariables;
// const config = variables.TEST;
// console.log('config ', config);

export default {
  dummyImage,
  imageSourceLike: `https://${config.apiPrefix}images.kravein.com.au`,
  devCallBack: `https://${config.authEnvironmentPrefix}auth.kravein.com.au/login?response_type=token&client_id=${config.ClientId}&redirect_uri=${config.localhost_redirect_uri}`,
  prodCallBack: `https://${config.authEnvironmentPrefix}auth.kravein.com.au/login?response_type=token&client_id=${config.ClientId}&redirect_uri=${config.redirect_uri}`,
  public: {
    aws_appsync_graphqlEndpoint: config.graphqlApiPublic,
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: config.aws_appsync_apiKey,
  },
  secured: {
    aws_appsync_graphqlEndpoint: config.graphqlApiPrivate,
    aws_appsync_region: 'us-east-1',
  },
  userPoolConfig: {
    UserPoolId: config.UserPoolId,
    ClientId: config.ClientId,
    region: 'us-east-1',
    IdentityPoolId: config.IdentityPoolId,
  },
  analyticsKey: config.analyticsKey,
  defaultCurrentLocation: {
    addressLine1: '',
    addressLine2: '',
    city: 'Sydney',
    state: 'New South Wales',
    postcode: '2000',
    country: 'Australia',
    latitude: '-33.865143',
    longitude: '151.209900',
  },
  placeSearchDistanceRadius: '200km',
  eventSearchDistanceRadius: '500km',
  itemSearchDistanceRadius: '200km',
  searchDistanceRadius: '200km',
  distanceRadius: '10km',
  googleApiKey: 'AIzaSyARq3lIWaDnlepdOd2alXdFzuTcd-PyGdY',
  facebook: {
    appId: '129278177706600',
    url: 'http://www.facebook.com',
  },
  twitter: {
    url: 'https://twitter.com',
  },
  apiPrefix: config.apiPrefix,
};
