import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { first } from 'lodash';
import { MdAccessTime } from 'react-icons/md';
import { GoLocation, GoChecklist } from 'react-icons/go';
import { getZoneTime } from '../../../../utils/eventHelpers';

import colors from '../../../../theme/styles/colors';

import { Text, IconActionButton, FlexRows } from '../../../../components/elements';
import SocialActionButton from '../../../../components/SocialActionButton';
import ImageView from '../desktop/ImageView';

const Container = styled.div`
  margin: 0rem 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${colors.lightGreyBeta};
`;

const IconWrapper = styled.span`
  align-items: flex-start;
  display: inline-flex;
  justify-content: center;
  padding-right: 1rem;
  padding-top: 0.2rem;
`;

const TextStyle = styled(Text)`
  padding-bottom: 0.4rem;
`;

const FlexRowsStyle = styled(FlexRows)`
  padding-bottom: 0.5rem;
`;

const SocialActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1.2 },
})``;

const IconActionButtonStyle = styled(IconActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1.2 },
})``;

const MobileEventCard = ({ event }) => {
  const [price, setPrice] = useState('');
  const [eventStartDate, setStartDate] = useState(0);

  useEffect(() => {
    if (event.minimum_price === null && event.maximum_price === null) {
      setPrice('');
    } else if (event.minimum_price === 0 && event.maximum_price === 0) {
      setPrice(`Book Now: Free`);
    } else if (event.minimum_price === event.maximum_price) {
      setPrice(`$${parseFloat(event.minimum_price).toFixed(2)}`);
    } else if (event.minimum_price !== 0 && event.maximum_price !== 0) {
      setPrice(
        `$${parseFloat(event.minimum_price).toFixed(2)} - $${parseFloat(
          event.maximum_price,
        ).toFixed(2)}`,
      );
    } else {
      setPrice(
        `$${parseFloat(event.minimum_price).toFixed(2)} - $${parseFloat(
          event.maximum_price,
        ).toFixed(2)}`,
      );
    }
    const fetData = async () => {
      const startDate = await getZoneTime(event.start, event.latitude, event.longitude);
      setStartDate(moment(startDate).format('LLLL'));
    };
    fetData();
  }, [event.latitude, event.longitude, event.maximum_price, event.minimum_price, event.start]);

  return (
    <Container>
      <Link to={`/event/${event.event_id}`}>
        <TextStyle className="is-capitalized" size="medium" weight="semibold">
          {event.name}
        </TextStyle>
      </Link>
      <Link to={`/event/${event.event_id}`}>
        <TextStyle size="small" weight="medium" color="darkGrey">
          {event.tagline}
        </TextStyle>
      </Link>
      <ImageView event={event} />
      <FlexRowsStyle justify="space-between">
        <Text weight="semibold" color="dark" size="small">
          {price}
        </Text>
        <Link to={`/event/${event.event_id}`}>
          <FlexRows justify="flex-start">
            <Text className="is-uppercase" weight="bold" color="primaryColor" size="small">
              {first(event.listing_type) === 'public_listing' ? 'READ MORE' : 'BOOK NOW'}
            </Text>
            {/* <Text color="waterMarkGrey">
              <MdKeyboardArrowRight size={25} />
            </Text> */}
          </FlexRows>
        </Link>
      </FlexRowsStyle>
      <Link to={`/event/${event.event_id}`}>
        <div className="is-flex">
          <IconWrapper>
            <GoLocation size={18} color={colors.specialGrey} />
          </IconWrapper>
          <Text size="smaller" color="dark" weight="regular" paddingBottom={0.5}>
            {event.place_name}, {event.address_line_1}, {event.city}, {event.state}{' '}
            {event.post_code}
          </Text>
        </div>
        <div className="is-flex">
          <IconWrapper>
            <MdAccessTime size={18} color={colors.specialGrey} />
          </IconWrapper>
          <Text size="smaller" color="darkGrey" paddingBottom={0.5}>
            {moment(eventStartDate).format('LLLL')}
          </Text>
        </div>
        <div className="is-flex">
          {event.event_type && event.event_type !== 0 && (
            <IconWrapper>
              <GoChecklist size={18} color={colors.specialGrey} />
            </IconWrapper>
          )}
          <Text color="dark" size="smaller" weight="regular" paddingBottom={0.5}>
            {event.event_type && event.event_type !== 0 && (
              <>
                {event.event_type &&
                  event.event_type.length !== 0 &&
                  first(event.event_type).description}
                {event.event_category &&
                  event.event_category.length !== 0 &&
                  ` | ${first(event.event_category).description}`}
              </>
            )}
          </Text>
        </div>
      </Link>
      <FlexRows justify="space-between">
        <SocialActionButtonStyle
          social={event.social}
          objectId={event.event_id}
          objectType="EVENT"
          type="LIKE"
          name="LIKE"
          defaultIcon="likeGrey"
          activeIcon="likeBlue"
        />
        <SocialActionButtonStyle
          social={event.social}
          objectId={event.event_id}
          objectType="EVENT"
          type="BOOKMARK"
          name="BOOKMARK"
          defaultIcon="bookmarkSolidGrey"
          activeIcon="bookmarkSolid"
        />
        <IconActionButtonStyle name="SHARE" icon="shareBlue" />
      </FlexRows>
    </Container>
  );
};

export default MobileEventCard;
