import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed from 'react-pose';
import ReactLoading from 'react-loading';
import { Icon, Text, FlexColumns } from '.';
import theme from '../../theme';

const ButtonAnimation = posed.a({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const Button = styled(ButtonAnimation)`
  &&& {
    border: 0;
    ${({ paddingLess }) => paddingLess && `padding:0.2rem 0.5rem`};
    background-color: transparent;
  }
`;

const VerticalSpan = styled.span`
  padding-top: 5px;
`;

const IconSocialActionButton = ({
  loading,
  name,
  defaultIcon,
  activeIcon,
  textStyle,
  iconStyle,
  paddingLess,
  vertical,
  withoutIcon,
  showCount,
  handleOnClick,
  active,
  socialCount,
}) => {
  return (
    <>
      {!vertical ? (
        <Button className="button" paddingLess={paddingLess} onClick={!loading && handleOnClick}>
          {defaultIcon && activeIcon && (
            <span className="icon">
              {loading ? (
                <ReactLoading type="spin" color={theme.primaryColor} height="14px" width="14px" />
              ) : (
                <Icon name={active ? activeIcon : defaultIcon} {...iconStyle} />
              )}
            </span>
          )}
          <span>
            {withoutIcon ? (
              <Text color={active ? 'primaryColor' : 'waterMarkGrey'} size="small" weight="regular">
                {name} {showCount && socialCount !== 0 && `(${socialCount})`}
              </Text>
            ) : (
              <Text {...textStyle}>
                {name} {showCount && socialCount !== 0 && `(${socialCount})`}
              </Text>
            )}
          </span>
        </Button>
      ) : (
        <Button className="button" onClick={!loading && handleOnClick}>
          <FlexColumns>
            {defaultIcon && activeIcon && (
              <span>
                {loading ? (
                  <ReactLoading type="spin" color={theme.primaryColor} height="14px" width="14px" />
                ) : (
                  <Icon name={active ? activeIcon : defaultIcon} {...iconStyle} />
                )}
              </span>
            )}
            <VerticalSpan>
              {withoutIcon ? (
                <Text
                  color={active ? 'primaryColor' : 'waterMarkGrey'}
                  size="small"
                  weight="regular"
                >
                  {name} {showCount && socialCount !== 0 && `(${socialCount})`}
                </Text>
              ) : (
                <Text {...textStyle}>
                  {name} {showCount && socialCount !== 0 && `(${socialCount})`}
                </Text>
              )}
            </VerticalSpan>
          </FlexColumns>
        </Button>
      )}
    </>
  );
};

IconSocialActionButton.defaultProps = {
  loading: false,
  vertical: false,
  social: null,
  type: '',
  value: false,
  name: 'github',
  defaultIcon: undefined,
  activeIcon: undefined,
  textStyle: {},
  iconStyle: {},
  paddingLess: false,
  showCount: true,
  socialCount: 0,
  active: false,
  handleOnClick: () => {},
};

IconSocialActionButton.propTypes = {
  loading: PropTypes.bool,
  vertical: PropTypes.bool,
  social: PropTypes.array,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  defaultIcon: PropTypes.string,
  activeIcon: PropTypes.string,
  textStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  paddingLess: PropTypes.bool,
  showCount: PropTypes.bool,
  active: PropTypes.bool,
  socialCount: PropTypes.number,
  handleOnClick: PropTypes.func,
};

export default IconSocialActionButton;
