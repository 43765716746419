import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { trim } from 'lodash';

import PlaceHours from './PlaceHours';
// import colors from '../../theme/styles/colors';
// import font from '../../theme/styles/fonts';

import {
  MobileInput,
  Text,
  Field,
  Button,
  InputErrorMessage,
  LocationSuggestion,
} from '../../components/elements';
import Images from './Images';
import Tags from './Tags';

const ImageUploadWrapper = styled.div`
  height: '3rem';
`;

const FieldStyle = styled(Field).attrs({ labelTextProps: { color: 'dark', label: 'small' } })``;

const Span = styled.span`
  color: red;
`;

// const AdditionalText = styled.span`
//   &&& {
//     color: ${colors.coolGrey};
//     font-size: ${font.size.tiny};
//   }
// `;

const Label = styled(Text).attrs({
  color: 'darkGrey',
  size: 'small',
  weight: 'semibold',
})``;

const initialTime = '';

const business_hour = [
  {
    day: 'MON',
    is_active: true,
    open24Hours: false,
    option: [
      {
        type: '',
        start: initialTime,
        end: initialTime,
      },
    ],
  },
  {
    day: 'TUE',
    is_active: true,
    open24Hours: false,
    option: [
      {
        type: '',
        start: initialTime,
        end: initialTime,
      },
    ],
  },
  {
    day: 'WED',
    is_active: true,
    open24Hours: false,
    option: [
      {
        type: '',
        start: initialTime,
        end: initialTime,
      },
    ],
  },
  {
    day: 'THU',
    is_active: true,
    open24Hours: false,
    option: [
      {
        type: '',
        start: initialTime,
        end: initialTime,
      },
    ],
  },
  {
    day: 'FRI',
    is_active: true,
    open24Hours: false,
    option: [
      {
        type: '',
        start: initialTime,
        end: initialTime,
      },
    ],
  },
  {
    day: 'SAT',
    is_active: true,
    open24Hours: false,
    option: [
      {
        type: '',
        start: initialTime,
        end: initialTime,
      },
    ],
  },
  {
    day: 'SUN',
    is_active: true,
    open24Hours: false,
    option: [
      {
        type: '',
        start: initialTime,
        end: initialTime,
      },
    ],
  },
];

const LocationForm = props => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    loading,
    setFieldValue,
    touched,
    errors,
  } = props;

  return (
    <>
      <div className="columns">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>
              Place name<Span>*</Span>
            </Label>
          </div>
          <FieldStyle
            name="name"
            value={values.name}
            id="Place_AddPlace_PlaceName"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputErrorMessage touched={touched.name} errors={errors.name} />
        </div>
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>
              Relationship to the place<Span>*</Span>
            </Label>
          </div>
          <FieldStyle
            name="relationship"
            id="Place_AddPlace_Relationship"
            value={values.relationship}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Customer,Employee, Manager, Owner etc.."
          />
          <InputErrorMessage touched={touched.relationship} errors={errors.relationship} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label paddingBottom={1} size="medium">
              Search Address<Span>*</Span>
            </Label>
          </div>
          <LocationSuggestion
            initialValue=""
            onSelect={place => {
              setFieldValue('streetAddressLine1', trim(place.addressLine1));
              setFieldValue('city', place.city);
              setFieldValue('postcode', place.postcode);
              setFieldValue('state', place.state);
              setFieldValue('country', place.country || 'AUSTRALIA');
              setFieldValue('timezone', place.timezone || '+10');
              setFieldValue('latitude', place.latitude);
              setFieldValue('longitude', place.longitude);
            }}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>
              Street Address<Span>*</Span>
            </Label>
          </div>
          <FieldStyle
            name="streetAddressLine1"
            value={values.streetAddressLine1}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="2/155 Fil"
            disabled
          />
          <InputErrorMessage
            touched={touched.streetAddressLine1}
            errors={errors.streetAddressLine1}
          />
        </div>
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>
              City/Suburb<Span>*</Span>
            </Label>
          </div>
          <FieldStyle
            name="city"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
          <InputErrorMessage touched={touched.city} errors={errors.city} />
        </div>

        <div className="column is-2 is-vcentered">
          <div className="field">
            <Label>
              PostCode<Span>*</Span>
            </Label>
          </div>
          <FieldStyle
            name="postcode"
            value={values.postcode}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
          <InputErrorMessage touched={touched.postcode} errors={errors.postcode} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>
              State<Span>*</Span>
            </Label>
          </div>
          <FieldStyle
            name="state"
            value={values.state}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
          <InputErrorMessage touched={touched.state} errors={errors.state} />
        </div>
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>
              Country<Span>*</Span>
            </Label>
          </div>
          <FieldStyle
            name="country"
            value={values.country}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
          <InputErrorMessage touched={touched.country} errors={errors.country} />
        </div>
        <div className="column is-3 is-vcentered">
          <div className="field">
            <Label>Additional Address Information</Label>
          </div>
          <FieldStyle
            name="additionalInfo"
            value={values.additionalInfo}
            id="Place_AddPlace_AdditionalInfo"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Landmark, Shopping Center etc..."
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>Email</Label>
          </div>
          <FieldStyle
            name="email"
            id="Place_AddPlace_Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>Phone</Label>
          </div>
          <MobileInput
            name="mobile"
            id="Place_AddPlace_Mobile"
            value={values.mobile}
            onChange={mobile => setFieldValue('mobile', mobile)}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>Website Address</Label>
          </div>
          <FieldStyle
            placeholder="http://"
            name="website"
            id="Place_AddPlace_Website"
            value={values.website}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputErrorMessage touched={touched.website} errors={errors.website} />
        </div>

        <div className="column is-one-third is-vcentered">
          <div className="field">
            <Label>Facebook Page</Label>
          </div>
          <FieldStyle
            placeholder="www.facebook.com"
            id="Place_AddPlace_Facebook"
            name="facebook"
            value={values.facebook}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      <Tags {...props} />

      <hr />
      <div>
        <Text weight="semibold">Hours</Text>
        <PlaceHours {...props} />
      </div>
      <br />
      <br />
      <ImageUploadWrapper>
        <Label>Add Photos</Label>
        <Text size="tiny" color="darkGrey">
          {/* eslint-disable-next-line max-len */}
          Drag and drop or upload upto 4 images. Formats accepted - &quot;.png, .jpg, .jpeg&quot;{' '}
          <br />
          Maximum size accepted - 10 MB
        </Text>
        <br />
        <Images values={values} setFieldValue={setFieldValue} />
      </ImageUploadWrapper>
      <div className="level">
        <div className="level-left">
          <div className="level-item" />
        </div>
        <div className="level-right">
          <div className="level-item">
            <Button
              className={loading && 'is-loading'}
              name="Add Place"
              inputType="File"
              backgroundColor="primaryColor"
              textProps={{ color: loading ? 'primaryColor' : 'white', weight: 'semibold' }}
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Form = withFormik({
  mapPropsToValues: () => ({
    name: '',
    relationship: '',
    streetAddressLine1: '',
    city: '',
    postcode: '',
    state: '',
    country: '',
    latitude: '',
    longitude: '',
    timezone: '',
    additionalInfo: '',
    email: '',
    mobile: '',
    website: '',
    facebook: '',
    whatsWrong: '',
    business_hour,
    business_types: [],
    service_category: [],
    feature_types: [],
    cuisine_style: [],
    good_for: [],
    image: [
      {
        url: '',
        preview: null,
        tooltip: '',
        link: '',
        tag: [],
        uploadingStatus: 'pending',
      },
    ],
  }),

  validationSchema: yup.object().shape({
    name: yup.string().required('Place name is required!'),
    relationship: yup.string().required('Relationship is required!'),
    streetAddressLine1: yup.string().required('Street Address is required'),
    // streetAddress: yup.string().required('Street Address is required'),
    city: yup.string().required('City is required'),
    postcode: yup.string().required('Postcode is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    email: yup.string().email('Enter valid email address!'),
    // mobile: yup.string().required('Mobile contact is required!'),
    website: yup.string().url('Enter valid url!'),
    facebook: yup.string().url('Enter valid url!'),
    cuisine_style: yup.array().required('Cuisine Style is required'),
    business_types: yup.array().required('Business Type is required'),
    service_category: yup.array().required('Services is required'),
    whatsWrong: yup
      .string()
      .min(26, 'At least 26 Character Required!')
      .max(300, 'Not greater than 300 Character!'),
    business_hour: yup.array().of(
      yup.object().shape({
        option: yup.array().of(
          yup.object().shape(
            {
              start: yup.string().when(['type', 'end'], {
                is: (type, end) =>
                  (type !== undefined && type !== '' && type !== 'Closed') ||
                  (end !== undefined && end !== ''),
                then: yup.string().required('Start date is Required'),
              }),
              end: yup.string().when(['type'], {
                is: type => type !== undefined && type !== '' && type !== 'Closed',
                then: yup.string().required('End date is Required'),
              }),
              // type: yup.string().when(['start', 'end'], {
              //   is: (start, end) => start !== '' || end !== '',
              //   then: yup
              //     .string()
              //     .min(3, 'At least 3 Character Required!')
              //     .max(20, 'Not greater than 20 Character!')
              //     .required('Label is Required'),
              // }),
            },
            ['type', 'end'],
          ),
        ),
      }),
    ),
  }),

  handleSubmit: (values, { props }) => {
    if (!props.isLoggedIn) {
      props.setIsActiveModal({
        value: true,
        path: '',
        callBackFunction: () => props.onSubmit(values),
      });
    } else {
      props.onSubmit(values);
    }
  },

  displayName: 'LocationForm',
})(LocationForm);

export default Form;
