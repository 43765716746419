import React from 'react';
import styled from 'styled-components';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { FlexRows, Text, FlexColumns } from '../../../../components/elements';
import SocialActionButton from '../../../../components/SocialActionButton';
// import ShareActionButton from '../../../../components/ShareActionButton';
import ImageView from './ImageView';

const Container = styled.div`
  padding-top: 2rem;
`;
const Wrapper = styled.div`
  padding: 0rem 1rem;
`;

const ImageWrapper = styled.div`
  padding: 1rem 0rem;
  overflow: hidden;
`;

const SocialIconActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'micro', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1.2 },
  showCount: false,
  vertical: true,
})``;

// const ShareActionButtonButtonStyle = styled(ShareActionButton).attrs({
//   textStyle: { size: 'micro', weight: 'semibold', color: 'darkGrey' },
//   paddingLess: true,
//   iconStyle: { size: 2 },
//   showCount: false,
//   vertical: true,
// })``;

const Info = ({ item }) => (
  <Container>
    <FlexRows justify="space-between">
      <SocialIconActionButtonStyle
        objectId={item.item_id}
        objectType="ITEM"
        type="TRIED"
        name="TRIED"
        defaultIcon="triedGrey"
        activeIcon="triedBlue"
      />
      <SocialIconActionButtonStyle
        objectId={item.item_id}
        objectType="ITEM"
        type="LOVE_IT"
        name="LOVE"
        defaultIcon="loveTheItemGrey"
        activeIcon="loveTheItemBlue"
      />
      <SocialIconActionButtonStyle
        objectId={item.item_id}
        objectType="ITEM"
        type="BOOKMARK"
        name="BOOKMARK"
        defaultIcon="bookmarkSolidGrey"
        activeIcon="bookmarkSolid"
      />
      {/* <ShareActionButtonButtonStyle objectId={item.item_id} objectType="ITEM" /> */}
    </FlexRows>

    <ImageWrapper>
      <ImageView item={item} />
    </ImageWrapper>
    <Wrapper>
      <FlexColumns align="flex-start">
        <Text className="is-capitalized" size="small" weight="semibold">
          {item.name}
        </Text>
        <Text className="is-capitalized	" weight="tiny" color="dark" size="small">
          {item.place_name}
        </Text>
        <Text size="micro" color="dark" weight="regular" paddingBottom={0.3}>
          {item.address_line_1}, {item.city}, {item.state}
        </Text>
        <Text className="is-capitalized	" weight="semibold" color="specialGrey" size="tiny">
          95% Loved it
        </Text>
      </FlexColumns>
      <a>
        <FlexRows justify="space-between">
          <Text className="is-uppercase" color="primaryColor" weight="medium" size="tiny">
            Find other dishes & Details of this place
          </Text>
          <Text color="coolGrey">
            <MdKeyboardArrowRight size={20} />
          </Text>
        </FlexRows>
      </a>
    </Wrapper>
  </Container>
);

export default Info;
