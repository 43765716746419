import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import DeskForm from './DeskForm';
import { Pager, Text } from '../../components/elements';

const Container = styled(Pager)`
  padding-top: 2rem;
`;

const DesktopView = ({ user, onSubmit, loading, history }) => (
  <Container>
    <div>
      <Text size="big" weight="semibold" paddingBottom={0.5}>
        My Profile Details
      </Text>
      <Text size="small" color="darkGrey">
        Maintain your profile information here.
      </Text>
    </div>
    <br />
    <DeskForm user={user} loading={loading} onSubmit={onSubmit} history={history} />
  </Container>
);

export default withRouter(DesktopView);
