import React from 'react';
import styled from 'styled-components';
import icons from '../../assets/icons';

const Image = styled.img`
  width: ${({ size }) => `${size}rem`};
`;

const Icon = ({ name, size, ...props }) => (
  <>
    <Image src={icons[name] || 'likeStarBlue'} size={size} {...props} />
  </>
);

export default Icon;
