import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import qs from 'querystringify';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { Text, Pager } from '../../components/elements';
import DeskForm from './components/desktop/DeskForm';

const Container = styled(Pager)`
  padding-top: 2rem;
`;
const Header = styled.div`
  padding-bottom: 1rem;
`;

const DesktopView = ({ loading, onSubmit, handleAction, history, location }) => {
  const setIsActiveDesktopLoginModal = useStoreActions(
    state => state.auth.setIsActiveDesktopLoginModal,
  );
  const { isLoggedIn } = useStoreState(state => state.auth);
  const params = qs.parse(location.search);
  return (
    <Container>
      <Header>
        <Text size="big" weight="semibold" paddingBottom={0.5}>
          Spot and Share an Item you love
        </Text>
        <Text size="small" color="darkGrey" paddingBottom={0.5}>
          Share an item from an enticing drink to a lip smacking ice cream or a delicious dish.
        </Text>
      </Header>
      <DeskForm
        haveInitialPlace={params.place_id}
        loading={loading}
        history={history}
        handleAction={values => handleAction(values)}
        onSubmit={values => onSubmit(values)}
        setIsActiveDesktopLoginModal={setIsActiveDesktopLoginModal}
        isLoggedIn={isLoggedIn}
      />
    </Container>
  );
};

export default withRouter(DesktopView);
