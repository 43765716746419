import gql from 'graphql-tag';
import { isUndefined, isNull } from 'lodash';
import moment from 'moment';
import client from './apolloClient';
import { getZoneTime } from './eventHelpers';

const imagesQuery = gql`
  query images($input: SearchInput) {
    search_images(input: $input) {
      image_id
      url
      audit {
        created_by
        created_at
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getApiImages = image_filter =>
  new Promise(resolve => {
    client.clientPublic
      .query({
        query: imagesQuery,
        variables: {
          input: {
            filter: {
              image_filter,
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(result => {
        const image =
          !isUndefined(result.data) ||
          !isUndefined(result.data.search_images) ||
          !isNull(result.data.search_images)
            ? result.data.search_images
            : [];
        resolve(image);
      })
      .catch(error => {
        console.log(error);
        resolve([]);
      });
  });

const placeDealsQuery = gql`
  query placeDeals($input: SearchInput) {
    search_place_deal(input: $input) {
      total_size
    }
  }
`;

export const getPlaceDealsCount = (placeId, latitude, longitude) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: placeDealsQuery,
        variables: {
          input: {
            filter: {
              place_deal_filter: {
                place_id: placeId,
                status: 'ACTIVE',
                end_date_range: { start_date: getZoneTime(moment(), latitude, longitude) },
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data: { search_place_deal } }) => {
        resolve(search_place_deal.total_size);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const eventDealsQuery = gql`
  query eventDeals($input: SearchInput) {
    search_place_event_voucher(input: $input) {
      total_size
    }
  }
`;

export const getEventDealsCount = (placeId, eventId, latitude, longitude) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: eventDealsQuery,
        variables: {
          input: {
            filter: {
              place_event_voucher_filter: {
                place_id: placeId,
                event_id: eventId,
                status: 'ACTIVE',
                end_date_range: { start_date: getZoneTime(moment(), latitude, longitude) },
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data: { search_place_event_voucher } }) => {
        resolve(search_place_event_voucher.total_size);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const followQuery = gql`
  query follows($input: SearchInput) {
    search_follows(input: $input) {
      total_size
    }
  }
`;

export const getFollowerCount = userId =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: followQuery,
        variables: {
          input: {
            filter: {
              follow_filter: { object_id: userId, object_type: 'USER', type: 'FOLLOW' },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data: { search_follows } }) => {
        resolve(search_follows.total_size);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getFollowingCount = userId =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: followQuery,
        variables: {
          input: {
            filter: {
              follow_filter: { user_id: userId, object_type: 'USER', type: 'FOLLOW' },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data && !isNull(data.search_follows)) {
          resolve(data.search_follows.total_size);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const reviewQuery = gql`
  query searchItemReview($userId: String) {
    search_item_review(input: { filter: { item_review_filter: { user_id: $userId } } }) {
      total_size
    }
    search_place_review(input: { filter: { place_review_filter: { user_id: $userId } } }) {
      total_size
    }
  }
`;

export const geReviewCount = userId =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: reviewQuery,
        variables: {
          userId,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data: { search_item_review, search_place_review } }) => {
        if (search_item_review && search_place_review) {
          const count = search_item_review.total_size + search_place_review.total_size;
          resolve(count);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
