import React, { useState } from 'react';
import styled from 'styled-components';
import { times, first } from 'lodash';
import moment from 'moment';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { MdStar } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { Text, FlexRows, Button, ShowMoreText } from '../../../../../components/elements';
import colors from '../../../../../theme/styles/colors';
import PlaceReviewImageView from './PlaceReviewImageView';

import ReviewedPlaceHeader from './ReviewedPlaceHeader';
import Social from './Social';
import apolloClient from '../../../../../utils/apolloClient';
import { parseSocialPlace } from '../../../../../utils/helpers';

const Container = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${colors.lightGreyBeta};
`;
const IconWrapper = styled.span`
  padding: 0.2rem;
`;

const IconActionButtonWrapper = styled.div`
  padding-top: 0rem;
`;

const StatusWrapper = styled.div`
  float: right;
  position: relative;
  bottom: 2.5rem;
`;

const searchReviewQuery = gql`
  query searchItemReview($placeReviewFilter: PlaceReviewFilter) {
    search_place_review(input: { filter: { place_review_filter: $placeReviewFilter } }) {
      place_review_listing {
        place_id
        place_review_id
        month_visited
        overall_rating
        service_category {
          name
          description
        }
        content
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;
const deletePlaceReview = gql`
  mutation deletePlaceReview($input: NavigatePlaceReviewInput) {
    delete_place_review(input: $input) {
      place_review_id
      error {
        description
      }
    }
  }
`;

const PlaceReviewItem = ({ review, socials, draft, history }) => {
  const { userId } = useStoreState(state => state.auth);
  const socialData = socials.filter(item => item.object_id === review.place_review_id);
  const socialArray = socialData.length !== 0 ? first(socialData).total_social_count : [];
  const social = parseSocialPlace(socialArray, userId);
  const [totalComments, setTotalComments] = useState(0);
  const [totalLike, setTotalLike] = useState(social.like.count);
  return (
    <Container>
      <ReviewedPlaceHeader placeId={review.place_id} place={review.place}>
        <div className="is-flex">
          {times(review.overall_rating).map(index => (
            <IconWrapper key={index}>
              <MdStar color={colors.tertiaryColor} size={35} />
            </IconWrapper>
          ))}
          {times(5 - review.overall_rating).map(index => (
            <IconWrapper key={index}>
              <MdStar color={colors.specialGrey} size={35} />
            </IconWrapper>
          ))}
        </div>
        <Text color="darkGrey" size="tiny">{`${moment(review.audit.created_at).calendar()}, Visited
        ${moment(review.month_visited).format('MMM, YYYY')}`}</Text>
      </ReviewedPlaceHeader>
      <ShowMoreText size="tiny" content={review.content} />
      <br />
      <PlaceReviewImageView placeId={review.place_id} placeReviewId={review.place_review_id} />
      {totalLike > 1 && `${totalLike} like`}
      {totalLike === 1 && `${totalLike} likes`}
      {(totalLike !== 0 || totalComments !== 0) && ', '}
      {totalComments > 1 && `${totalComments} comments`}
      {totalComments === 1 && `${totalComments} comment`}
      {/* <FlexRows justify="space-between"> */}
      <div className="columns">
        {!draft ? (
          <div className="column is-2">
            <IconActionButtonWrapper>
              <Social
                review={review}
                objectId={review.place_review_id}
                objectType="PLACE_REVIEW"
                likeActive={social.like.active}
                likeCountInt={social.like.count}
                likeSocialIdS={social.like.id}
                setTotalLike={setTotalLike}
              />
            </IconActionButtonWrapper>
          </div>
        ) : (
          <div className="column is-3">
            <FlexRows justify="space-between">
              <Mutation
                client={apolloClient.clientPrivate}
                mutation={deletePlaceReview}
                variables={{
                  input: {
                    user_id: review.audit.created_by,
                    place_id: review.place_id,
                    place_review_id: review.place_review_id,
                  },
                }}
                update={(client, { data: { delete_place_review } }) => {
                  const { clientPublic } = apolloClient;
                  const {
                    search_place_review: { place_review_listing },
                  } = clientPublic.readQuery({
                    query: searchReviewQuery,
                    variables: {
                      placeReviewFilter: { user_id: userId, draft: true, status: 'SUBMITTED' },
                    },
                  });
                  const finalReview = place_review_listing.filter(
                    item => item.place_review_id !== delete_place_review.place_review_id,
                  );
                  clientPublic.writeQuery({
                    query: searchReviewQuery,
                    variables: {
                      placeReviewFilter: { user_id: userId, draft: true, status: 'SUBMITTED' },
                    },
                    data: {
                      search_place_review: {
                        place_review_listing: finalReview,
                        __typename: 'PlaceReviewListingAggregation',
                      },
                    },
                  });
                }}
                onCompleted={() => {
                  swal('Poof! Your Place review has been deleted!', {
                    icon: 'success',
                  });
                }}
              >
                {(delete_place_review, { loading }) => (
                  <Button
                    className={`${loading && 'is-loading'}`}
                    height={0}
                    name="Delete"
                    textProps={{
                      size: 'tiny',
                      color: loading ? 'white' : 'darkGrey',
                    }}
                    onClick={() => {
                      swal('Are you sure you want to delete the draft place review ?', {
                        buttons: ['Cancel', true],
                      }).then(willDelete => {
                        if (willDelete) {
                          delete_place_review();
                        }
                      });
                    }}
                  />
                )}
              </Mutation>

              <Button
                height={0}
                name="Finish Review"
                textProps={{
                  size: 'tiny',
                  color: 'darkGrey',
                }}
                onClick={() =>
                  history.push(
                    // eslint-disable-next-line max-len
                    `/place-review/${review.place_id}?reviewId=${review.place_review_id}`,
                  )
                }
              />
            </FlexRows>
          </div>
        )}
      </div>
      {!draft && (
        <StatusWrapper>
          {review.status === 'SUBMITTED' && (
            <span className="tag is-warning is-medium">
              <Text color="white" size="small" weight="semibold">
                Pending
              </Text>
            </span>
          )}
          {review.status === 'APPROVED' && (
            <span className="tag is-success is-medium">
              <Text color="white" size="small" weight="semibold">
                Approved
              </Text>
            </span>
          )}
        </StatusWrapper>
      )}
      {/* </FlexRows> */}
    </Container>
  );
};

export default withRouter(PlaceReviewItem);
