/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { first, sortBy } from 'lodash';
import moment from 'moment';

import { Text, FlexRows, ReactSelect } from '../../../components/elements';
import DeskEvent from './DeskEvent';
import Loader from './Loader';

import client from '../../../utils/apolloClient';

const Container = styled.div`
  padding: 0rem 1rem;
`;

const Wrapper = styled.nav`
  background: #ffffff;
`;

const Item = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding: 1rem 0rem;
  padding-right: 1.5rem;
  cursor: pointer;
`;

const bookingQuery = gql`
  query search_event_bookings($input: SearchInput) {
    search_event_bookings(input: $input) {
      event_booking_listing {
        event_id
        booking_id
        booking_number
        url
        booking {
          booked_at
        }
      }
    }
  }
`;

const searchEventsQuery = gql`
  query search_events($input: SearchInput) {
    search_events(input: $input) {
      event_listing {
        event_id
        name
        place_name
        start
        end
        minimum_price
        maximum_price
        description
        address_line_1
        city
        state
        country
        post_code
        latitude
        longitude
      }
    }
  }
`;

const DesktopView = ({ title }) => {
  const { userId } = useStoreState(state => state.auth);
  const [active, setActive] = useState('active');
  const [bookingData, setBookingData] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment().toISOString());

  useEffect(() => {
    setCurrentDate(moment().toISOString());
  }, []);

  return (
    <Container>
      <Wrapper className="level">
        <div className="level-left">
          <FlexRows justify="flex-start">
            <Item
              justify="flex-start"
              active={active === 'active'}
              onClick={() => {
                setActive('active');
              }}
            >
              <Text
                className="is-capitalized"
                weight="semibold"
                size="medium"
                color={active === 'active' ? 'primaryColor' : 'darkGrey'}
              >
                Active {title}
              </Text>
            </Item>
            <Item
              justify="flex-start"
              active={active === 'past'}
              onClick={() => {
                setActive('past');
              }}
            >
              <Text
                className="is-capitalized"
                weight="semibold"
                size="medium"
                color={active === 'past' ? 'primaryColor' : 'darkGrey'}
              >
                Past {title}
              </Text>
            </Item>
          </FlexRows>
        </div>
        <div className="level-right">
          <div className="level-item">Sort by</div>
          <div className="level-item">
            <div style={{ width: '10rem' }}>
              <ReactSelect
                options={[
                  { label: 'Event start date', value: 'start-date' },
                  { label: 'Event end date', value: 'end-date' },
                  { label: 'Order date', value: 'order-date' },
                ]}
                onChange={value => {
                  if (value.value === 'start-date') {
                    const data = sortBy(bookingData, 'event.start');
                    setBookingData(data);
                  }
                  if (value.value === 'end-date') {
                    const data = sortBy(bookingData, 'event.end');
                    setBookingData(data);
                  }
                  if (value.value === 'order-date') {
                    const data = sortBy(bookingData, 'booking.booked_at');
                    setBookingData(data);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Wrapper>

      <Query
        client={client.clientPrivate}
        query={bookingQuery}
        variables={{
          input: {
            filter: {
              event_booking_filter: { user_id: userId },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loader />;
          }
          if (error) {
            return 'error';
          }
          const { search_event_bookings } = data;
          if (search_event_bookings.event_booking_listing.length === 0) {
            return 'No data found';
          }

          const eventsIds = search_event_bookings.event_booking_listing.map(item => item.event_id);
          const event_filter = {};
          Object.assign(
            event_filter,
            { event_id: eventsIds },
            active === 'active' && { end_date_range: { start_date: currentDate } },
            active === 'past' && {
              end_date_range: { end_date: moment(currentDate).subtract(1, 'day') },
            },
          );

          return (
            <Query
              query={searchEventsQuery}
              variables={{
                input: {
                  filter: {
                    event_filter,
                  },
                },
              }}
            >
              {({ data: data2, loading: loading2, error: error2 }) => {
                if (loading2) {
                  return <Loader />;
                }
                if (error2) {
                  return 'error';
                }

                if (data2.search_events.event_listing.length === 0) {
                  return <Container>No data found</Container>;
                }

                return (
                  <Container>
                    <div className="columns is-multiline">
                      <View
                        bookings={search_event_bookings.event_booking_listing}
                        events={data2.search_events.event_listing}
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                      />
                    </div>
                  </Container>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Container>
  );
};

const View = ({ bookings, events, bookingData, setBookingData }) => {
  useEffect(() => {
    const finalBookings = bookings.map(item => {
      const event =
        events.length !== 0 ? events.filter(item2 => item2.event_id === item.event_id) : null;
      return {
        ...item,
        event: event.length !== 0 ? first(event) : null,
      };
    });
    setBookingData(finalBookings);
  }, [bookings, events, events.length, setBookingData]);
  return (
    <>
      {bookingData.map(item => (
        <div className="column is-6" key={item.booking_id}>
          <DeskEvent
            eventId={item.event_id}
            bookingId={item.booking_id}
            orderNumber={item.booking_number}
            orderDate={item.booking.booked_at}
            bookingUrl={item.url}
            event={item.event}
          />
        </div>
      ))}
    </>
  );
};

export default DesktopView;
