import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { withRouter } from 'react-router-dom';

import { Text, FlexRows, Button } from '../../../../../components/elements';
import Follower from './Follower';
import Following from './Following';

const Wrapper = styled(FlexRows)`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;

const Heading = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding: 1rem;
`;

const Item = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding: 1rem;
  cursor: pointer;
  border-bottom: 2px solid
    ${({ theme: { colors }, active }) => (active ? colors.primaryColor : 'transparent')};
`;

const friends = [
  { name: 'Followers', count: 10 },
  { name: 'Following', count: 10 },
];

const MyFriends = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);
  const [active, setActive] = React.useState('Followers');
  return (
    <>
      <Heading>
        <Text className="is-capitalized" size="large" weight="semibold">
          My Friends
        </Text>
        <Button
          name="Find Friends"
          backgroundColor="primaryColor"
          textProps={{ color: 'white', weight: 'semibold', size: 'small' }}
          onClick={() => history.push('/profile/find-friends')}
        />
      </Heading>
      <Wrapper justify="flex-start">
        {friends.map(item => (
          <Item
            key={item.name}
            justify="flex-start"
            active={active === item.name}
            onClick={() => setActive(item.name)}
          >
            <Text
              className="is-capitalized"
              weight="semibold"
              size="small"
              color={active === item.name ? 'primaryColor' : 'darkGrey'}
            >
              {item.name}
            </Text>
          </Item>
        ))}
      </Wrapper>
      {active === 'Followers' && <Follower userId={userId} />}
      {active === 'Following' && <Following userId={userId} />}
    </>
  );
};

export default withRouter(MyFriends);
