import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { forEach } from 'lodash';

import { getServicesTypes } from '../../utils/fetchStatic';

import {
  Text,
  Button,
  CheckBox,
  Input,
  InputErrorMessage,
  Flex,
  MobileInput,
  TagInputs,
} from '../../components/elements';
import DeskIdentityForm from './DeskIdentityForm';

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const parseInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

const Span = styled.span`
  color: red;
`;
const Label = styled(Text).attrs({ color: 'darkGrey', size: 'small' })``;

const DesktopForm = props => {
  const [services, setServices] = useState([]);
  const {
    loading,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    history,
    step,
    setStep,
  } = props;
  useEffect(() => {
    const fetchData = async () => {
      const serviceData = await getServicesTypes();
      setServices(serviceData);
    };
    fetchData();
  }, []);

  return (
    <>
      <br />
      {step === 1 && (
        <>
          <div className="columns is-centered">
            <div className="column">
              <div className="columns is-multiline">
                <div className="column is-half">
                  <div className="field">
                    <Label paddingBottom={0.5}>
                      Full name<Span>*</Span>
                    </Label>
                    <div className="control">
                      <Input
                        name="name"
                        id="Place_AddPlace_FullName"
                        placeholder="Your full name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <InputErrorMessage touched={touched.name} errors={errors.name} />
                    </div>
                  </div>
                </div>
                <div className="column is-half">
                  <div className="field">
                    <Label paddingBottom={0.5}>
                      Relationship to the place<Span>*</Span>
                    </Label>
                    <div className="control">
                      <Input
                        name="relation"
                        id="Place_AddPlace_Relationship"
                        value={values.relation}
                        placeholder="Manger, Owner, etc..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <InputErrorMessage touched={touched.relation} errors={errors.relation} />
                    </div>
                  </div>
                </div>
                <div className="column is-half">
                  <div className="field">
                    <Label paddingBottom={0.5}>
                      Email<Span>*</Span>
                    </Label>
                    <div className="control">
                      <Input
                        name="email"
                        id="Place_AddPlace_Email"
                        value={values.email}
                        placeholder="Your email address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <InputErrorMessage touched={touched.email} errors={errors.email} />
                    </div>
                  </div>
                </div>
                <div className="column is-half">
                  <div className="field">
                    <Label paddingBottom={0.5}>
                      Phone<Span>*</Span>
                    </Label>
                    <div className="control">
                      <MobileInput
                        value={values.mobile}
                        placeholder="9056348956"
                        onChange={mobile => setFieldValue('mobile', mobile)}
                      />
                      <InputErrorMessage touched={touched.mobile} errors={errors.mobile} />
                    </div>
                  </div>
                </div>
                <div className="column is-half">
                  <div className="field">
                    <Label paddingBottom={0.5}>Website address</Label>
                    <div className="control">
                      <Input
                        name="website"
                        id="Place_AddPlace_Website"
                        value={values.website}
                        placeholder="http://"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <InputErrorMessage touched={touched.website} errors={errors.website} />
                    </div>
                  </div>
                </div>
                <div className="column is-half">
                  <div className="field">
                    <Label paddingBottom={0.5}>Linkedin profile page</Label>
                    <div className="control">
                      <Input
                        name="linkedin"
                        id="Place_AddPlace_LinedIn"
                        value={values.linkedin}
                        placeholder="http://linkedin.com/"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <InputErrorMessage touched={touched.linkedin} errors={errors.linkedin} />
                    </div>
                  </div>
                </div>
                <div className="column is-half">
                  <div className="field">
                    <Label paddingBottom={0.5}>Interested Services</Label>
                    <div className="control">
                      <TagInputs
                        value={parseData(values.service_type)}
                        suggestions={parseData(services)}
                        onChange={value => setFieldValue('service_type', parseInput(value))}
                      />
                      <InputErrorMessage touched={touched.linkedin} errors={errors.linkedin} />
                    </div>
                  </div>
                </div>
                <div className="column is-full">
                  <div className="field">
                    <Flex justify="flex-start" align="flex-start">
                      <CheckBox
                        id="CheckBox"
                        value={values.agree}
                        onChange={value => {
                          setFieldValue('agree', value);
                        }}
                      />
                      <div>
                        <Label>
                          I am an authorized representative of business and have the authority to
                          claim this place. I have read and accept KarveIn&apos;s
                          <a className="has-text-primary"> Terms of Service </a> and{' '}
                          <a className="has-text-primary"> Privacy Policy </a>
                        </Label>
                      </div>
                    </Flex>
                    <InputErrorMessage touched={touched.agree} errors={errors.agree} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="columns is-centered">
            <div className="column">
              <nav className="level">
                <div className="level-left">
                  <Button
                    className={loading && 'is-loading'}
                    type="button"
                    name="Back"
                    width={3}
                    height={0.5}
                    backgroundColor="primaryColor"
                    textProps={{ color: 'white', weight: 'semibold' }}
                    onClick={() => history.goBack()}
                  />
                </div>
                <div className="level-right">
                  <Button
                    className={loading && 'is-loading'}
                    name="Verify"
                    width={3}
                    height={0.5}
                    backgroundColor="primaryColor"
                    textProps={{ color: loading ? 'primaryColor' : 'white', weight: 'semibold' }}
                    onClick={() => {
                      handleSubmit();
                    }}
                  />
                </div>
              </nav>
            </div>
          </div>
        </>
      )}
      {step === 2 && <DeskIdentityForm {...props} />}
    </>
  );
};

const MyEnhancedForm = withFormik({
  mapPropsToValues: ({ place }) => ({
    place_id: place ? place.place_id : '',
    name: '',
    relation: '',
    email: '',
    mobile: '',
    website: '',
    linkedin: '',
    agree: false,
    service_type: [],
    identity: 'facebook',
  }),
  validate: values => {
    const errors = {};
    if (values.agree === false) {
      errors.agree = 'Agree terms and conditions is required';
    }

    return errors;
  },
  validationSchema: yup.object().shape({
    name: yup.string().required('Full name is required!'),
    relation: yup.string().required('Relationship is required!'),
    email: yup
      .string()
      .email('Enter valid email address!')
      .required('Email address is required!'),
    mobile: yup.string().required('Mobile contact is required!'),
    website: yup.string().url('Enter valid url!'),
    linkedin: yup.string().url('Enter valid url!'),
  }),

  handleSubmit: (values, { props }) => {
    if (!props.isLoggedIn) {
      props.setIsActiveModal({
        value: true,
        path: '',
        callBackFunction: () => props.onSubmit(values),
      });
    } else if (props.step === 2) {
      props.onSubmit(values);
    } else {
      props.setStep(2);
    }
  },

  displayName: 'ContactUsForm',
})(DesktopForm);

export default MyEnhancedForm;
