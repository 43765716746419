import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import posed from 'react-pose';
import { useStoreState } from 'easy-peasy';
import { capitalize } from 'lodash';
import { FaFilter, FaListUl, FaMapMarkerAlt } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';

import { Text, FlexRows, SearchInput } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';
import font from '../../../../theme/styles/fonts';

const Container = styled.div`
  position: fixed;
  background-color: ${({ theme: { colors: color } }) => color.lightGreyBeta};
  padding-top: 0.2rem;
  z-index: 100;
  width: 100%;
`;

const Columns = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
`;

const ScrollView = styled(FlexRows).attrs({ justify: 'space-around' })`
  padding-top: 2.8rem;
  width: 100%;
`;

const ScrollViewItem = styled.a`
  display: inline-block;
  text-align: center;
  padding: 0.5rem 0.5rem;
  border-bottom: 4px solid
    ${({ active, theme: { colors: color } }) => (active ? color.primaryColor : 'transparent')};
`;

const ButtonAnimation = posed.button({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const Button = styled(ButtonAnimation)`
  margin: 0rem 0.3rem !important;
  &&& {
    border-color: transparent;
    background-color: ${({ theme: { colors: color } }) => color.lightGreyAlpha};
    border-radius: 25px;
    :focus {
      border: 0;
    }
    :active {
      border: 0;
    }
  }
`;

const ButtonText = styled.span`
  color: ${colors.dark};
  font-size: ${font.size.tiny};
  font-weight: ${font.weight.semibold};
`;

const ButtonWrapper = styled.div`
  padding-top: 7rem;
`;

const Span = styled.span`
  color: ${colors.primaryColor};
`;

const MobileFilterContainer = ({
  filterStateInputs,
  setActiveFilterModal,
  setActiveMap,
  activeMap,
  handleTabDateFilter,
  setActiveEventTypeModal,
  history,
}) => {
  const eventCurrentDateFilter = useStoreState(state => state.global.eventCurrentDateFilter);
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const filterValue =
    filterStateInputs.eventTypeInputs.length +
    filterStateInputs.eventCategoryInputs.length +
    filterStateInputs.featureInputs.length +
    filterStateInputs.ageRestrictionInputs.length +
    filterStateInputs.dressCodeInputs.length;
  return (
    <>
      <Container>
        <Columns>
          <SearchInput
            value={`${capitalize(usedLocation.city)}, ${capitalize(usedLocation.state)}`}
            onFocus={() => history.push('/search-location')}
            leftIcon={<MdLocationOn size={20} color={colors.coolGrey} />}
            hideRightIcon
          />
        </Columns>
        <ScrollView>
          <ScrollViewItem
            className="item"
            active={eventCurrentDateFilter.name === 'today'}
            onClick={() => {
              handleTabDateFilter('today');
            }}
          >
            <Text className="is-uppercase" weight="bold" size="micro" letterSpacing="loose">
              Today
            </Text>
          </ScrollViewItem>
          <ScrollViewItem
            className="item"
            active={eventCurrentDateFilter.name === 'this-week'}
            onClick={() => {
              handleTabDateFilter('this-week');
            }}
          >
            <Text className="is-uppercase" weight="bold" size="micro" letterSpacing="loose">
              This Week
            </Text>
          </ScrollViewItem>
          <ScrollViewItem
            className="item"
            active={eventCurrentDateFilter.name === 'weekend'}
            onClick={() => {
              handleTabDateFilter('weekend');
            }}
          >
            <Text className="is-uppercase" weight="bold" size="micro" letterSpacing="loose">
              Weekend
            </Text>
          </ScrollViewItem>
          <ScrollViewItem
            className="item"
            active={eventCurrentDateFilter.name === 'upcoming-event'}
            onClick={() => {
              handleTabDateFilter('upcoming-event');
            }}
          >
            <Text className="is-uppercase" weight="bold" size="micro" letterSpacing="loose">
              Upcoming
            </Text>
          </ScrollViewItem>
        </ScrollView>
      </Container>
      <ButtonWrapper>
        <FlexRows justify="space-around">
          <Button className="button is-fullwidth" onClick={() => setActiveFilterModal(true)}>
            <span className="icon">
              <FaFilter size={16} color={colors.coolGrey} />
            </span>
            <ButtonText>Filters {filterValue !== 0 && <Span>({filterValue})</Span>}</ButtonText>
          </Button>
          <Button className="button is-fullwidth" onClick={() => setActiveEventTypeModal(true)}>
            <span className="icon">
              <FaListUl size={16} color={colors.coolGrey} />
            </span>
            <ButtonText>
              Events Type{' '}
              {filterStateInputs.eventTypeInputs.length !== 0 && (
                <Span>({filterStateInputs.eventTypeInputs.length})</Span>
              )}
            </ButtonText>
          </Button>
          <Button className="button is-fullwidth" onClick={() => setActiveMap(!activeMap)}>
            <span className="icon">
              {activeMap ? (
                <FaListUl size={16} color={colors.coolGrey} />
              ) : (
                <FaMapMarkerAlt size={16} color={colors.coolGrey} />
              )}
            </span>
            <ButtonText>{activeMap ? 'List' : 'Map'}</ButtonText>
          </Button>
        </FlexRows>
      </ButtonWrapper>
    </>
  );
};

export default withRouter(MobileFilterContainer);
