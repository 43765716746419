import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isUndefined, capitalize } from 'lodash';
import { MdLocationOn } from 'react-icons/md';
import { FaListUl } from 'react-icons/fa';

import Filters from './components/desktop/Filters';
import ItemCard from './components/desktop/ItemCard';
import {
  Text,
  FlexRows,
  Button,
  Pagination,
  MapView,
  ScrollToTopButton,
  Pager,
} from '../../components/elements';
import Tags from './components/desktop/Tags';
import MenuCategoryTabs from './components/desktop/MenuCategoryTabs';
import emptyImage from '../../assets/images/empty.svg';

const Container = styled.div`
  padding-top: 2rem;
`;
// const TopWrapper = styled.div`
//   && {
//     padding: 0.5rem 1rem;
//     margin: 1rem 0rem;
//     /* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06); */
//   }
// `;

const TopWrapperView = styled.div`
  && {
    padding: 0.5rem 1rem;
    margin: 0rem 0rem;
    /* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06); */
  }
`;
const ItemWrapper = styled.div`
  && {
    padding: 0rem;
    border-radius: 4px;
    /* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06); */
  }
`;

const FilterWrapper = styled.div``;

const PaginationWrapper = styled.div`
  padding: 1rem;
`;

const getMapLocation = dta => {
  const data = dta.map(item => ({
    id: item.item_id,
    title: `${item.name}`,
    subtitle: item.place_name,
    subtitle1: `${item.address_line_1}, ${item.city}, ${item.state}`,
    imageObject: {
      object_type: ['ITEM'],
      object_id: item.item_id,
      links_to_id: [item.item_id],
      links_to_type: ['ITEM'],
    },
    latitude: parseFloat(item.latitude),
    longitude: parseFloat(item.longitude),
    type: 'ITEM',
  }));
  return data;
};

const DesktopView = ({
  data,
  socials,
  filterFuncInputs,
  filterStateInputs,
  currentLocation,
  handlePageClick,
}) => {
  const [items, setItems] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [mapActive, setMapActive] = useState(false);

  useEffect(() => {
    setItems(
      !isUndefined(data.search_items) && !isUndefined(data.search_items.item_listing)
        ? data.search_items.item_listing
        : [],
    );
    setTotalPage(!isUndefined(data.search_items) && data.search_items.total_pages);
  }, [data.search_items]);

  return (
    <Pager>
      <Container>
        <div>
          <Text className="is-capitalized" size="blarger" weight="semibold" paddingBottom={1}>
            Items Around {capitalize(currentLocation.city)}
          </Text>
        </div>
        {items.length === 0 && (
          <FlexRows>
            <div>
              <figure style={{ width: '40rem' }}>
                <img src={emptyImage} alt="empty" />
              </figure>
            </div>
          </FlexRows>
        )}
        {items.length !== 0 && (
          <>
            <MenuCategoryTabs />
            {/* <TopWrapper>
            <div className="columns is-gapless is-vcentered">
              <div className="column"><Sort /></div>
              <div className="column is-three-quarters" />
            </div>
          </TopWrapper> */}
            <FilterWrapper className="columns is-gapless ">
              <div className="column">
                <Filters filterFuncInputs={filterFuncInputs} />
              </div>
              <ItemWrapper className="column is-three-quarters">
                <TopWrapperView>
                  <FlexRows justify="space-between" align="center">
                    <Tags
                      filterStateInputs={filterStateInputs}
                      filterFuncInputs={filterFuncInputs}
                    />
                    <div>
                      <Button
                        height={0}
                        name={mapActive ? 'Show List View' : 'Show Map View'}
                        textProps={{
                          size: 'tiny',
                          color: 'darkGrey',
                        }}
                        onClick={() => setMapActive(!mapActive)}
                      >
                        {!mapActive ? <MdLocationOn size={20} /> : <FaListUl size={15} />}
                        &nbsp;&nbsp;
                      </Button>
                    </div>
                  </FlexRows>
                </TopWrapperView>

                {mapActive ? (
                  <MapView data={getMapLocation(items)} />
                ) : (
                  <>
                    <div className="columns is-multiline">
                      {items.map(item => (
                        <div className="column is-half" key={item.item_id}>
                          <ItemCard
                            item={item}
                            socials={socials.filter(edge => edge.object_id === item.item_id)}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <PaginationWrapper>
                  <Pagination
                    currentPage={currentPage}
                    pageCount={totalPage}
                    handlePageClick={value => {
                      handlePageClick(value);
                      setCurrentPage(value.selected + 1);
                    }}
                  />
                </PaginationWrapper>
              </ItemWrapper>
            </FilterWrapper>
          </>
        )}
        <ScrollToTopButton />
      </Container>
    </Pager>
  );
};

export default DesktopView;
