import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MdLocationOn } from 'react-icons/md';
import { FaListUl } from 'react-icons/fa';
import { animateScroll } from 'react-scroll';

import { isUndefined, capitalize } from 'lodash';
import MenuCategoryTabs from './components/desktop/MenuCategoryTabs';
// import Breadcrumb from './components/desktop/Breadcrumb';
import Filters from './components/desktop/Filters';
import PlaceCard from './components/desktop/PlaceCard';
import Sort from './components/desktop/Sort';
import {
  Text,
  FlexRows,
  Button,
  Pagination,
  MapView,
  ScrollToTopButton,
  Pager,
} from '../../components/elements';
import Tags from './components/desktop/Tags';
import emptyImage from '../../assets/images/empty.svg';

const Container = styled.div`
  padding-top: 2rem;
`;
const TopWrapper = styled.div`
  && {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    margin: 1rem 0rem;
    /* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06); */
  }
`;
const PlaceWrapper = styled.div`
  padding: 1rem;
  border-radius: 4px;
  /* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06); */
`;

const FilterWrapper = styled.div``;
const PaginationWrapper = styled.div`
  padding: 1rem;
`;

const getMapLocation = dta => {
  const data = dta.map(item => ({
    id: item.place_id,
    title: `${item.name}`,
    subtitle: `${item.address_line_1}, ${item.city}, ${item.state}`,
    imageObject: {
      object_id: item.place_id,
      object_type: ['PLACE'],
      parent_id: item.place_id,
      parent_type: ['PLACE'],
      links_to_id: [item.place_id],
      links_to_type: ['PLACE'],
    },
    latitude: parseFloat(item.latitude),
    longitude: parseFloat(item.longitude),
    type: 'PLACE',
  }));
  return data;
};

const DesktopView = ({
  // loading,
  data,
  deals,
  follows,
  filterStateInputs,
  filterFuncInputs,
  currentLocation,
  handlePageClick,
}) => {
  const [places, setPlaces] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [mapActive, setMapActive] = useState(false);
  const [socials, setSocials] = useState([]);

  useEffect(() => {
    setSocials(
      !isUndefined(data.search_social) && !isUndefined(data.search_social.social_aggregation)
        ? data.search_social.social_aggregation.total_social_per_object_id
        : [],
    );
    setPlaces(
      !isUndefined(data.search_places) && !isUndefined(data.search_places.place_listing)
        ? data.search_places.place_listing
        : [],
    );
    setTotalPage(!isUndefined(data.search_places) && data.search_places.total_pages);
    animateScroll.scrollToTop({ smooth: 'linear' });
  }, [data.search_places, data.search_social]);

  return (
    <Pager>
      <Container>
        <div>
          <Text className="is-capitalized" size="blarger" weight="semibold" paddingBottom={1}>
            Places Around {capitalize(currentLocation.city)}
          </Text>
        </div>
        {places.length === 0 && (
          <>
            <MenuCategoryTabs onClick={item => filterFuncInputs.handleMenuCategory(item)} />
            <br />
            <FlexRows>
              <figure style={{ width: '40rem' }}>
                <img src={emptyImage} alt="empty" />
              </figure>
            </FlexRows>
          </>
        )}
        {places.length !== 0 && (
          <>
            <MenuCategoryTabs onClick={item => filterFuncInputs.handleMenuCategory(item)} />
            <TopWrapper>
              <div className="columns is-gapless is-vcentered">
                <div className="column">
                  <Sort />
                </div>
                <div className="column is-three-quarters">
                  <FlexRows justify="space-between" align="center">
                    <Tags
                      filterStateInputs={filterStateInputs}
                      filterFuncInputs={filterFuncInputs}
                    />
                    <div>
                      <Button
                        height={0}
                        name={mapActive ? 'Show List View' : 'Show Map View'}
                        textProps={{
                          size: 'tiny',
                          color: 'darkGrey',
                        }}
                        onClick={() => setMapActive(!mapActive)}
                      >
                        {!mapActive ? <MdLocationOn size={20} /> : <FaListUl size={15} />}
                        &nbsp;&nbsp;
                      </Button>
                    </div>
                  </FlexRows>
                </div>
              </div>
            </TopWrapper>

            <FilterWrapper className="columns">
              <div className="column">
                <Filters filterFuncInputs={filterFuncInputs} />
              </div>
              <PlaceWrapper className="column is-three-quarters">
                {mapActive ? (
                  <MapView data={getMapLocation(places)} />
                ) : (
                  <>
                    {places.map(place => (
                      <PlaceCard
                        place={place}
                        key={place.place_id}
                        socials={socials.filter(item => item.object_id === place.place_id)}
                        follow={follows.filter(item => item.object_id === place.place_id)}
                        deal={deals.filter(item => item.name === place.place_id)}
                      />
                    ))}
                  </>
                )}
                <PaginationWrapper>
                  <Pagination
                    currentPage={currentPage}
                    pageCount={totalPage}
                    handlePageClick={value => {
                      handlePageClick(value);
                      setCurrentPage(value.selected + 1);
                    }}
                  />
                </PaginationWrapper>
              </PlaceWrapper>
            </FilterWrapper>
          </>
        )}
        <ScrollToTopButton />
      </Container>
    </Pager>
  );
};

export default DesktopView;
