import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { first, snakeCase } from 'lodash';
import moment from 'moment';
import { FieldArray } from 'formik';
import uuid from 'uuid';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { IoMdCloseCircle } from 'react-icons/io';
import ReactLoading from 'react-loading';

import { toast } from 'react-toastify';
import { upload_image_s3 } from '../../utils/s3';
import Text from './Text';
import Icon from './Icon';
import FlexColumns from './FlexColumns';

const DropZoneWrapper = styled.div`
  &&& {
    .box {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
`;

const ImageContainer = styled.div``;
const DragAndDropContainer = styled.div``;

const Figure = styled.figure`
  &&& {
    opacity: ${opacity => opacity};
    img {
      border-radius: 6px;
    }
  }
`;

const handleDeleteDisabled = image => {
  let isDisabled = false;
  image.forEach(element => {
    if (element.uploadingStatus === 'uploading') {
      isDisabled = true;
    }
  });
  return isDisabled;
};

const MyDropzone = ({
  item,
  s3UploadPath,
  onChange,
  metaData,
  handleSelectLocalFile,
  totalImageLength,
  onDeleteImage,
  imageDeleteDisabled,
  iconProps,
  textProps,
}) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const isLoggedIn = useStoreState(state => state.auth.isLoggedIn);

  const onDrop = useCallback(
    async acceptedFiles => {
      if (!isLoggedIn) {
        setIsActiveModal({ value: true, path: '' });
      } else {
        const file = acceptedFiles[0];
        let dimension;
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          dimension = { width: image.width, height: image.height };
        };
        const acceptImageList = ['image/jpeg', 'image/png', 'image/jpg'];
        const imageAcceptable =
          acceptedFiles.length !== 0 ? acceptImageList.filter(item1 => item1 === file.type) : [];
        if (imageAcceptable.length !== 0) {
          // if (file.size < 60000) {
          //   toast.error('Your Image Quality is not good. Please upload more than 67 KB');
          // } else {
          handleSelectLocalFile(image.src);
          const imageName = file.name.split('.');
          const name = `${snakeCase(first(imageName))}-${moment().format(
            'YYYYMMDD_hhmmss',
          )}.${file.type.substring(6)}`;

          const { Location: url } = await upload_image_s3(file, `${s3UploadPath}${name}`, metaData);
          const response = { path: name, url, dimension };
          onChange(response);
        }
      }
    },
    // },
    [handleSelectLocalFile, isLoggedIn, metaData, onChange, s3UploadPath, setIsActiveModal],
  );

  const { getRootProps, getInputProps, isDragActive, dropzoneReject } = useDropzone({
    accept: 'image/*',
    onDrop,
  });

  return (
    <DropZoneWrapper>
      {item.preview !== null && (
        <ImageContainer>
          <Figure
            className="image is-square"
            opacity={item.uploadingStatus === 'uploading' ? 0.5 : 1}
          >
            <img src={item.preview} alt="image-upload" style={{ borderRadius: '6px' }} />

            <div
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
              }}
            >
              {item.uploadingStatus === 'uploading' ? (
                <ReactLoading type="spinningBubbles" color="#00aeef" height={30} width={30} />
              ) : (
                <a
                  onClick={() =>
                    imageDeleteDisabled
                      ? toast.info('Please wait for uploading completion...')
                      : onDeleteImage()
                  }
                >
                  <span>
                    <IoMdCloseCircle color="#ffffff" size={25} />
                  </span>
                </a>
              )}
            </div>
          </Figure>
          {/* {item.uploadingStatus === 'uploading' && (
            <LoaderWrapper>
              <progress className="progress is-small is-success" max="100">
                15%
              </progress>
              <p className="is-size-2 has-text-weight-bold">Uploading....</p>
            </LoaderWrapper>
          )} */}
        </ImageContainer>
      )}

      {totalImageLength <= 3 && item.uploadingStatus === 'pending' && (
        <FlexColumns className="box" style={{ cursor: 'pointer' }} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive && (
            <DragAndDropContainer style={dropzoneReject && { backgroundColor: 'red' }}>
              <br />
              <br />
              <Text {...textProps}>Drag and drop the file here ...</Text>
              <br />
              <br />
            </DragAndDropContainer>
          )}
          {!isDragActive && (
            <>
              <Icon name="photoAddSolid" {...iconProps} />
              {textProps.isActive && <Text {...textProps}>Add Photo</Text>}
            </>
          )}
        </FlexColumns>
      )}
    </DropZoneWrapper>
  );
};

const UploadImage = ({
  totalNumberOfUploadedImage,
  s3UploadPath,
  metaData,
  setFieldValue,
  colWidth,
  iconProps,
  textProps,
}) => {
  return (
    <FieldArray
      name="image"
      render={arrayHelpers => {
        const totalImageLength =
          arrayHelpers.form.values.image.length + totalNumberOfUploadedImage - 1;
        return (
          <>
            {arrayHelpers.form.values &&
              arrayHelpers.form.values.image.map((item, index) => (
                <div className={`column is-${colWidth || 6}`}>
                  <MyDropzone
                    totalImageLength={totalImageLength}
                    item={item}
                    s3UploadPath={s3UploadPath}
                    metaData={metaData}
                    index={index}
                    handleChange={arrayHelpers.form.handleChange}
                    handleBlur={arrayHelpers.form.handleBlur}
                    setFieldValue={setFieldValue}
                    handleSelectLocalFile={preview => {
                      setFieldValue(`image.${index}.preview`, preview);
                      setFieldValue(`image.${index}.uploadingStatus`, 'uploading');
                      arrayHelpers.push({
                        id: uuid(),
                        url: '',
                        preview: null,
                        tooltip: '',
                        link: '',
                        tag: [],
                        uploadingStatus: 'pending',
                      });
                    }}
                    onChange={value => {
                      setFieldValue(`image.${index}.url`, value.url);
                      setFieldValue(`image.${index}.uploadingStatus`, 'uploaded');
                      setFieldValue(`image.${index}.width`, value.dimension.width);
                      setFieldValue(`image.${index}.height`, value.dimension.height);
                    }}
                    imageDeleteDisabled={handleDeleteDisabled(arrayHelpers.form.values.image)}
                    onDeleteImage={() => {
                      const existedImage = arrayHelpers.form.values.image.filter(
                        item2 => item2.id !== item.id,
                      );
                      setFieldValue('image', existedImage);
                    }}
                    iconProps={iconProps}
                    textProps={textProps}
                  />
                </div>
              ))}
          </>
        );
      }}
    />
  );
};

MyDropzone.defaultProps = {
  totalNumberOfUploadedImage: 1,
  s3UploadPath: 'dummy/',
  metaData: {},
  onChange: () => {},
  iconProps: { size: 6 },
  textProps: { isActive: true, color: 'darkGrey', size: 'small', weight: 'semibold' },
};

MyDropzone.propTypes = {
  s3UploadPath: PropTypes.string,
  metaData: PropTypes.object,
  onChange: PropTypes.func,
  iconProps: PropTypes.object,
  textProps: PropTypes.object,
  totalNumberOfUploadedImage: PropTypes.number,
};

export default UploadImage;
