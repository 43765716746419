import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed from 'react-pose';
import gql from 'graphql-tag';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withRouter } from 'react-router-dom';
import uuid from 'uuid';
import ReactLoading from 'react-loading';

import { Button, Text, Icon, FlexColumns } from './elements';
import client from '../utils/apolloClient';
import theme from '../theme';

const ButtonAnimation = posed.a({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const IconButton = styled(ButtonAnimation)`
  &&& {
    border: 0;
    ${({ paddingLess }) => paddingLess && `padding:0.2rem 0.5rem`};
    background-color: transparent;
  }
`;

const VerticalSpan = styled.span`
  padding-top: 5px;
`;

const followMutation = gql`
  mutation follow($input: ActionFollowInput) {
    action_follow(input: $input) {
      follow_id
      object_id
      object_type
      user_id
      type
      error {
        description
      }
    }
  }
`;

const FollowButtonAction = ({
  isActive,
  activeFollowId,
  objectId,
  objectType,
  isIconButton,
  defaultIcon,
  activeIcon,
  textStyle,
  iconStyle,
  paddingLess,
  onlyIcon,
  textProps,
  buttonProps,
  vertical,
}) => {
  const [active, setActive] = React.useState(isActive);
  const [followId, setFollowId] = React.useState(activeFollowId);
  const [loading, setLoading] = React.useState(false);
  //   console.log(active);

  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn, userId } = useStoreState(state => state.auth);

  useEffect(() => {
    setActive(isActive);
    setFollowId(activeFollowId);
  }, [activeFollowId, isActive]);

  const handleOnClickActive = () => {
    setLoading(true);
    client.clientPrivate
      .mutate({
        mutation: followMutation,
        variables: {
          input: {
            user_id: userId,
            object_id: objectId,
            object_type: objectType,
            type: active ? 'UNFOLLOW' : 'FOLLOW',
            follow_id: active ? followId : uuid(),
          },
        },
      })
      .then(({ data }) => {
        if (data) {
          setFollowId(data.action_follow.follow_id);
          setLoading(false);
          setActive(!active);
          // setActive(data.action_follow.type === 'FOLLOW');
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleOnClick = () => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
        callBackFunction: userId && handleOnClickActive,
      });
    } else {
      handleOnClickActive();
    }
  };

  if (userId === objectId) {
    return null;
  }

  return (
    <>
      {!vertical ? (
        <>
          {!isIconButton ? (
            <Button
              height={0}
              name={active ? 'Following' : 'Follow'}
              className="is-small"
              onClick={handleOnClick}
              textProps={{
                color: active ? 'white' : 'primaryColor',
                ...textProps,
              }}
              backgroundColor={active && 'primaryColor'}
              borderRadius={19}
              paddingLess
              {...buttonProps}
            >
              {loading && (
                <ReactLoading
                  type="spin"
                  color={active ? theme.colors.white : theme.colors.primaryColor}
                  height="14px"
                  width="14px"
                />
              )}
              &nbsp;&nbsp;
            </Button>
          ) : (
            <IconButton className="button" paddingLess={paddingLess} onClick={handleOnClick}>
              {defaultIcon && activeIcon && (
                <span className="icon">
                  {loading ? (
                    <ReactLoading
                      type="spin"
                      color={theme.colors.primaryColor}
                      height="14px"
                      width="14px"
                    />
                  ) : (
                    <Icon name={active ? activeIcon : defaultIcon} {...iconStyle} />
                  )}
                </span>
              )}
              {!onlyIcon && (
                <span>
                  <Text className="is-capitalized" {...textStyle}>
                    {active ? 'Following' : 'Follow'}
                  </Text>
                </span>
              )}
            </IconButton>
          )}
        </>
      ) : (
        <IconButton className="button" paddingLess={paddingLess} onClick={handleOnClick}>
          <FlexColumns>
            {defaultIcon && activeIcon && (
              <span className="icon">
                <Icon name={active ? activeIcon : defaultIcon} {...iconStyle} />
              </span>
            )}
            <VerticalSpan>
              <span>
                <Text className="is-uppercase" {...textStyle}>
                  {active ? 'Following' : 'Follow'}
                </Text>
              </span>
            </VerticalSpan>
          </FlexColumns>
        </IconButton>
      )}
    </>
  );
};

FollowButtonAction.defaultProps = {
  isActive: false,
  activeFollowId: '',
  vertical: false,
  isIconButton: false,
  onlyIcon: false,
  textProps: { size: 'tiny', weight: 'semibold' },
};

FollowButtonAction.propTypes = {
  activeFollowId: PropTypes.string,
  isActive: PropTypes.bool,
  vertical: PropTypes.bool,
  isIconButton: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  textProps: PropTypes.object,
};

export default withRouter(FollowButtonAction);
