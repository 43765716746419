import React, { useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';

import { FlexColumns, Text, Input, Button, InputErrorMessage } from '../../elements';

const ButtonStyle = styled.div`
  &&& {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

const ForgotPassForm = ({
  isLoading,
  handleSubmit,
  handleChange,
  handleBlur,
  setIsActiveForgotPassModal,
  setStep,
  step,
  apiError,
  errors,
  values,
  touched,
}) => {
  const [otpError, setOtpError] = useState(false);
  const [newPassError, setNewPassError] = useState(false);
  return (
    <form
      className="modal-card-body"
      onSubmit={event => {
        event.preventDefault();
        if (step === 2) {
          if (values.newPassword.length === 0) {
            setNewPassError(true);
          } else if (values.otp.length === 0) {
            setOtpError(true);
          } else {
            handleSubmit();
          }
        } else {
          handleSubmit();
        }
      }}
    >
      <ButtonStyle
        className="delete"
        label="close"
        onClick={() => {
          setIsActiveForgotPassModal({ value: false });
          setStep(1);
        }}
      />
      <FlexColumns align="flex-start">
        <div>
          <Text color="primaryColor" size="large" weight="semibold" paddingBottom={0.5}>
            Kravein
          </Text>
        </div>
        {step === 1 && (
          <div>
            <Text size="medium">Forgot your password?</Text>
            <Text color="darkGrey" size="tiny">
              Please enter your email address and we we&apos;ll send you email on how to reset your
              password
            </Text>
          </div>
        )}
        {step === 2 && (
          <Text color="darkGrey" size="small">
            Approve the Forgot Password Request email and then submit OTP sent on mail to proceed.
          </Text>
        )}
      </FlexColumns>
      <br />
      {step === 1 && (
        <div className="field">
          <label className="label is-size-3">Email Address</label>
          <div className="control">
            <Input
              className="input"
              type="text"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputErrorMessage errors={errors.email} touched={touched.email} />
          </div>
        </div>
      )}
      {step === 2 && (
        <>
          <div className="field">
            <label className="label">Enter your new password</label>
            <div className="control">
              <Input
                className="input"
                name="newPassword"
                type="password"
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {newPassError && (
                <p className="help is-danger is-size-4">New password is required!</p>
              )}
              <InputErrorMessage errors={errors.newPassword} touched={touched.newPassword} />
            </div>
          </div>
          <div className="field">
            <label className="label is-size-3">Enter the OTP below </label>
            <div className="control">
              <Input
                className="input"
                type="text"
                name="otp"
                placeholder="OTP"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {otpError && <p className="help is-danger is-size-4">OTP is required!</p>}
              <InputErrorMessage errors={errors.otp} touched={touched.otp} />
            </div>
          </div>
        </>
      )}
      {apiError && (
        <Text size="tiny" color="danger">
          {apiError}
        </Text>
      )}
      <br />
      <Button
        className={isLoading && 'is-loading'}
        type="submit"
        name={`${step === 1 ? 'Reset Password' : 'Update Password'}`}
        width={3}
        backgroundColor="primaryColor"
        textProps={{
          color: isLoading ? 'primaryColor' : 'white',
          weight: 'semibold',
          size: 'small',
        }}
        isFullWidth
      />
    </form>
  );
};

const MyEnhancedForm = withFormik({
  mapPropsToValues: () => ({
    email: '',
    newPassword: '',
    otp: '',
  }),

  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email('Enter valid email address!')
      .required('Email address is required!'),
    //   newPassword: yup.when([], {
    //     is: props.step === 2,
    //     then: yup.string().required('New password is required!'),
    //   }),
    //   otp: yup.string().when([], {
    //     is: props.step === 2,
    //     then: yup.string().required('OTP is required!'),
    //   }),
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'ForgotPassForm',
})(ForgotPassForm);

export default MyEnhancedForm;
