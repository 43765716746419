import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Image, Icon, Button } from '../../../../components/elements';
import { getEncodedImage } from '../../../../utils/s3';
import config from '../../../../utils/config';

const Figure = styled.div`
  && {
    img {
      border-radius: 4px;
      width: 230px;
      height: 230px;
    }
  }
`;

const EmptyWrapper = styled.div`
  &&& {
    position: relative;
    .img-opacity {
      opacity: 0.6;
    }
  }
`;

const AddPhotosButtonWrapper = styled.div`
  right: 1rem;
  position: absolute;
  bottom: 1rem;
  z-index: 2;
`;

const EmptyImage = () => (
  <EmptyWrapper>
    <Figure className="img-opacity">
      <Image source={config.dummyImage} />
    </Figure>
  </EmptyWrapper>
);

const ImageView = ({ place, image, history }) => {
  if (!image) {
    return <EmptyImage placeId={place.place_id} history={history} />;
  }

  return (
    <Figure>
      <Image source={getEncodedImage(image, 307, 307)} />
    </Figure>
  );
};

export default withRouter(ImageView);
