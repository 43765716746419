import React from 'react';
import styled from 'styled-components';
import { FlexRows, Text } from '../../../../components/elements';

const Container = styled.div`
  position: fixed;
  padding: 0rem 0.5rem;
  width: 100%;
  z-index: 700;
  background-color: #ffffff;
`;

const PageHeaderTabs = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding-bottom: 0.5rem;
`;
const Item = styled.a`
  padding: 0.2rem 0rem;
  border-bottom: 4px solid
    ${({ active, theme: { colors } }) => (active ? colors.primaryColor : 'transparent')};
`;

const PageHeader = ({ tab, setTab }) => (
  <Container>
    <PageHeaderTabs>
      <Item
        active={tab === 'info'}
        onClick={() => {
          setTab('info');
        }}
      >
        <Text className="is-uppercase" weight="semibold" size="tiny">
          info
        </Text>
      </Item>
      <Item
        active={tab === 'insights'}
        onClick={() => {
          setTab('insights');
        }}
      >
        <Text className="is-uppercase" weight="semibold" size="tiny">
          insights
        </Text>
      </Item>
      {/* <Item
        active={tab === 'menu'}
        onClick={() => {
          setTab('menu');
        }}
      >
        <Text className="is-uppercase" weight="semibold" size="tiny">
          menu
        </Text>
      </Item> */}
      <Item
        active={tab === 'events'}
        onClick={() => {
          setTab('events');
        }}
      >
        <Text className="is-uppercase" weight="semibold" size="tiny">
          events
        </Text>
      </Item>
      <Item
        active={tab === 'deals'}
        onClick={() => {
          setTab('deals');
        }}
      >
        <Text className="is-uppercase" weight="semibold" size="tiny">
          deals
        </Text>
      </Item>
    </PageHeaderTabs>
  </Container>
);

export default PageHeader;
