import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { isNull } from 'lodash';

import { Text } from '../../../../components/elements';
import client from '../../../../utils/apolloClient';
import FollowActionButton from './FollowActionButton';
import GetImages from '../../../../components/GetImages';
import { getFollowerCount, geReviewCount } from '../../../../utils/reusableQuery';

const UserContainer = styled.article`
  padding: 0rem 1rem;
  justify-content: center;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;
const fetchUserQuery = gql`
  query fetch_user($input: NavigateUserInput) {
    fetch_user(input: $input) {
      first_name
      last_name
      display_name
      color_code
    }
  }
`;
const User = ({ userId, followId, forWhichComponent }) => {
  const [user, setUser] = useState(null);
  const [followerCount, setFollowerCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  useEffect(() => {
    client.clientPrivate
      .query({
        query: fetchUserQuery,
        variables: { input: { user_id: userId } },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data && !isNull(data.fetch_user)) {
          setUser(data.fetch_user);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followId]);

  useEffect(() => {
    const fetData = async () => {
      const followerCountData = await getFollowerCount(userId);
      const reviewCountData = await geReviewCount(userId);
      setFollowerCount(followerCountData);
      setReviewCount(reviewCountData);
    };
    fetData();
  }, [userId]);

  if (isNull(user)) {
    return '';
  }
  return (
    <UserContainer className="media">
      <figure className="media-left">
        <p className="image is-64x64">
          <GetImages
            name={`${user.display_name}`}
            maskProps={{ width: 50, height: 50 }}
            variables={{
              object_type: ['USER_IMAGE'],
              object_id: userId,
            }}
            maskTextProps={{
              color: 'white',
              weight: 'bold',
              size: 'medium',
              letterSpacing: 'loose',
            }}
            borderRadius={32}
            randomColorCode={user.color_code}
          />
        </p>
      </figure>
      <div className="media-content">
        <div>
          <Text size="smaller" weight="semibold">
            {user.display_name}
          </Text>
          <Text color="darkGrey" size="micro">
            {followerCount} Followers, {reviewCount} Reviews
          </Text>
        </div>
      </div>
      <div className="media-right">
        <FollowActionButton
          objectId={userId}
          followId={followId}
          type={forWhichComponent === 'Follower' ? 'FOLLOW' : 'UNFOLLOW'}
        />
      </div>
    </UserContainer>
  );
};

export default User;
