import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MdPhone, MdLocationOn, MdEmail, MdWeb } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { useStoreState } from 'easy-peasy';

import { capitalize, isNull, first } from 'lodash';
import moment from 'moment';

import { Text, FlexRows, FlexColumns, GetDirections } from '../../../../components/elements';
import BookingButton from './BookingButton';
import colors from '../../../../theme/styles/colors';
import ImageView from './ImageView';

import { getZoneTime } from '../../../../utils/eventHelpers';
import { parseSocialPlace } from '../../../../utils/helpers';
import Social from './Social';

const Container = styled.div`
  margin: 0;
  &&& {
    .icon {
      justify-content: flex-start;
      width: 1.9rem;
    }
  }
`;
const FlexColumnStyle = styled(FlexColumns)`
  height: 85%;
`;

const IconActionButtonWrapper = styled.div`
  padding: 1rem 0rem;
`;

const AddressWrapper = styled(FlexRows)`
  padding-left: 0rem;
  padding-bottom: 0.2rem;
`;

const SpecialSpan = styled.span`
  background-color: ${({ theme: { colors: color } }) => color.lightGreyBeta};
  padding: 4px;
`;
const Wrapper = styled.div`
  padding: 2px 4px;
  padding-top: 0.1rem;
`;
const PhoneWrapper = styled.div`
  padding: 2px 4px;
  padding-top: 0.2px;
`;

const TopView = ({
  event,
  totalNumberOfTicket,
  placeEventOrganizeOnly,
  placeInactive,
  socials,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const [price, setPrice] = useState('');
  const [eventStartDate, setEventStartDate] = useState(event.start);
  const [eventEndDate, setEventEndDate] = useState(event.end);
  const [eventSaleStartDate, setEventSaleStartDate] = useState(event.ticket_sale_start);
  const [eventSaleEndDate, setEventSaleEndDate] = useState(event.ticket_sale_end);
  const [dateNow, setDateNow] = useState(event.ticket_sale_end);

  useEffect(() => {
    const startDate = getZoneTime(event.start, event.latitude, event.longitude);
    const endDate = getZoneTime(event.end, event.latitude, event.longitude);
    const currentDate = getZoneTime(new Date(), event.latitude, event.longitude);

    const fetData = () => {
      setEventStartDate(startDate);
      setEventEndDate(endDate);
      setDateNow(currentDate);
      if (event.ticket_sale_start) {
        const saleStartDate = getZoneTime(event.ticket_sale_start, event.latitude, event.longitude);
        setEventSaleStartDate(saleStartDate);
      }
      if (event.ticket_sale_end) {
        const saleEndDate = getZoneTime(event.ticket_sale_end, event.latitude, event.longitude);
        setEventSaleEndDate(saleEndDate);
      }
    };
    fetData();

    if (event.minimum_price === null && event.maximum_price === null) {
      setPrice('');
    } else if (event.minimum_price === 0 && event.maximum_price === 0) {
      setPrice(`Book Now: Free`);
    } else if (event.minimum_price === event.maximum_price) {
      setPrice(`AUD $${parseFloat(event.minimum_price).toFixed(2)}`);
    } else if (event.minimum_price !== 0 && event.maximum_price !== 0) {
      setPrice(
        `AUD $${parseFloat(event.minimum_price).toFixed(2)} - AUD $${parseFloat(
          event.maximum_price,
        ).toFixed(2)}`,
      );
    } else {
      setPrice(
        `AUD$ ${parseFloat(event.minimum_price).toFixed(2)} - AUD$ ${parseFloat(
          event.maximum_price,
        ).toFixed(2)}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const primaryEmail =
    event && !isNull(event.contact)
      ? event.contact.filter(
          item =>
            item.is_primary === true && item.display === true && item.type === 'email_primary',
        )
      : [];
  const primaryMobile =
    event && !isNull(event.contact)
      ? event.contact.filter(
          item =>
            item.is_primary === true && item.display === true && item.type === 'phone_primary',
        )
      : [];
  const website =
    event && !isNull(event.contact)
      ? event.contact.filter(
          item => item.is_primary === true && item.display === true && item.type === 'website',
        )
      : [];

  const socialArray = socials.length !== 0 ? first(socials).total_social_count : [];
  const social = parseSocialPlace(socialArray, userId);

  return (
    <Container>
      <div className="columns">
        <div className="column is-7">
          <ImageView image={event.default_image_url} />
          <div className="columns">
            <div className="column is-9">
              <IconActionButtonWrapper>
                <Social
                  event={event}
                  endDate={eventEndDate}
                  currentDate={dateNow}
                  objectId={event.event_id}
                  objectType="EVENT"
                  likeActive={social.like.active}
                  bookMarkActive={social.bookmark.active}
                  likeCountInt={social.like.count}
                  bookMarkCountInt={social.bookmark.count}
                  likeSocialIdS={social.like.id}
                  bookMarkSocialIdS={social.bookmark.id}
                />
              </IconActionButtonWrapper>
            </div>
          </div>
        </div>
        <div className="column is-5">
          <FlexColumnStyle justify="space-between" align="space-between">
            <div>
              <Text
                className="is-capitalized"
                size="larger"
                weight="semibold"
                paddingBottom={0.2}
                lineHeight="loosest"
                style={{ marginLeft: '0.2rem' }}
              >
                {capitalize(event.name)}
              </Text>
              <Text
                size="tiny"
                weight="semibold"
                color="dark"
                paddingBottom={0.2}
                style={{ marginLeft: '0.2rem' }}
              >{`${moment(eventStartDate).format('llll')} to ${
                moment(eventStartDate).format('L') === moment(eventEndDate).format('L')
                  ? moment(eventEndDate).format('LT')
                  : moment(eventEndDate).format('llll')
              }`}</Text>
              {(placeEventOrganizeOnly || placeInactive) && (
                <Text
                  size="small"
                  weight="semibold"
                  color="dark"
                  paddingBottom={0.3}
                  style={{ marginLeft: '0.3rem' }}
                >
                  {event.place_name}, {capitalize(event.venue_name)}
                </Text>
              )}
              {!placeEventOrganizeOnly && !placeInactive && (
                <Link to={`/place/${event.place_id}`}>
                  <Text
                    size="smedium"
                    weight="semibold"
                    color="dark"
                    paddingBottom={0.3}
                    style={{ marginLeft: '0.3rem' }}
                  >
                    {event.place_name}, {capitalize(event.venue_name)}
                  </Text>
                </Link>
              )}
              <div className="is-flex">
                <span className="icon">
                  <MdLocationOn
                    size={18}
                    color={colors.specialGrey}
                    paddingRight={0.5}
                    paddingBottom={0.1}
                  />
                </span>
                <AddressWrapper justify="flex-start" align="flex-end">
                  <Text size="small" color="darkGrey" paddingBottom={0.1}>
                    {event.address_line_1} <br /> {event.city}, {event.state} {event.post_code}
                    <GetDirections latitude={event.latitude} longitude={event.longitude} />
                  </Text>
                </AddressWrapper>
              </div>
              <div>
                {primaryMobile.length !== 0 && first(primaryMobile).value && (
                  <PhoneWrapper className="is-flex">
                    <span className="icon">
                      <MdPhone size={18} color={colors.specialGrey} />
                    </span>
                    <Text size="small" color="darkGrey">
                      {first(primaryMobile).value}
                    </Text>
                  </PhoneWrapper>
                )}
              </div>
              <div style={{ paddingTop: '0.1px' }}>
                {primaryEmail.length !== 0 && first(primaryEmail).value && (
                  <Wrapper className="is-flex">
                    <span className="icon">
                      <MdEmail size={18} color={colors.specialGrey} />
                    </span>
                    <Text size="small" color="darkGrey">
                      {first(primaryEmail).value}
                    </Text>
                  </Wrapper>
                )}
              </div>

              {website.length !== 0 && first(website).value && (
                <Wrapper className="is-flex">
                  <span className="icon">
                    <MdWeb size={18} color={colors.specialGrey} />
                  </span>
                  <a href={first(website).value} target="_blank" rel="noopener noreferrer">
                    <Text size="small" color="primaryColor">
                      Event Website
                    </Text>
                  </a>
                </Wrapper>
              )}
            </div>
            <div>
              <div>
                <Text
                  size="small"
                  color="darkGrey"
                  paddingBottom={0.5}
                  style={{ marginLeft: '0.3rem', paddingTop: '1.2rem' }}
                >
                  <SpecialSpan>
                    {event.event_type &&
                      event.event_type.length !== 0 &&
                      first(event.event_type).description}
                    {event.event_category && event.event_category.length !== 0 && ' | '}
                    {event.event_category &&
                      event.event_category.length !== 0 &&
                      event.event_category.map(
                        (item, index) =>
                          `${item.description}${
                            index !== event.event_category.length - 1 ? ', ' : ''
                          }`,
                      )}
                  </SpecialSpan>{' '}
                  {event.restriction.length !== 0 && (
                    <SpecialSpan>
                      {event.restriction.length === 4
                        ? 'All Ages'
                        : event.restriction.map(
                            (item, index) =>
                              `${item.description}${
                                index !== event.restriction.length - 1 ? ', ' : ''
                              }`,
                          )}
                    </SpecialSpan>
                  )}
                </Text>
              </div>
              {event.ticket_sale_start && totalNumberOfTicket !== 0 && (
                <div style={{ marginLeft: '0.3rem' }}>
                  <Text size="tiny" color="darkGrey" paddingBottom={2}>
                    Tickets available from {moment(eventSaleStartDate).format('llll')}
                    {moment(eventSaleEndDate).isAfter(eventSaleStartDate) &&
                      ` to ${moment(eventSaleEndDate).format('llll')}`}
                  </Text>
                </div>
              )}
              <FlexRows justify="space-between">
                <div style={{ marginLeft: '0.3rem' }}>
                  <Text size="tiny" color="darkGrey" paddingBottom={0.2}>
                    {price}
                  </Text>
                </div>
                <div>
                  {first(event.listing_type) === 'public_tickets' && (
                    <BookingButton event={event} disabled={totalNumberOfTicket === 0} />
                  )}
                </div>
              </FlexRows>
            </div>
          </FlexColumnStyle>
        </div>
      </div>
    </Container>
  );
};

export default TopView;
