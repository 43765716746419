import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { first, isNull, forEach } from 'lodash';
import moment from 'moment';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import { Text, FlexRows, Button, FlexColumns } from '../../../components/elements';
import colors from '../../../theme/styles/colors';

const TicketWrapper = styled(FlexRows)`
  padding: 1rem;
  border-bottom: 2px solid ${colors.lightGreyTheta};
`;
const IconWrapper = styled.span`
  padding: 0rem 1.5rem;
`;
// const LevelWrapper = styled.div`
//   padding: 0rem 1rem;
// `;

// const Flex = styled.div`
//   padding-left: 1rem;
// `;

const StatusWrapper = styled.span`
  background: #c7c6c6;
  padding: 6px 17px;
  width: auto;
`;

const ButtonWrapper = styled(FlexRows)`
  padding: 1rem;
`;

const StatusQuantityHandler = ({
  ticket,
  ticketBookingFee,
  ticketSelections,
  handleTicketSelection,
  remainingTickets,
  displayRemainingTicket,
}) => {
  const filterTicketSelection = ticketSelections.filter(item => item.id === ticket.ticket_id);
  const quantity = filterTicketSelection.length !== 0 ? first(filterTicketSelection).quantity : 0;
  const [noOfSelectedTicket, setNoOfSelectedTicket] = useState(quantity);

  console.log('tickets', ticket);

  const onChange = value => {
    const ticketInput = {
      id: ticket.ticket_id,
      name: ticket.name,
      type: ticket.type,
      price: ticket.price,
      totalTicketPrice: parseFloat(ticket.price) * parseFloat(value),
      quantity: parseInt(value, 10),
      ticketBookingFee,
      attributes: ticket.attribute,
    };

    handleTicketSelection(ticketInput);
    setNoOfSelectedTicket(parseInt(value, 10));
  };

  if (ticket.status === 'HOLD') {
    return <StatusWrapper>HOLD</StatusWrapper>;
  }
  if (ticket.status === 'HIDDEN') {
    return <StatusWrapper>HIDDEN</StatusWrapper>;
  }

  if (remainingTickets <= 0) {
    return <StatusWrapper>SOLD OUT</StatusWrapper>;
  }
  if (moment(ticket.sale_start).isAfter(new Date())) {
    return <StatusWrapper>SALE NOT STARTED</StatusWrapper>;
  }

  if (moment(ticket.sale_end).isBefore(new Date())) {
    return <StatusWrapper>SALES ENDED</StatusWrapper>;
  }
  // console.log('ticket', display_remaining_ticket);
  return (
    <FlexColumns>
      <div className="is-flex">
        <a
          onClick={() => {
            if (noOfSelectedTicket !== 0 && noOfSelectedTicket > ticket.minimum) {
              onChange(noOfSelectedTicket - 1);
            } else if (noOfSelectedTicket !== 0) {
              onChange(noOfSelectedTicket - ticket.minimum);
            }
          }}
        >
          <IconWrapper>
            <FiMinusCircle
              size={30}
              color={noOfSelectedTicket === 0 ? colors.specialGrey : colors.primaryColor}
            />
          </IconWrapper>
        </a>
        <div style={{ paddingTop: '4px' }}>
          <Text size="medium" color="darkGrey" weight="semibold">
            {noOfSelectedTicket}
          </Text>
        </div>
        <a
          onClick={() => {
            if (
              noOfSelectedTicket !== remainingTickets &&
              noOfSelectedTicket !== ticket.maximum &&
              noOfSelectedTicket >= ticket.minimum
            ) {
              onChange(noOfSelectedTicket + 1);
            } else if (
              noOfSelectedTicket !== remainingTickets &&
              noOfSelectedTicket !== ticket.maximum
            ) {
              onChange(noOfSelectedTicket + ticket.minimum);
            }
          }}
        >
          <IconWrapper>
            <FiPlusCircle
              size={30}
              type="button"
              id="AddTicket_Event"
              color={
                noOfSelectedTicket === remainingTickets || noOfSelectedTicket === ticket.maximum
                  ? colors.specialGrey
                  : colors.primaryColor
              }
            />
          </IconWrapper>
        </a>
      </div>
      {displayRemainingTicket && (
        <Text size="micro" color="darkGrey">
          {remainingTickets} Remaining
        </Text>
      )}
    </FlexColumns>
  );
};

const TicketSelection = ({
  event,
  occurrence,
  tickets,
  setSubTotalPrice,
  totalQuantity,
  setTotalQuantity,
  ticketSelections,
  setTicketSelections,
  setBookingFee,
  isCouponApplied,
  onHandleConfirmBooking,
  eventBookingFee,
  setBookingFeeOfEvent,
  setCheckSelectionHaveAttendeeDetailsOrNot,
  checkSelectionHaveAttendeeDetailsOrNot,
  setStoreOfTicketPlusAttendee,
  clearAppliedCoupon,
  history,
}) => {
  const handleTicketSelection = async ticket => {
    let newTickets = [];
    const removeExisted = await ticketSelections.filter(item => item.id !== ticket.id);
    newTickets = ticket.quantity !== 0 ? removeExisted.concat(ticket) : removeExisted;
    await setTicketSelections(newTickets);
    let newPrice = 0;
    await newTickets.map(item => {
      newPrice += item.totalTicketPrice;
      return {};
    });
    let newQuantity = 0;
    await newTickets.map(item => {
      newQuantity += parseFloat(item.quantity);
      return {};
    });
    await setSubTotalPrice(newPrice);
    await setTotalQuantity(newQuantity);
    let newBookingFee = 0;
    await newTickets.map(item => {
      newBookingFee += item.ticketBookingFee * item.quantity;
      return {};
    });

    setBookingFee(newBookingFee);
    const checkingFreeOnlyOrPaidWithFee = [];
    await forEach(newTickets, item => {
      if (item.type === 'paid') {
        checkingFreeOnlyOrPaidWithFee.push(item);
      }
    });
    if (checkingFreeOnlyOrPaidWithFee.length === 0) {
      setBookingFeeOfEvent(0);
    } else {
      setBookingFeeOfEvent(eventBookingFee);
    }
    setCheckSelectionHaveAttendeeDetailsOrNot(false);
    forEach(newTickets, item => {
      if (item.attributes.length !== 0) {
        setCheckSelectionHaveAttendeeDetailsOrNot(true);
      }
    });
    setStoreOfTicketPlusAttendee();
    clearAppliedCoupon();
  };

  return (
    <>
      {tickets.map(ticket => {
        const checkBookingFee = ticket.fee.filter(item => item.name === 'online_passon_fee');
        const ticketBookingFee = checkBookingFee.length !== 0 ? first(checkBookingFee).value : 0;
        return (
          <TicketWrapper justify="space-between" align="center">
            <div>
              <Text className="is-capitalized" size="tiny" weight="semibold" color="dark">
                {ticket.name}
              </Text>
              <Text size="tiny" color="darkGrey">
                ${parseFloat(ticket.price).toFixed(2)}{' '}
                {ticketBookingFee !== 0 && `(+ $${ticketBookingFee} Fee)`}
              </Text>
              <Text size="tiny" color="darkGrey" paddingBottom={0.5}>
                Purchase between {ticket.minimum} and {ticket.maximum} ticket(s)
              </Text>
              <Text size="tiny" color="darkGrey">
                Sale start from {moment(ticket.sale_start).format('LLLL')} to{' '}
                {moment(ticket.sale_start).format('L') !==
                moment(isNull(ticket.sale_end) ? event.end : ticket.sale_end).format('L')
                  ? moment(isNull(ticket.sale_end) ? event.end : ticket.sale_end).format('LLLL')
                  : moment(isNull(ticket.sale_end) ? event.end : ticket.sale_end).format('LT')}
              </Text>
            </div>
            <div>
              <StatusQuantityHandler
                ticket={ticket}
                displayRemainingTicket={occurrence.display_remaining_ticket}
                ticketSelections={ticketSelections}
                handleTicketSelection={handleTicketSelection}
                ticketBookingFee={ticketBookingFee}
                remainingTickets={ticket.quantity - ticket.sold}
                isCouponApplied={isCouponApplied}
              />
            </div>
          </TicketWrapper>
        );
      })}
      <ButtonWrapper justify="space-between" align="flex-start">
        <div>
          <Button
            name="Back"
            backgroundColor="primaryColor"
            textProps={{ color: 'white', weight: 'semibold', size: 'small' }}
            onClick={() => history.goBack()}
          />
        </div>
        {/* <div>
          {totalQuantity !== 0 && (
            <Button
              name={checkSelectionHaveAttendeeDetailsOrNot ? 'Confirm Booking' : 'Checkout'}
              backgroundColor="primaryColor"
              textProps={{ color: 'white', weight: 'semibold', size: 'small' }}
              onClick={onHandleConfirmBooking}
            />
          )}
        </div> */}
      </ButtonWrapper>
    </>
  );
};

export default withRouter(TicketSelection);
