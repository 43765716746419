import React, { useState } from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { Modal, Icon, Text, FlexRows, Slider } from '../../../../components/elements';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
  &&& {
    .column {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const ModalFooter = styled.a`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 100;
  width: 100%;
  padding: 0.5rem;
  position: fixed;
  bottom: 0;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const Flex = styled(FlexRows)`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.specialGrey};
`;

const SliderWrapperStyle = styled.div`
  padding: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.specialGrey};
`;

const Item = ({ title, isIconActive, onSelect, children }) => (
  <a onClick={onSelect}>
    <Flex justify="space-between">
      <div>
        <Text>{title}</Text>
      </div>
      <div className="is-flex">
        {isIconActive && (
          <>
            <Icon iconName="fas fa-check" color="primaryColor" />
          </>
        )}
      </div>
      {children}
    </Flex>
  </a>
);

const SliderWrapper = ({ title, value, setValue }) => (
  <SliderWrapperStyle>
    <Text paddingBottom={1.5}>
      {title} ({value})
    </Text>
    <div>
      <Slider
        onChange={data => {
          setValue(`${data}km`);
        }}
      />
    </div>
  </SliderWrapperStyle>
);

const SortByModal = ({ isActive, setActiveSortByModal }) => {
  const sortBy = useStoreState(state => state.event.sortBy);
  const updateSortBy = useStoreActions(state => state.event.updateSortBy);
  const distance = useStoreState(state => state.event.distance);
  const updateDistance = useStoreActions(state => state.event.updateDistance);
  const [sort, setSort] = useState(sortBy);
  const [distanceValue, setDistanceValue] = useState(distance);

  const setToMainFilter = () => {
    setActiveSortByModal(false);
    updateSortBy(sort);
    updateDistance(distanceValue);
  };

  return (
    <Modal isActive={isActive}>
      <ModalHeader>
        <div className="columns is-mobile">
          <div className="column is-2">
            <a
              onClick={() => {
                setActiveSortByModal(false);
              }}
            >
              <Icon color="white" size="large" />
            </a>
          </div>
          <div className="column">
            <Text className="is-uppercase" align="center" color="white" weight="bold" size="small">
              Sort By
            </Text>
          </div>
          <div className="column is-4">
            <Text className="is-uppercase" color="white" weight="semibold" size="smaller">
              Clear All
            </Text>
          </div>
        </div>
      </ModalHeader>
      <Content>
        <SliderWrapper title="Distance" value={distanceValue} setValue={setDistanceValue} />
        <Item
          title="Price Low to High"
          isIconActive={sort === 'price_low_to_high'}
          onSelect={() => setSort('price_low_to_high')}
        />
        <Item
          title="Price High to Low"
          isIconActive={sort === 'price_high_to_low'}
          onSelect={() => setSort('price_high_to_low')}
        />
      </Content>
      <ModalFooter onClick={setToMainFilter}>
        <Text className="is-uppercase" color="white" align="center" weight="bold" size="small">
          Filter
        </Text>
      </ModalFooter>
    </Modal>
  );
};

export default SortByModal;
