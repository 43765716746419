import React from 'react';
import styled from 'styled-components';

import Icon from './Icon';
import FlexRows from './FlexRows';
import Flex from './Flex';

const IconWrapper = styled(Flex)`
  padding-right: 5px;
  padding-top: 2px;
`;

const IconProvider = ({ iconName, iconSize, children }) => (
  <FlexRows justify="flex-start" align="flex-start">
    <IconWrapper>
      <Icon name={iconName} size={iconSize} />
    </IconWrapper>
    {children}
  </FlexRows>
);

export default IconProvider;
