import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { omit } from 'lodash';
import React from 'react';
import colors from '../../../../theme/styles/colors';

import DeliveryAddress from '../../../Menu/components/desktop/DeliveryAddress';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 30rem;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    }
    .modal-card-head {
      padding: 1rem 2rem;
      padding-bottom: 0.6rem;
      background-color: rgba(255, 255, 255, 1);
      border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
    }
    .modal-card-body {
      padding: 2rem;
      padding-top: 0rem;
    }
    a {
      color: ${colors.primaryColor};
    }
  }
`;

const AddNewAddressModal = ({
  setAddressLine,
  state,
  place,
  func,
  isActiveModal,
  setIsActiveModal,
}) => {
  return (
    <Container className={`modal ${isActiveModal && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <DeliveryAddress
          userId={state.userId}
          placeId={place.place_id}
          placeName={place.name}
          userAddress={state.userAddress}
          onBack={() => setIsActiveModal(false)}
          updateUserDelivery={func.updateUserAddress}
          deliveryAddress={state.deliveryAddress}
          handleDeliverAddress={value => {
            setAddressLine('address_line_1', value.address_line_1);
            func.handleDeliveryAddress(omit(value, ['type', `__typename`, 'error', 'location']));
          }}
          isCheckoutPage
        />
      </div>
    </Container>
  );
};

export default withRouter(AddNewAddressModal);
