import React from 'react';
import gql from 'graphql-tag';
import qs from 'querystringify';
import { capitalize } from 'lodash';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { AutoCompleteInput, Text, InputErrorMessage } from '../../../../components/elements';
import client from '../../../../utils/apolloClient';

const Container = styled.div`
  &&& {
    .react-autosuggest__container {
      position: relative;
    }
    .react-autosuggest__suggestions-container {
      display: none;
    }
    .react-autosuggest__suggestions-container--open {
      max-height: 15rem;
      overflow-y: scroll;
      display: block;
      position: absolute;
      width: 100%;
      background: #fff;
      border: 1px solid #f6f6f6;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
      z-index: 2000;
    }
    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 5px 10px;
      border-bottom: 1px solid #f6f6f6 !important;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: #f6f6f6;
    }
  }
`;

const Span = styled.span`
  color: red;
`;

const Label = styled(Text).attrs({ color: 'darkGrey', size: 'medium', weight: 'semibold' })``;

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_places(input: $input) {
      place_listing {
        place_id
        name
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
      }
    }
  }
`;
const fetchPlaceQuery = gql`
  query fetch_place($input: NavigatePlaceInput) {
    fetch_place(input: $input) {
      place_id
      name
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      timezone
    }
  }
`;

const renderLocationSuggestion = location => {
  if (location.type) {
    return (
      <div className="is-flex">
        <Text color="darkGrey" size="tiny">
          {location.text}
        </Text>
        <Text color="primaryColor" size="tiny" weight="bold">
          {location.query}
        </Text>
      </div>
    );
  }
  return (
    <div>
      <Text color="primaryColor" size="tiny" weight="bold">
        {location.name}
      </Text>
      <Text color="darkGrey" size="micro">
        {location.address_line_1}, {location.city}, {location.state}, {location.post_code}
      </Text>
    </div>
  );
};
const getSuggestionValue = suggestion => suggestion.suburbName;

class PlaceSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: '',
      suggestions: [],
      loading: false,
    };
  }

  onCreateNewPlace = place => {
    this.setState({
      location: place.name,
    });
  };

  componentDidMount = () => {
    const params = qs.parse(this.props.location.search);
    if (params.place_id) {
      this.setState({ loading: true });
      const { onHandlePlaceSelection } = this.props;
      client.clientPublic
        .query({
          query: fetchPlaceQuery,
          variables: { input: { place_id: params.place_id } },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          const { fetch_place } = data;
          onHandlePlaceSelection(fetch_place);
          this.setState({
            location: `${capitalize(fetch_place.name)}`,
          });
          this.setState({ loading: false });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  componentDidUpdate = prevProps => {
    const { placeAction, place } = this.props;
    if (prevProps.place !== place && placeAction === 'new') {
      this.onCreateNewPlace(place);
    }
  };

  setSuburbSuggestions = suggestions => {
    this.setState({ suggestions });
  };

  onChange = (event, { newValue, method }) => {
    if (method === 'type') {
      this.setState({
        location: newValue,
      });
    }
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { onHandleModalActive, onHandlePlaceSelection } = this.props;
    if (suggestion.type) {
      onHandleModalActive(suggestion.query);
    } else {
      onHandlePlaceSelection(suggestion);
      this.setState({
        location: `${capitalize(suggestion.name)}`,
      });
    }
  };

  onBlur = (event, { highlightedSuggestion }) => {
    console.log('onBlur', highlightedSuggestion);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    client.clientPublic
      .query({
        query: placesQuery,
        variables: {
          input: {
            search_criteria: value,
            filter: {
              place_filter: {
                listing_status: 'APPROVED', // APPROVED,UNLISTED Item Review Place True
                // item_review_place: true, //
                event_business: false,
                status: 'ACTIVE',
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const addPlaceData = [
          {
            type: 'custom',
            text: 'Would you like to add this place?',
            query: value,
          },
        ];
        const customData = addPlaceData.concat(data.search_places.place_listing);
        this.setSuburbSuggestions(customData);
        // console.log('data', data);
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { location, suggestions, loading } = this.state;
    const { touched, errors, haveInitialPlace } = this.props;
    if (haveInitialPlace) {
      return null;
    }

    return (
      <div className="columns is-gapless is-vcentered">
        <div className="column">
          <Label>
            Where was it ?<Span>*</Span>
          </Label>
        </div>
        <div className="column ">
          <Container>
            <div className={`control ${loading && 'is-loading'}`}>
              <AutoCompleteInput
                value={location}
                placeholder="Name of the place. eg. Supernormal Canteen.."
                // leftIcon={<GoLocation size={18} />}
                // rightIcon={<MdMyLocation size={18} />}
                enableInputWithAllElements
                onChange={this.onChange}
                onBlur={this.onBlur}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderLocationSuggestion}
                onSuggestionSelected={this.onSuggestionSelected}
                disabled={loading}
              />
            </div>
          </Container>
          <InputErrorMessage touched={touched.placeData} errors={errors.placeData} />
          <br />
        </div>
      </div>
    );
  }
}

export default withRouter(PlaceSearchInput);
