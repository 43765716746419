/* eslint-disable max-len */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { replace } from 'lodash';
import { toast } from 'react-toastify';
import { getIn, Field } from 'formik';

import { Input, ReactSelect, MobileInput, Text } from '../../../components/elements';

const Span = styled.span`
  &&& {
    color: red;
  }
`;

const Toast = ({ error, error2 }) => {
  useEffect(() => {
    toast.error(error);
  }, [error]);
  return <div className="has-text-danger	is-size-5">{error2}</div>;
};

const ErrorMessage = ({ name, message, ticketName }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? (
        <Toast error={`${message} ${error} in ${ticketName}`} error2={error} />
      ) : null;
    }}
  />
);

const AttendeeInputs = ({
  attributes,
  ticketIndex,
  attendeeIndex,
  handleChange,
  handleBlur,
  setFieldValue,
  ticketName,
}) => (
  <>
    <div className="columns is-multiline is-vcentered is-8">
      {attributes.map((item, index) => {
        if (item.name === 'name') {
          return (
            <div className="column is-half">
              <div className="columns">
                <div className="column is-4">
                  <Text color="darkGrey" size="small">
                    Attendee Name{item.required && <Span color="has-text-danger">*</Span>}
                  </Text>
                </div>
                <div className="column">
                  <Input
                    name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                    value={item.values}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                    message="Attendee Name is"
                    ticketName={ticketName}
                  />
                </div>
              </div>
            </div>
          );
        }
        if (item.name === 'mobile') {
          return (
            <div className="column is-half">
              <div className="columns">
                <div className="column is-4">
                  <Text color="darkGrey" size="small">
                    Mobile Number{item.required && <Span color="has-text-danger">*</Span>}
                  </Text>
                </div>
                <div className="column">
                  <MobileInput
                    value={item.values}
                    onChange={value =>
                      setFieldValue(
                        `ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`,
                        replace(value, ' ', ''),
                      )
                    }
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                    message="Attendee Mobile Number is"
                    ticketName={ticketName}
                  />
                </div>
              </div>
            </div>
          );
        }
        if (item.name === 'email') {
          return (
            <div className="column is-half">
              <div className="columns">
                <div className="column is-4">
                  <Text color="darkGrey" size="small">
                    Email Address{item.required && <Span color="has-text-danger">*</Span>}
                  </Text>
                </div>
                <div className="column">
                  <Input
                    label="Email Address"
                    name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                    value={item.values}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                    message="Attendee Email Address is"
                    ticketName={ticketName}
                  />
                </div>
              </div>
            </div>
          );
        }
        if (item.name === 'gender') {
          return (
            <div className="column is-half">
              <div className="columns">
                <div className="column is-4">
                  <Text color="darkGrey" size="small">
                    Gender{item.required && <Span color="has-text-danger">*</Span>}
                  </Text>
                </div>
                <div className="column">
                  <ReactSelect
                    options={[
                      { value: 'MALE', label: 'MALE' },
                      { value: 'FEMALE', label: 'FEMALE' },
                      { value: 'OTHER', label: 'OTHER' },
                    ]}
                    value={[{ value: item.values, label: item.values }]}
                    onChange={value =>
                      setFieldValue(
                        `ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`,
                        value.value,
                      )
                    }
                    required={item.required}
                  />
                  <ErrorMessage
                    name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                    message="Attendee Gender is"
                    ticketName={ticketName}
                  />
                </div>
              </div>
            </div>
          );
        }
        if (item.name === 'age') {
          return (
            <div className="column is-half">
              <div className="columns">
                <div className="column is-4">
                  <Text color="darkGrey" size="small">
                    Age{item.required && <Span color="has-text-danger">*</Span>}
                  </Text>
                </div>
                <div className="column">
                  <Input
                    type="number"
                    required={item.required}
                    name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                    value={item.values}
                    onChange={event => {
                      const value = parseFloat(event.target.value).toFixed(0);

                      if (
                        (value <= 120 && value > 0 && event.target.value !== 'e') ||
                        event.target.value === ''
                      ) {
                        setFieldValue(
                          `ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`,
                          value,
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    min="1"
                    max="120"
                  />
                  <ErrorMessage
                    name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                    message="Attendee Age is"
                    ticketName={ticketName}
                  />
                </div>
              </div>
            </div>
          );
        }
        return (
          <div>
            <p className="is-size-4 has-text-weight-semibold">No Additional data required</p>
          </div>
        );
      })}
    </div>
  </>
);

export default AttendeeInputs;
