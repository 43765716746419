import React, { useEffect, useState, useCallback } from 'react';
import gql from 'graphql-tag';
import queryString from 'query-string';
import { capitalize } from 'lodash';
import { Query } from 'react-apollo';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';

import Layout from '../../components/global/MenuLayout';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import LoadingOverlay from '../../components/loader/LoadingOverlay';

import { getZoneTime } from '../../utils/eventHelpers';
import { isDesktop } from '../../utils/helpers';
import config from '../../utils/config';
import Loader from './Loader';

const searchPlacesQuery = gql`
  query searchPlaceQuery($input: SearchInput) {
    search_food_orders(input: $input) {
      total_pages
      total_size
      place_and_service_type_listing {
        service_type_setting {
          service_type
          service_type_setting_id
          preparation_estimate
          added_estimate
          image_url
          menu_hour {
            day
            option {
              type
              start
              end
              start_in_sec
              end_in_sec
            }
          }
        }
        place {
          place_id
          slug
          status
          price_range
          tagline
          keywords
          distance
          voucher_list {
            code
            description
          }
          business_type {
            name
            description
          }
          service_category {
            name
            description
          }
          service_type {
            name
            description
          }
          cuisine_style {
            name
            description
          }
          item_type {
            name
            description
          }
          feature {
            name
            description
          }
          dress_code {
            name
            description
          }
          dietary_information {
            name
            description
          }
          dining_style {
            name
            description
          }
          public_transport {
            name
            description
          }
          ambience {
            name
            description
          }
          trading_name
          like_percentage
          display_order
          featured
          name
          description
          tagline
          tag
          slug
          voucher_count
          address_line_1
          address_line_2
          city
          state
          country
          post_code
          latitude
          longitude
          timezone
          default_image_url
          business_hour {
            day
            option {
              type
              start
              end
              start_in_sec
              end_in_sec
            }
          }
        }
      }
      place_aggregation {
        aggregation_name
        aggregation_listing {
          name
          description
          count
        }
      }
    }

    search_social(input: { filter: { social_filter: { object_type: "PLACE" } } }) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const View = ({
  data,
  loading,
  filterStateInputs,
  filterFuncInputs,
  currentLocation,
  handlePageClick,
  handleMobileFilter,
  reFetch,
  currentDate,
  fetchMoreInMobile,
}) => {
  const isMobile = !isDesktop();
  const [follows, setFollows] = useState([]);
  const [deals, setDeals] = useState([]);
  const updateFilterTags = useStoreActions(state => state.place.updateFilterTags);
  const { isLoggedIn, userId } = useStoreState(state => state.auth);

  useEffect(() => {
    if (data && data.search_food_orders) {
      updateFilterTags(data.search_food_orders.place_aggregation);
    }
  }, [data, updateFilterTags]);

  return (
    <>
      {isMobile ? (
        <div className="is-hidden-desktop">
          <MobileView
            data={data}
            deals={deals}
            follows={follows}
            loading={loading}
            handleMobileFilter={handleMobileFilter}
            filterFuncInputs={filterFuncInputs}
            fetchMoreInMobile={fetchMoreInMobile}
          />
        </div>
      ) : (
        <div className="is-hidden-touch">
          {loading && <LoadingOverlay isActive text="" content="" />}
          <DesktopView
            data={data}
            follows={follows}
            loading={loading}
            deals={deals}
            filterStateInputs={filterStateInputs}
            filterFuncInputs={filterFuncInputs}
            currentLocation={currentLocation}
            handlePageClick={handlePageClick}
          />
        </div>
      )}
    </>
  );
};

const totalPlaceSize = 20;

const Menus = ({ history, match, location }) => {
  const routeData = queryString.parse(location.search);
  const serviceType = capitalize(routeData.service_type) || 'Pickup';
  const currentLocation = useStoreState(state => state.global.usedLocation);
  const fetchProductTypes = useStoreActions(state => state.place.fetchProductTypes);
  const sort = useStoreState(state => state.place.sort);
  const filterInputs = useStoreState(state => state.place.filterInputs);
  const updateFilterInputs = useStoreActions(state => state.place.updateFilterInputs);
  const [category, setCategory] = useState(filterInputs.productType);
  const [cuisineInputs, setCuisineInputs] = useState(filterInputs.cuisines);
  const [itemTypeInputs, setItemTypeInputs] = useState(filterInputs.itemTypes);
  const [businessTypeInputs, setBusinessTypeInputs] = useState(filterInputs.businessTypes);
  const [featureInputs, setFeatureInputs] = useState(filterInputs.features);
  const [dressCodeInputs, setDressCodeInputs] = useState(filterInputs.dressCodes);
  const [diningStyleInputs, setDiningStyleInputs] = useState(filterInputs.diningStyles);
  const [publicTransportInputs, setPublicTransportInputs] = useState(filterInputs.publicTransports);
  const [ambienceInputs, setAmbienceInputs] = useState(filterInputs.ambiences);
  const [priceRangeInputs, setPriceRangeInputs] = useState(
    filterInputs.priceRanges ? filterInputs.priceRanges : [],
  );
  const [placeSearchQuery, setPlaceSearchQuery] = useState('');

  const [cursor, setCursor] = useState(0);
  const [currentDate, setCurrentDate] = useState(moment());

  useEffect(() => {
    fetchProductTypes();
    setCurrentDate(getZoneTime(moment(), currentLocation.latitude, currentLocation.longitude));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setToMainFilter = useCallback(() => {
    setCursor(0);
    updateFilterInputs({
      productType: category,
      cuisines: cuisineInputs,
      businessTypes: businessTypeInputs,
      features: featureInputs,
      itemTypes: itemTypeInputs,
      dressCodes: dressCodeInputs,
      diningStyles: diningStyleInputs,
      publicTransports: publicTransportInputs,
      ambiences: ambienceInputs,
      priceRanges: priceRangeInputs,
    });
  }, [
    ambienceInputs,
    businessTypeInputs,
    category,
    cuisineInputs,
    diningStyleInputs,
    dressCodeInputs,
    featureInputs,
    itemTypeInputs,
    priceRangeInputs,
    publicTransportInputs,
    updateFilterInputs,
  ]);

  useEffect(() => {
    setToMainFilter();
    // animateScroll.scrollToTop({ smooth: 'linear' });
  }, [setToMainFilter]);

  const handleMenuCategory = async categoryValue => {
    setCategory(categoryValue);
  };

  const addPriceRangeInputs = async range => {
    const newPriceRanges = await priceRangeInputs.concat([range]);
    setPriceRangeInputs(newPriceRanges);
  };

  const removePriceRangeInputs = value => {
    const newPriceRanges = priceRangeInputs.filter(item => item !== value);
    setPriceRangeInputs(newPriceRanges);
  };

  const addCuisineInputs = async cuisine => {
    const newCuisines = await cuisineInputs.concat([cuisine]);
    setCuisineInputs(newCuisines);
  };

  const removeCuisineInputs = name => {
    const newCuisines = cuisineInputs.filter(item => item.name !== name);
    setCuisineInputs(newCuisines);
  };

  const addItemTypeInputs = async itemType => {
    const newItemTypes = await itemTypeInputs.concat([itemType]);
    setItemTypeInputs(newItemTypes);
  };

  const removeItemTypeInputs = name => {
    const newItemTypes = itemTypeInputs.filter(item => item.name !== name);
    setItemTypeInputs(newItemTypes);
  };

  const addBusinessTypeInputs = async businessType => {
    const newBusinessType = await businessTypeInputs.concat([businessType]);
    setBusinessTypeInputs(newBusinessType);
  };

  const removeBusinessTypeInputs = name => {
    const newBusinessType = businessTypeInputs.filter(item => item.name !== name);
    setBusinessTypeInputs(newBusinessType);
  };

  const addFeatureInputs = async feature => {
    const newFeatures = await featureInputs.concat([feature]);
    setFeatureInputs(newFeatures);
  };

  const removeFeatureInputs = name => {
    const newFeatures = featureInputs.filter(item => item.name !== name);
    setFeatureInputs(newFeatures);
  };

  const addDressCodeInputs = async dressCode => {
    const newDressCodes = await dressCodeInputs.concat([dressCode]);
    setDressCodeInputs(newDressCodes);
  };

  const removeDressCodeInputs = name => {
    const newDressCodes = dressCodeInputs.filter(item => item.name !== name);
    setDressCodeInputs(newDressCodes);
  };

  const addDiningStyleInputs = async diningStyle => {
    const newDiningStyles = await diningStyleInputs.concat([diningStyle]);
    setDiningStyleInputs(newDiningStyles);
  };

  const removeDiningStyleInputs = name => {
    const newDiningStyles = diningStyleInputs.filter(item => item.name !== name);
    setDiningStyleInputs(newDiningStyles);
  };

  const addPublicTransportInputs = async publicTransport => {
    const newPublicTransports = await publicTransportInputs.concat([publicTransport]);
    setPublicTransportInputs(newPublicTransports);
  };

  const removePublicTransportInputs = name => {
    const newPublicTransports = publicTransportInputs.filter(item => item.name !== name);
    setPublicTransportInputs(newPublicTransports);
  };

  const addAmbienceInputs = async ambience => {
    const newAmbiences = await ambienceInputs.concat([ambience]);
    setAmbienceInputs(newAmbiences);
  };

  const removeAmbienceInputs = name => {
    const newAmbiences = ambienceInputs.filter(item => item.name !== name);
    setAmbienceInputs(newAmbiences);
  };

  const clearFilters = () => {
    setCuisineInputs([]);
    setItemTypeInputs([]);
    setBusinessTypeInputs([]);
    setFeatureInputs([]);

    setDressCodeInputs([]);
    setDiningStyleInputs([]);
    setPublicTransportInputs([]);
    setAmbienceInputs([]);
  };
  const onHandleClearItem = value => {
    switch (value) {
      case 'cuisines':
        setCuisineInputs([]);
        break;
      case 'businessType':
        setBusinessTypeInputs([]);
        break;
      case 'feature':
        setFeatureInputs([]);
        break;
      case 'itemType':
        setItemTypeInputs([]);
        break;
      case 'dressCode':
        setDressCodeInputs([]);
        break;
      case 'diningStyle':
        setDiningStyleInputs([]);
        break;
      case 'publicTrans':
        setPublicTransportInputs([]);
        break;
      case 'ambience':
        setAmbienceInputs([]);
        break;
      case 'priceRange':
        setPriceRangeInputs([]);
        break;
      default:
    }
  };

  const handleMobileFilter = mobileFilter => {
    setCuisineInputs(mobileFilter.cuisineInputs);
    setBusinessTypeInputs(mobileFilter.businessTypeInputs);
    setFeatureInputs(mobileFilter.featureInputs);
    setItemTypeInputs(mobileFilter.itemTypeInputs);
    setDressCodeInputs(mobileFilter.dressCodeInputs);
    setDiningStyleInputs(mobileFilter.diningStyleInputs);
    setPublicTransportInputs(mobileFilter.publicTransportsInputs);
    setAmbienceInputs(mobileFilter.ambienceInputs);
  };

  const filterStateInputs = {
    category,
    cuisineInputs,
    itemTypeInputs,
    businessTypeInputs,
    featureInputs,
    dressCodeInputs,
    diningStyleInputs,
    publicTransportInputs,
    ambienceInputs,
    priceRangeInputs,
  };

  const filterFuncInputs = {
    setPlaceSearchQuery,
    handleMenuCategory,
    addPriceRangeInputs,
    removePriceRangeInputs,
    addCuisineInputs,
    removeCuisineInputs,
    addItemTypeInputs,
    removeItemTypeInputs,
    addBusinessTypeInputs,
    removeBusinessTypeInputs,
    addFeatureInputs,
    removeFeatureInputs,
    addDressCodeInputs,
    removeDressCodeInputs,
    addDiningStyleInputs,
    removeDiningStyleInputs,
    addPublicTransportInputs,
    removePublicTransportInputs,
    addAmbienceInputs,
    removeAmbienceInputs,
    clearFilters,
    onHandleClearItem,
  };

  const cuisineInputsArray = cuisineInputs.map(item => item.name);
  const itemTypeInputsArray = itemTypeInputs.map(item => item.name);
  const businessTypeInputsArray = businessTypeInputs.map(item => item.name);
  const featureInputsArray = featureInputs.map(item => item.name);

  const dressCodeInputsArray = dressCodeInputs.map(item => item.name);
  const diningStyleInputsArray = diningStyleInputs.map(item => item.name);
  const publicTransportsInputsArray = publicTransportInputs.map(item => item.name);
  const ambienceInputsArray = ambienceInputs.map(item => item.name);
  const placeFilters = {};

  Object.assign(
    placeFilters,
    cuisineInputsArray.length > 0 && { cuisine_style: cuisineInputsArray },
    itemTypeInputsArray.length > 0 && { item_type: itemTypeInputsArray },
    businessTypeInputsArray.length > 0 && {
      business_type: businessTypeInputsArray,
    },
    featureInputsArray.length > 0 && { feature: featureInputsArray },

    dressCodeInputsArray.length > 0 && { dress_code: dressCodeInputsArray },
    diningStyleInputsArray.length > 0 && {
      dining_style: diningStyleInputsArray,
    },
    publicTransportsInputsArray.length > 0 && {
      public_transport: publicTransportsInputsArray,
    },
    ambienceInputsArray.length > 0 && { ambience: ambienceInputsArray },
    priceRangeInputs.length > 0 && { price_range: priceRangeInputs },
    category !== 'all' && {
      service_category: [category],
    },

    {
      listing_status: 'APPROVED',
      event_business: false,
      status: 'ACTIVE',
      distance: config.distanceRadius,
      service_type: serviceType,
    },
    // this.state.withDeals === true && { voucher_count: this.state.withDeals },
  );

  return (
    <Layout searchInputEnable service="places" history={history} match={match}>
      <Query
        query={searchPlacesQuery}
        variables={{
          input: {
            search_criteria: placeSearchQuery,
            location: `${currentLocation.latitude},${currentLocation.longitude}`,
            size: totalPlaceSize,
            from: cursor,
            sort: sort.value,
            filter: {
              place_filter: placeFilters,
              service_type_setting_filter: { service_type: serviceType },
            },
          },
        }}
      >
        {({ data, loading, error, refetch }) => {
          if (error) {
            return JSON.stringify(error);
          }

          if (!data.search_food_orders) {
            return <Loader />;
          }

          return (
            <View
              data={data}
              loading={loading}
              currentDate={currentDate}
              filterStateInputs={filterStateInputs}
              filterFuncInputs={filterFuncInputs}
              currentLocation={currentLocation}
              handlePageClick={value => {
                setCursor(value.selected * totalPlaceSize);
              }}
              handleMobileFilter={handleMobileFilter}
              fetchMoreInMobile={() => {
                setCursor(cursor + totalPlaceSize);
              }}
              reFetch={() => refetch()}
            />
          );
        }}
      </Query>
    </Layout>
  );
};

export default Menus;
