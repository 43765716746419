import React, { useState } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useStoreState, useStoreActions } from 'easy-peasy';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { isNull } from 'lodash';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Loading from '../../components/global/mobile/MobileLoading';
import client from '../../utils/apolloClient';

const userQuery = gql`
  query user($userId: String) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      first_name
      last_name
      preferred_username
      display_name
      email
      date_of_birth
      gender
      description
      color_code
      user_handle
      profile_image {
        image_id
      }
      address {
        is_default
        address_line_1
        city
        state
        country
        post_code
      }
      contact {
        type
        value
        display
        display_order
        is_primary
      }
    }
  }
`;

const userUpdateMutation = gql`
  mutation userUpdate($input: UserInput) {
    update_user(input: $input) {
      user_id
      first_name
      last_name
      display_name
      email
      color_code
      preferred_username
      error {
        description
      }
    }
  }
`;

const createImageMutation = gql`
  mutation createImage($input: ImageInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
  }
`;

const ProfileUpdate = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const updateLocalUser = useStoreActions(state => state.auth.updateUser);
  const [image, setImage] = useState('');

  return (
    <Layout
      history={history}
      match={match}
      bottomNavigationEnable={false}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading="PROFILE UPDATE"
    >
      <Query
        client={client.clientPrivate}
        query={userQuery}
        variables={{ userId }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }
          const { fetch_user } = data;

          return (
            <Mutation
              client={client.clientPrivate}
              mutation={createImageMutation}
              onCompleted={() => {
                swal('Great!', 'Profile updated successfully!', 'success').then(() => {
                  history.goBack();
                });
              }}
            >
              {(create_image, { loading: imageLoading }) => (
                <Mutation
                  client={client.clientPrivate}
                  mutation={userUpdateMutation}
                  onCompleted={({ update_user }) => {
                    if (!isNull(update_user.error)) {
                      update_user.error.map(item => toast.error(item.description));
                    } else {
                      updateLocalUser(update_user);
                      console.log('image', image);
                      if (image && image !== null) {
                        create_image({
                          variables: {
                            input: {
                              user_id: userId,
                              object_id: userId,
                              object_type: 'USER_IMAGE',
                              parent_id: userId,
                              parent_type: 'USER_IMAGE',
                              upload_type: 'USER_IMAGE',
                              links_to: [
                                {
                                  id: userId,
                                  type: 'USER_IMAGE',
                                },
                              ],
                              images: image,
                            },
                          },
                        });
                      } else {
                        swal('Great!', 'Profile updated successfully!', 'success').then(() => {
                          history.goBack();
                        });
                      }
                    }
                  }}
                >
                  {(update_user, { loading: updateLoading }) => (
                    <>
                      <div className="is-hidden-desktop">
                        <MobileView
                          user={fetch_user}
                          loading={updateLoading || imageLoading}
                          onSubmit={async values => {
                            await setImage(values.image);
                            const input = {
                              user_id: userId,
                              object_id: userId,
                              first_name: values.first_name,
                              last_name: values.last_name,
                              display_name: values.display_name,
                              preferred_username: values.preferred_username,
                              color_code: values.color_code,
                              email: values.email,
                              contact: [
                                {
                                  type: 'email',
                                  value: values.email,
                                  display: true,
                                  is_primary: true,
                                },
                                {
                                  type: 'email',
                                  value: values.alternateEmail,
                                  display: true,
                                  is_primary: false,
                                },
                                {
                                  type: 'mobile',
                                  value: values.primaryMobile,
                                  display: true,
                                  is_primary: true,
                                },
                                {
                                  type: 'mobile',
                                  value: values.alternateMobile,
                                  display: true,
                                  is_primary: false,
                                },
                              ],
                              date_of_birth: values.date_of_birth,
                              gender: values.gender,
                              description: values.description,
                              status: 'ACTIVE',
                            };
                            update_user({ variables: { input } });
                          }}
                        />
                      </div>
                      <div className="is-hidden-touch">
                        <DesktopView
                          user={fetch_user}
                          loading={updateLoading || imageLoading}
                          onSubmit={async values => {
                            await setImage(values.image);
                            const input = {
                              user_id: userId,
                              object_id: userId,
                              first_name: values.first_name,
                              last_name: values.last_name,
                              display_name: values.display_name,
                              preferred_username: values.preferred_username,
                              color_code: values.color_code,
                              user_handle: values.user_handle,
                              email: values.email,
                              contact: [
                                {
                                  type: 'email',
                                  value: values.email,
                                  display: true,
                                  is_primary: true,
                                },
                                {
                                  type: 'email',
                                  value: values.alternateEmail,
                                  display: true,
                                  is_primary: false,
                                },
                                {
                                  type: 'mobile',
                                  value: values.primaryMobile,
                                  display: true,
                                  is_primary: true,
                                },
                                {
                                  type: 'mobile',
                                  value: values.alternateMobile,
                                  display: true,
                                  is_primary: false,
                                },
                              ],
                              date_of_birth: values.date_of_birth,
                              gender: values.gender,
                              description: values.description,
                              status: 'ACTIVE',
                            };
                            update_user({ variables: { input } });
                          }}
                        />
                      </div>
                    </>
                  )}
                </Mutation>
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default ProfileUpdate;
