import React from 'react';
import styled from 'styled-components';
import { times } from 'lodash';
import moment from 'moment';

import {
  FlexRows,
  IconActionButton,
  Icon,
  ShowMoreText,
  FlexColumns,
  Text,
} from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';
import ReviewImageView from './ReviewImageView';
import ReviewCommentList from './ReviewCommentList';
import SocialActionButton from '../../../../components/SocialActionButton';
import FlaggedActionButton from '../../../../components/FlaggedActionButton';
import ItemHeader from '../ItemHeader';

const Container = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${colors.lightGreyBeta};
`;
const IconWrapper = styled.span`
  padding: 0.1rem;
`;

const IconStyle = styled(Icon).attrs({ size: 0.8 })``;
const SocialActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'smaller', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const IconActionButtonStyle = styled(IconActionButton).attrs({
  textStyle: { size: 'smaller', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;
const FlaggedActionButtonStyle = styled(FlaggedActionButton).attrs({
  textStyle: { size: 'smaller', weight: 'regular', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const ReviewItem = ({ review }) => {
  return (
    <Container>
      <ItemHeader itemId={review.item_id}>
        <FlexColumns>
          <div className="is-flex">
            {times(review.rating).map(() => (
              <IconWrapper>
                <IconStyle name="itemRatingGoldSolid" />
              </IconWrapper>
            ))}
            {times(5 - review.rating).map(() => (
              <IconWrapper>
                <IconStyle name="itemRatingSolidGrey" />
              </IconWrapper>
            ))}
          </div>
          <Text size="micro" color="darkGrey" lineHeight="normal">
            {moment(review.audit.created_at).fromNow()}
          </Text>
        </FlexColumns>
      </ItemHeader>
      <ShowMoreText content={review.content} limit={200} size="tiny" />
      <br />
      <ReviewImageView placeId={review.place_id} itemReviewId={review.item_review_id} />
      <FlexRows justify="space-between">
        <SocialActionButtonStyle
          social={review.social}
          objectId={review.item_review_id}
          objectType="ITEM_REVIEW"
          type="LIKE"
          name="LIKE"
          defaultIcon="likeGrey"
          activeIcon="likeBlue"
        />
        <IconActionButtonStyle name="COMMENT" icon="commentBlue" />
        <FlaggedActionButtonStyle
          createdBy={review.audit.created_by}
          objectId={review.item_review_id}
          parentId={review.place_id}
          objectType="ITEM_REVIEW"
          name="FLAG"
          icon="flagGrey"
        />
      </FlexRows>
      <ReviewCommentList
        itemId={review.item_id}
        itemReviewId={review.item_review_id}
        placeId={review.place_id}
      />
    </Container>
  );
};

export default ReviewItem;
