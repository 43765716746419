import React from 'react';
import styled from 'styled-components';

import Info from './components/mobile/Info';
import Points from './components/mobile/Points';
import DetailsItemTab from './components/mobile/DetailsItemTab';

const Container = styled.div`
  background-color: ${({ theme: { colors } }) => colors.lightGreyBeta};
  height: 100%;
`;

const MobileView = ({ user }) => (
  <>
    <Container>
      <Info user={user} />
      <Points user={user} />
      <DetailsItemTab />
    </Container>
  </>
);

export default MobileView;
