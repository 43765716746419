import React from 'react';
import styled from 'styled-components';
import { Text, FlexRows } from '../../../components/elements';
import Draft from './Draft';
import ItemReview from './ItemReview';
import PlaceReview from './PlaceReview';

const Container = styled.div``;

const Wrapper = styled(FlexRows)`
  position: sticky;
  top: 0rem;
  margin: 0rem 0.5rem;
`;

const Item = styled.div`
  padding: 0.5rem;
  border-bottom: 3px solid
    ${({ theme: { colors }, active }) => (active ? colors.primaryColor : 'transparent')};
  text-align: center;
  cursor: pointer;
`;

const MobileView = ({ children }) => {
  const [activeTab, setActiveTab] = React.useState('draft');
  return (
    <>
      <Container>
        <Wrapper justify="space-around">
          <Item
            active={activeTab === 'draft'}
            onClick={() => {
              setActiveTab('draft');
            }}
          >
            <Text
              className="is-uppercase"
              weight="bold"
              size="small"
              color={activeTab === 'draft' ? 'primaryColor' : 'dark'}
            >
              Draft
            </Text>
          </Item>
          <Item
            active={activeTab === 'item'}
            onClick={() => {
              setActiveTab('item');
            }}
          >
            <Text
              className="is-uppercase"
              weight="bold"
              size="small"
              color={activeTab === 'item' ? 'primaryColor' : 'dark'}
            >
              Item Reviews
            </Text>
          </Item>
          <Item
            active={activeTab === 'place'}
            onClick={() => {
              setActiveTab('place');
            }}
          >
            <Text
              className="is-uppercase"
              weight="bold"
              size="small"
              color={activeTab === 'place' ? 'primaryColor' : 'dark'}
            >
              Place Reviews
            </Text>
          </Item>
        </Wrapper>
        {activeTab === 'draft' && <Draft />}
        {activeTab === 'item' && <ItemReview />}
        {activeTab === 'place' && <PlaceReview />}
      </Container>
      {children}
    </>
  );
};

export default MobileView;
