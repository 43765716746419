import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import gql from 'graphql-tag';

import { FlexRows, IconSocialActionButton } from '../../../../components/elements';
import ShareActionButton from '../../../../components/ShareActionButton';
import client from '../../../../utils/apolloClient';

const SocialActionButtonStyle = styled(IconSocialActionButton).attrs({
  textStyle: { size: 'micro', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
  showCount: false,
})``;

const ShareActionButtonButtonStyle = styled(ShareActionButton).attrs({
  textStyle: { size: 'micro', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
  showCount: false,
})``;

const socialMutation = gql`
  mutation socialAction($input: SocialNetworkInput) {
    create_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const removeSocialMutation = gql`
  mutation removeSocialAction($input: NavigateSocialInput) {
    delete_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const PlaceSocial = ({
  objectId,
  objectType,
  likeActive,
  bookMarkActive,
  likeCountInt,
  bookMarkCountInt,
  likeSocialIdS,
  bookMarkSocialIdS,
}) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn, userId } = useStoreState(state => state.auth);
  const [likeCount, setLikeCount] = useState(likeCountInt);
  const [bookMarkCount, setBookMarkCount] = useState(bookMarkCountInt);

  const [likeSocialId, setLikeSocialId] = useState(likeSocialIdS);
  const [bookMarkSocialId, setBookMarkSocialId] = useState(bookMarkSocialIdS);

  const [isActiveLike, setIsActiveLike] = useState(likeActive);
  const [isActiveBookMark, setIsActiveBookMark] = useState(bookMarkActive);

  const [likeLoading, setLikeLoading] = useState(false);
  const [bookMarkLoading, setBookMarkLoading] = useState(false);

  const handleCountIncrement = type => {
    if (type === 'LIKE') {
      setLikeCount(likeCount + 1);
      setIsActiveLike(true);
      setLikeLoading(false);
    }
    if (type === 'BOOKMARK') {
      setBookMarkCount(bookMarkCount + 1);
      setIsActiveBookMark(true);
      setBookMarkLoading(false);
    }
  };

  const handleCountDecrement = type => {
    if (type === 'LIKE') {
      setLikeCount(likeCount - 1);
      setIsActiveLike(false);
      setLikeLoading(false);
    }
    if (type === 'BOOKMARK') {
      setBookMarkCount(bookMarkCount - 1);
      setIsActiveBookMark(false);
      setBookMarkLoading(false);
    }
  };

  const handleOnClickActive = type => {
    if (type === 'LIKE') {
      setLikeLoading(true);
    }
    if (type === 'BOOKMARK') {
      setBookMarkLoading(true);
    }
    client.clientPrivate
      .mutate({
        mutation: socialMutation,
        variables: {
          input: {
            user_id: userId,
            object_id: objectId,
            object_type: objectType,
            type,
            value: 1,
          },
        },
      })
      .then(({ data }) => {
        handleCountIncrement(type);
        if (type === 'LIKE') {
          setLikeSocialId(data.create_social.social_id);
        }
        if (type === 'BOOKMARK') {
          setBookMarkSocialId(data.create_social.social_id);
        }
      });
  };

  const handleOnClickRemove = (type, socialId) => {
    if (type === 'LIKE') {
      setLikeLoading(true);
    }
    if (type === 'BOOKMARK') {
      setBookMarkLoading(true);
    }
    client.clientPrivate
      .mutate({
        mutation: removeSocialMutation,
        variables: {
          input: {
            user_id: userId,
            social_id: socialId,
          },
        },
      })
      .then(() => {
        handleCountDecrement(type);
      });
  };

  const handleSocialAction = (type, action, socialId) => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
      });
    } else if (action) {
      handleOnClickActive(type);
    } else {
      handleOnClickRemove(type, socialId);
    }
  };

  return (
    <FlexRows justify="space-between">
      <SocialActionButtonStyle
        loading={likeLoading}
        active={isActiveLike}
        name="Like IT"
        defaultIcon="likeStarGrey"
        activeIcon="likeStarBlue"
        socialCount={likeCount}
        handleOnClick={() => handleSocialAction('LIKE', !isActiveLike, likeSocialId)}
      />

      <SocialActionButtonStyle
        loading={bookMarkLoading}
        active={isActiveBookMark}
        name="Bookmark"
        defaultIcon="bookmarkSolidGrey"
        activeIcon="bookmarkSolid"
        socialCount={bookMarkCount}
        handleOnClick={() => handleSocialAction('BOOKMARK', !isActiveBookMark, bookMarkSocialId)}
      />

      <ShareActionButtonButtonStyle name="Share" icon="shareBlue" />
    </FlexRows>
  );
};

PlaceSocial.defaultProps = {
  objectId: '',
  objectType: '',
  likeActive: false,
  bookMarkActive: false,
  likeCountInt: 0,
  bookMarkCountInt: 0,
};

PlaceSocial.propTypes = {
  objectId: PropTypes.string,
  objectType: PropTypes.string,
  likeActive: PropTypes.bool,
  bookMarkActive: PropTypes.bool,

  likeCountInt: PropTypes.number,
  bookMarkCountInt: PropTypes.number,
};

export default PlaceSocial;
