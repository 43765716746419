import React, { useState } from 'react';
import styled from 'styled-components';
import { times } from 'lodash';
import moment from 'moment';

import { MdStar } from 'react-icons/md';
import {
  FlexRows,
  IconActionButton,
  ShowMoreText,
  Text,
  FlexColumns,
  Avatar,
} from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';
import ReviewImageView from './ReviewImageView';

import PlaceReviewCommentList from './PlaceReviewCommentList';
import SocialActionButton from '../../../../components/SocialActionButton';
import FlaggedActionButton from '../../../../components/FlaggedActionButton';
import FollowButtonAction from '../../../../components/FollowButtonAction';

const Container = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${colors.lightGreyBeta};
`;
const IconWrapper = styled.span`
  padding: 0rem;
`;

const Heading = styled.div`
  padding-left: 0.5rem;
`;
const ButtonWrapper = styled.div`
  padding-top: 0.5rem;
`;
const SocialActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const IconActionButtonStyle = styled(IconActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const FlaggedActionButtonStyle = styled(FlaggedActionButton).attrs({
  textStyle: { size: 'tiny', weight: 'semibold', color: 'darkGrey' },
  paddingLess: true,
  iconStyle: { size: 1 },
})``;

const ReviewItem = ({ review, socials }) => {
  const [reFetchLikeUsers, setReFetchLikeUsers] = useState(false);
  // const { loading, user } = useFetchUser({ userId: review.audit.created_by });
  // const customColor = randomColor({
  //   luminosity: 'dark',
  // });
  const user = review.user_details;
  return (
    <Container>
      <FlexRows justify="space-between" align="flex-start">
        <div>
          <FlexRows justify="flex-start" align="flex-start">
            <Avatar
              name={user && user.display_name}
              maskProps={{ width: 40 }}
              maskTextProps={{
                className: 'is-capitalized',
                color: 'white',
                weight: 'bold',
                size: 'small',
                letterSpacing: 'loose',
              }}
              borderRadius={25}
              color={user && user.color_code}
              image={user && user.profile_image_url}
            />
            <Heading>
              <Text size="medium" weight="semibold">
                {user && user.display_name}
              </Text>
              <Text size="micro" color="darkGrey">
                {`${moment(review.audit.created_at).fromNow()}, Visited ${moment(
                  review.month_visited,
                ).format("MMMM 'YY")}`}
              </Text>
            </Heading>
          </FlexRows>
        </div>

        <div>
          <FlexColumns>
            <ButtonWrapper>
              <FollowButtonAction
                objectId={review.audit.created_by}
                objectType="USER"
                textProps={{ size: 'micro', weight: 'semibold' }}
                buttonProps={{ width: 0.4 }}
              />
            </ButtonWrapper>
            <div className="is-flex">
              {times(review.overall_rating).map(index => (
                <IconWrapper key={index}>
                  <MdStar color={colors.tertiaryColor} size={15} />
                </IconWrapper>
              ))}
              {times(5 - review.overall_rating).map(index => (
                <IconWrapper key={index}>
                  <MdStar color={colors.specialGrey} size={15} />
                </IconWrapper>
              ))}
            </div>
          </FlexColumns>
        </div>
      </FlexRows>
      <ShowMoreText content={review.content} limit={200} size="tiny" />
      <br />
      <ReviewImageView placeId={review.place_id} placeReviewId={review.place_review_id} />
      <FlexRows justify="space-between">
        <SocialActionButtonStyle
          social={review.social}
          objectId={review.place_review_id}
          objectType="PLACE_REVIEW"
          type="LIKE"
          name="LIKE"
          defaultIcon="likeGrey"
          activeIcon="likeBlue"
          trackOnChange={() => setReFetchLikeUsers(!reFetchLikeUsers)}
        />
        <IconActionButtonStyle
          name="COMMENT"
          icon="commentBlue"
          // onClick={() => setIsActiveComment(true)}
        />
        <FlaggedActionButtonStyle
          createdBy={review.audit.created_by}
          objectId={review.place_review_id}
          parentId={review.place_id}
          objectType="PLACE_REVIEW"
          name="FLAG"
          icon="flagGrey"
        />
      </FlexRows>
      <PlaceReviewCommentList placeId={review.place_id} placeReviewId={review.place_review_id} />
    </Container>
  );
};

export default ReviewItem;
