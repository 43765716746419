import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GoPlus, GoDash } from 'react-icons/go';
import { FlexRows, Text } from '../elements';

import colors from '../../theme/styles/colors';

const Counter = ({ disabled = false, quantity = 0, onChange }) => {
  const [count, setCount] = useState(quantity);

  useEffect(() => {
    setCount(quantity);
  }, [quantity]);

  const Container = styled(FlexRows)`
    width: 5rem;
    border: 1px solid #dbdbdb;
    padding: 0px 8px;
    border-radius: 6px;
  `;
  const TextStyle = styled(Text)`
    position: relative;
    top: 3px;
  `;
  const TextStyle2 = styled(Text)`
    position: relative;
    top: 1px;
  `;

  return (
    <Container justify="space-between">
      <TextStyle size="tiny" weight="semibold">
        <a onClick={() => !disabled && count > 0 && onChange(count - 1)}>
          <GoDash color={disabled ? colors.waterMarkGrey : colors.primaryColor} />
        </a>
      </TextStyle>
      <TextStyle2 color="darkGrey" size="tiny" weight="semibold">
        {count}
      </TextStyle2>
      <TextStyle size="tiny" weight="semibold">
        <a onClick={() => !disabled && count < 100 && onChange(count + 1)}>
          <GoPlus color={disabled ? colors.waterMarkGrey : colors.primaryColor} />
        </a>
      </TextStyle>
    </Container>
  );
};

export default Counter;
