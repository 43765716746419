import React from 'react';
import styled from 'styled-components';
import colors from '../../../../theme/styles/colors';
import { Text } from '../../../../components/elements';

const Container = styled.div`
  && {
    padding: 1rem 0rem;
    .input {
      border-color: transparent;
      border-radius: 0;
      border-bottom-color: ${colors.borderColor};
      border-width: 2px;
      padding: 0;
      font-size: 15px;
    }
    .input:active,
    .input:hover,
    .input:focus {
      border-color: transparent;
      border-bottom-color: ${colors.borderColor};
      box-shadow: 0 0 0 0.08em rgba(44, 86, 151, 0);
    }
  }
`;

const InputField = ({ label, placeholder, ...props }) => {
  return (
    <Container className="field">
      <label className="label">
        <Text color="primaryColor" size="small">
          {label}
        </Text>
      </label>
      <div className="control">
        <input className="input" type="text" placeholder={placeholder} {...props} />
      </div>
    </Container>
  );
};

export default InputField;
