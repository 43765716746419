import React, { useEffect, useCallback, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { first, forEach } from 'lodash';

import Layout from '../../components/global/MenuLayout';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
// import ErrorMessage from '../../components/global/ErrorMessage';
import Analytics from '../../components/global/Analytics';
import Loading from '../../components/global/Loading';
import client from '../../utils/apolloClient';

const eventQuery = gql`
  query fetch_event($eventId: String) {
    fetch_event(input: { event_id: $eventId }) {
      place_id
      event_id
      status
      listing_type
      start
      end
      ticket_sale_start
      ticket_sale_end
      place_name
      venue_name
      name
      description
      tagline
      tag
      slug
      voucher_count
      minimum_price
      maximum_price
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      timezone
      contact_event_organizer
      event_type {
        name
        description
        display_order
      }
      feature {
        name
        description
        logo
        display_order
      }
      restriction {
        type
        name
        description
        display_order
      }
      event_category {
        name
        description
        display_order
      }
      dress_code {
        name
        description
        display_order
      }
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      default_image_url
    }
    fetch_event_occurrence(input: { event_id: $eventId }) {
      event_id
      event_occurrence_id
      start
      end
      sold_tickets
      total_tickets
      remaining_tickets
      display_remaining_ticket
    }
    search_social(
      input: { filter: { social_filter: { object_type: "EVENT", object_id: [$eventId] } } }
    ) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const eventTicketsQuery = gql`
  query eventTicketsQuery($placeId: String, $eventOccurrenceId: String) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      event_business
      status
    }
    fetch_event_occurrence_ticket(input: { event_occurrence_id: $eventOccurrenceId }) {
      event_occurrence_id
      ticket_id
      sales_method
    }
  }
`;

const parseOfflineTicket = listOfTickets => {
  const finalTicket = [];
  forEach(listOfTickets, item => {
    forEach(item.sales_method, item2 => {
      if (item2 !== 'offline') {
        finalTicket.push(item);
      }
    });
  });
  return finalTicket;
};

const View = ({ event, occurrence, socials }) => {
  const [totalNumberOfTicket, setTotalNumberOfTicket] = useState(0);
  const [placeEventOrganizeOnly, setPlaceEventOrganizeOnly] = useState(false);
  const [placeInactive, setPlaceInActive] = useState(false);
  const updateRecentView = useStoreActions(state => state.event.addRecentView);
  const recentViews = useStoreState(state => state.event.recentViews);

  const eventTicketStartEndDate = useCallback(async () => {
    if (occurrence) {
      client.clientPublic
        .query({
          query: eventTicketsQuery,
          variables: { placeId: event.place_id, eventOccurrenceId: occurrence.event_occurrence_id },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          if (data) {
            const tickets = parseOfflineTicket(data.fetch_event_occurrence_ticket);
            setPlaceEventOrganizeOnly(data.fetch_place.event_business);
            setPlaceInActive(data.fetch_place.status !== 'ACTIVE');
            setTotalNumberOfTicket(tickets.length);
          }
        });
    }
  }, [event.place_id, occurrence]);

  useEffect(() => {
    if (event) {
      const filter = recentViews.filter(item => item.event_id === event.event_id);
      if (filter.length === 0) {
        updateRecentView(event);
      }
    }
    eventTicketStartEndDate();
  }, [event, eventTicketStartEndDate, recentViews, updateRecentView]);

  return (
    <Analytics pageName="Events" category="event" title="Event- Listing">
      <div className="is-hidden-desktop">
        <MobileView
          event={event}
          socials={socials}
          occurrence={occurrence}
          totalNumberOfTicket={totalNumberOfTicket}
        />
      </div>
      <div className="is-hidden-touch">
        <DesktopView
          event={event}
          socials={socials}
          totalNumberOfTicket={totalNumberOfTicket}
          placeEventOrganizeOnly={placeEventOrganizeOnly}
          placeInactive={placeInactive}
        />
      </div>
    </Analytics>
  );
};

const Event = ({ history, match }) => (
  <Layout history={history} match={match} service="events">
    <Query
      query={eventQuery}
      variables={{ eventId: match.params.eventId }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (error) {
          return 'error';
        }
        if (loading) {
          return <Loading />;
        }

        return (
          <View
            event={data.fetch_event}
            occurrence={first(data.fetch_event_occurrence)}
            socials={data.search_social.social_aggregation.total_social_per_object_id}
          />
        );
      }}
    </Query>
  </Layout>
);

export default Event;
