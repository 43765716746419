import React from 'react';
import styled from 'styled-components';

import { Text, Flex, FlexRows, Input, ShowMoreText } from '../../../components/elements';

const Container = styled.div`
  &&& {
    z-index: 2001;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 45rem;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    }
    .modal-card-body {
      padding: 2rem;
    }
  }
`;
const Wrapper = styled.div`
  background: #ffffff;
`;

const Section = styled.div`
  padding: 1.5rem 2rem;
  overflow: scroll;
`;

const CouponsSection = styled.div`
  padding: 0.5rem 1rem;
  padding-top: 0rem;
  height: 70vh;
  overflow: scroll;
`;

const Header = styled(Flex)`
  padding: 0.8rem;
  background: ${({ theme }) => theme.colors.lightGreyBeta};
`;

const ButtonStyle = styled.div`
  &&& {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

const Element = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;
const CouponHeader = styled.div`
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
`;

const ApplyVoucherModal = ({
  isActive,
  isClose,
  handleCoupon,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  applyCouponLoading,
  allVouchers,
}) => {
  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <Wrapper>
          <ButtonStyle className="delete" label="close" onClick={isClose} />
          <Header>
            <Text weight="semibold" color="darkGrey">
              Apply Promo Code
            </Text>
          </Header>
          <Section>
            <div className="field has-addons">
              <div className="control is-expanded">
                <Input
                  className="input"
                  type="text"
                  placeholder="Enter Promo Code"
                  name="voucherCode"
                  value={values.voucherCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="control">
                <a
                  className={`button is-primary ${applyCouponLoading && 'is-loading'}`}
                  onClick={handleSubmit}
                >
                  Apply
                </a>
              </div>
            </div>
          </Section>

          {allVouchers.length !== 0 && (
            <CouponsSection>
              <CouponHeader>
                <Text
                  className="is-capitalized"
                  size="small"
                  weight="semibold"
                  color="primaryColor"
                >
                  Available Promo Codes
                </Text>
              </CouponHeader>
              {allVouchers.map(deal => (
                <Element key={deal.deal_id}>
                  <Text
                    className="is-capitalized"
                    size="small"
                    weight="semibold"
                    paddingBottom={0.3}
                  >
                    {deal.tagline}
                  </Text>
                  {/* <Text size="small" weight="semibold" color="darkGrey" paddingBottom={0.2}>
                        {deal.tagline}
                      </Text> */}
                  <ShowMoreText
                    size="micro"
                    color="darkGrey"
                    content={deal.description}
                    lines={3}
                    paddingBottom={0.3}
                  >
                    {deal.description}
                  </ShowMoreText>

                  <FlexRows justify="space-between" style={{ paddingTop: '1rem' }}>
                    <span className="tag is-primary" style={{ padding: '1rem' }}>
                      <Text size="small" weight="semibold" color="white">
                        {deal.voucher_code}
                      </Text>
                    </span>

                    <a onClick={() => handleCoupon(deal.voucher_code)}>
                      <Text
                        className="is-uppercase"
                        size="small"
                        weight="semibold"
                        color="primaryColor"
                      >
                        <span style={{ marginRight: '0.5rem' }}>Apply</span>
                      </Text>
                    </a>
                  </FlexRows>
                </Element>
              ))}
            </CouponsSection>
          )}
        </Wrapper>
      </div>
    </Container>
  );
};

export default ApplyVoucherModal;
