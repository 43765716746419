import React from 'react';
import gql from 'graphql-tag';
import moment from 'moment';

import client from '../../../utils/apolloClient';
import DesktopViewLayout from './DesktopViewLayout';
import MobileViewLayout from './MobileViewLayout';
import MobileSecondaryHeader from './MobileSecondaryHeader';
import MobileSignInModal from './MobileSignInModal';
import MobileSignupModal from './MobileSignupModal';
import DeskTopSignInModal from './DeskTopSignInModal';
import DeskTopSignupModal from './DeskTopSignupModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import { getZoneTime } from '../../../utils/eventHelpers';
import ReportMenuErrorModal from './ReportMenuErrorModal';

const searchEventsQuery = gql`
  query searchEventsQuery($input: SearchInput) {
    search_events(input: $input) {
      event_listing {
        event_id
        name
        tagline
        place_name
        slug
      }
    }
  }
`;

const searchPlacesQuery = gql`
  query searchPlacesQuery($input: SearchInput) {
    search_places(input: $input) {
      place_listing {
        place_id
        address_line_1
        name
        city
        slug
        post_code
      }
    }
  }
`;

const searchItemQuery = gql`
  query searchItemsQuery($input: SearchInput) {
    search_items(input: $input) {
      item_listing {
        item_id
        name
        place_name
        slug
      }
    }
  }
`;

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchDataList: [], loading: false, eventDateFilter: '' };
  }

  setSearchListToState = (searchDataList, loading) => {
    this.setState({ searchDataList, loading });
  };

  fetchData = async () => {
    // const { usedLocation } = this.props;
    // const date = await getZoneTime(moment(), usedLocation.latitude, usedLocation.longitude);
    this.setState({ eventDateFilter: moment().toISOString() });
  };

  componentDidMount = async () => {
    this.fetchData();
  };

  handleBusinessesSearch = value => {
    if (value === '') {
      this.setSearchListToState([], false);
    } else {
      client.clientPublic
        .query({
          query: searchPlacesQuery,
          variables: {
            input: {
              search_criteria: value,
              filter: {
                place_filter: {
                  listing_status: 'APPROVED',
                  event_business: false,
                  status: 'ACTIVE',
                },
              },
            },
          },
        })
        .then(({ data, loading }) => {
          if (loading) {
            this.setState({ loading });
          } else {
            const searchDataList = data.search_places.place_listing;
            this.setSearchListToState(searchDataList, loading);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  handleEventsSearch = value => {
    const { eventDateFilter } = this.state;
    const event_filter = {};

    Object.assign(
      event_filter,
      { status: 'ACTIVE', draft: false },
      eventDateFilter !== '' && { end_date_range: { start_date: eventDateFilter } },
    );
    client.clientPublic
      .query({
        query: searchEventsQuery,
        variables: {
          input: { search_criteria: value, filter: { event_filter } },
        },
      })
      .then(({ data, loading }) => {
        if (loading) {
          this.setState({ loading });
        } else {
          const searchDataList = data.search_events.event_listing;
          this.setSearchListToState(searchDataList, loading);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleItemsSearch = value => {
    client.clientPublic
      .query({
        query: searchItemQuery,
        variables: {
          input: {
            search_criteria: value,
            filter: {
              item_filter: { approval_status: 'APPROVED' },
              place_filter: { status: 'ACTIVE' },
            },
          },
        },
      })
      .then(({ data, loading }) => {
        if (loading) {
          this.setState({ loading });
        } else {
          const searchDataList = data.search_items.item_listing;
          this.setSearchListToState(searchDataList, loading);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const func = {
      handleBusinessesSearch: this.handleBusinessesSearch,
      handleEventsSearch: this.handleEventsSearch,
      handleItemsSearch: this.handleItemsSearch,
    };

    const { isEnableMobileSecondaryHeader } = this.props;

    return (
      <>
        <div className="is-hidden-desktop">
          {isEnableMobileSecondaryHeader ? (
            <MobileSecondaryHeader {...this.props} />
          ) : (
            <MobileViewLayout {...this.props} func={func} state={this.state} />
          )}
          <MobileSignInModal />
          <MobileSignupModal />
        </div>
        <div className="is-hidden-touch">
          <DesktopViewLayout {...this.props} />
          <DeskTopSignInModal />
          <DeskTopSignupModal />
          <ForgotPasswordModal />
        </div>
      </>
    );
  }
}

export default Layout;
