import React, { useState, useEffect } from 'react';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateTimePickerCustomInput = ({ value, onClick, placeholder }) => (
  <div className="field" onClick={onClick}>
    <p className="control has-icons-left has-icons-right">
      <input className="input is-fullwidth" value={value} placeholder={placeholder} />
      <span className="icon is-small is-left">
        <i className="fas fa-calendar-alt" />
      </span>
    </p>
  </div>
);

const ReactDatePicker = ({ value, onChange, placeholderText, ...props }) => {
  const [startDate, setStartDate] = useState(value === null ? null : new Date(value));

  const handleChange = date => {
    setStartDate(date);
    onChange(moment(date).toISOString());
  };

  useEffect(() => {
    if (moment(value).toISOString() !== moment(startDate).toISOString()) {
      setStartDate(null);
    }
  }, [startDate, value]);

  return (
    <DatePicker
      selected={startDate}
      customInput={<DateTimePickerCustomInput placeholder="Select Date" />}
      onChange={date => handleChange(date)}
      dateFormat="d-MMMM-yyyy"
      showMonthDropdown
      showYearDropdown
      placeholderText={placeholderText || 'Select Date'}
      {...props}
    />
  );
};

export default ReactDatePicker;
