import React from 'react';
import { isDesktop } from '../../utils/helpers';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/Layout';
import Analytics from '../../components/global/Analytics';

const PlaceMenu = ({ history, match }) => {
  const isMobile = !isDesktop();
  return (
    <Layout history={history} match={match}>
      <Analytics pageName="PlaceMenu" category="placemenu" title="Place-Menu">
        <div className="is-hidden-desktop">
          <MobileView />
        </div>
        <div className="is-hidden-touch">
          <DesktopView />
        </div>
      </Analytics>
    </Layout>
  );
};

export default PlaceMenu;
