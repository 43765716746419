import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import ReactLoading from 'react-loading';
import { isNull } from 'lodash';

import { Text, FlexColumns } from '../../../../components/elements';
// import PlaceReviewActionButton from './PlaceReviewActionButton';
import ReviewItem from './ReviewItem';
import theme from '../../../../theme';

const searchPlaceReviewQuery = gql`
  query searchPlaceReview($input: SearchInput) {
    search_place_review(input: $input) {
      place_review_listing {
        place_id
        place_review_id
        month_visited
        overall_rating
        service_category {
          name
          description
        }
        content
        audit {
          created_at
          created_by
        }
        user_details {
          user_id
          display_name
          profile_image_url
          color_code
        }
      }
    }
    search_social(input: { filter: { social_filter: { object_type: "PLACE_REVIEW" } } }) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 0rem 1rem;
`;

const MessageContainer = styled(FlexColumns)`
  height: 30vh;
`;

const Reviews = ({ place }) => {
  return (
    <Container>
      <Query
        query={searchPlaceReviewQuery}
        variables={{
          input: {
            sort: 'CREATED_DESC',
            filter: {
              place_review_filter: {
                place_id: place.place_id,
                status: 'APPROVED',
              },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return (
              <MessageContainer>
                <ReactLoading type="spin" color={theme.primaryColor} width={50} height={50} />
              </MessageContainer>
            );
          }

          if (error) {
            return (
              <MessageContainer>
                <Text weight="semibold" color="darkGrey">
                  {error.message}
                </Text>
              </MessageContainer>
            );
          }
          if (isNull(data.search_place_review)) {
            return (
              <MessageContainer>
                <Text weight="semibold" color="darkGrey">
                  No Review Found
                </Text>
              </MessageContainer>
            );
          }

          const reviews = data.search_place_review.place_review_listing;
          const socials = data.search_social.social_aggregation.total_social_per_object_id;
          return <View reviews={reviews} socials={socials} place={place} />;
        }}
      </Query>
    </Container>
  );
};

const View = ({ reviews, socials }) => {
  if (reviews.length === 0) {
    return (
      <MessageContainer>
        <Text weight="semibold" color="darkGrey">
          No Review Found
        </Text>
      </MessageContainer>
    );
  }

  return (
    <>
      {/* <PlaceReviewActionButton placeId={place.place_id} placeName={place.name} /> */}

      {reviews.map(review => (
        <ReviewItem review={review} socials={socials} key={review.place_review_id} />
      ))}
    </>
  );
};

export default Reviews;
