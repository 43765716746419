import React from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { Input, Button, FlexColumns, InputErrorMessage } from '../../elements';
import colors from '../../../theme/styles/colors';

const Span = styled.span`
  color: ${colors.danger};
`;

const SignUpForm = props => {
  const {
    values,
    touched,
    setFieldValue,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isLoading,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="columns is-multiline">
        <div className="column is-half">
          <div className="field">
            <label className="label">
              First Name<Span>*</Span>
            </label>
            <div className="control">
              <Input
                className="input"
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={event => {
                  setFieldValue('firstName', event.target.value);
                  setFieldValue('displayName', `${event.target.value} ${values.lastName}`);
                }}
                onBlur={handleBlur}
              />
              <InputErrorMessage touched={touched.firstName} errors={errors.firstName} />
            </div>
          </div>
        </div>
        <div className="column is-half">
          <div className="field">
            <label className="label">
              Last Name<Span>*</Span>
            </label>
            <div className="control">
              <Input
                className="input"
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={event => {
                  setFieldValue('lastName', event.target.value);
                  setFieldValue('displayName', `${values.firstName} ${event.target.value} `);
                }}
                onBlur={handleBlur}
              />
              <InputErrorMessage touched={touched.lastName} errors={errors.lastName} />
            </div>
          </div>
        </div>
        <div className="column is-half">
          <div className="field">
            <label className="label">
              Display Name<Span>*</Span>
            </label>
            <div className="control">
              <Input
                className="input"
                type="text"
                name="displayName"
                value={values.displayName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputErrorMessage touched={touched.displayName} errors={errors.displayName} />
            </div>
          </div>
        </div>
        <div className="column is-half">
          <div className="field">
            <label className="label">
              Email Address<Span>*</Span>
            </label>
            <div className="control">
              <Input
                className="input"
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputErrorMessage touched={touched.email} errors={errors.email} />
            </div>
          </div>
        </div>
        <div className="column is-half">
          <div className="field">
            <label className="label">
              Password<Span>*</Span>
            </label>
            <div className="control">
              <Input
                className="input"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputErrorMessage touched={touched.password} errors={errors.password} />
            </div>
          </div>
        </div>
        <div className="column is-half">
          <div className="field">
            <label className="label">
              Confirm Password<Span>*</Span>
            </label>
            <div className="control">
              <Input
                className="input"
                type="password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputErrorMessage
                touched={touched.confirmPassword}
                errors={errors.confirmPassword}
              />
            </div>
          </div>
        </div>
      </div>
      <FlexColumns>
        <Button
          className={isLoading && 'is-loading'}
          type="submit"
          name="Sign Up"
          isFullWidth
          backgroundColor="primaryColor"
          textProps={{
            color: isLoading ? 'primaryColor' : 'white',
            weight: 'semibold',
            size: 'small',
          }}
        />
      </FlexColumns>
    </form>
  );
};

const MyEnhancedForm = withFormik({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
  }),

  validate: values => {
    const errors = {};
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Confirm Password must be matches with password!';
    }

    return errors;
  },
  validationSchema: yup.object().shape({
    firstName: yup.string().required('First name is required!'),
    lastName: yup.string().required('Last name is required!'),
    email: yup
      .string()
      .email('Enter valid email address!')
      .required('Email address is required!'),
    displayName: yup.string().required('Display name is required!'),
    password: yup.string().required('password is required!'),
    confirmPassword: yup.string().required('Confirm password is required!'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(false);
    props.onSubmit(values);
  },

  displayName: 'SignUpForm',
})(SignUpForm);

export default MyEnhancedForm;
