import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import { capitalize } from 'lodash';

import colors from '../../../../../theme/styles/colors';
import bgImg from '../../../../../assets/images/food-1245955_640.jpg';
import { Text, FlexRows } from '../../../../../components/elements';

const Header = styled(FlexRows)`
  padding: 0rem 0rem;
  width: 100%;
`;

const Content = styled.div`
  padding: 1.5rem;
`;

const BgImage = styled.div`
  && {
    background-image: ${({ image }) => (image ? `url(${image})` : `url(${bgImg})`)};
    padding: 12rem;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`;

const HeaderWrapper = styled.div`
  height: ${({ headerHeight }) => (headerHeight ? `${headerHeight}rem` : '0rem')};
  transition: height 300ms ease 0s, opacity 300ms ease 0s;
`;

const Details = ({ menuItem, onClose, isOpen }) => {
  const [scrollPos, setScrollPos] = useState(0);
  const [isAvailable, setIsAvailable] = useState(true);
  const elementRef = useRef();
  const onScroll = () => {
    const { scrollTop } = elementRef.current;
    setScrollPos(scrollTop);
  };

  useEffect(() => {
    if (menuItem.availability_status === 'UNAVAILABLE') {
      setIsAvailable(false);
    } else {
      setIsAvailable(true);
    }
  }, [menuItem]);

  return (
    <>
      <div>
        <a onClick={onClose}>
          <div className="cross">
            <IoMdClose
              size={20}
              color={colors.dark}
              style={{ cursor: 'pointer' }}
              onClick={onClose}
            />
          </div>
        </a>
      </div>
      {!menuItem.image && (
        <HeaderWrapper className="modal-card-head" id="scroll" headerHeight={4}>
          <Header>
            <Text size="large" weight="semibold" paddingBottom={0.5} className="item-title">
              {menuItem.name}
            </Text>
          </Header>
        </HeaderWrapper>
      )}
      {menuItem.image && (
        <HeaderWrapper id="scroll" headerHeight={scrollPos > 400 ? 4 : 0}>
          <div className="modal-card-head">
            <Header>
              <Text size="large" weight="semibold" paddingBottom={0.5} className="item-title">
                {menuItem.name}
              </Text>
            </Header>
          </div>
        </HeaderWrapper>
      )}
      <div className="modal-card-body" ref={elementRef} onScroll={onScroll}>
        <>
          {menuItem.image && <BgImage image={menuItem.image} />}
          <Content>
            {menuItem.image && (
              <Text size="large" weight="semibold" paddingBottom={0.5} className="item-title">
                {menuItem.name}
              </Text>
            )}
            {(!isAvailable || !isOpen) && (
              <Text
                color="darkGrey"
                size="smaller"
                lineHeight="normal"
                weight="regular"
                className="item-info"
                paddingBottom={1}
              >
                {capitalize(menuItem.name)} not available right now
              </Text>
            )}
            <Text
              color="darkGrey"
              size="smaller"
              lineHeight="normal"
              weight="regular"
              className="item-info"
              paddingBottom={1}
            >
              {menuItem.description}
            </Text>
            {menuItem.price && (
              <>
                <Text color="darkGrey" size="small">
                  Price
                </Text>
                <Text color="darkGrey" size="smaller">
                  ${parseFloat(menuItem.price).toFixed(2)}
                </Text>
              </>
            )}
          </Content>
        </>
      </div>
    </>
  );
};

export default Details;
