import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { MdClear } from 'react-icons/md';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import { first } from 'lodash';
import { animateScroll } from 'react-scroll';

import { FlexRows, Text, Flex, Icon, Pagination, Pager } from '../../components/elements';
import Place from './desktop/PlaceCard';
import Event from './desktop/EventCard';
import ItemCard from './desktop/ItemCard';
import Loading from '../../components/global/mobile/MobileLoading';
import client from '../../utils/apolloClient';

const Container = styled.div`
  margin-top: 0rem;
`;
const Wrapper = styled.div`
  padding: 1rem 0rem;
`;

const TextStyle = styled(Text).attrs({ size: 'tiny', color: 'dark', weight: 'semibold' })`
  padding-left: 0.5rem;
  &&& {
    :hover {
      ${({ theme: { primaryColor }, disabled }) => !disabled && `color:${primaryColor}`};
    }
  }
`;

const Tabs = styled(FlexRows).attrs({ justify: 'flex-start' })`
  background: ${({ theme: { colors } }) => colors.white};
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyTheta};
  position: sticky;
  top: 5rem;
  background: #ffffff;
  z-index: 200;
  padding: 0rem 0rem;
`;
const Item = styled.a`
  padding: 0.5rem 1rem;
  border-bottom: 2px solid
    ${({ theme: { colors }, active }) => (active ? colors.primaryColor : 'transparent')};
  ${({ disabled }) => disabled && 'opacity: 0.5'};
  position: relative;
  top: 2px;
`;

const FlexStyle = styled(Flex).attrs({ justify: 'flex-start' })``;
const PaginationWrapper = styled.div`
  &&& {
    nav {
      padding: 0rem;
    }
  }
`;

const searchFollowQuery = gql`
  query search_follows($input: SearchInput) {
    search_follows(input: $input) {
      follow_listing {
        follow_id
        object_id
        object_type
        user_id
        type
      }
    }
  }
`;

const searchDealsQuery = gql`
  query search_place_deal($input: SearchInput) {
    search_place_deal(input: $input) {
      place_deal_aggregation {
        aggregation_listing {
          name
          count
        }
      }
    }
  }
`;

const searchSocialQuery = gql`
  query search_social($input: SearchInput) {
    search_social(input: $input) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const DesktopView = ({
  places,
  items,
  events,
  query,
  history,
  currentDate,
  placeLoading,
  itemLoading,
  eventLoading,
  totalPlacePage,
  totalEventPage,
  totalItemPage,
  handlePageClick,
}) => {
  const [socials, setSocials] = useState([]);
  const [follows, setFollows] = useState([]);
  const [deals, setDeals] = useState([]);
  const [activeTab, setActive] = useState('places');
  const { isLoggedIn, userId } = useStoreState(state => state.auth);
  const placeIds = places.map(item => item.place_id);

  const fetchPlaceFollow = useCallback(() => {
    if (isLoggedIn) {
      client.clientPrivate
        .query({
          query: searchFollowQuery,
          variables: {
            input: {
              filter: {
                follow_filter: {
                  object_id: placeIds,
                  object_type: 'PLACE',
                  type: 'FOLLOW',
                  user_id: userId,
                },
              },
            },
          },
        })
        .then(({ data: data2 }) => {
          if (data2) {
            setFollows(data2.search_follows.follow_listing);
          }
        });
    }
  }, [isLoggedIn, placeIds, userId]);

  const fetchPlaceDeals = useCallback(() => {
    client.clientPublic
      .query({
        query: searchDealsQuery,
        variables: {
          input: {
            filter: {
              place_deal_filter: {
                place_id: placeIds,
                end_date_range: {
                  start_date: currentDate,
                },
              },
            },
          },
        },
      })
      .then(({ data: data2 }) => {
        if (data2) {
          const dealsData =
            data2.search_place_deal.place_deal_aggregation.length !== 0 &&
            first(data2.search_place_deal.place_deal_aggregation);
          if (dealsData) {
            setDeals(dealsData.aggregation_listing);
          }
        }
      });
  }, [currentDate, placeIds]);

  const fetchSocial = useCallback(() => {
    const eventIds = events.map(item => item.event_id);
    const itemIds = items.map(item => item.item_id);
    const objectIds = placeIds.concat(eventIds).concat(itemIds);
    const social_filter = { object_id: objectIds };
    // Object.assign(social_filter, isLoggedIn && { user_id: userId });
    client.clientPublic
      .query({
        query: searchSocialQuery,
        variables: {
          input: { filter: { social_filter } },
        },
      })
      .then(({ data: data2 }) => {
        if (data2) {
          setSocials(data2.search_social.social_aggregation.total_social_per_object_id);
        }
      });
  }, [events, items, placeIds]);

  useEffect(() => {
    fetchSocial();
    if (places.length !== 0) {
      fetchPlaceFollow();
      fetchPlaceDeals();
    }
  }, [fetchPlaceDeals, fetchPlaceFollow, fetchSocial, places.length]);

  useEffect(() => {
    if (places.length !== 0) {
      setActive('places');
    } else if (items.length !== 0) {
      setActive('items');
    } else if (events.length !== 0) {
      setActive('events');
    }
  }, [events.length, items.length, places.length]);

  const handleTab = value => {
    animateScroll.scrollToTop();
    setActive(value);
  };

  const orderFoods = places.filter(item => item.service_type && item.service_type.length !== 0);

  return (
    <Pager>
      <Container>
        <Wrapper>
          <FlexRows justify="space-between">
            <Text size="big" weight="semibold" paddingBottom={1}>
              Search results for &quot;{query}&quot;
            </Text>
            <a onClick={() => history.goBack()}>
              <Text size="big" weight="semibold" paddingBottom={1}>
                <MdClear size={30} />
              </Text>
            </a>
          </FlexRows>
        </Wrapper>
        <Tabs>
          <Item
            active={activeTab === 'places'}
            onClick={() => places.length !== 0 && handleTab('places')}
            disabled={places.length === 0}
          >
            <FlexStyle>
              <Icon name="headerPlaceAroundMe" size={1.2} />
              <TextStyle
                color={activeTab === 'places' ? 'primaryColor' : 'dark'}
                disabled={places.length === 0}
              >
                Places
              </TextStyle>
            </FlexStyle>
          </Item>

          <Item
            active={activeTab === 'items'}
            onClick={() => items.length !== 0 && handleTab('items')}
            disabled={items.length === 0}
          >
            <FlexStyle>
              <Icon name="headerFindItem" size={1.2} />
              <TextStyle
                color={activeTab === 'items' ? 'primaryColor' : 'dark'}
                disabled={items.length === 0}
              >
                Items
              </TextStyle>
            </FlexStyle>
          </Item>

          <Item
            active={activeTab === 'events'}
            onClick={() => events.length !== 0 && handleTab('events')}
            disabled={events.length === 0}
          >
            <FlexStyle>
              <Icon name="headerDiscoverEvent" size={1.2} />
              <TextStyle
                color={activeTab === 'events' ? 'primaryColor' : 'dark'}
                disabled={events.length === 0}
              >
                Discover Events
              </TextStyle>
            </FlexStyle>
          </Item>
          <Item
            active={activeTab === 'order-food'}
            onClick={() => places.length !== 0 && handleTab('order-food')}
            disabled={places.length === 0}
          >
            <FlexStyle>
              <Icon name="headerPlaceAroundMe" size={1.2} />
              <TextStyle
                color={activeTab === 'places' ? 'primaryColor' : 'dark'}
                disabled={places.length === 0}
              >
                Order Food
              </TextStyle>
            </FlexStyle>
          </Item>
        </Tabs>
        <Wrapper>
          <br />
          {placeLoading || itemLoading || eventLoading ? (
            <Loading />
          ) : (
            <>
              {activeTab === 'places' && (
                <div className="columns is-multiline">
                  {places.map(place => (
                    <div className="column is-4">
                      <Place
                        place={place}
                        key={place.place_id}
                        socials={socials.filter(item => item.object_id === place.place_id)}
                        follow={follows.filter(item => item.object_id === place.place_id)}
                        deal={deals.filter(item => item.name === place.place_id)}
                      />
                    </div>
                  ))}
                </div>
              )}
              {activeTab === 'order-food' && (
                <div className="columns is-multiline">
                  {orderFoods.map(place => (
                    <div className="column is-4">
                      <Place
                        isActiveOrderFood
                        place={place}
                        key={place.place_id}
                        socials={socials.filter(item => item.object_id === place.place_id)}
                        follow={follows.filter(item => item.object_id === place.place_id)}
                        deal={deals.filter(item => item.name === place.place_id)}
                      />
                    </div>
                  ))}
                </div>
              )}
              {activeTab === 'items' && (
                <div className="columns is-multiline">
                  {items.map(item => (
                    <div className="column is-4" key={item.item_id}>
                      <ItemCard
                        item={item}
                        socials={socials.filter(item2 => item2.object_id === item.item_id)}
                      />
                    </div>
                  ))}
                </div>
              )}
              {activeTab === 'events' && (
                <div className="columns is-multiline">
                  {events.map(event => (
                    <div className="column is-4" key={event.event_id}>
                      <Event
                        event={event}
                        socials={socials.filter(item2 => item2.object_id === event.event_id)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          <PaginationWrapper className="columns">
            <div className="column is-8">
              {activeTab === 'places' && totalPlacePage >= 2 && (
                <Pagination
                  currentPage={1}
                  pageCount={totalPlacePage}
                  handlePageClick={value => {
                    handlePageClick(value.selected, 'place');
                  }}
                />
              )}
              {activeTab === 'items' && totalItemPage >= 2 && (
                <Pagination
                  currentPage={1}
                  pageCount={totalItemPage}
                  handlePageClick={value => {
                    handlePageClick(value.selected, 'item');
                  }}
                />
              )}
              {activeTab === 'events' && totalEventPage >= 2 && (
                <Pagination
                  currentPage={1}
                  pageCount={totalEventPage}
                  handlePageClick={value => {
                    handlePageClick(value.selected, 'event');
                  }}
                />
              )}
            </div>
          </PaginationWrapper>
        </Wrapper>
      </Container>
    </Pager>
  );
};

export default DesktopView;
