import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { isNull } from 'lodash';
import ReactLoading from 'react-loading';

import { Text, ShowMoreText, FlexColumns } from '../../../../components/elements';
import theme from '../../../../theme';

const dealsQuery = gql`
  query search_place_deal($input: SearchInput) {
    search_place_deal(input: $input) {
      place_deal_listing {
        place_id
        deal_id
        name
        voucher_code
        description
        tagline
        audit {
          created_at
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 3rem 1rem;
`;

const Content = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  border-radius: 6px;
  margin-bottom: 1rem;
`;
const MessageContainer = styled(FlexColumns)`
  height: 50vh;
`;

const Deals = ({ place }) => {
  return (
    <Container>
      <Query
        query={dealsQuery}
        variables={{ input: { filter: { place_deal_filter: { place_id: place.place_id } } } }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return (
              <MessageContainer>
                <ReactLoading type="spin" color={theme.primaryColor} width={50} height={50} />
              </MessageContainer>
            );
          }
          if (error) {
            return (
              <MessageContainer>
                <Text weight="semibold" color="darkGrey">
                  {error.message}
                </Text>
              </MessageContainer>
            );
          }

          if (isNull(data.search_place_deal)) {
            return (
              <MessageContainer>
                <Text weight="semibold" color="darkGrey">
                  No Event Found
                </Text>
              </MessageContainer>
            );
          }

          return <View deals={data.search_place_deal.place_deal_listing} />;
        }}
      </Query>
    </Container>
  );
};

const View = ({ deals }) => {
  if (deals.length === 0) {
    return (
      <MessageContainer>
        <Text weight="semibold" color="darkGrey">
          No Event Found
        </Text>
      </MessageContainer>
    );
  }

  return (
    <>
      {deals.map(deal => (
        <>
          <Content key={deal.deal_id}>
            <Text size="small" weight="semibold" color="supportingColor2" paddingBottom={0.2}>
              {deal.name}
            </Text>
            <Text size="micro" color="darkGrey" paddingBottom={0.5}>
              {moment(deal.audit.created_at).format('LLLL')}
            </Text>
            <ShowMoreText size="tiny" color="darkGrey" limit={200} content={deal.description} />
            {deal.voucher_code && (
              <>
                <br />
                <div className="is-flex">
                  <Text className="is-uppercase" size="small" color="darkGrey" weight="regular">
                    To Claim User Voucher Code :
                  </Text>
                  &nbsp;
                  <Text className="is-uppercase" size="small" color="darkGrey" weight="semibold">
                    {deal.voucher_code}
                  </Text>
                </div>
              </>
            )}
          </Content>
        </>
      ))}
    </>
  );
};

export default Deals;
