import React from 'react';
import styled from 'styled-components';

import { Modal, Icon, Text, FlexRows } from '../../../../components/elements';
import { trackEvent } from '../../../../utils/analytics';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
  &&& {
    .column {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const ModalFooter = styled.a`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 100;
  width: 100%;
  padding: 0.5rem;
  position: fixed;
  bottom: 0;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const Flex = styled(FlexRows)`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.specialGrey};
`;

const Item = ({ title, isIconActive, onSelect }) => (
  <a onClick={onSelect}>
    <Flex justify="space-between">
      <div>
        <Text>{title}</Text>
      </div>
      <div className="is-flex">
        {isIconActive && (
          <>
            <Icon iconName="fas fa-check" color="primaryColor" />
          </>
        )}
      </div>
    </Flex>
  </a>
);

const SortByModal = ({ isActive, setActiveSortByModal }) => (
  <Modal isActive={isActive}>
    <ModalHeader>
      <div className="columns is-mobile">
        <div className="column is-2">
          <a onClick={() => setActiveSortByModal(false)}>
            <Icon color="white" size="large" />
          </a>
        </div>
        <div className="column">
          <Text className="is-uppercase" align="center" color="white" weight="bold" size="small">
            Sort By
          </Text>
        </div>
        <div className="column is-4">
          <Text className="is-uppercase" color="white" weight="semibold" size="smaller">
            Clear All
          </Text>
        </div>
      </div>
    </ModalHeader>
    <Content>
      <Item title="Deals & Vouchers" isIconActive={false} onSelect={() => {}} />
    </Content>
    <ModalFooter onClick={() => setActiveSortByModal(false)}>
      <Text className="is-uppercase" color="white" align="center" weight="bold" size="small">
        Filter
      </Text>
    </ModalFooter>
  </Modal>
);

export default SortByModal;
