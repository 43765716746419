import axios from 'axios';
import config from './config';

const url = `https://${config.apiPrefix}content.kravein.com.au/static`;
const businessTypesUrl = `${url}/place/business/type.json`;
const cuisineUrl = `${url}/menu/cuisine/style.json`;
const itemTypesUrl = `${url}/menu/item/type.json`;
const diningStyleUrl = `${url}/place/dining/style.json`;
const parkingDetailsUrl = `${url}/common/parking/detail.json`;
const publicTransportUrl = `${url}/common/public/transport.json`;
const noiseLevelUrl = `${url}/place/noise/level.json`;
const dressCodeUrl = `${url}/place/dress/code.json`;
const ambienceUrl = `${url}/place/ambience.json`;
const goodeForUrl = `${url}/place/good/for.json`;
const serviceTypesUrl = `${url}/place/service/type.json`;
const eventTypesUrl = `${url}/event/type.json`;
const eventCategoriesUrl = `${url}/event/listing/type.json`;
const featureUrl = `${url}/common/feature.json`;
const eventServiceCategoryUrl = `${url}/event/category.json`;
const serviceCategoryUrl = `${url}/place/service/category.json`;

export const getPlaceTypes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(businessTypesUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getCuisines = () =>
  new Promise((resolve, reject) => {
    axios
      .get(cuisineUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getItemTypes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(itemTypesUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getDiningStyle = () =>
  new Promise((resolve, reject) => {
    axios
      .get(diningStyleUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getParkingDetails = () =>
  new Promise((resolve, reject) => {
    axios
      .get(parkingDetailsUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getPublicTransports = () =>
  new Promise((resolve, reject) => {
    axios
      .get(publicTransportUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getNoiseLevels = () =>
  new Promise((resolve, reject) => {
    axios
      .get(noiseLevelUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getDressCodes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(dressCodeUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getAmbience = () =>
  new Promise((resolve, reject) => {
    axios
      .get(ambienceUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getGoodFor = () =>
  new Promise((resolve, reject) => {
    axios
      .get(goodeForUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getServicesTypes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(serviceTypesUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getEventTypes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(eventTypesUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getEventCategories = () =>
  new Promise((resolve, reject) => {
    axios
      .get(eventCategoriesUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getFeatures = () =>
  new Promise((resolve, reject) => {
    axios
      .get(featureUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getEventServiceCategory = () =>
  new Promise((resolve, reject) => {
    axios
      .get(eventServiceCategoryUrl)
      .then(response => {
        // console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getServiceCategory = () =>
  new Promise((resolve, reject) => {
    axios
      .get(serviceCategoryUrl)
      .then(response => {
        console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
