import React from 'react';
import styled from 'styled-components';

import { MdChevronLeft } from 'react-icons/md';

import { Text, Modal, FlexRows, LocationSuggestion } from '../../elements';

const ModalHeader = styled(FlexRows)`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const Container = styled.div`
  padding: 0.5rem 1rem;
  height: 100vh;
`;

const MobileSearchLocationModal = ({ isActive, onClose, onSelect }) => {
  return (
    <Modal isActive={isActive}>
      <ModalHeader justify="space-between">
        <a onClick={onClose}>
          <Text color="white">
            <MdChevronLeft size={25} />
          </Text>
        </a>
        <Text className="is-uppercase" align="center" color="white" weight="bold" size="medium">
          SEARCH ADDRESS
        </Text>
        <Text />
      </ModalHeader>
      <div className="modal-card">
        <Container>
          <LocationSuggestion
            initialValue=""
            onSelect={location => {
              onSelect(location);
              onClose();
            }}
          />
        </Container>
      </div>
    </Modal>
  );
};

export default MobileSearchLocationModal;
