import React, { useEffect, useState, useCallback } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { isNull, omit } from 'lodash';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useStoreState, useStoreActions } from 'easy-peasy';

import Layout from '../../components/global/MenuLayout';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import { isDesktop } from '../../utils/helpers';
import client from '../../utils/apolloClient';
import { getCart, parseOrderDatabaseInputItems } from './helpers';
import { getUserAddress, parseOrderDatabaseInputItemsWhileSubmissionAgain } from '../Menu/helpers';
import { Text, FlexRows, Icon } from '../../components/elements';
import { OrderFragments } from '../../utils/fragments';

const Flex = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 84vh;
  }
`;

const Button = styled(FlexRows)`
  background-color: ${({ theme: { colors: themeColors } }) => themeColors.primaryColor};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  width: 20rem;
  cursor: pointer;
`;

const actionOrderMutation = gql`
  mutation actionOrder($input: NavigateMenuOrderInput) {
    action_menu_order(input: $input) {
      menu_order_id
      place_id
      service_type
      service_type_setting_id
      menu_id
      menu_name
      status
      slug
      order_reference
      address {
        address_id
        type
        is_default
        contact_name
        contact_phone
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        status
      }
      audit {
        created_at
        updated_by
        updated_at
      }
      include_cuttlery
      billing {
        sub_total
        delivery_fee
        order_total
        order_amount
        place_charges {
          name
          amount
        }
        tax {
          type
          value
        }
        disbursement {
          card_customer_id
          id
          type
          amount
          payment_reference_id
          timestamp
          comment
        }
      }
      status_history {
        created_by
        created_at
        status
        action
        note {
          type
          value
          date
        }
      }
      group_order
      expiry_time
      limit_per_invitee
      max_line_item_per_invitee
      payment_preference
      guest_view
      estimated_completion_time
      estimates {
        type
        value_in_mins
      }
      adjustments {
        type
        value
        note {
          type
          value
          date
        }
      }
      overall_quantity
      large_order
      contact_less_delivery
      no_of_attendees
      sales_method
      preferred_time
      include_cuttlery
      food_setup_required
      note {
        type
        value
        date
      }
      contact {
        contact_name
        contact_phone
      }
      payment {
        card_id
        payment_method
        payment_details {
          id
          amount_money {
            amount
            currency
          }
          total_money {
            amount
            currency
          }
          status
          created_at
          updated_at
        }
        refund_details {
          id
          amount_money {
            amount
            currency
          }
          status
          created_at
          updated_at
        }
      }
      line_item {
        item_link_id
        menu_item_id
        prompt_id
        variant_id
        variant_name
        name
        quantity
        price_per_item
        net_price_per_item
        tax_per_item
        price
        tax
        overall_price
        modifier {
          modifier_group_id
          menu_item_id
          prompt_id
          variant_id
          variant_name
          name
          quantity
          price_per_item
          net_price_per_item
          tax_per_item
          price
          tax
        }
        menu_item_rating
        special_request
        user_id
        caption
        private
        sold_out
      }
      error {
        description
      }
    }
  }
`;

const updateOrderMutation = gql`
  mutation updateOrder($input: MenuOrderInput) {
    update_menu_order(input: $input) {
      ...OrderFragments
      error {
        description
      }
    }
  }
  ${OrderFragments}
`;

const View = ({
  loading,
  order,
  userId,
  user,
  history,
  deliveryAddress,
  setDeliveryAddress,
  userAddress,
  setUserAddress,
  setToMainOrderCart,
}) => {
  const isMobile = !isDesktop();
  const [cartUpdateLoading, setCartUpdateLoading] = useState(false);
  const { setCartQuantity } = useStoreActions(state1 => state1.global);

  const handleDeliveryAddress = address => {
    setCartUpdateLoading(true);
    setDeliveryAddress(address);
    const finalOne = parseOrderDatabaseInputItemsWhileSubmissionAgain(order.line_item);
    const updateInput = {
      user_id: userId,
      menu_order_id: order.menu_order_id,
      place_id: order.place_id,
      // service_type: order.service_type,
      service_type_setting_id: order.service_type_setting_id,
      menu_id: order.menu_id,
      address,
      line_item: finalOne,
    };

    client.clientPublic
      .mutate({ mutation: updateOrderMutation, variables: { input: updateInput } })
      .then(({ data: { update_menu_order } }) => {
        if (update_menu_order.error) {
          update_menu_order.error.map(item => toast.error(item.description));
        } else {
          setToMainOrderCart({ ...update_menu_order, place: order.place });
        }
        setCartUpdateLoading(false);
      })
      .catch(error => {
        console.error({ error });
        setCartUpdateLoading(false);
      });
  };

  const updateUserAddress = address => {
    setUserAddress(userAddress.concat(address));
  };

  const lineItem = order ? parseOrderDatabaseInputItems(order.line_item) : [];
  const state = {
    userId,
    name: user ? `${user.first_name} ${user.last_name}` : null,
    mobile: null,
    deliveryAddress,
    userAddress,
  };

  const func = {
    handleDeliveryAddress,
    updateUserAddress,
  };

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={actionOrderMutation}
      onCompleted={({ action_menu_order }) => {
        if (action_menu_order.error) {
          action_menu_order.error.map(item => toast.error(item.description));
        } else {
          setCartQuantity(0);
          history.push(`/order-details/${action_menu_order.menu_order_id}`, {
            order: { ...action_menu_order, place: order.place },
          });
        }
      }}
    >
      {(action_menu_order, { loading: actionLoading }) => (
        <>
          {isMobile ? (
            <div className="is-hidden-desktop">
              <MobileView />
            </div>
          ) : (
            <div className="is-hidden-touch">
              <DesktopView
                cartUpdateLoading={cartUpdateLoading}
                dataBaseLineItem={order.line_item}
                setToMainOrderCart={setToMainOrderCart}
                actionLoading={actionLoading}
                order={{ ...order, line_item: lineItem }}
                loading={loading}
                state={state}
                func={func}
                onSubmit={values => {
                  const input = {
                    user_id: userId,
                    menu_order_id: order.menu_order_id,
                    action: 'RECEIVE',
                    include_cuttlery: values.include_cuttlery,
                    payment: {
                      payment_method: values.payment_method,
                    },
                    contact: {
                      contact_name: values.contact_name,
                      contact_phone: values.contact_phone,
                    },
                  };
                  Object.assign(
                    input,
                    values.notes && { note: { value: values.notes } },
                    values.no_of_attendees && { no_of_attendees: values.no_of_attendees },
                    deliveryAddress &&
                      deliveryAddress.address_line_1 && { address: deliveryAddress },
                  );
                  action_menu_order({ variables: { input } });
                }}
              />
            </div>
          )}
        </>
      )}
    </Mutation>
  );
};

const EmptyComponent = ({ history }) => {
  return (
    <div className="columns is-centered">
      <Flex className="column is-12">
        <Icon name="cartIcon" size={8} />
        <br />
        <Text size="large" weight="semibold">
          There are no items in your cart?
        </Text>
        <Text size="tiny" color="darkGrey">
          Look like you haven&apos;t made your choice yet....
        </Text>

        <Button onClick={() => history.push('/order-food')}>
          <Text weight="semibold" size="smedium" color="white">
            Browse Restaurants
          </Text>
        </Button>
      </Flex>
    </div>
  );
};

const Checkout = ({ history, location }) => {
  const [order, setOrder] = useState({ line_item: [] });
  const [cartLoading, setCartLoading] = useState(true);
  const { userId, user } = useStoreState(state => state.auth);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [userAddress, setUserAddress] = useState([]);

  const fetchCart = useCallback(async () => {
    setCartLoading(true);
    // else if (location && location.state && location.state.order) {
    //   setOrder(location.state.order);
    // }
    try {
      const list = await getCart({
        filter: { menu_order_filter: { user_id: userId, status: 'DRAFT' } },
      });
      if (list.length !== 0) {
        setOrder(list[0]);
        setDeliveryAddress(list[0].address);
      } else {
        setOrder(null);
      }
      setCartLoading(false);
    } catch (err) {
      console.error(err);
      setCartLoading(false);
    }
  }, [userId]);

  const fetchUserAddress = useCallback(async () => {
    try {
      const list = await getUserAddress({
        userId,
      });
      if (list.length !== 0) {
        setUserAddress(list);
      }
    } catch (err) {
      console.error(err);
    }
  }, [userId]);

  useEffect(() => {
    fetchUserAddress();
    fetchCart();
  }, [fetchCart, fetchUserAddress]);

  const isEmpty = isNull(order);

  return (
    <Layout disableSubHeader disableCart>
      {isEmpty ? (
        <EmptyComponent history={history} />
      ) : (
        <View
          order={order}
          deliveryAddress={omit(deliveryAddress, ['__typename'])}
          setDeliveryAddress={setDeliveryAddress}
          userAddress={userAddress}
          setUserAddress={setUserAddress}
          loading={cartLoading}
          userId={userId}
          user={user}
          history={history}
          setToMainOrderCart={setOrder}
        />
      )}
    </Layout>
  );
};

export default Checkout;
