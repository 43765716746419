import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import moment from 'moment';

import { Text } from '../../../../components/elements';
import { getZoneTime } from '../../../../utils/eventHelpers';

const Container = styled.div`
  margin: 0;
`;

const Header = styled.div`
  padding: 0.5rem 0rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const Element = styled.div`
  padding: 1rem 0rem;
`;
const Span = styled.span`
  color: ${({ theme: { colors: color } }) => color.darkGrey};
  font-weight: ${({ theme: { fonts } }) => fonts.semibold};
`;
const dealsQuery = gql`
  query deals($input: SearchInput) {
    search_place_event_voucher(input: $input) {
      place_event_voucher_listing {
        deal_id
        name
        tagline
        description
        voucher_code
      }
    }
  }
`;

const Deals = ({ event }) => {
  return (
    <Query
      query={dealsQuery}
      variables={{
        input: {
          filter: {
            place_event_voucher_filter: {
              place_id: event.place_id,
              event_id: event.event_id,
              status: 'ACTIVE',
              end_date_range: {
                start_date: getZoneTime(moment(), event.latitude, event.longitude),
              },
            },
          },
        },
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return '';
        }
        if (error) {
          return 'error';
        }
        const { search_place_event_voucher } = data;
        if (search_place_event_voucher.place_event_voucher_listing.length === 0) {
          return '';
        }
        return (
          <>
            <Container>
              <Header>
                <Text size="large" weight="semibold" paddingTop={0.5}>
                  Deals
                </Text>
              </Header>
              {search_place_event_voucher.place_event_voucher_listing.map(deal => (
                <Element key={deal.deal_id}>
                  <Text size="medium" weight="semibold" paddingBottom={0.5}>
                    {deal.tagline}
                  </Text>
                  <Text size="small" color="darkGrey" paddingBottom={0.5}>
                    {deal.description}
                  </Text>
                  {deal.voucher_code && (
                    <Text size="small" weight="semibold" color="darkGrey">
                      To Claim use Voucher Code : <Span>{deal.voucher_code}</Span>
                    </Text>
                  )}
                </Element>
              ))}
            </Container>
          </>
        );
      }}
    </Query>
  );
};

export default Deals;
