import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { formatDate } from '../../../utils/helpers';

import { Text, FlexRows, FlexColumns } from '../../../components/elements';

const PageHeader = styled(FlexRows)`
  padding: 0.2rem 1rem;
  /* background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha}; */
`;

const StepWrapper = styled(FlexColumns).attrs({ align: 'flex-start' })`
  padding: 0.5rem 1rem;
`;

const Info = ({ event, occurrence }) => {
  const [price, setPrice] = useState('');

  useEffect(() => {
    if (event.minimum_price === null && event.maximum_price === null) {
      setPrice('');
    } else if (event.minimum_price === 0 && event.maximum_price === 0) {
      setPrice(`Book Now: Free`);
    } else if (event.minimum_price === event.maximum_price) {
      setPrice(`AUD $${parseFloat(event.minimum_price).toFixed(2)}`);
    } else if (event.minimum_price !== 0 && event.maximum_price !== 0) {
      setPrice(
        `AUD $${parseFloat(event.minimum_price).toFixed(2)} - AUD $${parseFloat(
          event.maximum_price,
        ).toFixed(2)}`,
      );
    } else {
      setPrice(
        `$${parseFloat(event.minimum_price).toFixed(2)} - $${parseFloat(
          event.maximum_price,
        ).toFixed(2)}`,
      );
    }
  }, [event.maximum_price, event.minimum_price]);

  return (
    <>
      <PageHeader justify="flex-start">
        <Text className="is-capitalized" size="medium" weight="semibold" lineSpacing="loose">
          {event.name}
        </Text>
      </PageHeader>
      <StepWrapper>
        <Text size="small" weight="medium" color="darkGrey">
          {formatDate(event.start)}
        </Text>
        <Text size="small" weight="medium" color="darkGrey">
          {price}
        </Text>
        <Text size="small" weight="medium" color="darkGrey">
          Available from {formatDate(occurrence.ticket_sale_start)}
        </Text>
      </StepWrapper>
    </>
  );
};

export default Info;
