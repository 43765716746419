import React from 'react';
import styled from 'styled-components';
import { RectLoader, FlexRows } from '../elements';

const Container = styled.div`
  padding: 1rem;
  &&& {
    line-height: 0.5;
  }
`;

const EventsCardLoader = () => (
  <>
    <Container>
      <RectLoader x={0} y={10} height={40} width={window.innerWidth / 1.5} />
      <RectLoader x={0} y={10} height={30} width={window.innerWidth / 2} />
      <RectLoader x={0} y={10} height={150} width={window.innerWidth - 12} />
      <RectLoader x={0} y={10} height={35} width={100} />
      <RectLoader x={0} y={10} height={35} width={window.innerWidth / 1.5} />
      <RectLoader x={0} y={10} height={35} width={window.innerWidth / 1.5} />
    </Container>
    <Container>
      <RectLoader x={0} y={10} height={40} width={window.innerWidth / 1.5} />
      <RectLoader x={0} y={10} height={30} width={window.innerWidth / 2} />
      <RectLoader x={0} y={10} height={150} width={window.innerWidth - 12} />
      <RectLoader x={0} y={10} height={35} width={100} />
      <RectLoader x={0} y={10} height={35} width={window.innerWidth / 1.5} />
      <RectLoader x={0} y={10} height={35} width={window.innerWidth / 1.5} />
    </Container>
    <Container>
      <RectLoader x={0} y={10} height={40} width={window.innerWidth / 1.5} />
      <RectLoader x={0} y={10} height={30} width={window.innerWidth / 2} />
      <RectLoader x={0} y={10} height={150} width={window.innerWidth - 12} />
      <RectLoader x={0} y={10} height={35} width={100} />
      <RectLoader x={0} y={10} height={35} width={window.innerWidth / 1.5} />
      <RectLoader x={0} y={10} height={35} width={window.innerWidth / 1.5} />
    </Container>
  </>
);

export default EventsCardLoader;
