import React from 'react';
import styled from 'styled-components';
import { Pager } from '../../components/elements';
import EditDetailsForm from './desktop/EditDetailsForm';

const Container = styled(Pager)`
  padding-top: 2rem;
`;

const DesktopView = ({ onSubmit, loading }) => (
  <Container>
    <EditDetailsForm
      onSubmit={values => {
        onSubmit(values);
      }}
      loading={loading}
    />
  </Container>
);

export default DesktopView;
