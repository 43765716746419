import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { kebabCase } from 'lodash';
import { withRouter } from 'react-router-dom';

import {
  Text,
  Flex,
  FlexRows,
  Button,
  FlexColumns,
  ShowMoreText,
  IconActionButton,
} from '../../components/elements';

import SocialActionButton from '../../components/SocialActionButton';
import AddToCalenderModal from './components/mobile/AddToCalenderModal';
import { getTicketUrlPdf } from '../../utils/s3';

const Container = styled.div`
  background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;

const Content = styled.div``;
const Card = styled.div`
  padding: 1rem;
`;

const TexStyle = styled(Text)`
  padding-bottom: 0.5rem;
`;

const ButtonWrapper = styled.div`
  padding: 0.5rem 1rem;
`;

const SocialActionButtonStyle = styled(SocialActionButton).attrs({
  textStyle: { size: 'small', weight: 'semibold', color: 'darkGrey' },
  iconStyle: { size: 1.2 },
})``;
const IconActionButtonStyle = styled(IconActionButton).attrs({
  textStyle: { size: 'small', weight: 'semibold', color: 'darkGrey' },
  iconStyle: { size: 1.2 },
})``;

const MobileView = ({ event, booking, history }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [isActiveCalenderModal, setIsActiveCalenderModal] = useState(false);

  const getTicket = useCallback(async () => {
    setLoading(true);
    const link = await getTicketUrlPdf(booking && booking.url);
    setPdfUrl(link);
    setLoading(false);
  }, [booking]);

  useEffect(() => {
    getTicket();
  }, [getTicket]);

  return (
    <Container>
      <Content>
        <Card>
          <div className="box">
            <Flex justify="flex-start">
              <Text size="small" weight="semibold">
                {event.name}
              </Text>
            </Flex>
            <ShowMoreText size="micro" color="darkGrey" content={event.description} limit={200} />
            <br />
            <FlexRows justify="space-between">
              <SocialActionButtonStyle
                social={event.social}
                objectId={event.event_id}
                objectType="EVENT"
                type="LIKE"
                name="LIKE"
                defaultIcon="likeGrey"
                activeIcon="likeBlue"
              />
              <SocialActionButtonStyle
                social={event.social}
                objectId={event.event_id}
                objectType="EVENT"
                type="BOOKMARK"
                name="BOOKMARK"
                defaultIcon="bookmarkGrey"
                activeIcon="bookmarkBlue"
              />
              <IconActionButtonStyle name="SHARE" icon="shareBlue" />
            </FlexRows>
            <hr />
            <Flex>
              <Text className="is-uppercase" weight="regular" size="small">
                Thank You For Order!
              </Text>
            </Flex>
            <br />
            <FlexColumns align="flex-start" justify="flex-start">
              <TexStyle className="is-uppercase" weight="semibold" size="tiny">
                Booking Confirmation
              </TexStyle>
              <TexStyle size="micro" color="darkGrey">
                Your tickets has been sent to your listed email.
              </TexStyle>
              <TexStyle size="micro" color="darkGrey">
                Your Order Number is 1232131JBJBJB
              </TexStyle>
              <TexStyle size="micro" color="darkGrey">
                Event Date: Sat, 14 Nov 2019 2:00 PM
              </TexStyle>
              <TexStyle size="micro" color="darkGrey">
                Transaction Date: Sat, 14 Nov 2019 2:00 PM
              </TexStyle>
            </FlexColumns>
            <FlexRows justify="space-between">
              <Text size="small" weight="semibold">
                Total: $106.5
              </Text>
              <Text size="small" weight="semibold">
                Quantity: 5
              </Text>
            </FlexRows>
            <hr />
            <Flex>
              {!loading && (
                <a href={pdfUrl} download={`${kebabCase(event.name)}-ticket.pdf`}>
                  <Text size="small" color="primaryColor" weight="semibold">
                    Download Ticket & Receipt
                  </Text>
                </a>
              )}
            </Flex>
          </div>
        </Card>
      </Content>
      <ButtonWrapper>
        <Button
          name="Add To Calender"
          isFullWidth
          backgroundColor="tertiaryColor"
          borderColor="tertiaryColor"
          textProps={{ color: 'white', weight: 'semibold', size: 'small' }}
          // disabled={disabled}
          onClick={() => setIsActiveCalenderModal(true)}
        />
        <AddToCalenderModal
          isActive={isActiveCalenderModal}
          onCloseModal={() => setIsActiveCalenderModal(false)}
          event={event}
        />
      </ButtonWrapper>
      <ButtonWrapper>
        <Button
          name="FIND OTHER EVENTS"
          isFullWidth
          borderColor="primaryColor"
          textProps={{ color: 'primaryColor', weight: 'semibold', size: 'small' }}
          onClick={() => history.push(`/events`)}
        />
      </ButtonWrapper>
      <ButtonWrapper>
        <Button
          name="VIEW TICKET'S"
          isFullWidth
          backgroundColor="primaryColor"
          borderColor="primaryColor"
          textProps={{ color: 'white', weight: 'semibold', size: 'small' }}
          disabled
        />
      </ButtonWrapper>
    </Container>
  );
};

export default withRouter(MobileView);
