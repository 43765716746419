import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { times, forEach, range, upperCase } from 'lodash';
import moment from 'moment';
import { Button } from '../../../../components/elements';
import colors from '../../../../theme/styles/colors';

import PickUpTime from './PickUpTime';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 30rem;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    }
    .modal-card-head {
      padding: 1rem 2rem;
      padding-bottom: 0.6rem;
      background-color: rgba(255, 255, 255, 1);
      border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
    }
    .modal-card-body {
      padding: 2rem;
      padding-top: 0rem;
    }
    a {
      color: ${colors.primaryColor};
    }
  }
`;

const Footer = styled.footer`
  && {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 1);
    border-top: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  }
`;

const getTimeRange = (options, estimateRange = 15) => {
  const timeData = [];
  forEach(options, item => {
    const startHours = moment(item.start, 'hh:mm A').hours();
    const startMin = moment(item.start, 'hh:mm A').minutes();
    const endHours = moment(item.end, 'hh:mm A').hours();
    const endMin = moment(item.end, 'hh:mm A').minutes();
    const rangeOfHours = range(startHours, endHours);
    const minRange = range(0, 59, estimateRange);
    console.log('options', rangeOfHours, minRange);
    forEach(rangeOfHours, item2 => {
      forEach(minRange, item3 => {
        const isPM = item2 > 12;
        const hours = item2 > 12 ? item2 - 12 : item2;
        const addZeroWithHours = hours < 10 ? `0${hours}` : hours;
        const final = `${addZeroWithHours}:${item3 === 0 ? '00' : item3} ${isPM ? 'PM' : 'AM'}`;
        const withMin = item3 === 60 - estimateRange ? '00' : item3 + estimateRange;
        const withHours = item3 === 60 - estimateRange ? item2 + 1 : item2;
        const finalHours = withHours > 12 ? withHours - 12 : withHours;
        const isPMWith = withHours > 12;
        const withFinal = `${finalHours}:${withMin === 0 ? '00' : withMin} ${
          isPMWith ? 'PM' : 'AM'
        }`;
        timeData.push(`${final} - ${withFinal}`);
      });
    });
  });

  return timeData;
};

const DiningOptionsModal = ({ isActiveModal, setIsActiveModal, menuHours = [] }) => {
  const [checkBox, setCheckBox] = useState('asap');
  const [timeRange, setTimeRange] = useState([]);
  const today = moment().format('ddd');
  const [selectedDay, setSelectedDay] = useState(today);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  const handleCheck = value => {
    setCheckBox(value);
  };

  const days = times(3 + 1).map(item =>
    moment()
      .add(item, 'days')
      .format('ddd, MMM, DD'),
  );

  useEffect(() => {
    if (menuHours.length !== 0) {
      const currentDayHours = menuHours.filter(item => item.day === upperCase(selectedDay));

      if (currentDayHours.length !== 0) {
        const timeRangeData = getTimeRange(currentDayHours[0].option);
        setTimeRange(timeRangeData);
      }
    }
  }, [menuHours, selectedDay]);

  return (
    <Container className={`modal ${isActiveModal && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <PickUpTime
          days={days}
          timeRange={timeRange}
          checkBox={checkBox}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          handleCheck={handleCheck}
          handleSelectDate={value => {
            const day = moment(value, 'ddd, MMM, DD').format('ddd');
            setSelectedDay(day);
            setSelectedDate(value);
          }}
          handleSelectTime={value => {
            setSelectedTime(value);
          }}
          onBack={() => setIsActiveModal(false)}
        />
        <Footer className="modal-card-foot">
          <Button
            // className={isLoading && 'is-loading'}
            type="submit"
            name="Update"
            width={3}
            backgroundColor="primaryColor"
            textProps={{
              color: colors.white,
              weight: 'semibold',
              size: 'small',
            }}
            style={{ width: '100%' }}
            onClick={() => {}}
          />
        </Footer>
      </div>
    </Container>
  );
};

export default DiningOptionsModal;
