import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import gql from 'graphql-tag';

import { IconSocialActionButton } from '../../../../components/elements';
import FlaggedActionButton from '../../../../components/FlaggedActionButton';
import client from '../../../../utils/apolloClient';

const SocialActionButtonStyle = styled(IconSocialActionButton).attrs({
  textStyle: { size: 'smaller', weight: 'regular', color: 'specialGrey' },
  paddingLess: true,
  iconStyle: { size: 1.2 },
})``;

const socialMutation = gql`
  mutation socialAction($input: SocialNetworkInput) {
    create_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const removeSocialMutation = gql`
  mutation removeSocialAction($input: NavigateSocialInput) {
    delete_social(input: $input) {
      social_id
      error {
        description
      }
    }
  }
`;

const ReviewSocial = ({
  comment,
  objectId,
  objectType,
  placeId,
  likeActive,
  likeCountInt,
  likeSocialIdS,
}) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn, userId } = useStoreState(state => state.auth);
  const [likeCount, setLikeCount] = useState(likeCountInt);

  const [likeSocialId, setLikeSocialId] = useState(likeSocialIdS);

  const [isActiveLike, setIsActiveLike] = useState(likeActive);
  console.log('CommentSocial', placeId);
  const handleCountIncrement = type => {
    if (type === 'LIKE') {
      setLikeCount(likeCount + 1);
      setIsActiveLike(true);
    }
  };

  const handleCountDecrement = type => {
    if (type === 'LIKE') {
      setLikeCount(likeCount - 1);
      setIsActiveLike(false);
    }
  };

  const handleOnClickActive = type => {
    handleCountIncrement(type);
    client.clientPrivate
      .mutate({
        mutation: socialMutation,
        variables: {
          input: {
            user_id: userId,
            object_id: objectId,
            object_type: objectType,
            type,
            value: 1,
          },
        },
      })
      .then(({ data }) => {
        if (type === 'LIKE') {
          setLikeSocialId(data.create_social.social_id);
        }
      });
  };

  const handleOnClickRemove = (type, socialId) => {
    handleCountDecrement(type);
    client.clientPrivate
      .mutate({
        mutation: removeSocialMutation,
        variables: {
          input: {
            user_id: userId,
            social_id: socialId,
          },
        },
      })
      .then(() => {});
  };

  const handleSocialAction = (type, action, socialId) => {
    if (!isLoggedIn) {
      setIsActiveModal({
        value: true,
        path: '',
      });
    } else if (action) {
      handleOnClickActive(type);
    } else {
      handleOnClickRemove(type, socialId);
    }
  };

  return (
    <>
      <div className="level-item">
        <SocialActionButtonStyle
          active={isActiveLike}
          name="Like"
          socialCount={likeCount}
          handleOnClick={() => handleSocialAction('LIKE', !isActiveLike, likeSocialId)}
          withoutIcon
        />
      </div>
      <div className="level-item">
        <FlaggedActionButton
          createdBy={comment.audit.created_by}
          objectId={comment.comment_id}
          objectType="ITEM_REVIEW_COMMENT"
          parentId={placeId}
          name="Flag"
          textStyle={{
            size: 'small',
            weight: 'regular',
            color: 'waterMarkGrey',
          }}
          dropdownHeading="Flag this Comment"
          suggestions={[
            { key: 'REPORT_COMMENT', name: 'Report this comment' },
            { key: 'INAPPROPRIATE_COMMENT', name: 'Inappropriate comment' },
            { key: 'OTHER_FEED_COMMENT', name: 'Other' },
          ]}
          paddingLess
        />
      </div>
    </>
  );
};

ReviewSocial.defaultProps = {
  objectId: '',
  objectType: '',
  likeActive: false,
  likeCountInt: 0,
};

ReviewSocial.propTypes = {
  objectId: PropTypes.string,
  objectType: PropTypes.string,
  likeActive: PropTypes.bool,

  likeCountInt: PropTypes.number,
};

export default ReviewSocial;
