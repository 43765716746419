import React from 'react';
import TimePicker from 'rc-time-picker';
import styled from 'styled-components';
import 'rc-time-picker/assets/index.css';

const Wrapper = styled.div`
  &&& {
    .rc-time-picker-input {
      color: #0d0d0d;
      @media screen and (max-width: 1023px) {
        font-size: 0.7rem;
        height: 28.5px;
      }
      @media screen and (min-width: 1024px) {
        font-size: 0.7rem;
        height: 28.5px;
      }
      @media screen and (min-width: 1216px) {
        font-size: 0.7rem;
        height: 29px;
      }
      @media screen and (min-width: 1408px) {
        font-size: 0.8rem;
        height: 29px;
      }
    }
  }
`;

const MyTimePicker = ({ ...props }) => (
  <Wrapper>
    <TimePicker id="time-picker" name="time-picker" showSecond={false} use12Hours {...props} />
  </Wrapper>
);

export default MyTimePicker;
