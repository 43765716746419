import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexRows, Icon } from '../../../../components/elements';

const IconWrapper = styled.span`
  padding: ${({ padding }) => `${padding}px`};
`;

const RatingInput = ({ handleChange, initialValue, padding, iconSize }) => {
  const [rating, setRating] = React.useState(initialValue || 0);
  return (
    <>
      <FlexRows align="flex-start">
        <a
          onClick={() => {
            setRating(1);
            handleChange(1);
          }}
        >
          {rating < 1 ? (
            <IconWrapper padding={padding}>
              <Icon name="itemRatingSolidGrey" type="button" size={iconSize} id="rating_1" />
            </IconWrapper>
          ) : (
            <IconWrapper padding={padding}>
              <Icon name="itemRatingGoldSolid" type="button" size={iconSize} id="rating_1" />
            </IconWrapper>
          )}
        </a>
        <a
          onClick={() => {
            setRating(2);
            handleChange(2);
          }}
        >
          {rating < 2 ? (
            <IconWrapper padding={padding}>
              <Icon name="itemRatingSolidGrey" type="button" size={iconSize} id="rating_2" />
            </IconWrapper>
          ) : (
            <IconWrapper padding={padding}>
              <Icon name="itemRatingGoldSolid" type="button" size={iconSize} id="rating_2" />
            </IconWrapper>
          )}
        </a>
        <a
          onClick={() => {
            setRating(3);
            handleChange(3);
          }}
        >
          {rating < 3 ? (
            <IconWrapper padding={padding}>
              <Icon name="itemRatingSolidGrey" type="button" size={iconSize} id="rating_3" />
            </IconWrapper>
          ) : (
            <IconWrapper padding={padding}>
              <Icon name="itemRatingGoldSolid" type="button" size={iconSize} id="rating_3" />
            </IconWrapper>
          )}
        </a>
        <a
          onClick={() => {
            setRating(4);
            handleChange(4);
          }}
        >
          {rating < 4 ? (
            <IconWrapper padding={padding}>
              <Icon name="itemRatingSolidGrey" size={iconSize} id="rating_4" />
            </IconWrapper>
          ) : (
            <IconWrapper padding={padding}>
              <Icon name="itemRatingGoldSolid" size={iconSize} id="rating_4" />
            </IconWrapper>
          )}
        </a>
        <a
          onClick={() => {
            setRating(5);
            handleChange(5);
          }}
        >
          {rating < 5 ? (
            <IconWrapper padding={padding}>
              <Icon name="itemRatingSolidGrey" size={iconSize} id="rating_5" />
            </IconWrapper>
          ) : (
            <IconWrapper padding={padding}>
              <Icon name="itemRatingGoldSolid" size={iconSize} id="rating_5" />
            </IconWrapper>
          )}
        </a>
      </FlexRows>
    </>
  );
};

RatingInput.defaultProps = {
  padding: 4,
  iconSize: 3,
};

RatingInput.propTypes = {
  padding: PropTypes.number,
  iconSize: PropTypes.number,
};

export default RatingInput;
