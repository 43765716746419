import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';

import {
  Field,
  Button,
  FlexColumns,
  Text,
  MobileInput,
  Notification,
} from '../../components/elements';
import { trackEvent } from '../../utils/analytics';

const Container = styled.div`
  padding: 1rem;
`;

const FieldWrapper = styled.div`
  padding: 1rem 0rem;
`;

const FieldStyle = styled(Field).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    color: 'primaryColor',
    weight: 'bold',
    size: 'small',
    letterSpacing: 'loose',
  },
})``;

const MobileFieldStyle = styled(MobileInput).attrs({
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    color: 'primaryColor',
    weight: 'bold',
    size: 'small',
    letterSpacing: 'loose',
  },
})``;

const ButtonWrapper = styled(FlexColumns)`
  position: fixed;
  width: 100%;
  bottom: 0;
`;
const ButtonStyle = styled(Button)`
  &&& {
    border-radius: 0rem;
    padding: 1.5rem;
  }
`;

const Form = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isLoading,
  isError,
  clearError,
}) => (
  <React.Fragment>
    <Container>
      {isError.error && (
        <Notification message={isError.message} onClose={() => clearError()} />
      )}
      <FieldWrapper>
        <FieldStyle
          label="First Name"
          placeholder="Nasim"
          name="firstName"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.firstName}
        />
        {errors.firstName && touched.firstName && (
          <div className="has-text-danger">{errors.firstName}</div>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          label="Last Name"
          placeholder="Akhtar"
          name="lastName"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lastName}
        />
        {errors.lastName && touched.lastName && (
          <div className="has-text-danger">{errors.lastName}</div>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          optional
          label="Preferred display name"
          placeholder="devnasim"
          name="preferredName"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.preferredName}
        />
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          label="Username"
          placeholder="john123"
          name="username"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.username}
        />
        {errors.username && touched.username && (
          <div className="has-text-danger">{errors.username}</div>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          label="Email"
          placeholder="john@gmail.com"
          name="email"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
        />
        {errors.email && touched.email && (
          <div className="has-text-danger">{errors.email}</div>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <MobileFieldStyle
          label="Mobile"
          placeholder="+41000866866868"
          name="mobile"
          onChange={value => setFieldValue('mobile', value)}
          value={values.mobile}
        />
        {errors.mobile && touched.mobile && (
          <div className="has-text-danger">{errors.mobile}</div>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          label="password"
          placeholder="@password123"
          rightIcon="fas fa-eye-slash"
          name="password"
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
        />
        {errors.password && touched.password && (
          <div className="has-text-danger">{errors.password}</div>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          label="confirm password"
          placeholder="@password123"
          rightIcon="fas fa-eye-slash"
          name="confirmPassword"
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmPassword}
        />
        {errors.confirmPassword && touched.confirmPassword && (
          <div className="has-text-danger">{errors.confirmPassword}</div>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <FieldStyle
          label="Date of Birth"
          placeholder="12/20/23"
          name="dob"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.dob}
        />
        {errors.dob && touched.dob && (
          <div className="has-text-danger">{errors.dob}</div>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <Text color="coolGrey" size="micro">
          By Signing in, i accept the Terms of Services & Private Policy. I
          understand that the content i posted is public. I also consent to
          receiving information about events, deals, news feeds, dishes, items
          or places in Kravein
        </Text>
      </FieldWrapper>
    </Container>
    <ButtonWrapper>
      <ButtonStyle
        name={isLoading ? 'Processing...' : 'Sign up'}
        backgroundColor="primaryColor"
        textStyle={{ color: 'white', weight: 'bold', size: 'large' }}
        onClick={
          handleSubmit
        }
      />
    </ButtonWrapper>
  </React.Fragment>
);

const MyEnhancedForm = withFormik({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    preferredName: '',
    username: '',
    email: '',
    mobile: '',
    password: '',
    confirmPassword: '',
    dob: '',
  }),

  // Custom sync validation
  validate: values => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'Required';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    }
    if (!values.username) {
      errors.username = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    }
    // if (!values.mobile) {
    //   errors.mobile = 'Required';
    // }
    if (!values.password) {
      errors.password = 'Required';
    }

    if (!values.confirmPassword || values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Does not match with password';
    }

    if (!values.dob) {
      errors.dob = 'Required';
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    console.log(values);
    props.onSubmit(values);
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  },
  displayName: 'SignupForm',
})(Form);

export default MyEnhancedForm;
