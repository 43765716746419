import React from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import moment from 'moment';
import { BsFillXCircleFill } from 'react-icons/bs';

import colors from '../../../theme/styles/colors';
import { Text, FlexRows } from '../../../components/elements';
import YouOrder from './YouOrder';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5) !important;
    }
    .modal-card {
      border-radius: 6px;
      width: 650px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
      position: relative;
      .cross {
        position: fixed;
        z-index: 100;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 22px;
        margin: 0.8rem;
      }
    }
    .modal-card-head {
      padding: 1rem 1rem;
      padding-bottom: 0.6rem;
      background-color: rgba(255, 255, 255, 1);
      border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
    }
    .modal-card-body {
      padding: 0rem;
    }
    a {
      color: ${colors.primaryColor};
    }
    .select:not(.is-multiple):not(.is-loading)::after {
      border-color: ${({ theme: { colors: themeColor } }) => themeColor.coolGrey};
    }
    .hide {
      display: none;
    }
  }
`;

const Header = styled(FlexRows)`
  padding: 0rem 0rem;
  width: 100%;
`;

const Content = styled.div`
  padding: 1.5rem;
`;

const Item = styled.div``;

const ModifiersModal = ({ isActiveModal, onClose, order }) => {
  return (
    <Container className={`modal ${isActiveModal && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <div className="cross">
          <IoMdClose
            size={20}
            color={colors.dark}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
        </div>
        <header className="modal-card-head" id="scroll">
          <Header>
            <Text size="large" weight="semibold" paddingBottom={0.5} className="item-title">
              Order Details
            </Text>
          </Header>
        </header>
        <div className="modal-card-body">
          <Content>
            <Item>
              <Text size="smedium" weight="semibold">
                {order.place.name}
              </Text>
              <Text size="smedium" weight="regular" color="darkGrey">
                {order.place.address_line_1}, {order.place.city} {order.place.state},{' '}
                {order.place.post_code}
              </Text>
            </Item>
            <br />
            <div className="columns is-gapless">
              <div className="column">
                <Item>
                  <Text size="smedium" weight="semibold">
                    Order Number
                  </Text>
                  <Text size="smedium" weight="regular" color="darkGrey">
                    {order.order_reference}
                  </Text>
                </Item>
              </div>
              <div className="column">
                <Item>
                  <Text size="smedium" weight="semibold">
                    Service
                  </Text>
                  <Text size="smedium" weight="regular" color="darkGrey">
                    {order.service_type}
                  </Text>
                </Item>
              </div>
            </div>
            <Item>
              <Text size="smedium" weight="semibold">
                Order Date
              </Text>
              <Text size="smedium" weight="regular" color="darkGrey">
                {moment(order.audit.created_at).format('llll')}
              </Text>
            </Item>
            <br />
            <Item>
              <Text size="smedium" weight="semibold">
                Status
              </Text>
              {order.status === 'CANCELLED' ? (
                <FlexRows justify="flex-start">
                  <BsFillXCircleFill size={15} color="red" style={{ marginRight: '0.2rem' }} />
                  <Text size="smedium" color="darkGrey">
                    {order.status}
                  </Text>
                </FlexRows>
              ) : (
                <Text size="smedium" weight="regular" color="darkGrey">
                  {order.status}
                </Text>
              )}
            </Item>
            <br />
            <Item>
              <Text size="smedium" weight="semibold">
                Payment
              </Text>
              <Text size="smedium" weight="regular" color="darkGrey">
                Cash
              </Text>
            </Item>
            <YouOrder order={order} />
          </Content>
        </div>
      </div>
    </Container>
  );
};

export default ModifiersModal;
