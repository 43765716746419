import React from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import uuid from 'uuid';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
// import Analytics from '../../components/global/Analytics';
import ErrorMessage from '../../components/global/ErrorMessage';
import client from '../../utils/apolloClient';

const fetchPlaceQuery = gql`
  query fetch_place($input: NavigatePlaceInput) {
    fetch_place(input: $input) {
      place_id
      name
      tagline
      cuisine_style {
        name
        description
      }
      address_line_1
      address_line_2
      city
      state
      country
      post_code
    }
  }
`;

const placeClaimMutation = gql`
  mutation placeClaim($input: PlaceClaimantInput) {
    create_place_claimant(input: $input) {
      claimant_id
      error {
        description
      }
    }
  }
`;

const ClaimYourPlace = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout
      history={history}
      match={match}
      bottomNavigationEnable={false}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading="MY REVIEWS"
    >
      <Query query={fetchPlaceQuery} variables={{ input: { place_id: match.params.placeId } }}>
        {({ data, loading, error }) => {
          if (error) {
            return <ErrorMessage />;
          }
          return (
            <Mutation
              client={client.clientPrivate}
              mutation={placeClaimMutation}
              onCompleted={() => {
                swal(
                  'Thank you for submitting your claim',
                  'We will process your verification and contact you as soon as possible.',
                  'success',
                ).then(() => {
                  history.goBack();
                });
              }}
            >
              {(create_place_claimant, { loading: claimLoading }) => (
                <>
                  <div className="is-hidden-desktop">
                    <MobileView
                      data={data}
                      loading={loading}
                      claimLoading={claimLoading}
                      createPlaceClaim={values => {
                        const input = {
                          user_id: userId,
                          claimant_id: uuid(),
                          object_type: 'place',
                          object_id: values.place_id,
                          contact_full_name: values.name,
                          contact_email: values.email,
                          contact_phone: values.mobile,
                          contact_business_url: values.website,
                          contact_role: values.relation,
                          contact_linked_in_url: values.linkedin,
                          agree_terms_and_conditions: values.agree,
                          service_type: values.service_type,
                        };
                        create_place_claimant({ variables: { input } });
                      }}
                    />
                  </div>
                  <div className="is-hidden-touch">
                    <DesktopView
                      data={data}
                      loading={loading}
                      claimLoading={claimLoading}
                      createPlaceClaim={values => {
                        const input = {
                          user_id: userId,
                          claimant_id: uuid(),
                          object_type: 'place',
                          object_id: values.place_id,
                          contact_full_name: values.name,
                          contact_email: values.email,
                          contact_phone: values.mobile,
                          contact_business_url: values.website,
                          contact_role: values.relation,
                          contact_linked_in_url: values.linkedin,
                          agree_terms_and_conditions: values.agree,
                          service_type: values.service_type,
                        };
                        create_place_claimant({ variables: { input } });
                      }}
                    />
                  </div>
                </>
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default ClaimYourPlace;
