import React from 'react';
import Text from './Text';
import { trackEvent } from '../../utils/analytics';

const Notification = ({ message, onClose }) => (
  <div className="notification is-danger">
    <button
      className="delete"
      onClick={ onClose }
    />
    <Text weight="semibold" color="white">
      {message}
    </Text>
  </div>
);

export default Notification;
