import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import randomColor from 'randomcolor';
import { isUndefined, isNull } from 'lodash';
import { Text, FlexRows, FlexColumns } from './elements';
import useFetchUser from '../utils/useFetchUser';
// import { firstTwoLetterPicker } from '../utils/helpers';
import GetImages from './GetImages';

// const UserImage = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: ${({ backgroundColor }) => `${backgroundColor}`};
//   width: ${({ maskProps }) => `${maskProps.width}px`};
//   height: ${({ maskProps }) => `${maskProps.width}px`};
//   border-radius: 2rem;
//   padding: 1rem;
// `;

const UserInfo = ({
  userId,
  subHeading,
  headingLeftElement,
  headingRightElement,
  children,
  maskProps,
  maskTextProps,
  variables,
}) => {
  const { loading, user } = useFetchUser({ userId });
  const customColor = randomColor({
    luminosity: 'dark',
  });
  return (
    <article className="media">
      <figure className="media-left">
        <FlexColumns>
          <GetImages
            name={!loading && `${user.caption_3}`}
            maskProps={maskProps}
            maskTextProps={maskTextProps}
            borderRadius={25}
            randomColorCode={
              !loading && !isNull(user.color_code) && !isUndefined(user.color_code)
                ? user.color_code
                : customColor
            }
            variables={variables}
          />
          {/* <UserImage
            className="is-rounded"
            maskProps={maskProps}
            backgroundColor={
              !loading && !isNull(user.color_code) && !isUndefined(user.color_code)
                ? user.color_code
                : customColor
            }
          >
            <Text {...maskTextProps}>{!loading && firstTwoLetterPicker(`${user.caption_3}`)}</Text>
          </UserImage> */}
          {headingLeftElement}
        </FlexColumns>
      </figure>
      <div className="media-content">
        <FlexRows justify="space-between">
          <div>
            <Text size="medium" weight="semibold" paddingBottom={0.2}>
              {!loading && user.caption_3}
            </Text>
            <Text size="micro" color="darkGrey" paddingBottom={0.5}>
              {subHeading}
            </Text>
          </div>
          {headingRightElement}
        </FlexRows>
        {children}
      </div>
    </article>
  );
};

UserInfo.defaultProps = {
  maskProps: { width: 55 },
  maskTextProps: { color: 'white', weight: 'bold', size: 'large', letterSpacing: 'loose' },
};

UserInfo.propTypes = {
  maskProps: PropTypes.object,
  maskTextProps: PropTypes.object,
};

export default UserInfo;
