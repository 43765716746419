import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { times, first, isNull, truncate } from 'lodash';
import { GoLocation, GoChecklist } from 'react-icons/go';

import { Text, FlexRows, Icon } from '../../../../components/elements';
import PlaceFollowButton from '../../../../components/PlaceFollowButton';
import PlaceSocial from './PlaceSocial';
import ImageView from './ImageView';
import { isOpen, parseSocialPlace, getKeywordTagline } from '../../../../utils/helpers';
import colors from '../../../../theme/styles/colors';

const Container = styled.div`
  border-bottom: 2px solid ${({ theme: { colors: color } }) => color.lightGreyAlpha};
  margin: 0rem 1rem;
  padding: 0.5rem 0rem;
  &&& {
    .columns.is-mobile > .column.is-5 {
      width: 38.66667%;
    }
  }
  .right-text {
    margin-left: 10px;
  }
`;

const Header = styled(FlexRows)`
  padding: 0.5rem 0rem;
`;

const RightContainer = styled.div`
  padding-left: 0.5rem;
  &&& {
    .icon {
      justify-content: flex-start;
    }
  }
  .features {
    align-items: flex-start;
    img {
      margin-top: 5px;
    }
  }
`;
const Span = styled.span`
  color: ${({ theme: { colors: color } }) => color.primaryColor};
`;

const TextStyle = styled(Text).attrs({})``;

const CardFooter = styled.div`
  position: relative;
`;

const MobileBusinessCard = ({ place, socials, follow, deal }) => {
  const { userId } = useStoreState(state => state.auth);
  const [isOpenPlace, setIsOpenPlace] = useState(false);

  useEffect(() => {
    const fetData = async () => {
      const data = await isOpen(place);
      setIsOpenPlace(data);
    };
    fetData();
  }, [place, place.place_id]);

  const socialArray = socials.length !== 0 ? first(socials).total_social_count : [];
  const social = parseSocialPlace(socialArray, userId);
  const followData = follow.length !== 0 ? first(follow) : null;
  const dealCounts = deal.length !== 0 ? first(deal).count : 0;

  return (
    <Container>
      <Header justify="space-between" align="flex-start">
        <Link to={`/place/${place.place_id}`} style={{ width: '70%' }}>
          <TextStyle className="is-capitalized" size="medium" weight="semibold">
            {place.name}
          </TextStyle>
          <Text color="darkGrey" size="micro">
            {parseFloat(place.distance).toFixed(2)} Kms{' '}
            {place.price_range && place.price_range !== 0 && '|'}{' '}
            {place.price_range && times(place.price_range).map(() => '$')}{' '}
            {dealCounts !== 0 && `| ${dealCounts} deals Available`}
            {isOpenPlace && (
              <a>
                | <Span>Open Now</Span>
              </a>
            )}
          </Text>
        </Link>
        <div>
          <PlaceFollowButton
            isActive={!isNull(followData)}
            activeFollowId={isNull(followData) ? '' : followData.follow_id}
            objectId={place.place_id}
            objectType="PLACE"
            type="FOLLOW"
          />
        </div>
      </Header>
      <div className="columns is-mobile is-gapless">
        <div className="column is-5">
          <ImageView place={place} />
          <CardFooter>
            <PlaceSocial
              objectId={place.place_id}
              objectType="PLACE"
              likeActive={social.like.active}
              likeCountInt={social.like.count}
              likeSocialIdS={social.like.id}
            />
          </CardFooter>
        </div>
        <div className="column">
          <Link to={`/place/${place.place_id}`}>
            <RightContainer>
              <div className="is-flex">
                <span className="icon">
                  <GoLocation size={18} color={colors.specialGrey} />
                </span>

                <Text
                  size="tiny"
                  color="dark"
                  weight="regular"
                  paddingBottom={0.3}
                  className="right-text"
                  lineHeight="normal"
                  style={{ marginLeft: '4px' }}
                >
                  {truncate(`${place.address_line_1}, ${place.city}`, { length: 30 })}
                </Text>
              </div>

              <div className="is-flex features">
                <span className="icon">
                  <Icon size={0.8} name="featureIcon" />
                </span>

                <Text
                  color="darkGrey"
                  size="tiny"
                  className="right-text"
                  lineHeight="normal"
                  style={{ marginLeft: '15px' }}
                >
                  {truncate(
                    getKeywordTagline(
                      place.business_type,
                      place.cuisine_style,
                      place.feature,
                      place.dietary_information,
                    ),
                    { length: 70 },
                  )}
                </Text>
              </div>
            </RightContainer>
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default MobileBusinessCard;
