import React, { useState } from 'react';
import styled from 'styled-components';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { isNull, omit } from 'lodash';
import { useStoreState } from 'easy-peasy';
import { FlexRows, Text } from '../../../../components/elements';

import DeliveryAddressForm from './DeliveryAddressForm';
import GuestDeliveryAddress from './GuestDeliveryAddress';
import client from '../../../../utils/apolloClient';
import { validateAddress } from '../../helpers';

const Header = styled(FlexRows)`
  padding: 0rem 0rem;
  width: 100%;
  .pickup-time {
    margin-right: 10rem;
  }
`;

const createAddressMutation = gql`
  mutation create_user_address($input: UserAddressInput) {
    create_user_address(input: $input) {
      address_id
      type
      is_default
      contact_name
      contact_phone
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      timezone
      status
      error {
        description
      }
    }
  }
`;

// const updateAddressMutation = gql`
//   mutation update_user_address($input: UserAddressInput) {
//     update_user_address(input: $input) {
//       address_id
//       type
//       is_default
//       contact_name
//       contact_phone
//       address_line_1
//       address_line_2
//       city
//       state
//       country
//       post_code
//       latitude
//       longitude
//       location
//       timezone
//       status
//       error {
//         description
//       }
//     }
//   }
// `;

const DeliveryAddress = ({
  userId,
  userAddress,
  placeId,
  placeName,
  onBack,
  deliveryAddress,
  updateUserDelivery,
  handleDeliverAddress,
  isCheckoutPage,
}) => {
  const { isLoggedIn } = useStoreState(state => state.auth);
  const [selectedAddress, setSelectedAddress] = useState(
    deliveryAddress ? deliveryAddress.address_id : null,
  );
  const [isActiveSuggestion, setIsActiveSuggestion] = useState(true);
  const [resetFormFunc, setResetFormFunc] = useState();
  const [isDeliverableAddress, setIsDeliverableAddress] = useState(true);
  const [checkIsDeliverableAddressLoading, setCheckIsDeliverableAddressLoading] = useState(false);

  return (
    <>
      <header className="modal-card-head">
        <Header justify="space-between">
          {isCheckoutPage ? (
            <>
              <Text size="large" weight="semibold" paddingBottom={0.5} className="pickup-time">
                Delivery Details
              </Text>
              <a onClick={onBack}>
                <IoMdClose color="#000000" size={25} />
              </a>
            </>
          ) : (
            <>
              <a onClick={onBack}>
                <MdKeyboardArrowLeft color="#000000" size={25} />
              </a>
              <Text size="large" weight="semibold" paddingBottom={0.5} className="pickup-time">
                Delivery Details
              </Text>
            </>
          )}
        </Header>
      </header>
      {isLoggedIn ? (
        <Mutation
          client={client.clientPrivate}
          mutation={createAddressMutation}
          onCompleted={async ({ create_user_address }) => {
            if (!isNull(create_user_address.error)) {
              create_user_address.error.map(item => toast.error(item.description));
            } else {
              updateUserDelivery(create_user_address);
              setSelectedAddress(create_user_address.address_id);
              setIsActiveSuggestion(true);
              if (resetFormFunc) {
                resetFormFunc.resetForm();
              }
              setCheckIsDeliverableAddressLoading(true);
              try {
                const isDeliverable = await validateAddress(create_user_address, placeId);
                setIsDeliverableAddress(isDeliverable);
                setCheckIsDeliverableAddressLoading(false);
              } catch (error) {
                console.error(error);
              }
            }
          }}
        >
          {(create_user_address, { loading }) => (
            <DeliveryAddressForm
              userAddress={userAddress}
              placeName={placeName}
              isDeliverableAddress={isDeliverableAddress}
              checkIsDeliverableAddressLoading={checkIsDeliverableAddressLoading}
              selectedAddress={selectedAddress}
              isActiveSuggestion={isActiveSuggestion}
              handleSelectAddress={async value => {
                setIsDeliverableAddress(false);
                setSelectedAddress(value.address_id);
                setCheckIsDeliverableAddressLoading(true);
                try {
                  const isDeliverable = await validateAddress(value, placeId);
                  setIsDeliverableAddress(isDeliverable);
                  setCheckIsDeliverableAddressLoading(false);
                } catch (error) {
                  console.error(error);
                }
              }}
              handleFinalAddress={() => {
                const filter = userAddress.filter(item => item.address_id === selectedAddress);
                handleDeliverAddress(filter[0]);
                onBack();
              }}
              setIsActiveSuggestion={setIsActiveSuggestion}
              loading={loading}
              onSubmit={(values, formProps) => {
                const input = omit(values, ['deliverToWhere', 'call', 'bell']);
                create_user_address({
                  variables: { input: { user_id: userId, ...input } },
                });
                setResetFormFunc(formProps);
              }}
            />
          )}
        </Mutation>
      ) : (
        <GuestDeliveryAddress loading={false} onSubmit={() => {}} />
      )}
    </>
  );
};

export default DeliveryAddress;
