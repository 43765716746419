import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Logo from './Logo';
import User from './User';
import AddPlace from './AddPlace';
import PlaceSearch from './PlaceSearch';
import LocationSearch from './LocationSearch';
import Cart from './Cart';
import SubHeader from './SubHeader';

const Container = styled.div`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  position: fixed;
  top: 0;
  z-index: 200;
  width: 100%;
`;
const Section = styled.div`
  width: 100%;
  border-bottom: 1.5px solid #ededed;
  box-shadow: rgb(30 168 221) 0px -2px 0px inset;
`;

const SectionSubHeader = styled.div`
  width: 100%;
  background: #ffffff;
`;

const Nav = styled.nav`
  display: flex;
  margin: 0rem auto;
  align-items: stretch;
  max-width: 1330px;
  z-index: 201;
`;

const Item = styled.div`
  flex-grow: ${({ grow }) => grow};
  margin: 1rem;
  transition: flex-grow 500ms ease-in-out;
  cursor: pointer;
`;

const HoverableItem = styled.div`
  flex-grow: ${({ grow }) => grow};
  margin: 1rem;
  transition: flex-grow 500ms ease-in-out;
  overflow: hidden;
  :hover {
    overflow: inherit;
  }
  cursor: pointer;
`;

const Item2 = styled.div`
  flex-grow: ${({ grow }) => grow};
  margin: 1rem;
  transition: flex-grow 500ms ease-in-out;
  cursor: pointer;
`;

const Header = ({ disableSubHeader, disableCart }) => {
  const [toggle, setToggle] = useState('search');
  const { setHideSubHeader } = useStoreActions(state => state.global);
  const { hideSubHeader } = useStoreState(state => state.global);

  useEffect(() => {
    setHideSubHeader(true);
  }, [setHideSubHeader]);

  return (
    <Container>
      <Section>
        <Nav>
          <Item grow={1}>
            <Logo />
          </Item>
          <Item grow={toggle === 'location' ? 3 : 3}>
            <LocationSearch active={toggle === 'location'} onClick={() => setToggle('location')} />
          </Item>
          <Item2 grow={toggle === 'search' ? 6 : 4}>
            <PlaceSearch active={toggle === 'search'} onClick={() => setToggle('search')} />
          </Item2>
          <Item grow={1} />
          <HoverableItem grow={0}>
            <AddPlace />
          </HoverableItem>
          <HoverableItem grow={0}>
            <User />
          </HoverableItem>
          {!disableCart && (
            <Item grow={0}>
              <Cart />
            </Item>
          )}
        </Nav>
      </Section>
      {hideSubHeader && (
        <>
          {!disableSubHeader && (
            <SectionSubHeader>
              <SubHeader />
            </SectionSubHeader>
          )}
        </>
      )}
    </Container>
  );
};

export default Header;
