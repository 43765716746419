import React, { useState, useRef, useEffect, PureComponent } from 'react';
import styled from 'styled-components';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Link, Element, Events as ScEvent } from 'react-scroll';
import { first } from 'lodash';

import { FlexRows, Text, ScrollToTopButton, SEO, Pager } from '../../components/elements';
import TopView from './components/desktop/TopView';
import Information from './components/desktop/Information';
import Deals from './components/desktop/Deals';
import LatestFeeds from './components/desktop/LatestFeeds';
import TrendingItems from './components/desktop/TrendingItems';
import Events from './components/desktop/Events';
import Reviews from './components/desktop/Reviews';
import Loading from '../../components/global/mobile/MobileLoading';
import Menu from './components/desktop/Menu';

const Container = styled(Pager)`
  padding-top: 2rem;
`;

const Tabs = styled(FlexRows).attrs({ justify: 'flex-start' })`
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyTheta};
  position: sticky;
  top: 3.89rem;
  background: #ffffff;
  z-index: 200;
`;
const Item = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 2px solid
    ${({ theme: { colors }, active }) => (active ? colors.primaryColor : 'transparent')};
`;

const tabs = [
  { key: 'informationElements', value: 'Information' },
  { key: 'dealsElements', value: 'Deals' },
  { key: 'feedsElements', value: 'Latest Feed' },
  { key: 'itemElements', value: 'Trending Items' },
  { key: 'menuElements', value: 'Menu' },
  { key: 'reviewElements', value: 'Reviews' },
  { key: 'eventElements', value: 'Events' },
];

const Tab = ({ item, setActive, activeTab, elements }) => {
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y <= 150) {
        setActive(item.key);
        // console.log('fvehqjvfgh', item.key);
      }
    },
    [],
    elements[item.key],
  );

  return (
    <Item active={activeTab === item.key} key={item.key}>
      <Link
        to={item.key}
        spy
        smooth
        duration={500}
        offset={-100}
        isDynamic
        // ignoreCancelEvents
        // onClick={() => {
        //   setActive(item.key);
        // }}
      >
        <Text
          className="is-capitalized"
          size="small"
          weight="medium"
          color={activeTab === item.key ? 'primaryColor' : 'dark'}
        >
          {item.value}
        </Text>
      </Link>
    </Item>
  );
};

const DesktopView = ({ data, loading }) => {
  const [activeTab, setActive] = useState('informationElements');
  const [activeElements, setElements] = useState(tabs);

  let activeElementsHelpers = tabs;

  const elements = {
    informationElements: useRef(),
    dealsElements: useRef(),
    feedsElements: useRef(),
    itemElements: useRef(),
    menuElements: useRef(),
    eventElements: useRef(),
    reviewElements: useRef(),
  };

  const handleElement = key => {
    if (key === 'eventElements') {
      const filter = activeElementsHelpers.filter(item => item.key !== key);
      activeElementsHelpers = filter;
      setElements(activeElementsHelpers);
    }
  };

  useEffect(() => {
    ScEvent.scrollEvent.register('begin', () => {});
    ScEvent.scrollEvent.register('end', () => {});
    // animateScroll.scrollToTop({ smooth: 'linear' });
  }, []);

  if (loading) {
    return <Loading />;
  }
  const place = data.fetch_place;
  const { total_social_per_object_id } = data.search_social.social_aggregation;
  const socials =
    total_social_per_object_id.length !== 0
      ? first(total_social_per_object_id).total_social_count
      : undefined;
  return (
    <Container>
      <SEO title={place.name} description={place.description} />
      <TopView place={place} socials={socials} />
      <Tabs>
        {activeElements.map(item => (
          <Tab item={item} setActive={setActive} elements={elements} activeTab={activeTab} />
        ))}
      </Tabs>
      <ListItem elements={elements} place={place} handleElement={handleElement} />
      <ScrollToTopButton />
    </Container>
  );
};

// eslint-disable-next-line react/no-redundant-should-component-update
class ListItem extends PureComponent {
  shouldComponentUpdate(nextProps) {
    return nextProps.place !== this.props.place;
  }

  render() {
    const { elements, place, handleElement } = this.props;
    return (
      <>
        <Element name="informationElements">
          <div ref={elements.informationElements}>
            <Information place={place} />
          </div>
        </Element>
        <Element name="dealsElements">
          {/* <div ref={elements.dealsElements}>
            <Deals place={place} handleElement={handleElement} />
          </div> */}
        </Element>
        <Element name="feedsElements">
          <div ref={elements.feedsElements}>
            <LatestFeeds place={place} handleElement={handleElement} />
          </div>
        </Element>
        <Element name="itemElements">
          <div ref={elements.itemElements}>
            <TrendingItems place={place} handleElement={handleElement} />
          </div>
        </Element>
        <Element name="reviewElements">
          <div ref={elements.reviewElements}>
            <Reviews place={place} handleElement={handleElement} />
          </div>
        </Element>
        <Element name="menuElements">
          <div ref={elements.menuElements}>
            <Menu place={place} handleElement={handleElement} />
          </div>
        </Element>
        <Element name="eventElements">
          <div ref={elements.eventElements}>
            <Events place={place} handleElement={handleElement} />
          </div>
        </Element>
      </>
    );
  }
}

export default DesktopView;
