/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { trim } from 'lodash';
import uuid from 'uuid';
import * as yup from 'yup';

import { Flex, Text, Field, FlexRows } from '../../../../components/elements';
// eslint-disable-next-line max-len
import MobileSearchLocationModal from '../../../../components/global/mobile/MobileSearchLocationModal';

const Container = styled.div`
  padding: 0rem 1rem;
  background: transparent;
`;
const FieldWrapper = styled.div`
  padding: 1rem 0.5rem;
  background: transparent;
`;

const Wrapper = styled.div`
  border: 2px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  background: ${({ theme: { colors } }) => colors.lightGreyAlpha};
  .input {
    background: transparent !important;
  }
`;

const FieldStyle = styled(Field).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;

const FooterButton = styled.a`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.8rem 1rem;
  position: fixed;
  bottom: 0;
`;

const PlaceForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
}) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  return (
    <>
      <MobileSearchLocationModal
        isActive={isActiveModal}
        onClose={() => setIsActiveModal(false)}
        onSelect={place => {
          setFieldValue('address_line_1', trim(place.addressLine1));
          setFieldValue('city', place.city);
          setFieldValue('post_code', place.postcode);
          setFieldValue('state', place.state);
          setFieldValue('country', place.country || 'AUSTRALIA');
          setFieldValue('timezone', place.timezone || '+10');
          setFieldValue('latitude', place.latitude);
          setFieldValue('longitude', place.longitude);
        }}
      />
      <Container>
        <FieldWrapper>
          <FieldStyle
            label="Place Name"
            placeholder="Enter"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.name && touched.name && <div className="has-text-danger">{errors.name}</div>}
        </FieldWrapper>
        <FieldWrapper>
          <FieldStyle
            label="Look up Address"
            placeholder="Start Typing Address"
            // eslint-disable-next-line max-len
            value={`${values.address_line_1} ${values.city} ${values.state} ${values.post_code}, ${values.country}`}
            onFocus={() => setIsActiveModal(true)}
          />
          {errors.lookUpAddress && touched.lookUpAddress && (
            <div className="has-text-danger">{errors.lookUpAddress}</div>
          )}
        </FieldWrapper>
        <Wrapper>
          <FieldWrapper>
            <FieldStyle
              id="address_line_1"
              name="address_line_1"
              label=" Address Line 1"
              placeholder="Enter"
              value={values.address_line_1}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.address_line_1 && touched.address_line_1 && (
              <div className="has-text-danger">{errors.address_line_1}</div>
            )}
          </FieldWrapper>

          <FieldWrapper>
            <FieldStyle
              name="address_line_2"
              label=" Address Line 2"
              placeholder="Enter"
              value={values.address_line_2}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.address_line_2 && touched.address_line_2 && (
              <div className="has-text-danger">{errors.address_line_2}</div>
            )}
          </FieldWrapper>
          <FlexRows justify="space-between" align="flex-start">
            <FieldWrapper>
              <FieldStyle
                id="suburb"
                name="suburb"
                label="Suburb"
                placeholder="Enter"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.city && touched.city && <div className="has-text-danger">{errors.city}</div>}
            </FieldWrapper>
            <FieldWrapper>
              <FieldStyle
                name="state"
                label="State"
                placeholder="select"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.state && touched.state && (
                <div className="has-text-danger">{errors.state}</div>
              )}
            </FieldWrapper>
          </FlexRows>
          <FlexRows justify="space-between" align="flex-start">
            <FieldWrapper>
              <FieldStyle
                id="postcode"
                name="postcode"
                label="Post Code"
                placeholder="Enter"
                value={values.post_code}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.post_code && touched.post_code && (
                <div className="has-text-danger">{errors.post_code}</div>
              )}
            </FieldWrapper>
            <FieldWrapper>
              <FieldStyle
                id="country"
                name="country"
                label="Country"
                placeholder="select"
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.country && touched.country && (
                <div className="has-text-danger">{errors.country}</div>
              )}
            </FieldWrapper>
          </FlexRows>
        </Wrapper>
      </Container>

      <a onClick={handleSubmit}>
        <FooterButton>
          <Flex>
            <Text
              className="is-uppercase"
              color="white"
              align="center"
              weight="bold"
              size="medium"
              letterSpacing="loose"
            >
              ADD
            </Text>
          </Flex>
        </FooterButton>
      </a>
    </>
  );
};

const MyEnhancedForm = withFormik({
  mapPropsToValues: ({ placeNameValue }) => ({
    place_id: uuid(),
    name: placeNameValue,
    business_type: [],
    address_line_1: '',
    city: '',
    state: '',
    country: '',
    post_code: '',
    latitude: '',
    longitude: '',
    timezone: '',
  }),

  // Custom sync validation

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'At least 3 Characters Required!')
      .max(75, 'Not greater than 75 Characters!')
      .required('Place Name is required!'),
    address_line_1: yup.string().required('Street Address is required'),
    city: yup.string().required('Suburb / City is required'),
    post_code: yup.string().required('Post Code is required'),
    timezone: yup.string().required('Timezone is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'PlaceAddForm',
})(PlaceForm);

export default MyEnhancedForm;
