import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import gql from 'graphql-tag';
import { uniq, first } from 'lodash';
import { useStoreState } from 'easy-peasy';

import { Text } from '../../components/elements';
import ImageSlider from '../../components/ImageSlider';

import Social from './Social';
import UserArticle from '../../components/UserArticle';
import FollowButtonAction from '../../components/FollowButtonAction';
import { getPlaceTagline, parseSocialPlace } from '../../utils/helpers';
import client from '../../utils/apolloClient';

const Container = styled.div`
  &&& {
    z-index: 1800;
    .modal-content,
    .modal-card {
      max-height: 100vh;
      overflow-x: hidden;
      overflow-y: hidden;
      width: 100%;
    }
    .modal-background {
      background-color: rgba(0, 0, 0, 1);
    }
  }
`;
// const ButtonStyle = styled.div`
//   &&& {
//     position: absolute;
//     right: 4px;
//     top: 5px;
//   }
// `;

const PlaceWrapper = styled.div`
  padding: 0.5rem;
`;

const ButtonWrapper = styled.div`
  padding-top: 0.5rem;
`;

const searchQuery = gql`
  query searchItemReview($input: SearchInput, $imageInput: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        profile_image_url
        color_code
      }
    }
    search_social(input: $imageInput) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const RightView = ({ images, place, preViewImage }) => {
  const { userId } = useStoreState(state => state.auth);
  const allUserIds = uniq(images.map(image => image.audit.created_by));
  const imageIds = uniq(images.map(image => image.image_id));
  const [socials, setSocial] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    client.clientPublic
      .query({
        query: searchQuery,
        variables: {
          imageInput: {
            filter: { social_filter: { object_id: imageIds, object_type: 'IMAGE' } },
          },
          input: {
            filter: {
              user_filter: {
                user_id: allUserIds,
              },
            },
          },
        },
      })
      .then(({ data }) => {
        if (data) {
          setUsers(data.search_users.user_listing);
          setSocial(data.search_social.social_aggregation.total_social_per_object_id);
          setLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return null;
  }

  const user = first(users.filter(userData => userData.user_id === preViewImage.audit.created_by));

  return (
    <>
      <UserArticle
        user={user}
        subHeading={moment(preViewImage.audit.created_at).calendar()}
        maskProps={{ width: 50 }}
        maskTextProps={{
          color: 'white',
          weight: 'bold',
          size: 'medium',
          letterSpacing: 'loose',
        }}
        headingLeftElement={
          <ButtonWrapper>
            <FollowButtonAction
              objectId={preViewImage.audit.created_by}
              objectType="USER"
              textProps={{ size: 'micro', weight: 'semibold' }}
              buttonProps={{ width: 0.4 }}
            />
          </ButtonWrapper>
        }
      />
      <PlaceWrapper>
        <Text size="small" weight="semibold" color="primaryColor">
          {place.name}
        </Text>
        <Text size="smaller" color="darkGrey">
          {getPlaceTagline(place.business_type, place.cuisine_style)}
        </Text>
        <Text color="specialGrey" size="smaller">
          {moment(place.audit.created_at).fromNow()}
        </Text>
      </PlaceWrapper>
      {images.map(item => {
        const socialsPerObject = socials.filter(edge => edge.object_id === item.image_id);
        const socialArray =
          socialsPerObject.length !== 0 ? first(socialsPerObject).total_social_count : [];
        const social = parseSocialPlace(socialArray, userId);
        const isActive = item.image_id === preViewImage.image_id;

        return (
          <Social
            isActive={isActive}
            objectId={item.image_id}
            parentId={place.place_id}
            objectType="IMAGE"
            likeActive={social.like.active}
            likeCountInt={social.like.count}
            likeSocialIdS={social.like.id}
          />
        );
      })}
    </>
  );
};

const PhotoPreviewModal = ({
  isActive,
  isClose,
  images,
  place,
  whichImage,
  onSliderChange,
  preViewImage,
}) => {
  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <section className="modal-content" style={{ background: 'black' }}>
        <div className="columns is-centered" style={{ background: 'black' }}>
          <div className="column is-9" style={{ background: 'black', padding: '3rem' }}>
            <div>
              <ImageSlider
                images={images}
                whichImage={whichImage}
                onSliderChange={onSliderChange}
              />
            </div>
          </div>
          <div />
          <div
            className="column"
            style={{ background: 'white', paddingTop: '1rem', margin: '4rem 0rem' }}
          >
            <RightView images={images} place={place} preViewImage={preViewImage} />
          </div>
        </div>
      </section>
      <button className="modal-close is-large" aria-label="close" onClick={isClose} />
    </Container>
  );
};

export default PhotoPreviewModal;
