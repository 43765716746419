import React from 'react';
import styled from 'styled-components';

import PageHeader from './components/mobile/PageHeader';
import MenuItem from './components/mobile/MenuItem';

const Container = styled.div`
  padding-top: 6rem;
`;

const MobileView = ({ children }) => {
  const [tab, setTab] = React.useState('menu');
  return (
    <React.Fragment>
      <PageHeader tab={tab} setTab={setTab} />
      <Container>
        <MenuItem />
        <MenuItem />
        {children}
      </Container>
    </React.Fragment>
  );
};

export default MobileView;
