import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MdChevronRight } from 'react-icons/md';

import { FlexRows, Text, Icon } from '../../components/elements';
import MobileEventsModal from './Events/MobileEventsModal';
import MobilePlaceModal from './Places/MobilePlaceModal';
import MobileItemModal from './Items/MobileItemModal';

const items = [
  {
    key: 'events',
    name: 'Events',
    path: '/profile/my-bookmarks-events',
    icon: 'discoverEventsBlue',
  },
  {
    key: 'places',
    name: 'Place',
    path: '/profile/my-bookmarks-places',
    icon: 'placesAroundMeBlue',
  },
  {
    key: 'items',
    name: 'Items or dishes',
    path: '/profile/my-bookmarks-shared-items',
    icon: 'findAndShareItemBlue',
  },
];

const Container = styled.div``;

const Item = styled(FlexRows).attrs({ justify: 'space-between' })`
  background: ${({ theme: { colors } }) => colors.white};
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
  border-radius: 0.5rem;
  margin: 0.5rem 0rem;
`;

const MobileView = () => {
  const [isActive, setActive] = useState('');
  return (
    <Container>
      {items.map(item => (
        <a key={item.name} onClick={() => setActive(item.key)}>
          <Item>
            <div>
              <FlexRows>
                <Icon name={item.icon} size={1.5} />
                &nbsp; &nbsp;
                <Text align="left">{item.name}</Text>
              </FlexRows>
            </div>
            <Text color="specialGrey">
              <MdChevronRight size={25} />
            </Text>
          </Item>
        </a>
      ))}
      <MobilePlaceModal isActive={isActive === 'places'} onClose={() => setActive('')} />
      <MobileItemModal isActive={isActive === 'items'} onClose={() => setActive('')} />
      <MobileEventsModal isActive={isActive === 'events'} onClose={() => setActive('')} />
    </Container>
  );
};

export default MobileView;
