import React from 'react';
import styled from 'styled-components';
import { FlexRows, Icon, Text } from '../../../components/elements';

const Container = styled.div`
  padding: 1rem 0rem;
  margin: 0rem 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const Wrapper = styled.div`
  padding: 0rem 1rem;
`;
const TextWrapper = styled(Text)`
  padding-right: 6px;
`;
const MobilePlaces = () => (
  <Container>
    <FlexRows justify="flex-start" align="flex-start">
      <div>
        <Icon name="findAndShareItemSolid" size={2} />
      </div>
      <Wrapper>
        <Text size="tiny" color="coolGrey">
          2 months ago
        </Text>

        <FlexRows justify="space-between">
          <TextWrapper color="#00AEEF">Ram</TextWrapper>
          <TextWrapper>reviewed</TextWrapper>

          <TextWrapper color="#00AEEF">Rococo Acland Street</TextWrapper>
        </FlexRows>
      </Wrapper>
    </FlexRows>
  </Container>
);

export default MobilePlaces;
