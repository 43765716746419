import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { uniq, first } from 'lodash';
import { useStoreState } from 'easy-peasy';
import Slider from 'react-slick';
import moment from 'moment';

import Social from './Social';
import { parseSocialPlace, getPlaceTagline } from '../../../../utils/helpers';
import client from '../../../../utils/apolloClient';
import { Image, Text } from '../../../../components/elements';
import { getEncodedImage } from '../../../../utils/s3';

const Container = styled.div`
  &&& {
    z-index: 1800;
    .modal-content,
    .modal-card {
      max-height: 100vh;
      overflow-x: hidden;
      overflow-y: hidden;
      width: 100%;
    }
    .modal-background {
      background-color: rgba(0, 0, 0, 1);
    }
  }
`;

const SliderContainer = styled.div`
  justify-content: flex-start;
  &&& {
    img {
      object-fit: contain;
    }
  }
`;

const PlaceWrapper = styled.div`
  padding: 0.5rem;
`;

const searchQuery = gql`
  query searchItemReview($imageInput: SearchInput) {
    search_social(input: $imageInput) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const ImageSlider = ({ images, whichImage, onSliderChange }) => {
  const settings = {
    // dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    initialSlide: whichImage,
    afterChange: onSliderChange,
  };

  return (
    <SliderContainer>
      <Slider {...settings}>
        {images.map(image => (
          <figure className="image is-3by2">
            <Image source={getEncodedImage(image.image.url, 1024, 1024)} radius={0} />
          </figure>
        ))}
      </Slider>
    </SliderContainer>
  );
};

const RightView = ({ images, preViewImage }) => {
  const { userId } = useStoreState(state => state.auth);
  const imageIds = uniq(images.map(image => image.image.image_id));
  const [socials, setSocial] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (imageIds !== null) {
      client.clientPublic
        .query({
          query: searchQuery,
          variables: {
            imageInput: {
              filter: { social_filter: { object_id: imageIds, object_type: 'IMAGE' } },
            },
          },
        })
        .then(({ data }) => {
          if (data) {
            setSocial(data.search_social.social_aggregation.total_social_per_object_id);
            setLoading(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return null;
  }

  return (
    <>
      {images.map(item2 => {
        const socialsPerObject = socials.filter(edge => edge.object_id === item2.image.image_id);
        const socialArray =
          socialsPerObject.length !== 0 ? first(socialsPerObject).total_social_count : [];
        const social = parseSocialPlace(socialArray, userId);
        const isActive = item2.image.image_id === preViewImage.image.image_id;
        console.log('preViewImage', preViewImage);
        return (
          <>
            {isActive && (
              <PlaceWrapper>
                <Text size="small" weight="semibold" color="primaryColor">
                  {(item2.image.object_type === 'PLACE' ||
                    item2.image.object_type === 'PLACE_REVIEW') &&
                    item2.place.name}
                  {(item2.image.object_type === 'ITEM' ||
                    item2.image.object_type === 'ITEM_REVIEW') &&
                    item2.place.name}
                </Text>
                <Text size="smaller" color="darkGrey">
                  {(item2.image.object_type === 'PLACE' ||
                    item2.image.object_type === 'PLACE_REVIEW') &&
                    getPlaceTagline(item2.place.business_type, item2.place.cuisine_style)}
                  {(item2.image.object_type === 'ITEM' ||
                    item2.image.object_type === 'ITEM_REVIEW') &&
                    item2.item.place_name}
                </Text>
                <Text color="specialGrey" size="smaller">
                  {moment(item2.image.audit.created_at).fromNow()}
                </Text>
              </PlaceWrapper>
            )}
            <Social
              isActive={isActive}
              objectId={item2.image.image_id}
              objectType="IMAGE"
              likeActive={social.like.active}
              likeCountInt={social.like.count}
              likeSocialIdS={social.like.id}
            />
          </>
        );
      })}
    </>
  );
};

const PhotoPreviewModal = ({
  isActive,
  isClose,
  images,
  whichImage,
  onSliderChange,
  preViewImage,
}) => {
  // console.log('preViewImage', preViewImage);
  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <section className="modal-content" style={{ background: 'black' }}>
        <div className="columns is-centered" style={{ background: 'black' }}>
          <div className="column is-9" style={{ background: 'black', padding: '3rem' }}>
            <div>
              <ImageSlider
                images={images}
                whichImage={whichImage}
                onSliderChange={onSliderChange}
              />
            </div>
          </div>
          <div />
          <div
            className="column"
            style={{ background: 'white', paddingTop: '1rem', margin: '4rem 0rem' }}
          >
            <RightView images={images} preViewImage={preViewImage} />
          </div>
        </div>
      </section>
      <button className="modal-close is-large" aria-label="close" onClick={isClose} />
    </Container>
  );
};

export default PhotoPreviewModal;
