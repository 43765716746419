import tzLookup from 'tz-lookup';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import { isNaN } from 'lodash';

async function getDaysEndTime(dateNow, daysToAdd, timeToAdd) {
  const reqDate = new Date();
  reqDate.setTime(dateNow.getTime() + daysToAdd * 86400000 + timeToAdd);
  return reqDate;
}

async function getElapsedTime(zoneTime) {
  return (
    zoneTime.hours() * 60 * 60 * 1000 +
    zoneTime.minutes() * 60 * 1000 +
    zoneTime.seconds() * 1000 +
    zoneTime.milliseconds()
  );
}

async function getRemainingTime(zoneTime) {
  const time_end = 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999;
  return time_end - (await getElapsedTime(zoneTime));
}

export const getZoneTime = (date, latitude, longitude) => {
  const lat = !isNaN(parseFloat(latitude)) ? parseFloat(latitude).toFixed(5) : -37.867573;
  const long = !isNaN(parseFloat(longitude)) ? parseFloat(longitude).toFixed(5) : 144.978814;
  // console.log(lat, long);
  const time_zone = tzLookup(lat, long);
  return momentTimezone.tz(date, time_zone);
};

export const getTodayDateRange = async (latitude, longitude) => {
  const startDate = new Date();
  const zoneTime = await getZoneTime(startDate.toISOString(), latitude, longitude);
  const remainingTimeMs = await getRemainingTime(zoneTime);
  const endDate = await getDaysEndTime(startDate, 0, remainingTimeMs);

  return {
    startDate: zoneTime.toISOString(),
    endDate: endDate.toISOString(),
  };
};

export const getThisWeekDateRange = async (latitude, longitude) => {
  const dayToThisWeek = [0, 6, 5, 4, 3, 2, 1];
  const startDate = new Date();
  const zoneTime = await getZoneTime(startDate.toISOString(), latitude, longitude);
  const remaining_time_ms = await getRemainingTime(zoneTime);
  const endDate = await getDaysEndTime(startDate, dayToThisWeek[zoneTime.day()], remaining_time_ms);
  return {
    start_date: zoneTime.toISOString(),
    endDate: endDate.toISOString(),
  };
};

export const getThisWeekendDateRange = async (latitude, longitude) => {
  const dayToWeekendStart = [0, 4, 3, 2, 1, 0, 0];
  const dayToWeekendEnd = [0, 6, 5, 4, 3, 2, 1];
  const dateNow = new Date();
  const zoneTime = await getZoneTime(dateNow.toISOString(), latitude, longitude);
  const remaining_time_ms = await getRemainingTime(zoneTime);
  const startDate = await getDaysEndTime(
    dateNow,
    dayToWeekendStart[zoneTime.day()],
    dayToWeekendEnd[zoneTime.day()] ? -(await getElapsedTime(zoneTime)) : 0,
  );
  const endDate = await getDaysEndTime(dateNow, dayToWeekendEnd[zoneTime.day()], remaining_time_ms);
  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
};

const getNextMonday = currentTime => {
  const dayINeed = 1;
  const today = moment(currentTime)
    .subtract(3, 'days')
    .isoWeekday();
  if (today <= dayINeed) {
    return moment(currentTime).isoWeekday(dayINeed);
  }
  return moment(currentTime)
    .add(1, 'weeks')
    .isoWeekday(dayINeed);
};

export const getAllExactEventFilter = async (latitude, longitude) => {
  const today = await getTodayDateRange(latitude, longitude);
  const weekend = await getThisWeekendDateRange(latitude, longitude);
  const thisWeek = await getThisWeekDateRange(latitude, longitude);
  const currentTimeZoneTime = await getZoneTime(moment().toISOString(), latitude, longitude);
  const currentTime = `${moment(currentTimeZoneTime).format('L')} 12:00 AM`;
  const data = [
    {
      name: 'today',
      description: 'Today',
      range: {
        start_date_range: {
          end_date: today.endDate,
        },
        end_date_range: {
          start_date: today.startDate,
        },
      },
    },
    {
      name: 'this-week',
      description: 'This Week',
      range: {
        start_date_range: {
          end_date: thisWeek.endDate,
        },
        end_date_range: {
          start_date: thisWeek.start_date,
        },
      },
    },
    {
      name: 'weekend',
      description: 'Weekend',
      range: {
        start_date_range: {
          end_date: weekend.endDate,
        },
        end_date_range: {
          start_date: weekend.startDate,
        },
      },
    },
    {
      name: 'upcoming-event',
      description: 'Upcoming Events',
      range: {
        start_date_range: {
          start_date: moment(getNextMonday(currentTime)).toISOString(),
        },
      },
    },
  ];
  return data;
};

export const getEventFilterWithoutPast = async (latitude, longitude) => {
  const currentTimeZoneTime = await getZoneTime(moment().toISOString(), latitude, longitude);
  const data = {
    range: {
      start_date_range: {
        end_date: moment(currentTimeZoneTime)
          .utc()
          .toISOString(),
      },
      end_date_range: {
        start_date: moment(currentTimeZoneTime)
          .add(6, 'months')
          .utc()
          .toISOString(),
      },
    },
  };

  return data;
};
