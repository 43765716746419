import React from 'react';
import PropTypes from 'prop-types';
import { MdMyLocation, MdLocationOn } from 'react-icons/md';
import { useStoreActions, useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import { Text, SearchInput } from '../../elements';

import colors from '../../../theme/styles/colors';

const Container = styled.div``;

const FieldWrapper = styled.div`
  padding: 1rem;
`;

const SuggestionHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGreyAlpha};
  padding: 0.5rem 1rem;
`;

const ItemWrapper = styled.div`
  padding: 0.5rem 0rem;
  margin: 0rem 1rem;
`;

const CurrentLocationWrapper = styled.div`
  padding: 0.5rem 1rem;
`;

const SecondaryHeader = ({ title }) => (
  <SuggestionHeader>
    <Text className="is-uppercase" size="small" weight="regular">
      {title}
    </Text>
  </SuggestionHeader>
);

const Item = ({ title, subtitle, onSelect }) => (
  <a onClick={onSelect}>
    <ItemWrapper>
      <Text className="is-capitalized" size="small" weight="regular" color="primaryColor">
        {title}
      </Text>
      <Text className="is-capitalized" size="small" weight="regular">
        {subtitle}
      </Text>
    </ItemWrapper>
  </a>
);

const CurrentLocation = () => (
  <CurrentLocationWrapper className="is-flex">
    <Text className="icon" color="darkGrey">
      <MdMyLocation size={20} />
    </Text>
    &nbsp;&nbsp;
    <Text
      className="is-uppercase"
      size="small"
      weight="semibold"
      color="primaryColor"
      lineSpacing="loose"
    >
      Current Location
    </Text>
  </CurrentLocationWrapper>
);

const MobileLocationSuggestion = ({
  query,
  hideHeader,
  locations,
  onSearchInputChange,
  handleClickListener,
  back,
}) => {
  const updateUsedLocation = useStoreActions(state => state.global.updateUsedLocation);
  const addRecentViewLocation = useStoreActions(state => state.global.addRecentViewLocation);
  const recentViewLocations = useStoreState(state => state.global.recentViewLocations);

  const updateLocation = location => {
    const data = {
      addressLine1: '',
      addressLine2: '',
      city: location.name,
      state: location.state_name,
      postcode: location.post_code,
      country: location.country_name,
      latitude: location.latitude,
      longitude: location.longitude,
    };
    return data;
  };

  return (
    <Container>
      {!hideHeader && (
        <>
          <FieldWrapper>
            <SearchInput
              value={query}
              placeholder="Search Location"
              onChange={event => onSearchInputChange(event.target.value)}
              onClear={() => onSearchInputChange('')}
              leftIcon={<MdLocationOn size={20} color={colors.specialGrey} />}
            />
          </FieldWrapper>
        </>
      )}
      <SecondaryHeader title="Suggestions" />
      {locations.length === 0 && <CurrentLocation />}
      {locations.map((location, index) => (
        <Item
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          title={location.name}
          subtitle={`${location.state_name}, ${location.post_code}`}
          onSelect={() => {
            updateUsedLocation(updateLocation(location));
            addRecentViewLocation(location);
            handleClickListener(location);
            back();
          }}
        />
      ))}
      {recentViewLocations.length !== 0 && <SecondaryHeader title="Recent Search" />}
      {recentViewLocations.map(location => (
        <Item
          key={location.city}
          title={location.name}
          subtitle={`${location.state_name}, ${location.post_code}`}
          onSelect={() => {
            updateUsedLocation(updateLocation(location));
            handleClickListener(location);
            back();
          }}
        />
      ))}
    </Container>
  );
};

SecondaryHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

MobileLocationSuggestion.defaultProps = {
  handleClickListener: () => {},
  back: () => {},
};

MobileLocationSuggestion.propTypes = {
  handleClickListener: PropTypes.func,
  back: PropTypes.func,
};

export default MobileLocationSuggestion;
