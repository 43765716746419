import React from 'react';
import styled from 'styled-components';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';

const Container = styled.div`
  margin-top: 1rem;
`;

const ParentComponent = () => {
  return (
    <>
      <Container>
        <RectShape color="#f0f0f0" style={{ width: '100%', height: '2rem' }} />
      </Container>
      <Container>
        <RectShape color="#f0f0f0" style={{ width: '100%', height: '2rem' }} />
      </Container>
      <Container>
        <RectShape color="#f0f0f0" style={{ width: '100%', height: '2rem' }} />
      </Container>
      <Container>
        <RectShape color="#f0f0f0" style={{ width: '100%', height: '2rem' }} />
      </Container>
    </>
  );
};

const ParentLoader = () => {
  return (
    <ReactPlaceholder ready showLoadingAnimation>
      <ParentComponent />
    </ReactPlaceholder>
  );
};

export default ParentLoader;
