import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { capitalize, first } from 'lodash';
import { MdMyLocation, MdLocationOn } from 'react-icons/md';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { AutoCompleteInput, Text } from '../elements';
import client from '../../utils/apolloClient';
import colors from '../../theme/styles/colors';

const Container = styled.form`
  &&& {
    .icon {
      pointer-events: initial;
    }
    .react-autosuggest__container {
      position: relative;
    }
    .react-autosuggest__suggestions-container {
      display: none;
    }
    .react-autosuggest__suggestions-container--open {
      max-height: 15rem;
      overflow-y: scroll;
      display: block;
      position: absolute;
      width: 100%;
      background: #fff;
      border: 1px solid #f6f6f6;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
      z-index: 2000;
    }
    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 7px 10px;
      border-bottom: 1px solid #f6f6f6 !important;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: #f6f6f6;
    }
  }
`;

const IconWrapper = styled.div`
  padding-top: 0.15rem;
  padding-right: 0.5rem;
`;

const searchLocationQuery = gql`
  query searchLocation($search_criteria: String!) {
    search_location(input: { search_criteria: $search_criteria }) {
      location_listing {
        code
        country_id
        name
        post_code
        state_name
        country_name
        latitude
        longitude
        location
      }
    }
  }
`;

const renderLocationSuggestion = location => (
  <div className="is-flex">
    <IconWrapper>
      <MdLocationOn size={18} color={colors.specialGrey} />
    </IconWrapper>
    <div>
      <Text className="is-capitalized" color="primaryColor" size="tiny" weight="bold">
        {capitalize(location.name)}
      </Text>
      <Text color="darkGrey" size="micro">
        {location.state_name}, {location.post_code}
      </Text>
    </div>
  </div>
);
const getSuggestionValue = suggestion => suggestion.suburbName;

const LocationSearchInput = ({ inputClass, width, maxWidth }) => {
  const updateUsedLocation = useStoreActions(state => state.global.updateUsedLocation);
  const usedLocation = useStoreState(state => state.global.usedLocation);
  const currentLocation = useStoreState(state => state.global.currentLocation);
  const [location, setLocation] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const setSuburbSuggestions = suggestionsValue => {
    setSuggestions(suggestionsValue);
  };

  useEffect(() => {
    setLocation(`${usedLocation.city} ${usedLocation.state} ${usedLocation.postcode}`);
  }, [usedLocation.city, usedLocation.postcode, usedLocation.state]);

  const onSuggestionSelected = (event, { suggestion }) => {
    setLocation(`${capitalize(suggestion.name)} ${suggestion.state_name} ${suggestion.post_code}`);
    updateUsedLocation({
      addressLine1: '',
      addressLine2: '',
      city: suggestion.name,
      state: suggestion.state_name,
      postcode: suggestion.post_code,
      country: suggestion.country_name,
      latitude: suggestion.latitude,
      longitude: suggestion.longitude,
    });
  };

  const onChange = (event, { newValue, method }) => {
    if (method === 'type') {
      setLocation(newValue);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    client.clientPublic
      .query({
        query: searchLocationQuery,
        variables: { search_criteria: value },
      })
      .then(({ data }) => {
        setSuburbSuggestions(data.search_location.location_listing);
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleCurrentLocation = () => {
    setLocation(
      `${capitalize(currentLocation.city)} ${currentLocation.state} ${currentLocation.postcode}`,
    );
    updateUsedLocation(currentLocation);
  };

  return (
    <Container
      onSubmit={e => {
        e.preventDefault();
        if (suggestions.length !== 0) {
          const suggestion = first(suggestions);
          setLocation(
            `${capitalize(suggestion.name)} ${suggestion.state_name} ${suggestion.post_code}`,
          );
          updateUsedLocation({
            addressLine1: '',
            addressLine2: '',
            city: suggestion.name,
            state: suggestion.state_name,
            postcode: suggestion.post_code,
            country: suggestion.country_name,
            latitude: suggestion.latitude,
            longitude: suggestion.longitude,
          });
        }
      }}
    >
      <AutoCompleteInput
        inputClass={inputClass}
        value={capitalize(location)}
        width={width}
        maxWidth={maxWidth}
        placeholder="Suburb Name"
        leftIcon={<MdLocationOn size={20} color={colors.specialGrey} />}
        rightIcon={
          (usedLocation.postcode !== currentLocation.postcode || location.length === 0) && (
            <MdMyLocation size={18} color={colors.specialGrey} />
          )
        }
        onClickRightIcon={handleCurrentLocation}
        enableInputWithAllElements
        onChange={onChange}
        // onBlur={onBlur}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderLocationSuggestion}
        onSuggestionSelected={onSuggestionSelected}
      />
    </Container>
  );
};

export default LocationSearchInput;
