import React from 'react';
import styled from 'styled-components';

import { CheckBox, Text } from '../../../../components/elements';

const CheckBoxWrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const DealsCheckBox = () => {
  return (
    <CheckBoxWrapper>
      <Text className="is-capitalized" color="dark" size="lmedium" weight="regular">
        Deals
      </Text>
      <CheckBox initialValue={false} label="Show places with deals only" onChange={() => {}} />
    </CheckBoxWrapper>
  );
};

export default DealsCheckBox;
