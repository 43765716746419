import React from 'react';
import styled from 'styled-components';
import { Text } from '../elements';

const Container = styled.div`
  width: 100%;
  padding-bottom: 1rem;
`;

const Label = styled.div`
  padding-bottom: 0.5rem;
`;

const Field = ({ label, labelProps, children, ...props }) => {
  return (
    <Container {...props}>
      <Label {...labelProps}>
        <Text>{label}</Text>
      </Label>
      {children}
    </Container>
  );
};

export default Field;
