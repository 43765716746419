import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import randomColor from 'randomcolor';
import { Text } from '.';
import { firstTwoLetterPicker } from '../../utils/helpers';

const MaskWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ randomColorCode }) => randomColorCode};
  width: ${({ maskProps }) => `${maskProps.width}px`};
  height: ${({ maskProps }) => `${maskProps.width}px`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  padding: 1rem;
  margin-right: 0.2rem;
`;

const GetImages = ({ name, maskProps, maskTextProps, borderRadius, randomColorCode }) => {
  return (
    <MaskWrapper
      maskProps={maskProps}
      borderRadius={borderRadius}
      randomColorCode={randomColorCode}
    >
      <Text {...maskTextProps}>{firstTwoLetterPicker(name)}</Text>
    </MaskWrapper>
  );
};

GetImages.defaultProps = {
  name: 'Nasim Akhtar',
  maskProps: { width: 55 },
  maskTextProps: { color: 'white', weight: 'bold', size: 'large', letterSpacing: 'loose' },
  borderRadius: 6,
  randomColorCode: randomColor({
    luminosity: 'dark',
  }),
};

GetImages.propTypes = {
  name: PropTypes.string,
  randomColorCode: PropTypes.string,
  maskProps: PropTypes.object,
  maskTextProps: PropTypes.object,
  borderRadius: PropTypes.number,
};

export default GetImages;
