import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import moment from 'moment';

import DeskTopSignInModal from '../Layout/DeskTopSignInModal';
import DeskTopSignupModal from '../Layout/DeskTopSignupModal';
import ForgotPasswordModal from '../Layout/ForgotPasswordModal';
import client from '../../../utils/apolloClient';
import MobileViewLayout from '../Layout/MobileViewLayout';
import MobileSecondaryHeader from '../Layout/MobileSecondaryHeader';
import MobileSignInModal from '../Layout/MobileSignInModal';
import MobileSignupModal from '../Layout/MobileSignupModal';

import Header from './Header';

const Container = styled.div`
  margin: 0;
  padding: 0;
`;
const Body = styled.div`
  margin-top: 7.4rem;
`;

const searchEventsQuery = gql`
  query searchEventsQuery($input: SearchInput) {
    search_events(input: $input) {
      event_listing {
        event_id
        name
        tagline
        place_name
        slug
      }
    }
  }
`;

const searchPlacesQuery = gql`
  query searchPlacesQuery($input: SearchInput) {
    search_places(input: $input) {
      place_listing {
        place_id
        address_line_1
        name
        city
        slug
        post_code
      }
    }
  }
`;

const searchItemQuery = gql`
  query searchItemsQuery($input: SearchInput) {
    search_items(input: $input) {
      item_listing {
        item_id
        name
        place_name
        slug
      }
    }
  }
`;

const MenuLayout = ({
  disableSubHeader = false,
  disableCart = false,
  children,
  isEnableMobileSecondaryHeader,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [searchDataList, setSearchDataList] = useState([]);
  const [eventDateFilter, setEventDateFilter] = useState('');

  const setSearchListToState = (searchData, load) => {
    setSearchDataList(searchData);
    setLoading(load);
  };

  const fetchData = () => {
    // const { usedLocation } = this.props;
    // const date = await getZoneTime(moment(), usedLocation.latitude, usedLocation.longitude);
    setEventDateFilter(moment().toISOString());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleBusinessesSearch = value => {
    if (value === '') {
      setSearchListToState([], false);
    } else {
      client.clientPublic
        .query({
          query: searchPlacesQuery,
          variables: {
            input: {
              search_criteria: value,
              filter: {
                place_filter: {
                  listing_status: 'APPROVED',
                  event_business: false,
                  status: 'ACTIVE',
                },
              },
            },
          },
        })
        .then(({ data, load }) => {
          if (loading) {
            setLoading(load);
          } else {
            const searchData = data.search_places.place_listing;
            setSearchListToState(searchData, loading);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const handleEventsSearch = value => {
    const event_filter = {};

    Object.assign(
      event_filter,
      { status: 'ACTIVE', draft: false },
      eventDateFilter !== '' && { end_date_range: { start_date: eventDateFilter } },
    );
    client.clientPublic
      .query({
        query: searchEventsQuery,
        variables: {
          input: { search_criteria: value, filter: { event_filter } },
        },
      })
      .then(({ data, load }) => {
        if (loading) {
          setLoading(load);
        } else {
          const searchData = data.search_events.event_listing;
          setSearchListToState(searchData, loading);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleItemsSearch = value => {
    client.clientPublic
      .query({
        query: searchItemQuery,
        variables: {
          input: {
            search_criteria: value,
            filter: {
              item_filter: { approval_status: 'APPROVED' },
              place_filter: { status: 'ACTIVE' },
            },
          },
        },
      })
      .then(({ data, load }) => {
        if (loading) {
          setLoading(load);
        } else {
          const searchData = data.search_events.event_listing;
          setSearchListToState(searchData, loading);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const func = {
    handleBusinessesSearch,
    handleEventsSearch,
    handleItemsSearch,
  };

  const state = { searchDataList, loading, eventDateFilter };

  return (
    <>
      <div className="is-hidden-desktop">
        {isEnableMobileSecondaryHeader ? (
          <MobileSecondaryHeader {...props} />
        ) : (
          <MobileViewLayout {...props} func={func} state={state} />
        )}
        <MobileSignInModal />
        <MobileSignupModal />
      </div>
      <div className="is-hidden-touch">
        <Container>
          <DeskTopSignInModal />
          <DeskTopSignupModal />
          <ForgotPasswordModal />
          <Header disableSubHeader={disableSubHeader} disableCart={disableCart} />
          <Body>{children}</Body>
        </Container>
      </div>
    </>
  );
};

export default MenuLayout;
