import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';

import { ScrollView, Text } from '../../../../components/elements';

const Container = styled.div`
  padding-top: 1rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;
const Item = styled.div`
  padding: 0.3rem 1rem;
  cursor: pointer;
  p {
    color: ${({ theme: { primaryColor }, isActive }) => (isActive ? primaryColor : '#000000')};
  }
  border-bottom: 3px solid
    ${({ theme: { primaryColor }, isActive }) => (isActive ? primaryColor : 'transparent')};
`;

const Menu = (productTypes, selected) =>
  productTypes.map(item => (
    <Item
      key={item.name}
      isActive={selected === item.name}
      className="has-text-weight-bold has-text-centered"
    >
      <Text size="small" weight="medium">
        {item.description}
      </Text>
    </Item>
  ));

const MenuCategoryTabs = ({ onClick }) => {
  const productType = useStoreState(state => state.place.filterInputs.productType);
  const [filterTags, setFilterTags] = React.useState(productType);
  const productTypes = useStoreState(state => state.place.productTypes);

  // console.log('productType', productType);
  const addAllInProductTypes = [
    {
      id: 'ck0z91on18o8nv099856p4odh9',
      name: 'all',
      description: 'All',
      display_order: '0',
    },
  ];
  const finalProductTypes = addAllInProductTypes.concat(productTypes);
  const menu = Menu(finalProductTypes, filterTags);
  const handleFilter = key => {
    setFilterTags(key);
    onClick(key);
  };

  useEffect(() => {
    setFilterTags(productType);
  }, [productType]);

  return (
    <Container>
      <ScrollView menu={menu} onSelect={key => handleFilter(key)} />
    </Container>
  );
};

export default MenuCategoryTabs;
