import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';

import {
  FlexColumns,
  Text,
  Icon,
  FlexRows,
  FlexItem,
  TextCircularMask,
} from '../../../../components/elements';

const Container = styled(FlexColumns)`
  background: ${({ theme: { colors } }) => colors.white};
  padding: 1rem 0rem;

  margin-bottom: 1rem;
`;
const UserNameWrapper = styled(FlexItem)`
  padding: 1rem;
`;
const TextStyle = styled(Text).attrs({ align: 'center' })``;
const EditButton = styled(Link)`
  position: absolute;
  right: 6%;
`;

const Span = styled.span`
  color: ${({ theme: { colors } }) => colors.dark} !important;
`;
const Info = ({ user }) => {
  const { userImage } = useStoreState(state => state.auth);
  return (
    <Container>
      {!isNull(userImage) ? (
        <figure className="image is-128x128">
          <label htmlFor="file-input">
            <img className="is-rounded" src={userImage} alt="alt" />
          </label>
        </figure>
      ) : (
        <TextCircularMask
          name={`${user.first_name} ${user.last_name}`}
          randomColorCode={user.color_code}
          borderRadius={40}
          maskProps={{ width: 80, height: 80 }}
          maskTextProps={{ color: 'white', weight: 'bold', size: 'big', letterSpacing: 'loose' }}
        />
      )}

      <FlexRows>
        <UserNameWrapper>
          <TextStyle
            className="is-uppercase"
            color="primaryColor"
            weight="bold"
            size="small"
            letterSpacing="loose"
          >
            {user.first_name} {user.last_name}
          </TextStyle>
          <TextStyle color="specialGrey" size="tiny">
            @{user.display_name}
          </TextStyle>
          <TextStyle size="tiny" weight="semibold" color="darkGrey">
            <Span>{user.followerCount}</Span> Follower &nbsp;
            <Span>{user.followingCount}</Span> Following
          </TextStyle>
        </UserNameWrapper>
        <EditButton to="/profile-update">
          <Icon name="profileEditBlue" size={2} />
        </EditButton>
      </FlexRows>
    </Container>
  );
};

export default Info;
