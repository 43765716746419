import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';

const updatePlaceMutation = gql`
  mutation updatePlace($input: PlaceInput) {
    update_place(input: $input) {
      place_id
      name
      error {
        code
        description
      }
    }
  }
`;

const fetchPlaceQuery = gql`
  query fetch_place($input: NavigatePlaceInput) {
    fetch_place(input: $input) {
      place_id
      name
      address_line_1
      city
      state
      country
      post_code
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      service_type {
        name
        description
        display_order
      }
      cuisine_style {
        name
        description
        display_order
      }
      feature {
        name
        description
        logo
        display_order
      }
      business_type {
        name
        description
        display_order
      }
      listing_status
      claim_status
    }
  }
`;

const PlaceDataCorrections = ({ history, match }) => {
  return (
    <Layout history={history} match={match}>
      <Analytics pageName="PlaceCorrections" category="placecorrection" title="Place-Correction" />
      <Query query={fetchPlaceQuery} variables={{ input: { place_id: match.params.placeId } }}>
        {({ data, loading, error }) => {
          if (error) {
            return error;
          }
          return (
            <Mutation
              mutation={updatePlaceMutation}
              onCompleted={() => {
                swal('Great!', 'Place has been updated successfully!', 'success').then(() => {
                  history.push('/places');
                });
              }}
            >
              {(update_place, { error: error1 }) => (
                <>
                  <div className="is-hidden-desktop">
                    <MobileView />
                  </div>
                  <div className="is-hidden-touch">
                    <DesktopView
                      data={data}
                      loading={loading}
                      error={error1}
                      onSubmit={values => {
                        const input = {
                          place_id: '2290802a-c911-4718-a829-5edc420e0d05',
                          name: values.name,
                          address_line_1: values.address,
                          city: values.city,
                          post_code: values.postcode,
                          website: values.website,
                          mobile: values.mobile,
                        };

                        console.log('updateValues', input);
                        update_place({ variables: { input } });
                      }}
                    />
                  </div>
                </>
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default PlaceDataCorrections;
