import React from 'react';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';

const Profile = ({ history, match }) => {
  return (
    <Layout
      history={history}
      match={match}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading="MY ORDERS"
    >
      <Analytics pageName="MyOrders" category="myorder" title="MyOrder-List">
        <div className="is-hidden-desktop">
          <MobileView />
        </div>
        <div className="is-hidden-touch">
          <DesktopView />
        </div>
      </Analytics>
    </Layout>
  );
};

export default Profile;
