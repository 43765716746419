import React, { Component } from 'react';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import { SliderRail, Handle, Track } from './Elements';

const sliderStyle = {
  position: 'relative',
  width: '100%',
  touchAction: 'none',
};

const domain = [1, 100];
const defaultValues = [2];

class MySlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: defaultValues.slice(),
      update: defaultValues.slice(),
    };
  }

  onUpdate = update => {
    const { onChange } = this.props;
    this.setState({ update });
    onChange(update);
  };

  onChange = values => {
    const { onChange } = this.props;
    this.setState({ values });
    onChange(values);
  };

  render() {
    const {
      state: { values },
    } = this;

    return (
      <Slider
        mode={1}
        step={1}
        domain={domain}
        rootStyle={sliderStyle}
        onUpdate={this.onUpdate}
        onChange={this.onChange}
        values={values}
      >
        <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    );
  }
}

export default MySlider;
