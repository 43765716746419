import React, { useState } from 'react';
import styled from 'styled-components';
import { truncate } from 'lodash';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Text, FlexRows } from '../../../../components/elements';
import ItemTypeModal from './ItemTypeModal';
import CuisinesModal from './CuisinesModal';

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const TextWrapper = styled.div`
  padding-left: 1rem;
`;

const ItemWrapper = styled(FlexRows)`
  padding: 0.5rem;
`;

const Item = styled(ItemWrapper)`
  &&& {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
    padding: 1rem 0rem;
  }
`;

const ModalFooter = styled.a`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem;
  position: fixed;
  bottom: 0;
`;

const SelectItem = ({ itemName, onFinalItemSubmission }) => {
  const [isActiveItemTypeModal, setIsActiveItemTypeModal] = useState(false);
  const [isActiveCuisinesModal, setIsActiveCuisinesModal] = useState(false);
  const [itemType, setItemType] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  return (
    <>
      <Content>
        <Item justify="flex-start" align="flex-start">
          <TextWrapper>
            <Text className="is-uppercase" size="small" weight="semibold" color="primaryColor">
              Name of the Item
            </Text>
            <Text className="is-capitalized" size="tiny" color="darkGrey" weight="semibold">
              {itemName}
            </Text>
          </TextWrapper>
        </Item>
        <a onClick={() => setIsActiveItemTypeModal(true)}>
          <Item justify="space-between">
            <TextWrapper>
              <Text className="is-uppercase" size="small" weight="semibold" color="primaryColor">
                Item Type
              </Text>
              <Text size="tiny" align="center" color="darkGrey" weight="semibold">
                {itemType.length !== 0
                  ? truncate(`${itemType.map(edge => edge.description)}`, { length: 50 })
                  : 'e.g. Entree, Main'}
              </Text>
            </TextWrapper>
            <Text color="specialGrey">
              <MdKeyboardArrowRight size={30} />
            </Text>
          </Item>
        </a>
        <a onClick={() => setIsActiveCuisinesModal(true)}>
          <Item justify="space-between">
            <TextWrapper>
              <Text className="is-uppercase" size="small" weight="semibold" color="primaryColor">
                Cuisines
              </Text>
              <Text size="tiny" color="darkGrey" weight="semibold">
                {itemType.length !== 0
                  ? truncate(`${cuisines.map(edge => edge.description)}`, { length: 50 })
                  : 'e.g. Italian, Indian, American'}
              </Text>
            </TextWrapper>
            <Text color="specialGrey">
              <MdKeyboardArrowRight size={30} />
            </Text>
          </Item>
        </a>
        <ItemTypeModal
          itemType={itemType}
          isActive={isActiveItemTypeModal}
          onClose={() => setIsActiveItemTypeModal(false)}
          onSelect={values => {
            setItemType(values);
            setIsActiveItemTypeModal(false);
          }}
        />
        <CuisinesModal
          cuisine={cuisines}
          isActive={isActiveCuisinesModal}
          onClose={() => setIsActiveCuisinesModal(false)}
          onSelect={values => {
            setCuisines(values);
            setIsActiveCuisinesModal(false);
          }}
        />
      </Content>
      <ModalFooter onClick={() => onFinalItemSubmission(itemType, cuisines)}>
        <Text className="is-uppercase" color="white" align="center" weight="bold" size="lmedium">
          ADD ITEM
        </Text>
      </ModalFooter>
    </>
  );
};

export default SelectItem;
