import React from 'react';
import styled from 'styled-components';

import { PageHeader } from '../../components/elements';
import MobileForm from './MobileForm';

const Container = styled.div`
  padding: 4rem 0rem;
`;

const MobileView = ({ children, onSubmit, loading }) => (
  <React.Fragment>
    <PageHeader text="Add New Card" />
    <Container>
      <MobileForm onSubmit={onSubmit} loading={loading} />
    </Container>
    {children}
  </React.Fragment>
);

export default MobileView;
