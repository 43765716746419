import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { getApiImages } from '../../../../utils/reusableQuery';
import { Image } from '../../../../components/elements';
import blankImage from '../../../../assets/images/blank.png';
import { getEncodedImage } from '../../../../utils/s3';

const Figure = styled.figure``;

const ImageView = ({ placeId, itemReviewId }) => {
  const [images, setImage] = useState(null);

  useEffect(() => {
    const get = async () => {
      const data = await getApiImages({
        object_id: itemReviewId,
        object_type: ['ITEM_REVIEW'],
        parent_id: placeId,
        parent_type: ['PLACE'],
        // links_to_id: [itemReviewId],
        // links_to_type: ['ITEM_REVIEW'],
      });
      setImage(data);
    };
    get();
  }, [itemReviewId, placeId]);

  if (images === null) {
    return (
      <div className="columns is-multiline">
        <div className="column is-2">
          <Figure className="image is-5by3">
            <Image source={blankImage} objectFit="contain" />
          </Figure>
        </div>
      </div>
    );
  }

  return (
    <div className="columns is-multiline">
      {images.map(image => (
        <div className="column is-2">
          <Figure className="image is-5by3">
            <Image source={getEncodedImage(image.url, 1024, 1024)} objectFit="contain" />
          </Figure>
        </div>
      ))}
    </div>
  );
};

export default ImageView;
