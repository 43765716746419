import React from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { MdSearch } from 'react-icons/md';
import { Text, Flex, Button, Input, Pager } from '../../components/elements';
import LocationSearchInput from '../PlaceSearchForReview/LocationSearch';
import GetImages from '../../components/GetImages';
import { getPlaceTagline } from '../../utils/helpers';

const Container = styled(Pager)`
  padding-top: 2rem;
`;
const Wrapper = styled.div`
  padding: 2rem 1rem;
`;

const ItemWrapper = styled.div`
  padding: 1rem 0rem;
`;

const Item = styled(ItemWrapper)`
  &&& {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  }
`;

const AddPlaceWrapper = styled.div`
  margin-top: 5rem;
  padding: 1rem 0rem;
  border-top: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;

const TextWrapper = styled.div`
  padding-left: 1rem;
`;

const DesktopView = ({
  places,
  placeQueryTag,
  setPlaceQueryTag,
  onSuggestionsFetchRequested,
  loading,
  history,
  setLocation,
}) => (
  <Container>
    <Text size="large" weight="semibold" paddingBottom={0.5}>
      Claim your place
    </Text>
    <Text size="tiny" color="darkGrey">
      Claim your free listing&apos;s and customize to you taste! If you are an authorized
      representative or the owner of this place, sign up to manage your place, keep all the
      information upto date and grow your business. You will be also be able to showcase your
      specialties, upload photos, engage with customers, receive notifications and track page
      visits. Features include unlimited free posts, displaying multiple menus, black board
      specials, publishing deals or offers for free, creating and managing events and much, much
      more...
    </Text>
    <Wrapper>
      <div className="columns is-centered">
        <div className="column is-10 is-vcentered">
          <form
            onSubmit={event => {
              event.preventDefault();
              onSuggestionsFetchRequested();
            }}
          >
            <ItemWrapper>
              <div className="columns is-vcentered">
                <div className="column is-5">
                  <div className="control has-icons-left">
                    <Input
                      placeholder="Search by place name"
                      id="ClaimPlace_SearchPlace"
                      value={placeQueryTag}
                      onChange={event => setPlaceQueryTag(event.target.value)}
                    />
                    <span className="icon is-small is-left">
                      <MdSearch size={20} />
                    </span>
                  </div>
                </div>
                <div className="column is-4">
                  <LocationSearchInput
                    onHandleLocationChange={value => setLocation(value.location)}
                  />
                </div>
                <div className="column">
                  <Button
                    className={`${loading && 'is-loading'}`}
                    name="Search"
                    width={4}
                    type="submit"
                    height={0.9}
                    backgroundColor="primaryColor"
                    textProps={{
                      color: loading ? 'primaryColor' : 'white',
                      size: 'small',
                      weight: 'semibold',
                    }}
                  />
                </div>
              </div>
            </ItemWrapper>
          </form>
          {places.map(item => (
            <Item key={item.place_id}>
              <div className="columns is-vcentered">
                <div className="column is-5">
                  <div>
                    <Flex justify="flex-start">
                      <GetImages
                        name={item.name}
                        maskProps={{ width: 50, height: 50 }}
                        variables={{
                          object_type: ['PLACE'],
                          object_id: item.place_id,
                          links_to_id: [item.place_id],
                          links_to_type: ['PLACE'],
                        }}
                      />
                      <TextWrapper>
                        <Link to={`/claim-your-place/${item.place_id}`}>
                          <Text className="is-capitalized" size="medium" color="primaryColor">
                            {capitalize(item.name)}
                          </Text>
                        </Link>
                        <Text size="small" color="darkGrey">
                          {item.tagline}
                        </Text>
                        <Text size="small" color="darkGrey">
                          {getPlaceTagline(item.business_type, item.cuisine_style)}
                        </Text>
                      </TextWrapper>
                    </Flex>
                  </div>
                </div>
                <div className="column is-4">
                  <Text size="small" color="darkGrey">
                    {item.address_line_1}, <br />
                    {item.city}, {item.state} {item.post_code}
                  </Text>
                </div>
                <div className="column">
                  <Button
                    name="Claim this place"
                    width={2}
                    height={0.9}
                    className="is-small"
                    backgroundColor="primaryColor"
                    textProps={{ color: 'white', size: 'small', weight: 'semibold' }}
                    onClick={() => history.push(`/claim-your-place/${item.place_id}`)}
                  />
                </div>
              </div>
            </Item>
          ))}
          <AddPlaceWrapper>
            <div className="columns is-vcentered">
              <div className="column is-9">
                <Text color="darkGrey" size="small">
                  If you cannot find your Place, contact us to join our growing family or click{' '}
                  <a href="/contact-us">here</a> to add it
                </Text>
              </div>
              <div className="column">
                <Button
                  name="Add a place"
                  width={2.9}
                  height={0.9}
                  className="is-small"
                  backgroundColor="primaryColor"
                  textProps={{ color: 'white', size: 'small', weight: 'semibold' }}
                  onClick={() => history.push('/add-place')}
                />
              </div>
            </div>
          </AddPlaceWrapper>
        </div>
      </div>
    </Wrapper>
  </Container>
);

export default withRouter(DesktopView);
