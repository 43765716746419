import React from 'react';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';

const MyBookmarks = ({ history, match }) => (
  <Layout
    history={history}
    match={match}
    isEnableMobileSecondaryHeader
    secondaryHeaderHeading="MY BOOKMARKS"
  >
    <div className="is-hidden-desktop">
      <MobileView />
    </div>
    <div className="is-hidden-touch">
      <DesktopView />
    </div>
  </Layout>
);

export default MyBookmarks;
