import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
// import moment from 'moment';
import { isNull } from 'lodash';
import ReviewItem from './ReviewItem';

const searchItemReviewQuery = gql`
  query searchItemReview($input: SearchInput) {
    search_item_review(input: $input) {
      item_review_listing {
        place_id
        item_id
        item_review_id
        rating
        content
        audit {
          created_at
          created_by
        }
        user_details {
          user_id
          display_name
          profile_image_url
          color_code
        }
      }
    }
    search_social(input: { filter: { social_filter: { object_type: "ITEM_REVIEW" } } }) {
      social_aggregation {
        total_social_per_object_id {
          object_id
          total_social_count {
            type
            count
            user {
              user_id
              social_id
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 0rem 0rem;
  padding-right: 20%;
`;

const Reviews = ({ item }) => {
  return (
    <Container>
      <Query
        query={searchItemReviewQuery}
        variables={{
          input: {
            sort: 'CREATED_DESC',
            filter: {
              item_review_filter: {
                item_id: item.item_id,
                status: 'APPROVED',
              },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return '';
          }
          if (error) {
            return 'error';
          }

          if (isNull(data.search_item_review)) {
            return '';
          }

          if (data.search_item_review.item_review_listing.length === 0) {
            return '';
          }
          const reviews = data.search_item_review.item_review_listing;
          const socials = data.search_social.social_aggregation.total_social_per_object_id;

          return (
            <>
              {reviews.map(review => (
                <ReviewItem review={review} socials={socials} key={review.item_review_id} />
              ))}
            </>
          );
        }}
      </Query>
    </Container>
  );
};

export default Reviews;
