import React, { useState } from 'react';
import styled from 'styled-components';
import { first, forEach, filter } from 'lodash';
import { useStoreState } from 'easy-peasy';

import Info from './mobile/Info';
import Tickets from './mobile/Tickets';
import CheckoutForm from './mobile/CheckoutForm';
import Loader from '../../components/loader/Loader';

const Container = styled.div``;

const parseOfflineTicket = listOfTickets => {
  const finalTicket = [];
  forEach(listOfTickets, item => {
    forEach(item.sales_method, item2 => {
      if (item2 !== 'offline') {
        finalTicket.push(item);
      }
    });
  });
  return finalTicket;
};

const MobileView = ({
  eventData,
  loading,
  bookingFeeOfEvent,
  setBookingFeeOfEvent,
  subTotalPrice,
  setSubTotalPrice,
  totalQuantity,
  setTotalQuantity,
  ticketSelections,
  setTicketSelections,
  bookingFee,
  setBookingFee,
  discount,
  setDiscount,
  setCouponCode,
  checkSelectionHaveAttendeeDetailsOrNot,
  setCheckSelectionHaveAttendeeDetailsOrNot,
  finalSubmission,
  bookingLoading,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const [step, setStep] = useState(1);
  const [bookingInputs, setBookingInputs] = useState();
  const [storeOfTicketPlusAttendee, setStoreOfTicketPlusAttendee] = useState();
  const [applyCouponLoading, handleApplyCouponLoading] = useState(false);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [isCouponModalActive, setIsCouponModalActive] = useState(false);

  if (loading) {
    return <Loader />;
  }

  const {
    fetch_event,
    fetch_event_occurrence,
    fetch_event_occurrence_ticket,
    search_place_event_voucher,
  } = eventData;
  const tickets = filter(fetch_event_occurrence_ticket, item => item.status !== 'HIDDEN');

  const bookingFeeValues =
    fetch_event.fee.length !== 0
      ? fetch_event.fee.filter(
          item => item.name === 'booking_fee' && item.sales_method === 'online',
        )
      : [];

  const eventBookingFee = bookingFeeValues.length !== 0 ? first(bookingFeeValues).value : 0;
  const allVouchers = search_place_event_voucher.place_event_voucher_listing;

  return (
    <Container>
      {step === 1 && (
        <>
          <Info event={fetch_event} occurrence={first(fetch_event_occurrence)} />
          <Tickets
            userId={userId}
            allVouchers={allVouchers}
            occurrence={first(fetch_event_occurrence)}
            setStoreOfTicketPlusAttendee={setStoreOfTicketPlusAttendee}
            event={fetch_event}
            eventBookingFee={eventBookingFee}
            bookingFeeOfEvent={bookingFeeOfEvent}
            setBookingFeeOfEvent={setBookingFeeOfEvent}
            tickets={parseOfflineTicket(tickets)}
            subTotalPrice={subTotalPrice}
            bookingFee={bookingFee}
            discount={discount}
            setSubTotalPrice={setSubTotalPrice}
            totalQuantity={totalQuantity}
            setTotalQuantity={setTotalQuantity}
            ticketSelections={ticketSelections}
            setTicketSelections={setTicketSelections}
            setBookingFee={setBookingFee}
            checkSelectionHaveAttendeeDetailsOrNot={checkSelectionHaveAttendeeDetailsOrNot}
            setCheckSelectionHaveAttendeeDetailsOrNot={setCheckSelectionHaveAttendeeDetailsOrNot}
            applyCouponLoading={applyCouponLoading}
            handleApplyCouponLoading={handleApplyCouponLoading}
            setIsCouponModalActive={setIsCouponModalActive}
            isCouponModalActive={isCouponModalActive}
            setIsCouponApplied={setIsCouponApplied}
            setCouponCode={setCouponCode}
            setDiscount={setDiscount}
            loading={bookingLoading}
            back={() => setStep(1)}
            onHandleConfirmBooking={() => {
              if (!checkSelectionHaveAttendeeDetailsOrNot) {
                const ticketsData = ticketSelections.map(ticket => {
                  const customTicket = {
                    ticket_id: ticket.id,
                    quantity: ticket.quantity,
                  };
                  return customTicket;
                });
                const input = {
                  user_id: userId,
                  place_id: fetch_event.place_id,
                  event_id: fetch_event.event_id,
                  event_occurrence_id: first(fetch_event_occurrence).event_occurrence_id,
                  sales_method: 'online',
                  ticket: ticketsData,
                };
                setBookingInputs(input);
                finalSubmission(input);
              } else {
                setStep(2);
              }
            }}
          />
        </>
      )}
      {step === 2 && (
        <CheckoutForm
          event={fetch_event}
          ticketSelections={ticketSelections}
          storeOfTicketPlusAttendee={storeOfTicketPlusAttendee}
          totalQuantity={totalQuantity}
          loading={bookingLoading}
          orderTotal={subTotalPrice + bookingFee - discount + bookingFeeOfEvent}
          back={() => setStep(1)}
          onSubmit={values => {
            setStoreOfTicketPlusAttendee(values.ticketSelections);
            const ticketsData = values.ticketSelections.map(ticket => {
              const customTicket = {
                ticket_id: ticket.ticket_id,
                quantity: ticket.quantity,
                attendee: ticket.attendees.map(attendee => {
                  const name = attendee.attributes.filter(item => item.name === 'name');
                  const email = attendee.attributes.filter(item => item.name === 'email');
                  const mobile = attendee.attributes.filter(item => item.name === 'mobile');
                  const age = attendee.attributes.filter(item => item.name === 'age');
                  const gender = attendee.attributes.filter(item => item.name === 'gender');
                  return {
                    attendee_name: name.length !== 0 ? first(name).values : null,
                    attendee_email: email.length !== 0 ? first(email).values : null,
                    attendee_age: age.length !== 0 ? parseInt(first(age).values, 10) : null,
                    attendee_gender: gender.length !== 0 ? first(gender).values : null,
                    attendee_phone: mobile.length !== 0 ? first(mobile).values : null,
                  };
                }),
              };
              return customTicket;
            });
            const input = {
              user_id: userId,
              place_id: fetch_event.place_id,
              event_id: fetch_event.event_id,
              event_occurrence_id: first(fetch_event_occurrence).event_occurrence_id,
              sales_method: 'online',
              ticket: ticketsData,
            };
            // setBookingInputs(input);
            finalSubmission(input);
          }}
        />
      )}
    </Container>
  );
};

export default MobileView;
