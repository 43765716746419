import React from 'react';
import styled from 'styled-components';

import { PageHeader, Text } from '../../components/elements';
import MobileForm from './MobileForm';

const Container = styled.div`
  padding: 4rem 0rem;
`;

const Wrapper = styled.div`
  padding: 1rem;
`;

const MobileView = ({ children, onSubmit, loading }) => (
  <>
    <PageHeader text="Feed back" />
    <Container>
      <Wrapper>
        <Text size="tiny">
          is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book. It has survived not only five
          centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          It was popularised in the 1960s with the release of Letrase
        </Text>
      </Wrapper>
      <MobileForm onSubmit={onSubmit} loading={loading} />
    </Container>
    {children}
  </>
);

export default MobileView;
