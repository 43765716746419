import React from 'react';
import styled from 'styled-components';
import ProgressiveImage from './ProgressiveImage';

import blankImage from '../../assets/images/blank.png';

const ImgWrapper = styled.span`
  &&& {
    @-webkit-keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    img {
      border-radius: ${radius => radius || 0.7}rem;
      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
    }
  }
`;

const Image = ({ name, source, radius, objectFit, ...props }) => (
  <ImgWrapper radius={radius} objectFit={objectFit}>
    <ProgressiveImage overlaySrc={blankImage} src={source} {...props} />
  </ImgWrapper>
);

export default Image;
