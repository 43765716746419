import React, { useState, useEffect } from 'react';
import CounterInput from 'react-counter-input';
import styled from 'styled-components';
import colors from '../../../../../theme/styles/colors';
import font from '../../../../../theme/styles/fonts';

const ModifierCounter = ({ quantity, onChange, disabled }) => {
  const [count, setCount] = useState(quantity);

  useEffect(() => {
    setCount(quantity);
  }, [quantity]);

  const Container = styled.div`
    margin-left: 10px;
  `;

  return (
    <Container>
      <CounterInput
        min={1}
        max={disabled ? 1 : 100}
        count={count}
        onCountChange={value => {
          if (!disabled) {
            setCount(value);
            onChange(value);
          }
        }}
        wrapperStyle={{
          height: '1.8rem',
          borderRadius: '4px',
        }}
        btnStyle={{
          color: disabled ? colors.waterMarkGrey : colors.primaryColor,
          fontSize: font.size.bigger,
          fontWeight: font.weight.medium,
          border: '1px solid',
          borderRadius: '1.5rem',
          height: '3rem',
          width: '3rem',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
        inputStyle={{ width: '40px' }}
        disabled
      />
    </Container>
  );
};

export default ModifierCounter;
