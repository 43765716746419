/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { kebabCase } from 'lodash';
import moment from 'moment';

import colors from '../../theme/styles/colors';
import { Text, FlexRows, Pager } from '../../components/elements';
import Header from './components/desktop/Header';
import Tickets from './components/desktop/Tickets';
import { getTicketUrlPdf } from '../../utils/s3';
import { getZoneTime } from '../../utils/eventHelpers';

const Container = styled(Pager)``;
const Wrapper = styled.div`
  padding: 0.5rem 1rem;
  background-color: ${colors.lightGreyAlpha};
`;
const TicketContainer = styled.div`
  margin-top: 0.5rem;
  background-color: ${colors.white};
`;
const Head = styled.div`
  padding: 0rem 1rem;
`;

const TopView = styled(FlexRows).attrs({ justify: 'space-between', align: 'flex-end' })`
  padding: 0.5rem 1rem;
`;

const Element = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding: 0.5rem 1rem;
`;
const Heading = styled(FlexRows)`
  padding: 1rem;
  border-bottom: 2px solid ${colors.lightGreyTheta};
`;

const DesktopView = ({ event, booking, ticketRecord }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [eventStartDate, setEventStartDate] = useState(event.start);

  const getTicket = useCallback(async () => {
    const startDate = await getZoneTime(event.start, event.latitude, event.longitude);
    setEventStartDate(startDate);
    setLoading(true);
    const link = await getTicketUrlPdf(booking.url);
    setPdfUrl(link);
    setLoading(false);
  }, []);

  useEffect(() => {
    getTicket();
  }, []);

  if (!booking) {
    return '';
  }

  return (
    <Container>
      <Header event={event} />
      <Wrapper>
        <Head>
          <Text className="is-capitalized" size="medium" weight="semibold" paddingBottom={0.5}>
            Booking Confirmation
          </Text>
        </Head>

        <TicketContainer>
          <TopView>
            <div>
              <Text size="small" weight="semibold">
                Thank you for your order !! Your booking and tickets can be accessed from &apos;My
                Orders&apos;.
              </Text>
              <Text size="tiny" color="darkGrey">
                Your order number is {(booking && booking.booking_number) || ''}
              </Text>
              <Text size="tiny" color="darkGrey">
                Your event date: {moment(eventStartDate).format('LLLL')}
              </Text>
              <Text size="tiny" color="darkGrey">
                Your tickets have been sent to your listed email address.{' '}
              </Text>
            </div>
            <div>
              <a
                className={`button is-primary ${loading && 'is-loading'}`}
                href={pdfUrl}
                download={`${kebabCase(event.name)}-ticket.pdf`}
              >
                <Text size="small" color={loading ? 'primaryColor' : 'white'}>
                  Downloads Ticket(s)
                </Text>
              </a>
            </div>
          </TopView>
        </TicketContainer>
        <div className="columns">
          <div className="column">
            <TicketContainer>
              <Tickets ticketRecord={ticketRecord} eventId={event.event_id} />
            </TicketContainer>
          </div>

          <div className="column is-3">
            <TicketContainer>
              <Heading>
                <Text weight="semibold" color="darkGrey" size="large">
                  Order Details
                </Text>
              </Heading>
              <Element>
                <div>
                  <Text weight="semibold" color="darkGrey" size="small">
                    Total Quantity :
                  </Text>
                </div>
                <div>
                  <Text weight="semibold" color="darkGrey" size="small">
                    {booking.quantity && booking.quantity}
                  </Text>
                </div>
              </Element>
              <Element>
                <div>
                  <Text weight="semibold" color="darkGrey" size="small">
                    Sub Total :
                  </Text>
                </div>
                <div>
                  <Text weight="semibold" color="darkGrey" size="small">
                    ${parseFloat(booking.sub_total_price).toFixed(2)}
                  </Text>
                </div>
              </Element>
              <Element>
                <div>
                  <Text weight="semibold" color="darkGrey" size="small">
                    Booking Fee :
                  </Text>
                </div>
                <div>
                  <Text weight="semibold" color="darkGrey" size="small">
                    ${parseFloat(booking.booking_fee).toFixed(2)}
                  </Text>
                </div>
              </Element>
              <Element>
                <div>
                  <Text weight="semibold" color="darkGrey" size="small">
                    Transaction Fee :
                  </Text>
                </div>
                <div>
                  <Text weight="semibold" color="darkGrey" size="small">
                    ${parseFloat(booking.transaction_fee).toFixed(2)}
                  </Text>
                </div>
              </Element>

              <Element>
                <div>
                  <Text weight="semibold" color="darkGrey" size="small">
                    Total Fee :
                  </Text>
                </div>
                <div>
                  <Text weight="semibold" color="darkGrey" size="small">
                    ${parseFloat(booking.total_fee).toFixed(2)}
                  </Text>
                </div>
              </Element>
              {booking.discount && booking.discount !== 0 && (
                <Element>
                  <div>
                    <Text weight="semibold" color="primaryColor" size="small">
                      Promo
                    </Text>
                  </div>
                  <div>
                    <Text weight="semibold" color="primaryColor" size="small">
                      - ${parseFloat(booking.discount).toFixed(2)}
                    </Text>
                  </div>
                </Element>
              )}
              <Element>
                <div>
                  <Text weight="semibold" color="dark" size="small">
                    Order Total :
                  </Text>
                </div>
                <div>
                  <Text weight="semibold" color="dark" size="small">
                    ${parseFloat(booking.total_price).toFixed(2)}
                  </Text>
                </div>
              </Element>
            </TicketContainer>
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

export default DesktopView;
