import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { first } from 'lodash';

import { Text } from '../../../components/elements';
import GetImages from '../../../components/GetImages';

const Container = styled.article`
  padding-bottom: 1rem;
  justify-content: center;
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const itemQuery = gql`
  query fetch_item($input: NavigateItemInput) {
    fetch_item(input: $input) {
      item_id
      place_id
      place_name
      name
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      timezone
    }
  }
`;

const ReviewedItemHeader = ({ itemId, children }) => {
  return (
    <Container className="media">
      <Query
        query={itemQuery}
        variables={{ input: { item_id: itemId } }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return '';
          }
          if (error) {
            return '';
          }
          const { fetch_item } = data;
          const item = first(fetch_item);
          return (
            <>
              <figure className="media-left">
                <GetImages
                  name={item.name}
                  maskProps={{ width: 64, height: 64 }}
                  variables={{
                    object_type: ['ITEM'],
                    object_id: itemId,
                    links_to_id: [itemId],
                    links_to_type: ['ITEM'],
                  }}
                />
              </figure>
              <div className="media-content">
                <div className="content">
                  <div>
                    <Text
                      className="is-capitalized"
                      size="small"
                      weight="semibold"
                      lineHeight="normal"
                    >
                      {item.name}
                    </Text>
                    <Text size="micro" color="darkGrey" lineHeight="normal">
                      {item.place_name}
                    </Text>
                    <Text size="micro" color="darkGrey" lineHeight="normal">
                      {item.address_line_1} <br /> {item.city}, {item.state} {item.post_code},{' '}
                      {item.country}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="media-right">{children}</div>
            </>
          );
        }}
      </Query>
    </Container>
  );
};

ReviewedItemHeader.defaultProps = {};

ReviewedItemHeader.propTypes = {};

export default ReviewedItemHeader;
