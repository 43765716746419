import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdRadioButtonUnchecked, MdRadioButtonChecked } from 'react-icons/md';
import Text from './Text';
import { FlexRows } from '.';

import colors from '../../theme/styles/colors';

const Container = styled.div`
  &&& {
    padding-top: 0.2rem;
    i {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    }
  }
`;

const TextStyle = styled(Text)`
  padding-left: 0.5rem;
`;

const CheckBox = ({ label, onChange, value, labelTextProps, disabled = false }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(value);
  }, [value]);

  return (
    <Container>
      <a
        onClick={() => {
          if (!disabled) {
            setActive(!active);
            setTimeout(() => {
              onChange(!active);
            }, 1);
          }
        }}
      >
        <FlexRows justify="flex-start">
          {active ? (
            <MdRadioButtonChecked size={18} color={colors.primaryColor} />
          ) : (
            <MdRadioButtonUnchecked
              size={18}
              color={disabled ? colors.waterMarkGrey : colors.darkGrey}
            />
          )}
          <TextStyle {...labelTextProps}>{label}</TextStyle>
        </FlexRows>
      </a>
    </Container>
  );
};

CheckBox.defaultProps = {
  labelTextProps: { size: 'tiny', color: 'darkGrey', weight: 'regular' },
};

CheckBox.propTypes = {
  labelTextProps: PropTypes.object,
};

export default CheckBox;
