import React from 'react';
import styled from 'styled-components';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';

import GetImages from '../../../../components/GetImages';
import { Text, Button, FlexRows } from '../../../../components/elements';
import apolloClient from '../../../../utils/apolloClient';
// import AvatarWithText from '../../../../../components/loader/AvatarWithText';

const Wrapper = styled.article`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`;

const Container = styled.article`
  padding-bottom: 0.5rem;
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const fetchPlaceQuery = gql`
  query fetch_place($input: NavigatePlaceInput) {
    fetch_place(input: $input) {
      place_id
      name
      tagline
      description
      address_line_1
      city
      state
      post_code
      country
    }
  }
`;

const searchReviewQuery = gql`
  query searchItemReview($placeReviewFilter: PlaceReviewFilter) {
    search_place_review(input: { filter: { place_review_filter: $placeReviewFilter } }) {
      place_review_listing {
        place_id
        place_review_id
        month_visited
        overall_rating
        service_category {
          name
          description
        }
        content
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const deletePlaceReview = gql`
  mutation deletePlaceReview($input: NavigatePlaceReviewInput) {
    delete_place_review(input: $input) {
      place_review_id
      error {
        description
      }
    }
  }
`;

const ReviewedPlaceHeader = ({ review, history }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Query
      query={fetchPlaceQuery}
      variables={{ input: { place_id: review.place_id } }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return '';
        }
        if (error) {
          return '';
        }
        const { fetch_place } = data;
        return (
          <Wrapper>
            <Container className="media">
              <figure className="media-left">
                <GetImages
                  name={fetch_place.name}
                  maskProps={{ width: 80, height: 80 }}
                  variables={{
                    object_type: ['PLACE'],
                    object_id: fetch_place.place_id,
                    links_to_id: [fetch_place.place_id],
                    links_to_type: ['PLACE'],
                  }}
                />
              </figure>
              <div className="media-content">
                <div className="content">
                  <div>
                    <Text
                      className="is-capitalized"
                      size="small"
                      weight="semibold"
                      lineHeight="normal"
                    >
                      {fetch_place.name}
                    </Text>
                    <Text size="micro" color="darkGrey" lineHeight="normal">
                      {fetch_place.address_line_1} <br /> {fetch_place.city}
                      {fetch_place.post_code}, {fetch_place.state}, {fetch_place.country}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="media-right">
                <Text size="micro" color="darkGrey" lineHeight="normal">
                  {moment(review.audit.created_at).fromNow()}
                </Text>
              </div>
            </Container>
            <FlexRows justify="space-start">
              <Mutation
                client={apolloClient.clientPrivate}
                mutation={deletePlaceReview}
                variables={{
                  input: {
                    user_id: review.audit.created_by,
                    place_id: review.place_id,
                    place_review_id: review.place_review_id,
                  },
                }}
                update={(client, { data: { delete_place_review } }) => {
                  const { clientPublic } = apolloClient;
                  const {
                    search_place_review: { place_review_listing },
                  } = clientPublic.readQuery({
                    query: searchReviewQuery,
                    variables: {
                      placeReviewFilter: { user_id: userId, draft: true, status: 'SUBMITTED' },
                    },
                  });
                  const finalReview = place_review_listing.filter(
                    item => item.place_review_id !== delete_place_review.place_review_id,
                  );
                  clientPublic.writeQuery({
                    query: searchReviewQuery,
                    variables: {
                      placeReviewFilter: { user_id: userId, draft: true, status: 'SUBMITTED' },
                    },
                    data: {
                      search_place_review: {
                        place_review_listing: finalReview,
                      },
                    },
                  });
                }}
                onCompleted={() => {
                  swal('Poof! Your Place review has been deleted!', {
                    icon: 'success',
                  });
                }}
              >
                {(delete_place_review, { loading: deleteLoading }) => (
                  <Button
                    className={`${loading && 'is-loading'}`}
                    height={0}
                    width={2}
                    name="Delete"
                    textProps={{
                      size: 'tiny',
                      color: deleteLoading ? 'white' : 'darkGrey',
                    }}
                    onClick={() => {
                      swal('Are you sure you want to do this?', {
                        buttons: ['Cancel', true],
                      }).then(willDelete => {
                        if (willDelete) {
                          delete_place_review();
                        }
                      });
                    }}
                  />
                )}
              </Mutation>
              &nbsp;&nbsp;&nbsp;
              <Button
                height={0}
                name="Finish Review"
                textProps={{
                  size: 'tiny',
                  color: 'darkGrey',
                }}
                onClick={() =>
                  history.push(
                    // eslint-disable-next-line max-len
                    `/place-review/${review.place_id}?reviewId=${review.place_review_id}`,
                  )
                }
              />
            </FlexRows>
          </Wrapper>
        );
      }}
    </Query>
  );
};

ReviewedPlaceHeader.defaultProps = {};

ReviewedPlaceHeader.propTypes = {};

export default withRouter(ReviewedPlaceHeader);
