import React from 'react';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';

const Settings = ({ history, match }) => (
  <Layout
    history={history}
    match={match}
    isEnableMobileSecondaryHeader
    secondaryHeaderHeading="SETTINGS"
  >
    <Analytics pageName="Settings" category="settings" title="Setting-Page">
      <div className="is-hidden-desktop">
        <MobileView />
      </div>
      <div className="is-hidden-touch">
        <DesktopView />
      </div>
    </Analytics>
  </Layout>
);

export default Settings;
