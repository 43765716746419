import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { isNull, trim } from 'lodash';
import uuid from 'uuid';

import { Text, LocationSuggestion } from '../../../../components/elements';

const InputWrapper = styled.div`
  padding: 1rem 0rem;
`;

const DeliveryAddressForm = ({ onSubmit }) => {
  const [suggestionError, setSuggestionError] = useState();
  const addressId = uuid();
  const formik = useFormik({
    initialValues: {
      address_id: addressId,
      is_default: false,
      type: '',
      contact_name: '',
      contact_phone: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      post_code: '',
      state: '',
      country: '',
      latitude: '',
      longitude: '',
      status: '',
    },
    onSubmit,
  });

  const { values, setFieldValue } = formik;

  return (
    <>
      <InputWrapper>
        <Text size="small" weight="semibold" color="darkGrey" paddingBottom={1}>
          Enter street address or suburb name
        </Text>
        <LocationSuggestion
          initialValue={
            values.address_line_1
              ? // eslint-disable-next-line max-len
                `${values.address_line_1} ${values.city} ${values.state} ${values.post_code} ${values.country}`
              : ''
          }
          onSelect={location => {
            if (location.addressLine1 && location.length !== 0) {
              setFieldValue('address_line_1', trim(location.addressLine1));
              setFieldValue('city', location.city);
              setFieldValue('post_code', location.postcode);
              setFieldValue('state', location.state);
              setFieldValue('country', location.country || 'AUSTRALIA');
              setFieldValue('latitude', location.latitude);
              setFieldValue('longitude', location.longitude);
            } else {
              setSuggestionError('Street address required!');
            }
          }}
        />
        {suggestionError && (
          <Text color="danger" size="small">
            {suggestionError}
          </Text>
        )}
      </InputWrapper>
    </>
  );
};

export default DeliveryAddressForm;
