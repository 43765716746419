import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '../../../../components/elements';

const BookingButton = ({ history, event, disabled }) => {
  return (
    <Button
      // className={loading && 'is-loading'}
      name="Book Now"
      backgroundColor="primaryColor"
      textProps={{ color: 'white', weight: 'semibold' }}
      disabled={disabled}
      onClick={() => history.push(`/event-booking/${event.event_id}`)}
    />
  );
};

export default withRouter(BookingButton);
