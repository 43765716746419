import React from 'react';
import styled from 'styled-components';
import { first } from 'lodash';

import { Text, Avatar } from '../../components/elements';
import FollowButtonAction from '../../components/FollowButtonAction';

const UserContainer = styled.article`
  padding: 1rem;
  justify-content: center;
  border: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const DeskUser = ({ user, itemReview, placeReview, follow }) => {
  const totalItemReviewCount = itemReview.length !== 0 ? first(itemReview).count : 0;
  const totalPlaceReviewCount = placeReview.length !== 0 ? first(placeReview).count : 0;
  const totalFollowCount = follow.length !== 0 ? first(follow).count : 0;
  const totalReviewCount = totalItemReviewCount + totalPlaceReviewCount;

  return (
    <UserContainer className="media">
      <figure className="media-left">
        <p className="image is-64x64">
          <Avatar
            name={user.display_name}
            color={user.color_code}
            image={user.profile_image_url}
            maskProps={{ width: 64, height: 64 }}
            borderRadius={32}
          />
        </p>
      </figure>
      <div className="media-content">
        <div>
          <Text size="small" weight="semibold">
            {user.display_name}
          </Text>
          <Text color="darkGrey" size="tiny">
            {totalFollowCount} Followers, {totalReviewCount} Reviews
          </Text>
        </div>
      </div>
      <div className="media-right">
        <FollowButtonAction
          objectId={user.user_id}
          objectType="USER"
          defaultIcon="follow"
          activeIcon="following"
          isIconButton
          onlyIcon
          iconStyle={{ size: 3 }}
        />
      </div>
    </UserContainer>
  );
};

export default DeskUser;
