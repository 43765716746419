import React, { useState } from 'react';
import styled from 'styled-components';
import { first } from 'lodash';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { Text, CheckBox, FlexRows } from '../../../../components/elements';

const Item = styled.div`
  padding-bottom: 0.5rem;
  /* border-bottom: 2px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};*/
`;
const ItemContent = styled.div`
  padding: 0.25rem 1rem;
`;

const Heading = styled(FlexRows).attrs({ justify: 'space-between' })`
  padding-top: 0.25rem;
  padding-right: 1rem;
`;
const CheckBoxWrapper = styled.div`
  padding: 0rem;
`;

const ListingTypeFilter = ({
  listType,
  paidTicket,
  freeTicket,
  filterFuncInputs,
  filterInputs,
  onHandleClearItem,
}) => {
  const [toggle, setToggle] = useState(true);
  const listTypeInput = filterInputs.listingType.filter(item => item.name === 'public_listing');
  const paidInput = filterInputs.listingType.filter(item => item.name === 'PAID_TICKET');
  const freeInput = filterInputs.listingType.filter(item => item.name === 'FREE_TICKET');
  return (
    <Item>
      <Heading>
        <div className="is-flex">
          <span className="icon">
            <a onClick={() => setToggle(!toggle)}>
              {toggle ? (
                <Text color="dark" size="small" weight="regular">
                  <FaAngleUp />
                </Text>
              ) : (
                <Text color="dark" size="small" weight="regular">
                  <FaAngleDown />
                </Text>
              )}
            </a>
          </span>
          <Text className="is-capitalized" color="dark" size="lmedium" weight="regular">
            Listing Type
          </Text>
        </div>

        {(listTypeInput.length !== 0 || paidInput.length !== 0 || freeInput.length !== 0) && (
          <a onClick={onHandleClearItem}>
            <Text className="is-capitalized" color="primaryColor" size="tiny" weight="semibold">
              Clear
            </Text>
          </a>
        )}
      </Heading>
      {toggle && (
        <ItemContent>
          <CheckBoxWrapper>
            {listType.length !== 0 && (
              <CheckBox
                initialValue={listTypeInput.length !== 0}
                label={`Listing Only ( ${first(listType).count} )`}
                onChange={value =>
                  value
                    ? filterFuncInputs.addListingTypeInputs(first(listType))
                    : filterFuncInputs.removeListingTypeInputs(first(listType).name)
                }
              />
            )}
            {paidTicket.length !== 0 && (
              <CheckBox
                initialValue={paidInput.length !== 0}
                label={`Paid Event ( ${first(paidTicket).count} )`}
                onChange={value =>
                  value
                    ? filterFuncInputs.addTicketTypeInputs(first(paidTicket))
                    : filterFuncInputs.removeTicketTypeInputs(first(paidTicket).name)
                }
              />
            )}
            {freeTicket.length !== 0 && (
              <CheckBox
                initialValue={freeInput.length !== 0}
                label={`Free Event ( ${first(freeTicket).count} )`}
                onChange={value =>
                  value
                    ? filterFuncInputs.addTicketTypeInputs(first(freeTicket))
                    : filterFuncInputs.removeTicketTypeInputs(first(freeTicket).name)
                }
              />
            )}
          </CheckBoxWrapper>
        </ItemContent>
      )}
    </Item>
  );
};

export default ListingTypeFilter;
