import React from 'react';
import styled from 'styled-components';

import { FlexRows, Icon, Text } from '../../../components/elements';

const Container = styled.div`
  padding: 1rem 0rem;
  margin: 0rem 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const Wrapper = styled.div`
  padding: 0rem 1rem;
`;
const TextWrapper = styled(Text)`
  padding-right: 6px;
`;
const MobileEvents = () => (
  <Container>
    <FlexRows justify="flex-start" align="flex-start">
      <div>
        <Icon name="bookmark" size={2} />
      </div>
      <Wrapper>
        <Text size="tiny" color="coolGrey">
          2 days ago
        </Text>

        <FlexRows justify="flex-start" align="flex-start">
          <TextWrapper color="#00AEEF">Suresh</TextWrapper>
          <TextWrapper>bookmarked</TextWrapper>
          <TextWrapper color="#00AEEF">Jalsa</TextWrapper>
          <TextWrapper> at </TextWrapper>
          <TextWrapper color="#00AEEF">Las Tapas</TextWrapper>
        </FlexRows>
      </Wrapper>
    </FlexRows>
  </Container>
);

export default MobileEvents;
