import React from 'react';
import styled from 'styled-components';

import LocationForm from './LocationForm';
import { Text, CheckBox } from '../../../components/elements';

const Container = styled.div`
  padding: 0rem 2rem;
`;
const Wrapper = styled.div`
  padding-left: 0.4rem;
`;
const SubHeading = styled(Text)`
  span {
    color: ${({ theme: { colors: themeColor } }) => themeColor.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
  }
`;
const Heading = styled(Text)`
  span {
    color: ${({ theme: { colors: color } }) => color.primaryColor};
    font-size: ${({ theme: { fonts } }) => fonts.size.small};
  }
`;

const BoxWrapper = styled.div`
  padding-bottom: 1.5rem;
`;

const EditDetailsForm = ({ onSubmit, loading }) => {
  return (
    <>
      <Container>
        <Wrapper>
          <Heading size="big" weight="semibold" paddingBottom={0.5}>
            Report Errors at &quot;Sichuan Hot Pot Cuisine&quot;
          </Heading>
          <SubHeading size="small" weight="regular" paddingBottom={0.5}>
            100 Chapel street, St Kilda, Victoria 3122 Australia
          </SubHeading>
        </Wrapper>
        <br />
        <Text paddingBottom={1}>What&apos;s Wrong?</Text>
        <BoxWrapper>
          <div className="columns">
            <div className="column is-3">
              <CheckBox label="Data Errors with this place listing" />
            </div>
            <div className="column is-3">
              <CheckBox label="This place is a duplicate" />
            </div>
            <div className="column is-3">
              <CheckBox label="This place is inappropriate" />
            </div>
          </div>

          <div className="columns">
            <div className="column is-3">
              <CheckBox label="This place des not exist" />
            </div>
            <div className="column is-3">
              <CheckBox label="This place is permanently close" />
            </div>
            <div className="column is-3">
              <CheckBox label="Other" />
            </div>
          </div>
        </BoxWrapper>
        <br />
        <SubHeading size="medium" weight="regular" paddingBottom={0.5}>
          <div className="is-flex">
            <CheckBox /> PlaceInformation Edits
          </div>
        </SubHeading>
        <br />

        <LocationForm
          onSubmit={values => {
            onSubmit(values);
          }}
          loading={loading}
        />
      </Container>
    </>
  );
};

export default EditDetailsForm;
