import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { trim, replace } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowLeft, MdDone } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';

import { Radio, Text, Button, FlexRows, TextArea } from '../../elements';
import colors from '../../../theme/styles/colors';

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.5);
    }
    .modal-card {
      border-radius: 6px;
      width: 30rem;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    }
    .modal-card-head {
      padding: 1rem 1rem;
      padding-bottom: 0.6rem;
      background-color: rgba(255, 255, 255, 1);
      border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
    }
    .modal-card-body {
      padding: 2rem;
      padding-top: 1rem;
      .radio-btns {
        margin-bottom: 1rem;
      }
      .radio-btn {
        align-items: baseline;
      }
    }
    a {
      color: ${colors.primaryColor};
    }
  }
`;
const ButtonStyle = styled.div`
  ${'' /* &&& {
    position: absolute;
    right: 8px;
    top: 8px;
  } */}
`;

const Header = styled(FlexRows)`
  padding: 0rem 0rem;
  width: 100%;
  .open-hours-title {
    margin-left: 7rem;
  }
`;

const Footer = styled.footer`
  && {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 1);
    border-top: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  }
`;

const ReportMenuErrorModal = ({ isActiveModal, setIsActiveModal }) => {
  return (
    <Container className={`modal ${isActiveModal && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <Header justify="space-between">
            <Text size="large" weight="semibold" paddingBottom={0.5} className="open-hours-title">
              Report Menu Errors
            </Text>
            <IoMdClose
              size={20}
              color={colors.dark}
              style={{ marginLeft: '1rem', cursor: 'pointer' }}
              onClick={() => setIsActiveModal(false)}
            />
          </Header>
        </header>
        <div className="modal-card-body">
          <div className="radio-btns">
            <Text size="medium" weight="regular" paddingBottom={1}>
              What's Wrong ?
            </Text>
            <div className="is-flex radio-btn">
              <Radio label="" value={false} onChange={() => {}} disabled={false} />
              <Text size="small" weight="regular" color="darkGrey">
                The menu item price is incorrect
              </Text>
            </div>

            <div className="is-flex radio-btn">
              <Radio label="" value={false} onChange={() => {}} disabled={false} />
              <Text size="small" weight="regular" color="darkGrey">
                Menu items options are missing
              </Text>
            </div>
            <div className="is-flex radio-btn">
              <Radio label="" value={false} onChange={() => {}} disabled={false} />
              <Text size="small" weight="regular" color="darkGrey">
                Open & close hours are incorrect
              </Text>
            </div>
            <div className="is-flex radio-btn">
              <Radio label="" value={false} onChange={() => {}} disabled={false} />
              <Text size="small" weight="regular" color="darkGrey">
                Missing Menu Item
              </Text>
            </div>
            <div className="is-flex radio-btn">
              <Radio label="" value={false} onChange={() => {}} disabled={false} />
              <Text size="small" weight="regular" color="darkGrey">
                Other
              </Text>
            </div>
          </div>
          <Text size="smedium" weight="medium" paddingBottom={0.5}>
            Other Details
          </Text>
          <TextArea
            placeholder="Provide any additional details to ensure accuracy"
            style={{ resize: 'none' }}
          />
          <Text color="coolGrey" size="ultraMicro" paddingBottom={3}>
            Characters left: 0/500
          </Text>
        </div>
        <Footer className="modal-card-foot">
          <Button
            // className={isLoading && 'is-loading'}
            type="submit"
            name="Submit"
            width={3}
            backgroundColor="primaryColor"
            textProps={{
              color: colors.white,
              weight: 'semibold',
              size: 'small',
            }}
            style={{ width: '100%' }}
            onClick={() => {}}
          />
        </Footer>
      </div>
    </Container>
  );
};

export default withRouter(ReportMenuErrorModal);
