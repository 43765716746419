import React from 'react';
import styled from 'styled-components';

const Container = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 2px solid #fafafa;
  border-radius: 5px;
  background: #f0f0f0;
  outline: none;
  :focus {
    border: 2px solid #00aeef !important;
    border-radius: 5px;
    background: #fafafa;
  }
`;

const TextArea = ({ ...props }) => {
  return <Container {...props} />;
};

export default TextArea;
