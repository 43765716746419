import React from 'react';
import styled from 'styled-components';
import { FlexColumns, FlexRows, Text } from '../../../../components/elements';

const Container = styled(FlexRows)`
  margin: 0.5rem;
`;
const ScrollView = styled.div`
  overflow: auto;
  width: 100%;
`;
const Item = styled(FlexColumns)`
  background: ${({ theme: { colors } }) => colors.white};
  padding: 1rem;
  width: 23%;
  margin-right: 1rem;
`;

const Head = styled(Text).attrs({
  className: 'is-uppercase',
  color: 'primaryColor',
  size: 'tiny',
  weight: 'semibold',
  letterSpacing: 'loose',
})``;

const Number = styled(Text).attrs({
  size: 'tiny',
  weight: 'semibold',
  letterSpacing: 'loose',
})`
  padding-bottom: 0.5rem;
`;

const Points = ({ user }) => {
  const items = [
    { name: 'Badges', iconName: 'myBadgesBlue', count: user.myBadgesBlueCount },
    {
      name: 'Points',
      iconName: 'rewardPointsBlue',
      count: user.rewardPointsBlueCount,
    },
    { name: 'Liked', iconName: 'likeStarBlue', count: user.likeCount },
    { name: 'Loved', iconName: 'itemRatingGoldSolid', count: user.itemCount },
    { name: 'Photos', iconName: 'Photos', count: user.photosCount },
    { name: 'Shares', iconName: 'Share', count: user.shareCount },
    { name: 'Reviews', iconName: 'Reviews', count: user.reviewCount },
  ];
  return (
    <ScrollView>
      <Container justify="space-between">
        {items.map(edge => (
          <Item>
            <Number>{edge.count}</Number>
            <Head>{edge.name}</Head>
          </Item>
        ))}
      </Container>
    </ScrollView>
  );
};

export default Points;
