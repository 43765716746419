import React from 'react';
import { Icon } from '../components/elements';

const iconsList = [
  'addAPlaceWhite',
  'featureIcon',
  'flagThisReview',
  'flagThisReviewGrey',
  'follow',
  'following',
  'partyCateringSolid',
  'partyOrFunctionSolid',
  'roomServiceSolid',
  'schoolCateringSolid',
  'nightClubBookingSolid',
  'restaurantSolid',
  'barSolid',
  'happyHourSolid',
  'corporateCateringSolid',
  'corporateCateringSolidGrey',
  'aboutUs',
  'contactUsSolid',
  'listABusinessSolid',
  'claimABusinessSolid',
  'corporateCuisineSolid',
  'corporateFunctionSolid',
  'filterSolid',
  'mapViewSolid',
  'listViewSolid',
  'kraverSolid',
  'inspiredKraverSolid',
  'socialiteKraverSolid',
  'explorerKraver1Solid',
  'explorerKraver2Solid',
  'persistentKraverSolid',
  'perfectKraverSolid',
  'expertKraverSolid',
  'connoisseurKraverSolid',
  'superstarKraverSolid',
  'amazingKraverSolid',
  'incredibleKraverSolid',
  'marvelousKraverSolid',
  'djStationSolid',
  'supportAndFeedbackSolid',
  'groupCateringSolid',
  'placesMapMarkerSolid',
  'venueAreaLocMarkerSolid',
  'venueAreaSolid',
  'groupOrderSolid',
  'splitPaymentSolid',
  'bakerySolid',
  'pickupSolid',
  'breweriesAndWinsSolid',
  'itemRatingBlueSolid',
  'itemRatingSolidGrey',
  'eventMapMarkerSolid',
  'accommodationRoomsSolid',
  'eventFunctionPackagesSolid',
  'inHouseCateringSolid',
  'inHouseCateringSolid2',
  'venueAreaSolid',
  'venueCeilingHeightSolid',
  'venuePriceSolid',
  'enquireSolid',
  'meetingMaterialSolid',
  'termsAndConditionSolid',
  'bestSuitedForSolid',
  'parkingSolid',
  'venueMapMarkerSolid',
  'gallerySolid',
  'beenThereBlueSolid',
  'bookmarkSolid',
  'bookmarkSolidGrey',
  'dealsAndPromotionSolid',
  'photoAddSolid',
  'discoverEventsSolid',
  'facebookSolid',
  'findAndShareItemSolid',
  'findVenuesSolid',
  'placesAroundMeSolid',
  'restaurantAndBars',
  'reviewItemSolid',
  'reviewPlaceSolid',
  'tableBookingSolid',
  'takeawayOrDeliverySolid',
  'itemRatingGoldSolid',
  'partyCatering',
  'reviewAPlace',
  'partyOrFunctionPackagesBlue',
  'dealsAndPromotions',
  'roomServiceBlue',
  'nightClubBookingBlue',
  'Restaurant',
  'Bars',
  'happyHour',
  'corporateCatering',
  'contactUs',
  'corporateCuisine',
  'corporateFunction',
  'homeBlue',
  'shoppingCartBlue',
  'reviewAPlaceBlue',
  'activityFeedBlue',
  'notificationsBlue',
  'schoolCateringblue',
  'searchesBlue',
  'trackMyOrderBlue',
  'reorderBlue',
  'myProfileBlue',
  'facilitiesBlue',
  'featuresAndServices',
  'cateringPreferencesBlue',
  'disabilityAccessBlue',
  'decreaseQuantityBlue',
  'increaseQuantityBlue',
  'externalCateringBlue',
  'banquetOnRoundsBlue',
  'boardroomBlue',
  'classroomBlue',
  'cocktailBlue',
  'hollowSquareBlue',
  'theatreBlue',
  'uShapeTableBlue',
  'exhibitionBlue',
  'seatedBlue',
  'standingBlue',
  'banquetOvalBlue',
  'breakOutRoomsBlue',
  'danceFloorBlue',
  'addAPlaceBlue',
  'beenThereBlue',
  'beenThereGrey',
  'claimYourPlacesBlue',
  'commentBlue',
  'discoverEventsBlue',
  'findAndShareItemBlue',
  'findFriendsBlue',
  'findVenuesBlue',
  'likeBlue',
  'likeGrey',
  'likeStarBlue',
  'likeStarGrey',
  'bookmarkBlue',
  'bookmarkGrey',
  'myOrdersBlue',
  'placesAroundMeBlue',
  'settingsBlue',
  'reviewItemBlue',
  'supportAndFeedbackBlue',
  'tableBookingBlue',
  'takeawayOrDeliveryBlue',
  'partyCatering',
  'partyOrFunctionPackages',
  'dealsAndPromotions',
  'roomService',
  'schoolCatering',
  'nightClubBooking',
  'shareBlue',
  'locationBlue',
  'phoneBlue',
  'FollowGrey',
  'flagBlue',
  'flagGrey',
  'featuresBlue',
  'rewardPointsBlue',
  'loveTheItemBlue',
  'loveTheItemGrey',
  'myBadgesBlue',
  'profileEditBlue',
  'triedBlue',
  'triedGrey',
  'dateBlue',
  'deals',
  'findFriendsGrey',
  'settingsGrey',
];

const IconList = () => (
  <div className="container section">
    <br />
    <div className="columns is-multiline">
      {iconsList.map(item => (
        <div className="column is-3 has-text-centered " key={item}>
          <Icon name={item} />
          <br />
          <code className="code">{item}</code>
        </div>
      ))}
    </div>
  </div>
);

export default IconList;
