import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ButtonAnimation = posed.button({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const ButtonStyle = styled(ButtonAnimation)`
  &&& {
    display: flex;
    padding: 1.2rem 1rem;
    margin: 0;
    ${({ isFullWidth }) => isFullWidth && 'width:100%;'}
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: space-between;
    border: 1px solid
      ${({ theme: { colors }, borderColor, backgroundColor }) =>
        colors[borderColor] || `${colors[backgroundColor] || '#dce1e1'}`};
    color: ${({ theme: { colors } }) => colors.primaryColor};
    text-decoration: none;
    /* font-size: ${({ size, theme: { fonts } }) => fonts.size[size || 'medium']}; */
    font-weight: 600;
    text-align: center;
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    /* box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08); */
    background-color: ${({ theme: { colors }, backgroundColor }) =>
      backgroundColor ? colors[backgroundColor] : colors.white};
    cursor: pointer;
    p{
      line-height: 0px;
    }
    .icon{
      height: 0em;
    }
    :hover {
      border-color: ${({ theme: { colors }, borderHoverColor }) =>
        borderHoverColor ? colors[borderHoverColor] : colors.primaryColor};
      color: ${({ theme: { colors } }) => colors.primaryColor};
      text-decoration: none;
      /* fill: #262959; */
      /* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16); */
      /* transform: translateY(-1px); */
    }
  }
`;

const CheckoutButton = ({
  className,
  children,
  width,
  height,
  textProps,
  iconSize,
  isFullWidth,
  ...props
}) => (
  <ButtonStyle
    className={`button ${className}`}
    buttonWidth={width}
    buttonHeight={height}
    isFullWidth={isFullWidth}
    {...props}
  >
    {children}
  </ButtonStyle>
);

CheckoutButton.defaultProps = {
  className: '',
  borderRadius: 4,
};

CheckoutButton.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  borderRadius: PropTypes.number,
};

export default CheckoutButton;
