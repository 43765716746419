import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { truncate } from 'lodash';

import { Text, Icon, FlexRows } from '../../../../components/elements';

const Container = styled.div`
  /* padding: 1rem; */

  &&& {
    background-color: ${({ theme: { colors: themeColors } }) => themeColors.white};
    .is-full {
      width: 22rem;
      height: 100%;
    }

    .card-wrapper {
      height: 100%;
      position: relative;
      display: block;
      border: 1px solid ${({ theme: { colors: themeColors } }) => themeColors.borderColor};
      cursor: pointer;
      ${({ isAddedThisItem, theme: { colors: themeColors } }) =>
        isAddedThisItem && `border:2px solid ${themeColors.primaryColor}`}
      :hover {
        ${({ isAddedThisItem, theme: { colors: themeColors } }) =>
          isAddedThisItem
            ? `border:2px solid ${themeColors.primaryColor}`
            : `border:1px solid ${themeColors.primaryColor}`}
      }
    }
    .card {
      display: flex;
      box-shadow: none !important;
    }
    .icon {
      justify-content: flex-start;
    }
    .media {
      position: relative;
    }
  }
`;

const Left = styled.div`
  && {
    width: 100%;
    padding: 1rem;
    padding-top: 1rem;
    .item-info {
      margin-top: -12px;
    }
    @media (min-width: 1195px) and (max-width: 1206px) {
      .item-title {
        padding-bottom: 0.1rem;
        font-size: 14px;
      }
    }
    @media (min-width: 1024px) and (max-width: 1195px) {
      padding: 0.5rem;
      padding-left: 0.9rem;
      .item-title {
        padding-bottom: 0.1rem;
        font-size: 15px;
      }
      .item-price {
        padding-bottom: 0.1rem;
        font-size: 15px;
      }
      .item-info {
        font-size: 14px;
        margin-top: 0px;
      }
    }
    @media (min-width: 1024px) and (max-width: 1135px) {
      padding: 0.5rem;
      padding-left: 0.9rem;
      .item-title {
        padding-bottom: 0.1rem;
        font-size: 14px;
      }
      .item-price {
        padding-bottom: 0.1rem;
        font-size: 14px;
      }
      .item-info {
        font-size: 13px;
      }
    }
    @media (min-width: 1024px) and (max-width: 1096px) {
      .item-title {
        font-size: 13px;
      }
      .item-price {
        font-size: 13px;
      }
    }
  }
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.8rem;
`;

const IconWrapper = styled.span`
  display: flex;
  cursor: pointer;
  margin: 0.5rem;
`;

const Image = styled.img`
  &&& {
    width: 11.54rem;
    object-fit: cover;
    height: 10rem;
  }
`;

const IconStyle = styled(Icon).attrs({ size: 1 })`
  margin-right: 0.3rem;
`;

const Quantity = styled.div`
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  padding: 0.5rem;
`;

const SoldOut = styled.div`
  background: #e8e8e8;
  padding: 2px 10px;
  border-radius: 6px;
`;

const Description = styled.div`
  padding-top: 6px;
`;

const PopularItem = ({
  itemId,
  allowItemReview,
  name,
  price,
  hasShare,
  image,
  description,
  setModifiersModal,
  isAddedThisItem,
  numberOfAddedItem,
  joules,
  availability,
  unAvailableDates,
  isCustomService,
}) => {
  return (
    <Container
      isAddedThisItem={isAddedThisItem}
      className={isCustomService ? 'column is-one-third' : 'column is-half'}
      onClick={() => setModifiersModal(true)}
    >
      <div className="card-wrapper">
        {isAddedThisItem && (
          <Quantity className="is-flex">
            <Text color="primaryColor" size="medium" weight="semibold">
              x{numberOfAddedItem}
            </Text>
            {/* <Wrapper className="is-flex">
              <Text color="white" size="tiny" weight="semibold">
                Added to cart
              </Text>
            </Wrapper> */}
          </Quantity>
        )}
        <div className="is-flex">
          {image && (
            <Image src={image || 'https://bulma.io/images/placeholders/256x256.png'} alt="empty" />
          )}
          <Left>
            <Text weight="regular" size="smedium" paddingBottom="0.4" className="item-title">
              {name}
            </Text>
            <FlexRows justify="space-between">
              <Text size="smedium" className="item-price">
                {price}
              </Text>
              {(availability === 'UNAVAILABLE' || unAvailableDates !== null) && (
                <SoldOut>
                  <Text color="coolGrey" size="tiny" weight="semibold">
                    {unAvailableDates || 'Sold Out'}
                  </Text>
                </SoldOut>
              )}
            </FlexRows>

            {description && (
              <Description>
                <Text color="darkGrey" size="smaller" lineHeight="normal" weight="regular">
                  {truncate(description, {
                    length: 70,
                    separator: ' ',
                  })}
                </Text>
              </Description>
            )}
          </Left>
        </div>
        {hasShare && (
          <Bottom>
            {allowItemReview && (
              <Link to={`/item-review/${itemId}`}>
                <IconWrapper>
                  <IconStyle name="itemRatingSolidGrey" />
                  <Text size="micro" color="darkGrey">
                    Share
                  </Text>
                </IconWrapper>
              </Link>
            )}

            {joules && joules !== 0 && (
              <Text color="darkGrey" size="micro">
                {joules} Kj
              </Text>
            )}
          </Bottom>
        )}
      </div>
    </Container>
  );
};

export default withRouter(PopularItem);
