import React from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import client from '../../../../../utils/apolloClient';

import { Text, Radio, Button, FlexColumns } from '../../../../../components/elements';

const Container = styled.article`
  padding: 1rem;
  justify-content: center;
  border: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const Wrapper = styled.div`
  padding-left: 1.5rem;
`;

const deleteAddressMutation = gql`
  mutation delete_user_address($input: NavigateUserAddressInput) {
    delete_user_address(input: $input) {
      address_id
      type
      is_default
      contact_name
      contact_phone
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      timezone
      status
      error {
        description
      }
    }
  }
`;

const Address = ({
  address,
  handleEditAddress,
  onHandleDelete,
  defaultAddress,
  handleChangeDefault,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const checkPrimary = defaultAddress ? address.address_id === defaultAddress.address_id : false;
  return (
    <Container className="media">
      <div className="media-content">
        <div>
          <div className="is-flex">
            <Radio
              label=""
              value={checkPrimary}
              onChange={() => handleChangeDefault(address)}
              disabled={checkPrimary}
            />
            <Text size="medium" weight="semibold" color="darkGrey">
              {checkPrimary ? 'Primary Address' : 'Make this Address primary'}
            </Text>
          </div>

          <Wrapper>
            <Text size="small" weight="semibold">
              {address.type}
            </Text>
            <Text color="darkGrey" size="tiny">
              {address.address_line_1}
            </Text>
            <Text color="darkGrey" size="tiny">
              {address.city}, {address.state}, {address.post_code}
            </Text>
            <Text color="darkGrey" size="tiny">
              {address.country}
            </Text>
          </Wrapper>
        </div>
      </div>
      <div className="media-right">
        <FlexColumns justify="space-between">
          <Button
            name="Edit"
            width={2}
            textProps={{ size: 'small', color: 'primaryColor' }}
            onClick={handleEditAddress}
          />
          <br />
          <br />
          {!checkPrimary && (
            <Mutation
              client={client.clientPrivate}
              mutation={deleteAddressMutation}
              update={() => {
                onHandleDelete(address.address_id);
              }}
              onCompleted={({ delete_user_address }) => {
                if (!isNull(delete_user_address) && !isNull(delete_user_address.error)) {
                  delete_user_address.error.map(item => toast.error(item.description));
                } else if (isNull(delete_user_address)) {
                  toast.error('Something went wrong in backned!');
                } else {
                  toast.success('Address had been deleted successfully!');
                }
              }}
            >
              {delete_user_address => (
                <Button
                  name="Delete"
                  width={1.5}
                  textProps={{ size: 'small', color: 'danger' }}
                  borderHoverColor="danger"
                  onClick={() => {
                    swal('Are you sure delete this Address ?', {
                      dangerMode: true,
                      buttons: true,
                    }).then(willDelete => {
                      if (willDelete) {
                        delete_user_address({
                          variables: { input: { user_id: userId, address_id: address.address_id } },
                        });
                      }
                    });
                  }}
                />
              )}
            </Mutation>
          )}
        </FlexColumns>
      </div>
    </Container>
  );
};

export default Address;
