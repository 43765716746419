import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

const TextAreaStyle = styled(TextareaAutosize)`
  &&& {
    width: 100%;
    border: 2px solid
      ${({ theme: { colors }, borderLess }) => (borderLess ? 'transparent' : colors.lightGreyBeta)};
    :focus {
      border: 2px solid
        ${({ theme: { colors }, borderLess }) =>
          borderLess ? 'transparent' : colors.lightGreyBeta};
      ${({ borderLess }) =>
        borderLess && 'box-shadow: 0 0 0 0em rgba(44, 86, 151, 0.25) !important;'};
    }
  }
`;

const TextArea = ({ borderLess, ...props }) => (
  <TextAreaStyle className="textarea" borderLess={borderLess} {...props} />
);

export default TextArea;
