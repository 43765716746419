import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isNull, kebabCase, upperCase } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Text } from '../../../components/elements';

import { getTicketUrlPdf } from '../../../utils/s3';

const PlaceContainer = styled.article`
  padding: 1rem;
  justify-content: center;
  align-items: flex-end;
  border: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const Event = ({ bookingId, orderNumber, orderDate, bookingUrl, event }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  console.log('bookingUrl', bookingUrl);
  const getTicket = async () => {
    setLoading(true);
    const link = await getTicketUrlPdf(bookingUrl);
    setPdfUrl(link);
    setLoading(false);
  };

  useEffect(() => {
    getTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isNull(event)) {
    return '';
  }

  return (
    <PlaceContainer className="media">
      <div className="media-content">
        <div>
          <Text size="tiny" weight="semibold">
            {upperCase(moment(event.start).format('LLLL'))}
          </Text>
          <Link to={`/event/${event.event_id}`}>
            <Text size="small" weight="semibold">
              {event.name}
            </Text>
          </Link>

          <Text color="darkGrey" size="tiny">
            {event.place_name}
          </Text>
          <Text color="darkGrey" size="tiny">
            {event.city}, {event.state}, {event.country} {event.post_code}
          </Text>
          <Text color="darkGrey" size="tiny">
            Order Date :{' '}
            {moment(orderDate)
              .format('LLLL')
              .toUpperCase()}
          </Text>
          <Text color="darkGrey" size="tiny">
            Order Number : {orderNumber}
          </Text>
        </div>
      </div>
      <div className="media-right">
        <a href={pdfUrl} download={`${kebabCase(event.name)}-ticket.pdf`} disabled={loading}>
          <Text color="primaryColor" size="small" weight="semibold">
            Download Ticket(s)
          </Text>
        </a>
      </div>
    </PlaceContainer>
  );
};

export default Event;
