import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import uuid from 'uuid';
import { forEach, first } from 'lodash';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import qs from 'querystringify';

import MobileView from './MobileView';
import DesktopView from './DesktopView';
import Layout from '../../components/global/MenuLayout';
import Analytics from '../../components/global/Analytics';
import ErrorMessage from '../../components/global/ErrorMessage';
import Loading from '../../components/global/mobile/MobileLoading';
import client from '../../utils/apolloClient';

const placeReviewQuery = gql`
  query itemReview($placeId: String, $placeReviewId: String) {
    fetch_place_review(input: { place_id: $placeId, place_review_id: $placeReviewId }) {
      place_review_id
      place_id
      status
      approval_status
      content
      overall_rating
      month_visited
      service_category {
        name
        description
      }
    }
  }
`;

const placeReviewImageQuery = gql`
  query itemReview($placeId: String, $placeReviewId: String) {
    search_image_review(
      input: {
        filter: {
          image_review_filter: {
            object_id: $placeReviewId
            parent_id: $placeId
            object_type: "PLACE_REVIEW"
            parent_type: "PLACE"
            approval_status: "SUBMITTED"
          }
        }
      }
    ) {
      image_review_listing {
        image_id
        image_review_id
        url
        tags
        approval_status
        status
      }
    }
  }
`;

const fetchPlaceQuery = gql`
  query fetch_place($placeId: String) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      tagline
      cuisine_style {
        name
        description
      }
      business_type {
        name
        description
      }
      address_line_1
      address_line_2
      city
      state
      country
      post_code
    }
  }
`;

const placeReviewMutation = gql`
  mutation placeReview($input: PlaceReviewInput) {
    create_place_review(input: $input) {
      place_id
      error {
        description
      }
    }
  }
`;

const createImageMutation = gql`
  mutation createImage($input: ImageInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
  }
`;
const updatePlaceReviewMutation = gql`
  mutation updatePlaceReview($input: PlaceReviewInput) {
    update_place_review(input: $input) {
      place_id
      error {
        description
      }
    }
  }
`;

const parseImage = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        image_id: item.id,
        url: item.url,
        link: item.link,
        tags: item.tags,
        tooltip: item.tooltip,
        width: item.width,
        height: item.height,
      });
    }
  });
  return imageValues;
};

const titleMessage = 'Thanks for sharing your review.';
const textMessage = 'Your review will be posted shortly.';

const draftTitleMessage = 'Your review has been saved as a draft!.';
const draftTextMessage = 'Your draft can be reviewed, edited and posted from your profile. ';

const PlaceReview = ({ history, match, location }) => {
  const { userId } = useStoreState(state => state.auth);
  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [placeReview, setPlaceReview] = useState();
  const [isCreated, setIsCreated] = useState(true);
  const [currentReviewId, setCurrentReviewId] = useState();
  const [reviewFetchLoading, setReviewFetchLoading] = useState(false);
  const [draft, setDraft] = useState(false);
  const { placeId } = match.params;
  const params = qs.parse(location.search);

  useEffect(() => {
    if (params.reviewId) {
      setReviewFetchLoading(true);
      client.clientPublic
        .query({
          query: placeReviewQuery,
          variables: { placeId, placeReviewId: params.reviewId },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          if (data.fetch_place_review) {
            setPlaceReview(first(data.fetch_place_review));
            client.clientPrivate
              .query({
                query: placeReviewImageQuery,
                variables: { placeId, placeReviewId: params.reviewId },
                fetchPolicy: 'network-only',
              })
              .then(({ data: data2 }) => {
                if (data2.search_image_review) {
                  setUploadedImages(data2.search_image_review.image_review_listing);
                  setReviewFetchLoading(false);
                  setIsCreated(false);
                }
              })
              .catch(() => {
                setReviewFetchLoading(false);
              });
          }
        })
        .catch(() => {
          setReviewFetchLoading(false);
        });
    }
  }, [match.params.placeId, params.placeId, params.reviewId, placeId]);

  return (
    <Layout
      history={history}
      match={match}
      isEnableMobileSecondaryHeader
      secondaryHeaderHeading="WRITE A REVIEW"
      headerLikeModal
    >
      <Analytics pageName="Profile" category="profile" title="Profile-Page">
        <Query query={fetchPlaceQuery} variables={{ placeId }} fetchPolicy="network-only">
          {({ data, loading, error }) => {
            if (error) {
              return <ErrorMessage />;
            }

            if (loading || reviewFetchLoading) {
              return <Loading />;
            }

            const place = data.fetch_place;

            return (
              <Mutation
                client={client.clientPrivate}
                mutation={createImageMutation}
                onCompleted={() => {
                  swal(
                    draft ? draftTitleMessage : titleMessage,
                    draft ? draftTextMessage : textMessage,
                    'success',
                  ).then(() => {
                    history.goBack();
                  });
                }}
              >
                {(create_image, { loading: imageLoading }) => (
                  <Mutation
                    client={client.clientPrivate}
                    mutation={updatePlaceReviewMutation}
                    onCompleted={() => {
                      if (images.length !== 1) {
                        create_image({
                          variables: {
                            input: {
                              user_id: userId,
                              object_id: currentReviewId,
                              object_type: 'PLACE_REVIEW',
                              parent_id: placeId,
                              parent_type: 'PLACE',
                              upload_type: 'USER',
                              links_to: [
                                {
                                  id: placeId,
                                  type: 'PLACE',
                                },
                              ],
                              images: parseImage(images),
                            },
                          },
                        });
                      } else {
                        swal(
                          draft ? draftTitleMessage : titleMessage,
                          draft ? draftTextMessage : textMessage,
                          'success',
                        ).then(() => {
                          history.goBack();
                        });
                      }
                    }}
                  >
                    {(update_place_review, { loading: updateReviewLoading }) => (
                      <Mutation
                        client={client.clientPrivate}
                        mutation={placeReviewMutation}
                        onCompleted={() => {
                          if (images.length !== 1) {
                            create_image({
                              variables: {
                                input: {
                                  user_id: userId,
                                  object_id: currentReviewId,
                                  object_type: 'PLACE_REVIEW',
                                  parent_id: placeId,
                                  parent_type: 'PLACE',
                                  upload_type: 'USER',
                                  links_to: [
                                    {
                                      id: placeId,
                                      type: 'PLACE',
                                    },
                                  ],
                                  images: parseImage(images),
                                },
                              },
                            });
                          } else {
                            swal(
                              draft ? draftTitleMessage : titleMessage,
                              draft ? draftTextMessage : textMessage,
                              'success',
                            ).then(() => {
                              history.goBack();
                            });
                          }
                        }}
                      >
                        {(create_place_review, { loading: reviewLoading }) => (
                          <>
                            <div className="is-hidden-desktop">
                              <MobileView
                                place={place}
                                placeReview={placeReview}
                                isCreated={isCreated}
                                uploadedImages={uploadedImages}
                                reviewLoading={reviewLoading || imageLoading || updateReviewLoading}
                                onSubmit={values => {
                                  setDraft(values.isDraft);
                                  setImages(values.image);
                                  const input = {
                                    user_id: userId,
                                    place_review_id: isCreated
                                      ? uuid()
                                      : placeReview.place_review_id,
                                    place_id: placeId,
                                    content: values.content,
                                    overall_rating: values.overall_rating,
                                    month_visited: values.month_visited,
                                    service_category: values.service_category,
                                    draft: values.isDraft,
                                  };
                                  setCurrentReviewId(input.place_review_id);
                                  if (isCreated) {
                                    create_place_review({ variables: { input } });
                                  } else {
                                    update_place_review({
                                      variables: { input },
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="is-hidden-touch">
                              <DesktopView
                                place={place}
                                placeReview={placeReview}
                                isCreated={isCreated}
                                uploadedImages={uploadedImages}
                                reviewLoading={reviewLoading || imageLoading || updateReviewLoading}
                                onSubmit={values => {
                                  setDraft(values.isDraft);
                                  setImages(values.image);
                                  const input = {
                                    user_id: userId,
                                    place_review_id: isCreated
                                      ? uuid()
                                      : placeReview.place_review_id,
                                    place_id: placeId,
                                    content: values.content,
                                    overall_rating: values.overall_rating,
                                    month_visited: values.month_visited,
                                    service_category: values.service_category,
                                    draft: values.isDraft,
                                  };
                                  setCurrentReviewId(input.place_review_id);
                                  if (isCreated) {
                                    create_place_review({ variables: { input } });
                                  } else {
                                    update_place_review({
                                      variables: { input },
                                    });
                                  }
                                }}
                              />
                            </div>
                          </>
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                )}
              </Mutation>
            );
          }}
        </Query>
      </Analytics>
    </Layout>
  );
};

export default PlaceReview;
