import gql from 'graphql-tag';
import { forEach, groupBy, map } from 'lodash';
import uuid from 'uuid';

import client from '../../utils/apolloClient';

export const parseOrderDatabaseInputItems = items => {
  const data = [];
  forEach(items, item => {
    let selectedVariant = null;
    if (item.variant_id) {
      selectedVariant = {
        variant_id: item.variant_id,
        variant_name: item.variant_name,
        variant_price: item.price_per_item,
      };
    }

    const groupsTemp = [];
    const groups = groupBy(item.modifier, 'modifier_group_id');
    // console.log('parseOrderDatabaseInputItems', groups);
    map(groups, item2 => {
      let selectedVariant2 = null;
      if (item2.variant_id) {
        selectedVariant2 = {
          variant_id: item2.variant_id,
          variant_name: item2.variant_name,
          variant_price: item2.price_per_item,
        };
      }

      const modifiersTemp = [];
      forEach(item2, item3 => {
        modifiersTemp.push({
          menu_item: { menu_item_id: item3.menu_item_id, name: item3.name },
          override_price: item3.price,
          override_variant: [],
          selectedVariant: selectedVariant2,
          ...item3,
        });
      });

      const group = { modifier_group_id: item2[0].modifier_group_id, modifiers: modifiersTemp };
      groupsTemp.push(group);
    });

    const newItem = {
      id: uuid(),
      item_link_id: item.item_link_id,
      menu_item_id: item.menu_item_id,
      quantity: item.quantity,
      name: item.name,
      price_per_item: item.price_per_item,
      tax: item.tax,
      net_price_per_item: item.net_price_per_item,
      price: item.price,
      special_request: item.special_request,
      selectedVariant,
      groups: groupsTemp,
    };
    data.push(newItem);
  });
  return data;
};

const searchCartQuery = gql`
  query searchCart($input: SearchInput) {
    search_menu_orders(input: $input) {
      menu_order_listing {
        menu_order_id
        place_id
        service_type
        service_type_setting_id
        menu_id
        status
        order_reference
        service_type
        place {
          name
          address_line_1
          city
          state
        }
        include_cuttlery
        estimates {
          type
          value_in_mins
        }
        address {
          address_id
          type
          is_default
          contact_name
          contact_phone
          address_line_1
          address_line_2
          city
          state
          country
          post_code
          latitude
          longitude
          status
        }
        audit {
          created_at
          updated_at
          updated_by
        }
        note {
          value
        }
        billing {
          sub_total
          delivery_fee
          order_total
          order_amount
          tax {
            type
            value
          }
        }
        line_item {
          name
          item_link_id
          menu_item_id
          prompt_id
          variant_id
          variant_name
          name
          quantity
          price_per_item
          net_price_per_item
          tax_per_item
          price
          tax
          modifier {
            name
            modifier_group_id
            menu_item_id
            variant_id
            variant_name
            name
            quantity
            price_per_item
            net_price_per_item
            tax_per_item
            price
            tax
          }
          menu_item_rating
          special_request
          user_id
          caption
          private
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getCart = ({ size = 100, filter }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchCartQuery,
        variables: {
          input: {
            size,
            filter,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          data.search_menu_orders.menu_order_listing &&
          data.search_menu_orders.menu_order_listing.length !== 0
        ) {
          resolve(data.search_menu_orders.menu_order_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
