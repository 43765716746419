import React, { useState, useEffect } from 'react';
import { FieldArray } from 'formik';
import styled from 'styled-components';
import { MdKeyboardArrowUp, MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { forEach } from 'lodash';

import { Text, FlexRows, CheckBox, Radio } from '../../../../../components/elements';
import colors from '../../../../../theme/styles/colors';
import { calculateItemPrice } from '../../../helpers';

const Container = styled.div`
  && {
    padding-bottom: 1rem;
    border-bottom: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
    margin-bottom: 1.5rem;
  }
`;

const Top = styled(FlexRows)``;

const Modifier = styled.div`
  .radio-btn {
    align-items: baseline;
  }
`;

const Wrapper = styled.div``;

const CheckBoxWrapper = styled(FlexRows)`
  && {
    padding-bottom: 0.7rem;
    i {
      font-size: 1.2rem;
    }
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

const SubModifier = styled.div`
  border-radius: 4px;
  padding: 0.5rem;
  border: 2px solid ${({ theme: { colors: themeColor } }) => themeColor.borderColor};
  width: 70%;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const ValidationMessage = ({
  isNotRequired,
  selection_type,
  selection_quantity,
  selection_quantity_from,
  selection_quantity_to,
}) => {
  if (selection_type === 'CUSTOM') {
    if (selection_quantity === 1) {
      return (
        <Text
          size="smaller"
          color={isNotRequired ? 'coolGrey' : 'secondaryColor'}
          paddingBottom="1"
        >
          Please choose any one option ( {!isNotRequired ? 'Required' : 'Optional'} )
        </Text>
      );
    }

    if (!isNotRequired) {
      return (
        <Text
          size="smaller"
          color={isNotRequired ? 'coolGrey' : 'secondaryColor'}
          paddingBottom="1"
        >
          Please choose any {selection_quantity} options ({' '}
          {!isNotRequired ? 'Required' : 'Optional'} )
        </Text>
      );
    }
    return (
      <Text size="smaller" color={isNotRequired ? 'coolGrey' : 'secondaryColor'} paddingBottom="1">
        Please choose up to {selection_quantity} options ({' '}
        {!isNotRequired ? 'Required' : 'Optional'} )
      </Text>
    );
  }

  return (
    <Text size="smaller" color={isNotRequired ? 'coolGrey' : 'secondaryColor'} paddingBottom="1">
      Please choose between {selection_quantity_from} and {selection_quantity_to} options ({' '}
      {!isNotRequired ? 'Required' : 'Optional'} )
    </Text>
  );
};

const handleRadioButtonSelection = (modifiers, newSelectedModId) => {
  const data = [];
  forEach(modifiers, item => {
    if (newSelectedModId === item.menu_item_id) {
      const temp = { ...item, isSelected: true };
      Object.assign(
        temp,
        item.variant && item.variant.length !== 0 && { selectedVariant: item.variant },
      );
      data.push(temp);
    } else {
      const temp = { ...item, isSelected: false };
      data.push(temp);
    }
  });

  return data;
};

const CheckBoxSelection = ({
  notAvailable,
  group,
  modifier,
  setFieldValue,
  parentIndex,
  index,
}) => {
  const [disabled, setDisabled] = useState(notAvailable);
  useEffect(() => {
    const filter = group.modifier.filter(item => item.isSelected === true);
    if (group.selection_type === 'RANGE') {
      if (!modifier.isSelected && filter.length === group.selection_quantity_to) {
        setDisabled(true);
      } else if (!modifier.isSelected) {
        setDisabled(false);
      }
    } else if (!modifier.isSelected && filter.length === group.selection_quantity) {
      setDisabled(true);
    } else if (!modifier.isSelected) {
      setDisabled(false);
    }
  }, [
    group.modifier,
    group.selection_quantity,
    group.selection_quantity_to,
    group.selection_type,
    modifier.isSelected,
  ]);

  if (group.selection_type === 'CUSTOM' && group.selection_quantity === 1) {
    return (
      <Radio
        label={modifier.name}
        value={modifier.isSelected}
        labelTextProps={{
          size: 'small',
          color: notAvailable ? 'waterMarkGrey' : 'darkGrey',
          weight: 'regular',
        }}
        onChange={value => {
          if (value) {
            setFieldValue(`groups.${parentIndex}.modifier.${index}.isSelected`, true);
            const final = handleRadioButtonSelection(group.modifier, modifier.menu_item_id);
            setFieldValue(`groups.${parentIndex}.modifier`, final);
          }
        }}
        disabled={notAvailable}
      />
    );
  }

  return (
    <CheckBox
      label={modifier.name}
      initialValue={modifier.isSelected}
      labelTextProps={{
        size: 'small',
        color: disabled ? 'waterMarkGrey' : 'darkGrey',
        weight: 'regular',
      }}
      onChange={value => {
        if (value) {
          setFieldValue(`groups.${parentIndex}.modifier.${index}.isSelected`, true);
          if (modifier.variant && modifier.variant.length !== 0) {
            setFieldValue(
              `groups.${parentIndex}.modifier.${index}.selectedVariant`,
              modifier.variant,
            );
          }
        } else {
          setFieldValue(`groups.${parentIndex}.modifier.${index}.isSelected`, false);
        }
      }}
      disabled={notAvailable || disabled}
    />
  );
};

const Modifiers = ({ isAvailable, parentIndex, group, setFieldValue }) => {
  const [isActive, setIsActive] = useState(true);
  return (
    <Container>
      <Top justify="space-between">
        <div>
          <Text size="medium">{group.name}</Text>
          <ValidationMessage
            isNotRequired={!group.is_required}
            selection_type={group.selection_type}
            selection_quantity={group.selection_quantity}
            selection_quantity_from={group.selection_quantity_from}
            selection_quantity_to={group.selection_quantity_to}
          />
        </div>
        {!isActive ? (
          <MdKeyboardArrowUp
            size={30}
            color={colors.coolGrey}
            onClick={() => setIsActive(!isActive)}
            style={{ cursor: 'pointer', marginRight: '-5px' }}
          />
        ) : (
          <MdKeyboardArrowDown
            size={30}
            color={colors.coolGrey}
            onClick={() => setIsActive(!isActive)}
            style={{ cursor: 'pointer', marginRight: '-5px' }}
          />
        )}
      </Top>
      {isActive && (
        <FieldArray
          name={`groups.${parentIndex}.modifier`}
          render={() => (
            <Modifier>
              {group.modifier.map((modifier, index) => {
                const notAvailable =
                  modifier.availability_status === 'UNAVAILABLE' ||
                  modifier.un_available_dates !== null;
                const variant =
                  modifier.variant && modifier.variant.length !== 0 && modifier.variant[0];
                const selectedSubVariant =
                  modifier.selectedVariant &&
                  modifier.selectedVariant[0].variants.filter(item => item.isSelected);
                return (
                  <Wrapper key={modifier.menu_item_id}>
                    <CheckBoxWrapper justify="space-between">
                      <div className="is-flex radio-btn">
                        <CheckBoxSelection
                          group={group}
                          modifier={modifier}
                          setFieldValue={setFieldValue}
                          parentIndex={parentIndex}
                          index={index}
                          notAvailable={!isAvailable || notAvailable}
                        />
                      </div>
                      {notAvailable ? (
                        <Text size="smedium" weight="semibold" color="waterMarkGrey">
                          {modifier.un_available_dates ? modifier.un_available_dates : 'Sold out'}
                        </Text>
                      ) : (
                        <Text size="smedium" weight="regular" color="darkGrey">
                          {calculateItemPrice(modifier.variant, modifier.price)}
                        </Text>
                      )}
                    </CheckBoxWrapper>
                    {variant &&
                      modifier.isSelected &&
                      modifier.selectedVariant &&
                      modifier.selectedVariant.length !== 0 && (
                        <SubModifier
                          onClick={() => {
                            setFieldValue('isActiveModifierLevelVariantForStep2Value', {
                              parentIndex,
                              index,
                              selectedVariant: modifier.selectedVariant,
                            });
                            setFieldValue('isActiveModifierLevelVariantForStep2', true);
                            setFieldValue(
                              'isActiveModifierLevelVariantForStep2HeaderValue',
                              modifier.name,
                            );
                          }}
                        >
                          <FlexRows justify="space-between">
                            <Text size="small">{variant.prompt}</Text>
                            <MdKeyboardArrowRight
                              size={30}
                              onClick={() => setIsActive(!isActive)}
                              style={{ cursor: 'pointer' }}
                            />
                          </FlexRows>
                          <Text size="tiny" color="secondaryColor">
                            {selectedSubVariant && selectedSubVariant.length !== 0 ? (
                              <>
                                {selectedSubVariant[0].variant_name} ($
                                {parseFloat(selectedSubVariant[0].variant_price).toFixed(2)})
                              </>
                            ) : (
                              <>( Required )</>
                            )}
                          </Text>
                        </SubModifier>
                      )}
                  </Wrapper>
                );
              })}
            </Modifier>
          )}
        />
      )}
    </Container>
  );
};

export default Modifiers;
