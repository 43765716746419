import React from 'react';
import styled from 'styled-components';
import { first } from 'lodash';

import { Text, FlexRows, Avatar } from '../../components/elements';
import FollowButtonAction from '../../components/FollowButtonAction';

const UserContainer = styled(FlexRows)`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;

const DeskUser = ({ user, itemReview, placeReview, follow }) => {
  const totalItemReviewCount = itemReview.length !== 0 ? first(itemReview).count : 0;
  const totalPlaceReviewCount = placeReview.length !== 0 ? first(placeReview).count : 0;
  const totalFollowCount = follow.length !== 0 ? first(follow).count : 0;
  const totalReviewCount = totalItemReviewCount + totalPlaceReviewCount;

  return (
    <UserContainer justify="space-between">
      <div>
        <FlexRows>
          <Avatar
            name={user.display_name}
            color={user.color_code}
            image={user.profile_image_url}
            maskProps={{ width: 50, height: 50 }}
            maskTextProps={{
              color: 'white',
              weight: 'bold',
              size: 'medium',
              letterSpacing: 'loose',
            }}
            borderRadius={32}
          />
          &nbsp; &nbsp; &nbsp;
          <div>
            <Text size="small" weight="semibold">
              {user.display_name}
            </Text>
            <Text color="darkGrey" size="tiny">
              {totalFollowCount} Followers, {totalReviewCount} Reviews
            </Text>
          </div>
        </FlexRows>
      </div>
      <div>
        <FollowButtonAction
          objectId={user.user_id}
          objectType="USER"
          defaultIcon="follow"
          activeIcon="following"
          isIconButton
          onlyIcon
          iconStyle={{ size: 3 }}
        />
      </div>
    </UserContainer>
  );
};

export default DeskUser;
