import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { truncate } from 'lodash';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';

import { Text } from '../../../../components/elements';
import DealModal from './DealModal';

const Container = styled.div`
  /* padding: 1rem; */
  &&& {
    background-color: ${({ theme: { colors: themeColors } }) => themeColors.white};
    margin-bottom: 0.5rem;

    .is-full {
      width: 22rem;
      height: 100%;
    }
    .card-wrapper {
      display: block;
      border: 2px solid ${({ theme: { colors: themeColors } }) => themeColors.borderColor};
      padding-bottom: 1rem;
      cursor: pointer;
      @media (min-width: 1024px) and (max-width: 1070px) {
        height: 11rem;
      }
      :hover {
        border: 1px solid ${({ theme: { colors: themeColors } }) => themeColors.primaryColor};
      }
    }
    .card {
      display: flex;
      box-shadow: none !important;
    }
    .icon {
      justify-content: flex-start;
    }
    .media {
      position: relative;
    }
  }
`;

const Left = styled.div`
  && {
    padding: 1rem;
    padding-top: 1rem;
    .item-info {
      margin-top: -12px;
    }
  }
`;

const DealItem = ({ isCustomService, size, deal, placeLocation }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const time_zone = tzLookup(
    parseFloat(placeLocation.latitude),
    parseFloat(placeLocation.longitude),
  );
  return (
    <>
      <DealModal
        placeLocation={placeLocation}
        deal={deal}
        isActive={isActiveModal}
        onClose={() => setIsActiveModal(false)}
      />
      <Container
        className={`column ${isCustomService || size ? 'is-one-third' : 'is-half'}`}
        onClick={() => setIsActiveModal(true)}
      >
        <div className="card-wrapper">
          <div className="is-flex">
            <Left className="">
              <Text
                color="darkYellow"
                weight="semibold"
                size="smedium"
                className="deal-title"
                paddingBottom="0.5"
              >
                {deal.name}
              </Text>
              <Text weight="regular" size="smaller" paddingBottom="0.5">
                {truncate(
                  // eslint-disable-next-line max-len
                  deal.description,
                  {
                    length: 70,
                    separator: ' ',
                  },
                )}
              </Text>
              <Text size="small" weight="regular" color="darkGrey">
                Offer valid till{' '}
                {moment(deal.end)
                  .tz(time_zone)
                  .format('MMM DD, YYYY hh:mm:A')}
              </Text>
            </Left>
          </div>
        </div>
      </Container>
    </>
  );
};

export default withRouter(DealItem);
