import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from './Flex';

const Container = styled(Flex)`
  &&& {
    flex-direction: column;
  }
`;

const FlexColumns = ({ children, ...props }) => <Container {...props}>{children}</Container>;

FlexColumns.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FlexColumns;
