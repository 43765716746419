import React from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';

import MobileForm from './MobileForm';

const Container = styled.div``;

const MobileView = ({ children, onSubmit, loading }) => {
  const setIsActiveModal = useStoreActions(state => state.auth.setIsActiveDesktopLoginModal);
  const { isLoggedIn } = useStoreState(state => state.auth);
  return (
    <>
      <Container>
        <MobileForm
          onSubmit={onSubmit}
          loading={loading}
          setIsActiveModal={setIsActiveModal}
          isLoggedIn={isLoggedIn}
        />
      </Container>
      {children}
    </>
  );
};

export default MobileView;
