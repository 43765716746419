import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from '../../elements';

const Field = styled.div`
  position: relative;
  width: 100%;
  input {
    font-size: 15px;
    width: 100%;
    padding: 10px 38px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    background: #ffffff;
    outline: none;
    ${({ inputIsCapitalized }) => inputIsCapitalized && 'text-transform: capitalize;'}
  }
  input:focus {
    /* border-left: 2px solid #00aeef !important;
    border-right: 2px solid #00aeef !important;
    border-top: 2px solid #00aeef !important; */
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px; */
    border-bottom-right-radius: ${({ isEnable }) => (isEnable ? '0px' : '5px')};
    border-bottom-left-radius: ${({ isEnable }) => (isEnable ? '0px' : '5px')};
    background: #ffffff;
  }
  span {
    position: absolute;
    top: 9px;
    left: 4px;
  }
`;

const Popup = styled.div`
  position: absolute;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px; */
  top: 41px;
  max-height: 25rem;
  overflow-y: scroll;
  width: 100%;
  background: #fafafa !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: 201;
  border-left: 2px solid #fafafa !important;
  border-right: 2px solid #fafafa !important;
  border-bottom: 2px solid #fafafa !important;
  background: #f0f0f0;
`;

const AutoSuggest = ({
  value,
  onChange,
  onBlur = () => {},
  suggestions,
  renderSuggestion,
  onSelectItem = () => {},
  handleQuerySearch = () => {},
  icon,
  placeholder,
  inputIsCapitalized = false,
  whichElementOpenOnEnter = 0,
}) => {
  const [enableSuggestion, setEnableSuggestion] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const containerRef = useRef();
  const inputRef = useRef();

  const onKeyDown = e => {
    // enter key
    if (e.keyCode === 13) {
      if (suggestions.length !== whichElementOpenOnEnter) {
        onSelectItem(suggestions[whichElementOpenOnEnter]);
        setActiveSuggestion(activeSuggestion);
        setEnableSuggestion(false);
        if (inputRef.current) {
          inputRef.current.blur();
        }
      } else {
        handleQuerySearch();
      }
    }
    //  up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        setActiveSuggestion(suggestions.length - 1);
      } else {
        setActiveSuggestion(activeSuggestion - 1);
      }
    }
    //  down arrow
    else if (e.keyCode === 40) {
      if (suggestions.length === activeSuggestion) {
        setActiveSuggestion(0);
      } else {
        setActiveSuggestion(activeSuggestion + 1);
      }
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setEnableSuggestion(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <Field
      ref={containerRef}
      isEnable={suggestions.length !== 0}
      inputIsCapitalized={inputIsCapitalized}
    >
      <span>{icon || <Icon name="searchBlack" size={2} />}</span>
      <input
        ref={inputRef}
        value={value}
        placeholder={placeholder}
        onFocus={event => {
          setEnableSuggestion(true);
          event.target.select();
        }}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      {enableSuggestion && suggestions.length !== 0 && (
        <Popup>
          {suggestions.map((item, index) =>
            renderSuggestion(item, activeSuggestion === index, () => {
              setActiveSuggestion(index);
              onSelectItem(suggestions[index]);
              setEnableSuggestion(false);
            }),
          )}
        </Popup>
      )}
    </Field>
  );
};

export default AutoSuggest;
