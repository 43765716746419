import React from 'react';
import styled from 'styled-components';
import { Text, FlexColumns, FlexRows } from '../../components/elements';

const Container = styled(FlexColumns)`
  padding: 3rem 1rem;
  background-color: ${({ theme: { colors } }) => colors.lightGreyAlpha};
`;

const AppStorePromo = () => (
  <Container>
    <Text size="large" color="secondaryColor" weight="semibold">
      iOS and Android App
    </Text>
    <Text size="micro" lineHeight="normal" align="center" paddingBottom={1}>
      Find world-class dishes, restaurant and events at your fingertips and easily spot, snap and
      share dishes on the go with our mobile app
    </Text>
    <FlexRows justify="space-around">
      <div>
        <img src="/images/apple.png" alt="apple-store" />
      </div>
      &nbsp;
      <div>
        <img src="/images/google.png" alt="play-store" />
      </div>
    </FlexRows>
  </Container>
);

export default AppStorePromo;
