import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { dropRight, capitalize, uniqBy } from 'lodash';
import { withRouter } from 'react-router-dom';
import { GoSearch } from 'react-icons/go';

import { Text, Icon, FlexRows } from '../../elements';
import AutoSuggest from './AutoSuggest';

import client from '../../../utils/apolloClient';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  cursor: pointer;
  && {
    .row p {
      padding-left: 1rem;
    }
  }
`;

const searchQuery = gql`
  query search(
    $search_criteria: String!
    # $location: String
    # $distanceRadius: String
    $eventFilter: EventFilter
  ) {
    search_places(
      input: {
        # location: $location
        filter: {
          place_filter: {
            place_name: $search_criteria
            listing_status: "APPROVED"
            event_business: false
            status: "ACTIVE"
            # distance: $distanceRadius
          }
        }
      }
    ) {
      place_listing {
        place_id
        name
        slug
        address_line_1
        city
        state
        post_code
        country
        service_type {
          name
        }
      }
    }
    search_items(
      input: {
        # location: $location
        filter: {
          item_filter: {
            name: $search_criteria
            status: "ACTIVE"
            approval_status: "APPROVED"
            # distance: $distanceRadius
          }
          place_filter: { status: "ACTIVE" }
        }
      }
    ) {
      item_listing {
        item_id
        name
        place_name
        address_line_1
        city
        state
        post_code
        country
      }
    }
    search_events(
      input: {
        # location: $location
        filter: { event_filter: $eventFilter, place_filter: { status: "ACTIVE" } }
      }
    ) {
      event_listing {
        event_id
        name
        address_line_1
        city
        state
        post_code
        country
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 0.8rem 1rem;
  ${({ activeIndex }) => activeIndex && 'background: #f0f0f0;'};
  :hover {
    background: #f0f0f0;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-left: 1rem;
`;

const Suggestions = ({ item, activeIndex, onClick, history }) => {
  const serviceType =
    item.service_type && item.service_type.length !== 0 ? uniqBy(item.service_type, 'name') : [];
  const parentHandleClick = () => {
    onClick();
    if (item.place_id) {
      history.push(`/place/${item.place_id}`);
    }
    if (item.item_id) {
      history.push(`/item/${item.item_id}`);
    }
    if (item.event_id) {
      history.push(`/event/${item.event_id}`);
    }
    if (item.showAllResult) {
      history.push(item.path);
    }
  };
  if (item.showAllResult) {
    return (
      <Row activeIndex={activeIndex}>
        <div onClick={parentHandleClick}>
          <Text color="primaryColor" size="small" weight="semibold">
            {item.title}
          </Text>
        </div>
      </Row>
    );
  }

  return (
    <Row className="row" activeIndex={activeIndex}>
      <div onClick={parentHandleClick}>
        {item.place_id && <Icon name="headerPlaceAroundMe" size={1.6} />}
        {item.item_id && <Icon name="headerFindItem" size={1.6} />}
        {item.event_id && <Icon name="headerDiscoverEvent" size={1.6} />}
      </div>

      <TextWrapper>
        <div onClick={parentHandleClick}>
          <Text className="is-capitalized" color="darkGrey" size="small" weight="semibold">
            {capitalize(item.name)}
          </Text>
          {item.item_id && (
            <Text className="is-capitalized" color="darkGrey" size="micro">
              {capitalize(item.place_name)}
            </Text>
          )}
          <Text color="darkGrey" size="micro">
            {item.address_line_1}, {item.city}, {item.state}
          </Text>
        </div>

        {serviceType.length !== 0 && (
          <FlexRows justify="flex-start">
            {serviceType.map(item2 => (
              <a onClick={() => history.push(`/order-food/${item2.name}/${item.place_id}`)}>
                <Text color="secondaryColor" weight="semibold" size="tiny">
                  {item2.name}
                </Text>
              </a>
            ))}
          </FlexRows>
        )}
      </TextWrapper>
    </Row>
  );
};

const AddPlace = ({ active, onClick, history }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setQuery('');
  }, []);

  const onSuggestionsFetchRequested = value => {
    const event_filter = {};

    Object.assign(event_filter, {
      event_name: value,
      status: 'ACTIVE',
      draft: false,
    });

    client.clientPublic
      .query({
        query: searchQuery,
        variables: {
          search_criteria: value,
          eventFilter: event_filter,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        let finalSuggestion = [];
        if (data.search_places.length !== 0) {
          finalSuggestion = finalSuggestion.concat(data.search_places.place_listing);
        }
        if (data.search_items.length !== 0) {
          finalSuggestion = finalSuggestion.concat(data.search_items.item_listing);
        }
        if (data.search_events.length !== 0) {
          finalSuggestion = finalSuggestion.concat(data.search_events.event_listing);
        }

        const finalCustomSuggestion = dropRight(
          finalSuggestion,
          finalSuggestion ? finalSuggestion.length - 5 : 0,
        );

        const finalCustom = [
          {
            showAllResult: true,
            title: `Show all results for "${value}"`,
            path: `/global-search?query=${value}`,
          },
        ];

        setSuggestions(
          finalSuggestion.length === 0
            ? finalSuggestion
            : finalCustom.concat(finalCustomSuggestion),
        );
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const fetchData = event => {
    setQuery(event.target.value);
    onSuggestionsFetchRequested(event.target.value);
  };

  return (
    <a onClick={onClick}>
      <Container>
        {active ? (
          <AutoSuggest
            whichElementOpenOnEnter={1}
            placeholder="Search any place, food, cuisine, item or event.."
            icon={<GoSearch color="#aeaeae" size={25} />}
            value={query}
            suggestions={suggestions}
            onChange={fetchData}
            handleQuerySearch={() => history.push(`/global-search?query=${query}`)}
            onSelectItem={item => {
              if (item.place_id) {
                history.push(`/place/${item.place_id}`);
              }
              if (item.item_id) {
                history.push(`/item/${item.item_id}`);
              }
              if (item.event_id) {
                history.push(`/event/${item.event_id}`);
              }
              if (item.showAllResult) {
                history.push(item.path);
              }
            }}
            renderSuggestion={(item, activeIndex, onSelectItem) => (
              <Suggestions
                item={item}
                activeIndex={activeIndex}
                onClick={onSelectItem}
                history={history}
              />
            )}
          />
        ) : (
          <>
            <GoSearch color="#ffffff" size={25} />
            <Text color="white" size="small" weight="semibold">
              Search
            </Text>
          </>
        )}
      </Container>
    </a>
  );
};

export default withRouter(AddPlace);
