import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { trim } from 'lodash';

import { Flex, Text, Field, MobileInput } from '../../components/elements';
import MobileTagInput from './MobileTagInput';
import MobilePlaceHours from './MobilePlaceHours';
import MobileSearchLocationModal from '../../components/global/mobile/MobileSearchLocationModal';
import {
  getPlaceTypes,
  getCuisines,
  getFeatures,
  getServiceCategory,
  getGoodFor,
} from '../../utils/fetchStatic';
import MobileImages from './MobileImages';

const Container = styled.div`
  &&& {
    padding: 1rem;
    padding-bottom: 3rem;
    .react-tel-input {
      input {
        border: 0;
        border-bottom: 1px solid #a8a8a8;
        border-radius: 0;
        :focus {
          box-shadow: 0;
        }
      }

      .flag-dropdown {
        border: 0;
        background-color: transparent;
      }
    }
  }
`;
const FieldWrapper = styled.div`
  padding: 1rem 0.5rem;
`;

const MobileFieldStyle = styled(MobileInput).attrs({
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    color: 'primaryColor',
    weight: 'bold',
    size: 'small',
    letterSpacing: 'loose',
  },
})``;
const FieldStyle = styled(Field).attrs({
  lite: true,
  borderBottomColor: 'specialGrey',
  labelTextProps: {
    className: 'is-uppercase',
    size: 'small',
    color: 'primaryColor',
    weight: 'bold',
    letterSpacing: 'loose',
  },
})``;

const FooterButton = styled.button`
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.8rem 1rem;
  position: fixed;
  bottom: 0;
  border-radius: 0;
  border-color: transparent;
`;

const MobileForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    loading,
    setFieldValue,
  } = props;
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [data, setData] = useState([
    { businessTypes: [], cuisines: [], serviceTypes: [], featureTypes: [], goodFor: [] },
  ]);

  useEffect(() => {
    const getData = async () => {
      const businessTypes = await getPlaceTypes();
      const serviceTypes = await getServiceCategory();
      const featureTypes = await getFeatures();
      const cuisines = await getCuisines();
      const goodFor = await getGoodFor();

      setData({
        businessTypes,
        serviceTypes,
        featureTypes,
        cuisines,
        goodFor,
      });
    };
    getData();
  }, []);
  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <MobileSearchLocationModal
        isActive={isActiveModal}
        onClose={() => setIsActiveModal(false)}
        onSelect={place => {
          setFieldValue('streetAddressLine1', trim(place.addressLine1));
          setFieldValue('city', place.city);
          setFieldValue('postcode', place.postcode);
          setFieldValue('state', place.state);
          setFieldValue('country', place.country || 'AUSTRALIA');
          setFieldValue('latitude', place.latitude);
          setFieldValue('longitude', place.longitude);
          setFieldValue('fullAddress', `${place.addressLine1} ${place.city} ${place.state}`);
        }}
      />
      <Container>
        <FieldWrapper>
          <FieldStyle
            name="name"
            label="Place Name"
            placeholder="Cambrell curries house"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.name && touched.name && <div className="has-text-danger">{errors.name}</div>}
        </FieldWrapper>
        <FieldWrapper>
          <FieldStyle
            label="Address"
            placeholder="45 Ocean Street New South Wells"
            value={values.fullAddress}
            onFocus={() => setIsActiveModal(true)}
            required
          />
          {errors.fullAddress && touched.fullAddress && (
            <div className="has-text-danger">{errors.fullAddress}</div>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <MobileFieldStyle
            label="Phone Number"
            placeholder="+41000866866868"
            name="mobile"
            onChange={value => setFieldValue('mobile', value)}
            value={values.mobile}
          />
          {errors.mobile && touched.mobile && (
            <div className="has-text-danger">{errors.mobile}</div>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <FieldStyle
            name="email"
            label=" Email"
            placeholder="john@gmail.com"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email && <div className="has-text-danger">{errors.email}</div>}
        </FieldWrapper>
        <FieldWrapper>
          <FieldStyle
            name="relationship"
            label=" Relation to the place"
            placeholder="Manger, Owner, etc"
            value={values.relationship}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.relationship && touched.relationship && (
            <div className="has-text-danger">{errors.relationship}</div>
          )}
        </FieldWrapper>
        <MobilePlaceHours {...props} />
        <FieldWrapper>
          <MobileTagInput
            label="What is this place good for"
            options={data.goodFor}
            onChange={value => setFieldValue('good_for', value)}
          />
          {errors.good_for && touched.good_for && (
            <div className="has-text-danger">{errors.good_for}</div>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <MobileTagInput
            label="Business Type"
            optional="Select the business type that describe the focus of the business (upto 2)"
            options={data.businessTypes}
            onChange={value => setFieldValue('business_types', value)}
            required
          />
          {errors.business_types && touched.business_types && (
            <div className="has-text-danger">{errors.business_types}</div>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <MobileTagInput
            label="Cuisines"
            optional="Select the cuisines offered of this place"
            options={data.cuisines}
            onChange={value => setFieldValue('cuisine_style', value)}
            required
          />
          {errors.cuisine_style && touched.cuisine_style && (
            <div className="has-text-danger">{errors.cuisine_style}</div>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <MobileTagInput
            label="Features"
            optional="Select the feature of this place"
            options={data.featureTypes}
            onChange={value => setFieldValue('feature_types', value)}
          />
          {errors.feature_types && touched.feature_types && (
            <div className="has-text-danger">{errors.feature_types}</div>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <MobileTagInput
            label="Services"
            optional="Select the services at this place"
            options={data.serviceTypes}
            onChange={value => setFieldValue('service_category', value)}
            required
          />
          {errors.service_category && touched.service_category && (
            <div className="has-text-danger">{errors.service_category}</div>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <FieldStyle
            label="Place Website"
            name="website"
            value={values.website}
            placeholder="www.business.com"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.website && touched.website && (
            <div className="has-text-danger">{errors.website}</div>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <FieldStyle
            label="Facebook Page"
            placeholder="www.facebook.com"
            name="facebook"
            value={values.facebook}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.facebook && touched.facebook && (
            <div className="has-text-danger">{errors.facebook}</div>
          )}
        </FieldWrapper>

        <FieldWrapper>
          <MobileImages values={values} setFieldValue={setFieldValue} />
        </FieldWrapper>
      </Container>

      <FooterButton type="submit">
        <Flex>
          <Text
            className="is-uppercase"
            color="white"
            align="center"
            weight="bold"
            size="medium"
            letterSpacing="loose"
          >
            {loading ? 'Loading...' : 'Submit'}
          </Text>
        </Flex>
      </FooterButton>
    </form>
  );
};

const MyEnhancedForm = withFormik({
  mapPropsToValues: () => ({
    name: '',
    relationship: '',
    streetAddressLine1: '',
    fullAddress: '',
    city: '',
    postcode: '',
    state: '',
    country: '',
    latitude: '',
    longitude: '',
    timezone: '',
    additionalInfo: '',
    email: '',
    mobile: '',
    website: '',
    facebook: '',
    whatsWrong: '',
    business_hour: [],
    business_types: [],
    service_category: [],
    feature_types: [],
    cuisine_style: [],
    good_for: [],
    image: [
      {
        url: '',
        preview: null,
        tooltip: '',
        link: '',
        tag: [],
        uploadingStatus: 'pending',
      },
    ],
  }),

  validationSchema: yup.object().shape({
    name: yup.string().required('Place name is required!'),
    relationship: yup.string().required('Relationship is required!'),
    fullAddress: yup.string().required('Address is required'),
    // streetAddress: yup.string().required('Street Address is required'),
    // city: yup.string().required('City is required'),
    // postcode: yup.string().required('Postcode is required'),
    // state: yup.string().required('State is required'),
    // country: yup.string().required('Country is required'),
    // email: yup.string().email('Enter valid email address!'),
    // mobile: yup.string().required('Mobile contact is required!'),
    website: yup.string().url('Enter valid url!'),
    facebook: yup.string().url('Enter valid url!'),
    cuisine_style: yup.array().required('Cuisine Style is required'),
    business_types: yup.array().required('Business Type is required'),
    service_category: yup.array().required('Services is required'),
    // whatsWrong: yup
    //   .string()
    //   .min(26, 'At least 26 Character Required!')
    //   .max(300, 'Not greater than 300 Character!'),
    business_hour: yup.array().of(
      yup.object().shape({
        option: yup.array().of(
          yup.object().shape(
            {
              start: yup.string().when(['type', 'end'], {
                is: (type, end) =>
                  (type !== undefined && type !== '' && type !== 'Closed') ||
                  (end !== undefined && end !== ''),
                then: yup.string().required('Start date is Required'),
              }),
              end: yup.string().when(['type'], {
                is: type => type !== undefined && type !== '' && type !== 'Closed',
                then: yup.string().required('End date is Required'),
              }),
              // type: yup.string().when(['start', 'end'], {
              //   is: (start, end) => start !== '' || end !== '',
              //   then: yup
              //     .string()
              //     .min(3, 'At least 3 Character Required!')
              //     .max(20, 'Not greater than 20 Character!')
              //     .required('Label is Required'),
              // }),
            },
            ['type', 'end'],
          ),
        ),
      }),
    ),
  }),

  handleSubmit: (values, { props }) => {
    if (!props.isLoggedIn) {
      props.setIsActiveModal({
        value: true,
        path: '',
        callBackFunction: () => props.onSubmit(values),
      });
    } else {
      props.onSubmit(values);
    }
  },

  displayName: 'LocationForm',
})(MobileForm);

export default MyEnhancedForm;
