import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { isNull } from 'lodash';
import { Text, Avatar } from '../../../components/elements';

const PlaceContainer = styled.article`
  padding: 1rem;
  justify-content: center;
  border: 1px solid ${({ theme: { colors } }) => colors.lightGreyAlpha};
  &&& {
    .content p:not(:last-child) {
      margin-bottom: 0rem;
    }
  }
`;

const Item = ({ item, image }) => {
  if (isNull(item)) {
    return '';
  }
  return (
    <Link to={`/item/${item.item_id}`}>
      <PlaceContainer className="media">
        <figure className="media-left">
          <p className="image is-64x64">
            <Avatar
              name={item.name}
              image={image && image.url}
              maskProps={{ width: 64, height: 64 }}
            />
          </p>
        </figure>
        <div className="media-content">
          <div>
            <Text size="small" weight="semibold">
              {item.name}
            </Text>
            <Text color="darkGrey" size="tiny">
              Restaurant, Asian
            </Text>
            <Text color="darkGrey" size="tiny">
              {item.city}, {item.state}, {item.country} {item.post_code}
            </Text>
          </div>
        </div>
        <div className="media-right">
          {/* <IconButton className="button">
        <UnFollowActionButton placeId={placeId} followId={followId} />
      </IconButton> */}
        </div>
      </PlaceContainer>
    </Link>
  );
};

export default Item;
