import React from 'react';
import MenuLayout from '../components/global/MenuLayout';
import Button from '../components/lib/Button';

const DesktopForm = ({ client }) => {
  return (
    <MenuLayout>
      <div className="container section">
        <div className="columns">
          <div className="column">
            <Button size="small">Hello</Button>
            <br />
            <br />
            <Button isLoading size="medium">
              Hello
            </Button>
            <br />
            <br />
            <Button isDisabled size="large">
              Hello
            </Button>
            <br />
            <br />
            <Button isDisabled size="extraLarge">
              Hello
            </Button>
          </div>
          <div className="column">
            <Button isOutLine size="medium">
              Hello
            </Button>
            <br />
            <br />
            <Button isOutLine isLoading size="large">
              Hello
            </Button>
            <br />
            <br />
            <Button isOutLine isDisabled size="large">
              Hello
            </Button>
            <br />
            <br />
            <Button isOutLine isDisabled size="large">
              Hello
            </Button>
          </div>
          <div className="column">
            <Button isGhost size="large">
              Hello
            </Button>
          </div>
          <div className="column">
            <Button isLink size="extraLarge">
              Hello
            </Button>
          </div>
        </div>
      </div>
    </MenuLayout>
  );
};
export default DesktopForm;
