import React from 'react';
import styled from 'styled-components';
import { truncate, trim } from 'lodash';
import { MdClear, MdKeyboardArrowRight } from 'react-icons/md';

import { Modal, Text, FlexRows } from '../../../../components/elements';
import DealsCheckBox from './DealsCheckBox';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
  &&& {
    .column {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
const ModalHeaderLeft = styled.div`
  position: absolute;
  left: 0.5rem;
`;
const ModalHeaderRight = styled.div`
  position: absolute;
  right: 0.5rem;
`;

const ModalFooter = styled.a`
  background-color: #fff;
  z-index: 50;
  width: 100%;
  padding: 0.5rem;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  border-top: 2px solid ${({ theme: { colors } }) => colors.borderColor};
  .apply-filter {
    padding: 1rem 3rem;
    background-color: ${({ theme: { primaryColor } }) => primaryColor};
    border-radius: 5px;
  }
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 5rem;
`;

const Flex = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.borderColor};
`;

const FilterListItem = ({ title, content, onSelect }) => (
  <Flex onClick={() => onSelect(true)}>
    <div>
      <Text paddingBottom="0.3">{title}</Text>
      <Text size="tiny" color="primaryColor" weight="regular">
        {content ? (
          <Text color="primaryColor" size="tiny">
            {trim(content)}
          </Text>
        ) : (
          'All'
        )}
      </Text>
    </div>
    <div className="is-flex">
      &nbsp; &nbsp;
      <Text color="specialGrey">
        <MdKeyboardArrowRight size={30} />
      </Text>
    </div>
  </Flex>
);

const FilterModal = ({
  isActive,
  cuisineInput,
  businessTypeInputs,
  featureInputs,
  itemTypeInputs,
  serviceTypeInputs,
  dressCodeInputs,
  diningStyleInputs,
  publicTransportsInputs,
  ambienceInputs,
  setActiveFilterModal,
  setActiveCuisinesFilterModal,
  setActiveBusinessFilterModal,
  setActiveFeaturesFilterModal,
  setActiveItemsFilterModal,
  setActiveServiceTypeFilterModal,
  setActiveDressCodeFilterModal,
  setActiveDiningStyleFilterModal,
  setActivePublicTransportsFilterModal,
  setActiveAmbienceFilterModal,
  setToMainFilter,
  filterTags,
  clearFilter,
}) => {
  const {
    cuisines,
    businessTypes,
    features,
    itemTypes,
    serviceType,
    dressCode,
    diningStyle,
    publicTransports,
    ambience,
  } = filterTags;
  return (
    <Modal isActive={isActive}>
      <ModalHeader>
        <ModalHeaderLeft>
          <a onClick={() => setActiveFilterModal(false)}>
            <Text color="white">
              <MdClear size={25} />
            </Text>
          </a>
        </ModalHeaderLeft>
        <ModalHeaderRight>
          <a onClick={clearFilter}>
            <Text className="is-uppercase" color="white" weight="semibold" size="small">
              Clear All
            </Text>
          </a>
        </ModalHeaderRight>
        <FlexRows>
          <Text className="is-uppercase" align="center" color="white" weight="bold" size="lmedium">
            Filter
          </Text>
        </FlexRows>
      </ModalHeader>
      <Content>
        {cuisines.length !== 0 && (
          <FilterListItem
            title="Sort By"
            content={truncate(
              cuisineInput.map(item => ` ${item.description}`),
              { length: 40 },
            )}
            onSelect={setActiveCuisinesFilterModal}
          />
        )}

        {businessTypes.length !== 0 && (
          <FilterListItem
            title="Business Types"
            content={truncate(
              businessTypeInputs.map(item => ` ${item.description}`),
              { length: 40 },
            )}
            onSelect={setActiveBusinessFilterModal}
          />
        )}
        {features.length !== 0 && (
          <FilterListItem
            title="Dietary"
            content={truncate(
              featureInputs.map(item => ` ${item.description}`),
              { length: 40 },
            )}
            onSelect={setActiveFeaturesFilterModal}
          />
        )}

        <FilterListItem
          title="Dining Style"
          content={truncate(
            serviceTypeInputs.map(item => ` ${item.description}`),
            { length: 40 },
          )}
          onSelect={setActiveServiceTypeFilterModal}
        />

        {dressCode.length !== 0 && (
          <FilterListItem
            title="Features"
            content={truncate(
              dressCodeInputs.map(item => ` ${item.description}`),
              { length: 40 },
            )}
            onSelect={setActiveDressCodeFilterModal}
          />
        )}
        {diningStyle.length !== 0 && (
          <FilterListItem
            title="Price Range"
            content={truncate(
              diningStyleInputs.map(item => ` ${item.description}`),
              { length: 40 },
            )}
            onSelect={setActiveDiningStyleFilterModal}
          />
        )}
        <DealsCheckBox />
      </Content>
      <ModalFooter>
        <Text color="primaryColor" weight="regular" size="lmedium">
          Cancel
        </Text>
        <Text
          color="white"
          weight="semibold"
          size="lmedium"
          className="apply-filter"
          onClick={() => setToMainFilter()}
        >
          Apply Filters
        </Text>
      </ModalFooter>
    </Modal>
  );
};

export default FilterModal;
