import React from 'react';
import styled from 'styled-components';

import { MdClear, MdDone } from 'react-icons/md';
import { trim } from 'lodash';

import { Modal, Text, FlexRows } from '../../../../components/elements';

import SearchInput from './SearchInput';

const ModalHeader = styled.div`
  background-color: ${({ theme: { primaryColor } }) => primaryColor};
  z-index: 50;
  width: 100%;
  padding: 0.5rem 1rem;
  &&& {
    .column {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const ModalHeaderLeft = styled.div`
  position: absolute;
  left: 0.5rem;
`;
const ModalHeaderRight = styled.div`
  position: absolute;
  right: 0.5rem;
`;

const Content = styled.div`
  flex: 1;
  z-index: 50;
  width: 100%;
  overflow-y: scroll;
`;

const ModalFooter = styled.a`
  background-color: #fff;
  z-index: 50;
  width: 100%;
  padding: 0.5rem;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  border-top: 2px solid ${({ theme: { colors } }) => colors.borderColor};
  .apply-filter {
    padding: 1rem 3rem;
    background-color: ${({ theme: { primaryColor } }) => primaryColor};
    border-radius: 5px;
  }
`;

const Flex = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.borderColor};
`;

const Item = ({ title, isIconActive, onSelect }) => (
  <Flex onClick={onSelect}>
    <div>
      <Text>{trim(title)}</Text>
    </div>
    <div className="is-flex">
      {isIconActive && (
        <Text color="primaryColor">
          <MdDone size={25} />
        </Text>
      )}
    </div>
  </Flex>
);

const FilterTypeModal = ({
  headerTitle,
  headerLeftButtonText,
  headerRightButtonText,
  isActive,
  typeDisplayObjectName,
  typeInputs,
  types,
  addType,
  removeType,
  onCloseModal,
}) => (
  <Modal isActive={isActive}>
    <ModalHeader>
      <ModalHeaderLeft>
        <a onClick={() => onCloseModal(false)}>
          {headerLeftButtonText && (
            <Text color="white">
              <MdClear size={25} />
            </Text>
          )}
        </a>
      </ModalHeaderLeft>
      <ModalHeaderRight>
        <a onClick={() => onCloseModal(false)}>
          <Text className="is-uppercase" color="white" weight="semibold" size="small">
            {headerRightButtonText}
          </Text>
        </a>
      </ModalHeaderRight>
      <FlexRows>
        <Text className="is-uppercase" align="center" color="white" weight="bold" size="lmedium">
          {headerTitle}
        </Text>
      </FlexRows>
    </ModalHeader>
    <SearchInput type={headerTitle} />
    {/* <SearchWrapper>
      <Field leftIcon="fas fa-search" placeholder="Search by typing cuisine name" />
    </SearchWrapper> */}
    <Content>
      {types.map(type => {
        const isActiveItem = typeInputs.filter(item => type.name === item.name);
        return (
          <Item
            key={type.name}
            title={`${type[typeDisplayObjectName]}`}
            isIconActive={isActiveItem.length !== 0}
            onSelect={() => (isActiveItem.length !== 0 ? removeType(type.name) : addType(type))}
          />
        );
      })}
    </Content>
    <ModalFooter>
      <Text color="primaryColor" weight="regular" size="lmedium">
        Cancel
      </Text>
      <Text color="white" weight="semibold" size="lmedium" className="apply-filter">
        Apply
      </Text>
    </ModalFooter>
  </Modal>
);

export default FilterTypeModal;
