import React from 'react';
import styled from 'styled-components';
import { FlexRows, Icon, Text } from '../../components/elements';

const Container = styled(FlexRows)`
  padding: 1.5rem 0rem;
  margin: 0rem 1rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGreyBeta};
`;

const TabItem = ({ name }) => (
  <Container justify="space-between">
    <Text size="small" weight="regular" letterSpacing="loose">
      {name}
    </Text>
    <Icon iconName="fas fa-angle-right" color="specialGrey" />
  </Container>
);

export default TabItem;
